<app-header (goBack)="onBackClick()" [moveElementDown]="bodyContainer">
  <div headerBody>
    <div class="section-forms" [formGroup]="formGroup">
      <div class="container g-0">
        <div class="row ms-2 py-2">
          <div class="col-md-1">
            <app-form-item [field]="fields.isin" [focus]="isin">
              <input class="form-control fw-medium" type="text" [formControlName]="fields.isin" component #isin readonly />
            </app-form-item>
              <app-status size="sm" [status]="instrumentStatus">
            </app-status> 
          </div>
          <div class="col-md-1">
            <app-form-item [field]="fields.ipa" [focus]="ipa">
              <input class="form-control fw-medium" type="text" [formControlName]="fields.ipa" component #ipa [readonly]="isEditMode" />
            </app-form-item>
          </div>
          <div class="col-md-2">
            <app-form-item [field]="fields.issuerName" [focus]="issuerName">
              <input class="form-control fw-medium" type="text" [formControlName]="fields.issuerName" component #issuerName readonly />
            </app-form-item>
          </div>
          <div class="col-md-2">
            <app-form-item [field]="fields.principalPayingAgent" [focus]="principalPayingAgent">
              <input class="form-control fw-medium" type="text" [formControlName]="fields.principalPayingAgent"
                component #principalPayingAgent readonly />
            </app-form-item>
          </div>
          <div class="col-md-2" *ngIf="!isCspSgLux">
            <app-form-item [field]="fields.commonDepositary" [focus]="commonDepositary">
              <input class="form-control fw-medium" type="text" [formControlName]="fields.commonDepositary"
                component #commonDepositary readonly />
            </app-form-item>
          </div>
          <div class="col-md-2" *ngIf="isCspSgLux">
            <app-form-item [field]="fields.commonServiceProvider" [focus]="commonServiceProvider">
              <input class="form-control fw-medium" type="text" [formControlName]="fields.commonServiceProvider"
                component #commonServiceProvider readonly />
            </app-form-item>
          </div>
          <div class="col-md-2">
            <app-form-item [field]="fields.listingAgent" [focus]="listingAgent">
              <input class="form-control fw-medium" type="text" [formControlName]="fields.listingAgent" component
                #listingAgent readonly />
            </app-form-item>
          </div>
          <div class="col-md-2" [hidden]="!isEventExchCertType">
            <app-form-item [field]="fields.physicalForm" [focus]="physicalForm">
              <input class="form-control fw-medium" type="text" [formControlName]="fields.physicalForm" component
                #physicalForm readonly />
            </app-form-item>
          </div>
        </div>
      </div>
      <div class="row ms-2 py-2">
        <div class="col-md-2">
          <app-form-item [field]="fields.complianceRestriction" [focus]="complianceRestriction">
            <app-yes-no-buttons component #complianceRestriction [formControlName]="fields.complianceRestriction"></app-yes-no-buttons>
          </app-form-item>
        </div>
        <div class="col-md-2">
          <app-form-item [field]="fields.paymentDefault" [focus]="paymentDefault">
            <app-yes-no-buttons component #paymentDefault [formControlName]="fields.paymentDefault"></app-yes-no-buttons>
          </app-form-item>
        </div>
      </div>
    </div>
  </div>
  <div headerButton> 
    <div *ngIf="!isEditMode">
      <button *ngIf="canModifyEvents && displayCancelButton" class="btn btn-md btn-outline-danger m-2" (click)="onCancelEventClick()">
        <span class="title-case" translate>events.buttons.cancel</span>
      </button>
      <button *ngIf="canModifyEvents && enableModifyEvent" (click)="onEditClick()" class="btn btn-md btn-icon-text btn-default" >
        <em class="icon icon-prepend me-1">mode_edit</em>
        <span class="title-case" translate>events.buttons.modifyEvent</span>
      </button>
    </div>
    <app-event-save-cancel *ngIf="isEditMode" (save)= "savePositions()" [disableSaveButton]="disableSaveButton">
    </app-event-save-cancel>
  </div>
</app-header>
<div class="container" #bodyContainer>
  <div class="text-end">
    <button class="btn btn-flat mb-2" (click)="onAuditClick()" [hidden]="!this.auditData?.eventSentforValidationBy">Event audit</button>
    <button class="btn btn-flat mb-2" data-bs-toggle="collapse"
      data-bs-target=".collapse"
      aria-expanded="false">Expand/Collapse</button>
  </div>
  <div *ngIf="canModifyEvents && (displaySendToValidation || displayValidateAndReject || displayReleasePayment)"
    class="bg-info py-2 px-3 mb-0 mt-2 text-white text-end">
    <div>
      <button *ngIf="displayReleasePayment" (click)="releasePayment()" class="btn btn-flat-info">
        <span class="btn btn-outline-primary-alt" translate>events.buttons.releasePayment</span>
      </button>
      <button *ngIf="displaySendToValidation" (click)="onSendToValidateClick()" class="btn btn-flat-info">
        <span class="btn btn-outline-primary-alt" translate>events.buttons.sendToValidation</span>
      </button>
      <button *ngIf="displayValidateAndReject" (click)="onValidateClick()" class="btn btn-flat-info">
        <span class="btn btn-outline-primary-alt" translate>events.buttons.validate</span>
      </button>
      <button *ngIf="displayValidateAndReject" (click)="onRejectClick()" class="btn btn-flat-info">
        <span class="btn btn-outline-primary-alt" translate>events.buttons.reject</span>
      </button>
    </div>
  </div>
  <div class="border">
    <div class="card">
      <div class="card-header border-top" id="eventDetails">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEventDetails"
            aria-expanded="true" aria-controls="collapseEventDetails">
            <em class="icon icon-sm me-2 text-secondary">
              keyboard_arrow_right
            </em>
            <span translate>events.form.eventDetails</span>
          </button>
        </h5>
      </div>

      <div id="collapseEventDetails" class="collapse show" aria-labelledby="eventDetails">
        <div class="card-body">
          <div class="section-forms" [formGroup]="formGroup">
            <div class="row gy-2">
              <div class="col-md-3">
                <app-form-item [field]="fields.ipa" [focus]="ipa">
                  <input class="form-control" type="text" [formControlName]="fields.ipa"
                    [placeholder]="placeholder(fields.ipa) | translate" component #ipa [readonly]="isEditMode" />
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.isin" [focus]="isin">
                  <input class="form-control" type="text" [formControlName]="fields.isin"
                    [placeholder]="placeholder(fields.isin) | translate" component #isin readonly />
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.issuerName" [focus]="issuerName">
                  <input class="form-control" type="text" [formControlName]="fields.issuerName"
                    [placeholder]="placeholder(fields.issuerName) | translate" component #issuerName readonly />
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.instrumentName" [focus]="instrumentName">
                  <input class="form-control" type="text" [formControlName]="fields.instrumentName"
                    [placeholder]="placeholder(fields.instrumentName) | translate" component #instrumentName readonly />
                </app-form-item>
              </div>
            </div>

            <div class="row gy-2 mt-1">
              <div class="col-md-3">
                <app-form-item [field]="fields.eventType" [focus]="eventType">
                  <input class="form-control" type="text" [formControlName]="fields.eventType"
                    [placeholder]="placeholder(fields.eventType) | translate" component #eventType readonly />
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.eventId" [focus]="eventId">
                  <input class="form-control" type="text" [formControlName]="fields.eventId"
                    [placeholder]="placeholder(fields.eventId) | translate" component #eventId readonly />
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.eventStatus" [focus]="eventStatus" *ngIf="eventLoaded | async">
                  <app-event-type-multiselect #eventStatus component [formControlName]="fields.eventStatus"
                    [multiple]="false" [closeOnSelect]="true" [type]="EVENT_WORKFLOW_STATUS"
                    styleClass="form-control-lg" [hasError]="hasError(fields.eventStatus)"
                    [defaultItems]=eventStatusDropDownValues>
                  </app-event-type-multiselect>
                </app-form-item>
              </div>
              <div class="col-md-3" [hidden]="isEventExchCertType">
                <app-form-item [field]="fields.transferMode" [focus]="transferMode">
                  <select class="form-control box-style" type="text" [formControlName]="fields.transferMode" component
                    #transferMode>
                    <option value="e-Mail">E-mail</option>
                    <option value="csv">CSV</option>
                  </select>
                </app-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header border-top" id="processingDates">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseProcessingDates" aria-expanded="false" aria-controls="collapseProcessingDates">
            <em class="icon icon-sm me-2 text-secondary">
              keyboard_arrow_right
            </em>
            <span translate>events.form.processingDates</span>
          </button>
        </h5>
      </div>
      <div id="collapseProcessingDates" class="collapse show" aria-labelledby="processingDates">
        <div class="card-body">
          <div class="section-forms" [formGroup]="formGroup">
            <div class="row gy-2">
              <div class="col-md-3">
                <app-form-item [field]="fields.notificationDate" [focus]="notificationDate">
                  <app-date-picker [formControlName]="fields.notificationDate" #notificationDate component  (onchange)="dateModificationAlert()">
                  </app-date-picker>
                </app-form-item>
              </div>
              <div class="col-md-3" [hidden]="!isEventExchCertType">
                <app-form-item [field]="fields.valueDate" [focus]="valueDate">
                  <app-date-picker [formControlName]="fields.valueDate" #valueDate component (onchange)="dateModificationAlert();recordValueDateModify()">
                  </app-date-picker>
                </app-form-item>
                <div class="error-message" *ngIf=valueDateModificationAlert>
                  <span translate>events.creation.valueDateInterventionError</span>
                </div>
                <div class="error-message" *ngIf=recordValueDateAlert>
                  <span translate>events.creation.recordValueDateError</span>
                </div>
              </div>
              <div class="col-md-3" [hidden]="isEventExchCertType">
                <app-form-item [field]="fields.reqFundDate" [focus]="reqFundDate">
                  <app-date-picker [formControlName]="fields.reqFundDate" #reqFundDate component (onchange)="dateModificationAlert()">
                  </app-date-picker>
                </app-form-item>
                <div class="error-message" *ngIf=reqFundsDateModificationAlert>
                  <span translate>events.creation.reqFundsDateModificationAlert</span>
                </div>
              </div>
              <div class="col-md-3" *ngIf=isInternationalISIN [hidden]="isEventExchCertType">
                <app-form-item [field]="fields.preAdvDate" [focus]="preAdvDate">
                  <app-date-picker [formControlName]="fields.preAdvDate" #preAdvDate component (onchange)="dateModificationAlert()">
                  </app-date-picker>
                </app-form-item>
                <div class="error-message" *ngIf=preAdviseReqOfFundModificationAlert>
                  <span translate>events.creation.preAdviseReqOfFundModificationAlert</span>
                </div>
                <div class="error-message" *ngIf=preAdviseNotificationModificationAlert>
                  <span translate>events.creation.preAdviseNotificationModificationAlert</span>
                </div>
              </div>
              <div class="col-md-3" [hidden]="isEventExchCertType">
                <app-form-item [field]="fields.timingFundArrivalDate" [focus]="timingFundArrivalDate">
                  <app-date-picker [formControlName]="fields.timingFundArrivalDate" #timingFundArrivalDate component>
                  </app-date-picker>
                </app-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" [hidden]="isEventExchCertType">
      <div class="card-header border-top" id="eventDates">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEventDates"
            aria-expanded="false" aria-controls="collapseEventDates">
            <em class="icon icon-sm me-2 text-secondary">
              keyboard_arrow_right
            </em>
            <span translate>events.form.eventDates</span>
          </button>
        </h5>
      </div>
      <div id="collapseEventDates" class="collapse show" aria-labelledby="eventDates">
        <div class="card-body">
          <div class="section-forms" [formGroup]="formGroup">
            <div class="row gy-2">
              <div class="col-md-3">
                <app-form-item [field]="fields.valueDate" [focus]="valueDate">
                  <app-date-picker [formControlName]="fields.valueDate" #valueDate component (onchange)="dateModificationAlert();recordValueDateModify()">
                  </app-date-picker>
                </app-form-item>
                <div class="error-message" *ngIf=valueDateModificationAlert>
                  <span translate>events.creation.valueDateInterventionError</span>
                </div>
                <div class="error-message" *ngIf=recordValueDateAlert>
                  <span translate>events.creation.recordValueDateError</span>
                </div>
              </div>
              <div class="col-md-3" [hidden]="displayBeginEndDate">
                <app-form-item [field]="fields.beginDate" [focus]="beginDate">
                  <app-date-picker  [style.color]="startDateValueMismatched" [formControlName]="fields.beginDate" #beginDate component (onchange)="beginEndDateModificationAlert()">
                  </app-date-picker>
                </app-form-item>
              </div>
              <div class="col-md-3" [hidden]="displayBeginEndDate">
                <app-form-item [field]="fields.endDate" [focus]="endDate" >
                  <app-date-picker [style.color]="endDateValueMismatched" [formControlName]="fields.endDate" #endDate component (onchange)="beginEndDateModificationAlert()">
                  </app-date-picker>
                </app-form-item>                
                <div class="error-message" *ngIf=beginEndDateAlert>
                  <span translate>events.creation.beginEndDateModificationAlert</span>
                </div>
              </div>
              <div class="col-md-3" *ngIf=isInternationalISIN>
                <app-form-item [field]="fields.recordDate" [focus]="recordDate">
                  <app-date-picker [formControlName]="fields.recordDate" #recordDate component (onchange)="recordValueDateModify()">
                  </app-date-picker>
                </app-form-item>
              </div>
              <div class="col-md-3" [hidden]="displayExDate">
                <app-form-item [field]="fields.exDate" [focus]="exDate">
                  <app-date-picker [formControlName]="fields.exDate" #exDate component>
                  </app-date-picker>
                </app-form-item>
              </div>
            </div>
            <div class="row gy-2 mt-1">
              <div class="col-md-3">
                <app-form-item [field]="fields.paymentDate" [focus]="paymentDate">
                  <app-date-picker [formControlName]="fields.paymentDate" #paymentDate component>
                  </app-date-picker>
                </app-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" [hidden]="isEventIntrType">
      <div class="card-header border-top" id="interestDetails">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseInterestDetails" aria-expanded="false" aria-controls="collapseInterestDetails">
            <em class="icon icon-sm me-2 text-secondary">
              keyboard_arrow_right
            </em>
            <span translate>events.form.interestDetails</span>
          </button>
        </h5>
      </div>
      <div id="collapseInterestDetails" class="collapse show" aria-labelledby="interestDetails">
        <div class="card-body">
          <div class="section-forms" [formGroup]="formGroup">
            <div class="row" *ngIf=false>
              <div class="col-md-3">
                <app-form-item [field]="fields.redemptionType" [focus]="redemptionType">
                  <app-event-type-multiselect #redemptionType component [formControlName]="fields.redemptionType"
                    [multiple]="false" [closeOnSelect]="true" [type]="EVENT_REDEMPTION_TYPE"
                    [hasError]="hasError(fields.redemptionType)" styleClass="form-control-lg"
                    (onchange)="displayRedemtionTypeBasedFields()"
                    placeholder="{{ placeholder(fields.redemptionType)  | translate }}"
                    [defaultItems]=redemptionTypesDropDown>
                  </app-event-type-multiselect>
                </app-form-item>
              </div>
            </div>
            <div class="row gy-2">
              <div class="col-md-3">
                <app-form-item [field]="fields.couponType" [focus]="couponType">
                  <input class="form-control" type="text" [formControlName]="fields.couponType"
                    placeholder="{{ placeholder(fields.couponType)  | translate }}" component #couponType readonly />
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.dayCountFraction" [focus]="dayCountFraction">
                  <input class="form-control" type="text" [formControlName]="fields.dayCountFraction"
                    placeholder="{{ placeholder(fields.dayCountFraction)  | translate }}" component #dayCountFraction
                    readonly [style.color]="dcfColorMismatched" />
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.numOfDayOfThePeriod" [focus]="numOfDayOfThePeriod">
                  <input class="form-control" type="text" [formControlName]="fields.numOfDayOfThePeriod"
                    [placeholder]="placeholder(fields.numOfDayOfThePeriod) | translate" component #numOfDayOfThePeriod
                    readonly />
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.interestRate" [focus]="interestRate">
                  <input class="form-control" type="text" [formControlName]="fields.interestRate"
                    [placeholder]="placeholder(fields.interestRate) | translate" component #interestRate />
                </app-form-item>
              </div>
            </div>
            <div class="row gy-2 mt-1">
              <div class="col-md-3">
                <app-form-item [field]="fields.denominationAsString" [focus]="denominationAsString" readonly>
                  <input class="form-control" type="text" [formControlName]="fields.denominationAsString"
                    [placeholder]="placeholder(fields.denominationAsString) | translate" component #denominationAsString
                    readonly [style.color]="denominationValueMismatched" />
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.denominationCurrency" [focus]="denominationCurrency">
                  <input class="form-control" type="text" [formControlName]="fields.denominationCurrency"
                    [placeholder]="placeholder(fields.denominationCurrency) | translate" component #denominationCurrency
                    [style.color]="denominationCcyMismatched" readonly />
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.amountPerDenomination" [focus]="amountPerDenomination">
                  <app-number #amountPerDenomination component [formControlName]="fields.amountPerDenomination" [formatThousandSeparator]='true'
                  [style.color]="amountPerDenominationValueMismatched" placeholder="{{ placeholder(fields.amountPerDenomination) | translate }}">

                  </app-number>
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.paymentCurrency">
                  <input *ngIf=!multiplePmtCurrenciesExists class="form-control" type="text" [formControlName]="fields.paymentCurrency"
                    [placeholder]="placeholder(fields.paymentCurrency) | translate" component
                    #paymentCurrency />
                  <select *ngIf=multiplePmtCurrenciesExists class="form-control" type="text" [formControlName]="fields.paymentCurrency" component
                    #paymentCurrency>
                    <option [label]="paymentCcy" *ngFor="let paymentCcy of paymentCcys" [value]="paymentCcy">
                      {{paymentCcy}}
                    </option>
                  </select>
                </app-form-item>
                <div class="error-message" *ngIf=multiplePmtCurrenciesExists>
                  <span translate>events.creation.multiPaymentCurrency</span>
                </div>
              </div>
            </div>
            <div class="row gy-2 mt-1" [hidden]="idRedmTypeDT" *ngIf=false>
              <div class="col-md-3">
                <app-form-item [field]="fields.underlyingDenomQty" [focus]="underlyingDenomQty">
                    <app-number #underlyingDenomQty component [formControlName]="fields.underlyingDenomQty" [formatThousandSeparator]='true'
                      placeholder="{{ placeholder(fields.underlyingDenomQty) | translate }}">
                    </app-number>
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.underlyingIsin" [focus]="underlyingIsin">
                  <input class="form-control" type="text" [formControlName]="fields.underlyingIsin"
                    [placeholder]="placeholder(fields.underlyingIsin) | translate" component #underlyingIsin />
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.underlyingDescription" [focus]="underlyingDescription">
                  <input class="form-control" type="text" [formControlName]="fields.underlyingDescription"
                    [placeholder]="placeholder(fields.underlyingDescription) | translate" component
                    #underlyingDescription />
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.strikePrice" [focus]="strikePrice">
                    <app-number #strikePrice component [formControlName]="fields.strikePrice" [formatThousandSeparator]='true'
                    placeholder="{{ placeholder(fields.strikePrice) | translate }}">
                  </app-number>
                </app-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" [hidden]="displayRedemptionField">
      <div class="card-header border-top" id="redemptionDetails">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseRedemptionDetails" aria-expanded="false" aria-controls="collapseRedemptionDetails">
            <em class="icon icon-sm me-2 text-secondary">
              keyboard_arrow_right
            </em>
            <span translate>events.form.redemptionDetails</span>
          </button>
        </h5>
      </div>
      <div id="collapseRedemptionDetails" class="collapse show" aria-labelledby="redemptionDetails">
        <div class="card-body">
          <div class="section-forms" [formGroup]="formGroup">
            <div class="row gy-2">
              <div class="col-md-3">
                <app-form-item [field]="fields.redemptionType" [focus]="redemptionType">
                  <app-event-type-multiselect #redemptionType component [formControlName]="fields.redemptionType"
                    [multiple]="false" [closeOnSelect]="true" [type]="EVENT_REDEMPTION_TYPE"
                    [hasError]="hasError(fields.redemptionType)" styleClass="form-control-lg"
                    (onchange)="displayRedemtionTypeBasedFields()"
                    placeholder="{{ placeholder(fields.redemptionType)  | translate }}"
                    [defaultItems]=redemptionTypesDropDown [style.color]="redmTypeMismatched">
                  </app-event-type-multiselect>
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.denominationAsString" [focus]="denominationAsString" readonly>
                  <input class="form-control" type="text" [formControlName]="fields.denominationAsString"
                    [placeholder]="placeholder(fields.denominationAsString) | translate" component #denominationAsString
                    readonly [style.color]="denominationValueMismatched" />
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.denominationCurrency" [focus]="denominationCurrency">
                  <input class="form-control" type="text" [formControlName]="fields.denominationCurrency"
                    [placeholder]="placeholder(fields.denominationCurrency) | translate" component #denominationCurrency
                    [style.color]="denominationCcyMismatched" readonly />
                </app-form-item>
              </div>
            </div>
            <div class="row gy-2 mt-1">
              <div class="col-md-3" [hidden]="displayRedemtionPercent">
                <app-form-item [field]="fields.eventRedemptionPrice" [focus]="eventRedemptionPrice">
                  <input class="form-control" type="text" [formControlName]="fields.eventRedemptionPrice"
                    [placeholder]="placeholder(fields.eventRedemptionPrice) | translate" component
                    [style.color]="redmRateMismatched" #eventRedemptionPrice />
                </app-form-item>
              </div>
              <div class="col-md-3" [hidden]="displayRedemtionUnit">
                <app-form-item [field]="fields.eventRedemptionUnit" [focus]="eventRedemptionUnit">
                  <input class="form-control" type="text" [formControlName]="fields.eventRedemptionUnit"
                    [style.color]="redmRateMismatched"
                    [placeholder]="placeholder(fields.eventRedemptionUnit) | translate" component
                    #eventRedemptionUnit />
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.amountPerDenomination" [focus]="amountPerDenomination">
                    <app-number #amountPerDenomination component [formControlName]="fields.amountPerDenomination" [formatThousandSeparator]='true'
                    [style.color]="amountPerDenominationValueMismatched" placeholder="{{ placeholder(fields.amountPerDenomination) | translate }}">
                  </app-number>
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.paymentCurrency">
                  <input *ngIf=!multiplePmtCurrenciesExists class="form-control" type="text" [formControlName]="fields.paymentCurrency"
                    [placeholder]="placeholder(fields.paymentCurrency) | translate" component
                    #paymentCurrency />
                  <select *ngIf=multiplePmtCurrenciesExists class="form-control" type="text" [formControlName]="fields.paymentCurrency" component
                    #paymentCurrency>
                    <option [label]="paymentCcy" *ngFor="let paymentCcy of paymentCcys" [value]="paymentCcy">
                      {{paymentCcy}}
                    </option>
                  </select>
                </app-form-item>
                <div class="error-message" *ngIf=multiplePmtCurrenciesExists>
                  <span translate>events.creation.multiPaymentCurrency</span>
                </div>
              </div>
            </div>
            <div class="row gy-2 mt-1" [hidden]="idRedmTypeDT">
              <div class="col-md-3">
                <app-form-item [field]="fields.underlyingDenomQty" [focus]="underlyingDenomQty">
                    <app-number #underlyingDenomQty component [formControlName]="fields.underlyingDenomQty" [formatThousandSeparator]='true'
                      placeholder="{{ placeholder(fields.underlyingDenomQty) | translate }}">
                    </app-number>
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.underlyingIsin" [focus]="underlyingIsin">
                  <input class="form-control" type="text" [formControlName]="fields.underlyingIsin"
                    [placeholder]="placeholder(fields.underlyingIsin) | translate" component #underlyingIsin />
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.underlyingDescription" [focus]="underlyingDescription">
                  <input class="form-control" type="text" [formControlName]="fields.underlyingDescription"
                    [placeholder]="placeholder(fields.underlyingDescription) | translate" component
                    #underlyingDescription />
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.strikePrice" [focus]="strikePrice">
                    <app-number #strikePrice component [formControlName]="fields.strikePrice" [formatThousandSeparator]='true'
                    placeholder="{{ placeholder(fields.strikePrice) | translate }}">
                  </app-number>
                </app-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" [hidden]="isEventExchCertType">
      <div class="card-header border-top" id="eventOtherDetails">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseEventOtherDetails" aria-expanded="false" aria-controls="collapseEventOtherDetails">
            <em class="icon icon-sm me-2 text-secondary">
              keyboard_arrow_right
            </em>
            <span translate>events.form.additionalDetails</span>
          </button>
        </h5>
      </div>
      <div id="collapseEventOtherDetails" class="collapse show" aria-labelledby="eventOtherDetails">
        <div class="card-body">
          <div class="section-forms" [formGroup]="formGroup">
            <div class="row gy-2">             
              <div class="col-md-3">
                <app-form-item [field]="fields.previousPoolFactor" [focus]="previousPoolFactor">
                  <input class="form-control" type="text" [formControlName]="fields.previousPoolFactor"
                    [placeholder]="placeholder(fields.previousPoolFactor) | translate" component #previousPoolFactor />
                </app-form-item>
              </div>
              <div class="col-md-3" [hidden]="isIntrRedmMcalDvcaType">
                <app-form-item [field]="fields.newPoolFactor" [focus]="newPoolFactor">
                  <input class="form-control" type="text" [formControlName]="fields.newPoolFactor"
                    [placeholder]="placeholder(fields.newPoolFactor) | translate" component #newPoolFactor />
                </app-form-item>
              </div>
              <div class="col-md-3" [hidden]="isIntrRedmMcalDvcaType">
                <app-form-item [field]="fields.partialRedemptionRate" [focus]="partialRedemptionRate">
                  <input class="form-control" type="text" [formControlName]="fields.partialRedemptionRate"
                    [placeholder]="placeholder(fields.partialRedemptionRate) | translate" component
                    #partialRedemptionRate />
                </app-form-item>
              </div>
            </div>
            <div class="row gy-2 mt-1" [hidden]="displayDenominationDetails">
              <div class="col-md-3">
                <app-form-item [field]="fields.denominationAsString" [focus]="denominationAsString" readonly>
                  <input class="form-control" type="text" [formControlName]="fields.denominationAsString"
                    [placeholder]="placeholder(fields.denominationAsString) | translate" component #denominationAsString
                    readonly [style.color]="denominationValueMismatched" />
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.denominationCurrency" [focus]="denominationCurrency">
                  <input class="form-control" type="text" [formControlName]="fields.denominationCurrency"
                    [placeholder]="placeholder(fields.denominationCurrency) | translate" component #denominationCurrency
                    [style.color]="denominationCcyMismatched" readonly />
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.amountPerDenomination" [focus]="amountPerDenomination">
                    <app-number #amountPerDenomination component [formControlName]="fields.amountPerDenomination" [formatThousandSeparator]='true'
                    placeholder="{{ placeholder(fields.amountPerDenomination) | translate }}">
                  </app-number>
                </app-form-item>
              </div>
              <div class="col-md-3">
                <app-form-item [field]="fields.paymentCurrency">
                  <input *ngIf=!multiplePmtCurrenciesExists class="form-control" type="text" [formControlName]="fields.paymentCurrency"
                    [placeholder]="placeholder(fields.paymentCurrency) | translate" component
                    #paymentCurrency />
                  <select *ngIf=multiplePmtCurrenciesExists class="form-control" type="text" [formControlName]="fields.paymentCurrency" component
                    #paymentCurrency>
                    <option [label]="paymentCcy" *ngFor="let paymentCcy of paymentCcys" [value]="paymentCcy">
                      {{paymentCcy}}
                    </option>
                  </select>
                </app-form-item>  
                <div class="error-message" *ngIf=multiplePmtCurrenciesExists>
                  <span translate>events.creation.multiPaymentCurrency</span>
                </div>
              </div>
            </div>
            <div class="row gy-2 mt-1">
              <div class="col-md-3">
                <app-form-item [field]="fields.narrative" [focus]="narrative">
                  <input class="form-control" type="text" [formControlName]="fields.narrative"
                    [placeholder]="placeholder(fields.narrative) | translate" component #narrative />
                </app-form-item>
              </div>
              <div class="col-md-3" [hidden]="isEventDvcaType">
                <app-form-item [field]="fields.forexRate" [focus]="forexRate">
                  <input class="form-control" type="text" [formControlName]="fields.forexRate"
                    [placeholder]="placeholder(fields.forexRate) | translate" component #forexRate />
                </app-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header border-top" id="positions">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePositions"
            aria-expanded="false" aria-controls="collapsePositions">
            <em class="icon icon-sm me-2 text-secondary">
              keyboard_arrow_right
            </em>
            <span translate>events.form.positions</span>
          </button>
        </h5>
      </div>
      <div id="collapsePositions" class="collapse show" aria-labelledby="positions">
        <div class="card-body">
          <div class="section-forms" [formGroup]="formGroup">
            <div>
              <app-position-container (displaySwiftMessage)="displaySwiftMessage($event)" (displayPoolFactor)="displayPoolFactor($event)" (displayEcashPayments)="displayEcashPayments($event)" (positionsGridData)="positionsGridData($event)" [positionData]="positionData" [eventPositionInput]="eventPositionInput" [pageType]="pageType" *ngIf="positionsLoaded| async"></app-position-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="displaySwifts">
      <div class="card-header border-top" id="swifts">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSwifts"
            aria-expanded="false" aria-controls="collapseSwifts">
            <em class="icon icon-sm me-2 text-secondary">
              keyboard_arrow_right
            </em>
            <span translate>events.form.notificationList</span>
          </button>
        </h5>
      </div>
      <div id="collapseSwifts" class="collapse show" aria-labelledby="swifts">
        <div class="card-body" style="padding-top: 0rem;">
          <div class="section-forms" [formGroup]="formGroup">
            <div>
              <app-swifts-container [swiftData]="swiftData"></app-swifts-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="displayPoolFactors">
      <div class="card-header border-top" id="poolFactorDetail">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsePoolFactorDetail"
            aria-expanded="false" aria-controls="collapsePoolFactorDetail">
            <em class="icon icon-sm me-2 text-secondary">
              keyboard_arrow_right
            </em>
            <span translate>events.form.poolFactorDetail</span>
          </button>
        </h5>
      </div>
      <div id="collapsePoolFactorDetail" class="collapse show" aria-labelledby="PoolFactorDetail">
        <div class="card-body" style="padding-top: 0rem;">
          <div class="section-forms" [formGroup]="formGroup">
            <div>
              <app-pool-factor-container [poolFactorData]="poolFactorData"></app-pool-factor-container>
              </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="card" *ngIf="displayEcashPayment">
      <div class="card-header border-top" id="eCashPaymentDetail">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseECashPaymentDetail"
            aria-expanded="false" aria-controls="collapseECashPaymentDetail">
            <em class="icon icon-sm me-2 text-secondary">
              keyboard_arrow_right
            </em>
            <span translate>events.form.eCashPaymentDetail</span>
          </button>
        </h5>
      </div>
      <div id="collapseECashPaymentDetail" class="collapse show" aria-labelledby="eCashPaymentDetail">
        <div class="card-body" style="padding-top: 0rem;">
          <div class="section-forms" [formGroup]="formGroup">
            <div>
              <app-ecash-payments-container [eCashPaymentsData]="eCashPaymentsData"></app-ecash-payments-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
