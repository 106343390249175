import { Injectable } from '@angular/core';
import { SettlementModel } from '@instruments/models/settlement.model';
import { SETTLEMENT_ACTIONS } from '@shared/models/settlement-action.model';
import { notEmpty } from '@utils/utility-functions';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class SettlementActionsService {
  public allowedActions$ = new BehaviorSubject<SETTLEMENT_ACTIONS[]>([]);
  public staActions = [
    ['INITIALIZED', ['DELIVERY_AND_SECURITIES_CREATION_AND_PAYMENT_TO_BE_VALIDATED']],

    ['IN REPAIR', ['DELIVERY_AND_SECURITIES_CREATION_AND_PAYMENT_TO_BE_VALIDATED', 'CANCEL']],

    ['DELIVERY SECURITIES PAYMENT TO BE VALIDATED', ['VALIDATE', 'REJECT', 'CANCEL']],

    ['DELIVERY TO BE VALIDATED', ['VALIDATE']],

    ['SECURITIES TO BE VALIDATED', ['VALIDATE']],

    ['PAYMENT TO BE VALIDATED', ['VALIDATE']],

    ['SECURITIES VALIDATED', ['CANCEL']],

    ['DELIVERY VALIDATED', ['CANCEL']],

    ['PAYMENT VALIDATED', []],

    ['CANCELLED', []]
  ];

  public spiActions = [
    ['INITIALIZED', ['DELIVERY_TO_BE_VALIDATED', 'SECURITIES_CREATION_TO_BE_VALIDATED']],

    ['IN REPAIR', ['DELIVERY_TO_BE_VALIDATED', 'SECURITIES_CREATION_TO_BE_VALIDATED', 'PAYMENT_TO_BE_VALIDATED', 'CANCEL']],

    ['DELIVERY TO BE VALIDATED', ['VALIDATE', 'REJECT', 'CANCEL']],

    ['DELIVERY VALIDATED', ['SECURITIES_CREATION_TO_BE_VALIDATED', 'PAYMENT_TO_BE_VALIDATED', 'CANCEL']],

    ['SECURITIES TO BE VALIDATED', ['VALIDATE', 'REJECT', 'CANCEL']],

    ['SECURITIES VALIDATED', ['PAYMENT_TO_BE_VALIDATED', 'CANCEL']],

    ['PAYMENT TO BE VALIDATED', ['VALIDATE', 'REJECT', 'CANCEL', 'SECURITIES_VALIDATED']],

    ['PAYMENT VALIDATED', []],

    ['CANCELLED', []]
  ];
  public canPerformAction(selectedRows: SettlementModel[], action: SETTLEMENT_ACTIONS, type: InstrumentOrigin): boolean {
    const selectedStatuses = selectedRows.map(q => q.issuingSettlementStatus?.valueDescription).filter(notEmpty);
    const actions = (type === 'SPI' ? this.spiActions : this.staActions).filter(a => selectedStatuses.includes(a[0] as string));
    return actions.every(a => (a[1] as string[]).includes(action));
  }
  public onAllowedActions(selectedRows: SettlementModel[], type: InstrumentOrigin): void {
    const selectedStatuses = selectedRows.map(q => q.issuingSettlementStatus?.valueDescription).filter(notEmpty);
    const actions = (type === 'SPI' ? this.spiActions : this.staActions).filter(a => selectedStatuses.includes(a[0] as string));
    let intersection = actions.length > 0 && actions[0].length > 0 ? (actions[0][1] as SETTLEMENT_ACTIONS[]) : [];
    for (const action of actions) {
      intersection = [...new Set([...intersection.filter(value => (action[1] as SETTLEMENT_ACTIONS[]).includes(value))])];
    }
    this.allowedActions$.next(intersection);
  }
}
