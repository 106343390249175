import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FormService } from '@shared/services/forms/form.service';
import { CustomValidators } from '@shared/validators/custom-validator';
import { RegenerateScheduleCriteriaModel } from '@events/models/regenerateScheduleCriteria.model';

@Injectable({
  providedIn: 'root'
})
export class RegenerateSchedulesFormService extends FormService<RegenerateScheduleCriteriaModel> {
  constructor(fb: UntypedFormBuilder) {
    super('event', fb, {
      isin: fb.control(null, [Validators.required, CustomValidators.Isin])
    });
  }
}
