/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Content } from './content';
import { DocumentVersionIdentifier } from './documentVersionIdentifier';


export interface Doc { 
    documentVersionIdentifier?: DocumentVersionIdentifier;
    confidentialityLevel?: string;
    date?: string;
    metadata?: { [key: string]: object; };
    content?: Content;
    file?: Blob;
    contentType?: string;
}

