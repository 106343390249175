import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GridOptions, ColDef, GridApi, SelectionChangedEvent, FirstDataRenderedEvent } from 'ag-grid-community';
import { EventColumnDefService } from './_aggrid/event-column-def-service';
import { ScheduleEventGridViewModel } from '@events/models';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  providers: [EventColumnDefService]
})
export class EventsListComponent implements OnInit {
  @Input() public scheduledEvents: ScheduleEventGridViewModel[] = [];
  @Output() selectedISIN = new EventEmitter<string>();

  public columnDefs: ColDef[] = [];
  public defaultColDef: any;
  public gridOptions!: GridOptions;
  public gridApi!: GridApi;
  public rowCount = 0;

  constructor(private readonly eventColumnDefService: EventColumnDefService) {}

  ngOnInit(): void {
    this.columnDefs = this.eventColumnDefService.get();
    this.gridOptions = {
      onFirstDataRendered: this.onFirstDataRendered,
      onGridSizeChanged: () => {
        this.gridOptions.api?.sizeColumnsToFit();
      },
      onColumnVisible: () => {
        this.columnResize();
      },
      onToolPanelVisibleChanged: () => {
        this.columnResize();
      },
      pagination: false,
      suppressPaginationPanel: false,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      suppressContextMenu: true,
      stopEditingWhenCellsLoseFocus: true
    };

    this.defaultColDef = {
      sortable: true,
      filter: false,
      suppressMenu: true,
      resizable: true,
      floatingFilterComponentParams: { suppressFilterButton: true }
    };
  }

  public sideBar: any = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        minWidth: 225,
        width: 225,
        maxWidth: 225,
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true
        }
      }
    ],
    defaultToolPanel: ''
  };

  onGridReady(params: any) {
    const gridApi = params.api;
    this.gridApi = params.api;
    gridApi.sizeColumnsToFit();
    gridApi.addGlobalListener(this.resizeColumn);
    gridApi.refreshHeader();
    this.rowCount = this.gridApi.getDisplayedRowCount();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent): void {
    params.api.sizeColumnsToFit();
  }

  private columnResize() {
    setTimeout(() => {
      this.gridOptions.api?.sizeColumnsToFit();
    });
  }

  private readonly resizeColumn = (event: any) => {
    if (['gridSizeChanged', 'toolPanelVisibleChanged', 'columnVisible', 'rowDataChanged'].includes(event)) {
      this.columnResize();
    }
  };

  onSelectionChanged(event: SelectionChangedEvent) {
    const selectedRows = event.api.getSelectedRows();
    if (selectedRows.length === 0) {
      this.rowCount = this.gridApi.getDisplayedRowCount();
    }
    this.selectedISIN.emit(selectedRows[0].isinReference);
  }

  public get displayCsvDownload(): boolean {
    return this.rowCount > 0;
  }
}
