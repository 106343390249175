<div class="section-forms" [formGroup]="formGroup">
  <div class="row">
    <div class="form-group col-md-6">
      <app-form-item [focus]="certifiedNominal" [field]="fields.certifiedNominal"
        [labelAddendum]="certifiedNominalType">
        <app-number #issuePrice [formControlName]="fields.certifiedNominal"
          [placeholder]="placeholder(fields.certifiedNominal) | translate" component #certifiedNominal></app-number>
      </app-form-item>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-6">
      <app-form-item [field]="fields.partiallyCertified" [focus]="partiallyCertifiedInput">
        <input class="ms-2" type="checkbox" component [formControlName]="fields.partiallyCertified"
          (ngModelChange)="disableCertifiedNominal()" #partiallyCertifiedInput />
      </app-form-item>
    </div>
  </div>
</div>
