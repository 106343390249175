import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import { routeToCommonDep, routeToSettlement, routeToTranche } from '@instruments/routes';
import { INSTRUMENT_CONSTANTS } from '@instruments/configs/instrument-constants';
import { PermissionService } from '@shared/services/permission.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-instrument-action',
  templateUrl: './instrument-action.component.html',
  styleUrls: ['./instrument-action.component.scss']
})
export class InstrumentActionComponent implements OnInit {
  params: ICellRendererParams | undefined;
  public canReadCommonDep!: boolean;
  private readonly shutdown$ = new Subject<void>();

  constructor(public readonly permissionService: PermissionService, private readonly router: Router) {}

  ngOnInit(): void {
    this.permissionService.canReadCommonDep$.pipe(takeUntil(this.shutdown$)).subscribe({
      next: (canCreateOperation: boolean) => {
        this.canReadCommonDep = canCreateOperation;
      }
    });
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  canGoToCommonDep(): boolean {
    return (
      this.canReadCommonDep &&
      (this.params?.data.commonDepositaryName === INSTRUMENT_CONSTANTS.Parties.commonDepositary.SOCIETEGENERALELUXEMBOURG || this.params?.data.commonSafeKeeperName === INSTRUMENT_CONSTANTS.Parties.commonDepositary.SOCIETEGENERALELUXEMBOURG)
    );
  }

  goToDetail(): void {
    this.router.navigate(routeToTranche(this.params?.data.idInstrument, this.params?.data.trancheNumber));
  }
  goToSettlement(): void {
    this.router.navigate(routeToSettlement(this.params?.data.idInstrument, this.params?.data.trancheNumber));
  }
  goToCommonDep(): void {
    this.router.navigate(routeToCommonDep(this.params?.data.idInstrument));
  }
}
