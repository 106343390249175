import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { Component } from '@angular/core';

@Component({
  selector: 'app-statut-badge',
  template: '<span class="{{ color }}">{{ status| uppercase  }}</span>',
  styles: ['button { width: 100%; }']
})
export class ProgrammeListStatusComponent implements ICellRendererAngularComp {
  status: 'active' | 'inactive' = 'inactive';
  colors = { active: 'success', inactive: 'warning' };
  color = '';

  agInit(params: ICellRendererParams): void {
    this.status = params.value?.toLowerCase();
    this.color = `badge badge-discreet-${this.colors[this.status]} badge-pill`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
