import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sgs-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('content-class') contentClasses: string | undefined;
  @Output() sgsModalHidden = new EventEmitter<boolean>();

  public shown = false;
  public visibleAnimate = false;

  public show(): void {
    this.shown = true;
    setTimeout(() => (this.visibleAnimate = true), 100);
  }

  public hide(): void {
    this.visibleAnimate = false;
    /* No timeout, to avoid having this timeout polute the shown field, in the case of a show() call right after a hide() call. */
    this.shown = false;
    this.sgsModalHidden.emit(true);
  }

  public onContainerClicked(event: MouseEvent): void {
    if ((event.target as HTMLElement).classList.contains('modal')) {
      this.hide();
    }
  }
}

// https://stackoverflow.com/a/40144809
