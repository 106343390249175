/**
 * issuer-ipa-events-api API
 * Manage events data
 *
 * The version of the OpenAPI document: 1.3.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Request of Funds to create
 */
export interface RequestOfFundsSearchDto { 
    eventRefIds?: Array<number>;
    isins?: Array<string>;
    eventTypeValueIds?: Array<number>;
    minAmount?: number;
    maxAmount?: number;
    valueDateFrom?: string;
    valueDateTo?: string;
}

