import { Injectable } from '@angular/core';
import { AgGridColumnDefBaseService } from '@shared/services/aggrid-column-def-base-service';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { INSTRUCTIONS_COLUMNS } from './instructions-column-def';

@Injectable()
export class InstructionColumnDefService extends AgGridColumnDefBaseService {
  constructor(translateService: I18nUtilsService) {
    super(translateService, 'instructions.list.headers', INSTRUCTIONS_COLUMNS);
  }
}
