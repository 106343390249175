import { Injectable } from '@angular/core';
import { InstrumentResourceService } from '@apis/backend/instruments';
import { MatrixInstrumentTypeIcsdResponseModel, ToMatrixInstrumentTypeIcsdResponseModel } from '@instruments/models/matrix-instrument-type-icsd-response.model';

import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClearingSystemsConfigsService {
  private readonly _loadedConfiges: [string, string, MatrixInstrumentTypeIcsdResponseModel[]][] = [];
  constructor(private readonly instrumentResourceService: InstrumentResourceService) {}

  public getConfig(isin: string | null | undefined, type: string | null | undefined): Observable<MatrixInstrumentTypeIcsdResponseModel[]> {
    if (!isin || !type || isin.length < 2) {
      return of([]);
    }
    const isin2FirstDigits = isin.substring(0, 2);
    const config = this._loadedConfiges.find(q => q[0] === isin2FirstDigits && q[1] === type);
    if (config) {
      return of(config[2]);
    }
    return this.instrumentResourceService.getMatrixInstrumentTypeIcsd({ instrumentTypeCode: type, isin2FirstDigits: isin2FirstDigits }).pipe(
      map(r => (r.resources ? r.resources?.map(dto => ToMatrixInstrumentTypeIcsdResponseModel(dto)) : [])),
      tap(result => {
        if (result.some(_ => true)) {
          this._loadedConfiges.push([isin2FirstDigits, type, result]);
        }
      })
    );
  }
}
