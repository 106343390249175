import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormService } from '@shared/services/forms/form.service';

import { ListingModel, InstrumentForms } from '@instruments/models';

@Injectable()
export class ListingFormService extends FormService<ListingModel, InstrumentForms> {
  constructor(fb: UntypedFormBuilder) {
    super('listing', fb, {
      listingCountry: fb.control(null),
      listingPlace: fb.control(null),
      publiclySold: fb.control(false)
    });
  }
}
