import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { InstrumentAddendumModel, InstrumentForms } from '@instruments/models';
import { FormService } from '@shared/services/forms/form.service';

@Injectable({
  providedIn: 'root'
})
export class InstrumentAddendumFormService extends FormService<InstrumentAddendumModel, InstrumentForms> {
  constructor(fb: UntypedFormBuilder) {
    super('addendum', fb, {
      id: fb.control(null),
      idWorkflow: fb.control(null),
      status: fb.control(null),
      trancheStatus: fb.control(null),
      selectedTrancheNumber: fb.control(null),
      tranches: fb.control([]),
      lastUpdateDateTimeAsString: fb.control(null),
      isInternalIssuer: fb.control(false)
    });
  }
}
