import { SearchPaymentsConfirmation, PaymentsConfirmationDto, UpdatePaymentsConfirmation } from '@apis/backend/payments';

export interface PaymentDetailModel {
  paymentId?: number;
  isin?: string;
  eventType?: string;
  requestOfFundsId?: number;
  eventId?: number;
  paymentConfReceiptDate?: string;
  valueDate?: string;
  amount?: number;
  paymentCcy?: string;
  cancelled?: string;
  paymentStatus?: string;
  paymentRejectReason?: string;
  comments?: string;
  paymentReference?: number;
  settlementMode?: string;
  adviceNumber?: string;
  orderingInstitutation?: string;
  dealIdentifier?: string;
  markReference?: string;
  details?: string;
  beneficiary?: string;
  backOffice?: string;
}

export type PaymentType =
  | 'isin'
  | 'eventType'
  | 'requestOfFundsId'
  | 'eventId'
  | 'paymentConfReceiptDate'
  | 'valueDate'
  | 'amount'
  | 'paymentCcy'
  | 'cancelled'
  | 'paymentRejectReason'
  | 'paymentReference'
  | 'settlementMode'
  | 'adviceNumber'
  | 'orderingInstitutation'
  | 'dealIdentifier'
  | 'markReference'
  | 'details'
  | 'beneficiary'
  | 'backOffice';

export const PaymentFields: readonly PaymentType[] = [
  'isin',
  'eventType',
  'requestOfFundsId',
  'eventId',
  'paymentConfReceiptDate',
  'valueDate',
  'amount',
  'paymentCcy',
  'cancelled',
  'paymentRejectReason',
  'paymentReference',
  'settlementMode',
  'adviceNumber',
  'orderingInstitutation',
  'dealIdentifier',
  'markReference',
  'details',
  'beneficiary',
  'backOffice'
];
export const toPaymentDetailsSearchDto = (id: number): SearchPaymentsConfirmation => {
  return {
    paymentIds: [id]
  };
};

enum PaymentStatus {
  'AWAIT NEW PAYMENT' = 7,
  'REGULARIZED' = 8,
  'PAYMENT PENDING' = 5
}

export const toPaymentStatusUpdateDto = (paymentRef: number, paymentStatus?: string | undefined, remarks?: string | undefined): UpdatePaymentsConfirmation[] => {
  let status: any;
  switch (paymentStatus) {
    case 'AWAIT NEW PAYMENT':
      status = PaymentStatus['AWAIT NEW PAYMENT'];
      break;
    case 'REGULARIZED':
      status = PaymentStatus['REGULARIZED'];
      break;
    case 'PAYMENT_PENDING':
      status = PaymentStatus['PAYMENT PENDING'];
      break;
  }
  return [
    {
      paymentId: paymentRef,
      paymentStatusValueId: status,
      paymentRemarks: remarks
    }
  ];
};

export const setPaymentDetails = (paymentDetail: PaymentsConfirmationDto | null): PaymentDetailModel | null => {
  if (paymentDetail == null) {
    return null;
  }
  return {
    isin: paymentDetail.isin,
    eventType: paymentDetail.eventType?.valueDescription,
    requestOfFundsId: paymentDetail.reqofFundsId,
    eventId: paymentDetail.eventId,
    paymentConfReceiptDate: paymentDetail.receiptDate,
    valueDate: paymentDetail.valueDate,
    amount: paymentDetail.amount,
    paymentCcy: paymentDetail.currency,
    cancelled: paymentDetail.isCancelled ? 'Y' : 'N',
    paymentStatus: paymentDetail.paymentStatus?.valueDescription,
    paymentRejectReason: paymentDetail.paymentRejectReason,
    comments: paymentDetail.paymentRemarks,
    paymentReference: paymentDetail.id,
    settlementMode: paymentDetail.settlementMode,
    adviceNumber: paymentDetail.adviceNumber,
    orderingInstitutation: paymentDetail.orderingInstitution,
    dealIdentifier: paymentDetail.dealIdentifier,
    markReference: paymentDetail.markReference,
    details: paymentDetail.comments,
    beneficiary: paymentDetail.beneficiary,
    backOffice: paymentDetail.backOffice
  };
};
