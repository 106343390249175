/**
 * issuer-ipa-events-extended API
 * Manage eventss data
 *
 * The version of the OpenAPI document: 1.1.15
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CreateEventPositionEntitlementRequest } from '../model/models';
import { EventPositionEntitlementResponse } from '../model/models';
import { EventPositionSearchCriteriaDto } from '../model/models';
import { PartialPaymentInputDto } from '../model/models';
import { PartialPaymentResponseDto } from '../model/models';
import { PositionBatchCreationResponseDto } from '../model/models';
import { Problem } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class EventsPositionResourceService {

    protected basePath = 'https://issuer-ipa-events-extended-develop.dev.socgen';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * create
     * Create Event Position  **Authorization policy:**  Scopes: api.issuer-ipa-events-extended.v1, api.issuer-ipa-events-extended.position.create; Authorities: SGM_ISS_PAY_api.issuer-ipa-events-extended.position.create
     * @param createEventPositionEntitlementRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createEventPositions(createEventPositionEntitlementRequest: CreateEventPositionEntitlementRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<EventPositionEntitlementResponse>;
    public createEventPositions(createEventPositionEntitlementRequest: CreateEventPositionEntitlementRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<EventPositionEntitlementResponse>>;
    public createEventPositions(createEventPositionEntitlementRequest: CreateEventPositionEntitlementRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<EventPositionEntitlementResponse>>;
    public createEventPositions(createEventPositionEntitlementRequest: CreateEventPositionEntitlementRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (createEventPositionEntitlementRequest === null || createEventPositionEntitlementRequest === undefined) {
            throw new Error('Required parameter createEventPositionEntitlementRequest was null or undefined when calling createEventPositions.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<EventPositionEntitlementResponse>(`${this.configuration.basePath}/v1/event-position-entitlements`,
            createEventPositionEntitlementRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Batch
     * Create Event Position in batch  **Authorization policy:**  Scopes: api.issuer-ipa-events-extended.v1, api.issuer-ipa-events-extended.create
     * @param type Batch Type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createEventPositionsInBatch(type: 'SGLux' | 'NonSGLux', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<PositionBatchCreationResponseDto>;
    public createEventPositionsInBatch(type: 'SGLux' | 'NonSGLux', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<PositionBatchCreationResponseDto>>;
    public createEventPositionsInBatch(type: 'SGLux' | 'NonSGLux', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<PositionBatchCreationResponseDto>>;
    public createEventPositionsInBatch(type: 'SGLux' | 'NonSGLux', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling createEventPositionsInBatch.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>type, 'type');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PositionBatchCreationResponseDto>(`${this.configuration.basePath}/v1/event-position-entitlements/_batch`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete
     * Deleting EventPosition   **Authorization policy:**  Scopes: api.issuer-ipa-events-extended.v1, api.issuer-ipa-events-extended.position.delete; Authorities: SGM_ISS_PAY_api.issuer-ipa-events-extended.position.delete
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePositions(requestBody: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<EventPositionEntitlementResponse>;
    public deletePositions(requestBody: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<EventPositionEntitlementResponse>>;
    public deletePositions(requestBody: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<EventPositionEntitlementResponse>>;
    public deletePositions(requestBody: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling deletePositions.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<EventPositionEntitlementResponse>(`${this.configuration.basePath}/v1/event-position-entitlements/do-delete`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Partial Payment
     * Partial Payment  **Authorization policy:**  Scopes: api.issuer-ipa-events-extended.v1, api.issuer-ipa-events-extended.position.partial-payment; Authorities: SGM_ISS_PAY_api.issuer-ipa-events-extended.position.partial-payment
     * @param partialPaymentInputDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public partialPayments(partialPaymentInputDto: PartialPaymentInputDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<PartialPaymentResponseDto>;
    public partialPayments(partialPaymentInputDto: PartialPaymentInputDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<PartialPaymentResponseDto>>;
    public partialPayments(partialPaymentInputDto: PartialPaymentInputDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<PartialPaymentResponseDto>>;
    public partialPayments(partialPaymentInputDto: PartialPaymentInputDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (partialPaymentInputDto === null || partialPaymentInputDto === undefined) {
            throw new Error('Required parameter partialPaymentInputDto was null or undefined when calling partialPayments.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PartialPaymentResponseDto>(`${this.configuration.basePath}/v1/event-position-entitlements/partialPayments`,
            partialPaymentInputDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * search
     * Search for Event positions  **Authorization policy:**  Scopes: api.issuer-ipa-events-extended.v1, api.issuer-ipa-events-extended.position.read; Authorities: SGM_ISS_PAY_api.issuer-ipa-events-extended.position.read
     * @param eventPositionSearchCriteriaDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchForEventPositions(eventPositionSearchCriteriaDto: EventPositionSearchCriteriaDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<EventPositionEntitlementResponse>;
    public searchForEventPositions(eventPositionSearchCriteriaDto: EventPositionSearchCriteriaDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<EventPositionEntitlementResponse>>;
    public searchForEventPositions(eventPositionSearchCriteriaDto: EventPositionSearchCriteriaDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<EventPositionEntitlementResponse>>;
    public searchForEventPositions(eventPositionSearchCriteriaDto: EventPositionSearchCriteriaDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (eventPositionSearchCriteriaDto === null || eventPositionSearchCriteriaDto === undefined) {
            throw new Error('Required parameter eventPositionSearchCriteriaDto was null or undefined when calling searchForEventPositions.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<EventPositionEntitlementResponse>(`${this.configuration.basePath}/v1/event-position-entitlements/_search`,
            eventPositionSearchCriteriaDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
