import { Component, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ICellRendererParams } from 'ag-grid-community';
import { routeToEvent } from '@events/routes';
import { Router } from '@angular/router';
import { EventModalsService, EventDetailFormService, EventFormService } from '@events/services';
import { EventsService } from '@shared/services/events.service';
import { PermissionService } from '@shared/services/permission.service';
import { EVENT_CONSTANTS as E } from '@events/configs/event-constants';
import { EventModel, EventDetailModel } from '@events/models';

@Component({
  selector: 'app-event-action',
  templateUrl: './event-action.component.html',
  providers: [EventModalsService]
})
export class EventActionComponent implements OnDestroy {
  params: ICellRendererParams | undefined;
  eventsStatus: string[] = [E.status.fixingDateToBeReached, E.status.eventToBeInput, E.status.valuationInRepair, E.status.valuationChased, E.status.eventToBeValidated, E.status.vamNotificationToBeSent];
  syncStatus = [E.status.fixingDateToBeReached, E.status.eventToBeInput, E.status.valuationInRepair];
  isDisabled = true;
  canDeleteEvents = false;
  public shutdown$ = new Subject<void>();
  eventDates: Nullable<EventModel> | undefined;
  constructor(
    private readonly eventModalsService: EventModalsService,
    private readonly eventService: EventsService,
    private readonly router: Router,
    private readonly permissionService: PermissionService,
    public eventDetailFormService: EventDetailFormService,
    public eventFormService: EventFormService
  ) {}
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.permissionService.canDeleteEvents$.pipe(takeUntil(this.shutdown$)).subscribe({
      next: (canDeleteEvents: boolean) => {
        this.canDeleteEvents = canDeleteEvents;
      }
    });
    if (this.eventsStatus.includes(this.params.data.status.valueDescription)) {
      this.isDisabled = false;
    }
  }

  onDeleteEventClick() {
    const modal = this.eventModalsService.openDeleteEventModal();
    const selectedData = [this.params?.node.data];
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        return;
      }
      this.confirmDeleteEvent(selectedData);
    });
  }

  confirmDeleteEvent(selectedData: any[]) {
    this.eventService.deleteEvent(selectedData[0].eventReference).subscribe(() => {
      this.params?.api.applyTransaction({ remove: selectedData });
    });
  }

  goToEventDetail(): void {
    if (this.syncStatus.includes(this.params?.data.status.valueDescription)) {
      this.router.navigate(routeToEvent(this.params?.data.eventReference, 'consult'), { state: this.params?.data.instrumentId });
    } else {
      this.router.navigate(routeToEvent(this.params?.data.eventReference, 'consult'));
    }
  }

  updateEventDates() {
    const selectedData = [this.params?.node.data];
    const modal = this.eventModalsService.openDatesModal(selectedData);
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        return;
      }
      this.eventDates = this.eventFormService.rawValue();
      this.eventService.updateScheduleDates(this.eventDates as EventDetailModel).subscribe(result => {
        selectedData[0].beginDate = result.schedules[0].beginDate;
        selectedData[0].endDate = result.schedules[0].endDate;
        selectedData[0].valueDate = result.schedules[0].valueDate;
        selectedData[0].notificationDate = result.schedules[0].notificationDate;
        this.params?.api.applyTransaction({ update: selectedData });
      });
    });
  }

  ngOnDestroy(): void {
    this.shutdown$.next();
  }
}
