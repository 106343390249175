import { TypedDataModel, PartyModel, IpaTypeModel } from '@shared/models';

export interface EventCriteriaSearchModel {
  isin?: string;
  ipaCode?: number[];
  eventId?: number[];
  issuerPerimter?: string;
  eventTypes?: TypedDataModel[];
  eventStatuses?: TypedDataModel[] | IpaTypeModel[];
  notificationFromDate?: string;
  notificationToDate?: string;
  reqFundFromDate?: string;
  reqFundToDate?: string;
  preAdviceFromDate?: string;
  preAdviceToDate?: string;
  valueFromDate?: string;
  valueToDate?: string;
  issuerName?: PartyModel[];
  pageNumber?: number;
  paymentCurrencies?: string[];
}
