/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-namespace */

import { notEmpty } from '@utils/utility-functions';

export namespace BooleanRules {
  export const isFalse = (value: boolean | null | undefined) => !value;
  export const isTrue = (value: boolean | null | undefined) => !!value;
  export const isDefined = <T>(value: T | null | undefined): value is T => notEmpty(value) && (!Array.isArray(value) || value.length > 0);
  export const isUndefined = <T>(value: T | null | undefined): value is null | undefined => !isDefined(value);
}
