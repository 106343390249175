/* eslint-disable @typescript-eslint/no-namespace */
import { Injectable } from '@angular/core';
import { EventsDataModel } from '@instruments/models';
import { IpaTypeModelRules as R, BooleanRules as B } from '@instruments/rules';
import { DisplayService } from '@shared/services/display/display.service';
import { CharacteristicFormService, EventsDataFormService } from '@instruments/services';

export namespace EventDataFormSection {
  export const firstCouponTypeFields = 'firstCouponTypeFields';
  export const secondCouponTypeFields = 'secondCouponTypeFields';
  export const correlatedFields = 'correlatedFields';
  export const decorrelatedFields = 'decorrelatedFields';
  export const recordDateFields = 'recordDateFields';
  export const redemptionManagementFields = 'redemptionManagementFields';
  export const partialRedemptionFields = 'partialRedemptionFields';
}

@Injectable()
export class EventsDataDisplayService extends DisplayService<EventsDataModel> {
  constructor(fs: EventsDataFormService, characteristicFormService: CharacteristicFormService) {
    super(fs);

    this.defineSection(EventDataFormSection.firstCouponTypeFields)
      .toHaveFields(
        'interestCommencementDate',
        'couponPaymentCurrencies',
        'couponFrequency',
        'dayCountFraction',
        'endDateAdjusted',
        'interestRate',
        'calculationTime',
        'calculationOffset',
        'paymentDateCorrelated',
        'businessDayConvention',
        'secondCouponType',
        'recordDatesCalculationDates'
      )
      .and.haveSubSections(EventDataFormSection.recordDateFields, EventDataFormSection.secondCouponTypeFields, EventDataFormSection.correlatedFields, EventDataFormSection.decorrelatedFields);

    this.defineSection(EventDataFormSection.recordDateFields).toHaveFields('recordDateOffset', 'recordDateCalculationMode', 'recordDateReference');
    this.defineSection(EventDataFormSection.correlatedFields).toHaveFields('firstCouponPayDate', 'businessDayConvention');
    this.defineSection(EventDataFormSection.decorrelatedFields).toHaveFields('firstEndPeriodDate', 'paymentDateOffset', 'paymentDateMgtMode');

    this.defineSection(EventDataFormSection.secondCouponTypeFields).toHaveFields('secondDayCountFraction', 'secondCouponPayDate');

    this.defineSection(EventDataFormSection.redemptionManagementFields)
      .toHaveFields('maturityDate', 'redemptionValuationDate', 'redemptionPrice', 'redeemableInSecurities', 'partialRedemption')
      .and.haveSubSections(EventDataFormSection.partialRedemptionFields);

    this.defineSection(EventDataFormSection.partialRedemptionFields).toHaveFields('partialRedemptCurrencies', 'partialRedemptionType', 'partialRedemptionCorrelatedToCoupon');

    this.describe('couponType').rules(
      {
        when: ({ couponType }) => R.isNotZeroCoupon(couponType),
        displaySections: EventDataFormSection.firstCouponTypeFields
      },
      {
        when: ({ couponType }) => R.isCouponFixed(couponType),
        displayFields: 'interestRate'
      }
    );

    this.describe('paymentDateCorrelated').rules({
      when: ({ paymentDateCorrelated }) => B.isTrue(paymentDateCorrelated),
      displaySections: EventDataFormSection.correlatedFields,
      else: {
        displaySections: EventDataFormSection.decorrelatedFields
      }
    });

    this.describe('secondCouponType').rules(
      {
        when: ({ secondCouponType }) => B.isDefined(secondCouponType),
        displaySections: EventDataFormSection.secondCouponTypeFields
      },
      {
        when: ({ secondCouponType }) => R.isCouponFixed(secondCouponType),
        displayFields: 'secondInterestRate'
      }
    );

    this.describe('recordDatesCalculationDates').rules({
      when: ({ recordDatesCalculationDates }) => !B.isDefined(recordDatesCalculationDates),
      displaySections: EventDataFormSection.recordDateFields
    });

    this.describe('perpetual').rules({
      when: ({ perpetual }) => B.isFalse(perpetual),
      displaySections: EventDataFormSection.redemptionManagementFields
    });

    this.describe('partialRedemption').rules({
      when: ({ partialRedemption }) => B.isTrue(partialRedemption),
      displaySections: EventDataFormSection.partialRedemptionFields
    });

    this.with(characteristicFormService, 'instrumentType').rules({
      when: ({ instrumentType }) => R.isWarrantType(instrumentType),
      displayFields: 'optionExerciseStyle'
    });
  }
}
