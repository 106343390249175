import { FormService } from '@shared/services/forms/form.service';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { conditionalValidator } from '@shared/utils';
import { CustomValidators } from '@shared/validators/custom-validator';
import { PartyModel, ProgrammeInformation } from '@shared/models';
import { PartyService } from '@shared/services/party.service';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { ProgrammeModel } from '@shared/models/programme.model';
import { ErrorMessageForm } from '@shared/validators/error-message-form.utils';

@Injectable({
  providedIn: 'root'
})
export class ProgrammeFormService extends FormService<ProgrammeModel> {
  private readonly programmeInformationSubject = new BehaviorSubject<ProgrammeInformation | null>(null);
  constructor(fb: UntypedFormBuilder, private readonly partyService: PartyService) {
    super('programme', fb, {
      id: fb.control(null),
      issuer: fb.control(null, Validators.required),
      issuerLeiCode: fb.control(null, Validators.required),
      icsdProgrammeNumber: fb.control(null),
      programmeName: fb.control(null, [Validators.required, Validators.maxLength(255)]),
      issuingAgents: fb.control([], Validators.required),
      principalPayingAgents: fb.control([], Validators.required),
      commonDepositaries: fb.control([], Validators.required),
      registrars: fb.control([]),
      eurosystemEligibility: fb.control(false),
      commonSafekeepers: fb.control(
        [],
        CustomValidators.RequiredIf<ProgrammeModel, 'eurosystemEligibility'>('eurosystemEligibility', value => !!value)
      ),
      fiscalAgents: fb.control([]),
      localPayingAgents: fb.control(
        [],
        conditionalValidator(() => this.requiredLocalPayingAgents(), Validators.required)
      ),
      listingAgents: fb.control([], Validators.required),
      // Competent Authorities
      competentAuthorities: fb.control(
        [],
        conditionalValidator(() => this.requiredCompetentAuthorities(), Validators.required)
      ),
      authorityProgrammeIdentifier: fb.control(null, [conditionalValidator(() => this.requiredAuthorityProgrammeIdentifier(), Validators.required), Validators.minLength(6)]),
      authorityProgrammeIdentifierDate: fb.control(null, [conditionalValidator(() => this.requiredAuthorityProgrammeIdentifier(), CustomValidators.ValidateRequiredDate)]),
      oldAuthorityProgrammeIdentifier: fb.control(null),
      oldAuthorityProgrammeIdentifierDate: fb.control(null, CustomValidators.ValidateIsDate),
      transferAgents: fb.control([]),
      calculationAgent: fb.control(null),
      status: fb.control(null),
      idWorkflow: fb.control(null)
    });
  }

  public setProgrammeInformation(programmeInformation: ProgrammeInformation | null) {
    if (programmeInformation) {
      this.patch('issuer', {
        leiCode: programmeInformation.leiCode,
        bdrId: programmeInformation.bdrId,
        name: programmeInformation.fullLegalName
      });
    } else {
      this.patch('issuer', null);
    }
    this.programmeInformationSubject.next(programmeInformation);
  }

  public get programmeInformation$(): Observable<ProgrammeInformation | null> {
    return this.programmeInformationSubject.asObservable();
  }
  private _formErrors: Record<string, unknown> = {};

  private _notApplicableParty: PartyModel | null = null;
  private _otherParty: PartyModel | null = null;
  private _sgLuxParty: PartyModel | null = null;
  private _competentAuthCSSFParty: PartyModel | null = null;

  public get notApplicableParty(): PartyModel | null {
    return this._notApplicableParty;
  }
  public get otherParty(): PartyModel | null {
    return this._otherParty;
  }
  public get sgLuxParty(): PartyModel | null {
    return this._sgLuxParty;
  }
  public get competentAuthCSSFParty(): PartyModel | null {
    return this._competentAuthCSSFParty;
  }

  public get formErrors() {
    return this._formErrors;
  }

  requiredLocalPayingAgents(): boolean {
    const principalPayingAgents: PartyModel[] = this.rawValue('principalPayingAgents') || [];
    const commonDepositaries: PartyModel[] = this.rawValue('commonDepositaries') || [];

    const isSGLUX = Object.values<PartyModel>(principalPayingAgents).some((a: PartyModel) => a?.leiCode === this._sgLuxParty?.leiCode);
    const isNotApplicable = !!commonDepositaries && JSON.stringify(commonDepositaries) === JSON.stringify(this._notApplicableParty);

    return isSGLUX && isNotApplicable;
  }
  requiredCompetentAuthorities(): boolean {
    const listingAgents = this.rawValue('listingAgents') || [];
    return Object.values<PartyModel>(listingAgents).some((a: PartyModel) => a?.leiCode === this._sgLuxParty?.leiCode);
  }
  requiredAuthorityProgrammeIdentifier(): boolean {
    const competentAuthorities = this.rawValue('competentAuthorities') || [];
    return Object.values<PartyModel>(competentAuthorities).some((a: PartyModel) => a?.leiCode === this._competentAuthCSSFParty?.leiCode);
  }

  retrievePartyByLeiCode(shutdown$: Observable<unknown>): void {
    combineLatest([
      this.partyService.getPartyByLeiCode(this.partyService.leiCodeNotApplicable),
      this.partyService.getPartyByLeiCode(this.partyService.leiCodeOther),
      this.partyService.getPartyByLeiCode(this.partyService.leiCodeSGLUX),
      this.partyService.getPartyByLeiCode(this.partyService.leiCodeCSSFLUXREGMARKET)
    ])
      .pipe(takeUntil(shutdown$))
      .subscribe(([notApplicableParty, otherParty, sgLuxParty, competentAuthCSSFParty]) => {
        this._notApplicableParty = notApplicableParty;
        this._otherParty = otherParty;
        this._sgLuxParty = sgLuxParty;
        this._competentAuthCSSFParty = competentAuthCSSFParty;
      });
  }

  requiredCommonSafekeeper(): boolean {
    return !!this.rawValue('eurosystemEligibility');
  }

  checkErrors(shutdown$: Observable<unknown>) {
    this.valueChanges()
      .pipe(takeUntil(shutdown$))
      .subscribe(() => {
        this._formErrors = ErrorMessageForm.getValidationErrors(this.formGroup);
      });
  }

  refreshErrors() {
    this._formErrors = ErrorMessageForm.getValidationErrors(this.formGroup);
  }

  public loadProgrammeInformation(leiCode: string) {
    this.partyService.getIssuerBdrDataFromLeiCode(leiCode).pipe(map(progInfo => this.programmeInformationSubject.next(progInfo)));
  }

  initFormWithStoredData(programme: ProgrammeModel | null) {
    this.setInitialValue(programme);
    this.resetCSSFProgrammeNumber(programme);
  }

  resetCSSFProgrammeNumber(programme: ProgrammeModel | null) {
    if (programme && programme.authorityProgrammeIdentifier) {
      this.patch('authorityProgrammeIdentifier', programme.authorityProgrammeIdentifier.includes('C-') ? programme.authorityProgrammeIdentifier.split('C-')[1] : programme.authorityProgrammeIdentifier);
    }
  }
}
