import { Component, OnDestroy, OnInit } from '@angular/core';
import { InstrumentCriteriaSearchModel } from '@instruments/models';
import { InstrumentCriteriaSearchFormService } from '@instruments/services';
import { InstrumentFilterSelectorService } from '@instruments/services/instrument-filter-selector.service';
import { FilterSelectorBaseComponent } from '@shared/search-base/filter-selector-base.component/filter-selector-base.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-instrument-filter-selector',
  templateUrl: '../../../../shared/search-base/filter-selector-base.component/filter-selector-base.component.html',
  styleUrls: ['../../../../shared/search-base/filter-selector-base.component/filter-selector-base.component.scss']
})
export class InstrumentFilterSelectorComponent extends FilterSelectorBaseComponent<InstrumentCriteriaSearchModel> implements OnInit, OnDestroy {
  public readonly shutdown$ = new Subject<void>();
  constructor(instrumentCriteriaSearchFormService: InstrumentCriteriaSearchFormService, instrumentFilterSelectorService: InstrumentFilterSelectorService) {
    super(instrumentCriteriaSearchFormService, instrumentFilterSelectorService, 'instrument-filters', 'instruments.list.selector');
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.filterSelectorService.$currentFilters.pipe(takeUntil(this.shutdown$)).subscribe(q => {
      this.filters = q;
    });
  }
  ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}
