/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SettlementModel } from '@instruments/models/settlement.model';

import { InstrumentFormService } from '@instruments/services';
import { ProgrammeModel } from '@shared/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-instrument-forms',
  templateUrl: './instrument-forms.component.html',
  styleUrls: ['./instrument-forms.component.scss']
})
export class InstrumentFormsComponent implements OnInit, OnDestroy {
  @Input() programme: ProgrammeModel | null = null;
  public tab: InstrumentSelectorTabType = 'Tranche';
  public shutdown$ = new Subject<void>();
  firstTab = 'none';
  settlementTab = 'none';
  firstContent = 'none';
  settlementContent = 'none';
  public instrumentId?: number;
  public settlemnts: SettlementModel[] = [];

  constructor(readonly instrumentForm: InstrumentFormService, readonly activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.instrumentForm.currentTabType$.pipe(takeUntil(this.shutdown$)).subscribe(tab => (this.tab = tab));
    const instrument = this.instrumentForm.rawValue();
    this.instrumentId = instrument?.id || 1;

    this.activatedRoute.params.pipe(takeUntil(this.shutdown$)).subscribe((params: Params) => {
      if (params['tab'] && (params['tab'] as InstrumentFormsTabType) === 'settlements') {
        this.settlementTab = 'active';
        this.firstTab = 'none';
        this.firstContent = 'none';
        this.settlementContent = 'show active';
      } else {
        this.firstTab = 'active';
        this.settlementTab = 'none';
        this.firstContent = 'show active';
        this.settlementContent = 'none';
      }
    });
  }

  get showSettlementTab(): boolean {
    return this.instrumentForm.formMode === 'consult';
  }

  showSettlementIcon(): boolean {
    return this.settlemnts.length > 0;
  }

  public get isConsultMode() {
    return this.instrumentForm.formMode === 'consult';
  }
  public get hasError() {
    return this.instrumentForm.hasError.bind(this.instrumentForm);
  }

  ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}
