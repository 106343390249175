import { Directive } from '@angular/core';
import { PermissionService } from '@shared/services/permission.service';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from '@shared/services/forms/form.service';
import { UnionFormService } from '@shared/services/forms/union.form.service';
import { PagingBaseModel } from './paging-base.model';
import { PagingCriteriaFormService } from '@shared/search-base/paging-criteria-form.service';
import { SearchContainerWithPaginationBaseComponent } from './search-container-with-pagination-base.component';

@Directive()
// eslint-disable-next-line max-len, prettier/prettier
export abstract class SearchContainerWithSaveFiltersBaseComponent<
  M,
  C,
  QM extends Partial<UnionToIntersection<PC | C>> | null,
  PC extends PagingBaseModel,
  PagingtWithCriteriaFormService extends PagingCriteriaFormService<PC>,
  CriteriaSearchFormService extends FormService<C>,
  UnionSearchFormService extends UnionFormService<[C, PC]>
> extends SearchContainerWithPaginationBaseComponent<M, C, QM, PC, PagingtWithCriteriaFormService, CriteriaSearchFormService, UnionSearchFormService> {
  protected readonly shutdown$ = new Subject<void>();
  public models: M[] = [];
  protected searchCriteria: C | null | undefined;
  constructor(
    pagingFormService: PagingtWithCriteriaFormService,
    criteriaFormService: CriteriaSearchFormService,
    permissionService: PermissionService,
    searchUnionFormService: UnionSearchFormService,
    activatedRoute: ActivatedRoute,
    router: Router,
    _itemsByPage: number,
    baseUrl: string | string[]
  ) {
    super(pagingFormService, criteriaFormService, permissionService, searchUnionFormService, activatedRoute, router, _itemsByPage, baseUrl);
  }

  protected abstract saveFilters(): void;
}
