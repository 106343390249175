import { PaymentsConfirmationResourceService, PaymentConfirmationUpdateResponseDTO, SearchPaymentsConfirmation, PaymentConfPagingResultDto } from '@apis/backend/payments';
import { Injectable } from '@angular/core';
import { PaymentCriteriaSearchModel, toSearchCriteriaInputDto, PaymentDetailModel, toPaymentDetailsSearchDto, setPaymentDetails, toPaymentStatusUpdateDto, toCsvCriteriaInputDto } from 'src/app/payment/models';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(private readonly paymentService: PaymentsConfirmationResourceService, private readonly toastManagerService: ToastManagerService) {}

  getPayments(serachCriteria: PaymentCriteriaSearchModel, pageNumber: number, itemsByPage: number): Observable<PaymentConfPagingResultDto> {
    const dto = toSearchCriteriaInputDto(serachCriteria, pageNumber, itemsByPage);
    return this.paymentService.searchPaymentConfirmationWithPagination(dto);
  }

  updatePaymentStatus(paymentRef: number, paymentStatus?: string, remarks?: string) {
    const paymentStatusUpdateDto = toPaymentStatusUpdateDto(paymentRef, paymentStatus, remarks);
    return this.paymentService.updatePaymentConfirmation(paymentStatusUpdateDto).pipe(
      tap((result: PaymentConfirmationUpdateResponseDTO) => {
        if (result) {
          this.toastManagerService.toastSuccess('toasts.payment.update.title', 'toasts.payment.update.message', undefined, result);
        }
      })
    );
  }

  getPaymentDetail(payment: number): Observable<PaymentDetailModel | null> {
    const dto = toPaymentDetailsSearchDto(payment);
    if (!dto) {
      return of(null);
    }

    return this.paymentService.searchPaymentConfirmation(dto).pipe(map(response => setPaymentDetails(response.paymentsConfirmationLists ? response.paymentsConfirmationLists[0] : null)));
  }

  exportPaymentsData(pagingRequestWithCriteria: SearchPaymentsConfirmation): Observable<Blob | null> {
    const dto = toCsvCriteriaInputDto(pagingRequestWithCriteria);
    if (!dto) {
      return of(null);
    }
    return this.paymentService.csvReportPaymentConfirmation(dto);
  }
}
