import { Component, OnInit, Input } from '@angular/core';
import { PoolFactorColumnDefService } from '../_aggrid/pool-factor-column-def-service';
import { ColDef, GridOptions, GridApi } from 'ag-grid-community';
import { toPoolFactorModal, PoolFactorGridModel } from '@events/models';
import { PoolFactorHistoryResponseListDto } from '@apis/backend/events';

@Component({
  selector: 'app-pool-factor-container',
  templateUrl: './pool-factor-container.component.html',
  providers: [PoolFactorColumnDefService]
})
export class PoolFactorContainerComponent implements OnInit {
  @Input() public poolFactorData?: PoolFactorHistoryResponseListDto;
  public columnDefs: ColDef[] = [];
  public gridOptions!: GridOptions;
  public gridApi!: GridApi;
  public poolFactorRowData: PoolFactorGridModel[] | undefined;

  constructor(private readonly poolFactorColumnDefService: PoolFactorColumnDefService) {}

  ngOnInit(): void {
    this.fetchPoolFactor();
    this.columnDefs = this.poolFactorColumnDefService.get();
    this.gridOptions = {
      onGridSizeChanged: () => {
        this.gridOptions.api?.sizeColumnsToFit();
      },
      pagination: true,
      paginationPageSize: 10,
      suppressPaginationPanel: false,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      suppressContextMenu: true,
      stopEditingWhenCellsLoseFocus: true
    };
  }

  onGridReady(params: { api: GridApi }): void {
    this.columnDefs = this.poolFactorColumnDefService.get();
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.refreshHeader();
  }

  public fetchPoolFactor() {
    const rowData: PoolFactorGridModel[] = [];
    this.poolFactorData?.factorHistorySearchResponseDtos?.forEach((poolFactor: PoolFactorGridModel) => {
      const poolFactors = toPoolFactorModal(poolFactor);
      rowData.push(poolFactors);
      this.poolFactorRowData = rowData;
    });
  }

  exportPoolFactorCsv() {
    this.gridOptions.api?.exportDataAsCsv();
  }
}
