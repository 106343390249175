import { Component } from '@angular/core';
import { SettlementModel } from '@instruments/models/settlement.model';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-settlement-edit-action',
  templateUrl: './settlement-edit-action.component.html'
})
export class SettlementEditActionComponent {
  params: ICellRendererParams | undefined;

  public get deleted(): boolean {
    return (this.params?.data as SettlementModel).toBeDeleted;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  public delete(): void {
    const row = this.params?.data as SettlementModel;
    const api = this.params?.api;
    if (row.id) {
      row.toBeDeleted = true;
      api?.redrawRows();
    } else {
      api?.applyTransaction({ remove: [row] });
    }
  }
  public restore(): void {
    const row = this.params?.data as SettlementModel;
    const api = this.params?.api;
    if (row.id) {
      row.toBeDeleted = false;
      api?.redrawRows();
    }
  }
}
