<div class="section-forms container g-0" [formGroup]="formGroup">
  <div class="row gy-4">
    <div class="col-md-6">
      <app-form-item [focus]="tradingMethodIdentifier" [field]="fields.tradingMethodIdentifier">
        <app-type-multiple-select [type]="TRADING_METHOD" [formControlName]="fields.tradingMethodIdentifier" component
          #tradingMethodIdentifier [multiple]="false" [closeOnSelect]="true" [withDebounce]="true"
          [hasError]="hasError(fields.tradingMethodIdentifier)" bindLabelProperty="valueDescription"
          [placeholder]="placeholder(fields.tradingMethodIdentifier) | translate">
        </app-type-multiple-select>
      </app-form-item>
    </div>
    <div class="col-md-6">
      <app-form-item [focus]="syndicated" [field]="fields.syndicated">
        <app-yes-no-buttons [formControlName]="fields.syndicated" component #syndicated></app-yes-no-buttons>
      </app-form-item>
    </div>
  </div>
  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item [focus]="minimumTrading" [field]="fields.minimumTrading">
        <app-number #minimumTrading [formControlName]="fields.minimumTrading"
          [placeholder]="placeholder(fields.minimumTrading) | translate" component></app-number>
      </app-form-item>
    </div>
  </div>
  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item [focus]="nominalCurrency" [field]="fields.nominalCurrency">
        <app-currency-multiple-select #nominalCurrency [formControlName]="fields.nominalCurrency"
          [placeholder]="placeholder(fields.nominalCurrency) | translate" component>
        </app-currency-multiple-select>
      </app-form-item>
    </div>
    <div class="col-md-6">
      <app-form-item [focus]="settlementCurrency" [field]="fields.settlementCurrency">
        <app-currency-multiple-select [formControlName]="fields.settlementCurrency"
          [placeholder]="placeholder(fields.settlementCurrency) | translate" component #settlementCurrency>
        </app-currency-multiple-select>
      </app-form-item>
    </div>
  </div>
  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item [focus]="maximumNominal" [field]="fields.maximumNominal">
        <app-number [formControlName]="fields.maximumNominal"
          [placeholder]="placeholder(fields.maximumNominal) | translate" component #maximumNominal></app-number>
      </app-form-item>
    </div>
  </div>
  <hr class="mb-5 mt-5" />
  <div class="row gy-4">
    <div class="col-md-6">
      <app-form-item [focus]="nominal" [field]="fields.nominal">
        <app-number [formControlName]="fields.nominal" [placeholder]="placeholder(fields.nominal) | translate" component
          #nominal></app-number>
      </app-form-item>
    </div>
    <div class="col-md-6 ps-3">
      <app-form-item [focus]="denomination" [field]="fields.denomination">
        <app-number [formControlName]="fields.denomination" [placeholder]="placeholder(fields.denomination) | translate"
          component #denomination></app-number>
      </app-form-item>
    </div>
  </div>
  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item [focus]="numberOfShares" [field]="fields.numberOfShares">
        <app-number [formControlName]="fields.numberOfShares"
          [placeholder]="placeholder(fields.numberOfShares) | translate" #numberOfShares component></app-number>
      </app-form-item>
    </div>
    <div class="col-md-6 ps-3">
      <app-form-item [focus]="issuePrice" [field]="fields.issuePrice" labelAddendum="label">
        <app-number #issuePrice [formControlName]="fields.issuePrice" [appendLabel]="issuePriceAppend"
          [addOnParams]="issuePriceTranslateParams" [placeholder]="placeholder(fields.issuePrice) | translate"
          component></app-number>
      </app-form-item>
    </div>
  </div>
  <div class="row gy-4 mt-1">
    <div class=" col-md-6">
      <app-form-item [focus]="outstandingNominal" [field]="fields.outstandingNominal"
        [labelAddendum]="outstandingNominalType">
        <app-number #issuePrice [formControlName]="fields.outstandingNominal"
          [placeholder]="placeholder(fields.outstandingNominal) | translate" component #outstandingNominal></app-number>
      </app-form-item>
    </div>
  </div>
  <div *ngIf="isConsultMode && displayPoolFactors">
    <hr class="mb-5 mt-5" />
    <app-collapse [hide]="false" title="{{ 'instruments.instrument.forms.poolFactor.title' | translate }}">
      <app-pool-factor-container [poolFactorData]="poolFactorData"></app-pool-factor-container>
    </app-collapse>
  </div>
  
</div>