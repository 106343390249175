import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { InstrumentService } from '@shared/services/instrument.service';
import { InstrumentModel } from '@instruments/models/instrument.model';
import { map, mergeMap } from 'rxjs/operators';
import { ProgrammeService } from '@shared/services/programme.service';

import { ProgrammeModel } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class InstrumentResolve implements Resolve<[InstrumentModel | null, ProgrammeModel | null]> {
  constructor(private readonly service: InstrumentService, private readonly programmeService: ProgrammeService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<[InstrumentModel | null, ProgrammeModel | null]> {
    const id = route.params['id'];
    const trancheNumber = route.params['trancheNumber'];

    return this.service.getInstrumentById(id, trancheNumber).pipe(
      mergeMap(instrument => {
        const programmeId = instrument?.internalProgrammeNumber || route.params.internalProgrammeNumber || null;
        return this.programmeService.getProgrammeById(programmeId).pipe(map(programme => [instrument, programme] as [InstrumentModel | null, ProgrammeModel | null]));
      })
    );
  }
}
