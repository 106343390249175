import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appFractional]'
})
export class FractionalNumberDirective {
  @Input() numberType: 'decimal' | 'integer' = 'decimal';
  private readonly specialKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private readonly el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): boolean {
    const allowedKeys = this.numberType === 'decimal' ? [...this.specialKeys, '.'] : this.specialKeys;
    if (event.ctrlKey || allowedKeys.indexOf(event.key) !== -1) {
      return true;
    }

    const testValue = +event.key;
    if (isNaN(testValue)) {
      event.preventDefault();
      return false;
    }

    return true;
  }
}
