import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-document-form-item',
  templateUrl: './document-form-item.component.html'
})
export class DocumentFormItemComponent {
  @Input() label!: string;
  @Input() focus: unknown;
  @Input() control!: AbstractControl;
  @Input() required = false;
}
