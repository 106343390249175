import { TypedDataModel } from '@shared/models';
import { SearchPaymentsConfirmation, SearchPaymentConfPaginationDto } from '@apis/backend/payments';

export interface PaymentCriteriaSearchModel {
  isin?: string;
  eventTypes?: TypedDataModel[];
  paymentStatus?: TypedDataModel[];
  minAmount?: number;
  maxAmount?: number;
  currency?: string;
  fundingRequestRef?: string;
  valueFromDate?: string;
  valueToDate?: string;
  pageNumber?: number;
  itemsByPage?: number;
}

export const toSearchCriteriaInputDto = (searchCriteria: PaymentCriteriaSearchModel, pageNumber: number, itemsByPage: number): SearchPaymentConfPaginationDto => {
  return {
    pageNumber: pageNumber,
    itemsByPage: itemsByPage,
    criteria: {
      isinLists: searchCriteria?.isin ? Array.from(new Array(searchCriteria.isin)) : undefined,
      requestOfFundsIds: searchCriteria.fundingRequestRef ? [Number(searchCriteria.fundingRequestRef)] : undefined,
      statusValueIds: convertToNumberArray(searchCriteria?.paymentStatus),
      eventTypeValueIds: convertToNumberArray(searchCriteria.eventTypes),
      valueFromDate: searchCriteria?.valueFromDate ? toDateFormat(searchCriteria?.valueFromDate) : undefined,
      valueToDate: searchCriteria?.valueToDate ? toDateFormat(searchCriteria?.valueToDate) : undefined,
      currency: searchCriteria.currency || undefined,
      minAmount: searchCriteria.minAmount,
      maxAmount: searchCriteria.maxAmount || undefined
    }
  };
};

export const toCsvCriteriaInputDto = (searchCriteria: PaymentCriteriaSearchModel): SearchPaymentsConfirmation => {
  return {
    isinLists: searchCriteria?.isin ? Array.from(new Array(searchCriteria.isin)) : undefined,
    requestOfFundsIds: searchCriteria.fundingRequestRef ? [Number(searchCriteria.fundingRequestRef)] : undefined,
    statusValueIds: convertToNumberArray(searchCriteria?.paymentStatus),
    eventTypeValueIds: convertToNumberArray(searchCriteria.eventTypes),
    valueFromDate: searchCriteria?.valueFromDate ? toDateFormat(searchCriteria?.valueFromDate) : undefined,
    valueToDate: searchCriteria?.valueToDate ? toDateFormat(searchCriteria?.valueToDate) : undefined,
    currency: searchCriteria.currency || undefined,
    minAmount: searchCriteria.minAmount,
    maxAmount: searchCriteria.maxAmount || undefined
  };
};

const convertToNumberArray = (event: TypedDataModel[] | null | undefined): number[] | undefined => {
  const arr: number[] = [];
  event?.forEach(element => {
    arr.push(Number(element.valueId));
  });
  return arr.length > 0 ? arr : undefined;
};

const toDateFormat = (dateString: string): string => {
  const [day, month, year] = dateString.split('-');
  return `${year}-${month}-${day}`;
};
