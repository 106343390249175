/**
 * issuer-ipa-events-api API
 * Manage events data
 *
 * The version of the OpenAPI document: 1.3.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SecheduleSearchInput } from './secheduleSearchInput';


/**
 * Primary id of a Events
 */
export interface SearchSchedulePaginationDto { 
    /**
     * Requested page number.
     */
    pageNumber?: number;
    /**
     * Number of items per page.
     */
    itemsByPage?: number;
    criteria?: SecheduleSearchInput;
}

