import { PartyModel, toPartyModel } from '@shared/models/party.model';
import { MouvementModel, toMouvementModel } from './mouvement.model';
import { MovementOfPositionResultDto } from '@apis/backend/instruments';

export interface MovementOfPositionResultModel {
  instrumentId?: number;
  isinReference?: string;
  seriesNumber?: string;
  currency?: string;
  icsdParty?: PartyModel | null;
  depositaryParty?: PartyModel | null;
  movements?: MouvementModel[];
}
export const toMovementOfPositionResultModel = (dto: MovementOfPositionResultDto): MovementOfPositionResultModel => {
  return {
    icsdParty: toPartyModel(dto.icsdParty),
    depositaryParty: toPartyModel(dto.depositaryParty),
    isinReference: dto.isinReference,
    seriesNumber: dto.seriesNumber,
    currency: dto.currency,
    instrumentId: dto.instrumentId,
    movements: dto.movementDtos?.map(mdto => toMouvementModel(mdto))
  };
};
