<div *ngIf="items$ | async as items">
  <app-ttd-items-selector
    [(ngModel)]="value"
    [data]="items"
    [singleSelect]="!multiple"
    [isReadOnly]="isReadOnly"
    [icon]="icon"
    (focusout)="onBlurEmit()"
    [labelProperties]="['valueDescription']"
    [withSummary]="withSummary"
    [maxDisplayedItems]="maxDisplayedItems"
    [placeHolder]="placeholder"
    key="valueId"
    labelPath="valueDescription">
    <div readonlyTemplate><span class="me-1" *ngFor="let val of displayValue">{{val.valueDescription}}</span></div>
  </app-ttd-items-selector>
</div>