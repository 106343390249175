/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IpaTypeDto } from './ipaTypeDto';


export interface InstrumentGlobalNoteDto { 
    globalNoteForm?: IpaTypeDto;
    legalForm?: IpaTypeDto;
    physicalForm?: IpaTypeDto;
    globalNoteClassic?: boolean;
    regs144LinkedInstrument?: string;
}

