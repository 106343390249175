import { ColDef } from 'ag-grid-community/dist/lib/main';
import { dateFormatter } from './instrument-settlement-column-def-edit';
export interface DisposalColumnDef extends ColDef {
  headerName: string;
  children: any[];
}

export const DISPOSAL_COLUMNS: DisposalColumnDef[] = [
  {
    headerName: 'header',
    children: [
      {
        field: 'icsd',
        width: 340,
        resizable: true,
        editable: false
      },
      {
        field: 'receptionDate',
        valueFormatter: dateFormatter,
        width: 360,
        resizable: true,
        editable: false
      }
    ]
  }
];
