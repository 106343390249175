import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { InstructionModel } from '../model/instruction.model';
import { InstructionService } from '../services/instruction.service';
import { InstructionColumnDefService } from '../services/instructions-column-def-service';

@Component({
  selector: 'app-instructions-container',
  templateUrl: './instructions-container.component.html',
  styleUrls: ['./instructions-container.component.scss'],
  providers: [InstructionColumnDefService]
})
export class InstructionsContainerComponent implements OnInit {
  subscriptions: Subscription[] = [];
  instructions: InstructionModel[] = [];
  public columnDefs: ColDef[] = [];
  constructor(private readonly instructionService: InstructionService, private readonly instructionColumnDefService: InstructionColumnDefService) {}
  ngOnInit(): void {
    this.subscriptions.push(this.instructionService.get().subscribe(r => (this.instructions = r)));
    this.columnDefs = this.instructionColumnDefService.get();
  }
}
