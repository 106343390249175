/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Criteria for search Instruments
 */
export interface ProgrammeCriteriaSearchDto { 
    /**
     * List of Issuer name for programme
     */
    issuerNames?: Array<string>;
    /**
     * Name of programme
     */
    programmeName?: string;
    /**
     * ICSD programme number
     */
    icsd?: string;
    /**
     * Status of programme
     */
    status?: string;
}

