import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormService } from '@shared/services/forms/form.service';
import { AuditDetailModel } from '@events/models';

@Injectable({
  providedIn: 'root'
})
export class AuditDetailFormService extends FormService<AuditDetailModel> {
  constructor(fb: UntypedFormBuilder) {
    super('event', fb, {
      eventValidatedBy: fb.control(null),
      eventValidatedAt: fb.control(null),
      eventSentforValidationBy: fb.control(null)
    });
  }
}
