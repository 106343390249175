<app-header (goBack)="saveAndClose.onCancelClick()" [moveElementDown]="bodyContainer">
  <div headerButton>
    <button *ngIf="canCloseInstrument" class="btn btn-md btn-flat-primary" (click)="onCloseInstrument()"
      (keyup.enter)="onCloseInstrument()">
      <span class="title-case" translate>instruments.buttons.closeInstrument</span>
    </button>
  </div>
  <div headerBody>
    <h1 *ngIf="isAddMode" class="display-3 mb-0" translate>instruments.new-instrument</h1>
    <ng-container *ngIf="!isAddMode">
      <div class="ps-2 my-2">
        <app-instrument-header-info [programme]="programme"></app-instrument-header-info>
      </div>
      <app-tranche-selector></app-tranche-selector>
    </ng-container>
    <app-save-cancel-button-bar #saveAndClose="saveAndClose" [cameFromProgramPage]="cameFromProgramme" [programmeId]="programme?.id"></app-save-cancel-button-bar>
  </div>
</app-header>
<div class="bg-lvl2" #bodyContainer>
  <div class="container">
    <div class="row">
      <div class="pt-4 pb-4 mx-auto ps-1 pe-1" [ngClass]="{ 'col-md-8': isAddMode, 'col-md-12': !isAddMode }">
        <ng-container *ngIf="isAddMode">
          <app-instrument-forms></app-instrument-forms>
          <div class="section-forms" *ngIf="isAddMode">
            <h5 class="mb-4" translate>programmes.programme.forms.documentation</h5>
            <div class="row">
              <div class="col-md-12">
                <app-document-forms-list #documentFormsList [multiple]="true" [isEditMode]="true"> </app-document-forms-list>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isAddMode">
          <app-hide-and-show>
            <div left-side>
              <app-instrument-forms [programme]="programme"></app-instrument-forms>
            </div>
            <div *ngIf="!isAddMode" right-side>
              <div class="tab-content">
                <div id="documents" class="tab-pane" role="tabpanel" aria-labelledby="documents-tab" [ngClass]="{ active: activeTab === 'documents' }">
                  <app-documents-list *ngIf="instrument.id" [isLoading]="isLoadingDocument" [documents]="documents" [canDeleteFile]="isEditMode" [isEditMode]="isEditMode"></app-documents-list>
                </div>
              </div>
            </div>
          </app-hide-and-show>
        </ng-container>
      </div>
    </div>
  </div>
</div>
