import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormService } from '@shared/services/forms/form.service';
import { OperationSearchCriteriaModel } from '../models/operation-search-criteria.model';

@Injectable()
export class OperationCriteriaSearchFormService extends FormService<OperationSearchCriteriaModel> {
  constructor(fb: UntypedFormBuilder) {
    super('search_operation', fb, {
      ipaCode: fb.control(null),
      isin: fb.control(null),
      seriesNumber: fb.control(null),
      status: fb.control(null),
      operation: fb.control(null)
    });
  }
}
