/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, forwardRef, Input, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-yes-no-buttons',
  templateUrl: './yes-no-buttons.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => YesNoButtonsComponent),
      multi: true
    }
  ]
})
export class YesNoButtonsComponent implements ControlValueAccessor {
  @Input() yesLabel = 'Yes';
  @Input() noLabel = 'No';
  @Input() classes = '';
  @Output() onchange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public focusId: string | null = null;
  public internalValue = false;
  public isReadOnly = false;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateValue(newValue: boolean): void {
    this.value = newValue;
  }

  get value(): boolean {
    return this.internalValue;
  }

  set value(value: boolean) {
    this.internalValue = value;
    this.onChange(value);
    this.onTouched();
    this.onchange.emit(value);
  }

  onChange!: OnChangeFn<boolean>;

  onTouched!: OnTouchFn;

  registerOnChange(fn: OnChangeFn<boolean>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchFn): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isReadOnly = isDisabled;
  }

  writeValue(value: boolean): void {
    this.internalValue = value;
  }
}
