import { GnReceptionDataDto } from '@apis/backend/instruments';
import { DisposalModel, toDisposalItemsModel, toGnDisposalItemDto } from './disposal.model';
import { DocumentationReceptionModel } from './documentation-reception.model';
import { PermanentMonitoringModel, toPermanentMonitoringItemsModel, toPermanentMonitoringDataDto } from './permanent-monitoring.model';
import { notEmpty } from '@utils/utility-functions';

export type CommonDepositoriesArrayType = [DisposalModel, DocumentationReceptionModel, PermanentMonitoringModel];
export type CommonDepositoriesModel = UnionToIntersection<CommonDepositoriesArrayType[number]>;

export type CommonDepositoriesForms = 'rootForm_CommonDepository' | 'documentation_reception' | 'managment_common_depository';

export const toCommonDepositoryDataFetchModel = (instrumentId: number, model: GnReceptionDataDto | null | undefined): CommonDepositoriesModel => ({
  id: model?.id,
  instrumentId: instrumentId || model?.idInstrument,
  gnReceived: model?.isGnReceivedIndicator,
  ftReceived: model?.isFtReceivedIndicator,
  comment: model?.commonDepComment,
  disposalId: model?.gnDisposalDataDto?.id,
  archiveBoxNumber: model?.gnDisposalDataDto?.archiveBoxNumber,
  archiveDate: model?.gnDisposalDataDto?.archiveDateAsString,
  disposalItems: (model?.gnDisposalDataDto?.disposalItems || []).map(toDisposalItemsModel).filter(notEmpty),
  permanentMonitoringItems: (model?.permanentMonitoringDataDtos || []).map(toPermanentMonitoringItemsModel).filter(notEmpty)
});

export const toGnReceptionDataDto = (model: CommonDepositoriesModel | null | undefined): GnReceptionDataDto => {
  return {
    id: model?.id,
    idInstrument: model?.instrumentId,
    isGnReceivedIndicator: model?.gnReceived,
    isFtReceivedIndicator: model?.ftReceived,
    commonDepComment: model?.comment,
    gnDisposalDataDto: {
      id: model?.disposalId,
      archiveBoxNumber: model?.archiveBoxNumber,
      archiveDateAsString: model?.archiveDate,
      disposalItems: (model?.disposalItems || []).map(toGnDisposalItemDto).filter(notEmpty)
    },
    permanentMonitoringDataDtos: (model?.permanentMonitoringItems || []).map(toPermanentMonitoringDataDto).filter(notEmpty)
  };
};
