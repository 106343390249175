import { Injectable } from '@angular/core';
import { AgGridColumnDefBaseService } from '@shared/services/aggrid-column-def-base-service';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';

import { POSITIONS_COLUMNS } from './positions-column-def';

@Injectable()
export class PostionColumnDefService extends AgGridColumnDefBaseService {
  constructor(translateService: I18nUtilsService) {
    super(translateService, 'positions.list.headers', POSITIONS_COLUMNS);
  }
}
