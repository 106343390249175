/**
 * issuer-ipa-events-extended API
 * Manage eventss data
 *
 * The version of the OpenAPI document: 1.1.15
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface StatusUpdateUserOutputDto { 
    id?: number;
    typedData?: StatusUpdateUserOutputDto.TypedDataEnum;
    userUpdated?: string;
}
export namespace StatusUpdateUserOutputDto {
    export type TypedDataEnum = 'BUSINESS_DAY_CONVENTION' | 'CALCULATION_TIME' | 'COUPON_FREQUENCY' | 'COUPON_TYPE' | 'SECOND_COUPON_TYPE' | 'DAY_COUNT_FRACTION' | 'SECOND_DAY_COUNT_FRACTION' | 'GLOBAL_NOTE_FORM' | 'INSTRUMENT_REGULATION' | 'INSTRUMENT_TYPE' | 'LABEL_CATEGORY' | 'LEGAL_DESK_ISSUER_COUNTRY' | 'LEGAL_FORM' | 'LISTING_COUNTRY' | 'LISTING_PLACE' | 'OPTION_EXERCISE_TYPE' | 'PARTIAL_REDEMPTION_TYPE' | 'PAYMENT_DATE_MGT_MODE' | 'PHYSICAL_FORM' | 'RECORD_DATE_CALC_MODE' | 'RECORD_DATE_OFFSET_REF' | 'TRADING_METHOD' | 'INSTRUMENT_WORKFLOW_STATUS' | 'TRANCHE_WORKFLOW_STATUS' | 'INCREASE_WORKFLOW_STATUS' | 'FINANCIAL_CENTER_TRADING_PLACES' | 'EVENT_TYPE' | 'REDEMPTION_TYPE' | 'EVENT_WORKFLOW_STATUS' | 'VALUATION_STATUS' | 'SWIFT_MESSAGE_STATUS' | 'REQUEST_OF_FUNDS_STATUS' | 'SWIFT_MESSAGE_FUNCTION' | 'SWIFT_MESSAGE_TYPE' | 'PAYMENTS_CONFIRMATION_STATUS' | 'TRANSFER_MODE' | 'INTERVENTION_TYPE' | 'MX_MESSAGE_TYPE' | 'ECASH_PAYMENT_STATUS';
    export const TypedDataEnum = {
        BUSINESSDAYCONVENTION: 'BUSINESS_DAY_CONVENTION' as TypedDataEnum,
        CALCULATIONTIME: 'CALCULATION_TIME' as TypedDataEnum,
        COUPONFREQUENCY: 'COUPON_FREQUENCY' as TypedDataEnum,
        COUPONTYPE: 'COUPON_TYPE' as TypedDataEnum,
        SECONDCOUPONTYPE: 'SECOND_COUPON_TYPE' as TypedDataEnum,
        DAYCOUNTFRACTION: 'DAY_COUNT_FRACTION' as TypedDataEnum,
        SECONDDAYCOUNTFRACTION: 'SECOND_DAY_COUNT_FRACTION' as TypedDataEnum,
        GLOBALNOTEFORM: 'GLOBAL_NOTE_FORM' as TypedDataEnum,
        INSTRUMENTREGULATION: 'INSTRUMENT_REGULATION' as TypedDataEnum,
        INSTRUMENTTYPE: 'INSTRUMENT_TYPE' as TypedDataEnum,
        LABELCATEGORY: 'LABEL_CATEGORY' as TypedDataEnum,
        LEGALDESKISSUERCOUNTRY: 'LEGAL_DESK_ISSUER_COUNTRY' as TypedDataEnum,
        LEGALFORM: 'LEGAL_FORM' as TypedDataEnum,
        LISTINGCOUNTRY: 'LISTING_COUNTRY' as TypedDataEnum,
        LISTINGPLACE: 'LISTING_PLACE' as TypedDataEnum,
        OPTIONEXERCISETYPE: 'OPTION_EXERCISE_TYPE' as TypedDataEnum,
        PARTIALREDEMPTIONTYPE: 'PARTIAL_REDEMPTION_TYPE' as TypedDataEnum,
        PAYMENTDATEMGTMODE: 'PAYMENT_DATE_MGT_MODE' as TypedDataEnum,
        PHYSICALFORM: 'PHYSICAL_FORM' as TypedDataEnum,
        RECORDDATECALCMODE: 'RECORD_DATE_CALC_MODE' as TypedDataEnum,
        RECORDDATEOFFSETREF: 'RECORD_DATE_OFFSET_REF' as TypedDataEnum,
        TRADINGMETHOD: 'TRADING_METHOD' as TypedDataEnum,
        INSTRUMENTWORKFLOWSTATUS: 'INSTRUMENT_WORKFLOW_STATUS' as TypedDataEnum,
        TRANCHEWORKFLOWSTATUS: 'TRANCHE_WORKFLOW_STATUS' as TypedDataEnum,
        INCREASEWORKFLOWSTATUS: 'INCREASE_WORKFLOW_STATUS' as TypedDataEnum,
        FINANCIALCENTERTRADINGPLACES: 'FINANCIAL_CENTER_TRADING_PLACES' as TypedDataEnum,
        EVENTTYPE: 'EVENT_TYPE' as TypedDataEnum,
        REDEMPTIONTYPE: 'REDEMPTION_TYPE' as TypedDataEnum,
        EVENTWORKFLOWSTATUS: 'EVENT_WORKFLOW_STATUS' as TypedDataEnum,
        VALUATIONSTATUS: 'VALUATION_STATUS' as TypedDataEnum,
        SWIFTMESSAGESTATUS: 'SWIFT_MESSAGE_STATUS' as TypedDataEnum,
        REQUESTOFFUNDSSTATUS: 'REQUEST_OF_FUNDS_STATUS' as TypedDataEnum,
        SWIFTMESSAGEFUNCTION: 'SWIFT_MESSAGE_FUNCTION' as TypedDataEnum,
        SWIFTMESSAGETYPE: 'SWIFT_MESSAGE_TYPE' as TypedDataEnum,
        PAYMENTSCONFIRMATIONSTATUS: 'PAYMENTS_CONFIRMATION_STATUS' as TypedDataEnum,
        TRANSFERMODE: 'TRANSFER_MODE' as TypedDataEnum,
        INTERVENTIONTYPE: 'INTERVENTION_TYPE' as TypedDataEnum,
        MXMESSAGETYPE: 'MX_MESSAGE_TYPE' as TypedDataEnum,
        ECASHPAYMENTSTATUS: 'ECASH_PAYMENT_STATUS' as TypedDataEnum
    };
}


