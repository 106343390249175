import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  constructor() {
    super();
  }

  parse(value: string): NgbDateStruct | null {
    const testFormat = /^(\d{2}-){2}\d{4}$/;
    if (!testFormat.test(value)) {
      return null;
    }

    const dateMoment = moment(value, 'DD-MM-YYYY');
    if (!dateMoment.isValid) {
      return null;
    }

    return {
      year: dateMoment.year(),
      month: dateMoment.month() + 1,
      day: dateMoment.date()
    };
  }

  format(value: NgbDateStruct | null): string {
    if (!value) {
      return '';
    }
    const dateMoment = moment(new Date(value.year, value.month - 1, value.day));
    return dateMoment.format('DD-MM-yyyy');
  }
}
