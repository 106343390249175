<app-header containerType="container-fluid" [backButton]="false">
  <div headerBody class="d-flex flex-row justify-content-between mb-1 mt-1">
    <h1 class="display-3 line-height-1" translate [translateParams]="{ variable: 'Programmes' }">Programmes</h1>
    <div class="d-flex justify-content-end">
      <app-text-icon-button [customClasses]="'btn-icon-start'" [disabled]="disableSearch" *ngIf="canCreateProgramme" routerLink="{{ '/programme/' + pageType.add }}" icon="add"
        color="primary" label="New programme" size="md" buttonType="btn-primary"></app-text-icon-button>
    </div>
  </div>
</app-header>
<div class="softLoading" *ngIf="disableSearch">Loading</div>
<div class="container-fluid content-margin-top">
  <div class="row mt-2">
    <div class="col-md-12">
      <form (ngSubmit)="search()">
        <app-programme-list-filter [disableExportCsv]="disableSearch" [programmes]="models">
          <app-text-icon-button icon=" search" [disabled]="disableSearch" searchTemplate [submit]="true" [customClasses]="'btn-icon-start'"
            label="{{'programmes.list.search'|translate}}" size="md" buttonType="btn-outline-default">
          </app-text-icon-button>
        </app-programme-list-filter>
      </form>
    </div>
  </div>
  <app-programme-list [models]="models"></app-programme-list>
</div>
