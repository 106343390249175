/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * List of rules for dynamic forms in frontEnd
 */
export interface FormsRules { 
    key?: string;
    label?: string;
    order?: number;
    required?: boolean;
    controlType?: string;
    typeInput?: string;
    addClass?: string;
    addValidations?: { [key: string]: object; };
    options?: { [key: string]: object; };
}

