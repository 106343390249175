<div class="sticky">
  <div [ngClass]="containerType" >
    <div class="row">
      <div [className]="reducedMarge ? 'col-md-8 mx-auto' : 'col-md-12 mx-auto'">
        <div class="mb-1 bg-lvl-1">
          <div class="d-flex justify-content-between align-items-center">
            <ng-container *ngIf="backButton">
              <a href="javascript:void(0)" (click)="emitGoBack()" class="text-large btn-link d-inline-block pb-1"><em
                  class="icon">arrow_back</em><span translate> Back</span></a>
            </ng-container>
            <ng-content select="[headerButton]"></ng-content>
          </div>
          <ng-content select="[headerBody]"></ng-content>
          <ng-content select="[subheaderBody]"></ng-content>
        </div>
      </div>
    </div>
  </div>
  <hr class="my-0" />
</div>
