import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ItemsSelectorModule } from '../../../shared/components/items-selector';

import { CustomMultipleSelectModule } from '../custom-multiple-select/custom-multiple-select.module';
import { CurrencyMultipleSelectV2Component } from './v2/currency-multiple-select-v2.component';

@NgModule({
  declarations: [CurrencyMultipleSelectV2Component],
  exports: [CurrencyMultipleSelectV2Component],
  imports: [FormsModule, ReactiveFormsModule, CommonModule, CustomMultipleSelectModule, ItemsSelectorModule]
})
export class CurrencyMultipleSelectModule {}
