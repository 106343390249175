import { PartyModel, IpaTypeModel } from '@shared/models';
import { InstrumentModel } from '@instruments/models';
import { InstrumentDto } from '@apis/backend/instruments/model/instrumentDto';

declare type OptionalStringArray = string[] | null | undefined;

export interface InstrumentDataModel {
  readonly id: number | undefined;
  readonly issuer: PartyModel | null;
  readonly seriesNumber: string | null;
  readonly isinReference: string | null;
  readonly name: string | null;
  readonly instrumentStatus?: IpaTypeModel | null;
  readonly physicalForm?: IpaTypeModel | null;
  readonly commonDepositary?: PartyModel | null;
  readonly commonServiceProvider?: PartyModel | null;
  readonly listingAgent?: PartyModel | null;
  readonly principalPayingAgent?: PartyModel | null;
  readonly tradingMethodIdentifier?: IpaTypeModel | null;
  readonly partialRedemption?: boolean;
  readonly partialRedemptionType?: IpaTypeModel | null;
  readonly redeemableInSecurities?: boolean;
  readonly couponPaymentCurrencies?: OptionalStringArray;
  readonly redemptionCurrencies?: OptionalStringArray;
  readonly partialRedemptCurrencies?: OptionalStringArray;
  readonly clearingSystems?: PartyModel[] | null;
  readonly couponFrequency?: IpaTypeModel | null;
  readonly complianceRestriction?: boolean;
  readonly paymentDefault?: boolean;
}

export const toInstrumentDataModel = (dto: InstrumentModel | null | undefined): InstrumentDataModel | null => {
  if (!dto) {
    return null;
  }
  return {
    id: dto.id,
    issuer: dto.issuer,
    seriesNumber: dto.seriesNumber,
    isinReference: dto.isinReference,
    name: dto.name,
    commonDepositary: dto.commonDepositary,
    commonServiceProvider: dto.commonServiceProvider,
    physicalForm: dto.physicalForm,
    listingAgent: dto.listingAgent,
    principalPayingAgent: dto.principalPayingAgent,
    tradingMethodIdentifier: dto.tradingMethodIdentifier,
    partialRedemption: dto.partialRedemption,
    partialRedemptionType: dto.partialRedemptionType,
    redeemableInSecurities: dto.redeemableInSecurities,
    couponPaymentCurrencies: dto.couponPaymentCurrencies,
    redemptionCurrencies: dto.redemptionCurrencies,
    partialRedemptCurrencies: dto.partialRedemptCurrencies,
    clearingSystems: dto.clearingSystems,
    instrumentStatus: dto.status,
    complianceRestriction: dto.complianceRestriction,
    paymentDefault: dto.paymentDefault
  };
};

export const toInstrumentModel = (dto: InstrumentDto[]): InstrumentDataModel[] => {
  let data: any;
  const instrumentData: InstrumentDataModel[] = [];
  dto.forEach(i => {
    if (i.instrumentCharacteristicsData && i.instrumentRoleData && i.instrumentGlobalNote && i.instrumentEventsData && i.instrumentIssuanceData)
      data = {
        id: i.id,
        issuer: i.instrumentCharacteristicsData.issuer!.name,
        seriesNumber: i.instrumentCharacteristicsData.seriesNumber,
        isinReference: i.instrumentCharacteristicsData.isinReference,
        name: i.instrumentCharacteristicsData.name,
        commonDepositary: i.instrumentRoleData.commonDepositary,
        commonServiceProvider: i.instrumentRoleData.commonServiceProvider,
        physicalForm: i.instrumentGlobalNote.physicalForm,
        listingAgent: i.instrumentRoleData.listingAgent,
        principalPayingAgent: i.instrumentRoleData.principalPayingAgent,
        tradingMethodIdentifier: i.instrumentIssuanceData.tradingMethodIdentifier,
        partialRedemption: i.instrumentEventsData.partialRedemption,
        partialRedemptionType: i.instrumentEventsData.partialRedemptionType,
        redeemableInSecurities: i.instrumentEventsData.redeemableInSecurities,
        couponPaymentCurrencies: i.instrumentEventsData.couponPaymentCurrencies,
        redemptionCurrencies: i.instrumentEventsData.redemptionCurrencies,
        partialRedemptCurrencies: i.instrumentEventsData.partialRedemptCurrencies,
        clearingSystems: i.instrumentCharacteristicsData.clearingSystems,
        instrumentStatus: i.status,
        complianceRestriction: i.instrumentCharacteristicsData.complianceRestriction,
        paymentDefault: i.instrumentCharacteristicsData.paymentDefault
      };
    instrumentData.push(data);
  });
  return instrumentData;
};
