import { Observable } from 'rxjs';
import { SgsEnvPropertiesService } from 'sgs-angular-components';

import { DOCUMENT, ɵparseCookieValue } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class CsrfInterceptor implements HttpInterceptor {
  private readonly cookieName: string;
  private readonly headerName: string;
  private lastCookieString: string | undefined = undefined;
  private lastToken: string | undefined | null = undefined;

  constructor(env: SgsEnvPropertiesService, @Inject(DOCUMENT) private readonly doc: Document) {
    this.cookieName = env.csrfCookieName;
    this.headerName = env.csrfHeaderName;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const method: string | undefined = request.method ? request.method.toUpperCase() : undefined;

    if (method && method !== 'GET' && request.url && request.url.indexOf('://') < 0) {
      const token = this.getToken();
      if (token) {
        request = request.clone({ headers: request.headers.set(this.headerName, token) });
      }
    }
    return next.handle(request);
  }

  private getToken(): string | undefined | null {
    const cookieString = this.doc.cookie || '';
    if (!this.lastToken || cookieString !== this.lastCookieString) {
      this.lastToken = ɵparseCookieValue(cookieString, this.cookieName);
      this.lastCookieString = cookieString;
    }
    return this.lastToken;
  }
}

export const csrfInterceptorProvider = [{ provide: HTTP_INTERCEPTORS, useClass: CsrfInterceptor, multi: true }];
