import { EcashPaymentRequestDto, EcashPaymentResponseDto } from '@apis/backend/events';

export interface ECashPaymentsDetailModel {
  eventId?: number;
  eCashId?: string;
  currency?: string;
  amount?: number;
  paymentEndToEndId?: string;
  identification?: string;
  creationDate?: string;
  paymentType?: string;
  eCashPaymentStatus?: string;
  paymentErrorMessage?: string;
}

export const toEcashPaymentsDetailsSearchDto = (id: number): EcashPaymentRequestDto => {
  return {
    id: id
  };
};

export const setEcashPaymentsDetails = (eCashpaymentsDetail: EcashPaymentResponseDto | null): ECashPaymentsDetailModel | null => {
  if (eCashpaymentsDetail == null) {
    return null;
  }
  return {
    eventId: eCashpaymentsDetail.eventId,
    eCashId: eCashpaymentsDetail.ecashId,
    paymentEndToEndId: eCashpaymentsDetail.paymentEndToEndId,
    identification: eCashpaymentsDetail.identification,
    amount: eCashpaymentsDetail.amount,
    currency: eCashpaymentsDetail.currency,
    paymentType: eCashpaymentsDetail.messageTypeId?.valueDescription,
    eCashPaymentStatus: eCashpaymentsDetail.ecashStatusId?.valueDescription,
    creationDate: eCashpaymentsDetail.creationDate,
    paymentErrorMessage: eCashpaymentsDetail.errorMessage
  };
};
