/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input } from '@angular/core';
import { PagingRequestWithCriteriaFormService } from '@instruments/services/paging-request-with-criteria.form.service';
import { InstrumentColumnDefService } from './_aggrid/instrument-column-def-service';
import { InstrumentDataFetchModel, PagingWithCriteriaModel } from '@instruments/models';
import { ListBaseComponent } from '@shared/search-base';

@Component({
  selector: 'app-instrument-list',
  templateUrl: './instrument-list.component.html',
  styleUrls: ['./instrument-list.component.scss'],
  providers: [InstrumentColumnDefService]
})
export class InstrumentListComponent extends ListBaseComponent<PagingWithCriteriaModel, PagingRequestWithCriteriaFormService, InstrumentColumnDefService> {
  @Input() public instruments: InstrumentDataFetchModel[] = [];

  constructor(instrumentColumnDefService: InstrumentColumnDefService, pagingRequestWithCriteriaFormService: PagingRequestWithCriteriaFormService) {
    super(instrumentColumnDefService, pagingRequestWithCriteriaFormService, 50);
  }
}
