<div class="section-forms" [formGroup]="formGroup">
  <div class="d-flex">
    <div class="me-2">
      <div class="input-group input-group-md">
        <div class="input-icon-start">
          <em class="icon text-secondary">search</em>
        </div>
        <input placeholder="{{ placeholder(fields.isin) | translate }}" #isin component type="search" [formControlName]="fields.isin" class="form-control form-control-md" />
      </div>
    </div>
    <div class="me-2">
      <div class="input-group input-group-md">
        <div class="input-icon-start">
          <em class="icon text-secondary">search</em>
        </div>
        <input [formControlName]="fields.ipaCode" placeholder="{{ placeholder(fields.ipaCode) | translate }}" class="form-control form-control-md" #ipaCode component />
      </div>
    </div>
    <div class="me-2">
      <div class="input-group input-group-md">
        <div class="input-icon-start">
          <em class="icon text-secondary">search</em>
        </div>
        <input [formControlName]="fields.seriesNumber" placeholder="{{ placeholder(fields.seriesNumber) | translate }}" class="form-control form-control-md" #seriesNumber component />
      </div>
    </div>
    <div class="me-2 mw-15">
      <app-type-multiple-select
        #operation
        component
        [formControlName]="fields.operation"
        icon="search"
        [closeOnSelect]="true"
        [type]="OPERATION_TYPE"
        [multiple]="false"
        [hasError]="hasError(fields.operation)"
        placeholder="{{ placeholder(fields.operation) | translate }}"
      >
      </app-type-multiple-select>
    </div>
    <div class="me-2">
      <app-type-multiple-select
        #status
        component
        [formControlName]="fields.status"
       
        [multiple]="false"
        [closeOnSelect]="true"
        [type]="OPERATION_WORKFLOW_STATUS"
        [hasError]="hasError(fields.status)"
        placeholder="{{ placeholder(fields.status) | translate }}"
      >
      </app-type-multiple-select>
    </div>
    <div class="ms-auto">
      <ng-content select="[searchTemplate]"></ng-content>
    </div>
  </div>

  <div class="row mt-2 mb-4">
    <div class="col-md-10">
      <app-operation-list-active-filters (filtersChanged)="onFiltersChanged($event)"> </app-operation-list-active-filters>
    </div>
  </div>
</div>
