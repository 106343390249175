import { Component, Output, EventEmitter, Input } from '@angular/core';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { ValuationCriteriaSearchModel } from '@valuation/models';
import { ValuationCriteriaSearchFormService } from '@valuation/services';
import { TYPED_DATA_TYPE, TypedDataModel } from '@shared/models';
import { VALUATION_CONSTANTS } from '@valuation/configs/valuation-constants';
import { VALUATION_REJECT_REASONS } from '@valuation/configs/reject-reasons';

@Component({
  selector: 'app-valuation-list-filter',
  templateUrl: './valuation-list-filter.component.html'
})
export class ValuationListFilterComponent extends BaseFormComponent<ValuationCriteriaSearchModel, ValuationCriteriaSearchFormService> {
  public isCollapsed = true;
  @Output() exportCSV = new EventEmitter<boolean>();
  @Input() disableExportCsv = false;
  constructor(valuationCriteriaSearchFormService: ValuationCriteriaSearchFormService) {
    super(valuationCriteriaSearchFormService, 'valuation.list.filters');
  }
  public EVENT_TYPE: TYPED_DATA_TYPE = 'EVENT_TYPE';
  public VALUATION_STATUS: TYPED_DATA_TYPE = 'VALUATION_STATUS';
  public EVENT_REDEMPTION_TYPE: TYPED_DATA_TYPE = 'EVENT_REDEMPTION_TYPE';
  public eventTypesDropDown = ['INTR', 'REDM', 'MCAL', 'PRED', 'PCAL', 'DVCA'];
  public valuationStatusDropDown = VALUATION_CONSTANTS.valuation.valuationFilterStatusDropDown;
  public valuationVersionDropDown = ['NEW', 'UPDATE', 'CANCEL', 'No Change'];
  public valuationRejectReasons: TypedDataModel[] = [...VALUATION_REJECT_REASONS];
  onDatesChanged(e: any, fromDate: any, toDate: any) {
    this.formService.patch(fromDate, e?.detail?.from?.formatted);
    this.formService.control(fromDate).markAsDirty();
    this.formService.patch(toDate, e?.detail?.to?.formatted);
    this.formService.control(toDate).markAsDirty();
  }

  exportCsv() {
    this.exportCSV.emit(true);
  }

  get receiptFromDate(): number | null {
    const formDate = this.formService?.rawValue()?.receiptFromDate;
    if (formDate) {
      return this.getDateTime(formDate);
    }
    return null;
  }

  get receiptToDate(): number | null {
    const formDate = this.formService?.rawValue()?.receiptToDate;
    if (formDate) {
      return this.getDateTime(formDate);
    }
    return null;
  }

  get valueBeginDate(): number | null {
    const formDate = this.formService?.rawValue()?.valueFromDate;
    if (formDate) {
      return this.getDateTime(formDate);
    }
    return null;
  }
  get valueEndDate(): number | null {
    const formDate = this.formService?.rawValue()?.valueToDate;
    if (formDate) {
      return this.getDateTime(formDate);
    }
    return null;
  }

  public get canExport(): boolean {
    return Object.keys(this.formService.fields)
      .map((_, index, array) => {
        const item = array[index];
        return this.formService.control(item as keyof ValuationCriteriaSearchModel);
      })
      .some(q => q.value !== null && q.value !== undefined);
  }

  private getDateTime(formDate: string) {
    const date = formDate.split('-');
    const newDate = new Date(+date[2], +date[1] - 1, +date[0]);
    return newDate.getTime();
  }
}
