import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() backButton = true;
  @Input() containerType: ContainerType = 'container';
  public reducedMarge = false;

  constructor(private readonly router: Router) {
    router.events.subscribe((val: Event) => {
      this.reducedMarge = val instanceof NavigationEnd && val.url.includes('add') ? true : false;
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() goBack: EventEmitter<any> = new EventEmitter();

  emitGoBack(): void {
    this.goBack.emit();
  }
}
