import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SettlementModel } from '@instruments/models/settlement.model';
import { SETLLEMENT_SECONDARY_ACTIONS_ARRAY, SETTLEMENT_ACTIONS } from '@shared/models/settlement-action.model';
import { SettlementActionsService } from '../consult/settlement-actions.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-instrument-settlement-actions',
  templateUrl: './settlement-actions.component.html',
  styleUrls: ['./settlement-actions.component.scss']
})
export class InstrumentsSettlementActionsComponent implements OnInit, OnDestroy {
  @Output() action = new EventEmitter<SETTLEMENT_ACTIONS>();
  @Input() isInternalIssuer = false;
  @Input() selectedSettlements: SettlementModel[] = [];
  public readonly shutdown$ = new Subject<void>();
  public actions: string[] = [...SETLLEMENT_SECONDARY_ACTIONS_ARRAY];
  allowedActions: SETTLEMENT_ACTIONS[] = [];
  constructor(public readonly settlementActionsService: SettlementActionsService) {}
  ngOnInit(): void {
    this.settlementActionsService.allowedActions$.pipe(takeUntil(this.shutdown$)).subscribe(allowedActions => {
      this.allowedActions = allowedActions.filter((q: SETTLEMENT_ACTIONS) => this.actions.includes(q));
    });
  }
  public get origin(): InstrumentOrigin {
    return this.isInternalIssuer ? 'STA' : 'SPI';
  }
  public canPerformAction(action: SETTLEMENT_ACTIONS): boolean {
    return this.allowedActions.includes(action);
  }
  onApplyAction(action: string): void {
    this.action.emit(action as SETTLEMENT_ACTIONS);
  }
  label(action: string): string {
    return 'instruments.instrument.forms.settlement.actions.' + action;
  }
  ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}
