import { MovementOfPositionCriteriaSearchDto } from '@apis/backend/instruments';
import { PartyModel, toPartyDto } from '@shared/models';

export interface MovementOfPositionCriteriaModel {
  isinReference?: string;
  icsdParty?: PartyModel | null;
  depositaryParty?: PartyModel | null;
}
export const toMovementOfPositionCriteriaSearchDto = (model: MovementOfPositionCriteriaModel): MovementOfPositionCriteriaSearchDto => {
  return {
    depositaryParty: toPartyDto(model.depositaryParty),
    icsdParty: toPartyDto(model.icsdParty),
    isinReference: model.isinReference
  };
};
