/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[yesNoLabel]'
})
export class YesNoLabelDirective implements OnChanges {
  @Input()
  yesNoLabel: boolean | null | undefined;
  constructor(private readonly elRef: ElementRef) {}

  ngOnChanges(): void {
    if (this.yesNoLabel == null) {
      return;
    }

    const innerText = this.yesNoLabel ? 'Yes' : 'No';
    this.elRef.nativeElement.innerText = innerText;
  }
}
