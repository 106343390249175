/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-namespace */

import { PartyDto } from '@apis/backend/instruments';
import { INSTRUMENT_CONSTANTS } from '@instruments/configs/instrument-constants';
import { IpaTypeModel } from '@shared/models/ipa-type.model';
import { BooleanRules as B } from './boolean.rules';

type NullableIpaTypeModel = IpaTypeModel | null | undefined;
type NullablePartyDto = PartyDto | null | undefined;

export namespace IpaTypeModelRules {
  type COUPON_TYPE = 'FIXED' | 'INDEXED' | 'VARIABLE' | 'ZERO-COUPON';
  type INSTRUMENT_TYPE = 'CERTIFICATE' | 'ECP' | 'EMTC' | 'EMTN' | 'ETF' | 'WARRANT';
  export const is = (model: NullableIpaTypeModel, valueDescription: COUPON_TYPE | INSTRUMENT_TYPE) => {
    if (!model) {
      return false;
    }

    return model.valueDescription === valueDescription;
  };
  export const isNot = (model: NullableIpaTypeModel, valueDescription: COUPON_TYPE | INSTRUMENT_TYPE) => {
    if (!model) {
      return true;
    }

    return model.valueDescription !== valueDescription;
  };

  export const isNotZeroCoupon = (couponType: NullableIpaTypeModel) => B.isDefined(couponType) && isNot(couponType, INSTRUMENT_CONSTANTS.Types.couponsTypes.ZEROCOUPON as COUPON_TYPE);

  export const isCouponFixed = (couponType: NullableIpaTypeModel) => is(couponType, 'FIXED');

  export const isNotWarrant = (instrumentType: NullableIpaTypeModel) => is(instrumentType, 'WARRANT');

  export const areNotFixedCoupon = (firstCouponType: NullableIpaTypeModel, secondCouponType: NullableIpaTypeModel) => isNot(firstCouponType, 'FIXED') && isNot(secondCouponType, 'FIXED');

  export const areNotZeroCoupon = (firstCouponType: NullableIpaTypeModel, secondCouponType: NullableIpaTypeModel) =>
    isNot(firstCouponType, INSTRUMENT_CONSTANTS.Types.couponsTypes.ZEROCOUPON as COUPON_TYPE) && B.isDefined(secondCouponType) && isNot(secondCouponType, INSTRUMENT_CONSTANTS.Types.couponsTypes.ZEROCOUPON as COUPON_TYPE);

  export const isWarrantType = (instrumentType: NullableIpaTypeModel) => B.isDefined(instrumentType) && instrumentType.valueDescription === INSTRUMENT_CONSTANTS.Types.instrumentTypes.WARRANT;
  export const istradingMethodUnit = (instrumentType: NullableIpaTypeModel) => B.isDefined(instrumentType) && instrumentType.valueDescription === INSTRUMENT_CONSTANTS.Types.tradingMethods.UNIT;

  export const isTradingMethodNominal = (instrumentType: NullableIpaTypeModel) => B.isDefined(instrumentType) && instrumentType.valueDescription === INSTRUMENT_CONSTANTS.Types.tradingMethods.NOMINAL;
  export const isInitialized = (instrumentStatut: NullableIpaTypeModel) => !B.isDefined(instrumentStatut) || (B.isDefined(instrumentStatut) && instrumentStatut.valueDescription === INSTRUMENT_CONSTANTS.Types.statuses.INITIALIZED);
  export const isClosed = (instrumentStatut: NullableIpaTypeModel) => B.isDefined(instrumentStatut) && instrumentStatut.valueDescription === INSTRUMENT_CONSTANTS.Types.statuses.CLOSED;
  export const isCancelled = (instrumentStatut: NullableIpaTypeModel) => B.isDefined(instrumentStatut) && instrumentStatut.valueDescription === INSTRUMENT_CONSTANTS.Types.statuses.CANCELLED;
  export const isLive = (instrumentStatut: NullableIpaTypeModel) => B.isDefined(instrumentStatut) && instrumentStatut.valueDescription === INSTRUMENT_CONSTANTS.Types.statuses.LIVE;
  export const isTefraD = (physicalForm: NullableIpaTypeModel) => B.isDefined(physicalForm) && physicalForm.valueDescription === INSTRUMENT_CONSTANTS.Types.PhysicalForms.TEMPORARYPERMANENTGLOBALNOTETEFRAD;
  export const isClEARSTREAM = (icsd: NullablePartyDto) => B.isDefined(icsd) && icsd.name === INSTRUMENT_CONSTANTS.Parties.CLEARSTREAMBANKINGSALuxembourg;
  export const isEUROCLEAR = (icsd: NullablePartyDto) => B.isDefined(icsd) && icsd.name === INSTRUMENT_CONSTANTS.Parties.EUROCLEARBANKBELGIUM;
  export const isPartySgLux = (agent: NullablePartyDto) => B.isDefined(agent) && agent.name === INSTRUMENT_CONSTANTS.Parties.SOCIETEGENERALELUXEMBOURG;
  export const isSgLux = (agent: NullablePartyDto) => B.isDefined(agent) && agent.name === INSTRUMENT_CONSTANTS.Parties.SOCIETEGENERALELUXEMBOURG;
}
