import { Observable, of } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BdrIdDataDto, PartyDto, ResultPartyDto } from '@apis/backend/instruments';
import { PartyResourceService } from '@apis/backend/instruments/api/partyResource.service';
import { PartyModel, toPartyModel, toPartyModels } from '@shared/models';
import { ROLES_TYPE } from '@shared/models/roles-types.model';
import { TYPE_FILTER_TYPE } from '@shared/models/type-filter-type.model';
import { ProgrammeInformation, toProgrammeInformation } from '@shared/models/programme-information.model';

@Injectable({
  providedIn: 'root'
})
export class PartyService {
  private readonly response = 'response';
  leiCodeSGLUX = 'TPS0Q8GFSZF45ZZFL873';
  leiCodeCSSFLUXREGMARKET = 'CSSF_LUXEMBOURG:_REGULATED_MARKET';
  leiCodeOther = 'LEI_OTHER';
  leiCodeNotApplicable = 'LEI_NA';

  constructor(private readonly partyResourceService: PartyResourceService) {}

  getPartiesByRoles(roleName: ROLES_TYPE, typeFilter: TYPE_FILTER_TYPE): Observable<PartyModel[]> {
    return this.partyResourceService.getPartiesByRoles(roleName, typeFilter, this.response).pipe(map((result: HttpResponse<ResultPartyDto>) => toPartyModels(result.body?.resources)));
  }
  getPartyByLeiCode(leiCode: string): Observable<PartyModel | null> {
    return this.partyResourceService.getPartyByLeiCode(leiCode, this.response).pipe(map((result: HttpResponse<PartyDto>) => toPartyModel(result.body)));
  }
  private isLeiCodeInvalid(leiCodeWithoutSpaces: string): boolean {
    return leiCodeWithoutSpaces.length !== 20 || Number.isNaN(+leiCodeWithoutSpaces.substring(leiCodeWithoutSpaces.length - 2, leiCodeWithoutSpaces.length + 1));
  }

  getIssuerBdrDataFromLeiCode(leiCode: string): Observable<ProgrammeInformation | null> {
    const cleanedLeiCode = leiCode.split(/\s/g).join('');
    if (this.isLeiCodeInvalid(cleanedLeiCode)) {
      return of(null);
    }
    return this.partyResourceService.getIssuerBdrDataFromLeicode(leiCode, this.response).pipe(
      map((result: HttpResponse<BdrIdDataDto>) => result.body!),
      filter(b => !!b),
      map(bdrId => toProgrammeInformation(cleanedLeiCode, bdrId))
    );
  }
}
