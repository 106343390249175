import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TrancheModel } from '@instruments/models/tranche.model';
import { FormService } from '@shared/services/forms/form.service';
import { CustomValidators } from '@shared/validators/custom-validator';

@Injectable()
export class TrancheFormService extends FormService<TrancheModel> {
  constructor(fb: UntypedFormBuilder) {
    super('tranche', fb, {
      trancheNumber: fb.control(null),
      interestCommencementDate: fb.control(null, [Validators.required, CustomValidators.ValidateDate]),
      finalTermReceived: fb.control(false),
      issueDate: fb.control(null, [Validators.required, CustomValidators.ValidateDate]),
      issuePrice: fb.control(null, [Validators.required, CustomValidators.ValidateNumber(29, 7)]),
      minimumTrading: fb.control(null, [CustomValidators.ValidateNumber(29, 7)]),
      nominal: fb.control(null, [Validators.required, CustomValidators.ValidateNumber(29, 7)]),
      outstandingNominal: fb.control(null),
      denomination: fb.control(null),
      numberOfShares: fb.control(null, [Validators.required, CustomValidators.ValidateNumber(29, 7)]),
      outstandingNumberOfShares: fb.control(null),
      status: fb.control(null),
      isinReference: fb.control(null, [Validators.required, CustomValidators.Isin]),
      trancheLastUpdateDateTimeAsString: fb.control(null)
    });
  }
}
