<app-header [backButton]="false" containerType="container-fluid">
  <div headerBody class="d-flex flex-row justify-content-between mb-1 mt-1">
    <h1 class="display-3 line-height-1" translate [translateParams]="{ variable: 'Instruments' }">Instruments</h1>
    <div class="d-flex justify-content-end">
      <app-text-icon-button (clicked)="openReportModal()" [disabled]="disableSearch" class="me-2"
        [customClasses]="'btn-icon-start'" label="{{ 'instruments.confirmations-report' | translate }}" size="md"
        buttonType="btn-outline-default"> </app-text-icon-button>
      <app-text-icon-button icon="upload" (clicked)="openUploadModal()" [disabled]="disableSearch"
        [customClasses]="'btn-icon-start'" *ngIf="!isUploading; else elseBlock" size="md" class="me-2"
        label="{{ 'instruments.confirmations' | translate }}" size="md"
        buttonType="btn-outline-default"></app-text-icon-button>
      <ng-template #elseBlock>
        <div class="me-3 mt-2">
          <app-blinker>
            <span class="text-primary fw-medium">{{ 'instruments.uploading' | translate }} <strong>{{
                uploadingFile?.name }}</strong></span>
          </app-blinker>
        </div>
      </ng-template>

      <app-text-icon-button *ngIf="canCreateInstrument" [disabled]="disableSearch" routerLink="{{ '/instrument/add' }}" icon="add" color="primary"
        label="{{ 'instruments.new-instrument' | translate }}" size="md" [customClasses]="'btn-icon-start'"
        buttonType="btn-primary"></app-text-icon-button>
    </div>
  </div>
</app-header>
<div class="softLoading" *ngIf="disableSearch">Loading</div>
<div class="container-fluid content-margin-top">
  <div class="row">
    <div class="col-md-12">
      <form (ngSubmit)="searchClick()">
        <app-intrument-list-filter [disableExportCsv]="disableSearch">
          <app-text-icon-button icon="search" [disabled]="disableSearch" searchTemplate [submit]="true" [customClasses]="'btn-icon-start d-flex ms-auto'"
            label="{{ 'instruments.list.search' | translate }}" size="md" buttonType="btn-outline-default">
          </app-text-icon-button>
        </app-intrument-list-filter>
      </form>
    </div>
  </div>
  <app-instrument-list [instruments]="models"></app-instrument-list>
</div>
