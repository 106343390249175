<div class="pt-3" *ngIf="eventPositionInput!.isInternationalISIN">
    <ag-grid-angular #agGrid style="width: 100%; height: 6.5rem;" class="ag-theme-sg-bootstrap-condensed"
        [gridOptions]="gridOptions!" [columnDefs]="positionsColumnDefs" [defaultColDef]="defaultColDef"
        [rowData]="positions" (gridReady)="onGridReady($event)" [toolPanelSuppressSideButtons]="true"
        suppressRowClickSelection=true  (cellValueChanged)="onCellValueChanged($event)">
    </ag-grid-angular>
</div>

<div class="pt-3" *ngIf="!hideTotalsGrid" [hidden]='isExchCertEvent'>
    <ag-grid-angular #agGrid style="width: 100%; height: 4.5rem;"
        class="ag-theme-sg-bootstrap-condensed" [gridOptions]="totalGridOptions!" [columnDefs]="positionsTotalColumnDefs"
        [defaultColDef]="defaultColDef" [rowData]="positionsTotal" (gridReady)="onTotalGridReady($event)"
        [toolPanelSuppressSideButtons]="true" rowSelection="single" (cellValueChanged)="onCellValueTotalChanged($event)" autoSizeAllColumns=true>
    </ag-grid-angular>
</div>
