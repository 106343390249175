import { SettlementPerformDto } from '@apis/backend/instruments';
import { SettlementDto } from '@apis/backend/instruments/model/settlementDto';
import { SettlementIdsDto } from '@apis/backend/instruments/model/settlementIdsDto';
import { IpaTypeModel, toIpaTypeDto, toIpaTypeModel } from '@shared/models';
import { IpaTypeModelRules as R } from '@instruments/rules';
import { SettlementStatusHistoryModel, toSettlementStatusHistoryModel } from './settlement-status-history.model';

export interface SettlementModel {
  id?: number;
  subscriberName?: string;
  subscriberClearing: IpaTypeModel | null;
  subscriberAccountNumber?: string;
  paymentType?: IpaTypeModel | null;
  amount?: string;
  numberOfshares?: string;
  issuePrice?: string;
  netAmount?: string;
  tradeDateAsString?: string;
  settlementDateAsString?: string;
  issuingAgentClearing?: IpaTypeModel | null;
  clearingAccountNumber?: IpaTypeModel | null;
  confirmationStatus?: IpaTypeModel | null;
  confirmationSgssGenerationDateAsString?: string;
  comments?: string;
  issuingSettlementStatus?: IpaTypeModel | null;
  userValidation?: string;
  timestampValidation?: string;
  instrumentTrancheId?: number;
  toBeDeleted: boolean;
  settlementStatusHistory?: SettlementStatusHistoryModel[];
}

export interface SettlementIdsModel {
  createdIds?: number[];
}

export const toSettlementIdsModel = (dto: SettlementIdsDto | null | undefined): SettlementIdsModel | null => {
  if (!dto) {
    return null;
  }
  return {
    createdIds: dto?.createdIds
  };
};

export const toSettlementModel = (settlements: SettlementDto): SettlementModel => {
  return {
    id: settlements.id,
    subscriberName: settlements.subscriberName,
    subscriberClearing: toIpaTypeModel(settlements.subscriberClearing),
    subscriberAccountNumber: settlements.subscriberAccountNumber,
    paymentType: toIpaTypeModel(settlements.paymentType),
    amount: settlements.amount,
    issuePrice: settlements.issuePrice,
    netAmount: settlements.netAmount,
    tradeDateAsString: settlements.tradeDateAsString,
    settlementDateAsString: settlements.settlementDateAsString,
    issuingAgentClearing: toIpaTypeModel(settlements.issuingAgentClearing),
    clearingAccountNumber: toIpaTypeModel(settlements.clearingAccountNumber),
    confirmationStatus: toIpaTypeModel(settlements.confirmationStatus),
    confirmationSgssGenerationDateAsString: settlements.confirmationSgssGenerationDateAsString,
    comments: settlements.comments,
    userValidation: settlements.userValidation,
    timestampValidation: settlements.timestampValidation,
    issuingSettlementStatus: toIpaTypeModel(settlements.status),
    toBeDeleted: false,
    numberOfshares: settlements.amount,
    settlementStatusHistory: (settlements as any).settlementStatusHistoryDtos?.map((q: any) => toSettlementStatusHistoryModel(q))
  };
};

export const toCreateSettlementDto = (tradingMethod: IpaTypeModel | null, trancheNumber: number, settlement: SettlementModel): SettlementPerformDto | null => {
  if (!settlement || !trancheNumber) {
    return null;
  }
  let amountToSet;
  if (R.istradingMethodUnit(tradingMethod)) {
    amountToSet = settlement.numberOfshares;
  } else if (R.isTradingMethodNominal(tradingMethod)) {
    amountToSet = settlement.amount;
  }
  return {
    id: settlement.id,
    subscriberName: settlement.subscriberName,
    subscriberClearing: toIpaTypeDto(settlement.subscriberClearing),
    subscriberAccountNumber: settlement.subscriberAccountNumber,
    paymentType: toIpaTypeDto(settlement.paymentType),
    amount: amountToSet?.split(',').join(''),
    issuePrice: settlement.issuePrice?.split(',').join(''),
    netAmount: settlement.netAmount?.split(',').join(''),
    tradeDateAsString: settlement.tradeDateAsString,
    settlementDateAsString: settlement.settlementDateAsString,
    issuingAgentClearing: toIpaTypeDto(settlement.issuingAgentClearing),
    clearingAccountNumber: toIpaTypeDto(settlement.clearingAccountNumber),
    confirmationStatus: toIpaTypeDto(settlement.confirmationStatus),
    confirmationSgssGenerationDateAsString: settlement.confirmationSgssGenerationDateAsString,
    comments: settlement.comments,
    userValidation: settlement.userValidation,
    timestampValidation: settlement.timestampValidation,
    toBeDeleted: settlement.toBeDeleted
  };
};
