import { MatrixInstrumentRoleDto } from '@apis/backend/instruments';
import { PartyModel } from '@shared/models';

export interface MatrixInstrumentRoleModel {
  issuingAgent?: PartyModel;
  commonDepositary?: PartyModel;
  principalPayingAgent?: PartyModel;
  localPayingAgent?: PartyModel;
  listingAgent?: PartyModel;
  registrar?: PartyModel;
  fiscalAgent?: PartyModel;
}

export const toMatrixInstrumentRoleDto = (model: MatrixInstrumentRoleModel | null | undefined): MatrixInstrumentRoleDto | undefined => {
  if (!model) {
    return undefined;
  }

  return {
    issuingAgent: model.issuingAgent,
    commonDepositary: model.commonDepositary,
    principalPayingAgent: model.principalPayingAgent,
    localPayingAgent: model.localPayingAgent,
    listingAgent: model.listingAgent,
    registrar: model.registrar,
    fiscalAgent: model.fiscalAgent
  };
};

export const toMatrixInstrumentRoleModel = (dto: MatrixInstrumentRoleDto | null | undefined): MatrixInstrumentRoleModel | null => {
  if (!dto) {
    return null;
  }

  return {
    issuingAgent: dto.issuingAgent,
    commonDepositary: dto.commonDepositary,
    principalPayingAgent: dto.principalPayingAgent,
    localPayingAgent: dto.localPayingAgent,
    listingAgent: dto.listingAgent,
    registrar: dto.registrar,
    fiscalAgent: dto.fiscalAgent
  };
};
