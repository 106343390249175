import { UpdateStatusRequestDto } from '@apis/backend/instruments';
import { dateToString } from '@utils/utility-functions';

export interface UpdateStatusRequestModel {
  ipaCode: number | null | undefined;
  trancheNumber?: number | null;
  increaseNumber?: number | null;
  issueDate?: Date | null;
  certifiedNominal?: number | null;
  partiallyCertified?: boolean | null;
}

export const toUpdateStatusRequestDto = (model: UpdateStatusRequestModel): UpdateStatusRequestDto => {
  return {
    ipaCode: model.ipaCode?.toString() || '',
    trancheNumber: model.trancheNumber?.toString() || '',
    increaseNumber: model.increaseNumber?.toString() || '',
    issueDateAsString: dateToString(model.issueDate) || undefined,
    certifiedNominal: model.certifiedNominal?.toString() || '',
    partiallyCertified: model.partiallyCertified || false
  };
};
