import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PartyModel, ROLES_TYPE, TYPE_FILTER_TYPE, TypedDataModel } from '@shared/models';
import { IssuingSettlementIndicatorsService } from '../issuing-settlement-indicators/issuing-settlement-indicators.service';
import { Subject } from 'rxjs';
import { IssuingSettlementIndicatorCellClickPayload, IssuingSettlementIndicatorRow } from '../issuing-settlement-indicators-grid/issuing-settlement-indicators-grid.model';
import { takeUntil, tap } from 'rxjs/operators';
import { GlobalNoteIndicatorRow } from '../global-note-indicators-grid/global-note-indicators-grid.model';
import { GlobalNoteIndicator } from '../global-note-indicators/global-note-indicators.model';
import { GlobalNoteIndicatorsService } from '../global-note-indicators/global-note-indicators.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportingAccessComponent } from '@instruments/components';

const taskId = 'b29ca88e-d5e9-11ec-a618-16ae6894a386';

@Component({
  selector: 'app-home',
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.component.html',
  providers: [IssuingSettlementIndicatorsService, GlobalNoteIndicatorsService]
})
export class HomeComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly partiesData: [TYPE_FILTER_TYPE, ROLES_TYPE, PartyModel[]][];
  private readonly typeReferenceData: Record<string, TypedDataModel[]>;

  processId = 'a57648b2-b4b7-11ec-84de-a2cb863e4915';
  taskId: string = taskId;
  issuingSettlementIndicatorsRows: IssuingSettlementIndicatorRow[] = [];
  documentationCdReceptionMonitoringRows: GlobalNoteIndicatorRow[] = [];
  permanentMonitoringRows: GlobalNoteIndicatorRow[] = [];
  disposalMonitoringRows: GlobalNoteIndicatorRow[] = [];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly issuingSettlementIndicatorsService: IssuingSettlementIndicatorsService,
    private readonly globalNoteIndicatorsService: GlobalNoteIndicatorsService,
    private readonly modalService: NgbModal
  ) {
    this.partiesData = this.activatedRoute.snapshot.data['parties'];
    this.typeReferenceData = this.activatedRoute.snapshot.data['typeReferenceData'];
  }

  ngOnInit(): void {
    this.issuingSettlementIndicatorsService
      .getIssuingSettlementIndicatorRows$(this.partiesData, this.typeReferenceData)
      .pipe(
        tap(x => (this.issuingSettlementIndicatorsRows = x)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.globalNoteIndicatorsService
      .getGlobalNoteIndicatorRows$()
      .pipe(
        tap(result => {
          const [documentationCdReceptionMonitoringRows, permanentMonitoringRows, disposalMonitoringRows] = result;
          this.documentationCdReceptionMonitoringRows = documentationCdReceptionMonitoringRows;
          this.permanentMonitoringRows = permanentMonitoringRows;
          this.disposalMonitoringRows = disposalMonitoringRows;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  onIssuingSettlementIndictorsGridLinkClicked(event: IssuingSettlementIndicatorCellClickPayload): void {
    this.issuingSettlementIndicatorsService.navigateToInstruments(event);
  }

  onGlobalNoteIndicatorsGridLinkClicked(rowData: GlobalNoteIndicatorRow, type: GlobalNoteIndicator): void {
    this.globalNoteIndicatorsService.navigateToInstruments({ type, rowData });
  }

  openReportingAccessModal(): void {
    this.modalService.open(ReportingAccessComponent, { size: 'lg', scrollable: true });
  }
}
