import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { EventsContainerComponent } from './components';
import { EventDetailComponent } from './components/event-detail/event-detail.component';
import { EventtResolve } from './event.resolve';
import { PermissionGuard } from '@routes/permission.guard';

const EVENTS_ROUTES: Routes = [
  { path: 'events', component: EventsContainerComponent, canActivate: [PermissionGuard] },
  { path: 'events/:pageType', component: EventDetailComponent, canActivate: [PermissionGuard] },
  {
    path: 'events/:pageType/:id',
    component: EventDetailComponent,
    resolve: { event: EventtResolve },
    canActivate: [PermissionGuard]
  }
];
export const eventsRoutes = RouterModule.forRoot(EVENTS_ROUTES, { preloadingStrategy: PreloadAllModules });
