export const CURRENCIES: string[] = [
  'AED',
  'AUD',
  'BGN',
  'BHD',
  'CAD',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CZK',
  'DKK',
  'EGP',
  'EUR',
  'GBP',
  'HKD',
  'HRK',
  'HUF',
  'ILS',
  'INR',
  'ISK',
  'JPY',
  'KES',
  'KWD',
  'KRW',
  'KZT',
  'MAD',
  'MXN',
  'NOK',
  'NZD',
  'OMR',
  'QAR',
  'RSD',
  'SAR',
  'THB',
  'TND',
  'PLN',
  'RON',
  'RUB',
  'SEK',
  'SGD',
  'TRY',
  'UGX',
  'USD',
  'ZAR',
  'ZMW'
];
