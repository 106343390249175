import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Router } from '@angular/router';
import { routeToValuation } from '@valuation/routes';
import { ValuationDetailFormService } from '@valuation/services';

@Component({
  selector: 'app-valuation-action',
  templateUrl: './valuation-action.component.html'
})
export class ValuationActionComponent {
  params: ICellRendererParams | undefined;
  constructor(private readonly router: Router, public valuationDetailFormService: ValuationDetailFormService) {}
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  goToValuationDetail(): void {
    const selectedData = [this.params?.node.data];
    this.router.navigate(routeToValuation(selectedData[0].valuationId, 'consult'));
  }
}
