<ng-container *ngIf="!isReadOnly; else readonlyTemplate">
    <ng-select #select (blur)="onFocusout()" [placeholder]="placeholder || ''"
      [ngModel]="value"
      [ngClass]="{ 'select-error': hasError, isReadOnly: isReadOnly }"
      [multiple]="multiple"
      [items]="listItems"
      [readonly]="isReadOnly"
      [labelForId]="focusId"
      (search)="onSearch($event)"
      (change)="onValueChange($event)"
      [closeOnSelect]="closeOnSelect">
    </ng-select>
</ng-container>
<ng-template #readonlyTemplate>
  <div>{{value}}</div>
</ng-template>
