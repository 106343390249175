import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '@routes/permission.guard';
import { InstructionShellComponent } from './instruction-shell/instruction-shell.component';
import { InstructionsContainerComponent } from './instructions-container/instructions-container.component';

const INSTRUCTIONS_ROUTES: Routes = [
  {
    path: 'extcashaccount',
    canActivate: [PermissionGuard],
    component: InstructionShellComponent,
    children: [
      { path: 'all', component: InstructionsContainerComponent },
      { path: '', component: InstructionsContainerComponent }
    ]
  }
];
export const instructionRoutes = RouterModule.forRoot(INSTRUCTIONS_ROUTES, { preloadingStrategy: PreloadAllModules });
