<app-header [backButton]="false" containerType="container-fluid">
  <div headerBody class="d-flex flex-row justify-content-between mb-1 mt-1">
    <h1 class="display-3 line-height-1" translate [translateParams]="{ variable: 'Valuation' }">Valuation</h1>
  </div>
</app-header>
<div class="softLoading" *ngIf="disableSearch">Loading</div>
<div class="container-fluid content-margin-top">
  <div class="row">
    <div class="col-md-12">
      <form>
        <app-valuation-list-filter [disableExportCsv]="disableSearch" (exportCSV)="exportCsv()">
          <app-text-icon-button icon="search" (clicked)="search()" [disabled]="disableSearch" searchTemplate customClasses="d-flex ms-auto btn-icon-start" [submit]="true"
            label="{{'valuation.list.search'|translate}}" size="md" buttonType="btn-outline-default"></app-text-icon-button>
        </app-valuation-list-filter>
      </form>
    </div>
  </div>
  <app-valuation-list [valuations]="valuations" *ngIf="loaded| async; else noData"></app-valuation-list>

  <div class="row mt-2">
    <div class="col-md-12" *ngIf=displayPagination>
      <app-sg-pagination [pageSize]="itemsByPage" [totalItems]="totalItems" (pageChanged)="onPageChanged($event)"
        [currentPage]="pageNumber" enableCellTextSelection=true [maxPagesToDisplay]="10"></app-sg-pagination>
    </div>
  </div>

  <ng-template #noData>
    <div class="col-12 justify-content-center" [class.d-none]="!valuations" [class.d-flex]="valuations">
      <div class="text-center text-secondary">
        <em class="icon icon-xl d-inline-block">search</em>
        <div class="h4" translate>ag-grid.title</div>
        <p translate>ag-grid.searchSubTitle</p>
      </div>
    </div>
  </ng-template>
</div>
