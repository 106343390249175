/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IpaTypeDto } from './ipaTypeDto';
import { ClientOperationDto } from './clientOperationDto';


export interface OperationDto { 
    idOperation?: number;
    instrumentId?: number;
    isin?: string;
    seriesNumber?: string;
    currency?: string;
    operationType?: IpaTypeDto;
    operationStatus?: IpaTypeDto;
    valueDateAsString?: string;
    instructionDateAsString?: string;
    clientOperationDtos?: Array<ClientOperationDto>;
}

