/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PartyDto } from './partyDto';


export interface OperationDataFetchDto { 
    idOperation?: number;
    ipaNumber?: number;
    isinCode?: string;
    seriesNumber?: string;
    currency?: string;
    operationType?: string;
    operationStatus?: string;
    valueDate?: string;
    csd?: PartyDto;
    direction?: string;
    value?: string;
}

