import { InstrumentCharacteristicsDataCreateDto, InstrumentCharacteristicsDataDto, InstrumentCharacteristicsDataUpdateDto } from '@apis/backend/instruments';
import { notEmpty } from '@utils/utility-functions';

import { IpaTypeModel, toIpaTypeDto, toIpaTypeModel, PartyModel, toPartyDto, toPartyModel } from '@shared/models';
import { TrancheModel } from './tranche.model';

export interface CharacteristicModel {
  readonly issuer: PartyModel | null;
  readonly issuerLeiCode: string | null;
  readonly clearingSystems: PartyModel[];
  readonly icsdProgrammeNumber: string | null;
  readonly internalProgrammeNumber: number | null;
  readonly commonCode: string | null;
  readonly mainTrancheIsin: string | null;
  readonly isinReference: string | null;
  readonly instrumentType: IpaTypeModel | null;
  readonly seriesNumber: string | null;
  readonly classNumber: string | null;
  readonly compartmentNumber: string | null;
  readonly name: string | null;
  readonly finalTermReceived: boolean;
  readonly legalDeskIssuer: IpaTypeModel | null;
  readonly complianceRestriction: boolean;
  readonly paymentDefault: boolean;
  readonly eligibility871m: boolean;
  readonly eligibilitySrd2: boolean;
  readonly labelCategory: IpaTypeModel | null;
  readonly upto: boolean;
}
export const toCharacteristicDto = (model?: CharacteristicModel | null): InstrumentCharacteristicsDataCreateDto | InstrumentCharacteristicsDataUpdateDto | undefined => {
  if (!model) {
    return undefined;
  }

  return {
    issuer: toPartyDto(model.issuer),
    clearingSystems: (model.clearingSystems || []).map(toPartyDto).filter(notEmpty),
    icsdProgrammeNumber: model.icsdProgrammeNumber || undefined,
    internalProgrammeNumber: model.internalProgrammeNumber || undefined,
    commonCode: model.commonCode || undefined,
    isinReference: model.isinReference || undefined,
    instrumentType: toIpaTypeDto(model.instrumentType),
    seriesNumber: model.seriesNumber || undefined,
    classNumber: model.classNumber || undefined,
    compartmentNumber: model.compartmentNumber || undefined,
    name: model.name || undefined,
    finalTermReceived: model.finalTermReceived,
    legalDeskIssuer: toIpaTypeDto(model.legalDeskIssuer),
    complianceRestriction: model.complianceRestriction,
    paymentDefault: model.paymentDefault,
    eligibility871m: model.eligibility871m,
    eligibilitySrd2: model.eligibilitySrd2,
    labelCategory: toIpaTypeDto(model.labelCategory),
    upto: model.upto
  };
};

export const toCharacteristicUpdateDto = (model?: CharacteristicModel | null): InstrumentCharacteristicsDataUpdateDto | undefined => {
  if (!model) {
    return undefined;
  }

  return {
    issuer: toPartyDto(model.issuer),
    clearingSystems: (model.clearingSystems || []).map(toPartyDto).filter(notEmpty),
    commonCode: model.commonCode || undefined,
    isinReference: model.isinReference || undefined,
    instrumentType: toIpaTypeDto(model.instrumentType),
    seriesNumber: model.seriesNumber || undefined,
    classNumber: model.classNumber || undefined,
    compartmentNumber: model.compartmentNumber || undefined,
    name: model.name || undefined,
    finalTermReceived: model.finalTermReceived,
    legalDeskIssuer: toIpaTypeDto(model.legalDeskIssuer),
    complianceRestriction: model.complianceRestriction,
    paymentDefault: model.paymentDefault,
    eligibility871m: model.eligibility871m,
    eligibilitySrd2: model.eligibilitySrd2,
    labelCategory: toIpaTypeDto(model.labelCategory),
    upto: model.upto
  };
};

export const toCharacteristicModel = (dto: InstrumentCharacteristicsDataDto | null | undefined, tranche: TrancheModel | null | undefined): CharacteristicModel => {
  return {
    issuer: toPartyModel(dto?.issuer),
    issuerLeiCode: dto?.issuer?.leiCode || null,
    clearingSystems: (dto?.clearingSystems || []).map(toPartyModel).filter(notEmpty),
    icsdProgrammeNumber: dto?.icsdProgrammeNumber || null,
    internalProgrammeNumber: dto?.internalProgrammeNumber || null,
    commonCode: dto?.commonCode || null,
    mainTrancheIsin: dto?.mainTrancheIsin || null,
    isinReference: tranche?.isinReference || dto?.isinReference || null,
    instrumentType: toIpaTypeModel(dto?.instrumentType),
    seriesNumber: dto?.seriesNumber || null,
    classNumber: dto?.classNumber || null,
    compartmentNumber: dto?.compartmentNumber || null,
    name: dto?.name || null,
    finalTermReceived: tranche?.finalTermReceived || false,
    legalDeskIssuer: toIpaTypeModel(dto?.legalDeskIssuer),
    complianceRestriction: dto?.complianceRestriction || false,
    paymentDefault: dto?.paymentDefault || false,
    eligibility871m: dto?.eligibility871m || false,
    eligibilitySrd2: dto?.eligibilitySrd2 || false,
    labelCategory: toIpaTypeModel(dto?.labelCategory),
    upto: dto?.upto || false
  };
};
