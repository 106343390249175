import { ROLES_TYPE } from './roles-types.model';
import { ProgrammeDto } from '@apis/backend/instruments';
import { dateToString, stringToDate } from '@utils/utility-functions';
import { PartyModel, toPartyModel, toPartyDto, toPartyDtos, toPartyModels } from './party.model';

export interface ProgrammeModel {
  readonly id: number;
  readonly icsdProgrammeNumber: string | null;
  readonly programmeName: string | null;
  readonly issuerLeiCode: string | null;
  readonly issuer: PartyModel | null;
  readonly issuingAgents: PartyModel[];
  readonly commonDepositaries: PartyModel[];
  readonly principalPayingAgents: PartyModel[];
  readonly localPayingAgents: PartyModel[];
  readonly calculationAgent: PartyModel[];
  readonly listingAgents: PartyModel[];
  readonly transferAgents: PartyModel[];
  readonly registrars: PartyModel[];
  readonly fiscalAgents: PartyModel[];
  readonly eurosystemEligibility: boolean;
  readonly commonSafekeepers: PartyModel[];
  readonly competentAuthorities: PartyModel[];
  readonly authorityProgrammeIdentifier: string | null;
  readonly authorityProgrammeIdentifierDate: Date | null;
  readonly oldAuthorityProgrammeIdentifier: string | null;
  readonly oldAuthorityProgrammeIdentifierDate: Date | null;
  readonly status: string | null;
  readonly idWorkflow: string | null;
}

export const toProgrammeDto = (model: ProgrammeModel | null | undefined): ProgrammeDto | undefined => {
  if (!model) {
    return undefined;
  }

  return {
    id: model.id,
    icsdProgrammeNumber: model.icsdProgrammeNumber || undefined,
    programmeName: model.programmeName || undefined,
    issuer: toPartyDto(model.issuer),
    issuingAgents: toPartyDtos(model.issuingAgents),
    commonDepositaries: toPartyDtos(model.commonDepositaries),
    principalPayingAgents: toPartyDtos(model.principalPayingAgents),
    localPayingAgents: toPartyDtos(model.localPayingAgents),
    calculationAgents: toPartyDtos(model.calculationAgent),
    listingAgents: toPartyDtos(model.listingAgents),
    transferAgents: toPartyDtos(model.transferAgents),
    registrars: toPartyDtos(model.registrars),
    fiscalAgents: toPartyDtos(model.fiscalAgents),
    eurosystemEligibility: model.eurosystemEligibility,
    commonSafekeepers: toPartyDtos(model.commonSafekeepers),
    competentAuthorities: toPartyDtos(model.competentAuthorities),
    authorityProgrammeIdentifier: model.authorityProgrammeIdentifier || undefined,
    authorityProgrammeIdentifierDateAsString: dateToString(model.authorityProgrammeIdentifierDate) || undefined,
    oldAuthorityProgrammeIdentifier: model.oldAuthorityProgrammeIdentifier || undefined,
    oldAuthorityProgrammeIdentifierDateAsString: dateToString(model.oldAuthorityProgrammeIdentifierDate) || undefined,
    status: model.status || undefined,
    idWorkflow: model.idWorkflow || undefined
  };
};

export const toProgrammeModel = (dto: ProgrammeDto | null | undefined): ProgrammeModel | null => {
  if (!dto) {
    return null;
  }

  return {
    id: dto.id ?? -1,
    icsdProgrammeNumber: dto.icsdProgrammeNumber || null,
    programmeName: dto.programmeName || null,
    issuer: toPartyModel(dto.issuer),
    issuerLeiCode: dto.issuer?.leiCode || null,
    issuingAgents: toPartyModels(dto.issuingAgents),
    commonDepositaries: toPartyModels(dto.commonDepositaries),
    principalPayingAgents: toPartyModels(dto.principalPayingAgents),
    localPayingAgents: toPartyModels(dto.localPayingAgents),
    calculationAgent: toPartyModels(dto.calculationAgents),
    listingAgents: toPartyModels(dto.listingAgents),
    transferAgents: toPartyModels(dto.transferAgents),
    registrars: toPartyModels(dto.registrars),
    fiscalAgents: toPartyModels(dto.fiscalAgents),
    eurosystemEligibility: dto.eurosystemEligibility || false,
    commonSafekeepers: toPartyModels(dto.commonSafekeepers),
    competentAuthorities: toPartyModels(dto.competentAuthorities),
    authorityProgrammeIdentifier: dto.authorityProgrammeIdentifier || null,
    authorityProgrammeIdentifierDate: stringToDate(dto.authorityProgrammeIdentifierDateAsString) || null,
    oldAuthorityProgrammeIdentifier: dto.oldAuthorityProgrammeIdentifier || null,
    oldAuthorityProgrammeIdentifierDate: stringToDate(dto.oldAuthorityProgrammeIdentifierDateAsString) || null,
    status: dto.status || null,
    idWorkflow: dto.idWorkflow || null
  };
};
export const getProgrammeFieldFromRole = (type: ROLES_TYPE): keyof ProgrammeModel | null => {
  switch (type) {
    case 'ISSUING_AGENT':
      return 'issuingAgents';
    case 'COMMON_DEPOSITARY':
      return 'commonDepositaries';
    case 'PRINCIPAL_PAYING_AGENT':
      return 'principalPayingAgents';
    case 'LOCAL_PAYING_AGENT':
      return 'localPayingAgents';
    case 'LISTING_AGENT':
      return 'listingAgents';
    case 'REGISTRAR':
      return 'registrars';
    case 'FISCAL_AGENT':
      return 'fiscalAgents';
    case 'CALCULATION_AGENT':
      return 'calculationAgent';
    case 'TRANSFER_AGENT':
      return 'transferAgents';
    default:
      return null;
  }
};
