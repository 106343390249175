<div class="input-group" >
  <div *ngIf="prependLabel&&value" class="input-group-prepend">
    <span class="input-group-text">{{prependLabel | translate:addOnParams}}</span>
  </div>
  <input *ngIf="!isReadOnly else showData" [(ngModel)]="value" (focus)="onFocus($event)" (blur)="onBlur($event)"
    appFractional class="form-control {{innerClass}}" [disabled]="isReadOnly" [placeholder]="placeholder" />
  <ng-template #showData><span class="me-2 mt-1">{{value}}</span></ng-template>
  <div *ngIf="appendLabel&&value" class="input-group-append">
    <span class="input-group-text">{{appendLabel | translate:addOnParams}}</span>
  </div>
</div>
 