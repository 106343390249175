/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PartyDto } from './partyDto';


/**
 * Programme to create
 */
export interface ProgrammeDto { 
    id?: number;
    icsdProgrammeNumber?: string;
    programmeName?: string;
    issuer?: PartyDto;
    issuingAgents?: Array<PartyDto>;
    commonDepositaries?: Array<PartyDto>;
    principalPayingAgents?: Array<PartyDto>;
    localPayingAgents?: Array<PartyDto>;
    calculationAgents?: Array<PartyDto>;
    listingAgents?: Array<PartyDto>;
    transferAgents?: Array<PartyDto>;
    registrars?: Array<PartyDto>;
    fiscalAgents?: Array<PartyDto>;
    commonSafekeepers?: Array<PartyDto>;
    competentAuthorities?: Array<PartyDto>;
    eurosystemEligibility?: boolean;
    authorityProgrammeIdentifier?: string;
    authorityProgrammeIdentifierDateAsString?: string;
    oldAuthorityProgrammeIdentifier?: string;
    oldAuthorityProgrammeIdentifierDateAsString?: string;
    status?: string;
    idWorkflow?: string;
}

