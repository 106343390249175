import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { HighchartsChartModule } from 'highcharts-angular';
import { AgGridModule } from 'ag-grid-angular';
import { HomeComponent } from './components';
import { StaHighchartsChartPieComponent } from './components/home/spi-highcharts-chart-pie/sta-highcharts-chart-pie.component';
import { SpiEventGridComponent } from './components/home/spi-event-grid/spi-event-grid.component';
import { IssuingSettlementIndicatorsGridComponent } from './components/issuing-settlement-indicators-grid/issuing-settlement-indicators-grid.component';
import { GlobalNoteIndicatorsGridComponent } from './components/global-note-indicators-grid/global-note-indicators-grid.component';
import { HeaderModule } from '@shared/modules/header/header.module';
import { ButtonsModule } from '@shared/modules/buttons/buttons.module';

@NgModule({
  declarations: [HomeComponent, StaHighchartsChartPieComponent, SpiEventGridComponent, IssuingSettlementIndicatorsGridComponent, GlobalNoteIndicatorsGridComponent],
  imports: [CommonModule, TranslateModule, SharedModule.forRoot(), HighchartsChartModule, AgGridModule.withComponents([]), HeaderModule, ButtonsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HomeModule {
  static forRoot(): ModuleWithProviders<HomeModule> {
    return {
      ngModule: HomeModule
    };
  }
}
