import { GnDisposalItemDto } from '@apis/backend/instruments';

export interface DisposalModel {
  readonly disposalId?: number;
  readonly archiveBoxNumber?: string;
  readonly archiveDate?: string;
  readonly disposalItems?: DisposalItemModel[];
}

export interface DisposalItemModel {
  readonly id?: number;
  readonly icsd?: string;
  readonly receptionDate?: string;
}

export const toDisposalItemsModel = (model: GnDisposalItemDto | null | undefined): DisposalItemModel => ({
  id: model?.id,
  icsd: model?.icsd,
  receptionDate: model?.receptDateAsString
});

export const toGnDisposalItemDto = (model: DisposalItemModel | null | undefined): GnDisposalItemDto => ({
  id: model?.id,
  icsd: model?.icsd,
  receptDateAsString: model?.receptionDate
});
