import { Injectable } from '@angular/core';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { AgGridColumnDefBaseService } from '@shared/services/aggrid-column-def-base-service';
import { INSTRUMENT_SETTLEMENT_COLUMNS } from './instrument-settlement-column-def';
import { InstrumentModel } from '@instruments/models';
import { INSTRUMENT_CONSTANTS } from '@instruments/configs/instrument-constants';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { amountFormatter } from './instrument-settlement-column-def-edit';

@Injectable()
export class InstrumentSettlementColumnDefService extends AgGridColumnDefBaseService {
  constructor(translateService: I18nUtilsService) {
    super(translateService, 'instruments.instrument.forms.settlement', INSTRUMENT_SETTLEMENT_COLUMNS);
  }
  getWithCustomAmounts(instrument: InstrumentModel | null): ColGroupDef[] {
    const instrumentSettlementColumnDef = this.get();
    return (instrumentSettlementColumnDef as ColGroupDef[]).map(col => {
      if (col['headerName'] === 'settlement') {
        const group: ColGroupDef = col;
        group.children.forEach((q: ColDef) => {
          if (q.field === 'issuePrice') {
            const issuePrice = group.children.find((q: ColDef) => q.field === 'issuePrice') as ColDef;
            if (issuePrice) {
              if (instrument?.tradingMethodIdentifier?.valueDescription === INSTRUMENT_CONSTANTS.Types.tradingMethods.NOMINAL) {
                issuePrice.valueFormatter = (params: { value: string }) => amountFormatter(params) + '%';
              } else {
                issuePrice.valueFormatter = (params: { value: string }) => amountFormatter(params) ?? '';
              }
            }
          }
        });
      }
      return col;
    });
  }
}
