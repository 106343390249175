import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { EventsService } from '@shared/services/events.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ValuationService } from '@shared/services/valuation.service';
import { ValuationDetailModel } from '@valuation/models';
import { EventDetailModel } from './models';

@Injectable({
  providedIn: 'root'
})
export class EventtResolve implements Resolve<[EventDetailModel | null, ValuationDetailModel | null]> {
  constructor(private readonly service: EventsService, private readonly valuationService: ValuationService, private readonly router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<[EventDetailModel | null, ValuationDetailModel | null]> {
    const id = route.params['id'];
    const instrumentId: any = this.router.getCurrentNavigation()?.extras.state;
    return this.service.getCompleteEventDetails(Number(id), instrumentId as number).pipe(
      mergeMap(event => {
        return this.valuationService.getValuationDetail(Number(id), false).pipe(
          map(valuation => [event, valuation] as [EventDetailModel | null, ValuationDetailModel | null]),
          catchError(() => [of(null), event] as [any, EventDetailModel])
        );
      })
    );
  }
}
