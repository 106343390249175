import { Injectable } from '@angular/core';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { AgGridColumnDefBaseService } from '@shared/services/aggrid-column-def-base-service';
import { PERMANENT_MONITORING_COLUMNS } from './permanent-monitoring-column-def';

@Injectable()
export class PermanentMonitoringColumnDefService extends AgGridColumnDefBaseService {
  constructor(translateService: I18nUtilsService) {
    super(translateService, 'instruments.instrument.forms.permanentMonitoring', PERMANENT_MONITORING_COLUMNS);
  }
}
