import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DialogModalComponent } from '@shared/modules/modals/components';
import { InstrumentFormService } from '../forms';

@Injectable()
export class InstrumentModalsService {
  constructor(private readonly modalService: NgbModal, private readonly instrumentFormService: InstrumentFormService) {}

  private get isEditMode() {
    return this.instrumentFormService.formMode === 'edit';
  }

  public openSaveModal(): NgbModalRef {
    const modal = this.modalService.open(DialogModalComponent);
    const dialog: DialogModalComponent = modal.componentInstance;
    dialog.dialogTitle = this.isEditMode ? 'modals.titles.editInstrument' : 'modals.titles.saveInstrument';
    dialog.dialogBody = this.isEditMode ? 'modals.contents.editInstrument' : 'modals.contents.saveInstrument';
    return modal;
  }

  public openSaveSettlement(): NgbModalRef {
    const modal = this.modalService.open(DialogModalComponent);
    const dialog: DialogModalComponent = modal.componentInstance;
    dialog.dialogTitle = 'modals.titles.saveSettlement';
    dialog.dialogBody = 'modals.contents.saveSettlement';
    return modal;
  }

  public openSaveCommonDepositary(): NgbModalRef {
    const modal = this.modalService.open(DialogModalComponent);
    const dialog: DialogModalComponent = modal.componentInstance;
    dialog.dialogTitle = 'modals.titles.saveCommonDepositary';
    dialog.dialogBody = 'modals.contents.saveCommonDepositary';
    return modal;
  }

  public openCancelModal(title?: string, body?: string): NgbModalRef {
    const modal = this.modalService.open(DialogModalComponent);
    const dialog: DialogModalComponent = modal.componentInstance;
    dialog.dialogTitle = title ? title : 'modals.titles.formCancel';
    dialog.dialogBody = body ? body : 'modals.contents.formCancel';
    return modal;
  }

  public openConfirmCloseInstrument(): NgbModalRef {
    const modal = this.modalService.open(DialogModalComponent);
    const dialog: DialogModalComponent = modal.componentInstance;
    dialog.dialogTitle = 'instruments.modals.titles.close-instrument';
    dialog.dialogBody = 'instruments.modals.contents.close-instrument';
    return modal;
  }
}
