/**
 * issuer-ipa-events-api API
 * Manage events data
 *
 * The version of the OpenAPI document: 1.1.16
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ValuationSearchRequestDTO } from './valuationSearchRequestDTO';


/**
 * Primary id of a Events
 */
export interface SearchValuationPaginationDto { 
    /**
     * Requested page number.
     */
    pageNumber?: number;
    /**
     * Number of items per page.
     */
    itemsByPage?: number;
    criteria?: ValuationSearchRequestDTO;
}

