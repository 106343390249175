<div>
  <div class="btn-group btn-group-toggle" #yesNoButtons>
    <ng-container *ngIf="!isReadOnly; else readOnly">
      <button name="yesNoButtons" [value]="true" type="button" class="btn btn-toggle-primary" [attr.id]="focusId"
        [class.active]="internalValue" (keyup.enter)="updateValue(true)" (click)="updateValue(true)"
        [disabled]="isReadOnly" translate>{{ yesLabel }}</button>
      <button name="yesNoButtons" [value]="false" type="button" class="btn btn-toggle-primary"
        [class.active]="!internalValue" (keyup.enter)="updateValue(false)" (click)="updateValue(false)"
        [disabled]="isReadOnly" translate>{{ noLabel }}</button>
    </ng-container>
    <ng-template #readOnly>
      <span [class]="classes" translate *ngIf="value"> {{ yesLabel }}</span>
      <span [class]="classes" translate *ngIf="!value"> {{ noLabel }}</span></ng-template>
  </div>
</div>
