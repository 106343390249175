import { Injectable } from '@angular/core';
import { EventsPositionResourceService, EventPositionEntitlementResponse } from '@apis/backend/payments';
import { Observable, of } from 'rxjs';
import { CreatePositionModel, toCreateEventsPositionDto, UpdatePositionModel, toUpdateEventsPositionDto, toEventPositionSearchDto } from '@events/models';

@Injectable({
  providedIn: 'root'
})
export class EventsPositionService {
  constructor(private readonly eventPositionResourceService: EventsPositionResourceService) {}

  getPositions(eventId: number): Observable<EventPositionEntitlementResponse | null> {
    const dto = toEventPositionSearchDto(eventId);
    if (!dto) {
      return of(null);
    }
    return this.eventPositionResourceService.searchForEventPositions(dto);
  }

  createPosition(position: CreatePositionModel): Observable<EventPositionEntitlementResponse | null> {
    const dto = toCreateEventsPositionDto(position);
    if (!dto) {
      return of(null);
    }
    return this.eventPositionResourceService.createEventPositions(dto);
  }

  updatePosition(position: UpdatePositionModel): Observable<EventPositionEntitlementResponse | null> {
    const dto = toUpdateEventsPositionDto(position);
    if (!dto) {
      return of(null);
    }
    return this.eventPositionResourceService.createEventPositions(dto);
  }
}
