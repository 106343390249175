import { Component, Input } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-amount-format-cell',
  templateUrl: './amount-format-cell.component.html'
})
export class AmountFormatCellComponent {
  @Input() display = true;
  params: ICellRendererParams | undefined;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  getValue(): string | number | undefined {
    return this.params?.value;
  }
}
