<app-header [backButton]="false" containerType="container-fluid">
  <div headerBody class="d-flex flex-row justify-content-between mb-1 mt-1">
    <h1 class="display-3 line-height-1" translate [translateParams]="{ variable: 'Events' }">Events</h1>
    <div class="d-flex justify-content-end">
      <app-text-icon-button (clicked)="getEventsReport()" [disabled]="disableSearch" class="me-1" icon="save_alt"
        label="{{'events.buttons.eventReport'|translate}}" size="md" [customClasses]="'btn-icon-start'" buttonType="btn-icon-text">
      </app-text-icon-button>

      <app-text-icon-button (clicked)="onRegenerateSchedulesClick()" [disabled]="disableSearch" color="primary" class="me-1"
      label="{{'events.buttons.regenerateSchedules'|translate}}"  size="md" buttonType="btn-primary">
      </app-text-icon-button>

      <app-text-icon-button *ngIf="canCreateEvents" (clicked)="onCreateEventClick()" [disabled]="disableSearch" icon="add" color="primary"
        label="{{'events.buttons.createEvent'|translate}}" size="md" [customClasses]="'btn-icon-start'" buttonType="btn-primary">
      </app-text-icon-button>
    </div>
  </div>
</app-header>

<div class="softLoading loading" *ngIf="loading">Loading</div>
<div class="softLoading" *ngIf="disableSearch">Loading</div>
<div class="container-fluid content-margin-top">
  <div class="row">
    <div class="col-md-12">
      <form>
        <app-event-list-filter [disableExportCsv]="disableSearch" (exportCSV)="exportCSV()">
          <app-text-icon-button (clicked)="search()" icon="search" [disabled]="disableSearch" searchTemplate customClasses="d-flex ms-auto btn-icon-start"
            [submit]="true" label="{{'events.list.search'|translate}}" size="md" buttonType="btn-outline-default">
          </app-text-icon-button>
        </app-event-list-filter>
      </form>
    </div>
  </div>
  <app-events-list [scheduledEvents]="scheduledEvents" (selectedISIN)="selectedISIN($event)"
    *ngIf="loaded| async; else noData"></app-events-list>

    <div class="row mt-2">
      <div class="col-md-12" *ngIf=displayPagination>
        <app-sg-pagination [pageSize]="itemsByPage" [totalItems]="totalItems" (pageChanged)="onPageChanged($event)"
          [currentPage]="pageNumber" enableCellTextSelection=true [maxPagesToDisplay]="10"></app-sg-pagination>
      </div>
    </div>
    
  <ng-template #noData>
    <div class="col-12 justify-content-center" [class.d-none]="!scheduledEvents" [class.d-flex]="scheduledEvents">
      <div class="text-center text-secondary">
        <em class="icon icon-xl d-inline-block">search</em>
        <div class="h4" translate>ag-grid.title</div>
        <p translate>ag-grid.searchSubTitle</p>
      </div>
    </div>
  </ng-template>
</div>
