import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DialogModalComponent } from '@shared/modules/modals/components';
import { Forms } from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class ProgrammeModalsService {
  constructor(private readonly modalService: NgbModal) {}
  public openSaveModal(formMode: Forms.FormMode): NgbModalRef {
    const modal = this.modalService.open(DialogModalComponent);
    const dialog: DialogModalComponent = modal.componentInstance;
    const title = formMode === 'add' ? 'modals.titles.saveProgramme' : 'modals.titles.editProgramme';
    const body = formMode === 'add' ? 'modals.contents.saveProgramme' : 'modals.contents.editProgramme';

    dialog.dialogTitle = title;
    dialog.dialogBody = body;
    return modal;
  }

  public openCancelModal(): NgbModalRef {
    const modal = this.modalService.open(DialogModalComponent);
    const dialog: DialogModalComponent = modal.componentInstance;
    dialog.dialogTitle = 'modals.titles.formCancel';
    dialog.dialogBody = 'modals.contents.formCancel';
    return modal;
  }
}
