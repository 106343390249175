import { UpdateScheduleEvent } from '@apis/backend/events';

export interface RegenerateSchedulesModel {
  isin?: string;
  ipaCode?: number;
  couponType?: string;
  couponFrequency?: number;
}

export const toRegenerateSchedules = (model: RegenerateSchedulesModel): UpdateScheduleEvent => {
  return {
    ipaCode: model.ipaCode,
    couponType: model.couponType,
    couponFrequency: model.couponFrequency,
    scheduleRegenerate: true
  };
};
