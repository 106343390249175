<div class="flex d-flex">
  <h3 class="text-secondary fw-medium" translate>instruments.instrument.forms.instrumentheader.isin</h3>
  <h3 class="fw-medium text-primary ms-1">{{value.isinReference}}</h3>
</div>
<div class="container g-0 ms-1 mt-2">
  <div class="row gy-4">
    <div class="col-md-1">
      <label class="text-secondary fw-medium"
        translate>instruments.instrument.forms.instrumentheader.ipaCode</label>
      <div>
        <span class=" fw-medium">{{value.id}}</span>
      </div>
    </div>
    <div class="col-md-1">
      <label class="text-secondary fw-medium"
        translate>instruments.instrument.forms.instrumentheader.seriesNumber</label>
      <div>
        <span class=" fw-medium">{{value.seriesNumber}}</span>
      </div>
    </div>
    <div class="col-md-2">
      <label class="text-secondary fw-medium"
        translate>instruments.instrument.forms.instrumentheader.Compartmentnumber</label>
      <div>
        <span class=" fw-medium">{{value.compartmentNumber}}</span>
      </div>
    </div>
    <div [ngClass]="{'col-md-2': programme,'col-md-6':!programme}" class="col-md-2">
      <label class="text-secondary fw-medium"
        translate>instruments.instrument.forms.instrumentheader.issuerName</label>
      <div>
        <span class=" fw-medium">{{value.issuer?.name}}</span>
      </div>
    </div>
    <div class="col-md-4" *ngIf="programme">
      <label class="text-secondary fw-medium"
        translate>instruments.instrument.forms.instrumentheader.program</label>
      <div>
        <a [routerLink]="['/programme/consult', programme ? programme.id : null]"
          class=" fw-medium">{{ programme ? programme.programmeName : null }}</a>
      </div>
    </div>
  </div>
  <div class="row mt-1 gy-4">
    <div class="col-md-2">
      <label class="text-secondary fw-medium"
        translate>instruments.instrument.forms.instrumentheader.complianceRestriction</label>
      <div>
        <span class=" fw-medium" [yesNoLabel]="value.complianceRestriction"></span>
      </div>
    </div>
    <div class="col-md-2">
      <label class="text-secondary fw-medium"
        translate>instruments.instrument.forms.instrumentheader.paymentDefault</label>
      <div>
        <span class=" fw-medium" [yesNoLabel]="value.paymentDefault"></span>
      </div>
    </div>
  </div>
</div>
