import { AmountFormatCellComponent } from '@shared/components/amount-format-cell/amount-format-cell.component';
import { ColDef, ValueFormatterParams } from 'ag-grid-community/dist/lib/main';
import { OperationDetailItemModel } from '../models/operation-detail-item.model';

export interface OperationDetailColumnDef extends ColDef {
  field: keyof OperationDetailItemModel;
}

export const OPERATION_DETAIL_COLUMNS: OperationDetailColumnDef[] = [
  {
    field: 'csdParty',
    sortable: true,
    resizable: true,
    cellRenderer: (data: any) => data?.value?.name ?? data?.value
  },
  {
    field: 'depositoryParty',
    sortable: true,
    resizable: true,
    cellRenderer: (data: any) => data?.value?.name ?? data?.value
  },
  {
    field: 'direction',
    sortable: true,
    resizable: true
  },
  {
    field: 'value',
    cellRendererFramework: AmountFormatCellComponent,
    sortable: true,
    resizable: true,
    cellStyle: { display: 'flex', 'justify-content': 'flex-end' }
  },
  {
    field: 'semeReference',
    sortable: true,
    resizable: true
  },
  {
    field: 'sendSwifts',
    valueFormatter: (q: ValueFormatterParams) => {
      if (q.data.sendSwifts) {
        return 'True';
      }
      return 'False';
    },
    sortable: true,
    resizable: true
  },
  {
    field: 'accountNumber',
    sortable: true,
    resizable: true
  }
];
