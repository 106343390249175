import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { ValuationModel } from '@valuation/models/valuation.model';
import { ValuationActionComponent } from './valuation-action/valuation-action.component';
import * as moment from 'moment';
import { MOMENT_DATE_FORMAT } from '@shared/pipes/date-format/common';

export interface ValuationColumnDef extends ColDef {
  field: keyof (ValuationModel & ValuationColumnsAction);
}

interface ValuationColumnsAction {
  actions: string;
}

const dateComparator = (date1: any, date2: any) => {
  const date1Number = monthToComparableNumber(date1);
  const date2Number = monthToComparableNumber(date2);
  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }
  return date1Number - date2Number;
};

const monthToComparableNumber = (date: any) => {
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }
  const yearNumber = date.substring(6, 10);
  const monthNumber = date.substring(3, 5);
  const dayNumber = date.substring(0, 2);
  return yearNumber * 10000 + monthNumber * 100 + dayNumber;
};

export const VALUATION_COLUMNS: ValuationColumnDef[] = [
  {
    field: 'valuationId'
  },
  {
    field: 'eventRef'
  },
  {
    field: 'isinCode'
  },
  {
    field: 'eventType',
    maxWidth: 150
  },
  {
    field: 'redemptionTypeDescription'
  },
  {
    field: 'paymentCCY',
    maxWidth: 150
  },
  {
    field: 'valueDate',
    maxWidth: 150,
    comparator: dateComparator,
    valueFormatter: (q: ValueFormatterParams) => {
      if (!q.data.valueDate) {
        return '';
      }
      const momentDate = moment(q.data.valueDate);
      return momentDate.format(MOMENT_DATE_FORMAT);
    }
  },
  {
    field: 'valuationDate',
    maxWidth: 150,
    comparator: dateComparator,
    valueFormatter: (q: ValueFormatterParams) => {
      if (!q.data.valuationDate) {
        return '';
      }
      const momentDate = moment(q.data.valuationDate);
      return momentDate.format(MOMENT_DATE_FORMAT);
    }
  },
  {
    field: 'receiptDate',
    maxWidth: 150,
    comparator: dateComparator,
    valueFormatter: (q: ValueFormatterParams) => {
      if (!q.data.receiptDate) {
        return '';
      }
      const momentDate = moment(q.data.receiptDate);
      return momentDate.format(MOMENT_DATE_FORMAT);
    }
  },
  {
    field: 'valuationStatusDescription'
  },
  {
    field: 'valuationVersion'
  },
  {
    field: 'rejectionReason'
  },
  {
    field: 'comments'
  },
  {
    field: 'actions',
    cellRendererFramework: ValuationActionComponent,
    maxWidth: 130,
    pinned: 'right'
  }
];
