import { OnInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MouvementModel } from '@positions/models';
import { MovementOfPositionCriteriaModel } from '@positions/models/movement-position-criteria.model';
import { MovementOfPositionResultModel } from '@positions/models/movements-position.model';
import { PositionService } from '@positions/services';
import { LocationService } from '@shared/services/location.service';
import { PermissionService } from '@shared/services/permission.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-mouvements',
  templateUrl: './mouvements.component.html'
})
export class MouvementsComponent implements OnInit, OnDestroy {
  position?: MovementOfPositionResultModel | null;
  private readonly shutdown$ = new Subject<void>();
  public canCreateOperation: boolean | undefined;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly locationService: LocationService,
    private readonly positionService: PositionService,
    public readonly permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.permissionService.canCreateOperation$.pipe(takeUntil(this.shutdown$)).subscribe({
      next: (canCreateOperation: boolean) => {
        this.canCreateOperation = canCreateOperation;
      }
    });

    const queryString = this.activatedRoute.snapshot.queryParams.criteria;
    if (queryString) {
      const query = JSON.parse(queryString) as MovementOfPositionCriteriaModel;
      this.load(query);
    }
  }

  public get mouvements(): MouvementModel[] {
    return this.position?.movements ?? [];
  }
  load(criteria: MovementOfPositionCriteriaModel): void {
    this.positionService
      .getPositionMovements(criteria)
      .pipe(takeUntil(this.shutdown$))
      .subscribe(r => {
        this.position = r;
      });
  }

  goback(): void {
    if (this.locationService.navgationHistories.length >= 1) {
      this.router.navigateByUrl(this.locationService.getBackUrl());
    }
  }
  ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}
