import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateParserFormatter, NgbDatepickerModule, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { CustomDateParserFormatter } from './custom-date-parser-formatter.service';
import { DatePickerComponent } from './date-picker.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [DatePickerComponent],
  imports: [CommonModule, NgbDatepickerModule, FormsModule, ReactiveFormsModule, TranslateModule, SharedModule],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ],
  exports: [DatePickerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DatePickerModule {}
