/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { getWidgetBus } from '@shared/sgwt/getWidgetBus';

@Injectable({
  providedIn: 'root'
})
export class SgConnectService {
  public getEmail(): string {
    let email = '';
    const bus = getWidgetBus();
    if (bus) {
      bus.subscribe('sg-connect.user-connection', (user: any) => {
        email = user.mail;
      });
    }
    return email ? email : '';
  }

  public getToken(): string {
    let token = '';
    const bus = getWidgetBus();
    if (bus) {
      bus.subscribe('sg-connect.access-token', (tk: any) => {
        token = tk;
      });
    }
    return token ? token : '';
  }
}
