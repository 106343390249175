export type TYPED_DATA_TYPE =
  | 'BUSINESS_DAY_CONVENTION'
  | 'CALCULATION_TIME'
  | 'COUPON_FREQUENCY'
  | 'COUPON_TYPE'
  | 'SECOND_COUPON_TYPE'
  | 'DAY_COUNT_FRACTION'
  | 'SECOND_DAY_COUNT_FRACTION'
  | 'GLOBAL_NOTE_FORM'
  | 'INSTRUMENT_REGULATION'
  | 'INSTRUMENT_TYPE'
  | 'LABEL_CATEGORY'
  | 'LEGAL_DESK_ISSUER_COUNTRY'
  | 'LEGAL_FORM'
  | 'LISTING_COUNTRY'
  | 'LISTING_PLACE'
  | 'OPTION_EXERCISE_TYPE'
  | 'PARTIAL_REDEMPTION_TYPE'
  | 'PAYMENT_DATE_MGT_MODE'
  | 'PHYSICAL_FORM'
  | 'RECORD_DATE_CALC_MODE'
  | 'RECORD_DATE_OFFSET_REF'
  | 'TRADING_METHOD'
  | 'INSTRUMENT_WORKFLOW_STATUS'
  | 'FINANCIAL_CENTER_TRADING_PLACES'
  | 'EVENT_TYPE'
  | 'PAYMENTS_CONFIRMATION_STATUS'
  | 'EVENT_WORKFLOW_STATUS'
  | 'EVENT_REDEMPTION_TYPE'
  | 'VALUATION_STATUS'
  | 'PAYMENT_TYPE'
  | 'SETTLEMENT_WORKFLOW_STATUS'
  | 'OPERATION_TYPE'
  | 'OPERATION_WORKFLOW_STATUS'
  | 'TRANCHE_WORKFLOW_STATUS'
  | 'INTERVENTION_TYPE'
  | 'ECASH_PAYMENT_STATUS'
  | 'MX_MESSAGE_TYPE';
