<app-header (goBack)="onBackClick()" [moveElementDown]="bodyContainer">
</app-header>
<div class="container" #bodyContainer>
    <div class="border">
        <div class="card">
            <div class="card-header border-top" id="eCashPaymentDetails">
                <h5 class="mb-0">
                    <button class="btn btn-link" type="button" data-toggle="collapse"
                        data-target="#collapseECashPaymentDetails" aria-expanded="true"
                        aria-controls="collapseECashPaymentDetails">
                        <i class="icon icon-sm me-2 text-secondary">
                            keyboard_arrow_right
                        </i>
                        <span translate>eCashPayments.form.eCashPaymentDetails</span>
                    </button>
                </h5>
            </div>
            <div id="collapseECashPaymentDetails" class="collapse show" aria-labelledby="headingOne">
                <div class="card-body pt-0">
                    <div class="section-forms" [formGroup]="formGroup">
                        <div class="row">
                            <div class="col-md-3">
                                <app-form-item [field]="fields.eventId">
                                    <input class="form-control" type="text" [formControlName]="fields.eventId"
                                        component #eventId />
                                </app-form-item>
                            </div>
                            <div class="col-md-3">
                                <app-form-item [field]="fields.eCashId" [focus]="eCashId">
                                    <input class="form-control" type="text" [formControlName]="fields.eCashId"
                                        component #eCashId />
                                </app-form-item>
                            </div>
                            <div class="col-md-3">
                                <app-form-item [field]="fields.paymentEndToEndId">
                                    <input class="form-control" type="text" [formControlName]="fields.paymentEndToEndId"
                                        component #paymentEndToEndId />
                                </app-form-item>
                            </div>
                            <div class="col-md-3">
                                <app-form-item [field]="fields.identification">
                                    <input class="form-control" type="text" [formControlName]="fields.identification"
                                        component #identification/>
                                </app-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <app-form-item [field]="fields.amount" [focus]="amount">
                                    <input class="form-control" type="text"
                                        [formControlName]="fields.amount" component #amount/>
                                </app-form-item>
                            </div>
                            <div class="col-md-3">
                                <app-form-item [field]="fields.currency">
                                    <input class="form-control" type="text" [formControlName]="fields.currency"
                                        component #currency/>
                                </app-form-item>
                            </div>
                            <div class="col-md-3">
                                <app-form-item [field]="fields.paymentType">
                                    <input class="form-control" type="text" [formControlName]="fields.paymentType"
                                        component #paymentType/>
                                </app-form-item>
                            </div>
                            <div class="col-md-3">
                                <app-form-item [field]="fields.eCashPaymentStatus">
                                    <input class="form-control" type="text" [formControlName]="fields.eCashPaymentStatus"
                                        component #eCashPaymentStatus/>
                                </app-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <app-form-item [field]="fields.creationDate" [focus]="creationDate">
                                    <app-date-picker [formControlName]="fields.creationDate" #creationDate component>
                                 </app-date-picker>
                               </app-form-item>
                            </div>
                            <div class="col-md-3">
                                <app-form-item [field]="fields.paymentErrorMessage">
                                    <input class="form-control" type="text" [formControlName]="fields.paymentErrorMessage"
                                        component #paymentErrorMessage/>
                                </app-form-item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>