import { TypedDataModel } from '@shared/models';

export const VALUATION_REJECT_REASONS: TypedDataModel[] = [
  { valueDescription: 'Amount calculated different from amount received', valueId: '1' },
  { valueDescription: 'CANCEL', valueId: '2' },
  { valueDescription: 'Currency mismatch', valueId: '3' },
  { valueDescription: 'Day Count Fraction mismatch', valueId: '4' },
  { valueDescription: 'Denomination is mismatch', valueId: '5' },
  { valueDescription: 'Duplicate events found', valueId: '6' },
  { valueDescription: 'EVENT not found', valueId: '7' },
  { valueDescription: 'End date mismatch', valueId: '8' },
  { valueDescription: 'Event is Already inProgress!', valueId: '9' },
  { valueDescription: 'Event status is already progressed', valueId: '10' },
  { valueDescription: 'ISIN not found', valueId: '11' },
  { valueDescription: 'Manual- PRED event', valueId: '12' },
  { valueDescription: 'Manual- PCAL event', valueId: '13' },
  { valueDescription: 'Mismatch with the Total number of underlying found', valueId: '14' },
  { valueDescription: 'New Pool Factor mismatch', valueId: '15' },
  { valueDescription: 'Nominal currency mismatch', valueId: '16' },
  { valueDescription: 'Nominal is mismatch', valueId: '17' },
  { valueDescription: 'PoseIn is Present - manual processing', valueId: '18' },
  { valueDescription: 'Previous Pool Factor mismatch', valueId: '19' },
  { valueDescription: 'Record date is null', valueId: '20' },
  { valueDescription: 'RedemptionType  mismatch', valueId: '21' },
  { valueDescription: 'Start date mismatch', valueId: '22' },
  { valueDescription: 'UPDATE', valueId: '23' },
  { valueDescription: 'Value date mismatch', valueId: '24' },
  { valueDescription: 'paymentCurrency is mismatch', valueId: '25' },
  { valueDescription: 'redemptionPrice is mismatch', valueId: '26' },
  { valueDescription: 'Pose in and Pose Out is invalid for INTR', valueId: '27' },
  { valueDescription: 'Start date mismatchEnd date mismatch', valueId: '28' },
  { valueDescription: 'Start date/End date mismatch', valueId: '29' },
  { valueDescription: 'is mismatch', valueId: '30' },
  { valueDescription: 'Amount mismatch - decimals', valueId: '31' },
  { valueDescription: 'Null rejection reason', valueId: '32' }
];

export const VALUATION_DASHBOARD_QUERY_REJECT_REASONS: TypedDataModel[] = [
  { valueDescription: 'Amount calculated different from amount received', valueId: '1' },
  { valueDescription: 'Currency mismatch', valueId: '3' },
  { valueDescription: 'Day Count Fraction mismatch', valueId: '4' },
  { valueDescription: 'Denomination is mismatch', valueId: '5' },
  { valueDescription: 'Duplicate events found', valueId: '6' },
  { valueDescription: 'EVENT not found', valueId: '7' },
  { valueDescription: 'End date mismatch', valueId: '8' },
  { valueDescription: 'ISIN not found', valueId: '11' },
  { valueDescription: 'Manual- PRED event', valueId: '12' },
  { valueDescription: 'Manual- PCAL event', valueId: '13' },
  { valueDescription: 'Mismatch with the Total number of underlying found', valueId: '14' },
  { valueDescription: 'New Pool Factor mismatch', valueId: '15' },
  { valueDescription: 'Nominal currency mismatch', valueId: '16' },
  { valueDescription: 'Nominal is mismatch', valueId: '17' },
  { valueDescription: 'PoseIn is Present - manual processing', valueId: '18' },
  { valueDescription: 'Previous Pool Factor mismatch', valueId: '19' },
  { valueDescription: 'Record date is null', valueId: '20' },
  { valueDescription: 'RedemptionType  mismatch', valueId: '21' },
  { valueDescription: 'Start date mismatch', valueId: '22' },
  { valueDescription: 'Value date mismatch', valueId: '24' },
  { valueDescription: 'paymentCurrency is mismatch', valueId: '25' },
  { valueDescription: 'redemptionPrice is mismatch', valueId: '26' },
  { valueDescription: 'Pose in and Pose Out is invalid for INTR', valueId: '27' },
  { valueDescription: 'Start date mismatchEnd date mismatch', valueId: '28' },
  { valueDescription: 'Start date/End date mismatch', valueId: '29' },
  { valueDescription: 'is mismatch', valueId: '30' },
  { valueDescription: 'Amount mismatch - decimals', valueId: '31' },
  { valueDescription: 'Null rejection reason', valueId: '32' }
];
