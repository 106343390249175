import { Injectable } from '@angular/core';
import { FormService } from '@shared/services/forms/form.service';
import { FormBuilder } from '@angular/forms';
import { ECashPaymentsDetailModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class EcashPaymentsDetailFormService extends FormService<ECashPaymentsDetailModel> {
  constructor(fb: FormBuilder) {
    super('eCashPaymentsDetail', fb, {
      eventId: fb.control(null),
      eCashId: fb.control(null),
      currency: fb.control(null),
      amount: fb.control(null),
      paymentEndToEndId: fb.control(null),
      identification: fb.control(null),
      creationDate: fb.control(null),
      paymentType: fb.control(null),
      eCashPaymentStatus: fb.control(null),
      paymentErrorMessage: fb.control(null)
    });
  }
}
