<div class="d-flex flex-row justify-content-end mb-0 mt-2">
  <button *ngIf="canEdit" class="btn btn-lg btn-flat-info ms-3 btn-icon-start" (click)="onEditClick()">
    <em class="icon">mode_edit</em>
    <span class="title-case">{{ 'instruments.buttons.modify.common-depository' | translate }}</span>
  </button>
  <div *ngIf="canSave" class="d-flex flex-row">
    <button (click)="onCancelClick()" (keyup.enter)="onCancelClick()" class="btn btn-lg btn-flat-primary"
      translate>common-buttons.cancel </button>
    <button (click)="onSaveClick()" (keyup.enter)="onSaveClick()" class="btn btn-lg btn-primary ms-3"
      translate>common-buttons.save </button>
  </div>
</div>

<div class="col-md-12">
  <app-collapse title="{{ 'instruments.instrument.forms.documentationCdReception.title' | translate }}" [hide]="false">
    <app-documentation-cd-reception></app-documentation-cd-reception>
  </app-collapse>
  <hr class="mb-3 mt-3" />
  <app-collapse [hide]="false" title="{{ 'instruments.instrument.forms.permanentMonitoring.title' | translate }}">
    <app-permanent-monitoring></app-permanent-monitoring>
  </app-collapse>
  <hr class="mb-3 mt-3" />
  <app-collapse [hide]="false" title="{{ 'instruments.instrument.forms.disposal.title' | translate }}">
    <app-disposal></app-disposal>
  </app-collapse>
</div>
