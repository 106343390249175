import { Pipe, PipeTransform } from '@angular/core';
import { ActiveFilterItem } from '@shared/models';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { toTitleCase } from '@utils/utility-functions';
import * as moment from 'moment';

@Pipe({
  name: 'activeFilterItem'
})
export class ActiveFilterItemPipe<T> implements PipeTransform {
  constructor(private readonly i18nUtils: I18nUtilsService) {}
  transform(item: ActiveFilterItem<T>, keyBase = ''): unknown {
    if (item.value === 'True' || item.value === 'False' || typeof item.value === 'boolean') {
      return this.i18nUtils.getI18nValue(`${keyBase}${item.value}`);
    }
    if ((typeof item.value === 'string' && item.value.length === 24 && moment(item.value, moment.ISO_8601, true).isValid()) || item.value instanceof Date) {
      return this.formatDate(item) ?? item.value.toString();
    }
    if (typeof item.value === 'string') {
      return item.value;
    }
    if ('valueDescription' in item.value) {
      return toTitleCase(item.value.valueDescription);
    }
    if ('name' in item.value) {
      return toTitleCase(item.value.name);
    }
    if (Array.isArray(item.value)) {
      return item.value.map(v => this.transform({ key: item.key, value: v, isMultipleValuesFilter: true })).join(', ');
    }
    return item.value;
  }
  private formatDate(item: ActiveFilterItem<T>) {
    if ((typeof item.value === 'string' && moment(item.value).isValid()) || item.value instanceof Date) {
      return moment(item.value).format('DD-MM-YYYY');
    }
    return null;
  }
}
