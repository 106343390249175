import { Component, EventEmitter, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActiveFilterItem } from '@shared/models';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { Subject } from 'rxjs';
import { ActiveFiltersBaseComponent } from '@shared/search-base';
import { PaymentCriteriaSearchModel } from '@payment/models';
import { PaymentFiltersSaveComponent } from '../filters-save/payment-filters-save.component';
import { PaymentCriteriaSearchFormService } from '@payment/services';

@Component({
  selector: 'app-payment-list-active-filters',
  templateUrl: './payment-list-active-filters.component.html'
})
export class PaymentListActiveFiltersComponent extends ActiveFiltersBaseComponent<PaymentCriteriaSearchModel> {
  @Output() filtersChanged = new EventEmitter<ActiveFilterItem<PaymentCriteriaSearchModel>[]>();
  public filterItems: ActiveFilterItem<PaymentCriteriaSearchModel>[] = [];
  public shutdown$ = new Subject<void>();

  constructor(formService: PaymentCriteriaSearchFormService, modalService: NgbModal, i18nUtils: I18nUtilsService) {
    super(formService, modalService, i18nUtils, 'payments-filters', 'payment.list.filters');
  }
  public onFilterChanged(items: ActiveFilterItem<PaymentCriteriaSearchModel>[]): void {
    this.filtersChanged.emit(items);
  }
  public saveFilters(): NgbModalRef {
    const modal = this.modalService.open(PaymentFiltersSaveComponent);
    const dialog: PaymentFiltersSaveComponent = modal.componentInstance;
    dialog.value = this.criteriaFormService.value();
    dialog.keyBase = this.keyBase;
    return modal;
  }
}
