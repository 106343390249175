import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-settlement-action',
  templateUrl: './settlement-action.component.html'
})
export class SettlementActionComponent {
  params: ICellRendererParams | undefined;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
}
