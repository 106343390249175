/**
 * issuer-ipa-events-extended API
 * Manage eventss data
 *
 * The version of the OpenAPI document: 1.1.21
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CreatePaymentsConfirmation } from '../model/models';
import { IdsDto } from '../model/models';
import { PaymentConfPagingResultDto } from '../model/models';
import { PaymentConfirmationUpdateResponseDTO } from '../model/models';
import { PaymentsConfirmationSearchOutputDto } from '../model/models';
import { Problem } from '../model/models';
import { SearchPaymentConfPaginationDto } from '../model/models';
import { SearchPaymentsConfirmation } from '../model/models';
import { UpdatePaymentsConfirmation } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class PaymentsConfirmationResourceService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * create
     * Create Payments Confirmation  **Authorization policy:**  Scopes: api.issuer-ipa-events-extended.v1, api.issuer-ipa-events-extended.payment-confirmation.create; Authorities: SGM_ISS_PAY_api.issuer-ipa-events-extended.payment-confirmation.create
     * @param createPaymentsConfirmation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPaymentConfirmation(createPaymentsConfirmation: Array<CreatePaymentsConfirmation>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<IdsDto>;
    public createPaymentConfirmation(createPaymentsConfirmation: Array<CreatePaymentsConfirmation>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<IdsDto>>;
    public createPaymentConfirmation(createPaymentsConfirmation: Array<CreatePaymentsConfirmation>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<IdsDto>>;
    public createPaymentConfirmation(createPaymentsConfirmation: Array<CreatePaymentsConfirmation>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (createPaymentsConfirmation === null || createPaymentsConfirmation === undefined) {
            throw new Error('Required parameter createPaymentsConfirmation was null or undefined when calling createPaymentConfirmation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<IdsDto>(`${this.configuration.basePath}/v1/paymentsConfirmations`,
            createPaymentsConfirmation,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * search
     * Search for Payments confirmation  **Authorization policy:**  Scopes: api.issuer-ipa-events-extended.v1, api.issuer-ipa-events-extended.payment-confirmation.read; Authorities: SGM_ISS_PAY_api.issuer-ipa-events-extended.payment-confirmation.read
     * @param searchPaymentsConfirmation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public csvReportPaymentConfirmation(searchPaymentsConfirmation: SearchPaymentsConfirmation, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<Blob>;
    public csvReportPaymentConfirmation(searchPaymentsConfirmation: SearchPaymentsConfirmation, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<Blob>>;
    public csvReportPaymentConfirmation(searchPaymentsConfirmation: SearchPaymentsConfirmation, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<Blob>>;
    public csvReportPaymentConfirmation(searchPaymentsConfirmation: SearchPaymentsConfirmation, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (searchPaymentsConfirmation === null || searchPaymentsConfirmation === undefined) {
            throw new Error('Required parameter searchPaymentsConfirmation was null or undefined when calling csvReportPaymentConfirmation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.configuration.basePath}/v1/paymentsConfirmations/_export`,
            searchPaymentsConfirmation,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * search
     * Search for Payments confirmation  **Authorization policy:**  Scopes: api.issuer-ipa-events-extended.v1, api.issuer-ipa-events-extended.payment-confirmation.read; Authorities: SGM_ISS_PAY_api.issuer-ipa-events-extended.payment-confirmation.read
     * @param searchPaymentsConfirmation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPaymentConfirmation(searchPaymentsConfirmation: SearchPaymentsConfirmation, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<PaymentsConfirmationSearchOutputDto>;
    public searchPaymentConfirmation(searchPaymentsConfirmation: SearchPaymentsConfirmation, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<PaymentsConfirmationSearchOutputDto>>;
    public searchPaymentConfirmation(searchPaymentsConfirmation: SearchPaymentsConfirmation, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<PaymentsConfirmationSearchOutputDto>>;
    public searchPaymentConfirmation(searchPaymentsConfirmation: SearchPaymentsConfirmation, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (searchPaymentsConfirmation === null || searchPaymentsConfirmation === undefined) {
            throw new Error('Required parameter searchPaymentsConfirmation was null or undefined when calling searchPaymentConfirmation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PaymentsConfirmationSearchOutputDto>(`${this.configuration.basePath}/v1/paymentsConfirmations/_search`,
            searchPaymentsConfirmation,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * search
     * Search for Payments confirmation with pagination  **Authorization policy:**  Scopes: api.issuer-ipa-events-extended.v1, api.issuer-ipa-events-extended.payment-confirmation.read; Authorities: SGM_ISS_PAY_api.issuer-ipa-events-extended.payment-confirmation.read
     * @param searchPaymentConfPaginationDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPaymentConfirmationWithPagination(searchPaymentConfPaginationDto: SearchPaymentConfPaginationDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<PaymentConfPagingResultDto>;
    public searchPaymentConfirmationWithPagination(searchPaymentConfPaginationDto: SearchPaymentConfPaginationDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<PaymentConfPagingResultDto>>;
    public searchPaymentConfirmationWithPagination(searchPaymentConfPaginationDto: SearchPaymentConfPaginationDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<PaymentConfPagingResultDto>>;
    public searchPaymentConfirmationWithPagination(searchPaymentConfPaginationDto: SearchPaymentConfPaginationDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (searchPaymentConfPaginationDto === null || searchPaymentConfPaginationDto === undefined) {
            throw new Error('Required parameter searchPaymentConfPaginationDto was null or undefined when calling searchPaymentConfirmationWithPagination.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PaymentConfPagingResultDto>(`${this.configuration.basePath}/v1/paymentsConfirmations/_searchWithPagination`,
            searchPaymentConfPaginationDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update
     * Update for Payments confirmation  **Authorization policy:**  Scopes: api.issuer-ipa-events-extended.v1, api.issuer-ipa-events-extended.payment-confirmation.update; Authorities: SGM_ISS_PAY_api.issuer-ipa-events-extended.payment-confirmation.update
     * @param updatePaymentsConfirmation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePaymentConfirmation(updatePaymentsConfirmation: Array<UpdatePaymentsConfirmation>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<PaymentConfirmationUpdateResponseDTO>;
    public updatePaymentConfirmation(updatePaymentsConfirmation: Array<UpdatePaymentsConfirmation>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<PaymentConfirmationUpdateResponseDTO>>;
    public updatePaymentConfirmation(updatePaymentsConfirmation: Array<UpdatePaymentsConfirmation>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<PaymentConfirmationUpdateResponseDTO>>;
    public updatePaymentConfirmation(updatePaymentsConfirmation: Array<UpdatePaymentsConfirmation>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {
        if (updatePaymentsConfirmation === null || updatePaymentsConfirmation === undefined) {
            throw new Error('Required parameter updatePaymentsConfirmation was null or undefined when calling updatePaymentConfirmation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<PaymentConfirmationUpdateResponseDTO>(`${this.configuration.basePath}/v1/paymentsConfirmations`,
            updatePaymentsConfirmation,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
