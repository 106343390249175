/**
 * issuer-ipa-events-api API
 * Manage events data
 *
 * The version of the OpenAPI document: 1.1.16
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CreateEventScheduleBatchDto } from '../model/models';
import { CreateScheduleEvent } from '../model/models';
import { DashboardResponseDto } from '../model/models';
import { EventScheduleBatchResponseDto } from '../model/models';
import { Problem } from '../model/models';
import { ScheduleEventDetailsResponse } from '../model/models';
import { ScheduleEventPagingResultDto } from '../model/models';
import { ScheduleEventResponse } from '../model/models';
import { ScheduleEventXmlFeedDto } from '../model/models';
import { SearchSchedulePaginationDto } from '../model/models';
import { SecheduleSearchInput } from '../model/models';
import { UpdateScheduleEvent } from '../model/models';
import { UpdateStatusDTO } from '../model/models';
import { UpdateStatusResponseDTO } from '../model/models';
import { XmlFeedResponseDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ScheduleEventResourceService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * batch
     * Create Batch Schedule  **Authorization policy:**  Scopes: api.issuer-ipa-events.v1, api.issuer-ipa-events.schedule.create; Authorities: SGM_ISS_PAY_api.issuer-ipa-events.schedule.create
     * @param createEventScheduleBatchDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBatchEvents(createEventScheduleBatchDto: CreateEventScheduleBatchDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<EventScheduleBatchResponseDto>;
    public createBatchEvents(createEventScheduleBatchDto: CreateEventScheduleBatchDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<EventScheduleBatchResponseDto>>;
    public createBatchEvents(createEventScheduleBatchDto: CreateEventScheduleBatchDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<EventScheduleBatchResponseDto>>;
    public createBatchEvents(createEventScheduleBatchDto: CreateEventScheduleBatchDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (createEventScheduleBatchDto === null || createEventScheduleBatchDto === undefined) {
            throw new Error('Required parameter createEventScheduleBatchDto was null or undefined when calling createBatchEvents.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<EventScheduleBatchResponseDto>(`${this.configuration.basePath}/v1/scheduleEvents/_batch`,
            createEventScheduleBatchDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create
     * Create a Schedule  **Authorization policy:**  Scopes: api.issuer-ipa-events.v1, api.issuer-ipa-events.schedule.create; Authorities: SGM_ISS_PAY_api.issuer-ipa-events.schedule.create
     * @param createScheduleEvent 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createEvents(createScheduleEvent: CreateScheduleEvent, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<ScheduleEventResponse>;
    public createEvents(createScheduleEvent: CreateScheduleEvent, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<ScheduleEventResponse>>;
    public createEvents(createScheduleEvent: CreateScheduleEvent, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<ScheduleEventResponse>>;
    public createEvents(createScheduleEvent: CreateScheduleEvent, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (createScheduleEvent === null || createScheduleEvent === undefined) {
            throw new Error('Required parameter createScheduleEvent was null or undefined when calling createEvents.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ScheduleEventResponse>(`${this.configuration.basePath}/v1/scheduleEvents`,
            createScheduleEvent,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create schedule event for XML feed
     * Create a Schedule with event  **Authorization policy:**  Scopes: api.issuer-ipa-events.v1, api.issuer-ipa-events.schedule.create; Authorities: SGM_ISS_PAY_api.issuer-ipa-events.schedule.create
     * @param scheduleEventXmlFeedDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createScheduleEvents(scheduleEventXmlFeedDto: ScheduleEventXmlFeedDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<XmlFeedResponseDto>;
    public createScheduleEvents(scheduleEventXmlFeedDto: ScheduleEventXmlFeedDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<XmlFeedResponseDto>>;
    public createScheduleEvents(scheduleEventXmlFeedDto: ScheduleEventXmlFeedDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<XmlFeedResponseDto>>;
    public createScheduleEvents(scheduleEventXmlFeedDto: ScheduleEventXmlFeedDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (scheduleEventXmlFeedDto === null || scheduleEventXmlFeedDto === undefined) {
            throw new Error('Required parameter scheduleEventXmlFeedDto was null or undefined when calling createScheduleEvents.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<XmlFeedResponseDto>(`${this.configuration.basePath}/v1/scheduleEvents/xmlFeedScheduleEvents`,
            scheduleEventXmlFeedDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete
     * Deleting Scheduled Event  **Authorization policy:**  Scopes: api.issuer-ipa-events.v1, api.issuer-ipa-events.schedule.delete; Authorities: SGM_ISS_PAY_api.issuer-ipa-events.schedule.delete
     * @param eventReference Primary id of a Event
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteEvents(eventReference: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<ScheduleEventResponse>;
    public deleteEvents(eventReference: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<ScheduleEventResponse>>;
    public deleteEvents(eventReference: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<ScheduleEventResponse>>;
    public deleteEvents(eventReference: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {
        if (eventReference === null || eventReference === undefined) {
            throw new Error('Required parameter eventReference was null or undefined when calling deleteEvents.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<ScheduleEventResponse>(`${this.configuration.basePath}/v1/scheduleEvents/${encodeURIComponent(String(eventReference))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * exportEventsData
     * Get paginated schedule Data  **Authorization policy:**  Scopes: api.issuer-ipa-events.v1, api.issuer-ipa-events.schedule.read; Authorities: SGM_ISS_PAY_api.issuer-ipa-events.schedule.read
     * @param secheduleSearchInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportEventsData(secheduleSearchInput: SecheduleSearchInput, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/octet-stream'}): Observable<Blob>;
    public exportEventsData(secheduleSearchInput: SecheduleSearchInput, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public exportEventsData(secheduleSearchInput: SecheduleSearchInput, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public exportEventsData(secheduleSearchInput: SecheduleSearchInput, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/octet-stream'}): Observable<any> {
        if (secheduleSearchInput === null || secheduleSearchInput === undefined) {
            throw new Error('Required parameter secheduleSearchInput was null or undefined when calling exportEventsData.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.configuration.basePath}/v1/scheduleEvents/_export`,
            secheduleSearchInput,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * issScheduleEventReports
     * issScheduleEventReports  **Authorization policy:**  Scopes: api.issuer-ipa-events.v1, api.issuer-ipa-events.schedule.read; Authorities: SGM_ISS_PAY_api.issuer-ipa-events.schedule.read
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public issScheduleEventReports(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/octet-stream'}): Observable<Blob>;
    public issScheduleEventReports(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public issScheduleEventReports(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public issScheduleEventReports(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/octet-stream'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/v1/scheduleEvents/issScheduleEventReports`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * scheduleStatusUpdates
     * Update list of schedule Status  **Authorization policy:**  Scopes: api.issuer-ipa-events.v1, api.issuer-ipa-events.schedule.update; Authorities: SGM_ISS_PAY_api.issuer-ipa-events.schedule.update
     * @param updateStatusDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scheduleStatusUpdate(updateStatusDTO: Array<UpdateStatusDTO>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<UpdateStatusResponseDTO>;
    public scheduleStatusUpdate(updateStatusDTO: Array<UpdateStatusDTO>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<UpdateStatusResponseDTO>>;
    public scheduleStatusUpdate(updateStatusDTO: Array<UpdateStatusDTO>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<UpdateStatusResponseDTO>>;
    public scheduleStatusUpdate(updateStatusDTO: Array<UpdateStatusDTO>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (updateStatusDTO === null || updateStatusDTO === undefined) {
            throw new Error('Required parameter updateStatusDTO was null or undefined when calling scheduleStatusUpdate.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<UpdateStatusResponseDTO>(`${this.configuration.basePath}/v1/scheduleEvents/scheduleStatusUpdates`,
            updateStatusDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * search
     * Get Dashborad Data  **Authorization policy:**  Scopes: api.issuer-ipa-events.v1, api.issuer-ipa-events.schedule.read; Authorities: SGM_ISS_PAY_api.issuer-ipa-events.schedule.read
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchDashboardDataDetails(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<DashboardResponseDto>;
    public searchDashboardDataDetails(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<DashboardResponseDto>>;
    public searchDashboardDataDetails(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<DashboardResponseDto>>;
    public searchDashboardDataDetails(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DashboardResponseDto>(`${this.configuration.basePath}/v1/scheduleEvents/_searchDashboardDataDetails`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * search
     * Get a Scheduled Event  **Authorization policy:**  Scopes: api.issuer-ipa-events.v1, api.issuer-ipa-events.schedule.read; Authorities: SGM_ISS_PAY_api.issuer-ipa-events.schedule.read
     * @param secheduleSearchInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchEvents(secheduleSearchInput: SecheduleSearchInput, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<ScheduleEventResponse>;
    public searchEvents(secheduleSearchInput: SecheduleSearchInput, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<ScheduleEventResponse>>;
    public searchEvents(secheduleSearchInput: SecheduleSearchInput, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<ScheduleEventResponse>>;
    public searchEvents(secheduleSearchInput: SecheduleSearchInput, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (secheduleSearchInput === null || secheduleSearchInput === undefined) {
            throw new Error('Required parameter secheduleSearchInput was null or undefined when calling searchEvents.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ScheduleEventResponse>(`${this.configuration.basePath}/v1/scheduleEvents/_search`,
            secheduleSearchInput,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * search
     * Get a Scheduled Event  **Authorization policy:**  Scopes: api.issuer-ipa-events.v1, api.issuer-ipa-events.schedule.read; Authorities: SGM_ISS_PAY_api.issuer-ipa-events.schedule.read
     * @param secheduleSearchInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchScheduleEvents(secheduleSearchInput: SecheduleSearchInput, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<ScheduleEventDetailsResponse>;
    public searchScheduleEvents(secheduleSearchInput: SecheduleSearchInput, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<ScheduleEventDetailsResponse>>;
    public searchScheduleEvents(secheduleSearchInput: SecheduleSearchInput, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<ScheduleEventDetailsResponse>>;
    public searchScheduleEvents(secheduleSearchInput: SecheduleSearchInput, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (secheduleSearchInput === null || secheduleSearchInput === undefined) {
            throw new Error('Required parameter secheduleSearchInput was null or undefined when calling searchScheduleEvents.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ScheduleEventDetailsResponse>(`${this.configuration.basePath}/v1/scheduleEvents/_searchScheduleEvents`,
            secheduleSearchInput,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchScheduleWithPagination
     * Get paginated schedule Data  **Authorization policy:**  Scopes: api.issuer-ipa-events.v1, api.issuer-ipa-events.schedule.read; Authorities: SGM_ISS_PAY_api.issuer-ipa-events.schedule.read
     * @param searchSchedulePaginationDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchScheduleWithPagination(searchSchedulePaginationDto: SearchSchedulePaginationDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<ScheduleEventPagingResultDto>;
    public searchScheduleWithPagination(searchSchedulePaginationDto: SearchSchedulePaginationDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<ScheduleEventPagingResultDto>>;
    public searchScheduleWithPagination(searchSchedulePaginationDto: SearchSchedulePaginationDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<ScheduleEventPagingResultDto>>;
    public searchScheduleWithPagination(searchSchedulePaginationDto: SearchSchedulePaginationDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (searchSchedulePaginationDto === null || searchSchedulePaginationDto === undefined) {
            throw new Error('Required parameter searchSchedulePaginationDto was null or undefined when calling searchScheduleWithPagination.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ScheduleEventPagingResultDto>(`${this.configuration.basePath}/v1/scheduleEvents/_searchScheduleWithPagination`,
            searchSchedulePaginationDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update
     * Update a Schedule  **Authorization policy:**  Scopes: api.issuer-ipa-events.v1, api.issuer-ipa-events.schedule.update; Authorities: SGM_ISS_PAY_api.issuer-ipa-events.schedule.update
     * @param updateScheduleEvent 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEvents(updateScheduleEvent: UpdateScheduleEvent, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<ScheduleEventResponse>;
    public updateEvents(updateScheduleEvent: UpdateScheduleEvent, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<ScheduleEventResponse>>;
    public updateEvents(updateScheduleEvent: UpdateScheduleEvent, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<ScheduleEventResponse>>;
    public updateEvents(updateScheduleEvent: UpdateScheduleEvent, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (updateScheduleEvent === null || updateScheduleEvent === undefined) {
            throw new Error('Required parameter updateScheduleEvent was null or undefined when calling updateEvents.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<ScheduleEventResponse>(`${this.configuration.basePath}/v1/scheduleEvents`,
            updateScheduleEvent,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
