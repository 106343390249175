import { Component, OnInit, Input } from '@angular/core';
import { ColDef, GridOptions, GridApi, FirstDataRenderedEvent } from 'ag-grid-community';
import { EcashPaymentsColumnDefService } from './_aggrid/e-cash-payments-column-def-service';
import { ECashPaymentsModel } from '../../models';

@Component({
  selector: 'app-e-cash-payments-list',
  templateUrl: './e-cash-payments-list.component.html',
  providers: [EcashPaymentsColumnDefService]
})
export class ECashPaymentsListComponent implements OnInit {
  @Input() public eCashPayments: ECashPaymentsModel[] = [];
  public columnDefs: ColDef[] = [];
  public defaultColDef: any;
  public gridOptions!: GridOptions;
  public gridApi!: GridApi;
  public rowCount = 0;
  constructor(private readonly eCashPaymentsColumnDefService: EcashPaymentsColumnDefService) {}

  ngOnInit(): void {
    this.columnDefs = this.eCashPaymentsColumnDefService.get();
    this.gridOptions = {
      onFirstDataRendered: this.onFirstDataRendered,
      onGridSizeChanged: () => {
        this.gridOptions.api?.sizeColumnsToFit();
      },
      onColumnVisible: () => {
        this.columnResize();
      },
      onToolPanelVisibleChanged: () => {
        this.columnResize();
      },
      pagination: false,
      suppressPaginationPanel: false,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      suppressContextMenu: true
    };

    this.defaultColDef = {
      sortable: true,
      filter: false,
      suppressMenu: true,
      resizable: true,
      floatingFilterComponentParams: { suppressFilterButton: true }
    };
  }

  public sideBar: any | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        minWidth: 225,
        width: 225,
        maxWidth: 225,
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true
        }
      }
    ],
    defaultToolPanel: ''
  };

  onGridReady(params: any) {
    const gridApi = params.api;
    this.gridApi = params.api;
    gridApi.sizeColumnsToFit();
    gridApi.addGlobalListener(this.resizeColumn);
    gridApi.refreshHeader();
    this.rowCount = this.gridApi.getDisplayedRowCount();
  }
  onFirstDataRendered(params: FirstDataRenderedEvent): void {
    params.api.sizeColumnsToFit();
  }

  private columnResize() {
    setTimeout(() => {
      this.gridOptions.api?.sizeColumnsToFit();
    });
  }

  private readonly resizeColumn = (event: any) => {
    if (['gridSizeChanged', 'toolPanelVisibleChanged', 'columnVisible', 'rowDataChanged'].includes(event)) {
      this.columnResize();
    }
  };
}
