import { Component, EventEmitter, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PositionSearchCriteriaModel } from '@positions/models';
import { PositionCriteriaSearchFormService } from '@positions/services';
import { ActiveFilterItem } from '@shared/models';
import { ActiveFiltersBaseComponent } from '@shared/search-base';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-position-list-active-filters',
  templateUrl: './position-list-active-filters.component.html'
})
export class PositionListActiveFiltersComponent extends ActiveFiltersBaseComponent<PositionSearchCriteriaModel> {
  @Output() filtersChanged = new EventEmitter<ActiveFilterItem<PositionSearchCriteriaModel>[]>();
  public filterItems: ActiveFilterItem<PositionSearchCriteriaModel>[] = [];
  public shutdown$ = new Subject<void>();

  constructor(formService: PositionCriteriaSearchFormService, modalService: NgbModal, i18nUtils: I18nUtilsService) {
    super(formService, modalService, i18nUtils, 'positions-filters', 'positions.list.filters');
  }

  public onFilterChanged(items: ActiveFilterItem<PositionSearchCriteriaModel>[]): void {
    this.filtersChanged.emit(items);
  }

  public saveFilters(): NgbModalRef | null {
    return null;
  }
}
