<div class="d-flex justify-content-end">
  <button class="btn btn-lg btn-flat-secondary btn-icon-start" translate (click)="export()">
    <em class="icon">save_alt</em> common-buttons.CSV-export
  </button>
</div>
<div class="row mt-1">
  <div class="col-md-12">
    <ag-grid-angular #agGrid class="ag-theme-sg-bootstrap-condensed" [gridOptions]="gridOptions!"
      [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [rowData]="mouvements"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>
