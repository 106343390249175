import { Injectable } from '@angular/core';
import { InstrumentResourceService, MatrixInstrumentRoleDto, MatrixInstrumentRoleRequestDto } from '@apis/backend/instruments';
import { MatrixInstrumentRoleModel, toMatrixInstrumentRoleModel } from '@instruments/models/matrix_instrument-role.model';
import { PartyModel, toPartyDto, toPartyModel, ROLES_TYPE, TYPE_FILTER_TYPE, ProgrammeModel, getProgrammeFieldFromRole } from '@shared/models';
import { PartyService } from '@shared/services/party.service';
import { notEmpty } from '@utils/utility-functions';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, first, map, mergeMap, shareReplay, debounceTime, switchMap } from 'rxjs/operators';
import { InstrumentFormService } from '@instruments/services';

@Injectable()
export class InstrumentRoleMatrixService {
  constructor(private readonly formService: InstrumentFormService, private readonly intrumentService: InstrumentResourceService, private readonly typeService: PartyService) {}
  getDefaultParties(type: ROLES_TYPE, filter: TYPE_FILTER_TYPE, programme: ProgrammeModel | null | undefined): Observable<PartyModel[]> {
    return of(programme).pipe(
      map(programme => this.getPartiesFromProgramme(type, programme)),
      mergeMap(parties => {
        if (parties.length > 0) {
          return of(parties);
        }
        return this.typeService.getPartiesByRoles(type, filter).pipe(
          first(),
          map(r => [...r].sort(this.sortPartyModel))
        );
      })
    );
  }
  private sortPartyModel(a: PartyModel, b: PartyModel): number {
    const nameA = a.name || '';
    const nameB = b.name || '';
    return nameA.localeCompare(nameB);
  }
  getMatrix(): Observable<MatrixInstrumentRoleModel | null> {
    const valueChangeObs = this.formService.valueChanges(['internalProgrammeNumber', 'instrumentType', 'icsdProgrammeNumber', 'isinReference', 'listingCountry', 'clearingSystems']).pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      concatMap(valuesChanged => {
        if (valuesChanged.isinReference && valuesChanged.internalProgrammeNumber && valuesChanged.icsdProgrammeNumber && valuesChanged.instrumentType) {
          const request: MatrixInstrumentRoleRequestDto = {
            clearingSystems: (valuesChanged.clearingSystems || []).map(toPartyDto).filter(notEmpty),
            icsdProgrammeNumber: valuesChanged.icsdProgrammeNumber,
            instrumentType: valuesChanged.instrumentType,
            isinReference: valuesChanged.isinReference,
            listingCountry: valuesChanged.listingCountry ? valuesChanged.listingCountry : undefined
          };
          return this.intrumentService.getMatrixInstrumentRole(request).pipe(
            catchError(_ => {
              return EMPTY;
            }),
            map(dto => toMatrixInstrumentRoleModel(dto))
          );
        }
        return of(null);
      })
    );

    return this.formService.formMode$.pipe(
      switchMap(formMode => {
        if (formMode === 'consult' || formMode === 'edit') {
          return of(null);
        }
        return valueChangeObs;
      })
    );
  }
  public getRoleFromType(type: ROLES_TYPE): Observable<PartyModel[]> {
    return this.getMatrix().pipe(
      map(matrix => {
        return this.getParties(type, matrix);
      }),
      shareReplay()
    );
  }
  public getField<T>(type: ROLES_TYPE): keyof T | null {
    switch (type) {
      case 'ISSUING_AGENT':
        return 'issuingAgent' as keyof T;
      case 'COMMON_DEPOSITARY':
        return 'commonDepositary' as keyof T;
      case 'PRINCIPAL_PAYING_AGENT':
        return 'principalPayingAgent' as keyof T;
      case 'LOCAL_PAYING_AGENT':
        return 'localPayingAgent' as keyof T;
      case 'LISTING_AGENT':
        return 'listingAgent' as keyof T;
      case 'REGISTRAR':
        return 'registrar' as keyof T;
      case 'FISCAL_AGENT':
        return 'fiscalAgent' as keyof T;
      case 'CALCULATION_AGENT':
        return 'calculationAgent' as keyof T;
      default:
        return null;
    }
  }
  private getParties(type: ROLES_TYPE, matrix?: MatrixInstrumentRoleDto | null): PartyModel[] {
    const field = this.getField<MatrixInstrumentRoleDto>(type);
    if (field && matrix) {
      return [toPartyModel(matrix[field])].filter(notEmpty);
    }
    return [];
  }

  private getPartiesFromProgramme(type: ROLES_TYPE, programme: ProgrammeModel | null | undefined): PartyModel[] {
    if (!programme || !type) {
      return [];
    }
    const field = getProgrammeFieldFromRole(type);
    if (!field) {
      return [];
    }
    if (Array.isArray(programme[field]) && (programme[field] as PartyModel[]).filter(notEmpty).length > 0) {
      return (programme[field] as PartyModel[]).filter(notEmpty);
    } else if (!Array.isArray(programme[field]) && programme[field]) {
      return [programme[field] as PartyModel].filter(notEmpty);
    }
    return [];
  }
}
