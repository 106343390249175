export interface SettlementStatusHistoryModel {
  settlementId?: number;
  creationDateAsString?: string;
  settlementDateHistory?: string;
  status?: string;
}

export const toSettlementStatusHistoryModel = (dto: any): SettlementStatusHistoryModel => {
  return {
    settlementId: dto.settlementId,
    creationDateAsString: dto.creationDateAsString,
    status: dto.status,
    settlementDateHistory: dto.settlementDateHistory
  };
};
