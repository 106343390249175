import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../../shared.module';
import { DatePillComponent } from './date-pill/date-pill.component';
import { MonthDisplayComponent } from './month-display/month-display.component';
import { MultipleDayPickerComponent } from './multiple-day-picker.component';

@NgModule({
  declarations: [MultipleDayPickerComponent, MonthDisplayComponent, DatePillComponent],
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, TranslateModule],
  exports: [MultipleDayPickerComponent]
})
export class MultipleDayPickerModule {}
