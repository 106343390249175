import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { OperationDetailsModel } from '@positions/models';
import { FormService } from '@shared/services/forms/form.service';
import { CustomValidators } from '@shared/validators/custom-validator';

@Injectable()
export class OperationDetailFormService extends FormService<OperationDetailsModel> {
  constructor(fb: UntypedFormBuilder) {
    super('operation-detail', fb, {
      id: fb.control(null),
      instrumentId: fb.control(null),
      isin: fb.control(null, [Validators.required, CustomValidators.Isin]),
      valueDate: fb.control(null, [Validators.required, CustomValidators.ValidateDate]),
      instructionDate: fb.control(null, [Validators.required, CustomValidators.ValidateDate]),
      seriesNumber: fb.control(null),
      currency: fb.control(null),
      operationType: fb.control(null),
      operationStatus: fb.control(null),
      clientOperations: fb.control([])
    });
  }
}
