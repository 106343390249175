/**
 * issuer-ipa-events-api API
 * Manage events data
 *
 * The version of the OpenAPI document: 1.1.16
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Criteria for search Schedules
 */
export interface SecheduleSearchInput { 
    isSTA?: boolean;
    eventReferences?: Array<number>;
    isinLists?: Array<string>;
    ipaCodeLists?: Array<number>;
    notificationFromDate?: string;
    notificationToDate?: string;
    valueFromDate?: string;
    valueToDate?: string;
    reqFundFromDate?: string;
    reqFundToDate?: string;
    preAdviceFromDate?: string;
    preAdviceToDate?: string;
    eventStatuses?: Array<number>;
    eventTypes?: Array<number>;
    pageNumber?: number;
    pageSize?: number;
    paymentCurrencies?: Array<string>;
}

