import { Injectable } from '@angular/core';
import { TypedDataResourceService } from '@apis/backend/instruments';
import { TYPED_DATA_TYPE, TypedDataModel, TypedDataResponse } from '@shared/models';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ToastManagerService } from '../modules/toasts/service/toastManager.service';

@Injectable({
  providedIn: 'root'
})
export class TypedDataService {
  private typedData: Partial<Record<TYPED_DATA_TYPE, Observable<TypedDataModel[]>>> = {};
  constructor(private readonly typedDataResourceService: TypedDataResourceService, private readonly toastManagerService: ToastManagerService) {}

  getTypeByTypedData(dataTypeName: TYPED_DATA_TYPE): Observable<TypedDataModel[]> {
    let result = this.typedData[dataTypeName];
    if (result) {
      return result;
    }

    result = this.typedDataResourceService.getTypeByTypedData(dataTypeName).pipe(
      map((result: TypedDataResponse) => result.resources),
      shareReplay()
    );
    this.typedData[dataTypeName] = result;
    return result;
  }
}
