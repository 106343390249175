import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DialogModalComponent } from '@shared/modules/modals/components';
import { EventDateFormComponent, EventFormContainerComponent, EventRegenerateFormComponent, PositionFormComponent, EventAuditFormComponent } from '@events/components';
import { PositionsGridModel, PositionsTotalGridModel, EventPositionInputModel, AuditDetailModel } from '@events/models';

@Injectable()
export class EventModalsService {
  constructor(private readonly modalService: NgbModal) {}

  public openCreateEventModal(isin: string): NgbModalRef {
    const modal = this.modalService.open(EventFormContainerComponent, { size: 'lg', scrollable: true });
    const dialog: EventFormContainerComponent = modal.componentInstance;
    dialog.isin = isin;
    return modal;
  }

  public openRegenerateSchedulesModal(isin: string): NgbModalRef {
    const modal = this.modalService.open(EventRegenerateFormComponent, { size: 'md', scrollable: true });
    const dialog: EventRegenerateFormComponent = modal.componentInstance;
    dialog.isin = isin;
    return modal;
  }

  public openDeleteEventModal(): NgbModalRef {
    const modal = this.modalService.open(DialogModalComponent);
    const dialog: DialogModalComponent = modal.componentInstance;
    dialog.dialogTitle = 'modals.titles.deleteEvent';
    dialog.dialogBody = 'modals.contents.deleteEvent';
    return modal;
  }

  public openCancelModal(): NgbModalRef {
    const modal = this.modalService.open(DialogModalComponent);
    const dialog: DialogModalComponent = modal.componentInstance;
    dialog.dialogTitle = 'modals.titles.formCancel';
    dialog.dialogBody = 'modals.contents.formCancel';
    return modal;
  }
  public openDatesModal(data: any): NgbModalRef {
    const modal = this.modalService.open(EventDateFormComponent, { size: 'lg', scrollable: true });
    const dialog: EventDateFormComponent = modal.componentInstance;
    dialog.eventReference = data[0].eventReference;
    dialog.isin = data[0].isinReference;
    dialog.eventType = data[0].eventType.valueDescription;
    dialog.valueDate = data[0].valueDate;
    dialog.notificationDate = data[0].notificationDate;
    dialog.beginDate = data[0].beginDate;
    dialog.endDate = data[0].endDate;
    return modal;
  }

  public openEventCancelModal(): NgbModalRef {
    const modal = this.modalService.open(DialogModalComponent);
    const dialog: DialogModalComponent = modal.componentInstance;
    dialog.dialogTitle = 'modals.titles.cancelEvent';
    dialog.dialogBody = 'modals.contents.cancelEvent';
    return modal;
  }

  public openSaveModal(amountPerDenom: string | undefined, calculatedAmountPerDenom: string, currency: string | undefined): NgbModalRef {
    const modal = this.modalService.open(DialogModalComponent);
    const dialog: DialogModalComponent = modal.componentInstance;
    dialog.dialogTitle = 'modals.titles.saveEvent';
    let message = '';
    const alertMessage = `Calculated amount per denom is ${currency} ${calculatedAmountPerDenom} and manual entry is ${currency} ${amountPerDenom}. Please check.`;
    const generalMessage = 'Would you like to save the event ?';
    if (amountPerDenom && calculatedAmountPerDenom && Number(calculatedAmountPerDenom.replace(/,/gi, '')) !== Number(amountPerDenom.replace(/,/gi, ''))) {
      message = alertMessage + generalMessage;
    } else {
      message = generalMessage;
    }
    dialog.dialogBody = message;
    return modal;
  }

  public openReleasePaymentModal(positionsData: PositionsGridModel[], positionsTotalData: PositionsTotalGridModel[], eventPositionInput?: EventPositionInputModel): NgbModalRef {
    const modal = this.modalService.open(PositionFormComponent, { size: 'xl', scrollable: true });
    const dialog: PositionFormComponent = modal.componentInstance;
    dialog.positions = positionsData;
    dialog.positionsTotal = positionsTotalData;
    dialog.eventPositionInput = eventPositionInput;
    return modal;
  }

  public openAuditDetailsModal(userAuditData: AuditDetailModel | undefined): NgbModalRef {
    const modal = this.modalService.open(EventAuditFormComponent, { size: 'lg', scrollable: true });
    const dialog: EventAuditFormComponent = modal.componentInstance;
    dialog.eventValidatedBy = userAuditData?.eventValidatedBy || undefined;
    dialog.eventValidatedAt = userAuditData?.eventValidatedAt || undefined;
    dialog.eventSentforValidationBy = userAuditData?.eventSentforValidationBy || undefined;
    return modal;
  }

  public openClearingSystemReleasePaymentModal(positionsData: PositionsGridModel[], eventPositionInput: any): NgbModalRef {
    const modal = this.modalService.open(PositionFormComponent, { size: 'xl', scrollable: true });
    const dialog: PositionFormComponent = modal.componentInstance;
    dialog.positions = positionsData;
    dialog.eventPositionInput = eventPositionInput;
    dialog.hideTotalsGrid = true;
    return modal;
  }
}
