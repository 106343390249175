<div class="section-forms" [formGroup]="formGroup">
    <div class="container-fluid">
        <div class="row gy-2">
            <div class="col-md-3">
                <div class="input-group input-group-md">
                    <div class="input-icon-start">
                        <em class="icon text-secondary">search</em>
                    </div>
                    <input #eventId component type="search" placeholder="{{ placeholder(fields.eventId)  | translate }}"
                        class="form-control form-control-md" [formControlName]="fields.eventId">        
                </div>
            </div>
    
            <div class="col-md-3">
                <div class="input-group input-group-md">
                    <div class="input-icon-start">
                        <em class="icon text-secondary">search</em>
                    </div>
                    <input #eCashId component type="search"
                        placeholder="{{ placeholder(fields.eCashId)  | translate }}"
                        class="form-control form-control-md" [formControlName]="fields.eCashId">    
                </div>
            </div>
    
            <div class="col-md-3">
                <app-currency-multiple-select [formControlName]="fields.currency"
                    [placeholder]="placeholder(fields.currency) | translate" #currency [multiple]="true" component>
                </app-currency-multiple-select>
            </div>
    
            <div class="col-md-2">
                <app-advanced-filters-button [advancedFiltersShown]="!isCollapsed" (changed)="collapse.toggle()">
                </app-advanced-filters-button>
            </div>
    
            <div class="col-md-1">
                <ng-content select="[searchTemplate]"></ng-content>
            </div>
        </div>
    </div>
   
    <div class="container-fluid mt-2">
        <div class="row gy-2">
            <div class="col-md-3">
                <app-type-multiple-select #paymentType component [multiple]="false"
                    [formControlName]="fields.paymentType"
                    placeholder="{{ placeholder(fields.paymentType)  | translate }}" [closeOnSelect]="true"
                    styleClass="form-control-md" [type]="MX_MESSAGE_TYPE">
                </app-type-multiple-select>
            </div>
            <div class="col-md-3">
                <app-type-multiple-select #eCashPaymentStatus component [multiple]="false"
                    [formControlName]="fields.eCashPaymentStatus"
                    placeholder="{{ placeholder(fields.eCashPaymentStatus)  | translate }}" [closeOnSelect]="true"
                    styleClass="form-control-md" [type]="ECASH_PAYMENT_STATUS">
                </app-type-multiple-select>
            </div>
        </div>
    </div>

    <div class="container-fluid mt-3" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <div class="row gy-2">
            <div class="col-md-3">
                <div class="input-group input-group-md">
                    <sg-daterangepicker #creationDate component
                        (dateChanged)="onDatesChanged($event,'creationFromDate','creationToDate')" [endDate]="creationEndDate"
                        startPlaceholder="{{'eCashPayments.list.filters.creationDate'|translate}}"
                        endPlaceholder="{{'eCashPayments.list.filters.creationDate'|translate}}" [startDate]="creationBeginDate">
                    </sg-daterangepicker>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group input-group-md">
                    <div class="input-icon-start">
                        <em class="icon text-secondary">search</em>
                    </div>
                    <input #eventId component type="search" placeholder="{{ placeholder(fields.paymentE2EId)  | translate }}"
                        class="form-control form-control-md" [formControlName]="fields.paymentE2EId">
                </div>
            </div>            
        </div>
    </div>
    <div class="container-fluid g-0 mt-2 mb-4">
        <div class="row gy-2">
            <div class="col d-flex justify-content-end align-items-end">
                <button class="btn btn-lg btn-flat-secondary btn-icon-start" [disabled]="disableExportCsv" (click)="exportCsv()" *ngIf="canExport" translate>
                    <em class="icon">save_alt</em> common-buttons.CSV-export
                </button>
            </div>
        </div>
    </div>
</div>
