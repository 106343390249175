<nav class="navbar .border border-opacity-70 border-bottom border-top bg-lvl1">
  <div class="navbar-title">
    <a class="navbar-title-link">
      <div class="navbar-logo">
        <svg viewBox="0 0 161 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <style>
              .primary-class {
                fill: var(--bs-primary, #000);
              }

              .red-socgen-class {
                fill: var(--bs-socgen, #e9041e);
              }
            </style>
          </defs>
          <rect x="0" y="0" width="32" height="32" fill="white"></rect>
          <rect x="1" y="1" width="30" height="15" class="red-socgen-class"></rect>
          <rect x="1" y="16" width="30" height="15" fill="black"></rect>
          <rect x="6" y="15" width="20" height="2" fill="white"></rect>

          <path d="M51.4313 3.55556C49.7666 2.88889 47.8799 2.33333 46.3261 2.33333C43.9954 2.33333 42.7746 2.88887 42.7746 3.77776C42.7746 6.33331 53.3181 4.33332 53.3181 9.88887C53.3181 12.2222 51.2094 14 46.881 14C44.3284 14 42.3307 13.4444 40 12.2222L40.9989 10.1111C42.9966 11.2222 44.8833 11.7778 46.881 11.7778C49.4337 11.7778 50.7655 11 50.7655 9.88887C50.7655 6.99998 40.222 9.22221 40.222 4C40.222 1.77778 42.3307 0.222229 46.2151 0.222229C48.5458 0.222229 50.4325 0.77777 52.3193 1.66666L51.4313 3.55556Z" class="primary-class"></path>
          <path d="M63.1956 14C58.9782 14 55.5377 11.2222 55.5377 6.99998C55.5377 2.88887 58.9782 0 63.1956 0C67.413 0 70.8535 2.88887 70.8535 6.99998C70.7425 11.2222 67.413 14 63.1956 14ZM63.1956 2.22222C60.0881 2.22222 58.0903 4.33332 58.0903 6.99998C58.0903 9.66664 60.0881 11.7778 63.1956 11.7778C66.3032 11.7778 68.3008 9.77776 68.3008 6.99998C68.3008 4.33332 66.3032 2.22222 63.1956 2.22222Z" class="primary-class"></path>
          <path d="M80.8421 14C76.4027 14 73.0732 11.2222 73.0732 6.99998C73.0732 2.88887 76.4027 0 80.8421 0C83.3947 0 85.2814 0.777763 86.9462 1.99998L85.6144 3.88889C84.3936 3 82.8398 2.22222 80.8421 2.22222C77.6235 2.22222 75.6259 4.22223 75.6259 6.8889C75.6259 9.55556 77.6235 11.5555 80.8421 11.5555C82.8398 11.5555 84.5046 10.8889 85.6144 9.99999L86.9462 11.8889C85.3924 13.2222 83.3947 14 80.8421 14Z" class="primary-class"></path>
          <path d="M89.7208 0.444427H92.1625V13.6666H89.7208V0.444427Z" class="primary-class"></path>
          <path d="M95.7139 13.5555V0.444427H107.7V2.55553H98.1556V5.77778H105.592V8H98.1556V11.4444H108.144V13.5555H95.7139Z" class="primary-class"></path>
          <path d="M109.476 2.55553V0.444427H122.683V2.55553H117.245V13.5555H114.803V2.55553H109.476Z" class="primary-class"></path>
          <path d="M124.57 13.5555V0.444427H136.556V2.55553H127.011V5.77778H134.447V8H127.011V11.4444H137V13.5555H124.57Z" class="primary-class"></path>
          <path d="M47.8889 29.6667C49.2222 29.6667 50.5556 29.3333 51.6667 28.8889V25.1111H54V30.2222C52.3333 31.3333 50 32 47.7778 32C43.3333 32 40 29.2222 40 25C40 20.8889 43.3333 18 47.7778 18C50.3333 18 52.2222 18.6667 53.8889 20L52.5555 21.8889C51.3333 21 49.7778 20.3333 47.7778 20.3333C44.5556 20.2222 42.5555 22.3334 42.5555 25C42.5555 27.6667 44.5556 29.6667 47.8889 29.6667Z" class="primary-class"></path>
          <path d="M57.2222 31.5556V18.4445H69.2222V20.5556H59.6667V23.7778H67.1111V26H59.6667V29.4445H69.6667V31.5556H57.2222Z" class="primary-class"></path>
          <path d="M74.4444 31.5556H72V18.3333H74.4444L83 28.2222H83.1111C83 25.7778 83 23.6667 83 21.7778V18.3333H85.3333V31.5556H82.8888L74.3333 21.6667C74.4445 23.6667 74.4444 27 74.4444 28.1111V31.5556Z" class="primary-class"></path>
          <path d="M88.7778 31.5556V18.4445H100.778V20.5556H91.2222V23.7778H98.6666V26H91.2222V29.4445H101.222V31.5556H88.7778Z" class="primary-class"></path>
          <path d="M110.222 26.7778H106.333V31.6667H103.889V18.4445H112.333C115.111 18.4445 116.889 20.2222 116.889 22.6667C116.889 25 115.222 26.4445 113.111 26.7778L117 31.6667H113.889L110.222 26.7778ZM112 24.6667C113.556 24.6667 114.444 23.7778 114.444 22.6667C114.444 21.4445 113.667 20.6667 112.333 20.6667H106.333V24.6667H112Z" class="primary-class"></path>
          <path d="M122 28.4445L120.444 31.6667H117.778L124.222 18.4445H126.778L133.222 31.6667H130.556L129 28.4445H122ZM123 26.2222H128.111L125.556 20.8889L123 26.2222Z" class="primary-class"></path>
          <path d="M135.111 31.5556V18.4445H137.555V29.4445H146.333V31.5556H135.111Z" class="primary-class"></path>
          <path d="M148 31.5556V18.4445H160V20.5556H150.444V23.7778H157.889V26H150.444V29.4445H160.444V31.5556H148Z" class="primary-class"></path>
        </svg>
      </div>
      <div class="navbar-title-divider"></div>
    </a>
  </div>
  <div class="navbar-content">
    <ul class="navbar-navigation m-0">
      <li class="navbar-item">
        <a class="navbar-link text-capitalize" routerLink="/home" routerLinkActive="active" translate>navbar.home</a>
      </li>
      <li class="navbar-item">
        <a class="navbar-link text-capitalize" routerLink="/programme" routerLinkActive="active"
          translate>navbar.programmes</a>
      </li>
      <li class="navbar-item">
        <a class="navbar-link text-capitalize" routerLink="/instrument" routerLinkActive="active"
          translate>navbar.instruments</a>
      </li>
      <li class="navbar-item">
        <a class="navbar-link" routerLink="/position" routerLinkActive="active" translate>navbar.postions-keeping</a>
      </li>
      <li class="navbar-item dropdown">
        <a class="navbar-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" translate>
          navbar.events
        </a>
        <ul class="dropdown-menu" aria-labelledby="navbarDropdown" data-bs-popper="static">
          <li>
            <a class="dropdown-item text-capitalize" routerLink="/events" routerLinkActive="active" translate>navbar.scheduleEvents</a>
            <a class="dropdown-item text-capitalize" routerLink="/valuation" routerLinkActive="active" translate>navbar.valuation</a>
          </li>
          <li id="submenu">
            <a class="dropdown-item text-capitalize" role="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" translate>navbar.payments</a>
            <div class="dropdown-submenu" aria-labelledby="navbarDocuments">
              <div class="dropdown-content">
                <a class="dropdown-item text-capitalize" routerLink="/payment" routerLinkActive="active"
                  translate>navbar.paymentConfirmation</a>
                <a class="dropdown-item text-capitalize" routerLink="/eCashPayments" routerLinkActive="active"
                  translate>navbar.eCashPayments</a>
              </div>
            </div>
          </li>
        </ul>
      </li>
      <li class="navbar-item" *ngIf="permissionService.userCanReadExtcashaccount$|async">
        <a class="navbar-link" routerLink="/extcashaccount" routerLinkActive="active" translate>
          navbar.standard-settlement-instructions </a>
      </li>
    </ul>
  </div>

  <div class="navbar-toolbar">
    <div class="d-flex justify-content-end align-items-center">
      <sgwt-account-center
        #sgwtAccountCenter
        [available-languages]="langAvailable"
        language="EN"
        authentication="sg-connect-v2"
        [environment]="environment == 'production' ? '' : environment"
        [hideServicesLink]="authType == 'sg-connect-v2' ? null : 'true'"
        mode="sg-markets"
        show-languages-selection="true"
        show-sign-in-button="true"
        producer-code="sgm_iss_pay"
        (sgwt-account-center--language-changed)="onLanguageChanged($event)"
      >
      </sgwt-account-center>
      <svg class="d-none d-md-block overflow-visible ms-3" width="135" height="32">
        <image xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="assets/sg_market.svg" x="0" y="0" height="16"
          width="132"></image>
        <!--Change here name of services-->
        <text class="font-family-display" x="0" y="32" height="16">IPA</text>
      </svg>
    </div>
  </div>
</nav>
