import { Component, OnInit, Input } from '@angular/core';
import { PermanentMonitoringColumnDefService } from '@instruments/components/instrument-list/_aggrid/permanent-monitoring-column-def-service';
import { PermanentMonitoringItemModel } from '@instruments/models/permanent-monitoring.model';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';

@Component({
  selector: 'app-permanent-monitoring-consult',
  templateUrl: './consult.component.html',
  providers: [PermanentMonitoringColumnDefService]
})
export class PermanentMonitoringConsultComponent implements OnInit {
  public gridOptions!: GridOptions;
  public columnDefs: ColDef[] = [];
  public defaultColDef: unknown;
  public gridApi!: GridApi;
  @Input() permanentMonitoringItems?: PermanentMonitoringItemModel[] | null = [];

  constructor(private readonly permanentMonitoringColumnDefService: PermanentMonitoringColumnDefService) {}

  ngOnInit(): void {
    this.columnDefs = this.permanentMonitoringColumnDefService.get();
    this.gridOptions = {
      pagination: false,
      suppressPaginationPanel: false,
      paginationPageSize: 10,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      suppressContextMenu: true,
      stopEditingWhenCellsLoseFocus: true
    };
    this.defaultColDef = {
      sortable: false,
      filter: false,
      suppressMenu: true
    };
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.refreshHeader();
  }
}
