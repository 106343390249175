import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormService } from '@shared/services/forms/form.service';
import { OperationPagingWithCriteriaModel } from '../models/operation-search-criteria.model';

@Injectable()
export class OperationPagingCriteriaSearchFormService extends FormService<OperationPagingWithCriteriaModel> {
  constructor(fb: UntypedFormBuilder) {
    super('pagin_search_operation', fb, {
      pageNumber: fb.control(1),
      totalItems: fb.control(0),
      itemsByPage: fb.control(20)
    });
  }
}
