import { ColDef } from 'ag-grid-community/dist/lib/main';
import { dateFormatter } from './instrument-settlement-column-def-edit';
export interface PermanentMonitoringEditColumnDef extends ColDef {
  field: string;
}

export const PERMANENT_MONITORING_EDIT_COLUMNS: PermanentMonitoringEditColumnDef[] = [
  {
    field: 'dateOfPm',
    sortable: true,
    resizable: true,
    cellEditor: 'agDateInput',
    valueFormatter: dateFormatter,
    width: 250,
    editable: true,
    singleClickEdit: true
  },
  {
    field: 'pmOk',
    singleClickEdit: true,
    cellEditorParams: { values: ['Yes', 'No'] },
    cellEditor: 'agSelectCellEditor',
    sortable: true,
    resizable: true,
    width: 250,
    editable: true
  },
  {
    field: 'typeOfDiscordance',
    singleClickEdit: true,
    cellEditorParams: { values: ['OK', 'FT / NOTICE / REPORTING', 'FOLDER NOT FOUND', 'INCORRECT IPA SIGNAGE', 'SIGNATURE PROBLEM', 'REDEMPTION/NOMINAL ', 'OTHER', 'GN REFECTION'] },
    cellEditor: 'agSelectCellEditor',
    sortable: true,
    resizable: true,
    width: 400,
    editable: true
  },
  {
    field: 'comment',
    sortable: true,
    resizable: true,
    width: 550,
    editable: true
  }
];
