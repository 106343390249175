import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventFormService } from '@events/services';

@Component({
  selector: 'app-event-date-form',
  templateUrl: './event-date-form.component.html'
})
export class EventDateFormComponent {
  public isFormInValid = false;
  public ClosedReason: DialogResult = 'closed';
  public CancelReason: DialogResult = 'cancel';
  public ConfirmReason: DialogResult = 'confirm';
  confirmButton = 'modals.buttons.confirm';
  cancelButton = 'modals.buttons.cancel';

  @Input() set beginDate(value: any) {
    if (value) {
      this.eventFormService.patchField('beginDate', stringToDateFormat(value));
    }
  }

  @Input() set endDate(value: any) {
    if (value) {
      this.eventFormService.patchField('endDate', stringToDateFormat(value));
    }
  }

  @Input() set valueDate(value: any) {
    if (value) {
      this.eventFormService.patchField('valueDate', stringToDateFormat(value));
    }
  }

  @Input() set notificationDate(value: any) {
    if (value) {
      this.eventFormService.patchField('notificationDate', stringToDateFormat(value));
    }
  }

  @Input() set eventReference(value: any) {
    if (value) {
      this.eventFormService.patchField('eventId', value);
    }
  }

  @Input() set eventType(value: any) {
    if (value) {
      this.eventFormService.patchField('eventType', value);
    }
  }

  @Input() set isin(value: any) {
    if (value) {
      this.eventFormService.patchField('isin', value);
    }
  }

  constructor(private readonly activeModal: NgbActiveModal, private readonly eventFormService: EventFormService) {}

  public onClose(): void {
    this.activeModal.dismiss(this.ClosedReason);
    this.activeModal.close(this.ClosedReason);
  }

  public onCancel(): void {
    this.activeModal.close(this.CancelReason);
  }

  public onConfirm(): void {
    this.eventFormService.updateValueAndValidity();
    if (this.eventFormService.valid) {
      this.activeModal.close(this.ConfirmReason);
    }
  }

  disableConfirmButton(status: boolean) {
    this.isFormInValid = status;
  }
}

const stringToDateFormat = (date: string | undefined): Date | undefined => {
  if (date === undefined || null) {
    return undefined;
  }
  const [year, month, day] = date.split('-');
  return new Date(+year, +month - 1, +day);
};
