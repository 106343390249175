/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { InstrumentIdTrancheNumberDto } from '@apis/backend/instruments';

export interface InstrumentIdTrancheNumberModel {
  readonly instrumentId: number;
  readonly trancheNumber: number;
}

export const toInstrumentIdTrancheNumberModel = (dto: InstrumentIdTrancheNumberDto | null | undefined): InstrumentIdTrancheNumberModel | null => {
  if (!dto) {
    return null;
  }
  return {
    instrumentId: dto.instrumentIdParent ?? -1,
    trancheNumber: dto.trancheNumber ?? -1
  };
};
