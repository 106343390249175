import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';

import { HttpFeedbackService } from '../services/httpFeedback.service';

@Injectable()
export class HttpFeedbackInterceptor implements HttpInterceptor {
  // blacklist that contains urls ignored by the spinner
  // v1/documents/search is blacklisted because of a call that is made every 5 sec during 1 min to refresh a list
  // in this case we don't want the spinner to activate every 5 sec to block the entire interface.
  spinnerBlackList = ['v1/documents/_search', 'v1/instruments/_confirmations-by-zip'];

  constructor(private readonly feedbackService: HttpFeedbackService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const urlSuffix = request.url.split('/api/');
    // if urlSuffix is not contained in the blacklist we increment spinner counter to display it; else we don't
    if (urlSuffix && urlSuffix[1] && this.spinnerBlackList.indexOf(urlSuffix[1]) === -1) {
      this.feedbackService.incrementCount();
    }
    if (environment.production) {
      const prodRequest = request.clone({ url: request.url.replace('/api/', '/') });
      return this.handleRequest(prodRequest, next);
    }
    return this.handleRequest(request, next);
  }

  public handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 401 || error.status === 403) {
      this.feedbackService.notifyOfAuthenticationError();
    }
    return throwError(error);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((e: HttpErrorResponse) => this.handleError(e)),
      finalize(() => {
        this.feedbackService.decrementCount();
      })
    );
  }
}

export const httpFeedbackInterceptorProvider = [{ provide: HTTP_INTERCEPTORS, useClass: HttpFeedbackInterceptor, multi: true }];

// https://grokonez.com/frontend/angular/angular-6/angular-6-http-interceptor-with-node-js-restapis
// https://stackoverflow.com/a/51085644
