<div class="row mb-3">
    <div class="d-flex justify-content-end align-items-end">
        <button class="btn btn-md btn-flat-primary btn-icon-start" (click)="exportPoolFactorCsv()" translate>
            <em class="icon">save_alt</em> common-buttons.CSV-export
        </button>
    </div>
</div>
<div class="row">
    <div class="form-group col-md-12">
        <div>
            <ag-grid-angular #agGrid 
                style="width: 100%;" 
                domLayout='autoHeight'
                class="ag-theme-sg-bootstrap-condensed"
                [columnDefs]="columnDefs" [rowData]="poolFactorRowData" (gridReady)="onGridReady($event)"
                rowSelection="single" [gridOptions]="gridOptions!">
            </ag-grid-angular>
        </div>
    </div>
</div>