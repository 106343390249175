import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { InstrumentModel, RoleDataModel } from '@instruments/models';
import { MatrixInstrumentRoleModel } from '@instruments/models/matrix_instrument-role.model';
import { CharacteristicFormService, RoleDataFormService } from '@instruments/services';
import { InstrumentRoleMatrixService } from '@instruments/services/instrument-role-matrix.service';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { ProgrammeModel, ROLES_TYPE } from '@shared/models';
import { Subject, of, combineLatest } from 'rxjs';
import { takeUntil, switchMap, startWith } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-instrument-role-data',
  templateUrl: './instrument-role-data.component.html',
  providers: [
    { provide: FormServiceInjectionToken, useExisting: RoleDataFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'instruments.instrument.forms.instrumentRoleData' }
  ]
})
export class InstrumentRoleDataComponent extends BaseFormComponent<RoleDataModel, RoleDataFormService> implements OnInit, OnDestroy {
  public ISSUING_AGENT: ROLES_TYPE = 'ISSUING_AGENT';
  public PRINCIPAL_PAYING_AGENT: ROLES_TYPE = 'PRINCIPAL_PAYING_AGENT';
  public COMMON_DEPOSITARY: ROLES_TYPE = 'COMMON_DEPOSITARY';
  public REGISTRAR: ROLES_TYPE = 'REGISTRAR';
  public COMMON_SERVICE_PROVIDER: ROLES_TYPE = 'COMMON_SERVICE_PROVIDER';
  public COMMON_SAFEKEEPER: ROLES_TYPE = 'COMMON_SAFEKEEPER';
  public FISCAL_AGENT: ROLES_TYPE = 'FISCAL_AGENT';
  public LOCAL_PAYING_AGENT: ROLES_TYPE = 'LOCAL_PAYING_AGENT';
  public LISTING_AGENT: ROLES_TYPE = 'LISTING_AGENT';
  public TRANSFER_AGENT: ROLES_TYPE = 'TRANSFER_AGENT';
  public CALCULATION_AGENT: ROLES_TYPE = 'CALCULATION_AGENT';
  public DESK_CALCULATION_AGENT: ROLES_TYPE = 'DESK_CALCULATION_AGENT';
  public CASH_MANAGER: ROLES_TYPE = 'CASH_MANAGER';
  public ESCROW_MANAGER: ROLES_TYPE = 'ESCROW_MANAGER';
  programme: ProgrammeModel | null | undefined;

  shutdown$ = new Subject<void>();

  constructor(
    public readonly formService: RoleDataFormService,
    public readonly characteristicFormService: CharacteristicFormService,
    public readonly i18nUtils: I18nUtilsService,
    private readonly instrumentRoleMatrixService: InstrumentRoleMatrixService,
    private readonly activatedRoute: ActivatedRoute,
    @Inject(LabelRootTranslateInjectionToken) private readonly rootTranslate: string,
    private readonly toast: ToastManagerService
  ) {
    super(formService, 'instruments.instrument.placeholder.instrumentRoleData');
  }

  ngOnInit(): void {
    this.initProgramme();
    this.subscribeToFormMode();
  }
  private subscribeToFormMode(): void {
    this.formService.formMode$
      .pipe(
        switchMap(formMode => {
          if (formMode === 'consult' || formMode === 'edit') {
            return of(null);
          }
          return this.instrumentRoleMatrixService.getMatrix();
        })
      )
      .pipe(takeUntil(this.shutdown$))
      .subscribe(matrix => {
        this.updateValuesFromMatrix(matrix);
      });
  }
  private updateValuesFromMatrix(matrix: MatrixInstrumentRoleModel | null): void {
    if (!matrix) {
      return;
    }
    const fields = Object.values<keyof RoleDataModel>(this.formService.fields);
    const currentValue = this.formService.rawValue();
    for (const f of fields) {
      const field = f as keyof MatrixInstrumentRoleModel;
      const value = matrix[field];
      if (value) {
        if (currentValue[field] && value.bdrId !== currentValue[field]?.bdrId) {
          this.toast.toastWarning(`${this.rootTranslate}.warning.titles.${field}_updated`, `${this.rootTranslate}.warning.field_updated_waring_message`);
        }
        this.formService.patchField(field, value);
      }
    }
  }
  private initProgramme(): void {
    const values = this.activatedRoute.snapshot.data['instrument'] as [InstrumentModel | null, ProgrammeModel | null];
    this.programme = values ? values[1] : null;
    combineLatest([this.activatedRoute.data.pipe(startWith(...[]))])
      .pipe(takeUntil(this.shutdown$))
      .subscribe(([routeData]) => {
        const values = routeData['instrument'] as [InstrumentModel | null, ProgrammeModel | null];

        this.programme = values ? values[1] : null;
      });
  }
  ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}
