import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';

@Component({
  selector: 'app-form-validation-errors',
  templateUrl: './form-validation-errors.component.html'
})
export class FormValidationErrorsComponent {
  @Input() control: AbstractControl | undefined;
  @Input() params: Record<string, unknown> | undefined;
  @Input() fieldTranslationKey: string | undefined;

  constructor(private readonly i18nUtils: I18nUtilsService) {}

  public translationParams(errorKey: string): Record<string | number | symbol, unknown> {
    const error = (this.control?.errors || {})[errorKey] || {};
    const field = this.fieldTranslationKey ? this.i18nUtils.getI18nValue(this.fieldTranslationKey) : undefined;
    return {
      ['field']: field,
      ...error,
      ...this.params
    };
  }
}
