export type SORTED_TYPE = 'ASC' | 'DESC';

export const PROGRAMME_CONSTANTS = {
  statuses: ['Active', 'Inactive'],
  search: {
    document: {
      field: 'creationDate'
    }
  }
};
