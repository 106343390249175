<app-ttd-items-selector
  #select
  [(ngModel)]="value"
  [data]="items"
  [singleSelect]="!multiple"
  [isReadOnly]="isReadOnly"
  [labelProperties]="[bindLabelProperty]"
  [placeHolder]="placeholder"
  [ngClass]="{ 'select-error': hasError, isReadOnly: isReadOnly }"
  (focusout)="onBlur()"
  (ready)="onReady()"
  [key]="keyProperty"
  [withSummary]="withSummary"
  labelPath="bindLabelProperty"
>
  <div readonlyTemplate><span class="me-1" *ngFor="let val of displayValue">{{ val }}</span></div>
</app-ttd-items-selector>
