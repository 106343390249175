/**
 * issuer-ipa-events-api API
 * Manage events data
 *
 * The version of the OpenAPI document: 1.3.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateScheduleDetails { 
    actionType?: UpdateScheduleDetails.ActionTypeEnum;
    eventReference?: number;
    notificationDate?: string;
    valueDate?: string;
    reqFundDate?: string;
    preAdviceDate?: string;
    beginDate?: string;
    endDate?: string;
    eventStatus?: number;
}
export namespace UpdateScheduleDetails {
    export type ActionTypeEnum = 'UPDATE' | 'CANCEL';
    export const ActionTypeEnum = {
        UPDATE: 'UPDATE' as ActionTypeEnum,
        CANCEL: 'CANCEL' as ActionTypeEnum
    };
}


