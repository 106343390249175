import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map, tap } from 'rxjs/operators';

import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IdDto, InstrumentIdTrancheNumberDto, ResultInstrumentDto } from '@apis/backend/instruments';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';

import { ErrorListFromBackendService } from '../modules/error-list-from-backend/service/error-list-from-backend.service';
import { InstrumentModel, toInstrumentDto, toInstrumentModel, toInstrumentUpdateDto } from '@instruments/models/instrument.model';

import { toUpdateStatusRequestDto, UpdateStatusRequestModel } from '@instruments/models/update-status-request.model';
import { PagingRequestWithCriteriaModel, toPagingRequestWithCriteriaDto } from '@instruments/models/paging-request-with-criteria.model';
import { InstrumentCriteriaSearchModel, toInstrumentCriteriaSearchDto } from '@instruments/models/instrument-criteria-search.model';
import { toCreateTrancheDto, toUpdateTrancheDto, TrancheModel } from '@instruments/models/tranche.model';
import { InstrumentIdTrancheNumberModel, toInstrumentIdTrancheNumberModel } from '@instruments/models/instrument-id-tranche-number.model';
import { INSTRUMENT_ACTIONS, IpaTypeModel } from '@shared/models';
import { SettlementIdsModel, SettlementModel, toCreateSettlementDto, toSettlementIdsModel, toSettlementModel } from '@instruments/models/settlement.model';
import { notEmpty } from '@utils/utility-functions';
import { PagingResultDtoInstrumentDataFetchDto } from '@apis/backend/instruments/model/pagingResultDtoInstrumentDataFetchDto';
import { SettlementDto } from '@apis/backend/instruments/model/settlementDto';
import { SettlementIdsDto } from '@apis/backend/instruments/model/settlementIdsDto';
import { InstrumentResourceService } from '@apis/backend/instruments/api/instrumentResource.service';
import { SETTLEMENT_ACTIONS } from '@shared/models/settlement-action.model';
import { toUpdateStatusSettlementRequestDto, UpdateStatusSettlementRequestModel } from '@instruments/models/update-status-settlement-request.model';
import { CommonDepositoriesModel, toGnReceptionDataDto } from '@instruments/models/management-common-depository.model';

@Injectable({
  providedIn: 'root'
})
export class InstrumentService {
  private readonly response = 'response';
  constructor(private readonly instrumentRessourceService: InstrumentResourceService, private readonly toastManagerService: ToastManagerService, private readonly errorListFromBackendService: ErrorListFromBackendService) {}

  create(instrument: InstrumentModel, noFrequency: IpaTypeModel): Observable<number | undefined> {
    const dto = toInstrumentDto(instrument, noFrequency);
    return this.instrumentRessourceService.createInstrument(dto, this.response).pipe(
      tap((result: HttpResponse<IdDto>) => {
        if (result) {
          this.toastManagerService.toastSuccess('toasts.instruments.create.title', 'toasts.instruments.create.message', undefined, result);
        }
      }),
      map((result: HttpResponse<IdDto>) => result.body?.id),
      catchError(error => this.handlingError(error))
    );
  }

  update(instrument: InstrumentModel, noFrequency: IpaTypeModel): Observable<number | undefined> {
    const dto = toInstrumentUpdateDto(instrument, noFrequency);
    return this.instrumentRessourceService.updateInstrument(dto, this.response).pipe(
      tap((result: HttpResponse<IdDto>) => {
        if (result) {
          this.toastManagerService.toastSuccess('toasts.instruments.update.title', 'toasts.instruments.update.message', undefined, result);
        }
      }),
      map((result: HttpResponse<IdDto>) => result.body?.id),
      catchError(error => this.handlingError(error))
    );
  }
  getInstrumentById(id: number | undefined, selectedTranche?: number): Observable<InstrumentModel | null> {
    if (!id) {
      return of(null);
    }
    return this.instrumentRessourceService.getInstrumentById(id, this.response).pipe(map(result => toInstrumentModel(result.body ?? {}, selectedTranche)));
  }
  getMultipleInstrumentsById(id: string): Observable<ResultInstrumentDto | null> {
    if (!id) {
      return of(null);
    }
    return this.instrumentRessourceService.getMultipleInstruments(id);
  }

  getInstrumentByIsin(isin: string | undefined): Observable<InstrumentModel | null> {
    if (!isin) {
      return of(null);
    }
    return this.instrumentRessourceService.getInstrumentBySingleIsin(isin, this.response).pipe(map(result => toInstrumentModel(result.body ?? {})));
  }

  private handlingError(error: any): Observable<any> {
    this.errorListFromBackendService.showError(error);
    return of(error);
  }

  getAllInstrumentsPaged(pagingRequestWithCriteria: PagingRequestWithCriteriaModel): Observable<PagingResultDtoInstrumentDataFetchDto | null> {
    const dto = toPagingRequestWithCriteriaDto(pagingRequestWithCriteria);

    if (!dto) {
      return of(null);
    }
    return this.instrumentRessourceService.searchInstrumentsPaged(dto);
  }

  exportInstrumentsData(criteria: InstrumentCriteriaSearchModel): Observable<Blob | null> {
    const dto = toInstrumentCriteriaSearchDto(criteria);

    if (!dto) {
      return of(null);
    }
    return this.instrumentRessourceService.exportInstrumentsData(dto);
  }

  updateStatus(action: INSTRUMENT_ACTIONS, request: UpdateStatusRequestModel): Observable<any> {
    return this.instrumentRessourceService.updateStatus(action, toUpdateStatusRequestDto(request));
  }

  createTranche(instrumentId: number, tranche: TrancheModel, upto: boolean): Observable<InstrumentIdTrancheNumberModel | null> {
    const dto = toCreateTrancheDto(tranche);
    if (!dto) {
      return of(null);
    }
    return this.instrumentRessourceService.createTranche(instrumentId, dto, this.response).pipe(
      map((result: HttpResponse<InstrumentIdTrancheNumberDto>) => toInstrumentIdTrancheNumberModel(result.body)),
      tap(result => {
        if (result) {
          if (upto) {
            this.toastManagerService.toastSuccess('toasts.increases.create.title', 'toasts.increases.create.message', undefined, result);
          } else {
            this.toastManagerService.toastSuccess('toasts.tranches.create.title', 'toasts.tranches.create.message', undefined, result);
          }
        }
      }),
      catchError(error => this.handlingError(error))
    );
  }
  updateTranche(instrumentId: number, trancheNumber: number, instrumentModel: Nullable<InstrumentModel>): Observable<InstrumentIdTrancheNumberModel | null> {
    const dto = toUpdateTrancheDto(instrumentModel, trancheNumber);
    if (!dto) {
      return of(null);
    }
    return this.instrumentRessourceService.updateTranche(instrumentId, trancheNumber, dto, this.response).pipe(
      map((result: HttpResponse<InstrumentIdTrancheNumberDto>) => toInstrumentIdTrancheNumberModel(result.body)),
      tap(result => {
        if (result) {
          this.toastManagerService.toastSuccess('toasts.tranches.update.title', 'toasts.tranches.update.message', undefined, result);
        }
      }),
      catchError(error => this.handlingError(error))
    );
  }

  getAllSettlements(instrumentId?: number, trancheNumber?: number): Observable<SettlementModel[]> {
    if (!instrumentId || !trancheNumber) {
      return of([]);
    }
    return this.instrumentRessourceService
      .getAllSettlementsByIdSettlements(instrumentId, trancheNumber, [], this.response)
      .pipe(map((result: HttpResponse<SettlementDto>) => ((result.body as SettlementDto[]) || []).map(toSettlementModel).filter(notEmpty)));
  }

  createSettlement(tradingMethod: IpaTypeModel | null, instrumentId: number, trancheNumber: number, models: SettlementModel[]): Observable<SettlementIdsModel | undefined | null> {
    const dtos = models.map(dto => toCreateSettlementDto(tradingMethod, trancheNumber, dto)).filter(notEmpty);
    if (!dtos) {
      return of(null);
    }

    return this.instrumentRessourceService.performSettlements(instrumentId, trancheNumber, dtos, this.response).pipe(
      map((result: HttpResponse<SettlementIdsDto>) => toSettlementIdsModel(result.body)),
      tap(result => {
        if (result) {
          this.toastManagerService.toastSuccess('toasts.settlements.update.title', 'toasts.settlements.update.message', undefined, result);
        }
      }),
      catchError(error => this.handlingError(error))
    );
  }

  uploadConfirmations(file: File): Observable<any> {
    return this.instrumentRessourceService.confirmationsByZip(file).pipe(catchError(error => this.handlingError(error)));
  }

  updateSettlementsStatus(action: SETTLEMENT_ACTIONS, dto: UpdateStatusSettlementRequestModel): Observable<any> {
    return this.instrumentRessourceService.updateSettlementStatus(action, toUpdateStatusSettlementRequestDto(dto), this.response).pipe(
      tap((result: HttpResponse<any>) => {
        if (result) {
          this.toastManagerService.toastSuccess('toasts.settlements.update.title', 'toasts.settlements.update.message', undefined, result);
        }
      }),
      map((result: HttpResponse<any>) => result.body?.status),
      catchError(error => this.handlingError(error))
    );
  }

  getGlobalNoteByInstrumentId(instrumentId: number): Observable<any> {
    if (!instrumentId) {
      return of();
    }
    return this.instrumentRessourceService.getGlobalNoteByInstrumentId(instrumentId).pipe(
      catchError(error => {
        const { status } = error;
        if (status === 404) {
          return of({});
        }
        this.handlingError(error);
        throw error;
      })
    );
  }

  public createOrUpdateGlobalNote(commonDepositary: CommonDepositoriesModel): Observable<any> {
    return this.instrumentRessourceService.createOrUpdateGlobalNote(toGnReceptionDataDto(commonDepositary), this.response).pipe(
      tap((result: HttpResponse<any>) => {
        if (result) {
          this.toastManagerService.toastSuccess('toasts.commonDepositary.update.title', 'toasts.commonDepositary.update.message', undefined, result);
        }
      }),
      map((result: HttpResponse<any>) => result.body?.status),
      catchError(error => {
        this.handlingError(error);
        throw error;
      })
    );
  }
}
