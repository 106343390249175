/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InstrumentRoleDataCreateDto } from './instrumentRoleDataCreateDto';
import { InstrumentEventsDataCreateDto } from './instrumentEventsDataCreateDto';
import { InstrumentGlobalNoteCreateDto } from './instrumentGlobalNoteCreateDto';
import { InstrumentIssuanceDataCreateDto } from './instrumentIssuanceDataCreateDto';
import { InstrumentCharacteristicsDataCreateDto } from './instrumentCharacteristicsDataCreateDto';
import { InstrumentListingDataCreateDto } from './instrumentListingDataCreateDto';


/**
 * Instrument to create
 */
export interface InstrumentCreateDto { 
    instrumentCharacteristicsData?: InstrumentCharacteristicsDataCreateDto;
    instrumentIssuanceData?: InstrumentIssuanceDataCreateDto;
    instrumentGlobalNote?: InstrumentGlobalNoteCreateDto;
    instrumentListingData?: InstrumentListingDataCreateDto;
    instrumentEventsData?: InstrumentEventsDataCreateDto;
    instrumentRoleData?: InstrumentRoleDataCreateDto;
}

