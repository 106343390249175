/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PartyDto } from './partyDto';
import { MovementDto } from './movementDto';


export interface MovementOfPositionResultDto { 
    instrumentId?: number;
    isinReference?: string;
    seriesNumber?: string;
    currency?: string;
    icsdParty?: PartyDto;
    depositaryParty?: PartyDto;
    movementDtos?: Array<MovementDto>;
}

