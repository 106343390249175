/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AmountFormatCellComponent } from '@shared/components/amount-format-cell/amount-format-cell.component';
import { MOMENT_DATE_FORMAT } from '@shared/pipes/date-format/common';
import { ColDef, ValueFormatterParams } from 'ag-grid-community/dist/lib/main';
import * as moment from 'moment';
import { PositionActionComponent } from '../components/positions-list/position-action/position-action.component';
import { PositionModel } from '../models/position.model';

export interface PositionColumnDef extends ColDef {
  field: keyof (PositionModel & PositionColumnsAction);
}
interface PositionColumnsAction {
  actions: string;
}

export const POSITIONS_COLUMNS: PositionColumnDef[] = [
  {
    field: 'isin',
    width: 250,
    sortable: true,
    resizable: true
  },
  {
    field: 'ipaCode',
    sortable: true,
    resizable: true
  },
  {
    field: 'seriesNumber',
    sortable: true,
    resizable: true
  },
  {
    field: 'currency',
    sortable: true,
    resizable: true
  },
  {
    field: 'valueDate',
    valueFormatter: (q: ValueFormatterParams) => {
      if (!q.data.valueDate) {
        return '';
      }
      const momentDate = moment(q.data.valueDate);
      return momentDate.format(MOMENT_DATE_FORMAT);
    },
    sortable: true,
    resizable: true
  },
  {
    field: 'icsd',
    sortable: true,
    resizable: true,
    valueFormatter: (q: ValueFormatterParams) => q.data.icsd?.name
  },
  {
    field: 'account',
    sortable: true,
    resizable: true
  },
  {
    field: 'totalAmount',
    cellRendererFramework: AmountFormatCellComponent,
    sortable: true,
    resizable: true,
    cellStyle: { display: 'flex', 'justify-content': 'flex-end' }
  },
  {
    field: 'blockedAmount',
    cellRendererFramework: AmountFormatCellComponent,
    sortable: true,
    resizable: true,
    cellStyle: { display: 'flex', 'justify-content': 'flex-end' }
  },
  {
    field: 'unblockedAmount',
    cellRendererFramework: AmountFormatCellComponent,
    sortable: true,
    resizable: true,
    cellStyle: { display: 'flex', 'justify-content': 'flex-end' }
  },
  {
    field: 'actions',
    cellRendererFramework: PositionActionComponent,
    sortable: true
  }
];
