import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ValuationContainerComponent, ValuationDetailComponent } from './components';
import { ValuationResolve } from './valuation.resolve';
import { PermissionGuard } from '@routes/permission.guard';

const VALUATION_ROUTES: Routes = [
  { path: 'valuation', component: ValuationContainerComponent, canActivate: [PermissionGuard] },
  { path: 'valuation/:pageType', component: ValuationDetailComponent, canActivate: [PermissionGuard] },
  {
    path: 'valuation/:pageType/:id',
    component: ValuationDetailComponent,
    resolve: { valuation: ValuationResolve },
    canActivate: [PermissionGuard]
  }
];

export const valuationRoutes = RouterModule.forRoot(VALUATION_ROUTES, { preloadingStrategy: PreloadAllModules });
