/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-this-alias */
import { Observable, of, combineLatest } from 'rxjs';
import { map, startWith, distinctUntilChanged, tap, shareReplay } from 'rxjs/operators';
import { SectionBuilder, And, Section, DisplayRulesFunction, DisplayRules, RuleMethod } from './display.service.contract';
import { getArray, arrayDistinct, deepEqual } from '@utils/utility-functions';
import { AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { IFormService } from '../forms/form.models';

type CheckValidationFn = (conditions: boolean[]) => boolean;
type Nullable = null | undefined;
type NullableStringOrArray = string | string[] | Nullable;

export abstract class DisplayService<T> {
  private compiled = false;
  private readonly rulesMode = new Map<keyof T, RuleMethod>();
  private readonly fieldsConditions = new Map<keyof T, Observable<boolean>[]>();
  private readonly sectionsConditions = new Map<string, Observable<boolean>[]>();

  //Those will be computed OnInitMethod from validationFieldsObservables
  private readonly finalFieldsObservable: Map<keyof T, Observable<boolean>> = new Map<keyof T, Observable<boolean>>();
  private readonly finalSectionObservable: Map<string, Observable<boolean>> = new Map<string, Observable<boolean>>();

  // This define sections of form (a.k.a a group of fields)
  private readonly sections: Map<string, Section<T>> = new Map<string, Section<T>>();

  constructor(private formService: IFormService<T>) {}

  private getAllSectionFields(sectionName: string): (keyof T)[] {
    const section = this.sections.get(sectionName) || { fields: [], subSections: [] };
    const fields = section.subSections.reduce<(keyof T)[]>((fields, sectionName) => {
      const subFields = this.getAllSectionFields(sectionName);
      return [...fields, ...subFields];
    }, []);
    return [...section.fields, ...fields];
  }

  public defineSection(sectionName: string): SectionBuilder<T> {
    const defineSectionFields = (sectionName: string, ...fields: (keyof T)[]) => {
      const section: Section<T> = this.sections.get(sectionName) || { fields: [], subSections: [] };
      const newSection: Section<T> = {
        fields: [...section.fields, ...fields],
        subSections: [...section.subSections]
      };
      this.sections.set(sectionName, newSection);
    };

    const defineSubSection = (thisSectionName: string, ...sectionNames: string[]) => {
      const section: Section<T> = this.sections.get(thisSectionName) || { fields: [], subSections: [] };
      const newSection: Section<T> = {
        fields: [...section.fields],
        subSections: [...section.subSections, ...sectionNames]
      };
      this.sections.set(sectionName, newSection);
    };

    const andResult: And<T> = {
      and: {
        toHaveFields(...fields: (keyof T)[]) {
          defineSectionFields(sectionName, ...fields);
          return andResult;
        },
        haveSubSections(...sectionNames: string[]) {
          defineSubSection(sectionName, ...sectionNames);
          return andResult;
        }
      }
    };

    return andResult.and;
  }

  private registerObservable<K extends keyof T, L extends (keyof T)[]>(sectionName: NullableStringOrArray, fields: K | L | Nullable, displayObs: Observable<boolean>) {
    const sections = getArray(sectionName);
    const fieldsInSection = sections.reduce<(keyof T)[]>((fields, section) => [...fields, ...this.getAllSectionFields(section)], []);
    const allFields = arrayDistinct([...getArray(fields), ...fieldsInSection]);

    allFields.forEach(field => {
      const existingConditions = this.fieldsConditions.get(field) || [];
      this.fieldsConditions.set(field, [...existingConditions, displayObs]);
    });

    sections.forEach(sectionName => {
      const existingConditions = this.sectionsConditions.get(sectionName) || [];
      this.sectionsConditions.set(sectionName, [...existingConditions, displayObs]);
    });
  }

  private createDisplayRulesMethod<U, V extends (keyof U)[]>(formService: IFormService<U>, validationFields: V): DisplayRulesFunction<T, U, V> {
    return (...rules: DisplayRules<T, U, V>[]) => {
      const valueChange = formService.valueChanges(validationFields).pipe(distinctUntilChanged(deepEqual), shareReplay());

      rules.forEach(r => {
        let formModeObs: Observable<boolean> = of(true);
        if (r.activeOn && r.activeOn.length) {
          formModeObs = formService.formMode$.pipe(map(fm => r.activeOn!.includes(fm)));
        }

        const baseDisplayObs = combineLatest([valueChange, formModeObs]).pipe(
          map(([value, isFormModeValid]) => !isFormModeValid || r.when(value)),
          startWith(true),
          distinctUntilChanged()
        );
        const invertedBaseDisplayObs = combineLatest([valueChange, formModeObs]).pipe(
          map(([value, isFormModeValid]) => !isFormModeValid || !r.when(value)),
          startWith(true),
          distinctUntilChanged()
        );

        this.registerObservable(r.displaySections, r.displayFields, baseDisplayObs);
        this.registerObservable(r.else?.displaySections, r.else?.displayFields, invertedBaseDisplayObs);
      });
    };
  }

  private setRuleMethod<K extends keyof T>(field: K, ruleMode: RuleMethod) {
    this.rulesMode.set(field, ruleMode);
  }

  private getRuleMethod<K extends keyof T>(field: K): RuleMethod {
    return this.rulesMode.get(field) || 'AND';
  }

  //This method is used to describe rules on a field of form
  protected describe<L extends (keyof T)[]>(...fields: L) {
    return {
      rules: this.createDisplayRulesMethod(this.formService, fields)
    };
  }

  //This method is used to describe rules on a field of form according to a second form service field
  protected with<U, L extends (keyof U)[]>(formService: IFormService<U>, ...fields: L) {
    return {
      rules: this.createDisplayRulesMethod(formService, fields)
    };
  }

  public shouldDisplay<K extends keyof T>(field: K): Observable<boolean> {
    return this.finalFieldsObservable.get(field) || of(true);
  }
  public shouldDisplaySection(sectionName: string): Observable<boolean> {
    return this.finalSectionObservable.get(sectionName) || of(true);
  }

  protected changeRuleMethodFor<L extends (keyof T)[]>(...fields: L) {
    return {
      toAnd: () => fields.forEach(f => this.setRuleMethod(f, 'AND')),
      toOr: () => fields.forEach(f => this.setRuleMethod(f, 'OR'))
    };
  }

  // We need to subscribe to field observable in order to handle validators
  // But we cannot subscribe in a service, therefore, we return it from the Compile method
  public Compile(): Observable<boolean>[] {
    if (!this.compiled) {
      this.fieldsConditions.forEach((conditions$, field) => {
        const control = this.formService.control(field);
        const validator: ValidatorFn | null = control.validator;
        const asyncValidator: AsyncValidatorFn | null = control.asyncValidator;

        const ruleMethod = this.getRuleMethod(field);
        const checkValid: CheckValidationFn = ruleMethod === 'AND' ? cdn => cdn.every(r => r) : cdn => cdn.some(r => r);

        //conditions$ =>
        const isVisible$ = combineLatest(conditions$).pipe(
          map(cdn => checkValid(cdn)),
          distinctUntilChanged((a, b) => a === b)
        );

        this.finalFieldsObservable.set(
          field,
          isVisible$.pipe(
            tap(isVisible => {
              if (!isVisible) {
                this.formService.clearField(field, { emitEvent: false, onlySelf: true });
                control.setValidators(null);
                control.setAsyncValidators(null);
              } else {
                this.formService.reset(field, { emitEvent: false, onlySelf: true });
                control.setValidators(validator);
                control.setAsyncValidators(asyncValidator);
              }
            }),
            shareReplay()
          )
        );
      });

      this.sectionsConditions.forEach((conditions$, sectionName) => {
        const isVisible$ = combineLatest(conditions$).pipe(map(res => res.every(r => r), distinctUntilChanged()));
        this.finalSectionObservable.set(sectionName, isVisible$);
      });

      this.compiled = true;
    }

    return [...this.finalFieldsObservable.values(), ...this.finalSectionObservable.values()];
  }
}
