import { ConfidentialityLevel, isConfidentialityLevel } from './types';
import { DocModel, ResultDocModel } from '@apis/backend/instruments';
import { notEmpty } from '@utils/utility-functions';

export interface DocumentModel {
  readonly id: string;
  readonly name: string;
  readonly defaultConfidentialityLevel: ConfidentialityLevel;
  readonly minimumConfidentialityLevel: ConfidentialityLevel;
}

export const toDocumentModel = (dto: DocModel | null | undefined): DocumentModel | null => {
  if (!dto) {
    return null;
  }

  const defaultConfidentiality = isConfidentialityLevel(dto.defaultConfidentialityLevel) ? dto.defaultConfidentialityLevel : 'C2';
  const minimumConfidentiality = isConfidentialityLevel(dto.minimumConfidentialityLevel) ? dto.minimumConfidentialityLevel : 'C2';

  return {
    id: dto.id ?? '',
    name: dto.name ?? '',
    defaultConfidentialityLevel: defaultConfidentiality,
    minimumConfidentialityLevel: minimumConfidentiality
  };
};

export const toDocumentModels = (dto: ResultDocModel | null | undefined): DocumentModel[] => {
  if (!dto) {
    return [];
  }
  return (dto.resources || []).map(toDocumentModel).filter(notEmpty);
};
