import { Component } from '@angular/core';
import { OperationDetailsModel } from '@positions/models';
import { OperationDetailFormService } from '@positions/services/operation-detail.form.service';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { TYPED_DATA_TYPE } from '@shared/models';

@Component({
  selector: 'app-operation-form',
  templateUrl: './operation-form.component.html'
})
export class OperationFormComponent extends BaseFormComponent<OperationDetailsModel, OperationDetailFormService> {
  public readonly OPERATION_TYPE: TYPED_DATA_TYPE = 'OPERATION_TYPE';
  constructor(formService: OperationDetailFormService) {
    super(formService, 'operations.placeholder.operationCreation');
  }
}
