import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html'
})
export class CollapseComponent {
  @Input() title: string | null = '';
  @Input() hide = true;

  public changeHide(): void {
    this.hide = !this.hide;
  }
}
