import { PdfViewerModule } from 'ng2-pdf-viewer';

import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerModule } from '@shared/modules/date-picker/date-picker.module';
import { HeaderModule } from '@shared/modules/header/header.module';
import { YesNoButtonsModule } from '@shared/modules/yes-no-buttons/yes-no-buttons.module';

import { CustomAgGridModule } from '@shared/modules/custom-ag-grid/custom-ag-grid.module';
import { CustomMultipleSelectModule } from '@shared/modules/custom-multiple-select/custom-multiple-select.module';
import { ErrorListFromBackendModule } from '@shared/modules/error-list-from-backend/error-list-from-backend.module';
import { SharedModule } from '@shared/shared.module';
import { programmeRoutes } from './programme.routing';
import { ProgrammeComponent, ProgrammeFormsComponent, ProgrammeListStatusComponent } from './components';
import { DocumentsModule } from '@documents/documents.module';
import { LeiMultipleSelectModule } from '@shared/modules/lei-multiple-select/lei-multiple-select.module';
import { ProgrammeInformationModule } from '@shared/modules/programme-information/programme-information.module';
import { HideAndShowModule } from '@shared/modules/hide-and-show/hide-and-show.module';
import { ProgrammeStatusComponent } from './components/programme-status/programme-status.component';
import { ProgrammesContainerComponent } from './components/programme-search/programmes-container/programmes-container.component';
import { ProgrammeListFilterComponent } from './components/programme-search/programme-list-filter/programme-list-filter.component';
import { ProgrammeListComponent } from './components/programme-search/programme-list.component/programme-list.component';
import { ButtonsModule } from '@shared/modules/buttons/buttons.module';
import { PaginationModule } from '@shared/modules/pagination/pagination.module';
import { AgGridModule } from 'ag-grid-angular';
import { PartyMultipleSelectModule } from '@shared/modules/party-multiple-select/party-multiple-select.module';
import { TypeSingleSelectModule } from '@shared/modules/type-single-select/type-single-select.module';
import { ProgrammeListActiveFiltersComponent } from './components/programme-list-active-filters/programme-list-active-filters.component';

@NgModule({
  declarations: [ProgrammeListComponent, ProgrammeListFilterComponent, ProgrammeComponent, ProgrammeFormsComponent, ProgrammeListStatusComponent, ProgrammeStatusComponent, ProgrammesContainerComponent, ProgrammeListActiveFiltersComponent],
  imports: [
    programmeRoutes,
    CommonModule,
    CustomAgGridModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    DatePickerModule,
    NgbModule,
    TranslateModule,
    PdfViewerModule,
    SharedModule.forRoot(),
    MatSelectModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    CustomMultipleSelectModule,
    ErrorListFromBackendModule,
    HeaderModule,
    YesNoButtonsModule,
    DocumentsModule,
    LeiMultipleSelectModule,
    ProgrammeInformationModule,
    HideAndShowModule,
    ButtonsModule,
    AgGridModule,
    PaginationModule,
    PartyMultipleSelectModule,
    TypeSingleSelectModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProgrammeModule {
  static forRoot(): ModuleWithProviders<ProgrammeModule> {
    return {
      ngModule: ProgrammeModule
    };
  }
}
