import { PdfViewerModule } from 'ng2-pdf-viewer';

import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyMultipleSelectModule } from '@shared/modules/currency-multiple-select/currency-multiple-select.module';
import { CustomAgGridModule } from '@shared/modules/custom-ag-grid/custom-ag-grid.module';
import { CustomMultipleSelectModule } from '@shared/modules/custom-multiple-select/custom-multiple-select.module';
import { DatePickerModule } from '@shared/modules/date-picker/date-picker.module';
import { ErrorListFromBackendModule } from '@shared/modules/error-list-from-backend/error-list-from-backend.module';
import { LocalCustomFormModule } from '@shared/modules/forms/local-custom-form.module';
import { HeaderModule } from '@shared/modules/header/header.module';
import { MultipleDayPickerModule } from '@shared/modules/multiple-day-picker/multiple-day-picker.module';
import { PartyMultipleSelectModule } from '@shared/modules/party-multiple-select/party-multiple-select.module';
import { TypeMultipleSelectModule } from '@shared/modules/type-multiple-select/type-multiple-select.module';
import { YesNoButtonsModule } from '@shared/modules/yes-no-buttons/yes-no-buttons.module';
import { SharedModule } from '@shared/shared.module';

import { instrumentsRoutes } from './instrument.routing';
import { ModalsModule } from '@shared/modules/modals/modals.module';
import { DocumentsModule } from '@documents/documents.module';
import { AgGridModule } from 'ag-grid-angular';
import { LeiMultipleSelectModule } from '@shared/modules/lei-multiple-select/lei-multiple-select.module';
import { NullableYesNoButtonsModule } from '@shared/modules/nullable-yes-no-buttons/nullable-yes-no-buttons.module';
import { PaginationModule } from '@shared/modules/pagination/pagination.module';
import {
  InstrumentListComponent,
  InstrumentComponent,
  InstrumentFormsComponent,
  InstrumentCharacteristicsComponent,
  InstrumentListingComponent,
  InstrumentIssuanceComponent,
  InstrumentGlobalNoteComponent,
  InstrumentRoleDataComponent,
  InstrumentEventsDataComponent,
  InstrumentHeaderInfoComponent,
  InstrumentsContainerComponent,
  InstrumentTypeComponent,
  IntrumentListFilterComponent,
  InstrumentActionComponent,
  InstrumentStatusTypeSelectComponent,
  InstrumentRoleMultiselectV2Component,
  TrancheSelectorComponent,
  TrancheCreationComponent,
  SaveCancelButtonBarComponent,
  InstrumentFilterSelectorComponent,
  TrancheFongeComponent,
  InstrumentSettlementComponent,
  InstrumentSettlementConsultComponent,
  InstrumentSettlementEditComponent,
  ReportingAccessFormComponent,
  ReportingAccessComponent,
  ReportingAccessListComponent
} from './components';
import { ButtonsModule } from '@shared/modules/buttons/buttons.module';
import { TypeSingleSelectModule } from '@shared/modules/type-single-select/type-single-select.module';
import { InstrumentListActiveFiltersComponent } from './components/instrument-list/instrument-list-active-filters/instrument-list-active-filters.component';
import { PartySingleSelectModule } from '@shared/modules/party-signle-select/party-single-select.module';
import { NumberInputModule } from '@shared/modules/number-input/number-input.module';
import { ProgrammeInformationModule } from '@shared/modules/programme-information/programme-information.module';
import { TrancheFormContainerComponent } from './components/tranche-form-container/tranche-form-container.component';
import { InstrumentFilterSelectorService } from './services/instrument-filter-selector.service';
import { InstrumentTrancheHistoryComponent } from './components/instrument-forms/instrument-tranche-history/instrument-tranche-history.component';
import { FilterSelectorServiceInjectionToken } from '@shared/modules/save-filter/tokens/filter-selector.token';
import { TrancheFongeContainerComponent } from './components/tranche-fonge-container/tranche-fonge-container.component';
import { HideAndShowModule } from '@shared/modules/hide-and-show/hide-and-show.module';
import { CollapseModule } from '@shared/modules/Collapse/collapse.module';
import { TypeComponent } from './components/instrument-list/_aggrid/type/type.component';
import { SettlementActionComponent } from './components/instrument-list/_aggrid/settlement-action/settlement-action.component';
import { InstrumentSettlementValuesComponent } from './components/instrument-forms/instrument-settlement/settlement-values/settlement-values.component';
import { SettlementEditActionComponent } from './components/instrument-list/_aggrid/settlement-edit-action/settlement-edit-action.component';
import { SettlementDataUploadComponent } from './components/settlement-data-upload/settlement-data-upload.component';
import { BlinkerModule } from '@shared/modules/blinker/blinker.module';
import { SettlementsConfirmationsReportsComponent } from './components/settlements-confirmations-reports/settlements-confirmations-reports.component';
import { CustomAggridEditingModule } from '@shared/modules/custom-aggrid-editing/custom-aggrid-editing.module';
import { DocumentationCdReceptionComponent } from './components/instrument-forms/management-common-depository/documentation-cd-reception/documentation-cd-reception.component';
import { DisposalComponent } from './components/instrument-forms/management-common-depository/disposal/disposal.component';
import { CommonDepositoryContainerComponent } from './components/instrument-forms/management-common-depository/common-depository-container/common-depository-container.component';
import { DisposalItemsConsultComponent } from './components/instrument-forms/management-common-depository/disposal/disposal-items-consult/disposal-items-consult.component';
import { DisposalItemsEditComponent } from './components/instrument-forms/management-common-depository/disposal/disposal-items-edit/disposal-items-edit.component';
import { PermanentMonitoringConsultComponent } from './components/instrument-forms/management-common-depository/permanent-monitoring/consult/consult.component';
import { PermanentMonitoringEditComponent } from './components/instrument-forms/management-common-depository/permanent-monitoring/edit/edit.component';
import { PermanentMonitoringComponent } from './components/instrument-forms/management-common-depository/permanent-monitoring/permanent-monitoring.component';
import { InstrumentsSettlementActionsComponent } from './components/instrument-forms/instrument-settlement/settlement-actions/settlement-actions.component';
import { SettlementHistoryComponent } from './components/settlement-history/settlement-history.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { InstrumentFiltersSaveComponent } from './components/instrument-list/filters-save/instrument-filters-save.component';
import { ItemsSelectorModule } from '../shared/components/items-selector';

@NgModule({
  declarations: [
    InstrumentListComponent,
    InstrumentComponent,
    InstrumentFormsComponent,
    InstrumentCharacteristicsComponent,
    InstrumentListingComponent,
    InstrumentIssuanceComponent,
    InstrumentGlobalNoteComponent,
    InstrumentRoleDataComponent,
    InstrumentEventsDataComponent,
    InstrumentHeaderInfoComponent,
    InstrumentsContainerComponent,
    InstrumentTypeComponent,
    IntrumentListFilterComponent,
    InstrumentActionComponent,
    InstrumentFilterSelectorComponent,
    InstrumentStatusTypeSelectComponent,
    TrancheSelectorComponent,
    InstrumentListActiveFiltersComponent,
    TrancheCreationComponent,
    TrancheFormContainerComponent,
    TrancheFongeComponent,
    SaveCancelButtonBarComponent,
    InstrumentTrancheHistoryComponent,
    TrancheFongeContainerComponent,
    TypeComponent,
    InstrumentSettlementComponent,
    InstrumentSettlementConsultComponent,
    InstrumentSettlementEditComponent,
    SettlementActionComponent,
    SettlementEditActionComponent,
    InstrumentSettlementValuesComponent,
    SettlementDataUploadComponent,
    SettlementsConfirmationsReportsComponent,
    CommonDepositoryContainerComponent,
    DocumentationCdReceptionComponent,
    PermanentMonitoringComponent,
    PermanentMonitoringConsultComponent,
    PermanentMonitoringEditComponent,
    DisposalComponent,
    DisposalItemsConsultComponent,
    DisposalItemsEditComponent,
    SettlementHistoryComponent,
    InstrumentsSettlementActionsComponent,
    InstrumentFiltersSaveComponent,
    InstrumentRoleMultiselectV2Component,
    ReportingAccessFormComponent,
    ReportingAccessComponent,
    ReportingAccessListComponent
  ],
  providers: [{ provide: FilterSelectorServiceInjectionToken, useExisting: InstrumentFilterSelectorService }],
  imports: [
    instrumentsRoutes,
    CommonModule,
    CustomAgGridModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    PdfViewerModule,
    SharedModule.forRoot(),
    MatSelectModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    CustomMultipleSelectModule,
    TypeMultipleSelectModule,
    PartyMultipleSelectModule,
    CurrencyMultipleSelectModule,
    ErrorListFromBackendModule,
    HeaderModule,
    YesNoButtonsModule,
    LocalCustomFormModule,
    MultipleDayPickerModule,
    DatePickerModule,
    ModalsModule,
    DocumentsModule,
    AgGridModule,
    PaginationModule,
    NullableYesNoButtonsModule,
    LeiMultipleSelectModule,
    ButtonsModule,
    TypeSingleSelectModule,
    PartySingleSelectModule,
    NumberInputModule,
    ProgrammeInformationModule,
    HideAndShowModule,
    BlinkerModule,
    CustomAggridEditingModule,
    CollapseModule,
    BsDropdownModule.forRoot(),
    ItemsSelectorModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InstrumentModule {
  static forRoot(): ModuleWithProviders<InstrumentModule> {
    return {
      ngModule: InstrumentModule
    };
  }
}
