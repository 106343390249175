<div *ngIf="operations.length>0; else noData" class="mt-3">
  <ag-grid-angular #agGrid class="ag-theme-sg-bootstrap-condensed"[gridOptions]="gridOptions!"
    [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [rowData]="operations" (gridReady)="onGridReady($event)">
  </ag-grid-angular>

<div class="row mt-1">
  <div class="col-md-12">
    <app-sg-pagination [pageSize]="itemsByPage" [totalItems]="totalItems" (pageChanged)="onPageChanged($event)"
      [currentPage]="currentPage" enableCellTextSelection="true" [maxPagesToDisplay]="10"></app-sg-pagination>
  </div>
</div>
</div>
<ng-template #noData>
  <div class="col-md-12">
    <app-no-data title="{{'ag-grid.title'|translate}}" subTitle="{{'ag-grid.searchSubTitle'|translate}}"></app-no-data>
  </div>
</ng-template>
