import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PositionModel, PositionSearchCriteriaModel, toPositionFetchModel } from '@positions/models';
import { PositionCriteriaSearchFormService, PositionSearchQueryModel, PositionService } from '@positions/services';

import { SearchContainerWithoutPaginationBaseComponent } from '@shared/search-base';
import { PermissionService } from '@shared/services/permission.service';
import { takeUntil } from 'rxjs/operators';
import { PositionListComponent } from '../positions-list/position-list.component/position-list.component';

@Component({
  selector: 'app-position-search-container',
  templateUrl: './position-search-container.component.html',
  styleUrls: ['./position-search-container.component.scss'],
  providers: [PositionCriteriaSearchFormService]
})
export class PositionSearchContainerComponent extends SearchContainerWithoutPaginationBaseComponent<PositionModel, PositionSearchCriteriaModel, PositionSearchQueryModel, PositionCriteriaSearchFormService> {
  @ViewChild(PositionListComponent) positionList!: PositionListComponent;
  public canReadMultiplePosition: boolean | undefined;
  public disableSearch = false;

  constructor(private readonly positionsService: PositionService, criteriaSearchFormService: PositionCriteriaSearchFormService, permissionService: PermissionService, activatedRoute: ActivatedRoute, router: Router) {
    super(criteriaSearchFormService, permissionService, activatedRoute, router, '/position/search');
  }
  protected load(): void {
    if (!this.searchCriteria || (this.searchCriteria && Object.values(this.searchCriteria).every(q => !q))) {
      return;
    }
    this.disableSearch = true;
    this.positionsService.setDisableNewOperation(this.disableSearch);

    this.positionsService
      .getAllPositions(this.searchCriteria)
      .pipe(takeUntil(this.shutdown$))
      .subscribe(
        result => {
          this.disableSearch = false;
          this.positionsService.setDisableNewOperation(this.disableSearch);
          this.models = Array.from(result?.resources || []).map(i => toPositionFetchModel(i));
        },
        error => {
          if (error) {
            this.disableSearch = false;
            this.positionsService.setDisableNewOperation(this.disableSearch);
          }
        }
      );
  }
  onExportCsvAction(): void {
    this.positionList.exportAsCsv();
  }

  protected initPermissions(): void {
    this.permissionService.canReadMultiplePosition$.pipe(takeUntil(this.shutdown$)).subscribe({
      next: (canReadMultiplePosition: boolean) => {
        this.canReadMultiplePosition = canReadMultiplePosition;
      }
    });
  }
}
