import { Component, OnDestroy, OnInit } from '@angular/core';
import { DocumentFileModel, DocumentModel, DocumentTypeInjectionToken } from '@documents/models';
import { DocumentService } from '@documents/services';
import { ReportingAccessFormService } from '@instruments/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormServiceInjectionToken } from '@shared/modules/forms/tokens';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-reporting-access',
  templateUrl: './reporting-access.component.html',
  providers: [DocumentService, ReportingAccessFormService, { provide: FormServiceInjectionToken, useExisting: ReportingAccessFormService }, { provide: DocumentTypeInjectionToken, useValue: '' }]
})
export class ReportingAccessComponent implements OnInit, OnDestroy {
  constructor(public readonly activeModal: NgbActiveModal, private readonly documentService: DocumentService, private readonly reportingAccessFormService: ReportingAccessFormService) {}
  public shutdown$ = new Subject<void>();
  public documentModels: DocumentModel[] = [];
  public documentsResult: DocumentFileModel[] = [];
  isLoadingDocument = false;
  formatDateForm = 'DD-MM-yyyy';
  formatDateService = 'yyyy-MM-DD';

  ngOnInit(): void {
    this.loadDocumentsType();
  }

  searchDocuments() {
    this.isLoadingDocument = true;

    const documentType = this.reportingAccessFormService.rawValue('documentType');
    const startDate = this.toFormatDateService(this.reportingAccessFormService.rawValue('startDate'));
    const endDate = this.toFormatDateService(this.reportingAccessFormService.rawValue('endDate'));
    const query = `metadata.documentType ='${documentType}' AND creationDate>='${startDate}' AND creationDate<='${endDate}'`;

    this.documentService
      .searchDocuments(null, query)
      .pipe(first())
      .subscribe(res => {
        this.documentsResult = res;
        this.isLoadingDocument = false;
      });
  }

  loadDocumentsType() {
    this.documentService
      .getAllModels()
      .pipe(takeUntil(this.shutdown$))
      .subscribe(res => {
        this.documentModels = res;
      });
  }

  toFormatDateService(date: string | null | undefined): string {
    return moment(date, this.formatDateForm).format(this.formatDateService);
  }

  public cancel(): void {
    this.activeModal.dismiss('closed');
    this.activeModal.close('closed');
  }

  ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.unsubscribe();
  }
}
