/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IpaTypeDto } from './ipaTypeDto';


export interface InstrumentEventsDataDto { 
    maturityDateAsString?: string;
    perpetual?: boolean;
    paymentDateCorrelated?: boolean;
    couponType?: IpaTypeDto;
    firstCouponPayDateAsString?: string;
    interestRate?: string;
    dayCountFraction?: IpaTypeDto;
    secondCouponType?: IpaTypeDto;
    secondCouponPayDateAsString?: string;
    secondInterestRate?: string;
    secondDayCountFraction?: IpaTypeDto;
    couponFrequency?: IpaTypeDto;
    couponPaymentCurrencies?: Array<string>;
    firstEndPeriodDateAsString?: string;
    paymentDateOffset?: string;
    paymentDateMgtMode?: IpaTypeDto;
    fundsArrivalDelayInDay?: string;
    endDateAdjusted?: boolean;
    businessDayConvention?: IpaTypeDto;
    recordDateCalculationMode?: IpaTypeDto;
    recordDatesCalculationDates?: Set<string>;
    recordDateOffset?: string;
    recordDateReference?: IpaTypeDto;
    calculationTime?: IpaTypeDto;
    calculationOffset?: string;
    redemptionValuationDateAsString?: string;
    redemptionCurrencies?: Array<string>;
    redemptionPrice?: string;
    redeemableInSecurities?: boolean;
    optionExerciseStyle?: IpaTypeDto;
    partialRedemption?: boolean;
    partialRedemptionCorrelatedToCoupon?: boolean;
    partialRedemptionType?: IpaTypeDto;
    partialRedemptCurrencies?: Array<string>;
    instrFinancialCurrencies?: Array<string>;
    instrFinancialPlaces?: Array<IpaTypeDto>;
    convertible?: boolean;
    partlyPaid?: boolean;
    putOption?: boolean;
}

