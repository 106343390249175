<nav  aria-label="Page navigation">
    <ul class="pagination   justify-content-end pagination-md">
        <li [class.disabled]="!previous" 
            class="page-item"
            (click)="changePage(1, $event)">
            <a class="page-link"> 
                <em class="icon">first_page</em>
            </a>
        </li>
        <li [class.disabled]="!previous" 
            class="page-item"
            (click)="navigate(-1, $event)">
            <a class="page-link"> 
                <em class="icon">keyboard_arrow_left</em>
            </a>
        </li>
        <li *ngFor="let page of pages" 
            class="page-item ms-2"  
            [class.active]="currentPage === page"
            (click)="changePage(page, $event)">
            <a class="page-link" >{{ page }}</a>
        </li>
        
        <li [class.disabled]="!next" 
            class="page-item ms-2"
            (click)="navigate(1, $event)">
            <a class="page-link ps-2"> 
                <em class="icon">keyboard_arrow_right</em>
            </a>
        </li>
        <li [class.disabled]="!next" 
        class="page-item"
        (click)="changePage(totalPages, $event)">
        <a class="page-link"> 
            <em class="icon">last_page</em>
        </a>
    </li>
    </ul>
</nav>

