import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditingDatePickerComponent } from './editing-date-picker/editing-date-picker.component';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomDateParserFormatter } from '../date-picker/custom-date-parser-formatter.service';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [EditingDatePickerComponent],
  exports: [EditingDatePickerComponent],
  imports: [CommonModule, NgbDatepickerModule, FormsModule, ReactiveFormsModule, TranslateModule, SharedModule],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class CustomAggridEditingModule {}
