export * from './eventDetailsResource.service';
import { EventDetailsResourceService } from './eventDetailsResource.service';
export * from './eventEcashPaymentResource.service';
import { EventEcashPaymentResourceService } from './eventEcashPaymentResource.service';
export * from './rofResource.service';
import { RofResourceService } from './rofResource.service';
export * from './scheduleEventResource.service';
import { ScheduleEventResourceService } from './scheduleEventResource.service';
export * from './swiftResource.service';
import { SwiftResourceService } from './swiftResource.service';
export * from './valuationResource.service';
import { ValuationResourceService } from './valuationResource.service';
export const APIS = [EventDetailsResourceService, EventEcashPaymentResourceService, RofResourceService, ScheduleEventResourceService, SwiftResourceService, ValuationResourceService];
