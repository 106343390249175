<ng-container [formGroup]="formGroup">
  <div class="section-forms container g-0">
    <h5 class="mb-4" translate>programmes.programme.forms.programmeCharacteristics.sectionName</h5>
    <div class="row gy-4" *ngIf="formMode === 'add'">
      <div class="pe-3 col-md-6">
        <label class="text-secondary fw-medium" [focusTo]="leiCodeInput"
          translate>programmes.programme.forms.programmeCharacteristics.leiCode <sup translateclass="mandatory">*</sup>
        </label>  
        <app-lei-multiple-select #leiCodeInput formControlName="issuerLeiCode" (blurEmit)="getErrorMessages()"
          [closeOnSelect]="true" [withDebounce]="true"
          [hasError]="(formGroup.get('issuer.leiCode')?.touched && formGroup.get('issuer.leiCode')?.errors !== null) || false"
          [placeholder]="'programmes.programme.forms.placeholder.programmeCharacteristics.leiCode' | translate"
          (programmeInformation)="onProgrammeInformationSelected($event)">
        </app-lei-multiple-select>
        <div *ngIf="formErrors.leiCode" class="invalid-fields">
          <div>{{
            formErrors.leiCode
            | translate
            : {
            field: i18nUtils.getI18nValue('programmes.programme.forms.programmeCharacteristics.leiCode')
            }
            }}</div>
        </div>
      </div>
    </div>
    <div *ngIf="isLoadingLeiInformation; else programmeInfoComponent" class="loading-spinner small text-center">
      <span>⭮</span>
    </div>
    <ng-template #programmeInfoComponent>
      <app-programme-information [formMode]="formMode" [programmeInformation]="programmeInformation">
      </app-programme-information>
    </ng-template>
    <div class="row mt-1 gy-4" *ngIf="formMode === 'add'">
      <div class="pe-3 col-md-6">
        <label class="text-secondary fw-medium" for="programmeName" translate>
          programmes.programme.forms.programmeCharacteristics.programmeName <sup translate
            class="mandatory">*</sup></label>
        <input (blur)="getErrorMessages()" maxlength="255" formControlName="programmeName" type="text"
          class="form-control" id="programmeName" [title]="formGroup.controls['programmeName'].value"
          placeholder="{{ 'programmes.programme.forms.placeholder.programmeCharacteristics.programmeName' | translate }}" />
        <div *ngIf="formErrors.programmeName" class="invalid-feedback">
          <div>{{
            formErrors.programmeName
            | translate
            : {
            field: i18nUtils.getI18nValue('programmes.programme.forms.programmeCharacteristics.programmeName'),
            maxlength: 255
            }
            }}</div>
        </div>
      </div>

      <div class="col-md-6">
        <label class="text-secondary fw-medium" for="issuer" translate>programmes.programme.forms.programmeCharacteristics.icsdProgrammeNumber</label>
        <input (blur)="getErrorMessages()" maxlength="10" formControlName="icsdProgrammeNumber" type="text"
          class="form-control" id="icsdProgrammeNumber" [placeholder]="
            formGroup.get('icsdProgrammeNumber')?.disabled || false
              ? ''
              : ('programmes.programme.forms.placeholder.programmeCharacteristics.icsdProgrammeNumber' | translate)
          " />
        <div *ngIf="formErrors.icsdProgrammeNumber" class="invalid-feedback">
          <div>{{
            formErrors.icsdProgrammeNumber
            | translate
            : {
            field: i18nUtils.getI18nValue('programmes.programme.forms.programmeCharacteristics.icsdProgrammeNumber'),
            requiredLength: 10
            }
            }}</div>
        </div>
      </div>
    </div>
  </div>
  <hr class="mb-5 mt-5" *ngIf="formMode !== 'add'" />
  <div class="section-forms container g-0">
    <h5 class="mb-4" translate>programmes.programme.forms.roles.sectionName</h5>
    <div class="row gy-4">
      <div class="pe-3 col-md-6">
        <label class="text-secondary fw-medium" for="issuingAgents"
          translate>programmes.programme.forms.roles.issuingAgents <sup translate class="mandatory">*</sup></label>
        <app-custom-multiple-select (blurEmit)="getErrorMessages()" formControlName="issuingAgents" id="issuingAgents"
          [multiple]="true" [isReadOnly]="forms.issuingAgents.disabled" [items]="issuingAgents"
          [bindLabelProperty]="'name'" [hasError]="forms.issuingAgents.touched && forms.issuingAgents.errors !== null"
          placeholder="{{ 'programmes.programme.forms.placeholder.roles.issuingAgents' | translate }}">
        </app-custom-multiple-select>

        <div *ngIf="forms.issuingAgents.touched && forms.issuingAgents.errors !== null" class="invalid-fields">
          <div>{{
            formErrors.issuingAgents
            | translate
            : {
            field: i18nUtils.getI18nValue('programmes.programme.forms.roles.issuingAgents')
            }
            }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <label class="text-secondary fw-medium" for="principalPayingAgents"
          translate>programmes.programme.forms.roles.principalPayingAgents <sup translate
            class="mandatory">*</sup></label>
        <app-custom-multiple-select (blurEmit)="getErrorMessages()" formControlName="principalPayingAgents"
          id="principalPayingAgents" [multiple]="true" [isReadOnly]="forms.principalPayingAgents.disabled"
          [items]="principalPayingAgents" [bindLabelProperty]="'name'"
          [hasError]="forms.principalPayingAgents.touched && forms.principalPayingAgents.errors !== null"
          placeholder="{{ 'programmes.programme.forms.placeholder.roles.principalPayingAgents' | translate }}">
        </app-custom-multiple-select>

        <div *ngIf="forms.principalPayingAgents.touched && forms.principalPayingAgents.errors !== null"
          class="invalid-fields">
          <div>{{
            formErrors.principalPayingAgents
            | translate
            : {
            field: i18nUtils.getI18nValue('programmes.programme.forms.roles.principalPayingAgents')
            }
            }}</div>
        </div>
      </div>
    </div>
    <div class="row gy-4 mt-1">
      <div class="pe-3 col-md-6">
        <label class="text-secondary fw-medium" for="commonDepositaries"
          translate>programmes.programme.forms.roles.commonDepositary <sup translate class="mandatory">*</sup></label>
        <app-custom-multiple-select (blurEmit)="getErrorMessages()" formControlName="commonDepositaries"
          id="commonDepositaries" [multiple]="true" [isReadOnly]="forms.commonDepositaries.disabled"
          [items]="commonDepositories" [bindLabelProperty]="'name'"
          [hasError]="forms.commonDepositaries.touched && forms.commonDepositaries.errors !== null"
          placeholder="{{ 'programmes.programme.forms.placeholder.roles.commonDepositary' | translate }}">
        </app-custom-multiple-select>

        <div *ngIf="forms.commonDepositaries.touched && forms.commonDepositaries.errors !== null"
          class="invalid-fields">
          <div>{{
            formErrors.commonDepositaries
            | translate
            : {
            field: i18nUtils.getI18nValue('programmes.programme.forms.roles.commonDepositary')
            }
            }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <label class="text-secondary fw-medium" for="registrars"
          translate>programmes.programme.forms.roles.registrars </label>
        <app-custom-multiple-select (blurEmit)="getErrorMessages()" formControlName="registrars" id="registrars"
          [multiple]="true" [isReadOnly]="forms.registrars.disabled" [items]="registrars" [bindLabelProperty]="'name'"
          [hasError]="forms.registrars.touched && forms.registrars.errors !== null"
          placeholder="{{ 'programmes.programme.forms.placeholder.roles.registrars' | translate }}">
        </app-custom-multiple-select>

        <div *ngIf="forms.registrars.touched && forms.registrars.errors !== null" class="invalid-fields">
          <div>{{
            formErrors.registrars
            | translate
            : {
            field: i18nUtils.getI18nValue('programmes.programme.forms.roles.registrars')
            }
            }}</div>
        </div>
      </div>
    </div>
    <div class="row gy-4 mt-1" *ngIf="showEurosystemEligibilities">
      <div class="col-md-6 pe-3">
        <label class="text-secondary fw-medium" for="eurosystemEligibility"
          translate>programmes.programme.forms.roles.eurosystemEligibility <sup translate
            class="mandatory">*</sup></label>
        <app-yes-no-buttons formControlName="eurosystemEligibility"></app-yes-no-buttons>
      </div>
      <div *ngIf="requiredCommonSafekeeper()" class="col-md-6">
        <label class="text-secondary fw-medium" for="commonSafekeepers"
          translate>programmes.programme.forms.roles.commonSafekeepers <sup translate class="mandatory">*</sup></label>
        <app-custom-multiple-select (blurEmit)="getErrorMessages()" formControlName="commonSafekeepers"
          id="commonSafekeepers" [multiple]="true" [isReadOnly]="forms.commonSafekeepers.disabled"
          [items]="commonSafekeepers" [bindLabelProperty]="'name'"
          [hasError]="forms.commonSafekeepers.touched && forms.commonSafekeepers.errors !== null"
          placeholder="{{ 'programmes.programme.forms.placeholder.roles.commonSafekeeper' | translate }}">
        </app-custom-multiple-select>
        <div *ngIf="forms.commonSafekeepers.touched && forms.commonSafekeepers.errors !== null" class="invalid-fields">
          <div>{{
            formErrors.commonSafekeepers
            | translate
            : {
            field: i18nUtils.getI18nValue('programmes.programme.forms.roles.commonSafekeepers')
            }
            }}</div>
        </div>
      </div>
    </div>
    <div class="row gy-4 mt-1">
      <div class="pe-3 col-md-6">
        <label class="text-secondary fw-medium" for="fiscalAgents"
          translate>programmes.programme.forms.roles.fiscalAgents </label>
        <app-custom-multiple-select (blurEmit)="getErrorMessages()" formControlName="fiscalAgents" id="fiscalAgents"
          [multiple]="true" [isReadOnly]="forms.fiscalAgents.disabled" [items]="listingFiscalAgents"
          [bindLabelProperty]="'name'" [hasError]="forms.fiscalAgents.touched && forms.fiscalAgents.errors !== null"
          placeholder="{{ 'programmes.programme.forms.placeholder.roles.fiscalAgents' | translate }}">
        </app-custom-multiple-select>

        <div *ngIf="forms.fiscalAgents.touched && forms.fiscalAgents.errors !== null" class="invalid-fields">
          <div>{{
            formErrors.fiscalAgents
            | translate
            : {
            field: i18nUtils.getI18nValue('programmes.programme.forms.roles.fiscalAgents')
            }
            }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <label class="text-secondary fw-medium" for="localPayingAgents"
          translate>programmes.programme.forms.roles.localPayingAgents <sup *ngIf="requiredLocalPayingAgents()"
            translate class="mandatory">*</sup></label>
        <app-custom-multiple-select (blurEmit)="getErrorMessages()" formControlName="localPayingAgents"
          id="localPayingAgents" [multiple]="true" [isReadOnly]="forms.localPayingAgents.disabled"
          [items]="localPayingAgents" [bindLabelProperty]="'name'"
          [hasError]="forms.localPayingAgents.touched && forms.localPayingAgents.errors !== null"
          placeholder="{{ 'programmes.programme.forms.placeholder.roles.localPayingAgents' | translate }}">
        </app-custom-multiple-select>

        <div *ngIf="forms.localPayingAgents.touched && forms.localPayingAgents.errors !== null" class="invalid-fields">
          <div>{{
            formErrors.localPayingAgents
            | translate
            : {
            field: i18nUtils.getI18nValue('programmes.programme.forms.roles.localPayingAgents')
            }
            }}</div>
        </div>
      </div>
    </div>
    <div class="row gy-4 mt-1">
      <div class="pe-3 col-md-6">
        <label class="text-secondary fw-medium" for="listingAgents"
          translate>programmes.programme.forms.roles.listingAgents <sup translate class="mandatory">*</sup></label>
        <app-custom-multiple-select (blurEmit)="getErrorMessages()" formControlName="listingAgents" id="listingAgents"
          [multiple]="true" [isReadOnly]="forms.listingAgents.disabled" [items]="listingAgents"
          [bindLabelProperty]="'name'" [hasError]="forms.listingAgents.touched && forms.listingAgents.errors !== null"
          placeholder="{{ 'programmes.programme.forms.placeholder.roles.listingAgents' | translate }}">
        </app-custom-multiple-select>

        <div *ngIf="forms.listingAgents.touched && forms.listingAgents.errors !== null" class="invalid-fields">
          <div>{{
            formErrors.listingAgents
            | translate
            : {
            field: i18nUtils.getI18nValue('programmes.programme.forms.roles.listingAgents')
            }
            }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <label class="text-secondary fw-medium" for="calculationAgent"
          translate>programmes.programme.forms.roles.calculationAgent</label>
        <app-custom-multiple-select (blurEmit)="getErrorMessages()" formControlName="calculationAgent"
          id="calculationAgent" [multiple]="true" [isReadOnly]="forms.calculationAgent.disabled"
          [items]="calculationAgents" [bindLabelProperty]="'name'" [closeOnSelect]="true"
          [hasError]="forms.calculationAgent.touched && forms.calculationAgent.errors !== null"
          placeholder="{{ 'programmes.programme.forms.placeholder.roles.calculationAgent' | translate }}">
        </app-custom-multiple-select>
        <div *ngIf="forms.calculationAgent.touched && forms.calculationAgent.errors !== null" class="invalid-fields">
          <div>{{
            formErrors.calculationAgent
            | translate
            : {
            field: i18nUtils.getI18nValue('programmes.programme.forms.roles.calculationAgent')
            }
            }}</div>
        </div>
      </div>
    </div>
    <div class="row gy-4 mt-1">
      <div class="pe-3 col-md-6">
        <label class="text-secondary fw-medium" for="transferAgents"
          translate>programmes.programme.forms.roles.transferAgent</label>
        <app-custom-multiple-select (blurEmit)="getErrorMessages()" formControlName="transferAgents" id="transferAgents"
          [multiple]="true" [isReadOnly]="forms.transferAgents.disabled" [items]="transferAgents"
          [bindLabelProperty]="'name'" [closeOnSelect]="true"
          [hasError]="forms.transferAgents.touched && forms.transferAgents.errors !== null"
          placeholder="{{ 'programmes.programme.forms.placeholder.roles.transferAgent' | translate }}">
        </app-custom-multiple-select>
        <div *ngIf="forms.transferAgents.touched && forms.transferAgents.errors !== null" class="invalid-fields">
          <div>{{
            formErrors.transferAgents
            | translate
            : {
            field: i18nUtils.getI18nValue('programmes.programme.forms.roles.transferAgents')
            }
            }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="section-forms container g-0" [hidden]="!requiredCompetentAuthorities()">
    <h5 class="mb-4" translate>programmes.programme.forms.competentAuthorities.sectionName</h5>
    <div class="row gy-4 mt-1">
      <div class="col-md-6">
        <label class="text-secondary fw-medium" for="competentAuthorities"
          translate>programmes.programme.forms.competentAuthorities.competentAuthorities <sup translate
            class="mandatory">*</sup></label>
        <app-custom-multiple-select (blurEmit)="getErrorMessages()" formControlName="competentAuthorities"
          id="competentAuthorities" [multiple]="true" [isReadOnly]="forms.competentAuthorities.disabled"
          [items]="competentAuthorities" [bindLabelProperty]="'name'"
          [hasError]="forms.competentAuthorities.touched && forms.competentAuthorities.errors !== null"
          placeholder="{{ 'programmes.programme.forms.placeholder.competentAuthorities.competentAuthorities' | translate }}">
        </app-custom-multiple-select>
        <div *ngIf="forms.competentAuthorities.touched && forms.competentAuthorities.errors !== null"
          class="invalid-fields">
          <div>{{
            formErrors.competentAuthorities
            | translate
            : {
            field: i18nUtils.getI18nValue('programmes.programme.forms.competentAuthorities.competentAuthorities')
            }
            }}</div>
        </div>
      </div>
    </div>
    <div class="row gy-4 mt-1" *ngIf="requiredAuthorityProgrammeIdentifier()">
      <div class="pe-3 col-md-6">
        <label class="text-secondary fw-medium" for="authorityProgrammeIdentifier"
          translate>programmes.programme.forms.competentAuthorities.authorityProgrammeIdentifier <sup translate
            class="mandatory">*</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">C-</span>
          </div>
          <input (blur)="getErrorMessages()" maxlength="6" formControlName="authorityProgrammeIdentifier" type="text"
            class="form-control" id="authorityProgrammeIdentifier"
            placeholder="{{ 'programmes.programme.forms.placeholder.competentAuthorities.authorityProgrammeIdentifier' | translate }}" />

          <div *ngIf="formErrors.authorityProgrammeIdentifier" class="invalid-feedback">
            <div>{{
              formErrors.authorityProgrammeIdentifier
              | translate
              : {
              field:
              i18nUtils.getI18nValue('programmes.programme.forms.competentAuthorities.authorityProgrammeIdentifier'),
              requiredLength: 6
              }
              }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <label class="text-secondary fw-medium" for="authorityProgrammeIdentifierDate"
          translate>programmes.programme.forms.competentAuthorities.authorityProgrammeIdentifierDate <sup translate
            class="mandatory">*</sup></label>
        <app-date-picker (inputBlur)="getErrorMessages()" focusId="authorityProgrammeIdentifierDate"
          formControlName="authorityProgrammeIdentifierDate"></app-date-picker>
        <div *ngIf="formErrors.authorityProgrammeIdentifierDate" class="invalid-feedback">
          <div>{{
            formErrors.authorityProgrammeIdentifierDate
            | translate
            : {
            field:
            i18nUtils.getI18nValue('programmes.programme.forms.competentAuthorities.authorityProgrammeIdentifierDate'),
            formatDate: 'dd/MM/YYYY'
            }
            }}</div>
        </div>
      </div>
    </div>
    <div class="row gy-4 mt-1">
      <ng-container
        *ngIf="(requiredAuthorityProgrammeIdentifier() && formMode !== 'add' && (formGroup.get('oldAuthorityProgrammeIdentifier')?.value || false)) || false">
        <div class="pe-3 col-md-6">
          <label class="text-secondary fw-medium" for="oldAuthorityProgrammeIdentifier"
            translate>programmes.programme.forms.competentAuthorities.oldAuthorityProgrammeIdentifier</label>
          <div class="input-group">
            <input type="text" class="form-control" id="oldAuthorityProgrammeIdentifier"
              formControlName="oldAuthorityProgrammeIdentifier" readonly />
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="(requiredAuthorityProgrammeIdentifier() && isAddMode && formGroup.get('oldAuthorityProgrammeIdentifierDate')?.value!) || false">
        <div class="col-md-6">
          <label class="text-secondary fw-medium" for="oldAuthorityProgrammeIdentifierDate"
            translate>programmes.programme.forms.competentAuthorities.oldAuthorityProgrammeIdentifierDate</label>
          <!--Date picker-->

          <app-date-picker focusId="oldAuthorityProgrammeIdentifierDate"
            formControlName="oldAuthorityProgrammeIdentifierDate"></app-date-picker>

          <div *ngIf="formErrors.oldAuthorityProgrammeIdentifierDate" class="invalid-feedback">
            <div>{{
              formErrors.oldAuthorityProgrammeIdentifierDate
              | translate
              : {
              field:
              i18nUtils.getI18nValue('programmes.programme.forms.competentAuthorities.authorityProgrammeIdentifierDate'),
              formatDate: 'dd/MM/YYYY'
              }
              }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="section-forms container g-0" *ngIf="isAddMode">
    <h5 class="mb-4" translate>programmes.programme.forms.documentation</h5>
    <div class="row">
      <div class="col-md-12 pb-4">
        <app-document-forms-list [multiple]="true"></app-document-forms-list>
      </div>
    </div>
  </div>
  <app-error-list-from-backend></app-error-list-from-backend>
  <div class="row" *ngIf="isAddOrEdit">
    <small><em class="btn-container-item pt-2" translate> mandatoryFields </em></small>
  </div>
</ng-container>
