<ng-container *ngIf="!isReadOnly; else readonlyTemplate">
  <div class="mdp-input w-100" (appClickOutside)="hideMonths()">
    <div class="d-flex d-align-items-center d-flex justify-content-between w-100" (click)="toggleShowMonths()">
      <div class="mdp-dates">
        <span class="text-muted ms-2" *ngIf="!selectedDates.length">{{placeholder}}</span>
        <app-date-pill *ngFor="let date of selectedDates; let idx=index" [date]="date" (deleted)="onDateDeleted(idx)">
        </app-date-pill>
      </div>
      <button class="btn btn-outline-secondary" type="button" [attr.id]="focusId">
        <em class="sgwt-widgets-icon icon-sm"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
            id="date_range" class="icon undefined" fill="currentColor">
            <path
              d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z">
            </path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </em>
      </button>
    </div>
    <div class="mdp-calendar border border-secondary p-2" *ngIf="showMonths">
      <div class="btn-group d-flex justify-content-center p-2">
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="navigate(-1);"
          translate>date-picker.prev</button>
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="today()"
          translate>date-picker.today</button>
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="navigate(1)"
          translate>date-picker.next</button>
      </div>
      <div class="mdp-months d-flex flex-row flex-wrap justify-content-around">
        <div class="mx-2 my-1" *ngFor="let month of displayedMonths">
          <app-month-display [nbDays]="nbDays(month)" [monthNumber]="month" [selectedDates]="selectedDates"
            (dateSelected)="onDateSelected($event)">
          </app-month-display>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #readonlyTemplate>
  <ng-container *ngFor="let date of selectedDates">
    <app-date-pill [isReadOnly]="isReadOnly" [date]="date"></app-date-pill>
  </ng-container>
</ng-template>
