import { ValuationSearchRequestDTO, SearchValuationPaginationDto } from '@apis/backend/events';
import { ValuationCriteriaSearchModel } from '.';
import { TypedDataModel, IpaTypeModel } from '@shared/models';

export const toValuationSearchInputModel = (valuationSearchInput: ValuationCriteriaSearchModel, pageNumber: number, itemsByPage: number): SearchValuationPaginationDto => {
  return {
    pageNumber: pageNumber,
    itemsByPage: itemsByPage,
    criteria: {
      eventReferences: valuationSearchInput.eventRef ? [Number(valuationSearchInput.eventRef)] : undefined,
      eventTypeValueIds: convertToNumberArray(valuationSearchInput.eventTypes),
      isinLists: valuationSearchInput.isin ? Array.from(new Array(valuationSearchInput.isin)) : [],
      valuationIds: valuationSearchInput.valuationRef ? [Number(valuationSearchInput.valuationRef)] : undefined,
      valueFromDate: valuationSearchInput.valueFromDate ? toDateFormat(valuationSearchInput.valueFromDate) : undefined,
      valueToDate: valuationSearchInput.valueToDate ? toDateFormat(valuationSearchInput.valueToDate) : undefined,
      receptionFromDate: valuationSearchInput.receiptFromDate ? toDateFormat(valuationSearchInput.receiptFromDate) : undefined,
      receptionToDate: valuationSearchInput.receiptToDate ? toDateFormat(valuationSearchInput.receiptToDate) : undefined,
      valuationStatusLists: convertToNumberArray(valuationSearchInput.valuationStatus),
      valuationVersion: valuationSearchInput.valuationVersion?.toString(),
      redemptionTypeIds: convertToNumberArray(valuationSearchInput.redemptionTypes),
      valuationRejectReasons: convertToStringArray(valuationSearchInput.valuationRejectReasons)
    }
  };
};

export const toValuationCsvExportModel = (valuationSearchInput: ValuationCriteriaSearchModel): ValuationSearchRequestDTO => {
  return {
    eventReferences: valuationSearchInput.eventRef ? [Number(valuationSearchInput.eventRef)] : undefined,
    eventTypeValueIds: convertToNumberArray(valuationSearchInput.eventTypes),
    isinLists: valuationSearchInput.isin ? Array.from(new Array(valuationSearchInput.isin)) : [],
    valuationIds: valuationSearchInput.valuationRef ? [Number(valuationSearchInput.valuationRef)] : undefined,
    valueFromDate: valuationSearchInput.valueFromDate ? toDateFormat(valuationSearchInput.valueFromDate) : undefined,
    valueToDate: valuationSearchInput.valueToDate ? toDateFormat(valuationSearchInput.valueToDate) : undefined,
    receptionFromDate: valuationSearchInput.receiptFromDate ? toDateFormat(valuationSearchInput.receiptFromDate) : undefined,
    receptionToDate: valuationSearchInput.receiptToDate ? toDateFormat(valuationSearchInput.receiptToDate) : undefined,
    valuationStatusLists: convertToNumberArray(valuationSearchInput.valuationStatus),
    valuationVersion: valuationSearchInput.valuationVersion?.toString(),
    redemptionTypeIds: convertToNumberArray(valuationSearchInput.redemptionTypes),
    valuationRejectReasons: convertToStringArray(valuationSearchInput.valuationRejectReasons)
  };
};

const convertToNumberArray = (event: TypedDataModel[] | IpaTypeModel[] | null | undefined): number[] => {
  const arr: number[] = [];
  event?.forEach(element => {
    arr.push(Number(element.valueId));
  });
  return arr;
};

const convertToStringArray = (event: TypedDataModel[] | IpaTypeModel[] | null | undefined): string[] => {
  const arr: string[] = [];
  event?.forEach(element => {
    arr.push(element.valueDescription!);
  });
  return arr;
};

const toDateFormat = (dateString: string): string => {
  const [year, month, day] = dateString.split('-');
  return `${day}-${month}-${year}`;
};
