import { CreateEventPositionEntitlementRequest, EventPositionSearchCriteriaDto } from '@apis/backend/payments';

export interface PositionsModel {
  readonly clsPosition?: number;
  readonly clsQty?: number;
  readonly clsEligible?: number;
  readonly clsNonEligible?: number;
  readonly clsAmount?: number;
  readonly clsUnderlyingQty?: number;
  readonly eosPosition?: number;
  readonly eocQty?: number;
  readonly eocEligible?: number;
  readonly eocNonEligible?: number;
  readonly eocAmount?: number;
  readonly eocUnderlyingQty?: number;
  readonly outstandingPosition?: number;
  readonly totalQty?: number;
  readonly totalEligible?: number;
  readonly totalNonEligible?: number;
  readonly totalAmount?: number;
  readonly totalUnderlyingQty?: number;
}

export interface CreatePositionModel {
  isin?: string;
  eventId: number;
  pullFromCommonDepository?: boolean;
}

export interface UpdatePositionModel {
  clsPosition?: number;
  eocPosition?: number;
  totalQty?: number;
  clsSemeReference?: string;
  eocSemeReference?: string;
  eventId: number | undefined;
  pullFromCommonDepository?: boolean;
  doPositionAmountRecalculation?: boolean;
}

export interface UpdateAdjustedQuantityModel {
  eventId?: string;
  clsAdjQty?: number;
  eocAdjQty?: number;
  totalAdjQty?: number;
  updateAdjustedQty?: boolean;
}

export const toEventPositionSearchDto = (eventId: number): EventPositionSearchCriteriaDto => {
  return {
    eventReferences: [Number(eventId)]
  };
};

export const toCreateEventsPositionDto = (position: CreatePositionModel): CreateEventPositionEntitlementRequest => {
  const dto = {
    isinCode: position.isin,
    eventId: position.eventId,
    pullFromCommonDepository: position.pullFromCommonDepository
  };
  return {
    resources: [dto]
  };
};

export const toUpdateEventsPositionDto = (updatedPosition: UpdatePositionModel): CreateEventPositionEntitlementRequest => {
  const dto = {
    eventId: updatedPosition.eventId || undefined,
    clearStreamQty: updatedPosition.clsPosition || undefined,
    euroClearQty: updatedPosition.eocPosition || undefined,
    totalQty: updatedPosition.totalQty || undefined,
    clsSemeReference: updatedPosition.clsSemeReference || undefined,
    eocSemeReference: updatedPosition.eocSemeReference || undefined,
    doPositionAmountRecalculation: updatedPosition.doPositionAmountRecalculation,
    pullFromCommonDepository: updatedPosition.pullFromCommonDepository
  };
  return {
    resources: [dto]
  };
};
