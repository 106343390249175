import { Component, OnInit, ViewChild } from '@angular/core';
import { ECashPaymentsCriteriaSearchFormService, ECashPaymentsService, EcashPaymentsSearchService, EcashPaymentsSearchQueryModel } from '../../services';
import { ECashPaymentsCriteriaSearchModel, toECashPaymentsModel, ECashPaymentsDetailModel } from '../../models';
import { ActivatedRoute, Router } from '@angular/router';
import { ECashPaymentsListComponent } from '..';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { downloadFile } from '@utils/utility-functions';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-e-cash-payments-container',
  templateUrl: './e-cash-payments-container.component.html',
  styleUrls: ['./e-cash-payments-container.component.scss'],
  providers: [ECashPaymentsCriteriaSearchFormService, EcashPaymentsSearchService]
})
export class ECashPaymentsContainerComponent implements OnInit {
  @ViewChild(ECashPaymentsListComponent) eCashPaymentsListComponent!: ECashPaymentsListComponent;
  eCashPaymentSearchCriteria: ECashPaymentsCriteriaSearchModel | undefined | null;
  private readonly shutdown$ = new Subject<void>();
  public eCashPayments: ECashPaymentsDetailModel[] = [];
  public disableSearch = false;
  public displayPagination = false;
  loaded?: Promise<boolean>;
  itemsByPage = 50;
  pageNumber = 1;
  totalItems = 0;
  constructor(
    private readonly eCashPaymentsCriteriaSearchFormService: ECashPaymentsCriteriaSearchFormService,
    private readonly eCashPaymentsService: ECashPaymentsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly eCashPaymentsSearchService: EcashPaymentsSearchService
  ) {}

  ngOnInit() {
    const queryString = this.activatedRoute.snapshot.queryParams.query;
    if (queryString) {
      const query = JSON.parse(queryString) as EcashPaymentsSearchQueryModel;
      this.eCashPaymentsSearchService.setInitialValue(query);
    }
    this.subscribeToFormChanges();
    if (queryString !== undefined && queryString !== '{}') {
      this.search();
    }
  }

  private subscribeToFormChanges(): void {
    const Allkeys = this.eCashPaymentsSearchService.getfields();
    this.eCashPaymentsSearchService.valueChanges(Allkeys).subscribe(q => {
      const query = this.removeEmpty(q);
      const stringfiedPredicate = JSON.stringify(query);
      this.router.navigate(['/eCashPayments'], { queryParams: { query: stringfiedPredicate } });
    });
  }

  search() {
    this.pageNumber = 1;
    this.loadEcashPayments();
  }

  loadEcashPayments() {
    this.disableSearch = true;
    this.eCashPayments = [];
    this.eCashPaymentSearchCriteria = this.eCashPaymentsCriteriaSearchFormService.value();
    this.eCashPaymentsService.getEcashPayments(this.eCashPaymentSearchCriteria ?? {}, this.pageNumber, this.itemsByPage).subscribe(
      result => {
        if (result?.dtos) {
          result?.dtos.forEach(eCashPayments => {
            const eCashPaymentsData = toECashPaymentsModel(eCashPayments);
            if (eCashPaymentsData) {
              this.eCashPayments.push(eCashPaymentsData);
            }
            this.totalItems = result.totalItems!;
            this.displayPagination = true;
            this.disableSearch = false;
            this.loaded = Promise.resolve(true);
          });
        } else {
          this.eCashPayments = [];
          this.disableSearch = false;
          this.loaded = Promise.resolve(true);
        }
      },
      error => {
        if (error) {
          this.disableSearch = false;
        }
      }
    );
  }

  public exportCsv() {
    this.disableSearch = true;
    this.eCashPaymentsService
      .exportValuationsData(this.eCashPaymentSearchCriteria!)
      .pipe(takeUntil(this.shutdown$))
      .subscribe(
        blob => {
          if (!blob) {
            return;
          }
          const date = formatDate(new Date(), 'yyyy_MM_dd', 'en');
          downloadFile(blob, `${date}_ECashPayments` + '.csv');
          this.disableSearch = false;
        },
        error => {
          if (error) this.disableSearch = false;
        }
      );
  }

  onPageChanged(selectedPageNumber: any) {
    this.pageNumber = selectedPageNumber;
    this.loadEcashPayments();
  }

  removeEmpty(obj: any) {
    return Object.entries(obj)
      .filter(([_, v]) => v != null)
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }
}
