<div class="section-forms" [formGroup]="formGroup">
    <div class="container-fluid g-0" >
        <div class="row">
            <div class="col">
                <div class="row gy-2">
                    <div class="col-md-1">
                        <app-valuation-filter-selector></app-valuation-filter-selector>
                    </div>
                    <div class="col-md-2">
                        <div class="input-group input-group-md">
                            <div class="input-icon-start">
                                <em class="icon text-secondary">search</em>
                            </div>
                            <input #isin component type="search" placeholder="{{ placeholder(fields.isin)  | translate }}"
                                class="form-control form-control-md" [formControlName]="fields.isin">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="input-group input-group-md">
                            <div class="input-icon-start">
                                <em class="icon text-secondary">search</em>
                            </div>
                            <input [formControlName]="fields.valuationRef" type="search"
                                placeholder="{{ placeholder(fields.valuationRef)  | translate }}" class="form-control form-control-md"
                                #ipaCode component>
                        </div>
                    </div>
            
                    <div class="col-md-3">
                        <app-valuation-type-multiselect #status component [multiple]="true"
                            [formControlName]="fields.valuationStatus" [withSummary]="true"
                            placeholder="{{ placeholder(fields.valuationStatus)  | translate }}" [closeOnSelect]="true"
                            [type]="VALUATION_STATUS" [defaultItems]=valuationStatusDropDown
                            [hasError]="hasError(fields.valuationStatus)">
                        </app-valuation-type-multiselect>
                    </div>

                    <div class="col-md-2">
                        <app-advanced-filters-button [advancedFiltersShown]="!isCollapsed" (changed)="collapse.toggle()">
                        </app-advanced-filters-button>
                    </div>
                </div>
                <div class="row mt-1 mb-1 gy-2">
                    <div class="col-md-3">
                        <div class="input-group input-group-md">
                            <div class="input-icon-start">
                                <em class="icon text-secondary">search</em>
                            </div>
                            <input [formControlName]="fields.eventRef" type="search"
                                placeholder="{{ placeholder(fields.eventRef)  | translate }}" class="form-control form-control-md"
                                #eventRef component>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <app-valuation-type-multiselect #eventType component [multiple]="true" [formControlName]="fields.eventTypes"
                            placeholder="{{ placeholder(fields.eventTypes)  | translate }}" [closeOnSelect]="true" [withSummary]="true"
                            [type]="EVENT_TYPE" [defaultItems]=eventTypesDropDown [hasError]="hasError(fields.eventTypes)">
                        </app-valuation-type-multiselect>
                    </div>

                    <div class="col-md-3">
                        <app-valuation-type-multiselect #eventType component [multiple]="true"
                            [formControlName]="fields.redemptionTypes" [withSummary]="true"
                            placeholder="{{ placeholder(fields.redemptionTypes)  | translate }}" [closeOnSelect]="true"
                            [type]="EVENT_REDEMPTION_TYPE" [hasError]="hasError(fields.redemptionTypes)">
                        </app-valuation-type-multiselect>
                    </div>
                    <div class="col-md-3">
                        <select class="form-control" [formControlName]="fields.valuationVersion">
                            <option [ngValue]=null selected>{{'valuation.list.filters.valuationVersion'|translate}}</option>
                            <option *ngFor="let item of valuationVersionDropDown" [ngValue]="item">{{item | translate}}</option>
                        </select>
                    </div>
                </div>
            </div>
    
            <div class="col-md-1">
                <ng-content select="[searchTemplate]"></ng-content>
            </div>
        </div>
    </div>

    <div class="container-fluid g-0 pt-3 pb-3" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <div class="row gy-2">
            <div class="col">
                <div class="row">
                    <div class="col-md-3">
                        <sg-daterangepicker #valueDate component
                            (dateChanged)="onDatesChanged($event,'valueFromDate','valueToDate')" [endDate]="valueEndDate"
                            startPlaceholder="{{'events.list.filters.valueDate'|translate}}"
                            endPlaceholder="{{'events.list.filters.valueDate'|translate}}" [startDate]="valueBeginDate">
                        </sg-daterangepicker>
                    </div>
                    <div class="col-md-3">
                        <sg-daterangepicker #receiptDate component
                            (dateChanged)="onDatesChanged($event,'receiptFromDate','receiptToDate')"
                            startPlaceholder="{{'valuation.list.filters.receiptDate'|translate}}"
                            endPlaceholder="{{'valuation.list.filters.receiptDate'|translate}}" [endDate]="receiptToDate"
                            [startDate]="receiptFromDate">
                        </sg-daterangepicker>
                    </div>
                    <div class="col-md-3">
                        <app-valuation-type-multiselect #eventType component [multiple]="true"
                        [formControlName]="fields.valuationRejectReasons" [withSummary]="true"
                        placeholder="{{ placeholder(fields.valuationRejectReasons)  | translate }}" [closeOnSelect]="true"
                        [defaultItems]=valuationRejectReasons
                        [hasError]="hasError(fields.valuationRejectReasons)">
                    </app-valuation-type-multiselect>
                </div>
                </div>
            </div>
            <div class="col-md-1">

            </div>
            
        </div>
    </div>
    <div class="container-fluid g-0 mt-2 mb-4">
        <div class="row">
            <div class="col-md-10">
                <app-valuation-list-active-filters></app-valuation-list-active-filters>
            </div>
            <div class="col-md-2 d-flex justify-content-end align-items-end">
                <button class="btn btn-lg btn-flat-secondary btn-icon-start" [disabled]="disableExportCsv" (click)="exportCsv()" *ngIf="canExport" translate>
                    <em class="icon">save_alt</em> common-buttons.CSV-export
                </button>
            </div>
        </div>
    </div>
