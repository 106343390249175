<div>
  <button class="btn btn-sm btn-flat" (click)="goToDetail()">
    <em class="icon">visibility</em>
  </button>
  
  <button class="btn btn-sm btn-flat" (click)="goToSettlement()">
    <em class="icon">currency_exchange</em>
  </button>
  <button class="btn btn-sm btn-flat" *ngIf="canGoToCommonDep()" (click)="goToCommonDep()">
    <em class="icon">account_balance</em>
  </button>
</div>

