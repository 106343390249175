import { Component } from '@angular/core';

@Component({
  selector: 'app-hide-and-show',
  templateUrl: './hide-and-show.component.html'
})
export class HideAndShowComponent {
  public hide = true;

  public changeHide(): void {
    this.hide = !this.hide;
  }
}
