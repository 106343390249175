import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { AbstractColDef, ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community';

export abstract class AgGridColumnDefBaseService {
  constructor(private readonly translateService: I18nUtilsService, private readonly fieldsTranslateBase: string = '', protected readonly columnDefs: ColDef[] | ColGroupDef[]) {}
  get(): AbstractColDef[] {
    const columns = this.columnDefs;

    for (const field of columns) {
      field.headerValueGetter = this.localizeHeader.bind(this);
      if ('children' in field) {
        const children = field?.children;
        children.forEach(child => {
          child.headerValueGetter = this.localizeHeader.bind(this);
        });
      }
    }
    return columns;
  }

  public localizeHeader(parameters: ICellRendererParams): string | undefined {
    const headerIdentifier = parameters.colDef?.field ?? parameters.colDef?.headerName;

    if (headerIdentifier) {
      return this.translateService.getI18nValue(`${this.fieldsTranslateBase}.${headerIdentifier}`);
    }

    return headerIdentifier;
  }
}
