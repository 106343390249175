import { Injectable } from '@angular/core';
import { AgGridColumnDefBaseService } from '@shared/services/aggrid-column-def-base-service';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { OPERATION_DETAIL_COLUMNS } from './operation-detail-column-def';

@Injectable()
export class OperationDetailColumnDefService extends AgGridColumnDefBaseService {
  constructor(translateService: I18nUtilsService) {
    super(translateService, 'operations.detail.headers', OPERATION_DETAIL_COLUMNS);
  }
}
