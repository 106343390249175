<div class="d-flex flex-row justify-content-between mb-0 mt-2">
  <app-instrument-settlement-values class="col-md-6"></app-instrument-settlement-values>
  <div>
    <button (click)="onCancelClick()" (keyup.enter)="onCancelClick()" class="btn btn-md btn-flat-primary"
      translate>common-buttons.cancel </button>
    <button (click)="onSaveClick()" (keyup.enter)="onSaveClick()" class="btn btn-md btn-info ms-3"
      translate> <em class="icon me-1">done</em> common-buttons.save </button>
  </div>
</div>

<div class="row mt-2">
  <div class="col-md-12">
    <ag-grid-angular #agGrid class="ag-theme-sg-bootstrap-condensed"  [gridOptions]="gridOptions!"  style="height: calc(50vh)"
      [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [rowData]="settlemnts" [rowSelection]="rowSelection"
      (cellValueChanged)="onCellValueChanged($event)" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
    <button (click)="add()" class="btn btn btn-default btn-md ms-3 mt-2 btn-icon-start">
      <em class="icon">add</em>
      <span class="title-case" translate>instruments.instrument.forms.settlement.add</span>
    </button>

  </div>
</div>
