<div class="section-forms" [formGroup]="formGroup">
    <div class="container-fluid g-0">
        <div class="row">
            <div class="col">
                <div class="row gy-2">
                    <div class="col-md-1">
                        <app-event-filter-selector></app-event-filter-selector>
                    </div>
                    <div class="col-md-2">
                        <div class="input-group input-group-md">
                            <div class="input-icon-start">
                                <em class="icon text-secondary">search</em>
                            </div>
                            <input placeholder="{{ placeholder(fields.isin)  | translate }}" #isin component type="search"
                                [formControlName]="fields.isin" class="form-control form-control-md">
                        </div>
                    </div>
            
                    <div class="col-md-3">
                        <div class="input-group input-group-md">
                            <div class="input-icon-start">
                                <em class="icon text-secondary">search</em>
                            </div>
                            <input [formControlName]="fields.ipaCode" type="search"
                                placeholder="{{ placeholder(fields.ipaCode)  | translate }}" class="form-control form-control-md"
                                #ipaCode component>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="input-group input-group-md">
                            <div class="input-icon-start">
                                <em class="icon text-secondary">search</em>
                            </div>
                            <input [formControlName]="fields.eventId" type="search"
                                placeholder="{{ placeholder(fields.eventId)  | translate }}" class="form-control form-control-md"
                                #eventId component>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <app-currency-multiple-select [formControlName]="fields.paymentCurrencies" icon="search" [withSummary]="true"
                            [placeholder]="placeholder(fields.paymentCurrencies) | translate" #paymentCurrencies [multiple]="true" component>
                        </app-currency-multiple-select>
                    </div>
            
                    <div class="col-md-2">
                        <app-advanced-filters-button [advancedFiltersShown]="!isCollapsed" [hasFilters]="filters.length>0"
                            (changed)="collapse.toggle()"></app-advanced-filters-button>
                    </div>
                </div>
                <div class="row mt-1 mb-1 gy-2">
                    <div class="col-md-3">
                        <app-type-multiple-select #eventType component [formControlName]="fields.eventTypes" [multiple]="true" [withSummary]="true"
                            [closeOnSelect]="true" [type]="EVENT_TYPE" [hasError]="hasError(fields.eventTypes)"
                            styleClass="form-control-lg mt-1" placeholder="{{ placeholder(fields.eventTypes)  | translate }}">
                        </app-type-multiple-select>
                    </div>
            
                    <div class="col-md-3">
                        <app-type-multiple-select #status component [formControlName]="fields.eventStatuses" [multiple]="true" [withSummary]="true"
                            [closeOnSelect]="true" [type]="EVENT_WORKFLOW_STATUS" [hasError]="hasError(fields.eventStatuses)"
                            styleClass="event-multi-select form-control-lg" [maxDisplayedItems]="maxDisplayedItems"
                            placeholder="{{ placeholder(fields.eventStatuses)  | translate }}">
                        </app-type-multiple-select>
                    </div>

                    <div class="col-md-2">
                        <select class="form-control" [formControlName]="fields.issuerPerimter">
                          <option [ngValue]=null selected>{{'instruments.list.filters.issuerPerimter'|translate}}</option>
                          <option ngValue=SPI>{{'instruments.list.filters.spi'|translate}}</option>
                          <option ngValue=STA>{{'instruments.list.filters.sta'|translate}}</option>
                        </select>
                      </div>
                </div>
            </div>
            
             <div class="col-md-1">
                <ng-content select="[searchTemplate]"></ng-content>
            </div>
        </div>
    </div>

    <div class="container-fluid g-0 pt-3 pb-3" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <div class="row gy-2">
            <div class="col">
                <div class="row">
                    <div class="col-md-3">
                        <sg-daterangepicker #notificationDate component
                            (dateChanged)="onDatesChanged($event,'notificationFromDate','notificationToDate')"
                            [endDate]="notificationEndDate"
                            startPlaceholder="{{'events.list.filters.notificationDate'|translate}}"
                            endPlaceholder="{{'events.list.filters.notificationDate'|translate}}"
                            [startDate]="notificationBeginDate">
                        </sg-daterangepicker>
                    </div>
                    <div class="col-md-3">
                        <sg-daterangepicker #reqFundDate component
                            (dateChanged)="onDatesChanged($event,'reqFundFromDate','reqFundToDate')" [endDate]="reqFundEndDate"
                            startPlaceholder="{{'events.list.filters.reqFundDate'|translate}}"
                            endPlaceholder="{{'events.list.filters.reqFundDate'|translate}}" [startDate]="reqFundBeginDate">
                        </sg-daterangepicker>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
    
        <div class="row gy-2 mt-1">
            <div class="col">
                <div class="row">
                    <div class="col-md-3">
                        <sg-daterangepicker #preAdviceDate component
                            (dateChanged)="onDatesChanged($event,'preAdviceFromDate','preAdviceToDate')"
                            [endDate]="preAdviceEndDate" startPlaceholder="{{'events.list.filters.preAdviceDate'|translate}}"
                            endPlaceholder="{{'events.list.filters.preAdviceDate'|translate}}" [startDate]="preAdviceBeginDate">
                        </sg-daterangepicker>
                    </div>
            
                    <div class="col-md-3">
                        <sg-daterangepicker #valueDate component
                            (dateChanged)="onDatesChanged($event,'valueFromDate','valueToDate')" [endDate]="valueEndDate"
                            startPlaceholder="{{'events.list.filters.valueDate'|translate}}"
                            endPlaceholder="{{'events.list.filters.valueDate'|translate}}" [startDate]="valueBeginDate">
                        </sg-daterangepicker>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div>

    <div class="container-fluid g-0 mt-2 mb-4">
        <div class="row">
            <div class="col-md-10">
                <app-event-list-active-filters (filtersChanged)="onFiltersChanged($event)"></app-event-list-active-filters>
            </div>
            <div class="col-md-2 d-flex justify-content-end align-items-end">
                <button class="btn btn-lg btn-flat-secondary btn-icon-start" [disabled]="disableExportCsv" (click)="exportCsv()" *ngIf="canExport" translate>
                    <em class="icon">save_alt</em> common-buttons.CSV-export
                </button>
            </div>
        </div>
    </div>
</div>
