import { UserAuthorities } from '@shared/services/permission.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Forms } from '@shared/utils';

const AllActions = ['Read', 'Edit', 'Create', 'ReadMultiple'] as const;
const ressourcesWithoutMultiple = ['Events', 'Valuation', 'Payment'];

type Action = typeof AllActions[number];

const toTitleCase = (value: string | null | undefined): string | null => {
  if (!value) {
    return null;
  }

  return `${value[0].toLocaleUpperCase()}${value.substring(1, value.length)}`;
};

const toAction = (value: Forms.FormMode | null, ressourceName: string | null): Action => {
  if (ressourceName && ressourcesWithoutMultiple.includes(ressourceName)) {
    switch (value) {
      case 'consult':
        return 'Read';
      case 'add':
        return 'Create';
      case 'edit':
        return 'Edit';
    }
  } else {
    switch (value) {
      case 'consult':
        return 'Read';
      case 'add':
        return 'Create';
      case 'edit':
        return 'Edit';
      default:
        return 'ReadMultiple';
    }
  }
  return 'Read';
};
const isKeyOf = <T>(key: any, data: T): key is keyof T => {
  return Object.keys(data).includes(key);
};

const hasAuthority = (key: string, authorities: UserAuthorities): boolean => {
  if (isKeyOf(key, authorities)) {
    const result = authorities[key];
    if (typeof result === 'boolean') {
      return result;
    }
  }
  return false;
};

export const isAuthorized$ = (urlPath: string | undefined, paramPageType: string | undefined, getAuthority$: () => Observable<UserAuthorities>): Observable<boolean> => {
  const ressourceUrl = toTitleCase(urlPath);

  const ressourceName = Forms.isRessource(ressourceUrl) ? ressourceUrl : null;
  const pageType = Forms.isFormMode(paramPageType) ? paramPageType : null;

  if ((paramPageType && !pageType) || !ressourceName) {
    return of(false);
  }

  const verb = toAction(pageType || 'consult', ressourceName);
  const authorityKey = `userCan${verb}${ressourceName}`;

  return getAuthority$().pipe(map(userAuthority => hasAuthority(authorityKey, userAuthority)));
};
