/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component } from '@angular/core';
import { EventCriteriaSearchModel } from '@events/models';
import { EventFilterSelectorService } from '@events/services/event-filter-selector.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FiltersSaveBaseComponent } from '@shared/modules/save-filter/filters-save/filters-save-base.component';

@Component({
  selector: 'app-event-filters-save',
  templateUrl: './event-filters-save.component.html'
})
export class EventFiltersSaveComponent extends FiltersSaveBaseComponent<EventCriteriaSearchModel, EventFilterSelectorService> {
  constructor(public activeModal: NgbActiveModal, filterSelectorService: EventFilterSelectorService) {
    super(activeModal, filterSelectorService);
  }
}
