/**
 * issuer-ipa-events-extended API
 * Manage eventss data
 *
 * The version of the OpenAPI document: 1.1.15
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PartyDto } from './partyDto';


export interface InstrumentRoleDataDto { 
    issuingAgent?: PartyDto;
    principalPayingAgent?: PartyDto;
    commonDepositary?: PartyDto;
    registrar?: PartyDto;
    commonServiceProvider?: PartyDto;
    commonSafeKeeper?: PartyDto;
    fiscalAgent?: PartyDto;
    localPayingAgent?: PartyDto;
    listingAgent?: PartyDto;
    calculationAgent?: PartyDto;
    deskCalculationAgent?: PartyDto;
    transferAgent?: PartyDto;
    cashManager?: PartyDto;
    escrowManager?: PartyDto;
    potentialEurosystemEligibility?: boolean;
}

