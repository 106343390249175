import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { LeiMultipleSelectComponent } from './lei-multiple-select.component';

@NgModule({
  declarations: [LeiMultipleSelectComponent],
  exports: [LeiMultipleSelectComponent],
  imports: [FormsModule, ReactiveFormsModule, NgSelectModule, CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LeiMultipleSelectModule {}
