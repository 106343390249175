import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { EventsService } from '@shared/services/events.service';
import { routeToEvent } from '@events/routes';
import { Router } from '@angular/router';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { EventModalsService, EventDetailFormService } from '@events/services';
import { EVENT_CONSTANTS } from '@events/configs/event-constants';

@Component({
  selector: 'app-events-clear-stream-action',
  templateUrl: './events-clear-stream-action.component.html'
})
export class EventsClearStreamActionComponent {
  enableReleasePayment = false;

  constructor(
    private readonly eventService: EventsService,
    private readonly toastManagerService: ToastManagerService,
    private readonly router: Router,
    private readonly modalsService: EventModalsService,
    private readonly eventDetailFormService: EventDetailFormService
  ) {}
  params: any | undefined;
  eventId: string | null | undefined;
  eventStatus: string | undefined;
  disablePartialReleasePayment = false;
  releasePaymentStatus = ['PAYMENT TO BE INPUT', 'PAYMENT BLOCKED', 'PAYMENT IN REPAIR', 'PREADVISE SENT', 'PAYMENT FAILED', 'PAYMENT PARTIALLY PAID'];

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.eventId = this.eventDetailFormService.rawValue('eventId');
    this.eventStatus = this.eventDetailFormService.rawValue('eventStatus')?.valueDescription;
    if (this.eventStatus && this.releasePaymentStatus.includes(this.eventStatus)) {
      this.enableReleasePayment = true;
    }
    if (this.params.data.isClsPaid || this.params.data.isEocPaid) {
      this.disablePartialReleasePayment = true;
    }
  }

  partialReleasePayment() {
    const selectedData = [this.params?.node.data];
    const eventPositionInput = {
      isInternationalISIN: true,
      paymentDate: selectedData[0].paymentDate,
      fundArrivalDate: selectedData[0].fundArrivalDate
    };
    const modal = this.modalsService.openClearingSystemReleasePaymentModal(selectedData, eventPositionInput);
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        return;
      }
      if (selectedData[0].systemType === EVENT_CONSTANTS.clearingSystem.clearStream) {
        this.confirmPartialReleasePayment(EVENT_CONSTANTS.clearingSystem.clearStream);
      } else if (selectedData[0].systemType === EVENT_CONSTANTS.clearingSystem.euroClear) {
        this.confirmPartialReleasePayment(EVENT_CONSTANTS.clearingSystem.euroClear);
      }
    });
  }

  private confirmPartialReleasePayment(systemType: string) {
    if (this.eventId !== null) {
      this.eventService.updatePaymentReleaseStatus(Number(this.eventId), systemType).subscribe(result => {
        if (result.statusCode?.toString() === 'ACCEPTED') {
          this.router.navigateByUrl('/events/consult', { skipLocationChange: true }).then(() => {
            this.router.navigate(routeToEvent(this.eventId, 'consult'));
            const message = `The request for ${systemType} payment release is being processed.`;
            this.toastManagerService.toastSuccess('toasts.events.releasePayment.title', message, undefined, result);
          });
        } else {
          const message = `The request for ${systemType} payment release is failed.`;
          this.toastManagerService.toastError('toasts.events.releasePaymentFailed.title', message, undefined, result);
        }
      });
    }
  }
}
