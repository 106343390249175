/**
 * issuer-ipa-events-api API
 * Manage events data
 *
 * The version of the OpenAPI document: 1.3.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IpaTypeDto } from './ipaTypeDto';


export interface ValuationRequestDTO { 
    valuationId?: number;
    callPut?: string;
    isinCode?: string;
    nominalCurrency?: string;
    interestCurrency?: string;
    couponRate?: number;
    amountPerDenomination?: number;
    denomination?: number;
    previousPoolFactor?: number;
    newPoolFactor?: number;
    interestDate?: string;
    valuationDate?: string;
    interestStartDate?: string;
    interestEndDate?: string;
    dayNB?: number;
    nominal?: number;
    rofPaymentDate?: string;
    redemptionCurrency?: string;
    redemptionRate?: number;
    rofDenominationAmount?: number;
    sharesToDeliver?: number;
    sharesPerNoteOut?: number;
    residualCash?: number;
    underlyingISIN?: string;
    underlyingCurrency?: string;
    strikeRate?: number;
    positionOUT?: number;
    positionIN?: number;
    forexRate?: number;
    remarks?: string;
    closingPrice?: number;
    valuationVersion?: string;
    apmPaymentDate?: string;
    apmCurrency?: string;
    eventType?: IpaTypeDto;
    couponType?: IpaTypeDto;
    dayCountFraction?: IpaTypeDto;
    redemptionType?: IpaTypeDto;
}

