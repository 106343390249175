<div class="section-forms" [formGroup]="formGroup">
  <div class="container-fluid g-0 pe-3">
    <div class="row gy-2">
      <div class="col-md-6">
        <input [formControlName]="fields.id" type="hidden">
        <input [formControlName]="fields.instrumentId" type="hidden">
        <app-form-item [focus]="gnReceivedInput" [field]="fields.gnReceived">
          <app-yes-no-buttons [formControlName]="fields.gnReceived" #gnReceivedInput component></app-yes-no-buttons>
        </app-form-item>
      </div>

      <div class="col-md-6">
        <app-form-item [focus]="ftReceived" [field]="fields.ftReceived">
          <app-yes-no-buttons [formControlName]="fields.ftReceived" #ftReceived component></app-yes-no-buttons>
        </app-form-item>
      </div>

      <div class="col-md-6">
        <app-form-item [focus]="comment" [field]="fields.comment">
          <input #comment [formControlName]="fields.comment" component type="text" class="form-control" maxlength="250"
            placeholder="{{ placeholder(fields.comment) | translate }}" />
        </app-form-item>
      </div>
    </div>
  </div>
</div>
