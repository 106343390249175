import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ProgrammeComponent } from './components';
import { PermissionGuard } from '@routes/permission.guard';
import { ProgrammesContainerComponent } from './components/programme-search/programmes-container/programmes-container.component';

const PROGRAMMES_ROUTES: Routes = [
  { path: 'programme', component: ProgrammesContainerComponent, canActivate: [PermissionGuard] },
  { path: 'programme/:pageType', component: ProgrammeComponent, canActivate: [PermissionGuard] },
  { path: 'programme/:pageType/:id', component: ProgrammeComponent, canActivate: [PermissionGuard] }
];

export const programmeRoutes = RouterModule.forRoot(PROGRAMMES_ROUTES, { preloadingStrategy: PreloadAllModules });
