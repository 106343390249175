import { EcashPaymentResponseDto } from '@apis/backend/events';

export interface ECashPaymentsModel {
  id?: number;
  eventId?: number;
  eCashId?: string;
  currency?: string;
  amount?: number;
  paymentEndToEndId?: string;
  identification?: string;
  creationDate?: string;
  paymentType?: string;
  eCashPaymentStatus?: string;
}

export const toECashPaymentsModel = (eCashPaymentsDetails: EcashPaymentResponseDto): ECashPaymentsModel | null => {
  if (!eCashPaymentsDetails) {
    return null;
  }
  return {
    id: eCashPaymentsDetails.id,
    eventId: eCashPaymentsDetails.eventId,
    eCashId: eCashPaymentsDetails.ecashId,
    currency: eCashPaymentsDetails.currency,
    amount: eCashPaymentsDetails.amount,
    paymentEndToEndId: eCashPaymentsDetails.paymentEndToEndId,
    identification: eCashPaymentsDetails.identification,
    creationDate: eCashPaymentsDetails.creationDate,
    paymentType: eCashPaymentsDetails.messageTypeId?.valueDescription,
    eCashPaymentStatus: eCashPaymentsDetails.ecashStatusId?.valueDescription
  };
};
