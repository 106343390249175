import { Injectable } from '@angular/core';
import { UnionFormService } from '@shared/services/forms/union.form.service';
import { ValuationCriteriaSearchModel } from '@valuation/models';
import { ValuationCriteriaSearchFormService } from '.';

export type ValuationSearchArrayType = [ValuationCriteriaSearchModel];
export type ValuationSearchQueryModel = ValuationCriteriaSearchModel;

@Injectable()
export class ValuationSearchService extends UnionFormService<ValuationSearchArrayType> {
  constructor(private readonly valuationCriteriaSearchFormService: ValuationCriteriaSearchFormService) {
    super('valuation-search', valuationCriteriaSearchFormService);
  }
  public getfields(): (keyof ValuationCriteriaSearchModel)[] {
    const array = Object.keys(this.valuationCriteriaSearchFormService.fields);
    return array as (keyof ValuationCriteriaSearchModel)[];
  }
}
