<div *ngIf="instruments.length>0; else noData">
  <ag-grid-angular #agGrid class="  ag-theme-sg-bootstrap-condensed" [gridOptions]="gridOptions!" [sideBar]="sideBar"
    [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [rowData]="instruments" (gridReady)="onGridReady($event)">
  </ag-grid-angular>
  <div class="row mt-2">
    <div class="col-md-12">
      <app-sg-pagination [pageSize]="itemsByPage" [totalItems]="totalItems" (pageChanged)="onPageChanged($event)"
        [currentPage]="currentPage" enableCellTextSelection=true [maxPagesToDisplay]="10"></app-sg-pagination>
    </div>
  </div>
</div>

<ng-template #noData>
  <div class="col-md-12">
    <app-no-data title="{{'ag-grid.title'|translate}}" subTitle="{{'ag-grid.searchSubTitle'|translate}}"></app-no-data>
  </div>
</ng-template>