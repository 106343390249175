import { PermanentMonitoringDataDto } from '@apis/backend/instruments';

export interface PermanentMonitoringItemModel {
  readonly id?: number;
  readonly dateOfPm?: string;
  readonly pmOk?: string;
  readonly typeOfDiscordance?: string;
  readonly comment?: string;
}

export interface PermanentMonitoringModel {
  readonly permanentMonitoringItems: PermanentMonitoringItemModel[];
}

export const toPermanentMonitoringItemsModel = (model: PermanentMonitoringDataDto | null | undefined): PermanentMonitoringItemModel => ({
  id: model?.id,
  dateOfPm: model?.pmDateAsString,
  pmOk: model?.pmStatus ? 'Yes' : 'No',
  typeOfDiscordance: model?.pmTypeDiscordance,
  comment: model?.pmComment
});

export const toPermanentMonitoringDataDto = (model: PermanentMonitoringItemModel | null | undefined): PermanentMonitoringDataDto => ({
  id: model?.id,
  pmDateAsString: model?.dateOfPm,
  pmStatus: model?.pmOk === 'Yes',
  pmTypeDiscordance: model?.typeOfDiscordance,
  pmComment: model?.comment
});
