import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FormService } from '@shared/services/forms/form.service';
import { CustomValidators } from '@shared/validators/custom-validator';

import { IssuanceModel, InstrumentForms } from '@instruments/models';

@Injectable()
export class IssuanceFormService extends FormService<IssuanceModel, InstrumentForms> {
  constructor(fb: UntypedFormBuilder) {
    super('issuance', fb, {
      syndicated: fb.control(false, Validators.required),
      tradingMethodIdentifier: fb.control(null, Validators.required),
      denomination: fb.control(null, [Validators.required, CustomValidators.ValidateNumber(29, 7)]),
      numberOfShares: fb.control(null, [Validators.required, CustomValidators.ValidateNumber(29, 7)]),
      nominalCurrency: fb.control(null, [Validators.required]),
      settlementCurrency: fb.control(null, [Validators.required]),
      maximumNominal: fb.control(null, [CustomValidators.ValidateNumber(29, 7)]),
      nominal: fb.control(null, [Validators.required, CustomValidators.ValidateNumber(29, 7)]),
      minimumTrading: fb.control(null, CustomValidators.ValidateNumber(29, 7)),
      issuePrice: fb.control(null, [Validators.required, CustomValidators.ValidateNumber(29, 7)]),
      outstandingNominal: fb.control(null, CustomValidators.ValidateNumber(29, 7))
    });
  }
}
