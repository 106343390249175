import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

type NullableNumberOrString = number | string | null | undefined;

@Pipe({
  name: 'amountFormat',
  pure: true
})
export class AmountFormatPipe implements PipeTransform {
  transform(value: NullableNumberOrString): string {
    if (!value) {
      return '';
    }
    const parsedValue = +(value.toString() || '').split(',').join('');
    return formatNumber(parsedValue, 'en', '1.4-4');
  }
}
