import { InstrumentIssuanceDataDto, InstrumentIssuanceDataUpdateDto } from '@apis/backend/instruments';
import { IpaTypeModel, toIpaTypeDto, toIpaTypeModel } from '@shared/models';
import { TrancheModel } from './tranche.model';

export interface IssuanceModel {
  readonly syndicated: boolean;
  readonly tradingMethodIdentifier: IpaTypeModel | null;
  readonly denomination: string | null;
  readonly numberOfShares: string | null;
  readonly nominalCurrency: string | null;
  readonly settlementCurrency: string | null;
  readonly maximumNominal: string | null;
  readonly nominal: string | null;
  readonly minimumTrading: string | null;
  readonly issuePrice: string | null;
  readonly outstandingNominal: string | null;
}

export const toIssuanceDto = (model: IssuanceModel | null | undefined): InstrumentIssuanceDataUpdateDto | undefined => {
  if (!model) {
    return undefined;
  }

  return {
    syndicated: model.syndicated,
    tradingMethodIdentifier: toIpaTypeDto(model.tradingMethodIdentifier),
    denomination: model.denomination || undefined,
    numberOfShares: model.numberOfShares || undefined,
    nominalCurrency: model.nominalCurrency || undefined,
    settlementCurrency: model.settlementCurrency || undefined,
    maximumNominal: model.maximumNominal || undefined,
    nominal: model.nominal || undefined,
    minimumTrading: model.minimumTrading || undefined,
    issuePrice: model.issuePrice || undefined,
    outstandingNominal: model.outstandingNominal || undefined
  };
};

export const toIssuanceModel = (dto: InstrumentIssuanceDataDto | null | undefined, tranche: TrancheModel | null | undefined): IssuanceModel => {
  return {
    syndicated: dto?.syndicated || false,
    tradingMethodIdentifier: toIpaTypeModel(dto?.tradingMethodIdentifier),
    denomination: dto?.denomination || null,
    numberOfShares: tranche?.numberOfShares || null,
    nominalCurrency: dto?.nominalCurrency || null,
    settlementCurrency: dto?.settlementCurrency || null,
    maximumNominal: dto?.maximumNominal || null,
    nominal: tranche?.nominal || null,
    minimumTrading: tranche?.minimumTrading || null,
    issuePrice: tranche?.issuePrice || null,
    outstandingNominal: tranche?.outstandingNominal || dto?.outstandingNominal || null
  };
};
