import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { PermissionsResourceService } from '@apis/backend/instruments';
import { ToastManagerService } from '../modules/toasts/service/toastManager.service';

export interface UserAuthorities {
  name?: string;
  authorities?: string[];
  userCanReadProgramme?: boolean;
  userCanReadMultipleProgramme?: boolean;
  userCanReadExtcashaccount?: boolean;
  userCanEditProgramme?: boolean;
  userCanCreateProgramme?: boolean;
  userCanReadInstrument?: boolean;
  userCanReadMultipleInstrument?: boolean;
  userCanEditInstrument?: boolean;
  userCanCreateInstrument?: boolean;
  userCanReadEvents?: boolean;
  userCanEditEvents?: boolean;
  userCanCreateEvents?: boolean;
  userCanDeleteEvents?: boolean;
  userCanReadPosition?: boolean;
  userCanReadMultiplePosition?: boolean;
  userCanReadValuation?: boolean;
  userCanEditValuation?: boolean;
  userCanReadOperation?: boolean;
  userCanReadMultipleOperation?: boolean;
  userCanUpdateOperation?: boolean;
  userCanUpdateStatusOperation?: boolean;
  userCanCreateOperation?: boolean;
  userCanReadCommonDep?: boolean;
  userCanEditCommonDep?: boolean;
}

const PERMISSION_PREFIX = 'SGM_ISS_PAY_api.issuer-ipa.';
const EVENT_PERMISSION_PREFIX = 'SGM_ISS_PAY_api.issuer-ipa-events.';
// for Sonar to be happy, this idiot
const PROGRAMME_STRING = 'programme';
const POSITION_STRING = 'position';
const OPERATION_STRING = 'operation';
const INSTRUMENT_STRING = 'instrument';
const EXTCASHACCOUNT_STRING = 'extcashaccount';
const EVENT_STRING = 'event';
const VALUATION_STRING = 'valuation';
const SCHEDULE_EVENT_STRING = 'schedule';
const COMMON_DEP_STRING = 'globalnote';
const UPDATE_STRING = '.update';
const WRITE_STRING = '.write';
const CREATE_STRING = '.create';
const READ_STRING = '.read';
const DELETE_STRING = '.delete';
const MULTIPLE_STRING = '.multiple';
const STATUS_STRING = '.status';

export const PROGRAMME_UPDATE_PERMISSION = PERMISSION_PREFIX + PROGRAMME_STRING + UPDATE_STRING;
export const PROGRAMME_READ_PERMISSION = PERMISSION_PREFIX + PROGRAMME_STRING + READ_STRING;
export const PROGRAMME_MULTIPLE_READ_PERMISSION = PERMISSION_PREFIX + PROGRAMME_STRING + MULTIPLE_STRING + READ_STRING;
export const PROGRAMME_CREATE_PERMISSION = PERMISSION_PREFIX + PROGRAMME_STRING + CREATE_STRING;
export const INSTRUMENT_UPDATE_PERMISSION = PERMISSION_PREFIX + INSTRUMENT_STRING + UPDATE_STRING;
export const INSTRUMENT_READ_PERMISSION = PERMISSION_PREFIX + INSTRUMENT_STRING + READ_STRING;
export const INSTRUMENT_MULTIPLE_READ_PERMISSION = PERMISSION_PREFIX + INSTRUMENT_STRING + MULTIPLE_STRING + READ_STRING;
export const INSTRUMENT_CREATE_PERMISSION = PERMISSION_PREFIX + INSTRUMENT_STRING + CREATE_STRING;
export const EVENT_UPDATE_PERMISSION = EVENT_PERMISSION_PREFIX + EVENT_STRING + UPDATE_STRING;
export const EVENT_READ_PERMISSION = EVENT_PERMISSION_PREFIX + EVENT_STRING + READ_STRING;
export const EVENT_CREATE_PERMISSION = EVENT_PERMISSION_PREFIX + EVENT_STRING + CREATE_STRING;
export const EVENT_DELETE_PERMISSION = EVENT_PERMISSION_PREFIX + SCHEDULE_EVENT_STRING + DELETE_STRING;
export const POSITION_READ_PERMISSION = PERMISSION_PREFIX + POSITION_STRING + READ_STRING;
export const POSITION_MULTIPLE_READ_PERMISSION = PERMISSION_PREFIX + POSITION_STRING + MULTIPLE_STRING + READ_STRING;
export const OPERATION_READ_PERMISSION = PERMISSION_PREFIX + OPERATION_STRING + READ_STRING;
export const OPERATION_CREATE_PERMISSION = PERMISSION_PREFIX + OPERATION_STRING + CREATE_STRING;
export const OPERATION_MULTIPLE_READ_PERMISSION = PERMISSION_PREFIX + OPERATION_STRING + MULTIPLE_STRING + READ_STRING;
export const OPERATION_UPDATE_PERMISSION = PERMISSION_PREFIX + OPERATION_STRING + UPDATE_STRING;
export const OPERATION_UPDATE_STATUS_PERMISSION = PERMISSION_PREFIX + OPERATION_STRING + UPDATE_STRING + STATUS_STRING;
export const VALUATION_READ_PERMISSION = EVENT_PERMISSION_PREFIX + VALUATION_STRING + READ_STRING;
export const COMMON_DEP_READ_PERMISSION = PERMISSION_PREFIX + COMMON_DEP_STRING + READ_STRING;
export const COMMON_DEP_UPDATE_PERMISSION = PERMISSION_PREFIX + COMMON_DEP_STRING + UPDATE_STRING;
export const VALUATION_UPDATE_PERSMISSION = EVENT_PERMISSION_PREFIX + VALUATION_STRING + WRITE_STRING;
export const EXTCASHACCOUNT_READ_PERMISSION = PERMISSION_PREFIX + EXTCASHACCOUNT_STRING + READ_STRING;

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  public canCreateProgramme$ = new BehaviorSubject<boolean>(false);
  public canEditProgramme$ = new BehaviorSubject<boolean>(false);
  public canReadProgramme$ = new BehaviorSubject<boolean>(false);
  public canReadMultipleProgramme$ = new BehaviorSubject<boolean>(false);
  public canReadPosition$ = new BehaviorSubject<boolean>(false);
  public canReadMultiplePosition$ = new BehaviorSubject<boolean>(false);
  public canReadOperation$ = new BehaviorSubject<boolean>(false);
  public canReadMultipleOperation$ = new BehaviorSubject<boolean>(false);
  public canUpdateOperation$ = new BehaviorSubject<boolean>(false);
  public canUpdateStatusOperation$ = new BehaviorSubject<boolean>(false);
  public canCreateOperation$ = new BehaviorSubject<boolean>(false);
  public canCreateInstrument$ = new BehaviorSubject<boolean>(false);
  public canEditInstrument$ = new BehaviorSubject<boolean>(false);
  public canReadInstrument$ = new BehaviorSubject<boolean>(false);
  public canCreateEvents$ = new BehaviorSubject<boolean>(false);
  public canEditEvents$ = new BehaviorSubject<boolean>(false);
  public canReadEvents$ = new BehaviorSubject<boolean>(false);
  public canDeleteEvents$ = new BehaviorSubject<boolean>(false);
  public canEditValuation$ = new BehaviorSubject<boolean>(false);
  public canReadValuation$ = new BehaviorSubject<boolean>(false);
  public canReadCommonDep$ = new BehaviorSubject<boolean>(false);
  public canEditCommonDep$ = new BehaviorSubject<boolean>(false);
  public userCanReadExtcashaccount$ = new BehaviorSubject<boolean>(false);
  public user$ = new BehaviorSubject<string | null>(null);

  constructor(private readonly permissionsResourceService: PermissionsResourceService, private readonly toastManagerService: ToastManagerService) {}

  getCurrentConnectedUserPermissions(): Observable<UserAuthorities> {
    return this.permissionsResourceService.getCurrentConnectedUserPermissions().pipe(
      map((result: UserAuthorities) => {
        result.userCanReadProgramme = result.authorities?.indexOf(PROGRAMME_READ_PERMISSION) !== -1;
        result.userCanReadMultipleProgramme = result.authorities?.indexOf(PROGRAMME_MULTIPLE_READ_PERMISSION) !== -1;
        result.userCanEditProgramme = result.authorities?.indexOf(PROGRAMME_UPDATE_PERMISSION) !== -1;
        result.userCanCreateProgramme = result.authorities?.indexOf(PROGRAMME_CREATE_PERMISSION) !== -1;
        result.userCanReadInstrument = result.authorities?.indexOf(INSTRUMENT_READ_PERMISSION) !== -1;
        result.userCanReadMultipleInstrument = result.authorities?.indexOf(INSTRUMENT_MULTIPLE_READ_PERMISSION) !== -1;
        result.userCanEditInstrument = result.authorities?.indexOf(INSTRUMENT_UPDATE_PERMISSION) !== -1;
        result.userCanCreateInstrument = result.authorities?.indexOf(INSTRUMENT_CREATE_PERMISSION) !== -1;
        result.userCanReadEvents = result.authorities?.indexOf(EVENT_READ_PERMISSION) !== -1;
        result.userCanEditEvents = result.authorities?.indexOf(EVENT_UPDATE_PERMISSION) !== -1;
        result.userCanCreateEvents = result.authorities?.indexOf(EVENT_CREATE_PERMISSION) !== -1;
        result.userCanDeleteEvents = result.authorities?.indexOf(EVENT_DELETE_PERMISSION) !== -1;
        result.userCanReadPosition = result.authorities?.indexOf(POSITION_READ_PERMISSION) !== -1;
        result.userCanReadMultiplePosition = result.authorities?.indexOf(POSITION_MULTIPLE_READ_PERMISSION) !== -1;
        result.userCanReadOperation = result.authorities?.indexOf(OPERATION_READ_PERMISSION) !== -1;
        result.userCanReadMultipleOperation = result.authorities?.indexOf(OPERATION_MULTIPLE_READ_PERMISSION) !== -1;
        result.userCanUpdateOperation = result.authorities?.indexOf(OPERATION_UPDATE_PERMISSION) !== -1;
        result.userCanUpdateStatusOperation = result.authorities?.indexOf(OPERATION_UPDATE_STATUS_PERMISSION) !== -1;
        result.userCanCreateOperation = result.authorities?.indexOf(OPERATION_CREATE_PERMISSION) !== -1;
        result.userCanDeleteEvents = result.authorities?.indexOf(EVENT_DELETE_PERMISSION) !== -1;
        result.userCanReadValuation = result.authorities?.indexOf(VALUATION_READ_PERMISSION) !== -1;
        result.userCanEditValuation = result.authorities?.indexOf(VALUATION_UPDATE_PERSMISSION) !== -1;
        result.userCanReadExtcashaccount = result.authorities?.indexOf(EXTCASHACCOUNT_READ_PERMISSION) !== -1;
        result.userCanReadCommonDep = result.authorities?.indexOf(COMMON_DEP_READ_PERMISSION) !== -1;
        result.userCanEditCommonDep = result.authorities?.indexOf(COMMON_DEP_UPDATE_PERMISSION) !== -1;
        this.canCreateProgramme$.next(result.userCanCreateProgramme);
        this.canEditProgramme$.next(result.userCanEditProgramme);
        this.canReadProgramme$.next(result.userCanReadProgramme);
        this.canReadMultipleProgramme$.next(result.userCanReadMultipleProgramme);
        this.canCreateInstrument$.next(result.userCanCreateInstrument);
        this.canEditInstrument$.next(result.userCanEditInstrument);
        this.canReadInstrument$.next(result.userCanReadInstrument);
        this.canReadInstrument$.next(result.userCanReadMultipleInstrument);
        this.canCreateEvents$.next(result.userCanCreateEvents);
        this.canReadEvents$.next(result.userCanReadEvents);
        this.canEditInstrument$.next(result.userCanEditEvents);
        this.canDeleteEvents$.next(result.userCanDeleteEvents);
        this.canReadPosition$.next(result.userCanReadPosition);
        this.canReadMultiplePosition$.next(result.userCanReadMultiplePosition);
        this.canReadOperation$.next(result.userCanReadOperation);
        this.canReadMultipleOperation$.next(result.userCanReadMultipleOperation);
        this.canUpdateOperation$.next(result.userCanUpdateOperation);
        this.canUpdateStatusOperation$.next(result.userCanUpdateStatusOperation);
        this.canCreateOperation$.next(result.userCanCreateOperation);
        this.canReadValuation$.next(result.userCanReadValuation);
        this.canEditValuation$.next(result.userCanEditValuation);
        this.userCanReadExtcashaccount$.next(result.userCanReadExtcashaccount);
        this.canReadCommonDep$.next(result.userCanReadCommonDep);
        this.canEditCommonDep$.next(result.userCanEditCommonDep);
        this.user$.next(result.name ?? '');
        return result;
      })
    );
  }
}
