/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Criteria for search Instruments
 */
export interface InstrumentCriteriaSearchDto { 
    /**
     * IPA number of instrument
     */
    ipaNumber?: string;
    /**
     * List Issuer name of instrument
     */
    issuerNames?: Array<string>;
    /**
     * Name of instrument
     */
    instrumentName?: string;
    /**
     * ISIN code of instrument
     */
    isin?: string;
    /**
     * ICSD programme number of instrument
     */
    icsd?: string;
    /**
     * Series number of instrument
     */
    serialNumber?: string;
    /**
     * Instrument type of instrument
     */
    instrumentType?: string;
    /**
     * Status of instrument
     */
    status?: string;
    /**
     * Final term of instrument
     */
    finalTermReceived?: boolean;
    /**
     * Issue date of start for search a tranche
     */
    beginIssueDateAsString?: string;
    /**
     * Issue date of end for search a tranche
     */
    endIssueDateAsString?: string;
    /**
     * PaymentDto Type of settlement
     */
    paymentType?: string;
    /**
     * Status for settlements
     */
    settlementStatuses?: Array<string>;
    /**
     * Currency for settlement
     */
    settlementCurrency?: string;
    withoutSettlement?: boolean;
    isSTA?: boolean;
    isGnReceived?: boolean;
    isFtReceived?: boolean;
    pmMonitoring?: string;
    /**
     * enum for clearer
     */
    clearer?: InstrumentCriteriaSearchDto.ClearerEnum;
}
export namespace InstrumentCriteriaSearchDto {
    export type ClearerEnum = 'ONE' | 'TWO' | 'NONE';
    export const ClearerEnum = {
        ONE: 'ONE' as ClearerEnum,
        TWO: 'TWO' as ClearerEnum,
        NONE: 'NONE' as ClearerEnum
    };
}


