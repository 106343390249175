import { Component, EventEmitter, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProgrammeCriteriaSearchModel } from '@programmes/models/progamme-paging-criteria.model';
import { ProgrammeCriteriaSearchFormService } from '@programmes/services/programme-criteria-search.form.service';
import { ActiveFilterItem } from '@shared/models';
import { ActiveFiltersBaseComponent } from '@shared/search-base';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-programme-list-active-filters',
  templateUrl: './programme-list-active-filters.component.html'
})
export class ProgrammeListActiveFiltersComponent extends ActiveFiltersBaseComponent<ProgrammeCriteriaSearchModel> {
  @Output() filtersChanged = new EventEmitter<ActiveFilterItem<ProgrammeCriteriaSearchModel>[]>();
  public filterItems: ActiveFilterItem<ProgrammeCriteriaSearchModel>[] = [];
  public shutdown$ = new Subject<void>();

  constructor(formService: ProgrammeCriteriaSearchFormService, modalService: NgbModal, i18nUtils: I18nUtilsService) {
    super(formService, modalService, i18nUtils, 'programmes-filters', 'programmes.list.filters');
  }
  public onFilterChanged(items: ActiveFilterItem<ProgrammeCriteriaSearchModel>[]): void {
    this.filtersChanged.emit(items);
  }
  public saveFilters(): NgbModalRef | null {
    return null;
  }
}
