import { Component, OnInit, Input } from '@angular/core';
import { SwiftMessageDto, SwiftMessageResponseDto } from '@apis/backend/events';
import { SwiftsColumnDefService } from '../_aggrid/swifts-column-def-service';
import { ColDef, GridOptions, GridApi, SelectionChangedEvent } from 'ag-grid-community';
import { toSwiftModal, SwiftsGridModel } from '@events/models/swiftsGrid.model';
import { Clipboard, PendingCopy } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-swifts-container',
  templateUrl: './swifts-container.component.html',
  styleUrls: ['./swifts-container.component.scss'],
  providers: [SwiftsColumnDefService]
})
export class SwiftsContainerComponent implements OnInit {
  @Input() public swiftData?: SwiftMessageResponseDto;
  public swiftMessageValues: SwiftsGridModel[] | undefined;
  public selectedData: any;
  public swiftMessage?: string;
  public errorSwiftMessage?: string;
  public columnDefs: ColDef[] = [];
  public gridOptions!: GridOptions;
  public gridApi!: GridApi;

  constructor(private readonly swiftsColumnDefService: SwiftsColumnDefService, private readonly clipboard: Clipboard) {}

  ngOnInit(): void {
    this.fetchSwiftMessages();
    this.columnDefs = this.swiftsColumnDefService.get();
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    this.selectedData = event.api.getSelectedRows();
    this.swiftMessage = this.selectedData[0].swiftMessage.replace(/\r\n/g, '<br>');
    this.errorSwiftMessage = this.selectedData[0].swiftMessageError;
  }

  public fetchSwiftMessages() {
    const rowData: SwiftsGridModel[] = [];
    this.swiftData?.swiftResponses?.forEach((swift: SwiftMessageDto) => {
      const swiftMessage = toSwiftModal(swift);
      rowData.push(swiftMessage);
      this.swiftMessageValues = rowData;
    });
  }

  copySwiftMessage() {
    const swiftMessage = this.clipboard.beginCopy(this.selectedData[0].swiftMessage);
    this.copyMessage(swiftMessage);
  }

  copySwiftErrorMessage() {
    const swiftErrorMessage = this.clipboard.beginCopy(this.selectedData[0].swiftMessageError);
    this.copyMessage(swiftErrorMessage);
  }

  copyMessage(message: PendingCopy) {
    const attempt = () => {
      const result = message.copy();
      if (!result) {
        setTimeout(attempt);
      } else {
        message.destroy();
      }
    };
    attempt();
  }
}
