import { Component, Input, OnInit } from '@angular/core';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { OperationModel } from 'src/app/positions-keeping/models/operation.model';
import { OperationColumnDefService } from 'src/app/positions-keeping/services/operation-column-def-service';
import { OperationPagingCriteriaSearchFormService } from 'src/app/positions-keeping/services/operation-paging-criteria-search.form.service';
@Component({
  selector: 'app-operation-list',
  templateUrl: './operation-list.component.html',
  providers: [OperationColumnDefService]
})
export class OperationListComponent implements OnInit {
  @Input() public operations: OperationModel[] = [];
  public columnDefs: ColDef[] = [];
  public defaultColDef: ColDef | undefined;
  public gridOptions!: GridOptions;

  public get currentPage(): number {
    return this.pagingRequestWithCriteriaFormService.rawValue().pageNumber ?? 1;
  }

  constructor(private readonly operationColumnDefService: OperationColumnDefService, private readonly pagingRequestWithCriteriaFormService: OperationPagingCriteriaSearchFormService) {}

  ngOnInit(): void {
    this.gridOptions = {
      onGridSizeChanged: () => {
        this.gridOptions.api?.sizeColumnsToFit();
      },
      pagination: false,
      suppressPaginationPanel: false,
      paginationPageSize: 20,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      suppressContextMenu: true,
      stopEditingWhenCellsLoseFocus: true
    };
    this.defaultColDef = {
      sortable: false,
      filter: false,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true }
    };
  }

  onGridReady(params: { api: GridApi }): void {
    this.columnDefs = this.operationColumnDefService.get();
    const gridApi = params.api;
    gridApi.sizeColumnsToFit();
    gridApi.setDomLayout('autoHeight');
    gridApi.refreshHeader();
  }

  onPageChanged(page: number): void {
    this.pagingRequestWithCriteriaFormService.patch('pageNumber', page);
  }

  get totalItems(): number {
    return this.pagingRequestWithCriteriaFormService.value()?.totalItems || 0;
  }
  get pageNumber(): number {
    return this.pagingRequestWithCriteriaFormService.value()?.pageNumber || 0;
  }
  get itemsByPage(): number {
    return this.pagingRequestWithCriteriaFormService.value()?.itemsByPage || 0;
  }
}
