import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { getExtensionFile } from '@utils/utility-functions';

@Directive({
  selector: '[appDragNDrop]'
})
export class DragNDropDirective {
  @Input()
  isMultiple: boolean | undefined;
  @Input() allowedExtensions: string[] = [];
  @HostBinding('class.fileover')
  fileOver: boolean | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() fileDropped = new EventEmitter<File[]>();
  re = /(?:\.([^.]+))?$/;
  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt: Event): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt: Event): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    if (!evt?.dataTransfer) {
      return;
    }
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      let filesOrFile = this.isMultiple ? Array.from(files) : [files[0]];
      if (this.allowedExtensions.length > 0) {
        filesOrFile = filesOrFile.filter(q => {
          const extension = getExtensionFile(q.name);
          return extension && this.allowedExtensions.includes(extension);
        });
      }
      this.fileDropped.emit(filesOrFile);
    }
  }
}
