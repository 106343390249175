import { Injectable } from '@angular/core';

import { DisplayService } from '@shared/services/display/display.service';
import { InstrumentCriteriaSearchFormService } from '..';
import { BooleanRules as B } from '@instruments/rules';
import { InstrumentCriteriaSearchModel } from '@instruments/models';

@Injectable()
export class SearchInstrumentDisplayService extends DisplayService<InstrumentCriteriaSearchModel> {
  constructor(ics: InstrumentCriteriaSearchFormService) {
    super(ics);

    this.with(ics, 'withoutSettlement').rules({
      when: ({ withoutSettlement }) => {
        return B.isFalse(withoutSettlement);
      },
      displayFields: ['settlementCurrency', 'settlementStatus', 'paymentType']
    });
  }
}
