import { Directive, OnDestroy, OnInit } from '@angular/core';
import { FilterItem } from '@shared/models';
import { FilterSelectorBaseService } from '@shared/services/filter-selector-base.service';
import { FormService } from '@shared/services/forms/form.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive()
export class FilterSelectorBaseComponent<C> implements OnDestroy, OnInit {
  public filters: FilterItem<C>[] = [];
  public selected: FilterItem<C> | null | undefined;
  protected readonly shutdown$ = new Subject<void>();
  // eslint-disable-next-line max-len, prettier/prettier
  constructor(public readonly criteriaSearchFormService: FormService<C>, public readonly filterSelectorService: FilterSelectorBaseService<C>, private readonly _key: string, public readonly _keyBase: string) {}

  ngOnInit(): void {
    this.filterSelectorService.loadFilters(this._key);
    this.criteriaSearchFormService
      .valueChanges()
      .pipe(takeUntil(this.shutdown$))
      .subscribe(changes => {
        const value: any = changes;
        const keys = Object.keys(value);
        if (keys.every(k => value[k] === null || value[k] === undefined || value[k] === '')) {
          this.selected = null;
        }
      });
  }

  public onChange(event: FilterItem<C>): void {
    this.criteriaSearchFormService.patch(event.value);
  }
  public select(filter: FilterItem<C>): void {
    this.selected = filter;
    this.criteriaSearchFormService.patch(filter.value);
  }
  ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}
