import { Component, Output, EventEmitter } from '@angular/core';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { EventModel } from '../../models/event.model';
import { EventFormService } from '../../services/event.form.service';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';
import { TYPED_DATA_TYPE } from '@shared/models';
import { Validators } from '@angular/forms';
import { CustomValidators } from '@shared/validators/custom-validator';
import { InstrumentService } from '@shared/services/instrument.service';
import { EVENT_CONSTANTS } from '@events/configs/event-constants';
import { InstrumentModel } from '@instruments/models';

@Component({
  selector: 'app-event-creation',
  templateUrl: './event-creation.component.html',
  styleUrls: ['./event-creation.component.scss'],
  providers: [
    { provide: FormServiceInjectionToken, useExisting: EventFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'events.form' }
  ]
})
export class EventCreationComponent extends BaseFormComponent<EventModel, EventFormService> {
  @Output() disableConfirmButtonStatus = new EventEmitter<boolean>();
  public EVENT_TYPE: TYPED_DATA_TYPE = 'EVENT_TYPE';
  public displayDate = false;
  public displayPredAndPcalErrorMessage = false;
  public displayRedmAndMcalErrorMessage = false;
  public displayBeginEndDateErrorMessage = false;
  public displayIntrErrorMessage = false;
  public displayValueDateErrorMessage = false;
  public displayIssueDateErrorMessage = false;
  public reqFundsDateModificationAlert = false;
  public preAdviseReqOfFundModificationAlert = false;
  public preAdviseNotificationModificationAlert = false;
  public valueDateModificationAlert = false;
  public partialRedemption: boolean | undefined;
  public partialRedemptionType: string | undefined = '';
  public couponType: string | undefined = '';
  public maturityDate: string | undefined;
  public instrument: InstrumentModel | undefined;
  constructor(eventFormService: EventFormService, private readonly instrumentService: InstrumentService) {
    super(eventFormService, 'events.creation');
  }

  ngOnInit(): void {
    this.getInstrumentDetails();
  }

  getInstrumentDetails() {
    const isin = this.formService.rawValue('isin');
    if (isin != null && isin.length === 12) {
      this.instrumentService.getInstrumentByIsin(isin).subscribe(result => {
        if (result) {
          this.instrument = result;
        }
      });
    }
  }

  creationAlert() {
    const eventType = this.formService.rawValue('eventType')?.valueDescription;
    const isin = this.formService.rawValue('isin');
    this.displayIntrErrorMessage = false;
    this.displayPredAndPcalErrorMessage = false;
    this.displayRedmAndMcalErrorMessage = false;
    this.displayIssueDateErrorMessage = false;
    if (isin != null && isin.length === 12) {
      switch (eventType) {
        case EVENT_CONSTANTS.eventTypes.INTR: {
          this.intrCreationAlert();
          break;
        }
        case EVENT_CONSTANTS.eventTypes.PRED:
        case EVENT_CONSTANTS.eventTypes.PCAL: {
          this.predAndPcalCreationAlert(eventType);
          break;
        }
        case EVENT_CONSTANTS.eventTypes.REDM:
        case EVENT_CONSTANTS.eventTypes.MCAL: {
          this.redmAndMcalCreationAlert();
          break;
        }
        default: {
          this.valueDateAlert();
          break;
        }
      }
    }
  }

  valueDateAlert() {
    const valueDate = this.formService.rawValue('valueDate');
    this.maturityDate = this.instrument?.maturityDate ? toFilterDateString(this.instrument.maturityDate) : undefined;
    const issueDate = this.instrument?.issueDate ? toFilterDateString(this.instrument.issueDate) : undefined;
    if (this.maturityDate && valueDate) {
      if (this.maturityDate >= toFilterDateString(valueDate)) {
        this.disableConfirmButtonStatus.emit(false);
        this.displayValueDateErrorMessage = false;
      } else {
        this.disableConfirmButtonStatus.emit(true);
        this.displayValueDateErrorMessage = true;
      }
    }
    if (issueDate && valueDate) {
      if (issueDate <= toFilterDateString(valueDate)) {
        this.disableConfirmButtonStatus.emit(false);
        this.displayIssueDateErrorMessage = false;
      } else {
        this.disableConfirmButtonStatus.emit(true);
        this.displayIssueDateErrorMessage = true;
      }
    }
  }

  intrCreationAlert() {
    this.couponType = this.instrument?.couponType?.valueDescription;
    if (this.couponType === EVENT_CONSTANTS.couponType.zeroCoupon) {
      this.disableConfirmButtonStatus.emit(true);
      this.displayIntrErrorMessage = true;
    } else {
      this.disableConfirmButtonStatus.emit(false);
      this.displayIntrErrorMessage = false;
      this.valueDateAlert();
    }
  }

  predAndPcalCreationAlert(eventType: string) {
    this.partialRedemption = this.instrument?.partialRedemption;
    this.partialRedemptionType = this.instrument?.partialRedemptionType?.valueDescription;
    if (this.partialRedemption) {
      if (
        (eventType === EVENT_CONSTANTS.eventTypes.PRED && (this.partialRedemptionType === eventType || this.partialRedemptionType === EVENT_CONSTANTS.partialRedemptionType.creditEvent)) ||
        (eventType === EVENT_CONSTANTS.eventTypes.PCAL && this.partialRedemptionType === eventType)
      ) {
        this.disableConfirmButtonStatus.emit(false);
        this.displayPredAndPcalErrorMessage = false;
        this.valueDateAlert();
      } else {
        this.disableConfirmButtonStatus.emit(true);
        this.displayPredAndPcalErrorMessage = true;
      }
    } else {
      this.disableConfirmButtonStatus.emit(true);
      this.displayPredAndPcalErrorMessage = true;
    }
  }

  redmAndMcalCreationAlert() {
    const valueDate = this.formService.rawValue('valueDate');
    this.maturityDate = this.instrument?.maturityDate ? toFilterDateString(this.instrument?.maturityDate) : undefined;
    if (this.maturityDate && valueDate) {
      if (this.maturityDate === toFilterDateString(valueDate)) {
        this.disableConfirmButtonStatus.emit(false);
        this.displayRedmAndMcalErrorMessage = false;
      } else {
        this.disableConfirmButtonStatus.emit(true);
        this.displayRedmAndMcalErrorMessage = true;
      }
    }
  }

  displayBeginEndDate() {
    const beginDate = this.formGroup.get('beginDate');
    const endDate = this.formGroup.get('endDate');
    if (this.formService.rawValue('eventType')?.valueDescription === EVENT_CONSTANTS.eventTypes.INTR) {
      this.displayDate = true;
      beginDate?.setValidators([Validators.required, CustomValidators.ValidateDate]);
      endDate?.setValidators([Validators.required, CustomValidators.ValidateDate]);
    } else {
      this.displayDate = false;
      beginDate?.clearValidators();
      endDate?.clearValidators();
    }
  }

  beginEndDateModificationAlert() {
    const beginDate = toFilterDateString(this.formService.rawValue('beginDate') ?? new Date());
    const endDate = toFilterDateString(this.formService.rawValue('endDate') ?? new Date());
    if (beginDate && endDate && endDate < beginDate) {
      this.displayBeginEndDateErrorMessage = true;
    } else {
      this.displayBeginEndDateErrorMessage = false;
    }
  }

  dateModificationAlert() {
    const notificationDate = this.formService.rawValue('notificationDate');
    const reqFundDate = this.formService.rawValue('reqFundDate');
    const preAdviceDate = this.formService.rawValue('preAdvDate');
    const valueDate = this.formService.rawValue('valueDate');
    this.reqFundsDateModificationAlert = false;
    this.preAdviseReqOfFundModificationAlert = false;
    this.preAdviseNotificationModificationAlert = false;
    this.valueDateModificationAlert = false;
    if (reqFundDate) {
      this.reqFundsDateModificationAlert = notificationDate ? notificationDate > reqFundDate : false;
      this.valueDateModificationAlert = valueDate ? valueDate < reqFundDate : false;
      if (preAdviceDate && preAdviceDate < reqFundDate) {
        this.preAdviseReqOfFundModificationAlert = true;
      }
    } else if (notificationDate && preAdviceDate && preAdviceDate < notificationDate) {
      this.preAdviseNotificationModificationAlert = true;
    }

    if (valueDate) {
      if (notificationDate && !this.valueDateModificationAlert) {
        this.valueDateModificationAlert = valueDate < notificationDate;
      }
      if (preAdviceDate && !this.valueDateModificationAlert) {
        this.valueDateModificationAlert = valueDate < preAdviceDate;
      }
    }
  }
}

export const toFilterDateString = (date: Date): string => {
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = date.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
};
