export const POSITION_CONSTANTS = {
  positions: {},
  operations: {
    types: {
      MARKDOWN: 'MDN - Mark Down',
      NETTING: 'NETT - Netting'
    },
    statuses: {
      INITIALIZED: 'INITIALIZED',
      VAIDATED: 'VALIDATED',
      INREPAIR: 'IN REPAIR'
    }
  },
  LEI_SG_LUXEMBOURG: { leiCode: 'TPS0Q8GFSZF45ZZFL873' },
  DEPOSITARY_SG_LUXEMBOURG: { leiCode: '7LTWFZYICNSX8D621K86', name: 'DEUTSCHE BANK AG' },
  mouvements: {
    types: {
      unblocked: 'Unblocked',
      blocked: 'Blocked',
      avai: 'AVAI'
    }
  }
};
