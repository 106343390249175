const ISIN_NOT_FOUND = 'ISIN NOT FOUND';
const AWAIT_NEW_VALUATION = 'AWAIT NEW VALUATION';

export const VALUATION_CONSTANTS = {
  eventTypes: {
    INTR: 'INTR',
    REDM: 'REDM',
    PRED: 'PRED',
    MCAL: 'MCAL',
    DVCA: 'DVCA',
    displayRedemptionFieldsInTypes: ['REDM', 'MCAL'],
    displayPredPcalFieldsInTypes: ['PRED', 'PCAL']
  },
  valuation: {
    valuationStatus: ['RECEIVED', 'RECONCILED', ISIN_NOT_FOUND, 'EVENT NOT FOUND'],
    valuationStatusDropDown: ['RECEIVED', 'NOT RECONCILED', ISIN_NOT_FOUND, 'EVENT NOT FOUND', 'RECONCILED', 'CANCELLED', 'MANUAL', 'REGULARIZED'],
    valuationFilterStatusDropDown: ['RECEIVED', 'NOT RECONCILED', ISIN_NOT_FOUND, 'EVENT NOT FOUND', 'RECONCILED', 'REGULARIZED', AWAIT_NEW_VALUATION],
    awaitNewValuation: AWAIT_NEW_VALUATION
  },
  valuationStatusSearchCondtion: [AWAIT_NEW_VALUATION, 'NOT RECONCILED']
};
