<div *ngIf="tab==='Tranche'">
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item">
      <button class="nav-link {{firstTab}}" id="home-tab" data-bs-toggle="tab" data-bs-target="#instrumentCharacteristics" role="tab" aria-controls="home" aria-selected="true">
        <span [class.required]="hasError('characteristic')" class="menu-label" translate>instruments.tabs.instrumentCharacteristics</span>
      </button>
    </li>
    <li class="nav-item">
      <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#issuanceData" role="tab" aria-controls="profile" aria-selected="false">
        <span [class.required]="hasError('issuance')" class="menu-label"translate>instruments.tabs.issuanceData</span>
      </button>
    </li>
    <li class="nav-item">
      <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#eventData" role="tab" aria-controls="contact" aria-selected="false">
        <span [class.required]="hasError ('eventData')" class="menu-label" translate>instruments.tabs.eventData</span>
      </button>
    </li>
    <li class="nav-item">
      <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#globalNote" role="tab" aria-controls="contactnpm" aria-selected="false">
        <span [class.required]="hasError ('globalNote')" class="menu-label" translate>instruments.tabs.globalNote</span>
      </button>
    </li>
    <li class="nav-item">
      <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#listing" role="tab" aria-controls="contact" aria-selected="false">
        <span [class.required]="hasError ('listing')" class="menu-label" translate>instruments.tabs.listing</span>
      </button>
    </li>
    <li class="nav-item">
      <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#roles" role="tab" aria-controls="contact" aria-selected="false" translate>
        <span [class.required]="hasError ('roleData')" class="menu-label"translate>instruments.tabs.roles</span>
      </button>
    </li>
    <li class="nav-item" *ngIf="showSettlementTab ">
      <button class="nav-link {{settlementTab}}" id="contact-tab" data-bs-toggle="tab" data-bs-target="#settlement" role="tab" aria-controls="contact" aria-selected="false" translate>
        <span class="menu-label" translate>instruments.tabs.settlement</span>
      </button>
    </li>
  </ul>
  <div class="tab-content mt-5" id="myTabContent">
    <div class="tab-pane fade {{firstContent}}" id="instrumentCharacteristics" role="tabpanel" aria-labelledby="profile-tab">
      <app-instrument-characteristics [programme]="programme"></app-instrument-characteristics>
    </div>
    <div class="tab-pane fade" id="issuanceData" role="tabpanel" aria-labelledby="profile-tab">
      <app-instrument-issuance></app-instrument-issuance>
    </div>
    <div class="tab-pane fade" id="eventData" role="tabpanel" aria-labelledby="contact-tab">
      <app-instrument-events-data></app-instrument-events-data>
    </div>
    <div class="tab-pane fade" id="globalNote" role="tabpanel" aria-labelledby="profile-tab">
      <app-instrument-global-note></app-instrument-global-note>
    </div>
    <div class="tab-pane fade" id="listing" role="tabpanel" aria-labelledby="profile-tab">
      <app-instrument-listing></app-instrument-listing>
    </div>
    <div class="tab-pane fade" id="roles" role="tabpanel" aria-labelledby="profile-tab">
      <app-instrument-role-data> </app-instrument-role-data>
    </div>
    <div class="tab-pane fade {{settlementContent}}" id="settlement" role="tabpanel" aria-labelledby="settlement-tab">
      <app-instrument-settlement></app-instrument-settlement>
    </div>
  </div>
</div>

<div *ngIf="tab ==='TrancheHistory'">
  <app-instrument-tranche-history> </app-instrument-tranche-history>
</div>

<div *ngIf="tab ==='commonDepository'">
  <app-common-depository-container> </app-common-depository-container>
</div>
<app-error-list-from-backend></app-error-list-from-backend>
