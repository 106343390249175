import { Injectable } from '@angular/core';
import { UnionFormService } from '@shared/services/forms/union.form.service';
import { ECashPaymentsCriteriaSearchModel } from '../models';
import { ECashPaymentsCriteriaSearchFormService } from '.';

export type EcashPaymentsSearchArrayType = [ECashPaymentsCriteriaSearchModel];
export type EcashPaymentsSearchQueryModel = ECashPaymentsCriteriaSearchModel;

@Injectable()
export class EcashPaymentsSearchService extends UnionFormService<EcashPaymentsSearchArrayType> {
  constructor(private readonly eCashPaymentsCriteriaSearchFormService: ECashPaymentsCriteriaSearchFormService) {
    super('eCashPayments-search', eCashPaymentsCriteriaSearchFormService);
  }
  public getfields(): (keyof ECashPaymentsCriteriaSearchModel)[] {
    const array = Object.keys(this.eCashPaymentsCriteriaSearchFormService.fields);
    return array as (keyof ECashPaymentsCriteriaSearchModel)[];
  }
}
