import { Injectable } from '@angular/core';
import { FormService } from '@shared/services/forms/form.service';
import { FormBuilder } from '@angular/forms';
import { ECashPaymentsCriteriaSearchModel } from '../models';

@Injectable()
export class ECashPaymentsCriteriaSearchFormService extends FormService<ECashPaymentsCriteriaSearchModel> {
  constructor(fb: FormBuilder) {
    super('search_eCash_payments', fb, {
      eventId: fb.control(null),
      eCashId: fb.control(null),
      currency: fb.control(null),
      eCashPaymentStatus: fb.control(null),
      paymentType: fb.control(null),
      paymentE2EId: fb.control(null),
      creationFromDate: fb.control(null),
      creationToDate: fb.control(null),
      pageNumber: fb.control(null),
      itemsByPage: fb.control(null)
    });
  }
}
