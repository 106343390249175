<div class="d-flex flex-wrap align-items-center" *ngIf="isShown">
  <span>{{'instruments.list.active-filters.active-filters'|translate}} :</span>
  <span class="badge badge-discreet-info badge-dismissible ms-2" *ngFor="let item of filterItems">
    <button type="button" class="btn" (click)="remove(item)">
      <em class="icon">close</em>
    </button>
    <ng-container *ngIf="!item.isMultipleValuesFilter || $any(item.value).length === 1; else multipleItemsTemplate">
      {{label(item.key)}} : {{item|activeFilterItem:'instruments.list.active-filters.values.'}}
    </ng-container>
    <ng-template #multipleItemsTemplate>
      {{label(item.key)}} : {{ $any(item.value).length + ' selected' }}
    </ng-template>
  </span>
  
  <button class="btn btn-link"
    (click)="clear()">{{'instruments.list.active-filters.clear-all-filters'|translate}}</button>
    <button class="btn btn-flat-info btn-md btn-icon-text" (click)="saveFilters()">
      <em class="icon">save_alt</em>
      {{'instruments.list.active-filters.save-filters'|translate}}
    </button>
  </div>
