<div class="section-forms" [formGroup]="formGroup">
  <div class="container-fluid">
    <div class="row gy-2">
      <div class="col-md-1">
        <app-instrument-filter-selector></app-instrument-filter-selector>
      </div>
      <div class="col-md-2">
        <div class="input-group input-group-md">
          <div class="input-icon-start">
            <em class="icon text-secondary">search</em>
          </div>
          <input placeholder="{{ placeholder(fields.isin)  | translate }}" #isin component type="search"
            [formControlName]="fields.isin" class="form-control form-control-md">
        </div>
      </div>
      <div class="col-md-3">
        <div class="input-group input-group-md">
          <div class="input-icon-start">
            <em class="icon text-secondary">search</em>
          </div>
          <input [formControlName]="fields.ipaNumber" placeholder="{{ placeholder(fields.ipaNumber)  | translate }}"
            class="form-control form-control-md" #ipaNumber component>
        </div>
      </div>
      <div class="col-md-2">
        <app-type-multiple-select #status component [formControlName]="fields.status" [multiple]="false"
          classes="form-control-md" [closeOnSelect]="true" [type]="TRANCHE_WORKFLOW_STATUS"  icon="search"
          [hasError]="hasError(fields.status)" placeholder="{{ placeholder(fields.status)  | translate }}">
        </app-type-multiple-select>
      </div>
      <div class="col-md-2">
        <app-advanced-filters-button [advancedFiltersShown]="!isCollapsed" [hasFilters]="filters.length>0"
          (changed)="collapse.toggle()"></app-advanced-filters-button>
      </div>
      <div class="col-md-2">
        <ng-content select="[searchTemplate]"></ng-content>
      </div>
    </div>
  </div>

  <div class="pb-3" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <div class="container-fluid">
      <div class="row mt-2 gy-2">
        <div class="col-md-3">
          <div class="input-group input-group-md">
            <div class="input-icon-start">
              <em class="icon text-secondary">search</em>
            </div>
            <input class="form-control " [formControlName]="fields.icsd" #icsd component
              placeholder="{{ placeholder(fields.icsd)  | translate }}">
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group input-group-md">
            <div class="input-icon-start">
              <em class="icon text-secondary">search</em>
            </div>
            <input class="form-control" [formControlName]="fields.serialNumber" #serialNumber component
              placeholder="{{ placeholder(fields.serialNumber)  | translate }}">
          </div>
        </div>
        <div class="col-md-3">
          <select class="form-control" [formControlName]="fields.issuerPerimter">
            <option [ngValue]="nullValue">{{'instruments.list.filters.issuerPerimter'|translate}}</option>
            <option ngValue=SPI>{{'instruments.list.filters.spi'|translate}}</option>
            <option ngValue=STA>{{'instruments.list.filters.sta'|translate}}</option>
          </select>
        </div>
        <div class="col-md-3">
          <app-party-multiple-select #clearingSystemsInput component [formControlName]="fields.issuers" [multiple]="true"  icon="search" [withSummary]="true"
            [type]="'ISSUER'" [filter]="'INSTRUMENT'" [hasError]="hasError('issuers')"
            placeholder="{{ placeholder(fields.issuers) | translate }}">
          </app-party-multiple-select>
        </div>
      </div>
      <div class="row mt-2 gy-2">
        <div class="col-md-3">
          <app-type-multiple-select #instrumentType component [formControlName]="fields.instrumentType" [multiple]="false"  icon="search"
            [closeOnSelect]="true" [type]="INSTRUMENT_TYPE" [hasError]="hasError(fields.instrumentType)"
            placeholder="{{ placeholder(fields.instrumentType)  | translate }}">
          </app-type-multiple-select>
        </div>
        <div class="col-md-3">
          <div class="input-group input-group-md">
            <div class="input-icon-start">
              <em class="icon text-secondary">search</em>
            </div>
            <input class="form-control" [formControlName]="fields.instrumentName" #instrumentName component
              placeholder="{{ placeholder(fields.instrumentName)  | translate }}">
          </div>
        </div>
        <div class="col-md-3">
          <select class="form-control" [formControlName]="fields.finalTermReceived">
            <option [ngValue]="nullValue">{{'instruments.list.filters.finalTermReceived'|translate}}</option>
            <option [ngValue]="true">{{'instruments.list.filters.ftReceived'|translate}}</option>
            <option [ngValue]="false">{{'instruments.list.filters.ftNotReceived'|translate}}</option>
          </select>
        </div>
        <div class="col-md-3">
          <sg-daterangepicker #issuedate component (dateChanged)="onDatesChanged($event)" [endDate]="endDate"
            startPlaceholder="{{'instruments.list.filters.issuedate'|translate}}"
            endPlaceholder="{{'instruments.list.filters.issuedate'|translate}}" [startDate]="beginDate">
          </sg-daterangepicker>
        </div>
      </div>

      <hr class="mb-3 mt-3" />
      <div class="row mt-2 gy-2">
        <div class="col-md-3">
          <app-form-item [withLabel]="false" [focus]="paymentType" [field]="fields.paymentType">
            <app-type-multiple-select #paymentType component [formControlName]="fields.paymentType" [multiple]="false"  icon="search"
              [closeOnSelect]="true" [type]="PAYMENT_TYPE" [hasError]="hasError(fields.paymentType)"
              placeholder="{{ placeholder(fields.paymentType)  | translate }}">
            </app-type-multiple-select>
          </app-form-item>
        </div>
        <div class="col-md-3">
          <app-form-item [withLabel]="false" [focus]="settlementStatus" [field]="fields.settlementStatus">
            <app-type-multiple-select #settlementStatus component [formControlName]="fields.settlementStatus" icon="search" [withSummary]="true"
              [multiple]="true" [closeOnSelect]="true" [type]="SETTLEMENT_WORKFLOW_STATUS"
              [hasError]="hasError(fields.settlementStatus)"
              placeholder="{{ placeholder(fields.settlementStatus)  | translate }}">
            </app-type-multiple-select>
          </app-form-item>
        </div>
        <div class="col-md-3">
          <app-form-item [withLabel]="false" [focus]="settlementCurrency" [field]="fields.settlementCurrency">
            <app-currency-multiple-select [formControlName]="fields.settlementCurrency" icon="search" [withSummary]="false"
              [placeholder]="placeholder(fields.settlementCurrency) | translate" #settlementCurrency [multiple]="false"
              component></app-currency-multiple-select>
          </app-form-item>
        </div>
        <div class="col-md-1 d-flex align-items-center">
          <label class="text-secondary fw-medium" translate> instruments.list.filters.withoutSettlement
          </label>
        </div>
        <div class="col-md-2">
          <app-form-item [withLabel]="false" [focus]="withoutSettlement" [field]="fields.withoutSettlement">
            <app-yes-no-buttons component #withoutSettlement [formControlName]="fields.withoutSettlement">
            </app-yes-no-buttons>
          </app-form-item>
        </div>
      </div>

      <hr class="mb-3 mt-3" />
      <div class="row mt-2 gy-2">
        <div class="col-md-3">
          <select class="form-control" [formControlName]="fields.cdFtReceived">
            <option [ngValue]="nullValue">{{'instruments.list.filters.cdFtReceived'|translate}}</option>
            <option [ngValue]="true">{{'instruments.list.filters.cdFtIsReceived'|translate}}</option>
            <option [ngValue]="false">{{'instruments.list.filters.cdFtNotReceived'|translate}}</option>
          </select>
        </div>
        <div class="col-md-3">
          <select class="form-control" [formControlName]="fields.cdGnReceived">
            <option [ngValue]="nullValue">{{'instruments.list.filters.cdGnReceived'|translate}}</option>
            <option [ngValue]="true">{{'instruments.list.filters.cdGnIsReceived'|translate}}</option>
            <option [ngValue]="false">{{'instruments.list.filters.cdGnNotReceived'|translate}}</option>
          </select>
        </div>
        <div class="col-md-3">
          <select class="form-control" [formControlName]="fields.permanentMonitoring">
            <option [ngValue]="nullValue">{{'instruments.list.filters.permanentMonitoring'|translate}}</option>
            <option ngValue=Yes>{{'instruments.list.filters.isPermanentMonitoring'|translate}}</option>
            <option ngValue=No>{{'instruments.list.filters.notpermanentMonitoring'|translate}}</option>
            <option ngValue=Empty>{{'instruments.list.filters.isEmptyPermanentMonitoring'|translate}}</option>
          </select>
        </div>
        <div class="col-md-3">
          <select class="form-control" [formControlName]="fields.disposalMonitoring">
            <option [ngValue]="nullValue">{{'instruments.list.filters.disposalMonitoring'|translate}}</option>
            <option ngValue=None>{{'instruments.list.filters.noneDisposalMonitoring'|translate}}</option>
            <option ngValue=One>{{'instruments.list.filters.oneDisposalMonitoring'|translate}}</option>
            <option ngValue=Both>{{'instruments.list.filters.bothDisposalMonitoring'|translate}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-2 mb-4">
    <div class="row">
      <div class="col-md-10">
        <app-instrument-list-active-filters (filtersChanged)="onFiltersChanged($event)">
        </app-instrument-list-active-filters>
      </div>
      <div class="col-md-2 d-flex justify-content-end align-items-end">
        <button class="btn btn-md btn-flat-primary btn-icon-start" [disabled]="disableExportCsv" (click)="exportCsv()" *ngIf="canExport" translate>
          <em class="icon">save_alt</em> common-buttons.CSV-export
        </button>
      </div>
    </div>
  </div>
