<ul class="nav nav-underline text-large " role="tablist">
    <li class="nav-item">
        <a class="nav-link ps-3 ms-0 pe-3 me-0 pb-2" data-toggle="pill" role="tab" translate
            (click)="updateValue(null)" [class.text-primary]="internalValue===null"
            [class.text-secondary]="internalValue===null" [class.active]="internalValue===null">
            {{nullLabel}}
        </a>
    </li>
    <li class="nav-item">
        <a class="nav-link ps-3 ms-0 pe-3 me-0 pb-2" data-toggle="pill" role="tab" translate
            (click)="updateValue(true)" [class.text-primary]="internalValue" [class.text-secondary]="internalValue"
            [class.active]="internalValue">
            {{yesLabel}}
        </a>
    </li>
    <li class="nav-item">
        <a class="nav-link ps-3 ms-0 pe-3 me-0 pb-2" data-toggle="pill" role="tab" translate
            (click)="updateValue(false)" [class.text-primary]="internalValue===false"
            [class.text-secondary]="internalValue===false" [class.active]="internalValue===false">
            {{noLabel}}
        </a>
    </li>
</ul>