import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toBool',
  pure: true
})
export class ToBoolPipe<T> implements PipeTransform {
  transform(value: T | null | undefined) {
    return !!value;
  }
}
