import { IpaTypeModel } from '@shared/models';

export interface ScheduleEventModel {
  readonly eventReference?: number | null;
  readonly instrumentId?: number | null;
  readonly eventDate?: string | null;
  readonly beginDate?: string | null;
  readonly endDate?: string | null;
  readonly valueDate?: string | null;
  readonly notificationDate?: string | null;
  readonly requestOfFundsDate?: string | null;
  readonly preAdviceDate?: string | null;
  readonly validationOfPaymentDate?: string | null;
  readonly nominalCurrency?: string | null;
  readonly paymentCurrency?: string | null;
  readonly status?: IpaTypeModel;
  readonly eventType?: IpaTypeModel;
}
