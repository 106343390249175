/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[moveElementDown]'
})
export class MoveElementDownDirective implements OnInit {
  @Input('moveElementDown')
  elementToMoveDown: HTMLElement | null = null;
  constructor(private readonly elRef: ElementRef) {}
  private findHtmlElementRef(): HTMLElement | null {
    let elt = this.elRef.nativeElement;
    while (!elt.offsetHeight) {
      elt = elt.firstChild;
    }
    if (elt instanceof HTMLElement) {
      return elt;
    }
    return null;
  }

  ngOnInit(): void {
    if (!this.elRef || !this.elRef.nativeElement || !this.elementToMoveDown) {
      return;
    }

    const eltRef = this.findHtmlElementRef();
    if (eltRef) {
      const observer = new ResizeObserver(elements => {
        const elt = elements[0];
        const target: HTMLElement = elt.target as HTMLElement;
        const height = Math.floor(elt.contentRect.height + target.offsetTop);
        if (this.elementToMoveDown) {
          this.elementToMoveDown.style.marginTop = `${height}px`;
        }
      });

      observer.observe(eltRef, { box: 'content-box' });
    }
  }
}
