import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CommonDepositoriesForms } from '@instruments/models/management-common-depository.model';
import { PermanentMonitoringModel } from '@instruments/models/permanent-monitoring.model';
import { FormService } from '@shared/services/forms/form.service';

@Injectable()
export class PermanentMonitoringFormService extends FormService<PermanentMonitoringModel, CommonDepositoriesForms> {
  constructor(fb: UntypedFormBuilder) {
    super('managment_common_depository', fb, {
      permanentMonitoringItems: fb.control([])
    });
  }
}
