/* eslint-disable @typescript-eslint/no-namespace */
import { Injectable } from '@angular/core';

import { DisplayService } from '@shared/services/display/display.service';
import { TrancheModel } from '@instruments/models/tranche.model';
import { EventsDataFormService, GlobalNoteFormService, InstrumentFormService, TrancheFormService } from '..';
import { BooleanRules, IpaTypeModelRules } from '@instruments/rules';

@Injectable()
export class TrancheDisplayService extends DisplayService<TrancheModel> {
  constructor(readonly ts: TrancheFormService, private readonly gn: GlobalNoteFormService, private readonly ed: EventsDataFormService, private readonly is: InstrumentFormService) {
    super(ts);
    this.init();
  }

  private init() {
    this.with(this.gn, 'physicalForm').rules({
      when: ({ physicalForm }) => {
        const instrument = this.is.rawValue();
        return BooleanRules.isFalse(instrument.upto) && IpaTypeModelRules.isTefraD(physicalForm);
      },
      displayFields: 'isinReference'
    });
    this.with(this.ed, 'couponType').rules({
      when: ({ couponType }) => IpaTypeModelRules.isNotZeroCoupon(couponType),
      displayFields: 'interestCommencementDate'
    });

    this.with(this.is, 'tradingMethodIdentifier').rules({
      when: ({ tradingMethodIdentifier }) => IpaTypeModelRules.isTradingMethodNominal(tradingMethodIdentifier),
      displayFields: 'nominal'
    });

    this.with(this.is, 'tradingMethodIdentifier').rules({
      when: ({ tradingMethodIdentifier }) => IpaTypeModelRules.istradingMethodUnit(tradingMethodIdentifier),
      displayFields: 'numberOfShares'
    });
  }
}
