import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgrammeCriteriaSearchModel, ProgrammePagingCriteriaModel, ProgrammeSearchQueryModel } from '@programmes/models/progamme-paging-criteria.model';
import { ProgrammeFetchModel, toProgrammeFetchModel } from '@programmes/models/programme-fetch.model';
import { ProgrammeCriteriaSearchFormService } from '@programmes/services/programme-criteria-search.form.service';
import { ProgrammePagingCriteriaSearchFormService } from '@programmes/services/programme-paging-criteria-search.form.service';
import { ProgrammeUnionSearchService } from '@programmes/services/programme-union-search.service';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { SearchContainerWithSaveFiltersBaseComponent } from '@shared/search-base';
import { PermissionService } from '@shared/services/permission.service';
import { ProgrammeService } from '@shared/services/programme.service';
import { Forms } from '@shared/utils';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-programmes-container',
  templateUrl: './programmes-container.component.html',
  styleUrls: ['./programmes-container.component.scss'],
  providers: [ProgrammePagingCriteriaSearchFormService, ProgrammeCriteriaSearchFormService, ProgrammeUnionSearchService]
})
export class ProgrammesContainerComponent extends SearchContainerWithSaveFiltersBaseComponent<
  ProgrammeFetchModel,
  ProgrammeCriteriaSearchModel,
  ProgrammeSearchQueryModel,
  ProgrammePagingCriteriaModel,
  ProgrammePagingCriteriaSearchFormService,
  ProgrammeCriteriaSearchFormService,
  ProgrammeUnionSearchService
> {
  public canCreateProgramme: boolean | undefined;
  public pageType = Forms.PageTypeEnum;
  public disableSearch = false;

  constructor(
    private readonly programmeService: ProgrammeService,
    criteriaSearchFormService: ProgrammeCriteriaSearchFormService,
    pagingCriteriaSearchFormService: ProgrammePagingCriteriaSearchFormService,
    permissionService: PermissionService,

    activatedRoute: ActivatedRoute,
    private readonly searchService: ProgrammeUnionSearchService,
    router: Router,
    private readonly toast: ToastManagerService
  ) {
    super(pagingCriteriaSearchFormService, criteriaSearchFormService, permissionService, searchService, activatedRoute, router, 20, '/programme');
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected saveFilters(): void {}
  protected load(): void {
    const searchParams = this.pagingFormService.value();
    if (!searchParams || !this.searchCriteria) {
      return;
    }
    this.disableSearch = true;

    this.programmeService
      .searchPaginatedProgrammes({
        ...this.searchCriteria,
        itemsByPage: searchParams?.itemsByPage,
        pageNumber: searchParams?.pageNumber ? searchParams?.pageNumber : 1
      })
      .pipe(takeUntil(this.shutdown$))
      .subscribe(result => {
        this.disableSearch = false;
        this.searchService.patch('totalItems', result?.totalItems);
        this.models = Array.from(result?.items || []).map(i => toProgrammeFetchModel(i));
      });
  }
  protected initPermissions(): void {
    this.permissionService.canCreateProgramme$.pipe(takeUntil(this.shutdown$)).subscribe({
      next: (canCreateProgramme: boolean) => {
        this.canCreateProgramme = canCreateProgramme;
      }
    });
  }
}
