import { AbstractControl, UntypedFormGroup } from '@angular/forms';

export class ErrorMessageForm {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getValidationErrors(group: UntypedFormGroup): any {
    let formErrors = {};

    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);

      formErrors = { ...formErrors, [key]: '' };
      if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
        this.getMessageErrorKey(abstractControl, formErrors, key);
      }
      if (abstractControl instanceof UntypedFormGroup) {
        const groupError = this.getValidationErrors(abstractControl);
        formErrors = { ...formErrors, ...groupError };
      }
    });
    return formErrors;
  }

  private static getMessageErrorKey(abstractControl: AbstractControl, formErrors: Record<string, unknown>, key: string) {
    for (const errorKey in abstractControl.errors) {
      if (errorKey && formErrors[key] === '') {
        formErrors[key] += `validationMessage.errors.${errorKey}`;
      }
    }
  }
}
