import { Injectable } from '@angular/core';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { AgGridColumnDefBaseService } from '@shared/services/aggrid-column-def-base-service';
import { SWIFTS_COLUMN } from './swifts-column-def';

@Injectable()
export class SwiftsColumnDefService extends AgGridColumnDefBaseService {
  constructor(translateService: I18nUtilsService) {
    super(translateService, 'events.swifts.headers', SWIFTS_COLUMN);
  }
}
