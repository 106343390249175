import { PoolFactorHistorySearchResponseDto, PoolFactorHistorySearchRequestDto } from '@apis/backend/events';

export interface PoolFactorGridModel {
  readonly creationDate?: string;
  readonly currentPf?: number;
  readonly newPf?: number;
  readonly eventId?: number;
  readonly partialRedmType?: string;
  readonly comments?: string;
}

export const toPoolFactorModal = (poolFactor: PoolFactorHistorySearchResponseDto): PoolFactorGridModel => {
  return {
    creationDate: poolFactor.creationDate,
    currentPf: poolFactor.oldPoolFactor,
    newPf: poolFactor.newPoolFactor,
    eventId: poolFactor.eventReference,
    partialRedmType: poolFactor.partialRedmType,
    comments: poolFactor.comments
  };
};

export const toPoolFactorSearchDto = (id: number[]): PoolFactorHistorySearchRequestDto => {
  return {
    instrumentIds: [Number(id)]
  };
};
