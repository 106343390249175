/**
 * issuer-ipa-events-extended API
 * Manage eventss data
 *
 * The version of the OpenAPI document: 1.1.21
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SearchPaymentsConfirmation } from './searchPaymentsConfirmation';


/**
 * Parameters of Payments confirmation search
 */
export interface SearchPaymentConfPaginationDto { 
    /**
     * Requested page number.
     */
    pageNumber?: number;
    /**
     * Number of items per page.
     */
    itemsByPage?: number;
    criteria?: SearchPaymentsConfirmation;
}

