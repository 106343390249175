/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { DayModel } from './day.model';

/*
USAGE EXAMPLE :
<app-multiple-date-picker formControlName="..." [placeholder]="'placeholder'">
</app-multiple-date-picker>
*/

@Component({
  selector: 'app-multiple-day-picker',
  templateUrl: './multiple-day-picker.component.html',
  styleUrls: ['./multiple-day-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleDayPickerComponent),
      multi: true
    }
  ]
})
export class MultipleDayPickerComponent implements ControlValueAccessor, OnInit {
  @Input() nbMonth = 2;
  @Input() hasError = false;
  @Input() placeholder = '';
  public isReadOnly = false;
  public focusId: string | null = null;
  public selectedDates: DayModel[] = [];
  public displayedMonths: number[] = []; //0 to 11
  private readonly daysPerMonth: number[] = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  public showMonths = false;

  public nbDays(month: number): number {
    return this.daysPerMonth[month];
  }

  public ngOnInit(): void {
    this.today();
  }

  private getMonthNumber(from: number, step: number): number {
    return (((from + step) % 12) + 12) % 12;
  }
  public navigate(number: number): void {
    // eslint-disable-next-line prettier/prettier
    const nextStartingMonth =
      number > 0 ? this.getMonthNumber(this.displayedMonths[this.nbMonth - 1], number) : this.getMonthNumber(this.displayedMonths[0], -this.nbMonth);
    this.displayedMonths = new Array<number>(this.nbMonth).fill(0).map((x, i) => this.getMonthNumber(nextStartingMonth, i));
  }

  public today(): void {
    const currentMonth = new Date().getMonth();
    this.displayedMonths = new Array<number>(this.nbMonth).fill(0).map((x, i) => this.getMonthNumber(currentMonth, i));
  }

  public toggleShowMonths(): void {
    if (this.showMonths) {
      this.onTouched();
    }
    this.showMonths = !this.showMonths;
  }
  public hideMonths(): void {
    if (this.showMonths) {
      this.onTouched();
    }
    this.showMonths = false;
  }

  public onDateSelected(date: DayModel): void {
    const existingId = this.selectedDates.findIndex(d => d.month === date.month && d.day === date.day);
    if (existingId >= 0) {
      this.onDateDeleted(existingId);
      return;
    }

    this.selectedDates = [...this.selectedDates, date];
    this.onChange(this.selectedDates);
  }

  public onDateDeleted(idx: number): void {
    const cpy = [...this.selectedDates];
    cpy.splice(idx, 1);
    this.selectedDates = cpy;
    this.onChange(this.selectedDates);
  }

  onChange!: OnChangeFn<DayModel[]>;

  onTouched!: OnTouchFn;

  setDisabledState(isDisabled: boolean): void {
    this.isReadOnly = isDisabled;
  }
  registerOnChange(fn: OnChangeFn<DayModel[]>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchFn): void {
    this.onTouched = fn;
  }
  writeValue(value: DayModel[] | null | undefined): void {
    this.selectedDates = value || [];
  }
}
