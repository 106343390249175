import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { sgwtConnect } from './app/sgwtConnect';
import { defineCustomElements } from '@sg-bootstrap/components/dist/loader';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { EVENT_AUTH_EXPIRED } from '@sgwt/connect-angular';

if (environment.production) {
  enableProdMode();
}

defineCustomElements(window);

const handleSgwtConnectPassiveMode = () => {
  // As working in passive mode, inject sgwtConnect in corresponding widget
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const widget: any = document.querySelector('sgwt-connect');
  if (widget) {
    // When the code is executed, the widget may not have been initialized. So, we need to check that, otherwise calling
    // `widget.setSgwtConnectInstance()` will throw an error.
    if (typeof widget.setSgwtConnectInstance === 'undefined') {
      // Widget is not initialized yet, so we will wait the event that indicates the widget is ready...
      const handleSgwtConnectReady = () => {
        widget.setSgwtConnectInstance(sgwtConnect);
        // Remove the event listener
        widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady);
      };

      widget.addEventListener('sgwt-connect--ready', handleSgwtConnectReady);
    } else {
      // Widget is initialized...
      widget.setSgwtConnectInstance(sgwtConnect);
    }

    widget.sgwtConnect.on(EVENT_AUTH_EXPIRED, () => widget.sgwtConnect.discardAuthorization());
  }

  // Request authorization, then retrieve the user profile
  if (sgwtConnect.isAuthorized()) {
    // Bootstrap your application...
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(
        () =>
          (document.body.innerHTML = `
        <div class="alert alert-danger" role="alert">
          Bootstrap initialization error}.
        </div>`)
      );
  } else if (sgwtConnect.getAuthorizationError()) {
    document.body.innerHTML = `
    <div class="alert alert-danger" role="alert">
      Authorization error: ${sgwtConnect.getAuthorizationError()}.
    </div>
  `;
  } else {
    sgwtConnect.requestAuthorization();
  }
};

const handleSilentRenew = () => {
  const setCookie = () => {
    const bus: any = window.SGWTWidgetConfiguration.bus;
    const lastToken = bus.lastValues;
    const accessToken = (lastToken['sg-connect.access-token'] || '').split('Bearer')[1]?.trim() || ''; //First connection, not having last token
    const cookieStr = `${encodeURIComponent('accessToken')}=${encodeURIComponent(accessToken)};sameSite=Strict;`;
    window.document.cookie = cookieStr;
  };

  setCookie();
  const widget: any = document.querySelector('sgwt-connect');
  if (widget) {
    widget.sgwtConnect.on('renewAuthorizationSuccess', () => {
      setCookie();
    });
  }
};

// As working in passive mode, inject sgwtConnect in corresponding widget
// eslint-disable-next-line @typescript-eslint/no-explicit-any
document.addEventListener('DOMContentLoaded', function () {
  handleSgwtConnectPassiveMode();
  handleSilentRenew();
});
