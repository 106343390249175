import { DocumentVersionIdentifier } from '@apis/backend/instruments';

export interface DocumentVersionIdentifierModel {
  readonly workspaceId: string;
  readonly modelId: string;
  readonly documentId: string;
  readonly versionId: string | null;
}

export const toDocumentVersionIdentifierModel = (dto: DocumentVersionIdentifier | null | undefined): DocumentVersionIdentifierModel => {
  return {
    workspaceId: dto?.workspaceId ?? '',
    modelId: dto?.modelId ?? '',
    documentId: dto?.documentId ?? '',
    versionId: dto?.versionId || null
  };
};
