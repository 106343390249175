import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

interface LinkCellRendererParams extends ICellRendererParams {
  linkClickHandler: any;
}

@Component({
  selector: 'app-link-cell-renderer',
  template: '<a href="javascript:void(0)" (click)="onLinkClick()">{{ params?.value }}</a>'
})
export class LinkCellRendererComponent implements ICellRendererAngularComp {
  params: LinkCellRendererParams | undefined = undefined;

  agInit(params: LinkCellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  onLinkClick(): void {
    if (this.params?.linkClickHandler) {
      this.params?.linkClickHandler(this.params?.data);
    }
  }
}
