import { Component } from '@angular/core';

@Component({
  selector: 'app-instrument-settlement',
  templateUrl: './instrument-settlement.component.html'
})
export class InstrumentSettlementComponent {
  public canModify = true;
  public canSave = false;
}
