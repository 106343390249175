import { ValuationDTO, ValuationRequestDTO, ValuationUpdateRequestDTO, ValuationSearchRequestDTO } from '@apis/backend/events';
import { IpaTypeModel } from '@shared/models';
import { formatNumber } from '@angular/common';

export interface ValuationDetailModel {
  valuationRef?: number;
  eventId?: number;
  valuationStatus?: string;
  valuationRepairReason?: string;
  isinCode?: string;
  eventType?: string;
  denomination?: string;
  denominationCcy?: string;
  valueDate?: string;
  maturityDate?: string;
  notificationDate?: string;
  receiptDate?: string;
  startDate?: string;
  endDate?: string;
  couponType?: string;
  interestRate?: number;
  dayCountFraction?: string;
  numOfDayOfThePeriod?: number;
  redemptionType?: IpaTypeModel;
  redemptionTypeDescription?: string;
  strikePrice?: number;
  redemptionRate?: string;
  underlyingIsin?: string;
  underlyingDenomQty?: number;
  previousPoolFactor?: number;
  forexRate?: number;
  amountPerDenomination?: number;
  newPoolFactor?: number;
  paymentCcy?: string;
  underlyingCcy?: string;
  outstandingNominal?: string;
  totalNumOfUnderlying?: number;
  positionIn?: number;
  positionOut?: number;
  valuationVersion?: string;
  rejectionStatus?: string;
  closingPrice?: number;
  remarks?: string;
}

export type ValuationType =
  | 'valuationRef'
  | 'isinCode'
  | 'eventType'
  | 'denomination'
  | 'denominationCcy'
  | 'paymentCcy'
  | 'underlyingCcy'
  | 'outstandingNominal'
  | 'valueDate'
  | 'notificationDate'
  | 'receiptDate'
  | 'startDate'
  | 'endDate'
  | 'couponType'
  | 'interestRate'
  | 'dayCountFraction'
  | 'numOfDayOfThePeriod'
  | 'redemptionType'
  | 'redemptionTypeDescription'
  | 'redemptionRate'
  | 'underlyingIsin'
  | 'strikePrice'
  | 'totalNumOfUnderlying'
  | 'underlyingDenomQty'
  | 'previousPoolFactor'
  | 'newPoolFactor'
  | 'amountPerDenomination'
  | 'positionIn'
  | 'positionOut'
  | 'valuationVersion'
  | 'rejectionStatus'
  | 'closingPrice'
  | 'forexRate';

export const ValuationFields: readonly ValuationType[] = [
  'valuationRef',
  'isinCode',
  'eventType',
  'denomination',
  'denominationCcy',
  'paymentCcy',
  'underlyingCcy',
  'outstandingNominal',
  'valueDate',
  'notificationDate',
  'receiptDate',
  'startDate',
  'endDate',
  'couponType',
  'interestRate',
  'dayCountFraction',
  'numOfDayOfThePeriod',
  'redemptionType',
  'redemptionTypeDescription',
  'redemptionRate',
  'underlyingIsin',
  'strikePrice',
  'totalNumOfUnderlying',
  'underlyingDenomQty',
  'previousPoolFactor',
  'newPoolFactor',
  'amountPerDenomination',
  'positionIn',
  'positionOut',
  'valuationVersion',
  'rejectionStatus',
  'closingPrice',
  'forexRate'
];

enum ValuationStatus {
  'AWAIT NEW VALUATION' = 4,
  'REGULARIZED' = 5
}

export const toValuationStatusUpdateDto = (valuationRef: number, valuationStatus?: string | undefined, remarks?: string | undefined): ValuationUpdateRequestDTO[] => {
  const status = valuationStatus === 'AWAIT NEW VALUATION' ? ValuationStatus['AWAIT NEW VALUATION'] : ValuationStatus['REGULARIZED'];
  return [
    {
      valuationRef: valuationRef,
      valuationStatus: {
        valueId: status
      },
      remarks: remarks
    }
  ];
};

export const toSearchByValuationId = (id: number): ValuationSearchRequestDTO => {
  return {
    valuationIds: [id]
  };
};

export const toSearchByEventId = (id: number): ValuationSearchRequestDTO | any => {
  return {
    eventReferences: [id]
  };
};

export const setValuationDetails = (valuationDetail: ValuationDTO | null): ValuationDetailModel | null => {
  if (valuationDetail == null) {
    return null;
  }
  return {
    valuationRef: valuationDetail.valuation?.valuationId,
    eventId: valuationDetail.eventReference,
    isinCode: valuationDetail.valuation?.isinCode,
    eventType: valuationDetail.valuation?.eventType?.valueDescription,
    valuationStatus: valuationDetail.status?.valueDescription,
    denomination: valuationDetail.valuation?.denomination !== undefined ? formatNumbers(valuationDetail.valuation?.denomination) : undefined,
    denominationCcy: valuationDetail.valuation?.nominalCurrency,
    outstandingNominal: valuationDetail.valuation?.nominal !== undefined ? formatNumbers(valuationDetail.valuation?.nominal) : undefined,
    paymentCcy: setPaymentCCY(valuationDetail.valuation?.eventType?.valueDescription, valuationDetail?.valuation),
    valueDate: setValueDate(valuationDetail.valuation?.eventType?.valueDescription, valuationDetail?.valuation),
    notificationDate: valuationDetail.valuation?.valuationDate,
    receiptDate: valuationDetail.timestampCreate,
    startDate: valuationDetail.valuation?.interestStartDate,
    endDate: valuationDetail.valuation?.interestEndDate,
    couponType: valuationDetail.valuation?.couponType?.valueDescription,
    interestRate: valuationDetail.valuation?.couponRate,
    dayCountFraction: valuationDetail.valuation?.dayCountFraction?.valueDescription,
    numOfDayOfThePeriod: valuationDetail.valuation?.dayNB,
    redemptionType: valuationDetail.valuation?.redemptionType,
    redemptionTypeDescription: valuationDetail.valuation?.redemptionType?.valueDescription,
    redemptionRate: valuationDetail.valuation?.redemptionRate?.toString(),
    strikePrice: valuationDetail.valuation?.strikeRate,
    forexRate: valuationDetail.valuation?.forexRate,
    underlyingIsin: valuationDetail.valuation?.underlyingISIN,
    underlyingCcy: valuationDetail.valuation?.underlyingCurrency,
    underlyingDenomQty: valuationDetail.valuation?.sharesPerNoteOut,
    totalNumOfUnderlying: valuationDetail.valuation?.sharesToDeliver,
    previousPoolFactor: valuationDetail.valuation?.previousPoolFactor,
    newPoolFactor: valuationDetail.valuation?.newPoolFactor,
    valuationVersion: valuationDetail.valuation?.valuationVersion,
    rejectionStatus: valuationDetail?.rejectReason,
    closingPrice: valuationDetail.valuation?.closingPrice,
    amountPerDenomination:
      valuationDetail.valuation?.amountPerDenomination !== undefined || valuationDetail.valuation?.rofDenominationAmount !== undefined
        ? setAmountPerDenomination(valuationDetail.valuation?.eventType?.valueDescription, valuationDetail?.valuation)
        : undefined,
    positionIn: valuationDetail.valuation?.positionIN,
    positionOut: valuationDetail.valuation?.positionOUT,
    remarks: valuationDetail.valuation?.remarks
  };
};

const setAmountPerDenomination = (eventType: string | undefined, valuation: ValuationRequestDTO | undefined): number | undefined => {
  let amountPerDenomination: number | string | undefined;
  switch (eventType) {
    case 'REDM':
    case 'MCAL':
    case 'PRED':
      amountPerDenomination = valuation?.rofDenominationAmount;
      break;
    default:
      amountPerDenomination = valuation?.amountPerDenomination || undefined;
  }
  return amountPerDenomination;
};

const setPaymentCCY = (eventType: string | undefined, valuation: ValuationRequestDTO | undefined): string | undefined => {
  let paymentCCY: string | undefined;
  switch (eventType) {
    case 'INTR':
      paymentCCY = valuation?.interestCurrency || undefined;
      break;
    case 'PRED':
    case 'REDM':
    case 'MCAL':
    case 'PCAL':
      paymentCCY = valuation?.redemptionCurrency;
      break;
  }
  return paymentCCY;
};

const setValueDate = (eventType: string | undefined, valuation: ValuationRequestDTO | undefined): string | undefined => {
  let valueDate: string | undefined = '';
  switch (eventType) {
    case 'INTR':
      valueDate = valuation?.interestDate;
      break;
    case 'PRED':
    case 'REDM':
    case 'MCAL':
    case 'PCAL':
      valueDate = valuation?.rofPaymentDate;
      break;
  }
  return valueDate;
};

const formatNumbers = (parsedValue: number): string | undefined => {
  return formatNumber(parsedValue, 'en', '1.0-0');
};
