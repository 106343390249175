import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InstrumentModel } from '@instruments/models';
import {
  CharacteristicFormService,
  EventsDataFormService,
  GlobalNoteFormService,
  InstrumentAddendumFormService,
  InstrumentFormService,
  IssuanceFormService,
  ListingFormService,
  RoleDataFormService,
  TrancheDisplayService,
  TrancheFormService
} from '@instruments/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tranche-form-container',
  templateUrl: './tranche-form-container.component.html',
  styleUrls: ['./tranche-form-container.component.scss'],
  providers: [InstrumentFormService, CharacteristicFormService, EventsDataFormService, GlobalNoteFormService, InstrumentAddendumFormService, IssuanceFormService, ListingFormService, TrancheFormService, TrancheDisplayService, RoleDataFormService]
})
export class TrancheFormContainerComponent implements OnInit, OnDestroy {
  public ClosedReason: DialogResult = 'closed';
  public CancelReason: DialogResult = 'cancel';
  public ConfirmReason: DialogResult = 'confirm';
  private readonly subscriptions: Subscription[] = [];
  public upto?: boolean | null;

  confirmButton = 'modals.buttons.confirm';
  cancelButton = 'modals.buttons.cancel';
  @Input() set instrument(value: InstrumentModel | null | undefined) {
    if (value) {
      this.subscriptions.push(...this.displayService.Compile().map(o => o.subscribe()));
      this.instrumentFormService.setInitialValue(value);
    }
  }

  constructor(private readonly activeModal: NgbActiveModal, public readonly instrumentFormService: InstrumentFormService, private readonly trancheFormService: TrancheFormService, private readonly displayService: TrancheDisplayService) {}

  ngOnInit(): void {
    this.upto = this.instrumentFormService.rawValue().upto;
  }

  public get createLabel(): string {
    return this.upto ? 'modals.titles.createIncrease' : 'modals.titles.createTranche';
  }

  public onClose(): void {
    this.activeModal.dismiss(this.ClosedReason);
    this.activeModal.close(this.ClosedReason);
  }

  public onCancel(): void {
    this.activeModal.close(this.CancelReason);
  }
  public onConfirm(): void {
    this.trancheFormService.updateValueAndValidity();
    if (this.trancheFormService.valid) {
      this.activeModal.close({ reason: this.ConfirmReason, tranche: this.trancheFormService.value() });
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
