<div class="section-forms" [formGroup]="formGroup">
    <div class="row">
        <div class="form-group col-md-4">
            <app-form-item [field]="fields.eventSentforValidationBy" [focus]="eventSentforValidationBy">
                <input class="form-control box-style" type="text" [formControlName]="fields.eventSentforValidationBy"
                 component #eventSentforValidationBy readonly />
              </app-form-item>
        </div>
        <div class="form-group col-md-4">
            <app-form-item [field]="fields.eventValidatedBy" [focus]="eventValidatedBy">
                <input class="form-control box-style" type="text" [formControlName]="fields.eventValidatedBy"
                 component #eventValidatedBy readonly />
              </app-form-item>
        </div>
        <div class="form-group col-md-4">
            <app-form-item [field]="fields.eventValidatedAt" [focus]="eventValidatedAt">
                <input class="form-control box-style" type="text" [formControlName]="fields.eventValidatedAt"
                  component #eventValidatedAt readonly />
              </app-form-item>
        </div>
    </div>
</div>