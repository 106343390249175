<div class="section-forms container g-0" [formGroup]="formGroup">
  <div class="row gy-4">
    <div class="col-md-6">
      <app-form-item [field]="fields.issueDate" [focus]="issueDateField">
        <app-date-picker [formControlName]="fields.issueDate" [hasError]="hasError(fields.issueDate)" #issueDateField
          component></app-date-picker>
      </app-form-item>
    </div>
  </div>
  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item [field]="fields.couponType" [focus]="couponTypeField">
        <app-type-multiple-select [formControlName]="fields.couponType" [multiple]="false" [closeOnSelect]="true"
          [type]="COUPON_TYPE" [hasError]="hasError(fields.couponType)"
          [placeholder]="placeholder(fields.couponType) | translate" #couponTypeField component>
        </app-type-multiple-select>
      </app-form-item>
    </div>
  </div>
  <ng-container *ngIf="shouldDisplaySection(firstCouponTypeSection) | async | toBool">
    <div class="row gy-4 mt-1">
      <div class="col-md-6">
        <app-form-item [field]="fields.interestCommencementDate" [focus]="interestCommencementDateField">
          <app-date-picker [formControlName]="fields.interestCommencementDate"
            [hasError]="hasError(fields.interestCommencementDate)" #interestCommencementDateField component>
          </app-date-picker>
        </app-form-item>
      </div>
    </div>
    <div class="row gy-4 mt-1">
      <div class="col-md-6">
        <app-form-item [field]="fields.couponFrequency" [focus]="couponFrequencyField">
          <app-type-multiple-select [formControlName]="fields.couponFrequency" [multiple]="false" [closeOnSelect]="true"
            [type]="COUPON_FREQUENCY" [hasError]="hasError(fields.couponFrequency)"
            [placeholder]="placeholder(fields.couponFrequency) | translate" #couponFrequencyField component>
          </app-type-multiple-select>
        </app-form-item>
      </div>
      <div class="col-md-6">
        <app-form-item [field]="fields.couponPaymentCurrencies" [focus]="couponPaymentCurrenciesField"
          (displayChanged)="onDisplayChanged('couponPaymentCurrencies',$event)">
          <app-currency-multiple-select [formControlName]="fields.couponPaymentCurrencies"
            [placeholder]="placeholder(fields.couponPaymentCurrencies) | translate" #couponPaymentCurrenciesField
            [multiple]="true" component></app-currency-multiple-select>
        </app-form-item>
      </div>
    </div>

    <div class="row gy-4 mt-1">
      <div class="col-md-6">
        <app-form-item [field]="fields.dayCountFraction" [focus]="dayCountFractionField">
          <app-type-multiple-select [formControlName]="fields.dayCountFraction" [multiple]="false"
            [closeOnSelect]="true" [type]="DAY_COUNT_FRACTION" [hasError]="hasError(fields.dayCountFraction)"
            [placeholder]="placeholder(fields.dayCountFraction) | translate" #dayCountFractionField component>
          </app-type-multiple-select>
        </app-form-item>
      </div>
      <div class="col-md-6">
        <app-form-item [field]="fields.interestRate" [focus]="interestRateField">
          <div class="input-group mb-3" component>
            <div class="input-group-prepend">
              <span class="input-group-text">%</span>
            </div>
            <app-number [formControlName]="fields.interestRate" #interestRateField
              [placeholder]="placeholder(fields.interestRate) | translate"></app-number>
          </div>
        </app-form-item>
      </div>
    </div>
    <div class="row gy-4 mt-1">
      <div class="col-md-6">
        <app-form-item [field]="fields.endDateAdjusted" [focus]="endDateAdjustedField" labelAddendum="label">
          <app-yes-no-buttons [formControlName]="fields.endDateAdjusted" [yesLabel]="adjustedLabel"
            [noLabel]="unadjustedLabel" #endDateAdjustedField component>
          </app-yes-no-buttons>
        </app-form-item>
      </div>
    </div>
    <div class="row gy-4 mt-1">
      <div class="col-md-6">
        <app-form-item [field]="fields.calculationTime" [focus]="calculationTimeField">
          <app-type-multiple-select [formControlName]="fields.calculationTime" [multiple]="false" [closeOnSelect]="true"
            [type]="CALCULATION_TIME" [hasError]="hasError(fields.calculationTime)"
            [placeholder]="placeholder(fields.calculationTime) | translate" #calculationTimeField component>
          </app-type-multiple-select>
        </app-form-item>
      </div>
      <div class="col-md-6">
        <app-form-item [field]="fields.calculationOffset" [focus]="calculationOffsetField">
          <app-number innerClass="form-control" [placeholder]="placeholder(fields.calculationOffset) | translate"
            [formControlName]="fields.calculationOffset" #calculationOffsetField component></app-number>
        </app-form-item>
      </div>
    </div>
    <div class="row gy-4 mt-1">
      <div class="col-md-6">
        <app-form-item [field]="fields.paymentDateCorrelated" [focus]="paymentDateCorrelatedField"
          labelAddendum="label">
          <app-yes-no-buttons [formControlName]="fields.paymentDateCorrelated" [yesLabel]="correlatedLabel"
            [noLabel]="decorrelatedLabel" #paymentDateCorrelatedField component>
          </app-yes-no-buttons>
        </app-form-item>
      </div>
    </div>
    <ng-container *ngIf="shouldDisplaySection(correlatedSection) | async | toBool; else decorellated">
      <div class="row gy-4 mt-1">
        <div class="col-md-6">
          <app-form-item [field]="fields.firstCouponPayDate" [focus]="firstCouponPayDateField">
            <app-date-picker [formControlName]="fields.firstCouponPayDate"
              [hasError]="hasError(fields.firstCouponPayDate)" #firstCouponPayDateField component>
            </app-date-picker>
          </app-form-item>
        </div>
      </div>
      <div class="row gy-4 mt-1">
        <div class="col-md-6">
          <app-form-item [field]="fields.businessDayConvention" [focus]="businessDayConventionField">
            <app-type-multiple-select [formControlName]="fields.businessDayConvention" [multiple]="false"
              [closeOnSelect]="true" [type]="BUSINESS_DAY_CONVENTION"
              [hasError]="hasError(fields.businessDayConvention)"
              [placeholder]="placeholder(fields.businessDayConvention) | translate" #businessDayConventionField
              component>
            </app-type-multiple-select>
          </app-form-item>
        </div>
      </div>
    </ng-container>
    <ng-template #decorellated>
      <div class="row gy-4 mt-1">
        <div class="col-md-6">
          <app-form-item [field]="fields.firstEndPeriodDate" [focus]="firstEndPeriodDateField">
            <app-date-picker [formControlName]="fields.firstEndPeriodDate"
              [hasError]="hasError(fields.firstEndPeriodDate)" #firstEndPeriodDateField component>
            </app-date-picker>
          </app-form-item>
        </div>
      </div>
      <div class="row gy-4 mt-1">
        <div class="col-md-6">
          <app-form-item [field]="fields.paymentDateOffset" [focus]="paymentDateOffsetField">
            <app-number [formControlName]="fields.paymentDateOffset" #paymentDateOffsetField component
              placeholder="{{ placeholder(fields.paymentDateOffset) | translate }}"></app-number>
          </app-form-item>
        </div>
      </div>
      <div class="row gy-4 mt-1">
        <div class="col-md-6">
          <app-form-item [field]="fields.paymentDateMgtMode" [focus]="paymentDateMgtModeField">
            <app-type-multiple-select [formControlName]="fields.paymentDateMgtMode" [multiple]="false"
              [closeOnSelect]="true" [type]="PAYMENT_DATE_MGT_MODE" [hasError]="hasError(fields.paymentDateMgtMode)"
              [placeholder]="placeholder(fields.paymentDateMgtMode) | translate" #paymentDateMgtModeField component>
            </app-type-multiple-select>
          </app-form-item>
        </div>
      </div>
    </ng-template>
    <div class="row gy-4 mt-1">
      <div class="col-md-6">
        <app-form-item [field]="fields.secondCouponType" [focus]="secondCouponTypeField">
          <app-type-multiple-select [formControlName]="fields.secondCouponType" [multiple]="false"
            [closeOnSelect]="true" [type]="SECOND_COUPON_TYPE" [hasError]="hasError(fields.secondCouponType)"
            [placeholder]="placeholder(fields.secondCouponType) | translate" #secondCouponTypeField component>
          </app-type-multiple-select>
        </app-form-item>
      </div>
      <div class="col-md-6">
        <app-form-item [field]="fields.secondInterestRate" [focus]="secondInterestRateField">
          <div class="input-group mb-3" component>
            <div class="input-group-prepend">
              <span class="input-group-text">%</span>
            </div>
            <app-number [formControlName]="fields.secondInterestRate" #secondInterestRateField></app-number>
          </div>
        </app-form-item>
      </div>
    </div>
    <div class="row gy-4 mt-1" *ngIf="shouldDisplaySection(secondCouponTypeSection) | async | toBool">
      <div class="col-md-6">
        <app-form-item [field]="fields.secondDayCountFraction" [focus]="secondDayCountFractionField">
          <app-type-multiple-select [formControlName]="fields.secondDayCountFraction" [multiple]="false"
            [closeOnSelect]="true" [type]="SECOND_DAY_COUNT_FRACTION"
            [hasError]="hasError(fields.secondDayCountFraction)"
            [placeholder]="placeholder(fields.secondDayCountFraction) | translate" #secondDayCountFractionField
            component>
          </app-type-multiple-select>
        </app-form-item>
      </div>
      <div class="col-md-6">
        <app-form-item [field]="fields.secondCouponPayDate" [focus]="secondCouponPayDateField">
          <app-date-picker [formControlName]="fields.secondCouponPayDate"
            [hasError]="hasError(fields.secondCouponPayDate)" #secondCouponPayDateField component>
          </app-date-picker>
        </app-form-item>
      </div>
    </div>
    <div class="row gy-4 mt-1">
      <div class="col-md-6">
        <app-form-item [field]="fields.recordDatesCalculationDates" [focus]="recordDatesCalculationDatesField">
          <app-multiple-day-picker [nbMonth]="1" [formControlName]="fields.recordDatesCalculationDates"
            [placeholder]="placeholder(fields.recordDatesCalculationDates) | translate"
            [hasError]="hasError(fields.recordDatesCalculationDates)" #recordDatesCalculationDatesField component>
          </app-multiple-day-picker>
        </app-form-item>
      </div>
    </div>
    <div class="row gy-4 mt-1" *ngIf="shouldDisplaySection(recordDateSection) | async | toBool">
      <div class="col-md-3">
        <app-form-item [field]="fields.recordDateOffset" [focus]="recordDateOffsetField">
          <app-number [formControlName]="fields.recordDateOffset" #recordDateOffsetField component></app-number>
        </app-form-item>
      </div>
      <div class="col-md-3">
        <app-form-item [field]="fields.recordDateCalculationMode" [focus]="recordDateCalculationModeField">
          <app-type-multiple-select [formControlName]="fields.recordDateCalculationMode" [multiple]="false" defaultValue="Business days"
            [closeOnSelect]="true" [type]="RECORD_DATE_CALC_MODE"
            [hasError]="hasError(fields.recordDateCalculationMode)"
            [placeholder]="placeholder(fields.recordDateCalculationMode) | translate" #recordDateCalculationModeField
            component>
          </app-type-multiple-select>
        </app-form-item>
      </div>
      <div class="col-md-6">
        <app-form-item [field]="fields.recordDateReference" [focus]="recordDateReferenceField">
          <app-type-multiple-select [formControlName]="fields.recordDateReference" [multiple]="false" defaultValue="end of period"
            [closeOnSelect]="true" [type]="RECORD_DATE_OFFSET_REF" [hasError]="hasError(fields.recordDateReference)"
            [placeholder]="placeholder(fields.recordDateReference) | translate" #recordDateReferenceField component>
          </app-type-multiple-select>
        </app-form-item>
      </div>
    </div>
    <hr class="mb-5 mt-2" />
  </ng-container>
  <div class="row gy-4">
    <div class="col-md-6">
      <app-form-item [field]="fields.redemptionCurrencies" [focus]="redemptionCurrenciesField">
        <app-currency-multiple-select [multiple]="true"
          [placeholder]="placeholder(fields.redemptionCurrencies) | translate"
          [formControlName]="fields.redemptionCurrencies" #redemptionCurrenciesField component>
        </app-currency-multiple-select>
      </app-form-item>
    </div>
  </div>
  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item [field]="fields.perpetual" [focus]="perpetualField">
        <app-yes-no-buttons [formControlName]="fields.perpetual" #perpetualField component></app-yes-no-buttons>
      </app-form-item>
    </div>
  </div>
  <ng-container *ngIf="shouldDisplaySection(redemptionManagementSection) | async | toBool">
    <div class="row gy-4 mt-1">
      <div class="col-md-6">
        <app-form-item [field]="fields.maturityDate" [focus]="maturityDateField">
          <app-date-picker [formControlName]="fields.maturityDate" [hasError]="hasError(fields.maturityDate)"
            #maturityDateField [placeholder]="placeholder(fields.maturityDate) | translate" component></app-date-picker>
        </app-form-item>
      </div>
    </div>
    <div class="row gy-4 mt-1">
      <div class="col-md-6">
        <app-form-item [field]="fields.redemptionValuationDate" [focus]="redemptionValuationDateField">
          <app-date-picker [formControlName]="fields.redemptionValuationDate"
            [hasError]="hasError(fields.redemptionValuationDate)"
            [placeholder]="placeholder(fields.redemptionValuationDate) | translate" #redemptionValuationDateField
            component>
          </app-date-picker>
        </app-form-item>
      </div>
      <div class="col-md-6">
        <app-form-item [field]="fields.redeemableInSecurities" [focus]="redeemableInSecuritiesField">
          <app-yes-no-buttons [formControlName]="fields.redeemableInSecurities" #redeemableInSecuritiesField component>
          </app-yes-no-buttons>
        </app-form-item>
      </div>
    </div>
    <div class="row gy-4 mt-1">
      <div class="col-md-6">
        <app-form-item [field]="fields.redemptionPrice" [focus]="redemptionPriceField" labelAddendum="label">
          <app-number innerClass="form-control" [placeholder]="placeholder(fields.redemptionPrice) | translate"
            [formControlName]="fields.redemptionPrice" [appendLabel]="redemptionPriceAppend"
            [addOnParams]="redemptionPriceTranslateParams" #redemptionPriceField component></app-number>
        </app-form-item>
      </div>
    </div>
    <div class="row gy-4 mt-1">
      <div class="col-md-6">
        <app-form-item [field]="fields.partialRedemption" [focus]="partialRedemptionField">
          <app-yes-no-buttons [formControlName]="fields.partialRedemption" #partialRedemptionField (onchange)="onPartialRedemptionChanged($event)"component>
          </app-yes-no-buttons>
        </app-form-item>
      </div>
      <div class="col-md-6">
        <app-form-item [field]="fields.partialRedemptCurrencies" [focus]="partialRedemptCurrenciesField">
          <app-currency-multiple-select [multiple]="true"
            [placeholder]="placeholder(fields.partialRedemptCurrencies) | translate"
            [formControlName]="fields.partialRedemptCurrencies" #partialRedemptCurrenciesField component>
          </app-currency-multiple-select>
        </app-form-item>
      </div>
    </div>
    <div class="row gy-4 mt-1" *ngIf="shouldDisplaySection(partialRedemptionSection) | async">
      <div class="col-md-6">
        <app-form-item [field]="fields.partialRedemptionType" [focus]="partialRedemptionTypeField">
          <app-type-multiple-select [formControlName]="fields.partialRedemptionType" [multiple]="false"
            [closeOnSelect]="true" [type]="PARTIAL_REDEMPTION_TYPE" [hasError]="hasError(fields.partialRedemptionType)"
            [defaultValue]="partialRedemptionTypeDefaultValue"
            [placeholder]="placeholder(fields.partialRedemptionType) | translate" #partialRedemptionTypeField component>
          </app-type-multiple-select>
        </app-form-item>
      </div>
      <div class="col-md-6">
        <app-form-item [field]="fields.partialRedemptionCorrelatedToCoupon"
          [focus]="partialRedemptionCorrelatedToCouponField">
          <app-yes-no-buttons [formControlName]="fields.partialRedemptionCorrelatedToCoupon"
            #partialRedemptionCorrelatedToCouponField component>
          </app-yes-no-buttons>
        </app-form-item>
      </div>
    </div>
    <hr class="mb-5 mt-2" />
  </ng-container>
  <div class="row gy-4">
    <div class="col-md-6">
      <app-form-item [field]="fields.instrFinancialCurrencies" [focus]="instrFinancialCurrenciesField">
        <app-currency-multiple-select [multiple]="true"
          [placeholder]="placeholder(fields.instrFinancialCurrencies) | translate"
          [formControlName]="fields.instrFinancialCurrencies" #instrFinancialCurrenciesField component>
        </app-currency-multiple-select>
      </app-form-item>
    </div>
    <div class="col-md-6">
      <app-form-item [field]="fields.instrFinancialPlaces" [focus]="instrFinancialPlacesField">
        <app-type-multiple-select [formControlName]="fields.instrFinancialPlaces" [multiple]="true"
          [closeOnSelect]="true" [type]="FINANCIAL_CENTER_TRADING_PLACES"
          [hasError]="hasError(fields.instrFinancialPlaces)"
          [placeholder]="placeholder(fields.instrFinancialPlaces) | translate" #instrFinancialPlacesField component>
        </app-type-multiple-select>
      </app-form-item>
    </div>
  </div>
  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item [field]="fields.fundsArrivalDelayInDay" [focus]="fundsArrivalDelayInDayField">
        <app-number innerClass="form-control" [placeholder]="placeholder(fields.fundsArrivalDelayInDay) | translate"
          [formControlName]="fields.fundsArrivalDelayInDay" #fundsArrivalDelayInDayField component></app-number>
      </app-form-item>
    </div>
    <div class="col-md-6">
      <app-form-item [field]="fields.optionExerciseStyle" [focus]="optionExerciseStyleField">
        <app-type-multiple-select [formControlName]="fields.optionExerciseStyle" [multiple]="false"
          [closeOnSelect]="true" [type]="OPTION_EXERCISE_TYPE" [hasError]="hasError(fields.optionExerciseStyle)"
          [placeholder]="placeholder(fields.optionExerciseStyle) | translate" #optionExerciseStyleField component>
        </app-type-multiple-select>
      </app-form-item>
    </div>
  </div>

  <hr class="mb-5 mt-2" />
  <div class="row gy-4">
    <div class="col-md-4">
      <app-form-item [field]="fields.putOption" [focus]="putOptionField">
        <app-yes-no-buttons [formControlName]="fields.putOption" #putOptionField component></app-yes-no-buttons>
      </app-form-item>
    </div>
    <div class="col-md-4">
      <app-form-item [field]="fields.convertible" [focus]="convertibleField">
        <app-yes-no-buttons [formControlName]="fields.convertible" #convertibleField component></app-yes-no-buttons>
      </app-form-item>
    </div>
    <div class="col-md-4">
      <app-form-item [field]="fields.partlyPaid" [focus]="partlyPaidField">
        <app-yes-no-buttons [formControlName]="fields.partlyPaid" #partlyPaidField component></app-yes-no-buttons>
      </app-form-item>
    </div>
  </div>
</div>
