import { MatrixInstrumentTypeIcsdResponseDto } from '@apis/backend/instruments';

export interface MatrixInstrumentTypeIcsdResponseModel {
  partyDesc?: string;
  leiCode?: string;
  bdrId?: string;
}

export const ToMatrixInstrumentTypeIcsdResponseModel = (dto: MatrixInstrumentTypeIcsdResponseDto): MatrixInstrumentTypeIcsdResponseModel => {
  return {
    partyDesc: dto.partyDesc,
    leiCode: dto.leiCode,
    bdrId: dto.bdrId
  };
};
