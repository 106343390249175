import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { EcashPaymentResponseDto } from '@apis/backend/events';
import { ECashPaymentsService } from './services';

@Injectable({
  providedIn: 'root'
})
export class EcashPaymentsResolve implements Resolve<EcashPaymentResponseDto | null> {
  constructor(private readonly service: ECashPaymentsService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<EcashPaymentResponseDto | null> {
    const id = route.params['id'];
    return this.service.getEcashPaymentsDetail(id).pipe(
      tap(response => {
        return response;
      }),
      catchError(() => {
        return of(null);
      })
    );
  }
}
