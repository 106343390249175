/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable, Inject } from '@angular/core';
import { ResultDoc, SearchDocsDto, DocumentResourceService, DocumentModelResourceService, DocumentVersionResourceService, Doc } from '@apis/backend/instruments';
import { IdTech, DocumentType, DocumentTypeInjectionToken } from '@documents/models/types';
import { Observable, EMPTY, of } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { map, tap, first, catchError } from 'rxjs/operators';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { DocumentFileModel, toDocumentFiles, toDocumentFile, DocumentModel, toDocumentModels, toDocumentModel, DocumentVersionModel, toDocumentVersionModels } from '@documents/models';
import { PROGRAMME_CONSTANTS } from '@programmes/configs/programme-constants';

@Injectable()
export class DocumentService {
  private readonly response = 'response';
  constructor(
    @Inject(DocumentTypeInjectionToken) private readonly documentType: DocumentType,
    private readonly toastManagerService: ToastManagerService,
    private readonly documentResourceService: DocumentResourceService,
    private readonly documentModelResourceService: DocumentModelResourceService,
    private readonly documentVersionResourceService: DocumentVersionResourceService
  ) {}

  //idTechParent refers to instrument or programme id
  public searchDocuments(idTechParent: IdTech, query?: string): Observable<DocumentFileModel[]> {
    const searchDocsDto: SearchDocsDto = { typeTech: this.documentType, idTech: idTechParent?.toString(), query: query || '' };
    return this.documentResourceService.searchDocuments(searchDocsDto, PROGRAMME_CONSTANTS.search.document.field, 'DESC', this.response).pipe(map((result: HttpResponse<ResultDoc>) => toDocumentFiles(result.body)));
  }

  public deleteDocument(document: DocumentFileModel | null | undefined, idDocument?: string | null | undefined): Promise<boolean> {
    idDocument = document?.documentVersionIdentifier?.documentId || idDocument;
    return new Promise<boolean>(res => {
      return this.documentResourceService
        .deleteDocument(idDocument ?? '', this.response)
        .pipe(
          tap((result: HttpResponse<any>) => {
            if (result) {
              this.toastManagerService.toastSuccess('toasts.documents.delete.title', 'toasts.documents.delete.message', undefined, result);
            }
          }),
          catchError(_ => {
            res(false);
            return of(EMPTY);
          }),
          first()
        )
        .subscribe(_ => res(true));
    });
  }

  public getDocumentMetadataByVersion(idDocument: string, idVersion?: string): Observable<DocumentFileModel | null> {
    return this.documentVersionResourceService.getDocumentMetadataByVersion(idDocument, idVersion ?? '', this.response).pipe(map((result: HttpResponse<Doc>) => toDocumentFile(result.body)));
  }

  public getDocumentContent(documentFile: DocumentFileModel): Observable<[Blob, string]> {
    const idVersionCall = documentFile.documentVersionIdentifier.versionId || '';
    return this.documentVersionResourceService.getDocumentContentByVersion(documentFile.documentVersionIdentifier.documentId, idVersionCall).pipe(map(file => [file, window.URL.createObjectURL(file)]));
  }

  public downloadDocumentContent(documentFile: DocumentFileModel): void {
    this.getDocumentContent(documentFile)
      .pipe(first())
      .subscribe(([file, contentUrl]) => {
        const a = document.createElement('a');
        a.href = contentUrl;
        a.target = '_blank';
        a.download = `${documentFile.metadata.documentName}.${file.type.substring(file.type.lastIndexOf('/') + 1)}`;
        a.click();
      });
  }

  public openDocumentContent(documentFile: DocumentFileModel): void {
    this.getDocumentContent(documentFile)
      .pipe(first())
      .subscribe(([_, contentUrl]) => {
        window.open(contentUrl, '_blank')?.focus();
      });
  }

  public getAllModels(): Observable<DocumentModel[]> {
    return this.documentModelResourceService.getAllModels().pipe(map(result => toDocumentModels(result)));
  }
  public getModel(id: string): Observable<DocumentModel | null> {
    return this.documentModelResourceService.getModel(id, this.response).pipe(map(result => toDocumentModel(result.body)));
  }

  public getAllDocumentVersions(idDocument: string): Observable<DocumentVersionModel[]> {
    return this.documentVersionResourceService.getAllDocumentVersions(idDocument, this.response).pipe(map(result => toDocumentVersionModels(result.body)));
  }
}
