<div class="row mt-2">
  <div class="col-md-12">
    <div *ngIf="isConsult; else edit_mode">
      <app-permanent-monitoring-consult
        [permanentMonitoringItems]="formService.rawValue('permanentMonitoringItems')"></app-permanent-monitoring-consult>
    </div>
    <ng-template #edit_mode>
      <app-permanent-monitoring-edit
        [permanentMonitoringItems]="formService.rawValue('permanentMonitoringItems')"></app-permanent-monitoring-edit>
    </ng-template>
  </div>
</div>
