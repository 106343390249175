import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith, shareReplay } from 'rxjs/operators';

import { AbstractControl, AbstractControlOptions, UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';

import { FieldInitialiser, FieldsNames, IFormService, FormEventOptions } from './form.models';
import { Forms } from '@shared/utils';
import { arrayDistinct } from '@utils/utility-functions';

export abstract class FormService<T, N extends string = string> implements IFormService<T> {
  private readonly internalFormGroup: UntypedFormGroup;
  private readonly fieldNames: FieldsNames<T>;
  private temporarlylockedFields: (keyof T)[] = [];
  private permantentLockedFields: (keyof T)[] = [];
  private readonly resetValue: Nullable<T>;
  private initialValue: Nullable<T>;
  private readonly internalFormMode = new BehaviorSubject<Forms.FormMode>('add');

  constructor(private readonly internalName: N, fb: UntypedFormBuilder, groupDefinition: FieldInitialiser<T>, options?: AbstractControlOptions | null | undefined) {
    this.internalFormGroup = fb.group(groupDefinition, options);
    this.fieldNames = Object.keys(groupDefinition).reduce((p, c) => ({ ...p, [c]: c }), {} as FieldsNames<T>);
    this.initialValue = this.internalFormGroup.getRawValue();
    this.resetValue = this.internalFormGroup.getRawValue();
  }

  public get name(): N {
    return this.internalName;
  }

  private get lockedFields(): (keyof T)[] {
    return [...this.temporarlylockedFields, ...this.permantentLockedFields];
  }

  public get fields(): FieldsNames<T> {
    return this.fieldNames;
  }

  public get formGroup(): UntypedFormGroup {
    return this.internalFormGroup;
  }

  public get valid(): boolean {
    return !this.internalFormGroup.invalid;
  }
  public get touched(): boolean {
    return this.internalFormGroup.touched;
  }

  public get dirty(): boolean {
    return this.internalFormGroup.dirty;
  }

  public get formMode(): Forms.FormMode {
    return this.internalFormMode.value;
  }

  public get formMode$(): Observable<Forms.FormMode> {
    return this.internalFormMode.asObservable();
  }

  private removeUnwantedFields(data: any): Partial<T> {
    const fields = Object.values<keyof T>(this.fields).filter(f => f in data);
    return fields.reduce<Partial<T>>((p, c) => ({ ...p, [c]: data[c] }), {});
  }

  public get validationErrors(): { [k: string]: ValidationErrors } {
    return Object.keys(this.internalFormGroup.controls).reduce<{ [k: string]: ValidationErrors }>((prev, current) => {
      prev[current] = this.internalFormGroup.get(current)?.errors || {};
      return prev;
    }, {});
  }
  public get isFormValid(): boolean {
    return this.formGroup.valid;
  }
  public isRequired<K extends keyof T>(field: K): boolean {
    if (this.formMode === 'consult') {
      return false;
    }

    const control = this.control(field);
    if (typeof control.value === 'boolean') {
      return false;
    }

    const validator = control.validator;
    if (!validator) {
      return false;
    }
    const res = validator({} as AbstractControl);
    return 'required' in (res || {});
  }

  public setInitialValue(model: Partial<T> | null, options?: FormEventOptions): void {
    if (!model) {
      return;
    }

    const initialValue = this.removeUnwantedFields(model);
    this.patchGroup(initialValue, options);
    this.initialValue = initialValue;
  }

  public clearInitialValue(): void {
    this.initialValue = this.resetValue;
  }

  public clearField(field: keyof T, options?: FormEventOptions) {
    const initialValue = this.initialValue[field];
    if (Array.isArray(initialValue)) {
      this.control(field).setValue([], options);
    } else if (typeof initialValue === 'boolean') {
      this.control(field).setValue(false, options);
    } else {
      this.control(field).setValue(null, options);
    }
  }

  public control<U extends AbstractControl = AbstractControl>(field: keyof T): U {
    return this.internalFormGroup.controls[field.toString()] as U;
  }

  public hasError(field: keyof T): boolean {
    const ctrl = this.control(field);
    return ctrl.touched && ctrl.errors !== null;
  }

  public isValid(field: keyof T): boolean {
    const ctrl = this.control(field);
    return ctrl.status === 'VALID' ? true : false;
  }

  private patchGroup(value: Partial<T>, options?: FormEventOptions): void {
    const patch = this.removeUnwantedFields(value);
    if (Object.values(patch).length > 0) {
      this.internalFormGroup.patchValue(patch, options);
    }
  }

  public patchField<K extends keyof T>(field: K, value: T[K], options?: FormEventOptions): void {
    const group: Partial<T> = {};
    group[field] = value;
    this.patchGroup(group, options);
  }
  public patch(value: Partial<T>, options?: FormEventOptions): void;
  public patch<K extends keyof T>(field: K, value: T[K] | null, options?: FormEventOptions): void;
  public patch<K extends keyof T>(param1: K | Partial<T>, param2?: T[K] | null | FormEventOptions, param3?: FormEventOptions): void {
    if (typeof param1 !== 'object') {
      this.patchField(param1, param2 as T[K], param3);
    } else {
      this.patchGroup(param1, param2 as FormEventOptions);
    }
  }

  public valueChanges(): Observable<Nullable<T>>;
  public valueChanges<K extends keyof T>(field: K): Observable<T[K] | null | undefined>;
  public valueChanges<L extends (keyof T)[]>(fields?: L): Observable<Nullable<Pick<T, L[number]>>>;
  public valueChanges<K extends keyof T, L extends (keyof T)[]>(fields?: K | L): Observable<T[K] | null | undefined | Nullable<Pick<T, L[number]>> | Nullable<T>> {
    if (!fields) {
      return this.formGroup.valueChanges.pipe(startWith(this.initialValue), shareReplay());
    }

    if (Array.isArray(fields)) {
      const valueChanges = fields.map(f => this.valueChanges(f));
      return combineLatest(valueChanges).pipe(
        map(values => {
          return fields.reduce<Pick<T, L[number]>>((p, c, i) => ({ ...p, [c]: values[i] }), {} as Pick<T, L[number]>);
        }),
        shareReplay()
      );
    }

    // startWith : prevent combineLatest to not firing if no changes have been made to the fields
    return this.control(fields).valueChanges.pipe(startWith(this.initialValue[fields]), shareReplay());
  }

  public rawValue(): Nullable<T>;
  public rawValue<K extends keyof T>(field: K): T[K] | null | undefined;
  public rawValue<L extends (keyof T)[]>(fields: L): Nullable<Pick<T, L[number]>>;
  public rawValue<K extends keyof T, L extends (keyof T)[]>(params?: K | L): T[K] | Nullable<T> | Nullable<Pick<T, L[number]>> | null | undefined {
    if (!params) {
      return this.internalFormGroup.getRawValue();
    }

    if (Array.isArray(params)) {
      return params.reduce((p, c) => {
        p[c] = this.control(c).value;
        return p;
      }, {} as Nullable<Pick<T, L[number]>>);
    }

    return this.control(params).value;
  }

  public value(): T | null;
  public value<K extends keyof T>(field: K): T[K] | null;
  public value<L extends (keyof T)[]>(fields: L): Pick<T, L[number]> | null;
  public value<K extends keyof T, L extends (keyof T)[]>(params?: K | L): T[K] | T | Pick<T, L[number]> | null {
    if (!this.valid) {
      return null;
    }

    if (!params) {
      return this.internalFormGroup.getRawValue();
    }

    if (Array.isArray(params)) {
      return params.reduce((p, c) => {
        p[c] = this.control(c).value;
        return p;
      }, {} as Pick<T, L[number]>);
    }

    return this.control(params).value;
  }

  public setFormMode(mode: Forms.FormMode): void {
    this.internalFormMode.next(mode);
    Object.keys(this.formGroup.controls).forEach(key => {
      const ctrl = this.formGroup.get(key);
      if (!ctrl) {
        return;
      }
      if (mode === 'consult') {
        ctrl.disable();
        return;
      }
      if (!this.lockedFields.some(f => f === key)) {
        ctrl.enable();
      }
    });
  }

  private updateDisableAndEnableStatusAfterLock() {
    if (this.formMode !== 'consult') {
      Object.values<keyof T>(this.fields)
        .filter(f => !this.lockedFields.includes(f))
        .forEach(f => {
          const control = this.control(f);
          if (control && control.disabled) {
            this.control(f).enable();
          }
        });
    }

    this.lockedFields.forEach(f => {
      const control = this.control(f);
      if (control && control.enabled) {
        this.control(f).disable();
      }
    });
  }

  public lockFieldsPermanently(...fields: (keyof T)[]): void {
    const lockedFields = Object.values<keyof T>(this.fieldNames).filter(f => fields.includes(f));
    this.permantentLockedFields = arrayDistinct([...this.permantentLockedFields, ...lockedFields]);
    this.updateDisableAndEnableStatusAfterLock();
  }

  public lockFields(...fields: (keyof T)[]): void {
    const myFields = Object.values<keyof T>(this.fields);
    this.temporarlylockedFields = arrayDistinct([...this.temporarlylockedFields, ...fields.filter(f => myFields.includes(f))]);
    this.updateDisableAndEnableStatusAfterLock();
  }

  public unlockFields(...fields: (keyof T)[]): void {
    const myFields = Object.values<keyof T>(this.fields);
    this.temporarlylockedFields = this.temporarlylockedFields.filter(f => !fields.includes(f) && myFields.includes(f));
    this.updateDisableAndEnableStatusAfterLock();
  }

  public lockAllFieldsExcept(...fields: (keyof T)[]): void {
    this.temporarlylockedFields = Object.values<keyof T>(this.fields).filter(f => !fields.includes(f));
    this.updateDisableAndEnableStatusAfterLock();
  }

  public unlockAllFieldsExcept(...fields: (keyof T)[]): void {
    const unlockedFields = Object.values<keyof T>(this.fields).filter(f => !fields.includes(f));
    this.temporarlylockedFields = this.temporarlylockedFields.filter(f => !unlockedFields.includes(f));
    this.updateDisableAndEnableStatusAfterLock();
  }

  public reset(options?: FormEventOptions): void;
  public reset<K extends keyof T>(field: K, options?: FormEventOptions): void;
  public reset<L extends (keyof T)[]>(fields: L, options?: FormEventOptions): void;
  public reset<K extends keyof T, L extends (keyof T)[]>(fields?: L | K, options?: FormEventOptions): void {
    if (fields === undefined) {
      return this.internalFormGroup.reset(this.initialValue, options);
    }
    const fieldArray: (keyof T)[] = Array.isArray(fields) ? fields : [fields];
    const myFields = Object.values<keyof T>(fieldArray).filter(f => fieldArray.includes(f));

    const resetValue = myFields.reduce<Nullable<T>>(
      (p, c) => ({
        ...p,
        [c]: this.initialValue[c]
      }),
      {}
    );
    if (Object.values(resetValue).length > 0) {
      fieldArray.forEach(f => {
        const control = this.control(f);
        if (!control) {
          return;
        }

        let value = resetValue[f];
        if (value === undefined) {
          value = null;
        }
        this.control(f).reset(value, options);
      });
    }
  }

  public updateValueAndValidity(options?: FormEventOptions): void {
    Object.keys(this.formGroup.controls).forEach(key => {
      const ctrl = this.formGroup.get(key);
      ctrl?.updateValueAndValidity(options);
      ctrl?.markAllAsTouched();
    });
    this.formGroup.updateValueAndValidity(options);
    this.formGroup.markAsTouched();
  }

  public errors(): Record<string, ValidationErrors> {
    const result: Record<string, ValidationErrors> = {};
    const globalError = this.internalFormGroup.errors;
    if (globalError && Object.values(globalError).length > 0) {
      result['global'] = globalError;
    }
    Object.values<keyof T>(this.fieldNames).forEach(field => {
      const error = this.internalFormGroup.get(field.toString())?.errors;
      if (error && Object.values(error).length > 0) {
        result[field.toString()] = error;
      }
    });

    return result;
  }
}
