<app-header containerType="container-fluid" (goBack)="goback()" [moveElementDown]="bodyContainer">
  <div headerBody *ngIf="position">
    <div class="mt-2">
      <span class="me-4 display-4" translate>mouvements.list.title</span>
    </div>
    <div class="container-fluid g-0 ps-2 mt-4 ms-2 mb-4">
      <div class="me-3 row gy-2">
        <div class="col-md-1">
          <label class="text-secondary fw-medium" translate>positions.position.positionHeader.isin</label>
          <div>
            <span class="fw-medium"> {{ position.isinReference }}</span>
          </div>
        </div>
        <div class="col-md-1">
          <label class="text-secondary fw-medium" translate>positions.position.positionHeader.ipaCode</label>
          <div>
            <span class="fw-medium">{{ position.instrumentId }}</span>
          </div>
        </div>
        <div class="col-md-2">
          <label class="text-secondary fw-medium"
            translate>positions.position.positionHeader.seriesNumber</label>
          <div>
            <span class="fw-medium">{{ position.seriesNumber }}</span>
          </div>
        </div>
        <div class="col-md-2">
          <label class="text-secondary fw-medium" translate>positions.position.positionHeader.currency</label>
          <div>
            <span class="fw-medium">{{ position.currency }}</span>
          </div>
        </div>
        <div class="col-md-2">
          <label class="text-secondary fw-medium" translate>positions.position.positionHeader.icsdName</label>
          <div>
            <span class="fw-medium">{{ position.icsdParty?.name }}</span>
          </div>
        </div>
        <div class="col-md"></div>
        <div class="col-auto">
          <app-add-operation></app-add-operation>
        </div>
      </div>
    </div>
  </div>
</app-header>
<div class="container-fluid bg-lvl2 pb-5" #bodyContainer>
  <div class="container-fluid content-margin-top">
    <app-mouvement-list [mouvements]="mouvements"></app-mouvement-list>
  </div>
</div>
