<ng-container *ngIf="!isReadOnly; else readonlyTemplate">
  <sg-picker
      #itemsPicker
      [placeholder]="displayedPlaceholder"
      (selectItem)="onSelectItem()" 
      (unselectItem)="onSelectItem()"
      (ready)="onReady()"
      (clear)="onSelectItem()"
      [singleSelect]="singleSelect"
      [summary]="withSummary"
      [maxDisplayedItems]="maxDisplayedItems"
      [icon]="icon">
    </sg-picker>
</ng-container>
<ng-template #readonlyTemplate>
  <ng-content select="[readonlyTemplate]"> </ng-content>
</ng-template>