import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customtitleCase',
  pure: true
})
export class TitleCasePipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    if (!value) {
      return '';
    }
    return value[0].toLocaleUpperCase() + value.substring(1).toLocaleLowerCase();
  }
}
