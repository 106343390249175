/**
 * issuer-ipa-events-api API
 * Manage events data
 *
 * The version of the OpenAPI document: 1.3.14
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EventInstrumentDataDto { 
    ipaCode?: string;
    issuerName?: string;
    instrumentName?: string;
    isin?: string;
    seriesNumber?: string;
}

