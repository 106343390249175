import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { GlobalNoteIndicatorRow } from './global-note-indicators-grid.model';
import { LinkCellRendererComponent } from '@shared/components/link-cell-renderer/link-cell-renderer.component';
import { QueryParameters } from '../global-note-indicators/global-note-indicators.model';
import { PagingRequestWithCriteriaModel } from '@instruments/models';

@Component({
  selector: 'app-global-note-indicators-grid-component',
  templateUrl: './global-note-indicators-grid.component.html'
})
export class GlobalNoteIndicatorsGridComponent implements OnInit {
  public columnDefs: ColDef[] | undefined = undefined;

  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 200,
    sortable: true,
    resizable: true
  };

  @Input() statusHeaderName: string | undefined = undefined;
  @Input() globalNoteIndicators: GlobalNoteIndicatorRow[] = [];
  @Output() linkClick = new EventEmitter<GlobalNoteIndicatorRow>();

  ngOnInit(): void {
    this.columnDefs = [
      {
        field: 'displayStatus',
        headerName: this.statusHeaderName,
        minWidth: 210
      },
      {
        field: 'value',
        headerName: 'Value',
        cellRendererFramework: LinkCellRendererComponent,
        cellRendererParams: {
          linkClickHandler: (rowData: GlobalNoteIndicatorRow) => this.linkClick.emit(rowData)
        }
      }
    ];
  }

  private buildQuery({ cdGnReceived, cdFtReceived }: QueryParameters): PagingRequestWithCriteriaModel {
    return {
      criteria: {
        cdGnReceived,
        cdFtReceived
      }
    };
  }
}
