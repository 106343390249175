import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OperationDetailItemModel } from '@positions/models';
import { OperationDetailEditColumnDefService } from '@positions/services/operation-detail-edit-column-def-service';
import { OperationDetailFormService } from '@positions/services/operation-detail.form.service';
import { TYPE_FILTER_TYPE, ROLES_TYPE, PartyModel } from '@shared/models';
import { GridOptions, ColDef, GridApi, CellValueChangedEvent, GridReadyEvent } from 'ag-grid-community';

@Component({
  selector: 'app-operation-items-edit',
  templateUrl: './operation-items-edit.component.html'
})
export class OperationItemsEditComponent implements OnInit {
  operationItems: OperationDetailItemModel[] | null | undefined = [];
  public gridOptions!: GridOptions;
  public columnDefs: ColDef[] = [];
  public defaultColDef: ColDef | undefined;
  public gridApi!: GridApi;
  public rowSelection: 'single' | 'multiple' = 'single';

  private parties: [TYPE_FILTER_TYPE, ROLES_TYPE, PartyModel[]][] = [];
  clearings: PartyModel[] = [];
  constructor(private readonly operationDetailColumnDefService: OperationDetailEditColumnDefService, private readonly formService: OperationDetailFormService, private readonly activeRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.parties = this.activeRoute.snapshot.data['parties'] as [TYPE_FILTER_TYPE, ROLES_TYPE, PartyModel[]][];
    this.clearings = this.getParties('OPERATION', 'CLEARING_SYSTEM');
    this.columnDefs = this.operationDetailColumnDefService.init(this.clearings);
    const operation = this.formService.rawValue();
    this.operationItems = operation.clientOperations;
    this.gridOptions = {
      detailRowAutoHeight: true,
      onGridSizeChanged: () => {
        this.gridOptions.api?.sizeColumnsToFit();
      },
      pagination: false,
      suppressPaginationPanel: false,
      paginationPageSize: 10,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      suppressContextMenu: true,
      stopEditingWhenCellsLoseFocus: true
    };
    this.defaultColDef = {
      sortable: false,
      filter: false,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true }
    };
  }
  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.refreshHeader();
  }

  onCellValueChanged(e: CellValueChangedEvent): void {
    const column = e.column.getColId() as keyof OperationDetailItemModel;
    if (column === 'csdParty') {
      const clearing = this.clearings?.find(q => q.name === (e.value.name ?? e.value));
      const item = this.operationItems?.find(q => q.id === e.data.id);
      if (clearing && item) {
        item.csdParty = clearing;
      }
    }

    this.formService.patchField('clientOperations', this.operationItems ?? []);
  }
  private getParties(filter: TYPE_FILTER_TYPE, role: ROLES_TYPE): PartyModel[] {
    return (this.parties?.find(q => q[0] === filter && q[1] === role) ?? [filter, role, []])[2] ?? [];
  }
}
