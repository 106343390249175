<div *ngIf="positions.length>0; else noData">
  <ag-grid-angular #agGrid class="ag-theme-sg-bootstrap-condensed" [gridOptions]="gridOptions!"
    [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [rowData]="positions" (gridReady)="onGridReady($event)">
  </ag-grid-angular>
</div>

<ng-template #noData>
  <div class="col-md-12">
    <app-no-data title="{{'ag-grid.title'|translate}}" subTitle="{{'ag-grid.searchSubTitle'|translate}}"></app-no-data>
  </div>
</ng-template>
