import { Component } from '@angular/core';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';
import { RegenerateSchedulesFormService } from '@events/services/regenerateSchedules.form.service';
import { RegenerateScheduleCriteriaModel } from '@events/models/regenerateScheduleCriteria.model';

@Component({
  selector: 'app-regenerate-schedules',
  templateUrl: './regenerate-schedules.component.html',
  providers: [
    { provide: FormServiceInjectionToken, useExisting: RegenerateSchedulesFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'events.form' }
  ]
})
export class RegenerateSchedulesComponent extends BaseFormComponent<RegenerateScheduleCriteriaModel, RegenerateSchedulesFormService> {
  constructor(regenerateSchedulesFormService: RegenerateSchedulesFormService) {
    super(regenerateSchedulesFormService, 'events.creation');
  }
}
