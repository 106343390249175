import { CreateScheduleEvent, CreateManualScheduleEventDto } from 'apis/backend/events';
import { TypedDataModel } from '@shared/models';

export interface EventModel {
  isin?: string;
  eventId?: number;
  valueDate?: Date;
  beginDate?: Date;
  endDate?: Date;
  notificationDate?: Date;
  reqFundDate?: Date;
  preAdvDate?: Date;
  eventType?: TypedDataModel;
}

export const toCreateManulEventDto = (model: EventModel | null | undefined): CreateManualScheduleEventDto | undefined => {
  if (!model) {
    return undefined;
  }
  return {
    isinValue: model.isin,
    eventType: Number(model.eventType?.valueId) || undefined,
    valueDate: model.valueDate ? toFilterDateString(model.valueDate) : undefined,
    beginDate: model.beginDate ? toFilterDateString(model.beginDate) : undefined,
    endDate: model.endDate ? toFilterDateString(model.endDate) : undefined,
    notificationDate: model.notificationDate ? toFilterDateString(model.notificationDate) : undefined,
    reqFundDate: model.reqFundDate ? toFilterDateString(model.reqFundDate) : undefined,
    preAdvDate: model.preAdvDate ? toFilterDateString(model.preAdvDate) : undefined
  };
};

export const toCreateEventDto = (model: EventModel, ipaCode: number): CreateScheduleEvent => {
  return {
    ipaCode: ipaCode,
    modify: false,
    automatic: false,
    createManualSchedule: toCreateManulEventDto(model)
  };
};

export const toCreateEventDetails = (eventReferenceId: number): number[] => {
  return [eventReferenceId];
};

const toFilterDateString = (date: Date): string => {
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = date.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
};
