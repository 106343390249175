import { TypedDataModel } from '@shared/models';
import { EcashPaymentRequestDto, SearchEcashPaginationDto } from '@apis/backend/events';

export interface ECashPaymentsCriteriaSearchModel {
  eventId?: number;
  eCashId?: string;
  currency?: string[];
  paymentType?: TypedDataModel;
  eCashPaymentStatus?: TypedDataModel;
  paymentE2EId?: string;
  creationFromDate?: string;
  creationToDate?: string;
  pageNumber?: number;
  itemsByPage?: number;
}

export const toSearchCriteriaInputDto = (searchCriteria: ECashPaymentsCriteriaSearchModel, pageNumber: number, itemsByPage: number): SearchEcashPaginationDto => {
  return {
    pageNumber: pageNumber,
    itemsByPage: itemsByPage,
    criteria: {
      eventId: searchCriteria.eventId ? Number(searchCriteria.eventId) : undefined,
      ecashIds: searchCriteria.eCashId ? [searchCriteria.eCashId] : undefined,
      currencies: convertToStringArray(searchCriteria.currency) || undefined,
      ecashStatusId: Number(searchCriteria.eCashPaymentStatus?.valueId) || undefined,
      messageTypeId: Number(searchCriteria.paymentType?.valueId) || undefined,
      paymentEndToEndId: searchCriteria.paymentE2EId || undefined,
      creationFromDate: searchCriteria.creationFromDate ? toDateFormat(searchCriteria.creationFromDate) : undefined,
      creationToDate: searchCriteria.creationToDate ? toDateFormat(searchCriteria.creationToDate) : undefined
    }
  };
};

export const toCsvCriteriaInputDto = (searchCriteria: ECashPaymentsCriteriaSearchModel): EcashPaymentRequestDto => {
  return {
    eventId: searchCriteria.eventId ? Number(searchCriteria.eventId) : undefined,
    ecashIds: searchCriteria.eCashId ? [searchCriteria.eCashId] : undefined,
    currencies: convertToStringArray(searchCriteria.currency) || undefined,
    ecashStatusId: Number(searchCriteria.eCashPaymentStatus?.valueId) || undefined,
    messageTypeId: Number(searchCriteria.paymentType?.valueId) || undefined,
    paymentEndToEndId: searchCriteria.paymentE2EId || undefined,
    creationFromDate: searchCriteria.creationFromDate ? toDateFormat(searchCriteria.creationFromDate) : undefined,
    creationToDate: searchCriteria.creationToDate ? toDateFormat(searchCriteria.creationToDate) : undefined
  };
};

const convertToStringArray = (event: string[] | null | undefined): string[] | undefined => {
  const arr: string[] = [];
  event?.forEach(element => {
    arr.push(element);
  });
  return arr.length > 0 ? arr : undefined;
};

const toDateFormat = (dateString: string): string => {
  const [day, month, year] = dateString.split('-');
  return `${year}-${month}-${day}`;
};
