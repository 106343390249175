<div class="modal-header">
  <h3 class="modal-title" translate> {{'instruments.instruments-reporting-access.title'}}</h3>
  <button type="button" class="close icon p-1" (click)="cancel()">close</button>
</div>
<div class="modal-body" style="height: 600px;">
  <form (ngSubmit)="searchDocuments()">
    <app-reporting-access-form [documents]="documentModels"></app-reporting-access-form>
  </form>
  <br/>
  <app-reporting-access-list [documents]="documentsResult" [isLoadingDocument]="isLoadingDocument"></app-reporting-access-list>
</div>
<div class="modal-footer">
  <button type="button" (click)="cancel()" (keyup.enter)="cancel()" class="btn btn-lg btn-link" translate>
    {{'instruments.instruments-reporting-access.close'}}</button>
</div>