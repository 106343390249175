import { Injectable } from '@angular/core';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { AgGridColumnDefBaseService } from '@shared/services/aggrid-column-def-base-service';
import { DISPOSAL_EDIT_COLUMNS } from './disposal-edit-column-def';
import { TypedDataModel } from '@shared/models';
import { ColDef } from 'ag-grid-community';
import { DisposalItemModel } from '@instruments/models/disposal.model';

@Injectable()
export class DisposalEditColumnDefService extends AgGridColumnDefBaseService {
  constructor(translateService: I18nUtilsService) {
    super(translateService, 'instruments.instrument.forms.disposal', DISPOSAL_EDIT_COLUMNS);
  }
  public init(icsd: TypedDataModel[]): ColDef[] {
    const columns = this.columnDefs;

    for (const field of columns) {
      field.headerValueGetter = this.localizeHeader.bind(this);
      if ('children' in field) {
        const children = field?.children;
        children.forEach(child => {
          child.headerValueGetter = this.localizeHeader.bind(this);
          if ('field' in child) {
            child.cellEditorParams = this.getCellEditorParams(child.field as keyof DisposalItemModel, icsd);
          }
        });
      }
    }
    return columns;
  }

  private getCellEditorParams(field: string, icsd: TypedDataModel[]): any {
    return field === 'icsd' ? { values: icsd.map(sc => sc.valueDescription) } : undefined;
  }
}
