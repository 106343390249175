import { Component, Input } from '@angular/core';
import { PermanentMonitoringItemModel } from '@instruments/models/permanent-monitoring.model';
import { ManagementCommonDepositoryFormService } from '@instruments/services/forms/managmement-common-depository.form.service';

@Component({
  selector: 'app-permanent-monitoring',
  templateUrl: './permanent-monitoring.component.html',
  providers: [ManagementCommonDepositoryFormService]
})
export class PermanentMonitoringComponent {
  @Input() permanentMonitoringItems?: PermanentMonitoringItemModel[] | null = [];

  constructor(public readonly formService: ManagementCommonDepositoryFormService) {}
  public get isConsult(): boolean {
    return this.formService.formMode === 'consult';
  }
}
