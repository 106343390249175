/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IpaTypeCreateDto } from './ipaTypeCreateDto';


export interface InstrumentIssuanceDataCreateDto { 
    syndicated?: boolean;
    tradingMethodIdentifier?: IpaTypeCreateDto;
    denomination?: string;
    numberOfShares?: string;
    nominalCurrency?: string;
    settlementCurrency?: string;
    maximumNominal?: string;
    nominal?: string;
    minimumTrading?: string;
    issuePrice?: string;
}

