/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Forms } from '@shared/utils';

const programmeBaseRoute = '/programme';
type NullableStringOrNumber = string | number | null | undefined;

export const routeToProgramme = (programmeId?: NullableStringOrNumber, formMode: Forms.FormMode = Forms.PageConsult) => {
  if (programmeId) {
    return [`${programmeBaseRoute}/${formMode}/${programmeId}`];
  } else if (formMode === 'add') {
    return [`${programmeBaseRoute}/${formMode}`];
  }

  return [programmeBaseRoute];
};
const instrumentBaseRoute = '/instrument';
export const routeToInstrument = (instrumentId?: NullableStringOrNumber, formMode = Forms.PageConsult) => {
  if (instrumentId) {
    return [`${instrumentBaseRoute}/${formMode}/${instrumentId}`];
  }

  return [instrumentBaseRoute];
};

export const routeToTranche = (instrumentId?: NullableStringOrNumber, trancheNumber?: NullableStringOrNumber, formMode = Forms.PageConsult) => {
  if (instrumentId && trancheNumber) {
    return [`${instrumentBaseRoute}/${formMode}/${instrumentId}/tranche/${trancheNumber}`];
  }

  return [instrumentBaseRoute];
};

export const routeToSettlement = (instrumentId?: NullableStringOrNumber, trancheNumber?: NullableStringOrNumber, formMode = Forms.PageConsult) => {
  if (instrumentId) {
    return [instrumentBaseRoute, formMode, instrumentId, 'settlements', trancheNumber];
  }

  return [instrumentBaseRoute];
};
export const routeToCommonDep = (instrumentId?: NullableStringOrNumber) => {
  if (instrumentId) {
    return [instrumentBaseRoute, 'consult', instrumentId, 'commonDepository'];
  }

  return [instrumentBaseRoute];
};
