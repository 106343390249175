import { forkJoin, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { PartyModel, ROLES_TYPE, TYPE_FILTER_TYPE } from '@shared/models';
import { map } from 'rxjs/operators';
import { PartyService } from '@shared/services/party.service';

@Injectable({
  providedIn: 'root'
})
export class PartiesDatasResolve implements Resolve<[TYPE_FILTER_TYPE, ROLES_TYPE, PartyModel[]][]> {
  constructor(private readonly partyService: PartyService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<[TYPE_FILTER_TYPE, ROLES_TYPE, PartyModel[]][]> {
    const roles = route.data.roles as { role: ROLES_TYPE; filter: TYPE_FILTER_TYPE }[];
    const obs = roles.map(role => {
      return this.partyService.getPartiesByRoles(role.role, role.filter).pipe(
        map(r => {
          const party: [TYPE_FILTER_TYPE, ROLES_TYPE, PartyModel[]] = [role.filter, role.role, r];
          return party;
        })
      );
    });

    return forkJoin(obs).pipe(
      map(result => {
        return result.reduce((p: [TYPE_FILTER_TYPE, ROLES_TYPE, PartyModel[]][], c) => {
          p.push(c);
          return p;
        }, []);
      })
    );
  }
}
