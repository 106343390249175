/**
 * issuer-ipa-events-extended API
 * Manage eventss data
 *
 * The version of the OpenAPI document: 1.1.15
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IpaTypedDataType } from './ipaTypedDataType';


export interface PaymentsConfirmation { 
    id?: number;
    valueDate?: string;
    currency?: string;
    amount?: number;
    settlementMode?: string;
    orderingInstitution?: string;
    beneficiary?: string;
    backOffice?: string;
    isCancelled?: boolean;
    adviceNumber?: string;
    dealIdentifier?: string;
    markReference?: string;
    isin?: string;
    eventTypeTypedDataType?: IpaTypedDataType;
    comments?: string;
    eventId?: number;
    reqofFundsId?: number;
    paymentStatusValueId?: number;
    paymentStatusTypedDataType?: IpaTypedDataType;
    paymentRejectReason?: string;
    receiptDate?: string;
    paymentRemarks?: string;
}

