import { PagingOperationDtoWithCriteriaDto, OperationCriteriaSearchDto } from '@apis/backend/instruments';
import { TypedDataModel } from '@shared/models';

export type OperationSearchArrayType = [OperationSearchCriteriaModel, OperationPagingWithCriteriaModel];
export type OperationSearchModel = OperationSearchCriteriaModel & OperationPagingWithCriteriaModel;
export type OperationSearchQueryModel = OperationSearchCriteriaModel | Pick<OperationPagingWithCriteriaModel, 'pageNumber'>;

export interface OperationSearchCriteriaModel {
  ipaCode?: string;
  isin?: string;
  seriesNumber?: string;
  operation?: TypedDataModel;
  status?: TypedDataModel;
}
export interface OperationPagingWithCriteriaModel {
  pageNumber?: number;
  itemsByPage?: number;
  totalItems?: number;
}

export const toOperationPagingRequestWithCriteriaDto = (model: OperationSearchModel | null | undefined): PagingOperationDtoWithCriteriaDto | undefined => {
  if (!model) {
    return undefined;
  }

  return {
    itemsByPage: model.itemsByPage,
    pageNumber: model.pageNumber,
    criteria: toOperationCriteriaSearchDto(model)
  };
};

export const toOperationCriteriaSearchDto = (model: OperationSearchCriteriaModel | null | undefined): OperationCriteriaSearchDto | undefined => {
  if (!model) {
    return undefined;
  }

  return {
    ipaNumber: model.ipaCode,
    isin: model.isin,
    seriesNumber: model.seriesNumber,
    operationType: model.operation?.valueId?.toString(),
    operationStatus: model.status?.valueId?.toString()
  };
};
