import { PartyDto } from '@apis/backend/instruments';
import { notEmpty } from '@utils/utility-functions';

export interface PartyModel {
  name?: string;
  leiCode?: string;
  bdrId?: string;
}

export const toPartyModel = (dto: PartyDto | null | undefined): PartyModel | null => {
  if (!dto) {
    return null;
  }

  return {
    name: dto.name,
    leiCode: dto.leiCode,
    bdrId: dto.bdrId
  };
};

export const toPartyModels = (dto: PartyDto[] | null | undefined): PartyModel[] => {
  return (dto || []).map(toPartyModel).filter(notEmpty);
};

export const toPartyDto = (model: PartyModel | null | undefined): PartyDto | undefined => {
  if (!model) {
    return undefined;
  }

  return {
    name: model.name,
    leiCode: model.leiCode,
    bdrId: model.bdrId
  };
};

export const toPartyDtos = (dto: PartyModel[] | null | undefined): PartyDto[] => {
  return (dto || []).map(toPartyDto).filter(notEmpty);
};
