import { Injectable } from '@angular/core';
import { SettlementHistoryComponent } from '@instruments/components/settlement-history/settlement-history.component';
import { SettlementModel } from '@instruments/models/settlement.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class SettlementHistoryModalsService {
  constructor(private readonly modalService: NgbModal) {}

  public openSettlementHistoryModal(settlement?: SettlementModel | null): NgbModalRef {
    const modal = this.modalService.open(SettlementHistoryComponent, { size: 'lg', scrollable: true });
    const dialog: SettlementHistoryComponent = modal.componentInstance;
    dialog.settlement = settlement;
    return modal;
  }
}
