<div class="modal-header">
  <h3 class="modal-title" translate> {{'instruments.confirmations'}}</h3>
  <button type="button" class="close icon p-1" (click)="cancel()">close</button>
</div>
<div class="modal-body">
  <div *ngIf="fileUploaded as value; else elseBlock">
   
      <div class="bg-lvl2 py-4 d-flex align-items-center">
        <svg class="text-info px-4" height="48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.817 50">
          <g transform="translate(-878 -148)">
            <path fill="currentColor" stroke="currentColor" stroke-width="2px" d="M0,2.515V48H39.817V16.4L23.415,0H0Z"
              transform="translate(879 149)"></path>
            <g transform="translate(887 163)">
              <path fill="none" d="M0,0H24V24H0Z"></path>
              <path fill="#fff" d="M9,16.17,4.83,12,3.41,13.41,9,19,21,7,19.59,5.59Z"></path>
            </g>
          </g>
        </svg>
        <div class="ps-3 flex-grow-1 text-info">
          <div>
            <span class="fw-medium">{{file?.name}}</span><br>
            <span class="text-end">{{file?.size| fileSize:2}}</span>
          </div>
        </div>
        <div>
          <button class="btn btn-lg btn-flat-light mx-4" (click)="remove()">
            <em class="icon">close</em>
          </button>
        </div>
      </div>
   
  </div>
  <ng-template #elseBlock>
    <app-uploading-files [isMultiple]="false" [allowedExtensions]="['zip']" (fileDropped)="onFileDropped($event)"></app-uploading-files>
  </ng-template>
</div>
<div class="modal-footer">
  <button type="button" (click)="cancel()" (keyup.enter)="cancel()" class="btn btn-lg btn-link" translate>
    {{'Cancel'}}</button>
  <button ngbAutofocus type="button" [disabled]="!file" (click)="upload()" class="btn btn-lg btn-primary" translate> {{'Upload'}} </button>
</div>

