import { Component } from '@angular/core';
import { DocumentationReceptionModel } from '@instruments/models/documentation-reception.model';
import { DocumentationReceptionFormService } from '@instruments/services/forms/documentation-reception.form.service';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';

@Component({
  selector: 'app-documentation-cd-reception',
  templateUrl: './documentation-cd-reception.component.html',
  providers: [
    { provide: FormServiceInjectionToken, useExisting: DocumentationReceptionFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'instruments.instrument.forms.documentationCdReception' }
  ]
})
export class DocumentationCdReceptionComponent extends BaseFormComponent<DocumentationReceptionModel, DocumentationReceptionFormService> {
  constructor(formService: DocumentationReceptionFormService) {
    super(formService, 'instruments.instrument.placeholder.documentationCdReception');
  }
}
