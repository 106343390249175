import { environment } from 'src/environments/environment';

import { HttpRequest } from '@angular/common/http';
import { setupSGWTConnect, SGWTConnectCore } from '@sgwt/connect-angular';

export const sgwtConnect = setupSGWTConnect({
  authorization_endpoint: environment.sgConnect.authorization_endpoint,
  client_id: environment.sgConnect.client_id,
  scope: environment.sgConnect.scope,
  acr_values: environment.sgConnect.acr_values,
  redirect_uri: environment.sgConnect.redirect_uri
});

// In order to make the <sgwt-account-center> widget work, even any other widget in need of the sgwtConnect instance,
//      we need to expose the sgwtConnect object on the window scope
window.sgwtConnect = sgwtConnect;
const isUserInfo = (req: HttpRequest<unknown>): boolean => req.url === environment.sgConnect.user_info_uri;
const isCallingApi = (req: HttpRequest<unknown>): boolean => {
  const escapeRegExp = (str: string) => str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
  const listRegex: RegExp[] = [];
  environment.sgsEnvProperties.apis_protocol.forEach(protocol => {
    environment.sgsEnvProperties.apis_host.forEach(host => {
      const regExp = new RegExp(`^${escapeRegExp(protocol)}?://${escapeRegExp(host)}?/`);
      listRegex.push(regExp);
    });
  });
  let isMatching = false;
  listRegex.forEach(reg => {
    if (reg.test(req.url)) {
      isMatching = true;
    }
  });
  return isMatching;
};

export const sgwtPredicate = (req: HttpRequest<unknown>): boolean => isUserInfo(req) || isCallingApi(req);
export const sgwtConnectFactory = (): SGWTConnectCore => sgwtConnect;
