import { SGSEnvProperties, Environment, SGConnectConfiguration } from './sg_configuration';

const sgsEnvProperties: SGSEnvProperties = {
  environment: '{%environment%}',
  backend_api_protocol: '{%backend_api_protocol%}',
  backend_api_host: '{%backend_api_host%}',
  event_backend_api_host: '{%event_backend_api_host%}',
  payment_backend_api_host: '{%payment_backend_api_host%}',
  apis_protocol: ['{%apis_protocol%}'],
  apis_host:  ['{%backend_api_host%}','{%position_backend_api_host%}','{%event_backend_api_host%}','{%payment_backend_api_host%}'],
  logout_action: '{%logout_action%}',
  csrfCookieName: '{%csrfCookieName%}',
  csrfHeaderName: '{%csrfHeaderName%}',
  matomo_url: '{%matomo_url%}',
  matomo_siteId: '{%matomo_siteId%}'
};

const sgConnect: SGConnectConfiguration = {
  authorization_endpoint: '{%authorization_endpoint%}',
  user_info_uri: '{%user_info_uri%}',
  client_id: '{%client_id%}',
  acr_values: '{%acr_values%}',
  scope: '{%scope%}'
};

export const environment: Environment = {
  production: JSON.parse('{%production%}'),
  applicationName: 'issuer-ipa-frontend',
  contextRoot: '/',
  languages: 'EN,FR',
  sgsEnvProperties,
  sgConnect
};
