/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-namespace */

import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

export type BooleanFn = () => boolean;

/**
 * @param predicate predicate for add validator in forms
 * @param validator validator for adding in formcontrol
 * @param errorNamespace optional argument that creates own namespace for the validation error
 */
export function conditionalValidator(pred: BooleanFn, valid: ValidatorFn, errNameSpace?: string): ValidatorFn {
  return (formControl: AbstractControl) => {
    if (!formControl.parent) {
      return null;
    }
    let error = null;
    if (pred()) {
      error = valid(formControl);
    }
    if (errNameSpace && error) {
      const customError = { [errNameSpace]: error };
      error = customError;
    }
    return error;
  };
}

export const formConsultMode = (form: UntypedFormGroup): void => {
  Object.keys(form.controls).forEach(key => {
    form.get(key)?.disable();
  });
};

export const formEditMode = (form: UntypedFormGroup): void => {
  Object.keys(form.controls).forEach(key => {
    form.get(key)?.enable();
  });
};

const AllFormModes = ['consult', 'edit', 'add'] as const;
const AllRessources = ['Programme', 'Instrument', 'Events', 'Valuation', 'Extcashaccount', 'Position'] as const;

export namespace Forms {
  export type Ressource = typeof AllRessources[number];
  export type FormMode = typeof AllFormModes[number];

  export const PageConsult: FormMode = 'consult';
  export const PageAdd: FormMode = 'add';
  export const PageEdit: FormMode = 'edit';
  export function isFormMode(value: string | null | undefined): value is FormMode {
    return AllFormModes.includes(value as any);
  }
  export function isRessource(value: string | null | undefined): value is Ressource {
    return AllRessources.includes(value as any);
  }

  export function isEditOrAdd(value: FormMode | null | undefined): value is Exclude<FormMode, 'consult'> {
    return value !== 'consult';
  }
  export function isConsult(value: FormMode | null | undefined): value is 'consult' {
    return value === 'consult';
  }

  export enum PageTypeEnum {
    edit = 'edit',
    add = 'add',
    consult = 'consult'
  }
}
