<div class="container g-0">
  <div class="row mt-2">
    <div class="col-md-12">
      <ag-grid-angular #agGrid class="ag-theme-sg-bootstrap-condensed" [gridOptions]="gridOptions!"
        (cellValueChanged)="onCellValueChanged($event)" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs"
        [rowData]="permanentMonitoringItems" (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button (click)="add()" class="btn btn-flat-info ms-3 mt-2 btn-icon-start">
        <em class="icon">add</em>
        <span class="title-case" translate>instruments.instrument.forms.permanentMonitoring.add</span>
      </button>
    </div>
  </div>
</div>
