import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderModule } from '@shared/modules/header/header.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocalCustomFormModule } from '@shared/modules/forms/local-custom-form.module';
import { TypeMultipleSelectModule } from '@shared/modules/type-multiple-select/type-multiple-select.module';
import { DatePickerModule } from '@shared/modules/date-picker/date-picker.module';
import { ButtonsModule } from '@shared/modules/buttons/buttons.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { CurrencyMultipleSelectModule } from '@shared/modules/currency-multiple-select/currency-multiple-select.module';
import { ECashPaymentsContainerComponent, ECashPaymentsListComponent, ECashPaymentsDetailComponent, ECashPaymentsActionComponent } from './components';
import { ECashPaymentsListFilterComponent } from './components/e-cash-payments-list/e-cash-payments-list-filter/e-cash-payments-list-filter.component';
import { eCashPaymentsRoutes } from './eCash-payments.routing';
import { PaginationModule } from '@shared/modules/pagination/pagination.module';

@NgModule({
  declarations: [ECashPaymentsContainerComponent, ECashPaymentsListComponent, ECashPaymentsListFilterComponent, ECashPaymentsDetailComponent, ECashPaymentsActionComponent],
  imports: [
    CommonModule,
    eCashPaymentsRoutes,
    AgGridModule.withComponents([]),
    RouterModule,
    HeaderModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    LocalCustomFormModule,
    TypeMultipleSelectModule,
    DatePickerModule,
    CurrencyMultipleSelectModule,
    ButtonsModule,
    NgbModule,
    SharedModule.forRoot(),
    PaginationModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EcashPaymentsModule {}
