import { OperationSearchCriteriaModel } from './operation-search-criteria.model';
import { OperationModel } from './operation.model';

export interface OperationPagingRequestWithCriteriaModel {
  /**
   * Requested page number.
   */
  pageNumber?: number;
  /**
   * Number of items per page.
   */
  itemsByPage?: number;
  criteria?: OperationSearchCriteriaModel | null;
}
export interface PagingResultDtoOperationDataFetchModel {
  totalItems?: number;
  totalPages?: number;
  currentPage?: number;
  items?: OperationModel[];
}
