import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchContainerWithPaginationBaseComponent } from '@shared/search-base/search-container-with-pagination-base.component';
import { PermissionService } from '@shared/services/permission.service';
import { takeUntil } from 'rxjs/operators';
import { OperationPagingWithCriteriaModel, OperationSearchCriteriaModel, OperationSearchQueryModel } from 'src/app/positions-keeping/models/operation-search-criteria.model';
import { OperationModel } from 'src/app/positions-keeping/models/operation.model';
import { OperationCriteriaSearchFormService } from 'src/app/positions-keeping/services/operation-criteria-search.form.service';
import { OperationPagingCriteriaSearchFormService } from 'src/app/positions-keeping/services/operation-paging-criteria-search.form.service';
import { OperationSearchService } from 'src/app/positions-keeping/services/operation-search.service';
import { OperationService } from 'src/app/positions-keeping/services/operation.service';
import { toOperationModelFetchModel } from '@positions/models';
@Component({
  selector: 'app-operation-management-container',
  templateUrl: './operation-management-container.component.html',
  styleUrls: ['./operation-management-container.component.scss'],
  providers: [OperationCriteriaSearchFormService, OperationPagingCriteriaSearchFormService, OperationSearchService]
})
// eslint-disable-next-line max-len, prettier/prettier
export class OperationManagementContainerComponent extends SearchContainerWithPaginationBaseComponent<
  OperationModel,
  OperationSearchCriteriaModel,
  OperationSearchQueryModel,
  OperationPagingWithCriteriaModel,
  OperationPagingCriteriaSearchFormService,
  OperationCriteriaSearchFormService,
  OperationSearchService
> {
  public canReadMultipleOperation: boolean | undefined;
  public disableSearch = false;

  constructor(
    private readonly operationsService: OperationService,
    criteriaSearchFormService: OperationCriteriaSearchFormService,
    pagingRequestWithCriteriaFormService: OperationPagingCriteriaSearchFormService,
    permissionService: PermissionService,
    activatedRoute: ActivatedRoute,
    searchFormService: OperationSearchService,
    router: Router
  ) {
    super(pagingRequestWithCriteriaFormService, criteriaSearchFormService, permissionService, searchFormService, activatedRoute, router, 20, '/position/operations');
  }

  protected load(): void {
    const searchParams = this.pagingFormService.value();
    if (!searchParams || !this.searchCriteria) {
      return;
    }
    this.disableSearch = true;
    this.operationsService.setDisableNewOperation(this.disableSearch);
    this.operationsService
      .searchPaginatedOperations({
        ...this.searchCriteria,
        itemsByPage: searchParams?.itemsByPage,
        pageNumber: searchParams?.pageNumber ? searchParams?.pageNumber : 1
      })
      .pipe(takeUntil(this.shutdown$))
      .subscribe(
        result => {
          this.disableSearch = false;
          this.operationsService.setDisableNewOperation(this.disableSearch);
          this.pagingFormService.patch('totalItems', result?.totalItems);
          this.models = Array.from(result?.items || []).map(i => toOperationModelFetchModel(i));
        },
        error => {
          if (error) {
            this.disableSearch = false;
            this.operationsService.setDisableNewOperation(this.disableSearch);
          }
        }
      );
  }

  protected initPermissions(): void {
    this.permissionService.canReadMultipleOperation$.pipe(takeUntil(this.shutdown$)).subscribe({
      next: (canReadMultipleOperation: boolean) => {
        this.canReadMultipleOperation = canReadMultipleOperation;
      }
    });
  }
}
