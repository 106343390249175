import { Injectable } from '@angular/core';
import { ProgrammeSearchArrayType } from '@programmes/models/progamme-paging-criteria.model';
import { UnionFormService } from '@shared/services/forms/union.form.service';
import { ProgrammeCriteriaSearchFormService } from './programme-criteria-search.form.service';
import { ProgrammePagingCriteriaSearchFormService } from './programme-paging-criteria-search.form.service';

@Injectable()
export class ProgrammeUnionSearchService extends UnionFormService<ProgrammeSearchArrayType> {
  constructor(criteriaSearchFormService: ProgrammeCriteriaSearchFormService, pagingCriteriaFormService: ProgrammePagingCriteriaSearchFormService) {
    super('programme-search', criteriaSearchFormService, pagingCriteriaFormService);
  }
}
