import { DocVersion, ResultDocVersion } from '@apis/backend/instruments';
import { stringToDate, notEmpty } from '@utils/utility-functions';

export interface DocumentVersionModel {
  idDocument: string;
  idVersion: string | null;
  modelId: string | null;
  creationDate: Date | null;
}

export const toDocumentVersionModel = (dto: DocVersion | null | undefined): DocumentVersionModel | null => {
  if (!dto) {
    return null;
  }

  return {
    idDocument: dto.idDocument ?? '',
    idVersion: dto.idVersion || null,
    modelId: dto.modelId || null,
    creationDate: stringToDate(dto.creationDate)
  };
};

export const toDocumentVersionModels = (dto: ResultDocVersion | null | undefined): DocumentVersionModel[] => {
  if (!dto) {
    return [];
  }

  return (dto.resources || []).map(toDocumentVersionModel).filter(notEmpty);
};
