import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, Input } from '@angular/core';
import { PROGRAMME_CONSTANTS } from '@programmes/configs/programme-constants';
import { ProgrammeCriteriaSearchModel } from '@programmes/models/progamme-paging-criteria.model';
import { ProgrammeCriteriaSearchFormService } from '@programmes/services/programme-criteria-search.form.service';

import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { ActiveFilterItem } from '@shared/models';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { ProgrammeService } from '@shared/services/programme.service';
import { downloadFile } from '@utils/utility-functions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-programme-list-filter',
  templateUrl: './programme-list-filter.component.html'
})
export class ProgrammeListFilterComponent extends BaseFormComponent<ProgrammeCriteriaSearchModel, ProgrammeCriteriaSearchFormService> implements AfterContentChecked, OnDestroy {
  @Input() disableExportCsv = false;
  public isCollapsed = true;
  public nullValue = null;
  public filters: ActiveFilterItem<ProgrammeCriteriaSearchModel>[] = [];
  public statuses: string[] = PROGRAMME_CONSTANTS.statuses;
  private readonly $shutdown = new Subject<void>();
  public get hasFilters(): boolean {
    return this.filters.some(_ => true);
  }

  constructor(private readonly programmeService: ProgrammeService, readonly criteriaSearchFormService: ProgrammeCriteriaSearchFormService, public readonly i18nUtils: I18nUtilsService, private readonly changeDetector: ChangeDetectorRef) {
    super(criteriaSearchFormService, 'programmes.list.filters');
  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  exportCsv(): void {
    const criteria = this.criteriaSearchFormService.value();
    if (!criteria) {
      return;
    }
    this.programmeService
      .exportProgrammes(criteria)
      .pipe(takeUntil(this.$shutdown))
      .subscribe(blob => {
        if (!blob) {
          return;
        }
        downloadFile(blob, 'programmes.csv');
      });
  }
  public get canExport(): boolean {
    return Object.keys(this.formService.fields)
      .map((_, index, array) => {
        const item = array[index];
        return this.formService.control(item as keyof ProgrammeCriteriaSearchModel);
      })
      .some(q => q.value);
  }
  public onFiltersChanged(filters: ActiveFilterItem<ProgrammeCriteriaSearchModel>[]): void {
    this.filters = [...filters];
  }
  ngOnDestroy(): void {
    this.$shutdown.next();
  }
}
