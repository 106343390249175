import { NumericEditorComponent } from '@instruments/components/instrument-list/_aggrid/custom-cell-editor/numeric-cell.component';
import { IcsdFormatComponent } from '@shared/components/icsd-format-cell/icsd-format-cell.component';
import { ColDef } from 'ag-grid-community/dist/lib/main';
import { OperationDetailItemModel } from '../models/operation-detail-item.model';

export interface OperationDetailColumnDef extends ColDef {
  field: keyof OperationDetailItemModel;
}

export const OPERATION_DETAIL_EDIT_COLUMNS: OperationDetailColumnDef[] = [
  {
    field: 'csdParty',
    editable: true,
    resizable: true,
    singleClickEdit: true,
    cellEditor: 'agSelectCellEditor',
    cellRenderer: (data: any) => data?.value?.name ?? data?.value
  },
  {
    field: 'depositoryParty',
    editable: false,
    resizable: true,
    cellEditor: 'agSelectCellEditor',
    cellRenderer: (data: any) => data?.value?.name ?? data?.value
  },
  {
    field: 'direction',
    cellEditor: 'agSelectCellEditor',
    cellRenderer: (data: any) => data?.value?.name ?? data?.value,
    singleClickEdit: true,
    editable: true,
    resizable: true
  },
  {
    field: 'value',
    cellEditorFramework: NumericEditorComponent,
    editable: true,
    resizable: true
  },
  {
    field: 'semeReference',
    sortable: true,
    editable: true,
    resizable: true
  },
  {
    field: 'sendSwifts',
    singleClickEdit: true,
    cellEditorSelector: () => {
      return {
        component: 'agSelectCellEditor',
        params: { values: [true, false] }
      };
    },
    sortable: true,
    editable: true,
    resizable: true
  },
  {
    field: 'accountNumber',
    cellEditorFramework: IcsdFormatComponent,
    sortable: true,
    editable: true,
    resizable: true,
    singleClickEdit: true
  }
];
