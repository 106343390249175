<div class="section-forms container g-0" [formGroup]="formGroup">
    <div class="row gy-4 mt-1">
      <div class="col-md-6">
        <app-form-item [field]="fields.isin" [focus]="isin">
          <input class="form-control" type="text" [formControlName]="fields.isin" 
          (keyup.enter)="getInstrumentDetails()" (blur)="getInstrumentDetails()"
            [placeholder]="placeholder(fields.isin) | translate" component #isin />
        </app-form-item>
      </div>
      <div  class="col-md-6">
        <app-form-item [field]="fields.notificationDate" [focus]="notificationDate">
          <app-date-picker [fixedPosition]="true" [formControlName]="fields.notificationDate" #notificationDate component (onchange)="dateModificationAlert()">
          </app-date-picker >
        </app-form-item>
      </div>
    </div>
    <div class="row gy-4 mt-1">
      <div class="col-md-6">
        <app-form-item [field]="fields.eventType" [focus]="eventType">
        <app-type-multiple-select #eventType component [formControlName]="fields.eventType"
              [multiple]="false" [closeOnSelect]="true" [type]="EVENT_TYPE"
              [hasError]="hasError(fields.eventType)"
              styleClass="form-control-lg"
              (onchange)="displayBeginEndDate();creationAlert()"
              placeholder="{{ placeholder(fields.eventType)  | translate }}">
            </app-type-multiple-select>
          </app-form-item>
          <div class="error-message" *ngIf=displayIntrErrorMessage>
            <span translate>events.creation.intrCreation</span>
          </div>
          <div class="error-message" *ngIf=displayPredAndPcalErrorMessage>
            <span translate>events.creation.predAndPcalCreation</span>
          </div>
      </div>
      <div class="col-md-6">
        <app-form-item [field]="fields.reqFundDate" [focus]="reqFundDate">
          <app-date-picker [fixedPosition]="true"  [formControlName]="fields.reqFundDate" #reqFundDate component (onchange)="dateModificationAlert()">
          </app-date-picker >
        </app-form-item>
        <div class="error-message" *ngIf=reqFundsDateModificationAlert>
          <span translate>events.creation.reqFundsDateModificationAlert</span>
        </div>
      </div>
    </div>
    <div class="row gy-4 mt-1">
      <div class="col-md-6">
        <app-form-item  [field]="fields.valueDate" [focus]="valueDate">
          <app-date-picker [fixedPosition]="true" [formControlName]="fields.valueDate" (onchange)="creationAlert();dateModificationAlert();" #valueDate component></app-date-picker >
        </app-form-item>
        <div class="error-message" *ngIf=displayRedmAndMcalErrorMessage>
          <span translate>events.creation.redmAndMcalCreation</span>
        </div>
        <div class="error-message" *ngIf=displayValueDateErrorMessage>
          <span translate>events.creation.valueDateMaturityError</span>
        </div>
        <div class="error-message" *ngIf=displayIssueDateErrorMessage>
          <span translate>events.creation.valueDateIssueDateError</span>
        </div>
        <div class="error-message" *ngIf=valueDateModificationAlert>
          <span translate>events.creation.valueDateInterventionError</span>
        </div>
      </div>
      <div class="col-md-6">
        <app-form-item [field]="fields.preAdvDate" [focus]="preAdvDate">
          <app-date-picker [fixedPosition]="true" [formControlName]="fields.preAdvDate" #preAdvDate component (onchange)="dateModificationAlert()">
          </app-date-picker >
        </app-form-item>
        <div class="error-message" *ngIf=preAdviseReqOfFundModificationAlert>
          <span translate>events.creation.preAdviseReqOfFundModificationAlert</span>
        </div>
        <div class="error-message" *ngIf=preAdviseNotificationModificationAlert>
          <span translate>events.creation.preAdviseNotificationModificationAlert</span>
        </div>
      </div>
    </div>
    <div *ngIf=displayDate class="row gy-4 mt-1">
      <div class="col-md-6">
        <app-form-item [field]="fields.beginDate" [focus]="beginDate">
          <app-date-picker [fixedPosition]="true" [formControlName]="fields.beginDate" #beginDate component (onchange)="beginEndDateModificationAlert()"></app-date-picker >
        </app-form-item>
        <div class="error-message" *ngIf=displayBeginEndDateErrorMessage>
          <span translate>events.creation.beginEndDateModificationAlert</span>
        </div>
      </div>
      <div class="col-md-6">
        <app-form-item [field]="fields.endDate" [focus]="endDate">
          <app-date-picker [fixedPosition]="true" [formControlName]="fields.endDate" #endDate component (onchange)="beginEndDateModificationAlert()">
          </app-date-picker >
        </app-form-item>
        <div class="error-message" *ngIf=displayBeginEndDateErrorMessage>
          <span translate>events.creation.beginEndDateModificationAlert</span>
        </div>
      </div>
    </div>
    </div>
    