import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormService } from '@shared/services/forms/form.service';
import { DocumentationReceptionModel } from '@instruments/models/documentation-reception.model';
import { CommonDepositoriesForms } from '@instruments/models/management-common-depository.model';

@Injectable()
export class DocumentationReceptionFormService extends FormService<DocumentationReceptionModel, CommonDepositoriesForms> {
  constructor(fb: UntypedFormBuilder) {
    super('documentation_reception', fb, {
      id: fb.control(undefined),
      instrumentId: fb.control(undefined),
      ftReceived: fb.control(false),
      gnReceived: fb.control(false),
      comment: fb.control(null)
    });
  }
}
