/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ProgammeActionComponent } from '@programmes/components/programme-action/programme-action.component';
import { ProgrammeFetchModel } from '@programmes/models/programme-fetch.model';
import { CellStatusComponent } from '@shared/components/cell-status/cell-status.component';
import { MOMENT_DATE_FORMAT } from '@shared/pipes/date-format/common';
import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community/dist/lib/main';
import * as moment from 'moment';

export interface ProgrammeColumnDef extends ColDef {
  field: keyof (ProgrammeFetchModel & PositionColumnsAction);
}
interface PositionColumnsAction {
  actions: string;
}
export const PROGRAMMES_COLUMNS: ProgrammeColumnDef[] = [
  {
    field: 'issuerName',
    resizable: true
  },
  {
    field: 'icsdProgrammeNumber',
    width: 70
  },
  {
    headerName: 'Programme Name',
    field: 'programmeName',
    resizable: true
  },
  {
    headerName: 'Issuer Reference',
    field: 'issuerReference',
    headerTooltip: 'BDRID on maestro referential',
    width: 75
  },
  {
    headerName: 'CSSF programme identifier date',
    field: 'cssfProgrammeIdentifierDate',
    valueFormatter: (q: ValueFormatterParams) => {
      if (!q.data.cssfProgrammeIdentifierDate) {
        return '';
      }
      const momentDate = moment(q.data.cssfProgrammeIdentifierDate);
      return momentDate.format(MOMENT_DATE_FORMAT);
    },
    valueGetter: (q: ValueGetterParams) => {
      if (!q.data.cssfProgrammeIdentifierDate) {
        return null;
      }
      const momentDate = moment(q.data.cssfProgrammeIdentifierDate);
      return momentDate.format(MOMENT_DATE_FORMAT);
    }
  },
  {
    headerName: 'Status',
    cellRendererFramework: CellStatusComponent,
    field: 'status',
    width: 65
  },
  {
    field: 'actions',
    cellRendererFramework: ProgammeActionComponent,
    width: 65
  }
];
