/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InstrumentGlobalNoteDto } from './instrumentGlobalNoteDto';
import { InstrumentIssuanceDataDto } from './instrumentIssuanceDataDto';
import { InstrumentListingDataDto } from './instrumentListingDataDto';
import { InstrumentRoleDataDto } from './instrumentRoleDataDto';
import { InstrumentEventsDataDto } from './instrumentEventsDataDto';
import { IpaTypeDto } from './ipaTypeDto';
import { InstrumentTrancheDto } from './instrumentTrancheDto';
import { InstrumentCharacteristicsDataDto } from './instrumentCharacteristicsDataDto';


export interface InstrumentDto { 
    id?: number;
    idWorkflow?: string;
    status?: IpaTypeDto;
    lastUpdateDateTimeAsString?: string;
    instrumentCharacteristicsData?: InstrumentCharacteristicsDataDto;
    instrumentIssuanceData?: InstrumentIssuanceDataDto;
    instrumentGlobalNote?: InstrumentGlobalNoteDto;
    instrumentListingData?: InstrumentListingDataDto;
    instrumentEventsData?: InstrumentEventsDataDto;
    instrumentRoleData?: InstrumentRoleDataDto;
    instrumentTranches?: Set<InstrumentTrancheDto>;
    isInternalIssuer?: boolean;
}

