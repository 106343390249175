import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { EventsDataModel } from '@instruments/models';
import { EventDataFormSection, EventsDataDisplayService, EventsDataFormService, IssuanceFormService } from '@instruments/services';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { DisplayServiceInjectionToken, FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';
import { TYPED_DATA_TYPE } from '@shared/models';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { delay, takeWhile } from 'rxjs/operators';
import { IpaTypeModelRules as R } from '@instruments/rules';
import { INSTRUMENT_CONSTANTS } from '@instruments/configs/instrument-constants';
import { VALUATION_CONSTANTS } from '@valuation/configs/valuation-constants';
@Component({
  selector: 'app-instrument-events-data',
  templateUrl: './instrument-events-data.component.html',
  providers: [
    { provide: FormServiceInjectionToken, useExisting: EventsDataFormService },
    { provide: DisplayServiceInjectionToken, useExisting: EventsDataDisplayService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'instruments.instrument.forms.instrumentEventsData' }
  ]
})
export class InstrumentEventsDataComponent extends BaseFormComponent<EventsDataModel, EventsDataFormService> implements OnInit, OnDestroy {
  public readonly COUPON_TYPE: TYPED_DATA_TYPE = 'COUPON_TYPE';
  public readonly SECOND_COUPON_TYPE: TYPED_DATA_TYPE = 'SECOND_COUPON_TYPE';
  public readonly FINANCIAL_CENTER_TRADING_PLACES: TYPED_DATA_TYPE = 'FINANCIAL_CENTER_TRADING_PLACES';
  public readonly BUSINESS_DAY_CONVENTION: TYPED_DATA_TYPE = 'BUSINESS_DAY_CONVENTION';
  public readonly RECORD_DATE_CALC_MODE: TYPED_DATA_TYPE = 'RECORD_DATE_CALC_MODE';
  public readonly RECORD_DATE_OFFSET_REF: TYPED_DATA_TYPE = 'RECORD_DATE_OFFSET_REF';
  public readonly CALCULATION_TIME: TYPED_DATA_TYPE = 'CALCULATION_TIME';
  public readonly PARTIAL_REDEMPTION_TYPE: TYPED_DATA_TYPE = 'PARTIAL_REDEMPTION_TYPE';
  public readonly OPTION_EXERCISE_TYPE: TYPED_DATA_TYPE = 'OPTION_EXERCISE_TYPE';
  public readonly PAYMENT_DATE_MGT_MODE: TYPED_DATA_TYPE = 'PAYMENT_DATE_MGT_MODE';
  public readonly COUPON_FREQUENCY: TYPED_DATA_TYPE = 'COUPON_FREQUENCY';
  public readonly DAY_COUNT_FRACTION: TYPED_DATA_TYPE = 'DAY_COUNT_FRACTION';
  public readonly SECOND_DAY_COUNT_FRACTION: TYPED_DATA_TYPE = 'SECOND_DAY_COUNT_FRACTION';

  firstCouponTypeSection = EventDataFormSection.firstCouponTypeFields;
  secondCouponTypeSection = EventDataFormSection.secondCouponTypeFields;
  recordDateSection = EventDataFormSection.recordDateFields;
  correlatedSection = EventDataFormSection.correlatedFields;
  decorrelatedSection = EventDataFormSection.decorrelatedFields;
  redemptionManagementSection = EventDataFormSection.redemptionManagementFields;
  partialRedemptionSection = EventDataFormSection.partialRedemptionFields;

  private subscriptions: Subscription[] = [];
  partialRedemptionTypeDefaultValue: string | null = null;

  constructor(
    readonly formService: EventsDataFormService,
    private readonly displayService: EventsDataDisplayService,
    private readonly issuanceFormService: IssuanceFormService,
    @Inject(LabelRootTranslateInjectionToken) private readonly labelRoot: string
  ) {
    super(formService, 'instruments.instrument.placeholder.instrumentEventsData');
  }
  public get shouldDisplay() {
    return this.displayService.shouldDisplay.bind(this.displayService);
  }

  public shouldDisplaySection(section: string): Observable<boolean> {
    return this.displayService.shouldDisplaySection(section);
  }

  public get redemptionPriceAppend() {
    if (R.isTradingMethodNominal(this.issuanceFormService.rawValue('tradingMethodIdentifier'))) {
      return `${this.labelRoot}.${this.fields.redemptionPrice}.nominal`;
    }
    return `${this.labelRoot}.${this.fields.redemptionPrice}.unit`;
  }

  public get redemptionPriceTranslateParams(): Record<string, unknown> {
    return {
      nominalCurrency: this.issuanceFormService.rawValue('nominalCurrency') || ''
    };
  }

  public get adjustedLabel(): string {
    return `${this.labelRoot}.${this.fields.endDateAdjusted}.adjusted`;
  }

  public get unadjustedLabel(): string {
    return `${this.labelRoot}.${this.fields.endDateAdjusted}.unadjusted`;
  }

  public get correlatedLabel(): string {
    return `${this.labelRoot}.${this.fields.paymentDateCorrelated}.correlated`;
  }

  public get decorrelatedLabel(): string {
    return `${this.labelRoot}.${this.fields.paymentDateCorrelated}.decorrelated`;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.issuanceFormService
        .valueChanges('settlementCurrency')
        .pipe(takeWhile(_ => this.formService.formMode === 'add'))
        .subscribe(currency => {
          if (this.formService.formMode === 'add') {
            this.setDefaultCurrencies(currency);
          }
        })
    );

    const displayObservables = this.displayService.Compile();
    this.subscriptions = displayObservables.map(o => o.subscribe());

    this.subscriptions.push(
      combineLatest([this.formService.valueChanges('issueDate'), this.displayService.shouldDisplay('interestCommencementDate')])
        .pipe(
          delay(5) //For some reason "subscribe arrives before the tap reset in handleValidators"
        )
        .subscribe(([issueDate, isVisible]) => {
          if (!isVisible) {
            this.formService.patch('interestCommencementDate', null);
            return;
          }
          const interestCommencementDate = this.formService.rawValue('interestCommencementDate');
          if (!interestCommencementDate) {
            this.formService.patch('interestCommencementDate', issueDate || null);
          }
        })
    );
  }

  setDefaultCurrencies(currency: string | null | undefined): void {
    if (currency) {
      const value = this.formService.rawValue();
      if (value.couponType?.valueDescription && value.couponType?.valueDescription !== INSTRUMENT_CONSTANTS.Types.couponsTypes.ZEROCOUPON) {
        this.formService.patch('couponPaymentCurrencies', [currency]);
      }
      this.formService.patch('redemptionCurrencies', [currency]);
      if (value.partialRedemption) {
        this.formService.patch('partialRedemptCurrencies', [currency]);
      }
    }
  }

  onPartialRedemptionChanged(display: boolean): void {
    if (this.formService.formMode !== 'consult') {
      this.setDefaultValuesToPartialTypeAndCorrelatedToCoupon(display);
    }
    if (this.formService.formMode === 'add') {
      const value = this.issuanceFormService.rawValue();
      if (display) {
        if (value.settlementCurrency) {
          this.formService.patch('partialRedemptCurrencies', [value.settlementCurrency]);
        }
      }
    }
  }

  setDefaultValuesToPartialTypeAndCorrelatedToCoupon(display: boolean): void {
    if (display) {
      this.partialRedemptionTypeDefaultValue = VALUATION_CONSTANTS.eventTypes.PRED;
      this.formService.patch('partialRedemptionCorrelatedToCoupon', true);
      return;
    }
    this.partialRedemptionTypeDefaultValue = null;
  }

  onDisplayChanged(field: keyof EventsDataModel, display: boolean): void {
    if (this.formService.formMode === 'add') {
      const value = this.issuanceFormService.rawValue();
      if (field === 'couponPaymentCurrencies' && display) {
        if (value.settlementCurrency) {
          this.formService.patch('couponPaymentCurrencies', [value.settlementCurrency]);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
