/* eslint-disable no-console */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { InstrumentFormService } from '@instruments/services';
import { TrancheModel } from '@instruments/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-instrument-settlement-values',
  templateUrl: './settlement-values.component.html'
})
export class InstrumentSettlementValuesComponent implements OnInit, OnDestroy {
  public nominal!: string | null;
  public nominalCurrency!: string | null;
  public settlementCurrency!: string | null;
  private readonly shutdown$: Subject<void> = new Subject<void>();

  constructor(private readonly instrumentFormService: InstrumentFormService) {}

  ngOnInit(): void {
    const instrument = this.instrumentFormService.value();

    this.instrumentFormService.currentTranche$.pipe(takeUntil(this.shutdown$)).subscribe((tranche: TrancheModel) => {
      this.nominal = this.formatedNumber(tranche?.nominal || null);
    });

    this.nominalCurrency = instrument?.nominalCurrency || null;
    this.settlementCurrency = instrument?.settlementCurrency || null;
  }

  private formatedNumber(val: string | null): string | null {
    if (val) {
      const parsedValue = parseFloat(val);
      const numberParts = parsedValue.toString().split('.');
      const integerPart = numberParts[0];
      const decimalPart = numberParts[1];

      const arrayValue = integerPart.toLocaleString().match(/.{1,3}(?=(.{3})*$)/g) || [];
      const valueFormatted = arrayValue.join(',');
      if (decimalPart) {
        return `${valueFormatted}.${decimalPart}`;
      }
      return valueFormatted;
    }
    return null;
  }

  ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}
