import { Component, Output, EventEmitter, Input } from '@angular/core';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { TYPED_DATA_TYPE, ActiveFilterItem } from '@shared/models';
import { EventCriteriaSearchModel } from '@events/models';
import { EventCriteriaSearchFormService } from '@events/services';

@Component({
  selector: 'app-event-list-filter',
  templateUrl: './event-list-filter.component.html',
  styleUrls: ['./event-list-filter.component.scss']
})
export class EventListFilterComponent extends BaseFormComponent<EventCriteriaSearchModel, EventCriteriaSearchFormService> {
  public isCollapsed = true;
  @Output() exportCSV = new EventEmitter<boolean>();
  @Input() disableExportCsv = false;
  public EVENT_TYPE: TYPED_DATA_TYPE = 'EVENT_TYPE';
  public EVENT_WORKFLOW_STATUS: TYPED_DATA_TYPE = 'EVENT_WORKFLOW_STATUS';
  public filters: ActiveFilterItem<EventCriteriaSearchModel>[] = [];
  public maxDisplayedItems = 35;

  constructor(eventCriteriaSearchFormService: EventCriteriaSearchFormService) {
    super(eventCriteriaSearchFormService, 'events.list.filters');
  }

  get notificationBeginDate(): number | null {
    const formDate = this.formService?.rawValue()?.notificationFromDate;
    if (formDate) {
      return this.getDateTime(formDate);
    }
    return null;
  }
  get notificationEndDate(): number | null {
    const formDate = this.formService?.rawValue()?.notificationToDate;
    if (formDate) {
      return this.getDateTime(formDate);
    }
    return null;
  }
  get reqFundBeginDate(): number | null {
    const formDate = this.formService?.rawValue()?.reqFundFromDate;
    if (formDate) {
      return this.getDateTime(formDate);
    }
    return null;
  }
  get reqFundEndDate(): number | null {
    const formDate = this.formService?.rawValue()?.reqFundToDate;
    if (formDate) {
      return this.getDateTime(formDate);
    }
    return null;
  }
  get preAdviceBeginDate(): number | null {
    const formDate = this.formService?.rawValue()?.preAdviceFromDate;
    if (formDate) {
      return this.getDateTime(formDate);
    }
    return null;
  }
  get preAdviceEndDate(): number | null {
    const formDate = this.formService?.rawValue()?.preAdviceToDate;
    if (formDate) {
      return this.getDateTime(formDate);
    }
    return null;
  }
  get valueBeginDate(): number | null {
    const formDate = this.formService?.rawValue()?.valueFromDate;
    if (formDate) {
      return this.getDateTime(formDate);
    }
    return null;
  }
  get valueEndDate(): number | null {
    const formDate = this.formService?.rawValue()?.valueToDate;
    if (formDate) {
      return this.getDateTime(formDate);
    }
    return null;
  }
  public get canExport(): boolean {
    return Object.keys(this.formService.fields)
      .map((_, index, array) => {
        const item = array[index];
        return this.formService.control(item as keyof EventCriteriaSearchModel);
      })
      .some(q => q.value !== null && q.value !== undefined);
  }

  private getDateTime(formDate: string) {
    const date = formDate.split('-');
    const newDate = new Date(+date[2], +date[1] - 1, +date[0]);
    return newDate.getTime();
  }

  onDatesChanged(e: any, fromDate: any, toDate: any) {
    this.formService.patch(fromDate, e?.detail?.from?.formatted);
    this.formService.control(fromDate).markAsDirty();
    this.formService.patch(toDate, e?.detail?.to?.formatted);
    this.formService.control(toDate).markAsDirty();
  }

  public onFiltersChanged(filters: ActiveFilterItem<EventCriteriaSearchModel>[]): void {
    this.filters = filters;
  }

  public exportCsv() {
    this.exportCSV.emit(true);
  }
}
