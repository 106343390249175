import { Injectable } from '@angular/core';
import { FormService } from '@shared/services/forms/form.service';
import { UntypedFormBuilder } from '@angular/forms';
import { ValuationCriteriaSearchModel } from '../models';

@Injectable()
export class ValuationCriteriaSearchFormService extends FormService<ValuationCriteriaSearchModel> {
  constructor(fb: UntypedFormBuilder) {
    super('search_valuation', fb, {
      eventRef: fb.control(null),
      isin: fb.control(null),
      valuationRef: fb.control(null),
      valuationStatus: fb.control(null),
      eventTypes: fb.control(null),
      valuationFromDate: fb.control(null),
      valuationToDate: fb.control(null),
      valueFromDate: fb.control(null),
      valueToDate: fb.control(null),
      receiptFromDate: fb.control(null),
      receiptToDate: fb.control(null),
      valuationVersion: fb.control(null),
      redemptionTypes: fb.control(null),
      valuationRejectReasons: fb.control(null),
      pageNumber: fb.control(null),
      itemsByPage: fb.control(null)
    });
  }
}
