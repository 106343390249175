import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { PaymentDetailModel, PaymentFields } from '../models';
import { FormService } from '@shared/services/forms/form.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentDetailFormService extends FormService<PaymentDetailModel> {
  constructor(fb: UntypedFormBuilder) {
    super('paymentDetail', fb, {
      paymentId: fb.control(null),
      isin: fb.control(null),
      eventType: fb.control(null),
      requestOfFundsId: fb.control(null),
      eventId: fb.control(null),
      paymentConfReceiptDate: fb.control(null),
      valueDate: fb.control(null),
      amount: fb.control(null),
      paymentCcy: fb.control(null),
      cancelled: fb.control(null),
      paymentStatus: fb.control(null),
      paymentRejectReason: fb.control(null),
      comments: fb.control(null),
      paymentReference: fb.control(null),
      settlementMode: fb.control(null),
      adviceNumber: fb.control(null),
      orderingInstitutation: fb.control(null),
      dealIdentifier: fb.control(null),
      markReference: fb.control(null),
      details: fb.control(null),
      beneficiary: fb.control(null),
      backOffice: fb.control(null)
    });
  }
  paymentLockFields() {
    const paymentField = Object.values<keyof PaymentDetailModel>(this.fields).filter(f => PaymentFields.includes(f as any));
    this.lockFieldsPermanently(...paymentField);
  }

  lockPaymentStatusField() {
    const paymentStatusField = this.fields.paymentStatus;
    this.lockFields(paymentStatusField);
  }

  unlockPaymentStatusField() {
    const paymentStatusField = this.fields.paymentStatus;
    this.unlockFields(paymentStatusField);
  }
}
