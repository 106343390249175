import { Injectable } from '@angular/core';
import { InstrumentCriteriaSearchModel, PagingWithCriteriaModel } from '@instruments/models';
import { UnionFormService } from '@shared/services/forms/union.form.service';
import { InstrumentCriteriaSearchFormService } from './forms';
import { PagingRequestWithCriteriaFormService } from './paging-request-with-criteria.form.service';

export type InstrumentSearchArrayType = [InstrumentCriteriaSearchModel, PagingWithCriteriaModel];
export type InstrumentSearchModel = InstrumentCriteriaSearchModel | PagingWithCriteriaModel;
export type InstrumentSearchQueryModel = InstrumentCriteriaSearchModel | Pick<PagingWithCriteriaModel, 'pageNumber'>;

@Injectable()
export class InstrumentSearchService extends UnionFormService<InstrumentSearchArrayType> {
  constructor(private readonly instrumentCriteriaSearchFormService: InstrumentCriteriaSearchFormService, pagingRequestWithCriteriaFormService: PagingRequestWithCriteriaFormService) {
    super('instrument-search', instrumentCriteriaSearchFormService, pagingRequestWithCriteriaFormService);
  }
  public getfields(): (keyof (InstrumentCriteriaSearchModel & PagingWithCriteriaModel))[] {
    const array = Object.keys(this.instrumentCriteriaSearchFormService.fields);
    array.push('pageNumber');
    return array as (keyof (InstrumentCriteriaSearchModel & PagingWithCriteriaModel))[];
  }
}
