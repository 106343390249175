import { Injectable } from '@angular/core';
import { FormService } from '@shared/services/forms/form.service';
import { UntypedFormBuilder } from '@angular/forms';
import { PaymentCriteriaSearchModel } from '../models';

@Injectable()
export class PaymentCriteriaSearchFormService extends FormService<PaymentCriteriaSearchModel> {
  constructor(fb: UntypedFormBuilder) {
    super('search_payment', fb, {
      isin: fb.control(null),
      eventTypes: fb.control(null),
      paymentStatus: fb.control(null),
      minAmount: fb.control(null),
      maxAmount: fb.control(null),
      currency: fb.control(null),
      fundingRequestRef: fb.control(null),
      valueFromDate: fb.control(null),
      valueToDate: fb.control(null),
      pageNumber: fb.control(null),
      itemsByPage: fb.control(null)
    });
  }
}
