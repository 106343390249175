import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { LinkCellRendererComponent } from '@shared/components/link-cell-renderer/link-cell-renderer.component';

import { IssuingSettlementIndicatorCellClickPayload, IssuingSettlementIndicatorRow, IssuingSettlementIndicatorScope } from './issuing-settlement-indicators-grid.model';

@Component({
  selector: 'app-issuing-settlement-indicators-grid',
  templateUrl: './issuing-settlement-indicators-grid.component.html'
})
export class IssuingSettlementIndicatorsGridComponent implements OnInit {
  public columnDefs: ColDef[] = [];

  public defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    resizable: true
  };

  @Input() scope: IssuingSettlementIndicatorScope = 'STA';
  @Input() statusColumnText = 'Status';

  private _issuingSettlementIndicators: IssuingSettlementIndicatorRow[] = [];
  get issuingSettlementIndicators(): IssuingSettlementIndicatorRow[] {
    return this._issuingSettlementIndicators;
  }
  @Input() set issuingSettlementIndicators(value: IssuingSettlementIndicatorRow[]) {
    this._issuingSettlementIndicators = value.filter(x => x.scope === this.scope);
  }

  @Output() linkClick = new EventEmitter<IssuingSettlementIndicatorCellClickPayload>();

  ngOnInit(): void {
    const columnDefs: ColDef[] = [
      {
        field: 'displayStatus',
        headerName: this.statusColumnText,
        minWidth: this.scope === 'SPI NOT LIVE' ? 250 : 340,
        cellRenderer: ({ data, value }) => {
          return `${value} ${!data.forAction ? ' <em class="icon text-info">info</em>' : ''}`;
        }
      },
      {
        field: 'todayCount',
        headerName: 'D',
        cellRendererFramework: LinkCellRendererComponent,
        cellRendererParams: {
          linkClickHandler: (rowData: IssuingSettlementIndicatorRow) =>
            this.linkClick.emit({
              period: 'Today',
              rowData
            })
        }
      },
      {
        field: 'todayPlusOneDayCount',
        headerName: 'D+1',
        cellRendererFramework: LinkCellRendererComponent,
        cellRendererParams: {
          linkClickHandler: (rowData: IssuingSettlementIndicatorRow) =>
            this.linkClick.emit({
              period: 'TodayPlusOneDay',
              rowData
            })
        }
      },
      {
        field: 'todayPlusTwoDaysCount',
        headerName: 'D+2',
        cellRendererFramework: LinkCellRendererComponent,
        cellRendererParams: {
          linkClickHandler: (rowData: IssuingSettlementIndicatorRow) =>
            this.linkClick.emit({
              period: 'TodayPlusTwoDays',
              rowData
            })
        }
      }
    ];

    if (this.scope === 'SPI' || this.scope === 'SPI NOT LIVE') {
      columnDefs.push({
        field: 'todayPlusThreeDaysCount',
        headerName: 'D+3',
        cellRendererFramework: LinkCellRendererComponent,
        cellRendererParams: {
          linkClickHandler: (rowData: IssuingSettlementIndicatorRow) =>
            this.linkClick.emit({
              period: 'TodayPlusThreeDays',
              rowData
            })
        }
      });
    }

    this.columnDefs = columnDefs;
  }
}
