<ng-container [ngSwitch]="format">
  <ng-container *ngSwitchCase="'default'">
    <div class="file-input file-input-md" appDragNDrop [allowedExtensions]="allowedExtensions" [isMultiple]="isMultiple" (fileDropped)="onFileDropped($event)">
      <div class="file-input-corners"></div>
      <em class="file-input-icon icon icon-xl">attach_file</em>
      <p class="file-input-text">
        Drag or
        <label for="inputfile-medium" class="btn file-input-link px-0 mb-0">select</label>
        your file to this area to upload
      </p>
      <input class="d-none" type="file" name="inputfile-medium" id="inputfile-medium" #fileDropRef [attr.accept]="accept"
          [multiple]="isMultiple" (change)="fileBrowseHandler($event)" />
      <div class="file-input-corners"></div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'large'">
    <div class="file-input file-input-lg" appDragNDrop [isMultiple]="isMultiple" (fileDropped)="onFileDropped($event)">
      <div class="file-input-corners"></div>
      <em class="file-input-icon icon icon-xl">attach_file</em>
      <h4 class="file-input-title">Add attachment</h4>
      <p class="file-input-text">
        Drag or
        <label for="inputfile-large" class="btn file-input-link px-0 mb-0">select</label>
        your file to this area to upload
      </p>
      <input class="d-none" type="file" name="inputfile-large" id="inputfile-large" #fileDropRef
          [multiple]="isMultiple" (change)="fileBrowseHandler($event)" />
      <div class="file-input-corners"></div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'small'">
    <div class="file-input file-input-sm" appDragNDrop [isMultiple]="isMultiple" (fileDropped)="onFileDropped($event)">
      <div class="file-input-corners"></div>
      <p class="file-input-text">
        Drag or
        <label for="inputfile-small1" class="btn file-input-link px-0 mb-0">select</label>
        your file to this area to upload
      </p>
      <input class="d-none" type="file" name="inputfile-small1" id="inputfile-small1" #fileDropRef
          [multiple]="isMultiple" (change)="fileBrowseHandler($event)" />
      <div class="file-input-corners"></div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'compact'">
    <div class="file-input file-input-compact" appDragNDrop [isMultiple]="isMultiple" (fileDropped)="onFileDropped($event)">
      <div class="file-input-corners"></div>
      <em class="file-input-icon icon icon-xl">attach_file</em>
      <p class="file-input-text">
        Drag or
        <label for="inputfile-compact" class="btn file-input-link px-0 mb-0">select</label>
        your file to this area
      </p>
      <input class="d-none" type="file" name="inputfile-compact" id="inputfile-compact" #fileDropRef
            [multiple]="isMultiple" (change)="fileBrowseHandler($event)" />
      <div class="file-input-corners"></div>
    </div>
  </ng-container>
</ng-container>
