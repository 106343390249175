<div class="modal-header">
    <h3 class="modal-title">{{ 'modals.titles.releasePayment'| translate}}</h3>
    <button type="button" class="close icon p-1" (click)="onClose()">close</button>
</div>
<div class="modal-body">
    <app-positions-list [positions]="positions" [positionsTotal]="positionsTotal"
        [eventPositionInput]="eventPositionInput" [hideActionsColumn]="hideActionsColumn"
        [hideTotalsGrid]="hideTotalsGrid"></app-positions-list>
    <div class="mt-2">
        <b class="modal-title">{{ 'modals.contents.releasePayment'| translate}}</b>
        <div class="row mt-2">
            <div class="form-group col-md-4">
                <b style="opacity: 0.5;">{{ 'events.form.paymentDate'| translate}}</b>
            </div>
            <div class="form-group col-md-4">
                <b style="opacity: 0.5;">{{ 'events.form.timingFundArrivalDate'| translate}}</b>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-4">
            <p> {{eventPositionInput?.paymentDate | date: 'dd MMM yyyy'}}</p>
            </div>
            <div class="form-group col-md-4">
                <p> {{eventPositionInput?.fundArrivalDate | date: 'dd MMM yyyy'}}</p>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" (click)="onCancel()" (keyup.enter)="onCancel()" class="btn btn-lg btn-link">{{ cancelButton |
        translate }}</button>
    <button ngbAutofocus type="button" (click)="onConfirm()" (keyup.enter)="onConfirm()" class="btn btn-lg btn-primary"
        translate>{{ confirmButton | translate }}</button>
</div>
