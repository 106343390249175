import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { ECashPaymentsModel } from 'src/app/eCashPayments/models';
import { ECashPaymentsActionComponent } from './e-cash-payments-action/e-cash-payments-action.component';
import * as moment from 'moment';
import { MOMENT_DATE_FORMAT } from '@shared/pipes/date-format/common';

export interface ECashPaymentsColumnDef extends ColDef {
  field: keyof (ECashPaymentsModel & ECashPaymentsColumnsAction);
}

interface ECashPaymentsColumnsAction {
  actions: string;
}

export const ECASH_PAYMENTS_COLUMNS: ECashPaymentsColumnDef[] = [
  {
    field: 'eventId',
    width: 108,
    resizable: true
  },
  {
    field: 'eCashId',
    width: 108,
    resizable: true
  },
  {
    field: 'currency',
    width: 112,
    resizable: true
  },
  {
    field: 'amount',
    width: 103,
    resizable: true
  },
  {
    field: 'paymentEndToEndId',
    width: 194,
    resizable: true
  },
  {
    field: 'identification',
    width: 137,
    resizable: true
  },
  {
    field: 'creationDate',
    valueFormatter: (q: ValueFormatterParams) => {
      if (!q.data.creationDate) {
        return '';
      }
      const momentDate = moment(q.data.creationDate);
      return momentDate.format(MOMENT_DATE_FORMAT);
    },
    width: 139,
    resizable: true
  },
  {
    field: 'paymentType',
    width: 137,
    resizable: true
  },
  {
    field: 'eCashPaymentStatus',
    width: 137,
    resizable: true
  },
  {
    field: 'actions',
    cellRendererFramework: ECashPaymentsActionComponent,
    maxWidth: 130,
    pinned: 'right'
  }
];
