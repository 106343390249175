/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input } from '@angular/core';
import { ProgrammeFetchModel } from '@programmes/models/programme-fetch.model';
import { ProgrammeColumnDefService } from '@programmes/services/programmes-column-def-service';
import { InstrumentColumnDefService } from '@instruments/components/instrument-list/_aggrid/instrument-column-def-service';
import { ProgrammePagingCriteriaModel } from '@programmes/models/progamme-paging-criteria.model';
import { ProgrammePagingCriteriaSearchFormService } from '@programmes/services/programme-paging-criteria-search.form.service';
import { ListBaseComponent } from '@shared/search-base';

@Component({
  selector: 'app-programme-list',
  templateUrl: './programme-list.component.html',
  styleUrls: ['./programme-list.component.scss'],
  providers: [ProgrammeColumnDefService]
})
export class ProgrammeListComponent extends ListBaseComponent<ProgrammePagingCriteriaModel, ProgrammePagingCriteriaSearchFormService, InstrumentColumnDefService> {
  @Input() public models: ProgrammeFetchModel[] = [];

  constructor(columnDefService: ProgrammeColumnDefService, pagingRequestWithCriteriaFormService: ProgrammePagingCriteriaSearchFormService) {
    super(columnDefService, pagingRequestWithCriteriaFormService, 20);
  }
}
