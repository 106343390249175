/**
 * issuer-ipa-events-api API
 * Manage events data
 *
 * The version of the OpenAPI document: 1.3.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreateManualScheduleEventDto } from './createManualScheduleEventDto';


/**
 * Schedule to create
 */
export interface CreateScheduleEvent { 
    issueDate?: string;
    firstInterestPaymentDate?: string;
    couponFrequency?: number;
    couponType?: string;
    ipaCode?: number;
    modify?: boolean;
    automatic?: boolean;
    createManualSchedule?: CreateManualScheduleEventDto;
}

