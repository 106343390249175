import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IdDto, PagingResultDtoOperationDataFetchDto, PositionResourceService } from '@apis/backend/instruments';

import { OperationDetailsModel, OperationSearchModel, OPERATION_ACTIONS, toOperationDetailsModel, toOperationDetailsModelDto, toOperationPagingRequestWithCriteriaDto } from '@positions/models';
import { ErrorListFromBackendService } from '@shared/modules/error-list-from-backend/service/error-list-from-backend.service';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class OperationService {
  private readonly response = 'response';
  public readonly disableNewOperation = new BehaviorSubject<boolean>(false);

  constructor(private readonly positionResourceService: PositionResourceService, private readonly toastManagerService: ToastManagerService, private readonly errorListFromBackendService: ErrorListFromBackendService) {}

  public searchPaginatedOperations(searchModel: OperationSearchModel): Observable<PagingResultDtoOperationDataFetchDto | null> {
    const dto = toOperationPagingRequestWithCriteriaDto(searchModel);

    if (!dto) {
      return of(null);
    }
    return this.positionResourceService.searchPaginatedOperations(dto);
  }

  public getOperationById(id: number): Observable<OperationDetailsModel | undefined | null> {
    if (!id) {
      return of(null);
    }
    return this.positionResourceService.getOperationById(id).pipe(map(result => toOperationDetailsModel(result)));
  }

  public save(operation: OperationDetailsModel | null): Observable<number | null | unknown> {
    const dto = toOperationDetailsModelDto(operation);
    if (!dto) {
      return of(null);
    }
    return this.positionResourceService.updateOperation(dto, this.response).pipe(
      tap((result: HttpResponse<IdDto>) => {
        if (result) {
          this.toastManagerService.toastSuccess('toasts.operations.update.title', 'toasts.operations.update.message', undefined, result);
        }
      }),
      map((result: HttpResponse<IdDto>) => result.body?.id),
      catchError(error => this.handlingError(error))
    );
  }
  updateStatus(action: OPERATION_ACTIONS, id: number): Observable<any> {
    return this.positionResourceService.updateOperationStatus(action, id);
  }
  private handlingError(error: any): Observable<any> {
    this.errorListFromBackendService.showError(error);
    return of(error);
  }

  public setDisableNewOperation(disableButton: boolean): void {
    this.disableNewOperation.next(disableButton);
  }
}
