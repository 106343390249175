import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { TypeSingleSelectComponent } from './type-single-select.component';

@NgModule({
  declarations: [TypeSingleSelectComponent],
  exports: [TypeSingleSelectComponent],
  imports: [FormsModule, ReactiveFormsModule, NgSelectModule, CommonModule]
})
export class TypeSingleSelectModule {}
