import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventFormService } from '@events/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-event-form-container',
  templateUrl: './event-form-container.component.html'
})
export class EventFormContainerComponent {
  public isFormInValid: boolean | undefined = false;
  public ClosedReason: DialogResult = 'closed';
  public CancelReason: DialogResult = 'cancel';
  public ConfirmReason: DialogResult = 'confirm';
  private readonly subscriptions: Subscription[] = [];

  confirmButton = 'modals.buttons.confirm';
  cancelButton = 'modals.buttons.cancel';
  @Input() set isin(value: string | null | undefined) {
    this.eventFormService.reset();
    if (value) {
      this.eventFormService.patchField('isin', value);
    }
  }

  constructor(private readonly activeModal: NgbActiveModal, private readonly eventFormService: EventFormService) {}

  disableConfirmButton(status: boolean) {
    this.isFormInValid = status;
  }

  public onClose(): void {
    this.activeModal.dismiss(this.ClosedReason);
    this.activeModal.close(this.ClosedReason);
  }

  public onCancel(): void {
    this.activeModal.close(this.CancelReason);
  }

  public onConfirm(): void {
    this.eventFormService.updateValueAndValidity();
    if (this.eventFormService.valid) {
      this.activeModal.close(this.ConfirmReason);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
