import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-http-feedback',
  templateUrl: './http-feedback.component.html',
  styleUrls: ['./http-feedback.component.scss']
})
export class HttpFeedbackComponent implements AfterViewInit {
  @ViewChild('authErrorModal', { static: false })
  authModal: ModalComponent | undefined;

  private viewInitialized = false;
  private _showAuthError: boolean | undefined;

  @Input() showSpinner: boolean | undefined;
  @Output() sgsHttpFeedbackAuthErrorSeen = new EventEmitter<boolean>();

  @Input()
  set showAuthError(authError: boolean) {
    this._showAuthError = authError;
    if (this.viewInitialized) {
      this.showHideAuthModal();
    }
  }

  ngAfterViewInit(): void {
    this.viewInitialized = true;
    this.showHideAuthModal();
  }

  private showHideAuthModal() {
    if (this._showAuthError) {
      this.authModal?.show();
    } else {
      this.authModal?.hide();
    }
  }

  public authErrorSeen(): void {
    this.sgsHttpFeedbackAuthErrorSeen.emit(true);
  }
}
