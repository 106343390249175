import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-settlement-data-upload',
  templateUrl: './settlement-data-upload.component.html'
})
export class SettlementDataUploadComponent {
  public file: File | null | undefined;

  constructor(public activeModal: NgbActiveModal) {}

  public get fileUploaded(): boolean {
    return !!this.file;
  }

  onFileDropped(file: File[]): void {
    this.file = file[0];
  }

  upload(): void {
    if (this.file) {
      this.activeModal.close({ reason: 'upload', file: this.file });
    }
  }
  remove(): void {
    this.file = null;
  }
  public cancel(): void {
    this.activeModal.dismiss('closed');
    this.activeModal.close('closed');
  }
}
