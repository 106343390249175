import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ColDef, GridApi, GridOptions, ValueFormatterParams } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { MouvementModel } from 'src/app/positions-keeping/models/mouvement.model';
import { MouvementColumnDefService } from 'src/app/positions-keeping/services/mouvement-column-def-service';

@Component({
  selector: 'app-mouvement-list',
  templateUrl: './mouvement-list.component.html',
  providers: [MouvementColumnDefService]
})
export class MouvementListComponent implements OnInit, OnDestroy {
  @Input() mouvements: MouvementModel[] | undefined | null;
  public columnDefs: ColDef[] = [];
  public defaultColDef: ColDef | undefined;
  public gridOptions!: GridOptions;
  private readonly shutdown$ = new Subject<void>();

  constructor(private readonly mouvementColumnDefService: MouvementColumnDefService) {}

  ngOnInit(): void {
    this.gridOptions = {
      onGridSizeChanged: () => {
        this.gridOptions.api?.sizeColumnsToFit();
      },
      pagination: false,
      suppressPaginationPanel: false,
      paginationPageSize: 10,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      suppressContextMenu: true,
      stopEditingWhenCellsLoseFocus: true
    };
    this.defaultColDef = {
      sortable: false,
      filter: false,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true }
    };
  }

  onGridReady(params: { api: GridApi }): void {
    this.columnDefs = this.mouvementColumnDefService.get();
    const gridApi = params.api;
    gridApi.sizeColumnsToFit();
    gridApi.setDomLayout('autoHeight');
    gridApi.refreshHeader();
  }
  export(): void {
    this.gridOptions?.api?.exportDataAsExcel({
      processCellCallback: params => {
        const colDef = params.column.getColDef();
        // try to reuse valueFormatter from the colDef
        if (colDef.valueFormatter && typeof colDef.valueFormatter !== 'string' && params.node) {
          const valueFormatterParams: ValueFormatterParams = {
            ...params,
            data: params?.node?.data,
            node: params.node,
            colDef: params.column.getColDef()
          };
          const formatter = colDef.valueFormatter as (params: ValueFormatterParams) => string;
          return formatter(valueFormatterParams);
        }
        return params.value;
      }
    });
  }
  ngOnDestroy(): void {
    this.shutdown$.next();
  }
}
