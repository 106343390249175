import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PositionService } from '@positions/services';
import { OperationDisplayService } from '@positions/services/display/operation.display.service';
import { OperationCreationFormService } from '@positions/services/operation-creation.form.service';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { IpaTypeModel, PartyModel, ROLES_TYPE, TYPED_DATA_TYPE, TYPE_FILTER_TYPE } from '@shared/models';
import { DisplayServiceInjectionToken, FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';
import { InstrumentService } from '@shared/services/instrument.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { OperationCreationModel } from 'src/app/positions-keeping/models/operation.model';
import { IpaTypeModelRules as R } from '@instruments/rules';
import { PositionModelRules as P } from '@positions/rules/position-model.rules';
import { SelectableParty } from '@shared/models/selectable-party.model';

@Component({
  selector: 'app-operation-form-container',
  templateUrl: './operation-form-container.component.html',
  providers: [
    OperationCreationFormService,
    OperationDisplayService,
    { provide: FormServiceInjectionToken, useExisting: OperationCreationFormService },
    { provide: DisplayServiceInjectionToken, useExisting: OperationDisplayService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'operations.forms.operationCreation' }
  ]
})
export class OperationFormContainerComponent extends BaseFormComponent<OperationCreationModel, OperationCreationFormService> implements OnInit, OnDestroy {
  public ClosedReason: DialogResult = 'closed';
  public CancelReason: DialogResult = 'cancel';
  public ConfirmReason: DialogResult = 'confirm';
  private readonly subscriptions: Subscription[] = [];
  public OPERATION_TYPE: TYPED_DATA_TYPE = 'OPERATION_TYPE';
  public CLEARING_SYSTEM_ROLE: ROLES_TYPE = 'CLEARING_SYSTEM';
  public OPERATION_FILTER: TYPE_FILTER_TYPE = 'OPERATION';
  public showSeparation = false;
  public isEuroclearSelected = false;
  public isClearstreamSelected = false;
  @Input() clearings: PartyModel[] = [];
  selectableClearnings: SelectableParty[] = [];
  constructor(
    private readonly displayService: OperationDisplayService,
    public formService: OperationCreationFormService,
    private readonly activeModal: NgbActiveModal,
    public instrumentService: InstrumentService,
    private readonly positionService: PositionService
  ) {
    super(formService, 'operations.placeholder.operationCreation');
  }

  ngOnInit(): void {
    this.clearings.forEach(party => {
      this.selectableClearnings.push({ ...party, checked: true });
    });

    const displayObservables = this.displayService.Compile();
    this.subscriptions.push(...displayObservables.map(o => o.subscribe()));

    this.subscriptions.push(
      this.formService
        .valueChanges('valueDate')
        .pipe()
        .subscribe(valueDate => {
          const instructionDate = this.formService.rawValue('instructionDate');
          if (!instructionDate && this.formService.isValid('valueDate')) {
            this.formService.patch('instructionDate', valueDate || null);
          }
        })
    );

    this.subscriptions.push(
      this.formService
        .valueChanges('isin')
        .pipe()
        .subscribe(isin => {
          if (isin && this.formService.isValid('isin') === true) {
            this.instrumentService
              .getInstrumentByIsin(isin)
              .pipe(first())
              .subscribe(i => {
                const ipaCode = i?.id;
                if (ipaCode) {
                  this.formService.patchField('ipaCode', ipaCode);
                  this.formService.lockFields('ipaCode');
                }
              });
          }
          this.formService.unlockFields('ipaCode');
        })
    );

    this.subscriptions.push(
      this.formService
        .valueChanges('icsd')
        .pipe()
        .subscribe(icsd => {
          if (icsd) {
            this.isEuroclearSelected = !!icsd?.find(icsd => {
              return R.isEUROCLEAR(icsd);
            });

            this.isClearstreamSelected = !!icsd?.find(icsd => {
              return R.isClEARSTREAM(icsd);
            });
          }
          this.showSeparation = !!icsd && icsd.length === 2;

          const type = this.formService.rawValue('type');
          this.setDefaultDirectionsWhenIcdsChanged(type);
        })
    );

    this.subscriptions.push(
      this.formService
        .valueChanges('type')
        .pipe()
        .subscribe(type => {
          this.setDefaultDirectionsWhenTypeChanged(type);
        })
    );
  }

  setDefaultDirectionsWhenIcdsChanged(type: IpaTypeModel | null | undefined): void {
    if (!type) {
      return;
    }

    if (P.isNetting(type)) {
      this.unlockDirectionsFields();
      this.patchDirectionsFields(false, this.isClearstreamSelected ? true : false);
      return;
    }

    if (P.isMarkDown(type)) {
      this.lockDirectionsFields();
      this.patchDirectionsFields(false, false);
      return;
    }

    this.lockDirectionsFields();
    this.patchDirectionsFields(this.isEuroclearSelected ? true : false, this.isClearstreamSelected ? true : false);
  }

  unlockDirectionsFields() {
    this.formService.unlockFields('operation');
    this.formService.unlockFields('ClearOperation');
  }

  lockDirectionsFields() {
    this.formService.lockFields('operation');
    this.formService.lockFields('ClearOperation');
  }

  patchDirectionsFields(operation: boolean, clearOperation: boolean) {
    this.formService.patchField('operation', operation);
    this.formService.patchField('ClearOperation', clearOperation);
  }

  setDefaultDirectionsWhenTypeChanged(type: IpaTypeModel | null | undefined): void {
    if (!type) {
      this.isEuroclearSelected = false;
      this.isClearstreamSelected = false;
      this.showSeparation = false;
      this.formService.clearField('icsd');
      return;
    }

    if (P.isNetting(type)) {
      this.formService.patchField('icsd', this.selectableClearnings);
    } else {
      this.setDefaultDirectionsWhenIcdsChanged(type);
    }
  }

  public onClose(): void {
    this.activeModal.dismiss(this.ClosedReason);
    this.activeModal.close(this.ClosedReason);
  }

  public onCancel(): void {
    this.activeModal.close(this.CancelReason);
  }

  public onConfirm(): void {
    this.formService.updateValueAndValidity({ emitEvent: false });
    if (this.formService.valid) {
      const operation = this.formService.value();
      this.positionService.createOperation(operation).subscribe();
      this.activeModal.close({ reason: this.ConfirmReason });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
