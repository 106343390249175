import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PaymentContainerComponent, PaymentDetailComponent } from './components';
import { PaymentResolve } from './payment.resolve';

const PAYMENT_ROUTES: Routes = [
  { path: 'payment', component: PaymentContainerComponent },
  { path: 'payment/:pageType', component: PaymentDetailComponent },
  {
    path: 'payment/:pageType/:id',
    component: PaymentDetailComponent,
    resolve: { payment: PaymentResolve }
  }
];

export const paymentRoutes = RouterModule.forRoot(PAYMENT_ROUTES, { preloadingStrategy: PreloadAllModules });
