/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Directive, Inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterSelectorBaseService } from '@shared/services/filter-selector-base.service';
import { FilterSelectorServiceInjectionToken } from '../tokens/filter-selector.token';

@Directive()
export class FiltersSaveBaseComponent<T, FTS extends FilterSelectorBaseService<T>> {
  public label = '';
  @Input() value: any;
  @Input() keyBase = '';
  constructor(public activeModal: NgbActiveModal, @Inject(FilterSelectorServiceInjectionToken) protected readonly filterSelectorService: FTS) {}

  save(): void {
    if (this.value) {
      this.filterSelectorService.push(this.value, this.label, this.keyBase);
      this.activeModal.close('confirm');
    }
  }
  public cancel(): void {
    this.activeModal.dismiss('closed');
    this.activeModal.close('closed');
  }
  public onBtnClick(reason: DialogResult): void {
    this.activeModal.close(reason);
  }
}
