import { EcashPaymentRequestDto, EcashPaymentResponseDto } from '@apis/backend/events';

export interface EcashPaymentsGridModel {
  readonly messageType?: string;
  readonly eCashId?: string;
  readonly amount?: number;
  readonly currency?: string;
  readonly identification?: string;
  readonly paymentEndToEndId?: string;
  readonly errorMessage?: string;
  readonly creationDate?: string;
  readonly status?: string;
}

export const toEcashPaymentsModal = (ecashPayments: EcashPaymentResponseDto): EcashPaymentsGridModel => {
  return {
    messageType: ecashPayments.messageTypeId?.valueDescription,
    eCashId: ecashPayments.ecashId,
    amount: ecashPayments.amount,
    currency: ecashPayments.currency,
    identification: ecashPayments.identification,
    paymentEndToEndId: ecashPayments.paymentEndToEndId,
    errorMessage: ecashPayments.errorMessage,
    creationDate: ecashPayments.creationDate,
    status: ecashPayments.ecashStatusId?.valueDescription
  };
};

export const toEcashPaymentsSearchDto = (id: number[]): EcashPaymentRequestDto => {
  return {
    eventId: Number(id)
  };
};
