import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { INSTRUMENT_CONSTANTS, SETTLEMENT_CONSTANTS } from '@instruments/configs/instrument-constants';
import { InstrumentCriteriaSearchModel } from '@instruments/models';
import { InstrumentSearchQueryModel } from '@instruments/services/instrument-search.service';
import { PartyModel, ROLES_TYPE, TypedDataModel, TYPE_FILTER_TYPE } from '@shared/models';
import { InstrumentService } from '@shared/services/instrument.service';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { forkJoin, Subject } from 'rxjs';

HC_exporting(Highcharts);
@Component({
  selector: 'app-sta-highcharts-chart-pie',
  templateUrl: './sta-highcharts-chart-pie.component.html'
})
export class StaHighchartsChartPieComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options | undefined;
  protected readonly shutdown$ = new Subject<void>();
  private typeReferenceData: Record<string, TypedDataModel[]> = {};
  private parties: [TYPE_FILTER_TYPE, ROLES_TYPE, PartyModel[]][] | undefined;
  private issuers: PartyModel[] = [];
  private totalItemsWithoutSettlements = 0;
  private totalItemInRepair = 0;
  private totalItemDeliverySecurities = 0;
  private readonly staIssuers = [INSTRUMENT_CONSTANTS.Issuers.SGE, INSTRUMENT_CONSTANTS.Issuers.SGIS, INSTRUMENT_CONSTANTS.Issuers.SGOE, INSTRUMENT_CONSTANTS.Issuers.SG_PARIS];
  private readonly instrumentRoute = '/instrument';
  private readonly criteriaInRepair: InstrumentCriteriaSearchModel = {};
  private readonly criteriaWithoutSettlements: InstrumentCriteriaSearchModel = {
    beginIssuedate: this.FormatDate(2),
    endIssuedate: this.FormatDate(5),
    withoutSettlement: true
  };
  private readonly criteriaTodeliverySecurities: InstrumentCriteriaSearchModel = {};

  constructor(private readonly activatedRoute: ActivatedRoute, private readonly router: Router, private readonly instrumentsService: InstrumentService) {}

  ngOnInit(): void {
    this.parties = this.activatedRoute.snapshot.data['parties'] as [TYPE_FILTER_TYPE, ROLES_TYPE, PartyModel[]][];
    this.issuers = this.getParties('INSTRUMENT', 'ISSUER').filter(x => x.name && this.staIssuers.includes(x.name));
    this.typeReferenceData = this.activatedRoute.snapshot.data['typeReferenceData'] as Record<string, TypedDataModel[]>;
    const settlementWorkflowStatus = this.typeReferenceData['SETTLEMENT_WORKFLOW_STATUS'];
    this.criteriaInRepair.issuers = this.issuers;
    this.criteriaWithoutSettlements.issuers = this.issuers;
    this.criteriaTodeliverySecurities.issuers = this.issuers;
    this.criteriaInRepair.settlementStatus = settlementWorkflowStatus.filter(x => {
      return x.valueDescription === SETTLEMENT_CONSTANTS.statuses.IN_REPAIR;
    });
    this.criteriaTodeliverySecurities.settlementStatus = settlementWorkflowStatus.filter(x => {
      return x.valueDescription === SETTLEMENT_CONSTANTS.statuses.DELIVERY_SECURITIES_PAYMENT_TO_BE_VALIDATED;
    });

    forkJoin([
      this.instrumentsService.getAllInstrumentsPaged({
        criteria: this.criteriaInRepair,
        itemsByPage: 20,
        pageNumber: 1
      }),
      this.instrumentsService.getAllInstrumentsPaged({
        criteria: this.criteriaTodeliverySecurities,
        itemsByPage: 20,
        pageNumber: 1
      }),
      this.instrumentsService.getAllInstrumentsPaged({
        criteria: this.criteriaWithoutSettlements,
        itemsByPage: 20,
        pageNumber: 1
      })
    ]).subscribe(result => {
      this.totalItemInRepair = result[0]?.totalItems || 0;
      this.totalItemDeliverySecurities = result[1]?.totalItems || 0;
      this.totalItemsWithoutSettlements = result[2]?.totalItems || 0;
      this.chartOptions = {
        chart: {
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: 'Issuing Settlement - STA'
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y}</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.y} '
            },
            showInLegend: true
          }
        },
        credits: {
          enabled: false
        },
        series: [
          {
            name: 'Brands',
            colorByPoint: true,
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.y}',
              connectorColor: 'silver'
            },
            point: {
              events: {
                click: (event: Highcharts.PointClickEventObject): void => {
                  this.navigate(event.point.name);
                }
              }
            },
            data: [
              { name: 'Instruments to settle', y: this.totalItemsWithoutSettlements },
              { name: 'Instruments to review', y: this.totalItemInRepair },
              { name: 'Instruments to validate', y: this.totalItemDeliverySecurities }
            ],
            type: 'pie'
          }
        ]
      };
    });
  }

  private FormatDate(daysToAdd: number) {
    const date = new Date();
    const dateAfterAddDays = new Date();
    dateAfterAddDays.setDate(date.getDate() + daysToAdd);
    return formatDate(dateAfterAddDays, 'dd-MM-yyyy', 'en');
  }

  navigate(name: string): void {
    if (name === 'Instruments to settle') {
      const query: InstrumentSearchQueryModel = this.criteriaWithoutSettlements;
      const stringfiedPredicate = JSON.stringify(query);
      this.router.navigate([this.instrumentRoute], { queryParams: { query: stringfiedPredicate } });
    }
    if (name === 'Instruments to review') {
      const query: InstrumentSearchQueryModel = this.criteriaInRepair;
      const stringfiedPredicate = JSON.stringify(query);
      this.router.navigate([this.instrumentRoute], { queryParams: { query: stringfiedPredicate } });
    }
    if (name === 'Instruments to validate') {
      const query: InstrumentSearchQueryModel = this.criteriaTodeliverySecurities;
      const stringfiedPredicate = JSON.stringify(query);
      this.router.navigate([this.instrumentRoute], { queryParams: { query: stringfiedPredicate } });
    }
  }

  private getParties(filter: TYPE_FILTER_TYPE, role: ROLES_TYPE): PartyModel[] {
    return (this.parties?.find(q => q[0] === filter && q[1] === role) ?? [filter, role, []])[2] ?? [];
  }
}
