import { Component, Input } from '@angular/core';
import { ColDef, GridOptions, GridApi, FirstDataRenderedEvent } from 'ag-grid-community';
import { ValuationColumnDefService } from './_aggrid/valuation-column-def-service';
import { ValuationModel } from '@valuation/models';

@Component({
  selector: 'app-valuation-list',
  templateUrl: './valuation-list.component.html',
  providers: [ValuationColumnDefService]
})
export class ValuationListComponent {
  @Input() public valuations: ValuationModel[] = [];

  public columnDefs: ColDef[] = [];
  public defaultColDef: any;
  public gridOptions!: GridOptions;
  public gridApi!: GridApi;
  public rowCount = 0;

  constructor(private readonly valuationColumnDefService: ValuationColumnDefService) {}

  ngOnInit(): void {
    this.columnDefs = this.valuationColumnDefService.get();
    this.gridOptions = {
      onFirstDataRendered: this.onFirstDataRendered,
      onGridSizeChanged: () => {
        this.gridOptions.api?.sizeColumnsToFit();
      },
      onColumnVisible: () => {
        this.columnResize();
      },
      onToolPanelVisibleChanged: () => {
        this.columnResize();
      },
      pagination: false,
      suppressPaginationPanel: false,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      suppressContextMenu: true,
      stopEditingWhenCellsLoseFocus: true
    };

    this.defaultColDef = {
      sortable: true,
      filter: false,
      suppressMenu: true,
      resizable: true,
      floatingFilterComponentParams: { suppressFilterButton: true }
    };
  }

  public sideBar: any = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        minWidth: 225,
        width: 225,
        maxWidth: 225,
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true
        }
      }
    ],
    defaultToolPanel: ''
  };

  onGridReady(params: any) {
    const gridApi = params.api;
    this.gridApi = params.api;
    gridApi.sizeColumnsToFit();
    gridApi.addGlobalListener(this.resizeColumn);
    gridApi.refreshHeader();
    this.rowCount = this.gridApi.getDisplayedRowCount();
  }
  onFirstDataRendered(params: FirstDataRenderedEvent): void {
    params.api.sizeColumnsToFit();
  }

  private columnResize() {
    setTimeout(() => {
      this.gridOptions.api?.sizeColumnsToFit();
    });
  }

  private readonly resizeColumn = (event: any) => {
    if (['gridSizeChanged', 'toolPanelVisibleChanged', 'columnVisible', 'rowDataChanged'].includes(event)) {
      this.columnResize();
    }
  };
}
