/**
 * issuer-ipa-events-extended API
 * Manage eventss data
 *
 * The version of the OpenAPI document: 1.1.21
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Parameters of Payments confirmation search
 */
export interface SearchPaymentsConfirmation { 
    paymentIds?: Array<number>;
    eventIds?: Array<number>;
    requestOfFundsIds?: Array<number>;
    statusValueIds?: Array<number>;
    eventTypeValueIds?: Array<number>;
    valueFromDate?: string;
    valueToDate?: string;
    receptionFromDate?: string;
    receptionToDate?: string;
    isinLists?: Array<string>;
    currency?: string;
    minAmount?: number;
    maxAmount?: number;
    adviceNumbers?: Array<string>;
    pageNumber?: number;
    pageSize?: number;
}

