/* eslint-disable no-console */
import { Injectable } from '@angular/core';

import { OperationCreationModel, OperationSearchModel, toOperationPagingRequestWithCriteriaDto, toPositionCreationModel } from '@positions/models';
import { MovementOfPositionCriteriaModel, toMovementOfPositionCriteriaSearchDto } from '@positions/models/movement-position-criteria.model';
import { MovementOfPositionResultModel, toMovementOfPositionResultModel } from '@positions/models/movements-position.model';
import { ErrorListFromBackendService } from '@shared/modules/error-list-from-backend/service/error-list-from-backend.service';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { PositionSearchCriteriaModel, toPositionCriteriaSearchDto } from '../models/position-search-criteria.model';
import { PositionResourceService, ResultPositionDataFetchDto, PagingResultDtoOperationDataFetchDto, IdDto } from '@apis/backend/instruments';

@Injectable({
  providedIn: 'root'
})
export class PositionService {
  public readonly disableNewOperation = new BehaviorSubject<boolean>(false);
  private readonly response = 'response';
  constructor(private readonly positionResourceService: PositionResourceService, private readonly toastManagerService: ToastManagerService, private readonly errorListFromBackendService: ErrorListFromBackendService) {}

  getAllPositions(positionSearchCriteriaModel: PositionSearchCriteriaModel): Observable<ResultPositionDataFetchDto | null> {
    const dto = toPositionCriteriaSearchDto(positionSearchCriteriaModel);
    if (!dto) {
      return of(null);
    }
    return this.positionResourceService.searchPosition(dto);
  }

  public searchPaginatedOperations(searchModel: OperationSearchModel): Observable<PagingResultDtoOperationDataFetchDto | null> {
    const dto = toOperationPagingRequestWithCriteriaDto(searchModel);

    if (!dto) {
      return of(null);
    }
    return this.positionResourceService.searchPaginatedOperations(dto);
  }
  public getPositionMovements(criteria: MovementOfPositionCriteriaModel): Observable<MovementOfPositionResultModel | null> {
    const dto = toMovementOfPositionCriteriaSearchDto(criteria);
    if (!dto) {
      return of(null);
    }
    return this.positionResourceService.getMovementsOfPosition(dto).pipe(
      map(r => {
        return toMovementOfPositionResultModel(r);
      })
    );
  }

  public createOperation(operation: OperationCreationModel | null): Observable<IdDto | null | unknown> {
    const dto = toPositionCreationModel(operation);
    if (!dto) {
      return of(null);
    }

    return this.positionResourceService.createOperation(dto, this.response).pipe(
      tap(result => {
        if (result) {
          this.toastManagerService.toastSuccess('toasts.operations.create.title', 'toasts.operations.create.message', undefined, result);
        }
      }),
      catchError(error => error)
    );
  }

  public setDisableNewOperation(disableButton: boolean): void {
    this.disableNewOperation.next(disableButton);
  }
}
