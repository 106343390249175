import { ColDef } from 'ag-grid-community';
import { CellStatusComponent } from '@shared/components/cell-status/cell-status.component';
import { EcashPaymentsGridModel } from '@events/models';

export interface EcashPaymentsColDef extends ColDef {
  field: keyof EcashPaymentsGridModel;
}

export const ECASH_COLUMN: EcashPaymentsColDef[] = [
  {
    field: 'messageType',
    width: 142,
    resizable: true
  },
  {
    field: 'eCashId',
    width: 108,
    resizable: true
  },
  {
    field: 'amount',
    width: 103,
    resizable: true
  },
  {
    field: 'currency',
    width: 112,
    resizable: true
  },
  {
    field: 'identification',
    width: 137,
    resizable: true
  },
  {
    field: 'paymentEndToEndId',
    width: 194,
    resizable: true
  },
  {
    field: 'errorMessage',
    width: 145,
    resizable: true
  },
  {
    field: 'creationDate',
    width: 139,
    resizable: true
  },
  {
    field: 'status',
    cellRendererFramework: CellStatusComponent,
    width: 100,
    resizable: true
  }
];
