import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IdDto, PagingResultDtoProgrammeDataFetchDto, ProgrammeDto, ProgrammeResourceService, ResultProgrammeDto } from '@apis/backend/instruments';
import { ErrorListFromBackendService } from '../modules/error-list-from-backend/service/error-list-from-backend.service';
import { ToastManagerService } from '../modules/toasts/service/toastManager.service';
import { notEmpty } from '@utils/utility-functions';
import { ProgrammeModel, toProgrammeDto, toProgrammeModel } from '@shared/models';
import { ProgrammeCriteriaSearchModel, ProgrammeSearchModel, toProgrammeCriteriaSearchDto, toProgrammePagingRequestWithCriteriaDto } from '@programmes/models/progamme-paging-criteria.model';

@Injectable({
  providedIn: 'root'
})
export class ProgrammeService {
  private readonly response = 'response';
  private readonly errorOccurence = 'Une erreur est survenue';

  constructor(private readonly programmeResourceService: ProgrammeResourceService, private readonly toastManagerService: ToastManagerService, private readonly errorListFromBackendService: ErrorListFromBackendService) {}

  getAllProgrammes(): Observable<ProgrammeModel[]> {
    return this.programmeResourceService.getMultipleProgrammes(undefined, this.response).pipe(map((result: HttpResponse<ResultProgrammeDto>) => (result.body?.resources || []).map(toProgrammeModel).filter(notEmpty)));
  }

  getProgrammeById(idProgramme: number | null | undefined): Observable<ProgrammeModel | null> {
    if (!idProgramme) {
      return of(null);
    }
    return this.programmeResourceService.getProgrammeById(idProgramme, this.response).pipe(map((result: HttpResponse<ProgrammeDto>) => toProgrammeModel(result.body)));
  }
  getProgrammeModelById(idProgramme: number | null | undefined): Observable<ProgrammeModel | null> {
    if (!idProgramme) {
      return of(null);
    }
    return this.programmeResourceService.getProgrammeById(idProgramme, this.response).pipe(map((result: HttpResponse<ProgrammeDto>) => toProgrammeModel(result?.body || null)));
  }
  createProgramme(programme: ProgrammeModel, filesToUploadLength: number): Observable<ProgrammeModel | null> {
    const dto = toProgrammeDto(programme);
    return this.programmeResourceService.createProgramme(dto ?? {}, this.response).pipe(
      tap((result: HttpResponse<IdDto>) => {
        if (result) {
          this.toastManagerService.toastSuccess('toasts.programmes.create.title', 'toasts.programmes.create.message', undefined, result);
          this.displayDocumentToast(filesToUploadLength);
        }
      }),
      mergeMap((result: HttpResponse<IdDto>) => this.getProgrammeById(result.body?.id)),
      catchError(error => this.handlingError(error))
    );
  }

  // to implement
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateProgramme(programme: ProgrammeModel, filesToUploadLength: number): Observable<ProgrammeModel | null> {
    const dto = toProgrammeDto(programme);
    return this.programmeResourceService.updateProgramme(dto ?? {}, this.response).pipe(
      tap((result: HttpResponse<IdDto>) => {
        if (result) {
          this.toastManagerService.toastSuccess('toasts.programmes.update.title', 'toasts.programmes.update.message', undefined, result);
          this.displayDocumentToast(filesToUploadLength);
        }
      }),
      mergeMap(() => this.getProgrammeById(dto?.id)),
      catchError(error => this.handlingError(error))
    );
  }
  public searchPaginatedProgrammes(searchModel: ProgrammeSearchModel): Observable<PagingResultDtoProgrammeDataFetchDto | null> {
    const dto = toProgrammePagingRequestWithCriteriaDto(searchModel);

    if (!dto) {
      return of(null);
    }
    return this.programmeResourceService.searchPaginatedProgrammes(dto);
  }

  public exportProgrammes(searchModel: ProgrammeCriteriaSearchModel): Observable<Blob | null> {
    const dto = toProgrammeCriteriaSearchDto(searchModel);

    if (!dto) {
      return of(null);
    }
    return this.programmeResourceService.exportProgrammeData(dto);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handlingError(error: any): Observable<any> {
    this.errorListFromBackendService.showError(error);
    return of(error);
  }

  private displayDocumentToast(filesToUploadLength: number): void {
    if (filesToUploadLength > 0) {
      this.toastManagerService.toastInfo('toasts.documents.processing.title', 'toasts.documents.processing.message', undefined, false);
    }
  }
}
