import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { POSITION_CONSTANTS } from '@positions/configs/position-constants';
import { PositionModel } from '@positions/models';
import { MovementOfPositionCriteriaModel } from '@positions/models/movement-position-criteria.model';
import { ICellRendererParams } from 'ag-grid-community';
import { routeToMouvements } from 'src/app/positions-keeping/routes';

@Component({
  selector: 'app-position-action',
  templateUrl: './position-action.component.html'
})
export class PositionActionComponent {
  params: ICellRendererParams | undefined;

  constructor(private readonly router: Router) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  goToDetail(): void {
    const position = this.params?.data as PositionModel;
    const criteria: MovementOfPositionCriteriaModel = { isinReference: position.isin, icsdParty: position.icsd, depositaryParty: position.depositary };
    this.router.navigate(routeToMouvements(this.params?.data.ipaCode), { queryParams: { criteria: JSON.stringify(criteria) } });
  }
  public get isDeutscheBank(): boolean {
    const position = this.params?.data as PositionModel;
    return position.depositary?.name === POSITION_CONSTANTS.DEPOSITARY_SG_LUXEMBOURG.name;
  }
  public get canShowDetails(): boolean {
    return !this.isDeutscheBank;
  }
}
