import { Component, OnInit } from '@angular/core';
import { EventsFilter } from '@events/models';
import { EventCriteriaSearchFormService } from '@events/services';
import { EventFilterSelectorService } from '@events/services/event-filter-selector.service';

@Component({
  selector: 'app-event-filter-selector',
  templateUrl: './event-filter-selector.component.html'
})
export class EventFilterSelectorComponent implements OnInit {
  public filters: EventsFilter[] = [];
  public selected: EventsFilter | undefined;
  constructor(private readonly eventCriteriaSearchFormService: EventCriteriaSearchFormService, public eventsFilterSelectorService: EventFilterSelectorService) {}

  ngOnInit(): void {
    this.eventsFilterSelectorService.loadFilters('events-filters');
  }

  onChange(event: EventsFilter): void {
    this.eventCriteriaSearchFormService.patch(event.value);
  }

  select(filter: EventsFilter): void {
    this.selected = filter;
    this.eventCriteriaSearchFormService.patch(filter.value);
  }
}
