import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PagingWithCriteriaModel } from '@instruments/models';
import { InstrumentCriteriaSearchFormService } from '@instruments/services';
import { PagingRequestWithCriteriaFormService } from '@instruments/services/paging-request-with-criteria.form.service';
import { TypedDataModel, TYPED_DATA_TYPE } from '@shared/models';
import { TypedDataService } from '@shared/services/typed-data.service';
import { EMPTY, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

type NullableTypedDataModel = TypedDataModel | null | undefined;

@Component({
  selector: 'app-status-type-select',
  templateUrl: './instrument-status-type-select.component.html',
  styleUrls: ['./instrument-status-type-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InstrumentStatusTypeSelectComponent),
      multi: true
    }
  ]
})
export class InstrumentStatusTypeSelectComponent implements OnInit, ControlValueAccessor {
  @Input() multiple = false;

  @Input() closeOnSelect = false;

  @Input() withDebounce = false;
  type: TYPED_DATA_TYPE = 'INSTRUMENT_WORKFLOW_STATUS';
  @Input() placeholder: string | null = null;

  @Input() hasError = false;
  @Output() blurEmit: EventEmitter<void> = new EventEmitter();

  @Output() inputEmit: EventEmitter<string> = new EventEmitter();

  public items$!: Observable<TypedDataModel[]>;
  public isReadOnly = false;
  public focusId: string | null = null;
  public internalValue: NullableTypedDataModel;

  constructor(private readonly typeService: TypedDataService, private readonly pagingRequestWithCriteriaFormService: PagingRequestWithCriteriaFormService, public readonly instrumentCriteriaSearchFormService: InstrumentCriteriaSearchFormService) {}

  private sortTypedDataModel(a: TypedDataModel, b: TypedDataModel) {
    if (a.valueDescription < b.valueDescription) {
      return -1;
    }
    if (a.valueDescription > b.valueDescription) {
      return 1;
    }
    return 0;
  }

  ngOnInit(): void {
    if (this.type) {
      this.items$ = this.typeService.getTypeByTypedData(this.type).pipe(
        map(res => [...res].sort(this.sortTypedDataModel)),
        first()
      );
    } else {
      this.items$ = EMPTY;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  selectStatus(newValue: NullableTypedDataModel): void {
    this.value = newValue;
  }

  get value(): NullableTypedDataModel {
    return this.internalValue;
  }
  set value(value: NullableTypedDataModel) {
    this.internalValue = value;
    this.onChange(value);
    this.onTouched();
  }
  getCount(status: string): number {
    const key: keyof PagingWithCriteriaModel = status.toLocaleLowerCase() as keyof PagingWithCriteriaModel;
    const value = this.pagingRequestWithCriteriaFormService.value();
    if (value) {
      return value[key] as number;
    }
    return 0;
  }
  get pagingValue(): PagingWithCriteriaModel | null {
    return this.pagingRequestWithCriteriaFormService.value();
  }
  get totalItems(): number {
    return (this.pagingValue?.live ?? 0) + (this.pagingValue?.closed ?? 0) + (this.pagingValue?.initialized ?? 0) + (this.pagingValue?.cancelled ?? 0);
  }

  onChange!: OnChangeFn<NullableTypedDataModel>;

  onTouched!: OnTouchFn;

  setDisabledState(isDisabled: boolean): void {
    this.isReadOnly = isDisabled;
  }
  registerOnChange(fn: OnChangeFn<NullableTypedDataModel>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchFn): void {
    this.onTouched = fn;
  }

  writeValue(value: NullableTypedDataModel): void {
    this.internalValue = value;
  }
}
