<div class="container g-0" [formGroup]="formGroup">
  <div class="row gy-4" [formGroup]="formGroup">
    <div class="col-md-5">
      <app-form-item [focus]="listingCountryInput" [field]="fields.listingCountry">
        <app-type-multiple-select component [type]="listingCountry" [formControlName]="fields.listingCountry"
          [multiple]="false" [closeOnSelect]="true" [withDebounce]="true" [hasError]="hasError(fields.listingCountry)"
          [placeholder]="placeholder(fields.listingCountry) | translate" #listingCountryInput></app-type-multiple-select>
      </app-form-item>
    </div>
    <div class="col-md-5">
      <app-form-item [focus]="listingPlaceInput" [field]="fields.listingPlace">
        <app-type-multiple-select component [type]="listingPlace" [formControlName]="fields.listingPlace"
          [multiple]="false" [closeOnSelect]="true" [withDebounce]="true" [hasError]="hasError(fields.listingPlace)"
          [placeholder]="placeholder(fields.listingPlace) | translate" #listingPlaceInput></app-type-multiple-select>
      </app-form-item>
    </div>
    <div class="col-md-2">
      <app-form-item [focus]="publiclySoldInput" [field]="fields.publiclySold">
        <app-yes-no-buttons component #publiclySoldInput [formControlName]="fields.publiclySold"></app-yes-no-buttons>
      </app-form-item>
    </div>
  </div>
</div>