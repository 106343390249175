export type ROLES_TYPE =
  | 'CALCULATION_AGENT'
  | 'COMMON_DEPOSITARY'
  | 'COMMON_SAFEKEEPER'
  | 'COMPETENT_AUTHORITY'
  | 'TRANSFER_AGENT'
  | 'ESCROW_MANAGER'
  | 'FISCAL_AGENT'
  | 'ISSUER'
  | 'ISSUING_AGENT'
  | 'LISTING_AGENT'
  | 'LOCAL_PAYING_AGENT'
  | 'PRINCIPAL_PAYING_AGENT'
  | 'REGISTRAR'
  | 'REIMBURSMENT_CALCULATION_AGENT'
  | 'CLEARING_SYSTEM'
  | 'CASH_MANAGER'
  | 'COMMON_SERVICE_PROVIDER'
  | 'DESK_CALCULATION_AGENT';
