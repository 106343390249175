import { AmountFormatCellComponent } from '@shared/components/amount-format-cell/amount-format-cell.component';
import { CellStatusComponent } from '@shared/components/cell-status/cell-status.component';
import { MOMENT_DATE_FORMAT } from '@shared/pipes/date-format/common';
import { ColDef, ValueFormatterParams } from 'ag-grid-community/dist/lib/main';
import * as moment from 'moment';
import { OperationActionComponent } from '../components/operation-management/operation-list/operation-action/operation-action.component';
import { OperationModel } from '../models/operation.model';

export interface OperationColumnDef extends ColDef {
  field: keyof (OperationModel & OperationColumnsAction);
}
interface OperationColumnsAction {
  actions: string;
}

export const OPERATIONS_COLUMNS: OperationColumnDef[] = [
  {
    field: 'isin',
    width: 250,
    sortable: true,
    resizable: true
  },
  {
    field: 'ipaCode',
    sortable: true,
    resizable: true
  },
  {
    field: 'seriesNumber',
    sortable: true,
    resizable: true
  },
  {
    field: 'valueDate',
    valueFormatter: (q: ValueFormatterParams) => {
      if (!q.data.valueDate) {
        return '';
      }
      const momentDate = moment(q.data.valueDate);
      return momentDate.format(MOMENT_DATE_FORMAT);
    },
    sortable: true,
    resizable: true
  },
  {
    field: 'operationType',
    sortable: true,
    resizable: true
  },
  {
    field: 'icsd',
    sortable: true,
    resizable: true,
    valueFormatter: (q: ValueFormatterParams) => q.data.icsd?.name
  },
  {
    field: 'direction',
    sortable: true,
    resizable: true
  },
  {
    field: 'value',
    cellRendererFramework: AmountFormatCellComponent,
    sortable: true,
    resizable: true,
    cellStyle: { display: 'flex', 'justify-content': 'flex-end' }
  },
  {
    field: 'currency',
    sortable: true,
    resizable: true
  },
  {
    field: 'status',
    cellRendererFramework: CellStatusComponent,
    sortable: true,
    resizable: true
  },
  {
    field: 'actions',
    cellRendererFramework: OperationActionComponent,
    sortable: true
  }
];
