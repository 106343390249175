/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { InstrumentModel } from '@instruments/models/instrument.model';
import { ColDef, ValueFormatterParams } from 'ag-grid-community/dist/lib/main';
import { ScheduleEventModel } from '@events/models';
import { EventActionComponent } from './event-action/event-action.component';
import { CellStatusComponent } from '@shared/components/cell-status/cell-status.component';
import * as moment from 'moment';
import { MOMENT_DATE_FORMAT } from '@shared/pipes/date-format/common';

export interface EventColumnDef extends ColDef {
  field: keyof (ScheduleEventModel & InstrumentModel & EventColumnsAction);
}
interface EventColumnsAction {
  actions: string;
}

export const EVENTS_COLUMNS: EventColumnDef[] = [
  {
    field: 'instrumentId',
    maxWidth: 150
  },
  {
    field: 'eventReference',
    maxWidth: 160
  },
  {
    field: 'eventType',
    valueFormatter: (q: ValueFormatterParams) => (q.data.eventType?.valueDescription ? q.data.eventType?.valueDescription : ''),
    maxWidth: 130,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    comparator: (valueA, valueB, _nodeA, _nodeB, _isInverted) => {
      if (valueA === valueB) {
        return 0;
      }
      return valueA.valueDescription?.localeCompare(valueB?.valueDescription);
    }
  },
  {
    field: 'isinReference'
  },
  {
    field: 'name'
  },
  {
    field: 'issuer',
    valueFormatter: (q: ValueFormatterParams) => q.data.issuer?.name,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    comparator: (valueA, valueB, _nodeA, _nodeB, _isInverted) => {
      if (valueA === valueB) {
        return 0;
      }
      return valueA.name?.localeCompare(valueB?.name);
    }
  },
  {
    field: 'status',
    cellRendererFramework: CellStatusComponent,
    valueFormatter: (q: ValueFormatterParams) => (q.data.status?.valueDescription ? q.data.status?.valueDescription : ''),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    comparator: (valueA, valueB, _nodeA, _nodeB, _isInverted) => {
      if (valueA === valueB) {
        return 0;
      }
      return valueA.valueDescription?.localeCompare(valueB?.valueDescription);
    }
  },
  {
    field: 'valueDate',
    width: 150,
    valueFormatter: (q: ValueFormatterParams) => {
      if (!q.data.valueDate) {
        return '';
      }
      const momentDate = moment(q.data.valueDate);
      return momentDate.format(MOMENT_DATE_FORMAT);
    }
  },
  {
    field: 'notificationDate',
    maxWidth: 170,
    valueFormatter: (q: ValueFormatterParams) => {
      if (!q.data.notificationDate) {
        return '';
      }
      const momentDate = moment(q.data.notificationDate);
      return momentDate.format(MOMENT_DATE_FORMAT);
    }
  },
  {
    field: 'requestOfFundsDate',
    maxWidth: 200,
    valueFormatter: (q: ValueFormatterParams) => {
      if (!q.data.requestOfFundsDate) {
        return '';
      }
      const momentDate = moment(q.data.requestOfFundsDate);
      return momentDate.format(MOMENT_DATE_FORMAT);
    }
  },
  {
    field: 'preAdviceDate',
    maxWidth: 170,
    valueFormatter: (q: ValueFormatterParams) => {
      if (!q.data.preAdviceDate) {
        return '';
      }
      const momentDate = moment(q.data.preAdviceDate);
      return momentDate.format(MOMENT_DATE_FORMAT);
    }
  },
  {
    field: 'seriesNumber',
    width: 130
  },
  {
    field: 'paymentCurrency',
    width: 170
  },
  {
    field: 'actions',
    cellRendererFramework: EventActionComponent,
    width: 150,
    resizable: true,
    pinned: 'right',
    suppressSizeToFit: true
  }
];
