/**
 * issuer-ipa-events-extended API
 * Manage eventss data
 *
 * The version of the OpenAPI document: 1.1.15
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PartyDto } from './partyDto';
import { IpaTypeDto } from './ipaTypeDto';


export interface InstrumentCharacteristicsDataDto { 
    issuer?: PartyDto;
    clearingSystems?: Array<PartyDto>;
    icsdProgrammeNumber?: string;
    internalProgrammeNumber?: number;
    commonCode?: string;
    mainTrancheIsin?: string;
    isinReference?: string;
    instrumentType?: IpaTypeDto;
    seriesNumber?: string;
    numberOfTranche?: number;
    classNumber?: string;
    compartmentNumber?: string;
    name?: string;
    legalDeskIssuer?: IpaTypeDto;
    complianceRestriction?: boolean;
    paymentDefault?: boolean;
    eligibility871m?: boolean;
    eligibilitySrd2?: boolean;
    labelCategory?: IpaTypeDto;
    upto?: boolean;
}

