import { Injectable } from '@angular/core';

import { TrancheFongeContainerComponent } from '@instruments/components/tranche-fonge-container/tranche-fonge-container.component';
import { InstrumentModel } from '@instruments/models';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ISSUE_OR_CANCEL } from '@shared/models';
import { DialogModalComponent } from '@shared/modules/modals/components';

@Injectable({
  providedIn: 'root'
})
export class FongeTrancheModalsService {
  constructor(private readonly modalService: NgbModal) {}

  public openFongeTrancheModal(instrument: InstrumentModel | null): NgbModalRef {
    const modal = this.modalService.open(TrancheFongeContainerComponent, { size: 'lg', scrollable: true });
    const dialog: TrancheFongeContainerComponent = modal.componentInstance;
    dialog.instrument = instrument;
    return modal;
  }
  public openConfirmChangeStatusModal(status: ISSUE_OR_CANCEL, dataType: 'instrument' | 'tranche'): NgbModalRef {
    const title = `instruments.modals.titles.${status.toLocaleLowerCase()}.${dataType}`;
    const body = `instruments.modals.contents.${status.toLocaleLowerCase()}.${dataType}`;
    const modal = this.modalService.open(DialogModalComponent);
    const dialog: DialogModalComponent = modal.componentInstance;
    dialog.dialogTitle = title;
    dialog.dialogBody = body;
    return modal;
  }
}
