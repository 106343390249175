import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { postionsKeepingRoutes } from './positions-keeping.routing';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { DocumentsModule } from '@documents/documents.module';
import { ButtonsModule } from '@shared/modules/buttons/buttons.module';
import { DatePickerModule } from '@shared/modules/date-picker/date-picker.module';
import { LocalCustomFormModule } from '@shared/modules/forms/local-custom-form.module';
import { HeaderModule } from '@shared/modules/header/header.module';
import { HideAndShowModule } from '@shared/modules/hide-and-show/hide-and-show.module';
import { LeiMultipleSelectModule } from '@shared/modules/lei-multiple-select/lei-multiple-select.module';
import { ModalsModule } from '@shared/modules/modals/modals.module';
import { MultipleDayPickerModule } from '@shared/modules/multiple-day-picker/multiple-day-picker.module';
import { NullableYesNoButtonsModule } from '@shared/modules/nullable-yes-no-buttons/nullable-yes-no-buttons.module';
import { NumberInputModule } from '@shared/modules/number-input/number-input.module';
import { PaginationModule } from '@shared/modules/pagination/pagination.module';
import { PartySingleSelectModule } from '@shared/modules/party-signle-select/party-single-select.module';
import { ProgrammeInformationModule } from '@shared/modules/programme-information/programme-information.module';
import { TypeSingleSelectModule } from '@shared/modules/type-single-select/type-single-select.module';
import { YesNoButtonsModule } from '@shared/modules/yes-no-buttons/yes-no-buttons.module';
import { AgGridModule } from 'ag-grid-angular';
import { PositionListFilterComponent } from './components/positions-list/position-list-filter/position-list-filter.component';
import { PositionListComponent } from './components/positions-list/position-list.component/position-list.component';
import { PositionFilterSelectorService } from './services/position-filter-selector.service';
import { FilterSelectorServiceInjectionToken } from '@shared/modules/save-filter/tokens/filter-selector.token';
import { PositionSearchContainerComponent } from './components/position-search-container/position-search-container.component';
import { PositionShellComponent } from './components/position-shell/position-shell.component';
import { OperationManagementContainerComponent } from './components/operation-management/operation-management-container/operation-management-container.component';
import { OperationListComponent } from './components/operation-management/operation-list/operation-list.component/operation-list.component';
import { OperationListFilterComponent } from './components/operation-management/operation-list/operation-list-filter/operation-list-filter.component';
import { MouvementListComponent } from './components/mouvements/mouvement-list/mouvement-list.component/mouvement-list.component';
import { MouvementsComponent } from './components/mouvements/mouvements.component';
import { AddOperationComponent } from './components/add-operation/add-operation.component';
import { OperationFormContainerComponent } from './components/add-operation/operation-form-container/operation-form-container.component';
import { OperationComponent } from './components/operation/operation.component';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { ModuleRegistry } from '@ag-grid-community/core';
import { OperationFormComponent } from './components/operation/operation-form/operation-form.component';
import { TypeMultipleSelectModule } from '@shared/modules/type-multiple-select/type-multiple-select.module';

import { OperationItemsConsultComponent } from './components/operation/operation-items-consult/operation-items-consult.component';
import { OperationItemsEditComponent } from './components/operation/operation-items-edit/operation-items-edit.component';
import { OperationListActiveFiltersComponent } from './components/operation-management/operation-list/operation-list-active-filters/operation-list-active-filters.component';
import { PartyMultipleCheckModule } from '@shared/modules/party-multiple-check-select/party-multiple-check.module';
import { PositionListActiveFiltersComponent } from './components/positions-list/position-list-active-filters/position-list-active-filters.component';

ModuleRegistry.registerModules([MasterDetailModule]);
@NgModule({
  declarations: [
    PositionSearchContainerComponent,
    PositionListFilterComponent,
    PositionListComponent,
    PositionListActiveFiltersComponent,
    PositionShellComponent,
    OperationManagementContainerComponent,
    OperationListComponent,
    OperationListFilterComponent,
    MouvementsComponent,
    MouvementListComponent,
    AddOperationComponent,
    OperationFormContainerComponent,
    OperationComponent,
    OperationFormComponent,
    OperationItemsConsultComponent,
    OperationItemsEditComponent,
    OperationListActiveFiltersComponent
  ],
  imports: [
    postionsKeepingRoutes,
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    SharedModule.forRoot(),
    HeaderModule,
    YesNoButtonsModule,
    LocalCustomFormModule,
    MultipleDayPickerModule,
    DatePickerModule,
    ModalsModule,
    DocumentsModule,
    AgGridModule,
    PaginationModule,
    NullableYesNoButtonsModule,
    LeiMultipleSelectModule,
    ButtonsModule,
    TypeSingleSelectModule,
    PartySingleSelectModule,
    NumberInputModule,
    ProgrammeInformationModule,
    HideAndShowModule,
    TypeMultipleSelectModule,
    PartyMultipleCheckModule
  ],
  providers: [{ provide: FilterSelectorServiceInjectionToken, useExisting: PositionFilterSelectorService }]
})
export class PositionsKeepingModule {
  static forRoot(): ModuleWithProviders<PositionsKeepingModule> {
    return {
      ngModule: PositionsKeepingModule
    };
  }
}
