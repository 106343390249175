import { Component, EventEmitter, forwardRef, Input, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { notEmpty } from '@utils/utility-functions';
import { CURRENCIES } from '../currencies';

type CurrencyType = string[] | string | undefined;
type NullableCurrencyItemOrArray = CurrencyItem[] | CurrencyItem | undefined;

export interface CurrencyItem {
  id: number | string;
  code: string;
}
/*

USAGE EXAMPLE :

<app-currency-multiple-select [multiple]="true" [placeholder]="'placeholder'">
</app-currency-multiple-select>

*/

@Component({
  selector: 'app-currency-multiple-select',
  templateUrl: './currency-multiple-select-v2.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyMultipleSelectV2Component),
      multi: true
    }
  ]
})
export class CurrencyMultipleSelectV2Component implements ControlValueAccessor {
  @Input() multiple = false;
  @Input() closeOnSelect = false;
  @Input() withDebounce = false;
  @Input() placeholder = '';
  @Input() icon = '';
  @Input() withSummary = false;
  @Input() hasError = false;
  @Output() blurEmit: EventEmitter<void> = new EventEmitter();
  @Output() inputEmit: EventEmitter<string> = new EventEmitter();

  public possibleCurrencies: CurrencyItem[] = [...CURRENCIES].sort(this.sortCurrencies).map(q => {
    return { id: q, code: q };
  });
  public isReadOnly = false;
  public focusId: string | null = null;

  private _value: NullableCurrencyItemOrArray = undefined;
  get value(): NullableCurrencyItemOrArray {
    return this._value;
  }

  set value(value: NullableCurrencyItemOrArray) {
    this._value = value || undefined;
    const val = value && Array.isArray(value) ? value?.map(q => q.code) : value?.code;
    this.onChange(val);
  }

  get displayValue(): string[] {
    if (!this.value) {
      return [];
    }
    if (!Array.isArray(this.value)) {
      return [this.value.code];
    }
    return this.value.map(q => q.code);
  }

  public onBlurEmit(): void {
    this.onTouched();
    this.blurEmit.next();
  }

  public onInputEmit(value: string): void {
    this.inputEmit.next(value);
  }

  onChange!: OnChangeFn<CurrencyType>;

  onTouched!: OnTouchFn;

  setDisabledState(isDisabled: boolean): void {
    this.isReadOnly = isDisabled;
  }

  registerOnChange(fn: OnChangeFn<CurrencyType>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchFn): void {
    this.onTouched = fn;
  }

  writeValue(value: CurrencyType): void {
    if (Array.isArray(value)) {
      this._value = value
        .map(q => {
          return this.possibleCurrencies.find(pc => pc.code === q);
        })
        .filter(notEmpty);
      return;
    }
    this._value = this.possibleCurrencies.find(q => q.code === value);
  }

  private sortCurrencies(a: string, b: string) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }
}
