import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DisposalModel } from '@instruments/models/disposal.model';
import { CommonDepositoriesForms } from '@instruments/models/management-common-depository.model';
import { FormService } from '@shared/services/forms/form.service';

@Injectable()
export class CommonDepositoryFormService extends FormService<DisposalModel, CommonDepositoriesForms> {
  constructor(fb: UntypedFormBuilder) {
    super('managment_common_depository', fb, {
      disposalId: fb.control(null),
      archiveBoxNumber: fb.control(null),
      archiveDate: fb.control(null),
      disposalItems: fb.control([])
    });
  }
}
