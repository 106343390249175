import { PagingRequestDtoWithCriteriaDto } from '@apis/backend/instruments/model/pagingRequestDtoWithCriteriaDto';
import { InstrumentCriteriaSearchModel, toInstrumentCriteriaSearchDto, toInstrumentCriteriaSearchModel } from './instrument-criteria-search.model';

export interface PagingRequestWithCriteriaModel {
  /**
   * Requested page number.
   */
  pageNumber?: number;
  /**
   * Number of items per page.
   */
  itemsByPage?: number;
  criteria?: InstrumentCriteriaSearchModel | null;
  tag?: string;
}

export const toPagingRequestWithCriteriaDto = (model: PagingRequestWithCriteriaModel | null | undefined): PagingRequestDtoWithCriteriaDto | undefined => {
  if (!model) {
    return undefined;
  }

  return {
    pageNumber: model.pageNumber,
    itemsByPage: model.itemsByPage,
    criteria: toInstrumentCriteriaSearchDto(model.criteria)
  };
};

export const toPagingRequestWithCriteriaModel = (dto: PagingRequestDtoWithCriteriaDto | null | undefined): PagingRequestWithCriteriaModel | null => {
  if (!dto) {
    return null;
  }

  return {
    pageNumber: dto.pageNumber,
    itemsByPage: dto.itemsByPage,
    criteria: toInstrumentCriteriaSearchModel(dto.criteria)
  };
};
