import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FormService } from '@shared/services/forms/form.service';
import { EventModel } from 'src/app/events/models/event.model';
import { CustomValidators } from '@shared/validators/custom-validator';

@Injectable({
  providedIn: 'root'
})
export class EventFormService extends FormService<EventModel> {
  constructor(fb: UntypedFormBuilder) {
    super('event', fb, {
      isin: fb.control(null, [Validators.required, CustomValidators.Isin]),
      eventId: fb.control(null),
      eventType: fb.control(null, [Validators.required]),
      valueDate: fb.control(null, [Validators.required, CustomValidators.ValidateDate]),
      beginDate: fb.control(null),
      endDate: fb.control(null),
      notificationDate: fb.control(null),
      reqFundDate: fb.control(null),
      preAdvDate: fb.control(null)
    });
  }
}
