import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PartiesDatasResolve } from '@instruments/resolves/parties-datas.resolve';
import { TypedDatasResolve } from '@instruments/resolves/typed-datas.resolve';
import { MouvementsComponent } from './components/mouvements/mouvements.component';
import { OperationManagementContainerComponent } from './components/operation-management/operation-management-container/operation-management-container.component';
import { OperationComponent } from './components/operation/operation.component';
import { PositionSearchContainerComponent } from './components/position-search-container/position-search-container.component';
import { PositionShellComponent } from './components/position-shell/position-shell.component';
import { PermissionGuard } from '@routes/permission.guard';

const POSITIONS_KEEPING_ROUTES: Routes = [
  {
    path: 'position',
    component: PositionShellComponent,
    canActivate: [PermissionGuard],
    children: [
      { path: 'search', component: PositionSearchContainerComponent },
      { path: 'operations', component: OperationManagementContainerComponent },
      { path: '', component: PositionSearchContainerComponent }
    ],
    resolve: {
      typeReferenceData: TypedDatasResolve,
      parties: PartiesDatasResolve
    },
    data: {
      types: ['OPERATION_TYPE', 'OPERATION_WORKFLOW_STATUS'],
      roles: [{ role: 'CLEARING_SYSTEM', filter: 'OPERATION' }]
    }
  },
  {
    path: 'position/:id',
    component: MouvementsComponent
  },
  {
    path: 'position/operation/:id',
    component: OperationComponent,
    canActivate: [PermissionGuard],
    resolve: {
      parties: PartiesDatasResolve
    },
    data: {
      roles: [{ role: 'CLEARING_SYSTEM', filter: 'OPERATION' }]
    }
  }
];
export const postionsKeepingRoutes = RouterModule.forRoot(POSITIONS_KEEPING_ROUTES, { preloadingStrategy: PreloadAllModules });
