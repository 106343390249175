import { InstrumentEventsDataCreateDto, InstrumentEventsDataDto } from 'apis/backend/instruments';
import { stringToDate, dateToString, notEmpty } from '@utils/utility-functions';
import { IpaTypeModel, toIpaTypeDto, toIpaTypeModel } from '@shared/models';
import { TrancheModel } from './tranche.model';
import { DayDate, dayDateToString, stringToDayDate } from './day-date.model';
import { IpaTypeModelRules } from '@instruments/rules';
import { IpaTypeDto } from '@apis/backend/events';

export interface EventsDataModel {
  readonly issueDate: Date | null;
  readonly interestCommencementDate: Date | null;
  readonly maturityDate: Date | null;
  readonly perpetual: boolean;
  readonly paymentDateCorrelated: boolean;
  readonly couponType: IpaTypeModel | null;
  readonly firstCouponPayDate: Date | null;
  readonly interestRate: string | null;
  readonly dayCountFraction: IpaTypeModel | null;
  readonly secondCouponType: IpaTypeModel | null;
  readonly secondCouponPayDate: Date | null;
  readonly secondInterestRate: string | null;
  readonly secondDayCountFraction: IpaTypeModel | null;
  readonly couponFrequency: IpaTypeModel | null;
  readonly couponPaymentCurrencies: string[];
  readonly firstEndPeriodDate: Date | null;
  readonly paymentDateOffset: string | null;
  readonly paymentDateMgtMode: IpaTypeModel | null;
  readonly fundsArrivalDelayInDay: string | null;
  readonly endDateAdjusted: boolean;
  readonly businessDayConvention: IpaTypeModel | null;
  readonly recordDateCalculationMode: IpaTypeModel | null;
  readonly recordDatesCalculationDates: DayDate[];
  readonly recordDateOffset: number | null;
  readonly recordDateReference: IpaTypeModel | null;
  readonly calculationTime: IpaTypeModel | null;
  readonly calculationOffset: string | null;
  readonly redemptionValuationDate: Date | null;
  readonly redemptionCurrencies: string[];
  readonly redemptionPrice: string | null;
  readonly redeemableInSecurities: boolean;
  readonly optionExerciseStyle: IpaTypeModel | null;
  readonly partialRedemption: boolean;
  readonly partialRedemptionCorrelatedToCoupon: boolean;
  readonly partialRedemptionType: IpaTypeModel | null;
  readonly partialRedemptCurrencies: string[];
  readonly instrFinancialCurrencies: string[];
  readonly instrFinancialPlaces: IpaTypeDto[];
  readonly convertible: boolean;
  readonly partlyPaid: boolean;
  readonly putOption: boolean;
}

const cleanUpModel = (couponType: IpaTypeModel | null, dto: InstrumentEventsDataCreateDto, noFrequency: IpaTypeModel): InstrumentEventsDataCreateDto => {
  if (!IpaTypeModelRules.is(couponType, 'ZERO-COUPON')) {
    return dto;
  }

  return {
    ...dto,
    couponFrequency: toIpaTypeDto(noFrequency),
    paymentDateCorrelated: false,
    recordDateCalculationMode: undefined,
    recordDateOffset: undefined,
    recordDateReference: undefined
  };
};
export const toEventsDataDto = (model: EventsDataModel | null | undefined, noFrequency: IpaTypeModel): InstrumentEventsDataCreateDto | undefined => {
  if (!model) {
    return undefined;
  }

  const result = {
    issueDateAsString: dateToString(model.issueDate) || undefined,
    interestCommencementDateAsString: dateToString(model.interestCommencementDate) || undefined,
    maturityDateAsString: dateToString(model.maturityDate) || undefined,
    perpetual: model.perpetual,
    paymentDateCorrelated: model.paymentDateCorrelated,
    couponType: toIpaTypeDto(model.couponType),
    firstCouponPayDateAsString: dateToString(model.firstCouponPayDate) || undefined,
    interestRate: model.interestRate || undefined,
    dayCountFraction: toIpaTypeDto(model.dayCountFraction),
    secondCouponType: toIpaTypeDto(model.secondCouponType),
    secondCouponPayDateAsString: dateToString(model.secondCouponPayDate) || undefined,
    secondInterestRate: model.secondInterestRate || undefined,
    secondDayCountFraction: toIpaTypeDto(model.secondDayCountFraction),
    couponFrequency: toIpaTypeDto(model.couponFrequency),
    couponPaymentCurrencies: model.couponPaymentCurrencies,
    firstEndPeriodDateAsString: dateToString(model.firstEndPeriodDate) || undefined,
    paymentDateOffset: model.paymentDateOffset || undefined,
    paymentDateMgtMode: toIpaTypeDto(model.paymentDateMgtMode),
    fundsArrivalDelayInDay: model.fundsArrivalDelayInDay || undefined,
    endDateAdjusted: model.endDateAdjusted,
    businessDayConvention: toIpaTypeDto(model.businessDayConvention),
    recordDateCalculationMode: toIpaTypeDto(model.recordDateCalculationMode),
    recordDatesCalculationDates: model.recordDatesCalculationDates.map(dayDateToString).filter(notEmpty),
    recordDateOffset: model.recordDateOffset?.toString(),
    recordDateReference: toIpaTypeDto(model.recordDateReference),
    calculationTime: toIpaTypeDto(model.calculationTime),
    calculationOffset: model.calculationOffset || undefined,
    redemptionValuationDateAsString: dateToString(model.redemptionValuationDate) || undefined,
    redemptionCurrencies: model.redemptionCurrencies,
    redemptionPrice: model.redemptionPrice || undefined,
    redeemableInSecurities: model.redeemableInSecurities,
    optionExerciseStyle: toIpaTypeDto(model.optionExerciseStyle),
    partialRedemption: model.partialRedemption,
    partialRedemptionCorrelatedToCoupon: model.partialRedemptionCorrelatedToCoupon,
    partialRedemptionType: toIpaTypeDto(model.partialRedemptionType),
    partialRedemptCurrencies: model.partialRedemptCurrencies,
    instrFinancialCurrencies: model.instrFinancialCurrencies,
    instrFinancialPlaces: model.instrFinancialPlaces,
    convertible: model.convertible,
    partlyPaid: model.partlyPaid,
    putOption: model.putOption
  };
  //Rustine pour envoyer zero freq is zero coupon
  return cleanUpModel(model.couponType, result, noFrequency);
};

export const toEventsDataModel = (dto: InstrumentEventsDataDto | null | undefined, tranche: TrancheModel | null | undefined): EventsDataModel => {
  return {
    issueDate: tranche?.issueDate || null,
    interestCommencementDate: tranche?.interestCommencementDate || null,
    maturityDate: stringToDate(dto?.maturityDateAsString),
    perpetual: dto?.perpetual || false,
    paymentDateCorrelated: dto?.paymentDateCorrelated || false,
    couponType: toIpaTypeModel(dto?.couponType),
    firstCouponPayDate: stringToDate(dto?.firstCouponPayDateAsString),
    interestRate: dto?.interestRate || null,
    dayCountFraction: toIpaTypeModel(dto?.dayCountFraction),
    secondCouponType: toIpaTypeModel(dto?.secondCouponType),
    secondCouponPayDate: stringToDate(dto?.secondCouponPayDateAsString),
    secondInterestRate: dto?.secondInterestRate || null,
    secondDayCountFraction: toIpaTypeModel(dto?.secondDayCountFraction),
    couponFrequency: toIpaTypeModel(dto?.couponFrequency),
    couponPaymentCurrencies: dto?.couponPaymentCurrencies || [],
    firstEndPeriodDate: stringToDate(dto?.firstEndPeriodDateAsString),
    paymentDateOffset: dto?.paymentDateOffset || null,
    paymentDateMgtMode: toIpaTypeModel(dto?.paymentDateMgtMode),
    fundsArrivalDelayInDay: dto?.fundsArrivalDelayInDay || null,
    endDateAdjusted: dto?.endDateAdjusted || false,
    businessDayConvention: toIpaTypeModel(dto?.businessDayConvention),
    recordDateCalculationMode: toIpaTypeModel(dto?.recordDateCalculationMode),
    recordDatesCalculationDates: Array.from(dto?.recordDatesCalculationDates || [])
      .map(stringToDayDate)
      .filter(notEmpty),
    recordDateOffset: +(dto?.recordDateOffset || '1'),
    recordDateReference: toIpaTypeModel(dto?.recordDateReference),
    calculationTime: toIpaTypeModel(dto?.calculationTime),
    calculationOffset: dto?.calculationOffset || null,
    redemptionValuationDate: stringToDate(dto?.redemptionValuationDateAsString),
    redemptionCurrencies: dto?.redemptionCurrencies || [],
    redemptionPrice: dto?.redemptionPrice || null,
    redeemableInSecurities: dto?.redeemableInSecurities || false,
    optionExerciseStyle: toIpaTypeModel(dto?.optionExerciseStyle),
    partialRedemption: dto?.partialRedemption || false,
    partialRedemptionCorrelatedToCoupon: dto?.partialRedemptionCorrelatedToCoupon || false,
    partialRedemptionType: toIpaTypeModel(dto?.partialRedemptionType),
    partialRedemptCurrencies: dto?.partialRedemptCurrencies || [],
    instrFinancialCurrencies: dto?.instrFinancialCurrencies || [],
    instrFinancialPlaces: dto?.instrFinancialPlaces || [],
    convertible: dto?.convertible || false,
    partlyPaid: dto?.partlyPaid || false,
    putOption: dto?.putOption || false
  };
};
