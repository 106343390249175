import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FormService } from '@shared/services/forms/form.service';
import { CustomValidators } from '@shared/validators/custom-validator';
import { OperationCreationModel } from '../models/operation.model';

@Injectable()
export class OperationCreationFormService extends FormService<OperationCreationModel> {
  constructor(fb: UntypedFormBuilder) {
    super('operation', fb, {
      id: fb.control(null),
      isin: fb.control(null, [Validators.required, CustomValidators.Isin]),
      ipaCode: fb.control(null),
      valueDate: fb.control(null, [Validators.required, CustomValidators.ValidateDate]),
      instructionDate: fb.control(null, [Validators.required, CustomValidators.ValidateDate]),
      seriesNumber: fb.control(null),
      currency: fb.control(null),
      icsd: fb.control([], Validators.required),
      name: fb.control(null),
      direction: fb.control(null),
      value: fb.control(null, [Validators.required, CustomValidators.ValidateNumber(29, 7)]),
      type: fb.control(null, Validators.required),
      operation: fb.control(null),
      account: fb.control(null),
      status: fb.control(null),
      sendSwift: fb.control(false),
      SEMEReference: fb.control(null, CustomValidators.SEMEReference),
      operationType: fb.control(null),
      icsdAccountNumber: fb.control(null, CustomValidators.icsdAccountNumber),
      ClearValue: fb.control(null, [Validators.required, CustomValidators.ValidateNumber(29, 7)]),
      ClearOperation: fb.control(null),
      ClearSendSwift: fb.control(false),
      ClearSEMEReference: fb.control(null, CustomValidators.SEMEReference),
      ClearIcsdAccountNumber: fb.control(null, CustomValidators.icsdAccountNumber)
    });
  }
}
