/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ColDef } from 'ag-grid-community/dist/lib/main';
import { InstructionModel } from '../model/instruction.model';

export interface InstructionsColumnDef extends ColDef {
  field: keyof (InstructionModel & InstructionColumnsAction);
}
interface InstructionColumnsAction {
  actions: string;
}

export const INSTRUCTIONS_COLUMNS: InstructionsColumnDef[] = [
  {
    field: 'name',

    filter: 'agMultiColumnFilter',
    width: 250,
    sortable: true,
    resizable: true
  },
  {
    field: 'leiCode',
    sortable: true,
    filter: 'agSetColumnFilter',
    resizable: true
  },
  {
    field: 'currency',
    sortable: true,
    filter: 'agSetColumnFilter',
    resizable: true
  },
  {
    field: 'correspondantBankName',
    sortable: true,
    resizable: true
  },
  {
    field: 'correspondantBankBIC',
    sortable: true,
    resizable: true
  },
  {
    field: 'correspondantBankAccount',
    sortable: true,
    resizable: true
  },
  {
    field: 'intermediaryName',
    sortable: true,
    resizable: true
  },
  {
    field: 'intermediaryBIC',
    sortable: true,
    resizable: true
  },
  {
    field: 'accountBIC',
    sortable: true,
    resizable: true
  },
  {
    field: 'account',
    sortable: true,
    resizable: true
  }
];
