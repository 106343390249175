import { Component, OnInit, forwardRef, Input, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { TYPED_DATA_TYPE, TypedDataModel } from '@shared/models';
import { TypedDataService } from '@shared/services/typed-data.service';
import { map, tap, first } from 'rxjs/operators';

type ModelType = TypedDataModel[] | TypedDataModel | undefined;
type TypedDataModelPayload = TypedDataModel[] | TypedDataModel | undefined;

@Component({
  selector: 'app-event-type-multiselect',
  templateUrl: './event-type-multiselect.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EventTypeMultiselectComponent),
      multi: true
    }
  ]
})
export class EventTypeMultiselectComponent implements ControlValueAccessor, OnInit {
  @Input() multiple = false;
  @Input() closeOnSelect = false;
  @Input() withDebounce = false;
  @Input() type: TYPED_DATA_TYPE | undefined = undefined;
  @Input() placeholder: string | null = null;
  @Input() defaultValue: string | null = null;
  @Input() defaultItems: (string | undefined)[] = [];
  @Input() hasError = false;
  @Output() blurEmit: EventEmitter<void> = new EventEmitter();
  @Output() onchange: EventEmitter<TypedDataModelPayload> = new EventEmitter<TypedDataModelPayload>();
  @Output() inputEmit: EventEmitter<string> = new EventEmitter();

  private selection: ModelType = undefined;
  public items!: TypedDataModel[];
  public isReadOnly = false;
  public focusId: string | null = null;

  constructor(private readonly typeService: TypedDataService) {}

  private sortTypedDataModel(a: TypedDataModel, b: TypedDataModel) {
    if (a.valueDescription < b.valueDescription) {
      return -1;
    }
    if (a.valueDescription > b.valueDescription) {
      return 1;
    }
    return 0;
  }

  ngOnInit(): void {
    if (this.type) {
      this.typeService
        .getTypeByTypedData(this.type)
        .pipe(
          map(res => [...res].sort(this.sortTypedDataModel)),
          first(),
          tap(res => {
            if (this.defaultValue && !this.isReadOnly) {
              this.value = res.find(q => q.valueDescription === this.defaultValue);
            }
          })
        )
        .subscribe(res => {
          if (this.defaultItems.length > 0) {
            this.items = res.filter(x => this.defaultItems.includes(x.valueDescription));
          } else {
            this.items = res;
          }
        });
    } else {
      this.items = [];
    }
  }
  get value(): ModelType {
    return this.selection;
  }
  set value(value: TypedDataModel[] | TypedDataModel | undefined) {
    this.selection = value || undefined;
    this.onChange(value);
    this.onchange.emit(value);
  }
  get displayValue(): TypedDataModel[] {
    if (!this.value) {
      return [];
    }
    return Array.isArray(this.value) ? this.value : [this.value];
  }
  public onBlurEmit(): void {
    this.onTouched();
    this.blurEmit.next();
  }
  public onInputEmit(value: any): void {
    this.inputEmit.next(value);
  }
  onChange!: OnChangeFn<ModelType>;

  onTouched!: OnTouchFn;

  setDisabledState(isDisabled: boolean): void {
    this.isReadOnly = isDisabled;
  }
  registerOnChange(fn: OnChangeFn<ModelType>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchFn): void {
    this.onTouched = fn;
  }

  writeValue(value: ModelType): void {
    this.selection = value;
  }
}
