/**
 * issuer-ipa-events-api API
 * Manage events data
 *
 * The version of the OpenAPI document: 1.1.16
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IpaTypeDto } from './ipaTypeDto';


/**
 * Represent a Event Detail
 */
export interface EventDetails { 
    eventReference?: number;
    timingFundArrivalDate?: string;
    transferMode?: IpaTypeDto;
    recordDate?: string;
    exDate?: string;
    previousPoolFactor?: number;
    newPoolFactor?: number;
    forexRate?: number;
    couponType?: IpaTypeDto;
    dayCountFraction?: IpaTypeDto;
    redemptionType?: IpaTypeDto;
    numOfDayOfThePeriod?: number;
    interestRate?: number;
    denominationAsString?: string;
    paymentCurrency?: string;
    outStandingNominalAsString?: string;
    idUserCreate?: string;
    timestampCreate?: string;
    maturityDate?: string;
    underlyingIsin?: string;
    underlyingLabelIsin?: string;
    strikePrice?: number;
    amountPerDenomination?: number;
    eventRedemptionPrice?: number;
    eventRedemptionUnit?: number;
    underlyingDenomQty?: number;
    warnings?: Array<string>;
    statusErrorDesc?: string;
    paymentDate?: string;
    multiplePmtCurrencies?: boolean;
    partialRedemptionRate?: number;
    eocAdjQty?: number;
    clsAdjQty?: number;
    totalAdjQty?: number;
    updateAdjustedQty?: boolean;
    auditValidatedBy?: string;
    auditValidatedTimestamp?: string;
    auditSentForValidation?: string;
    idUserUpdated?: string;
    timestampUpdated?: string;
    narrative?: string;
}

