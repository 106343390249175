/**
 * issuer-ipa-events-api API
 * Manage events data
 *
 * The version of the OpenAPI document: 1.3.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IpaTypeDto } from './ipaTypeDto';


export interface SwiftMessageDto { 
    id?: number;
    eventId?: number;
    swiftMessage?: string;
    swiftSemeRef?: string;
    swiftCorpRef?: string;
    swiftTraRef?: string;
    swiftMessageError?: string;
    swiftMessageFunction?: IpaTypeDto;
    swiftMessageType?: IpaTypeDto;
    status?: IpaTypeDto;
}

