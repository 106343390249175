/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * searchDoc
 */
export interface SearchDocsDto { 
    /**
     * Value type tech can be [instrument, programme, confirmationReports]
     */
    typeTech?: string;
    /**
     * Value ID for typeTech instrument and programme
     */
    idTech?: string;
    /**
     * Value for specific search data in metadata sg doc
     */
    query?: string;
}

