import { InstrumentCriteriaSearchDto } from '@apis/backend/instruments/model/instrumentCriteriaSearchDto';
import { PartyModel, TypedDataModel } from '@shared/models';
import { notEmpty } from '@utils/utility-functions';

export interface InstrumentCriteriaSearchModel {
  ipaNumber?: string;
  issuers?: PartyModel[];
  isin?: string;
  icsd?: string;
  serialNumber?: string;
  instrumentType?: TypedDataModel;
  status?: TypedDataModel | null;
  finalTermReceived?: boolean;
  issuerPerimter?: boolean;
  beginIssuedate?: string;
  endIssuedate?: string;
  instrumentName?: string;
  paymentType?: TypedDataModel;
  settlementStatus?: TypedDataModel[];
  settlementCurrency?: string;
  withoutSettlement?: boolean;
  cdFtReceived?: boolean;
  cdGnReceived?: boolean;
  permanentMonitoring?: string;
  disposalMonitoring?: TypedDataModel;
}

export const toInstrumentCriteriaSearchDto = (model: InstrumentCriteriaSearchModel | null | undefined): InstrumentCriteriaSearchDto | undefined => {
  if (!model) {
    return undefined;
  }

  const disposalMonitoring = model?.disposalMonitoring?.valueId !== undefined ? model.disposalMonitoring.valueId : (model.disposalMonitoring as any);
  let isSTA = undefined;

  if (model?.issuerPerimter) {
    isSTA = model.issuerPerimter.toString() === 'SPI' ? false : true;
  }

  return {
    ipaNumber: model.ipaNumber,
    issuerNames: model.issuers ? model.issuers.map(q => q.name).filter(notEmpty) : [],
    isin: model.isin,
    icsd: model.icsd,
    serialNumber: model.serialNumber,
    instrumentType: model.instrumentType?.valueId?.toString(),
    status: model.status?.valueId?.toString(),
    finalTermReceived: model.finalTermReceived,
    beginIssueDateAsString: model.beginIssuedate ? toFilterDateString(model.beginIssuedate, 0) : undefined,
    endIssueDateAsString: model.endIssuedate ? toFilterDateString(model.endIssuedate, 23) : undefined,
    instrumentName: model.instrumentName,
    paymentType: model.paymentType?.valueId?.toString(),
    settlementStatuses: model.settlementStatus?.map(q => q.valueId.toString()),
    settlementCurrency: model.settlementCurrency,
    withoutSettlement: model?.withoutSettlement || false,
    isSTA,
    isFtReceived: model.cdFtReceived,
    isGnReceived: model.cdGnReceived,
    pmMonitoring: model.permanentMonitoring ? toPermanentMonitoringDto(model.permanentMonitoring) : undefined,
    clearer: model.disposalMonitoring ? toClearerEnum(disposalMonitoring) : undefined
  };
};

export const toInstrumentCriteriaSearchModel = (dto: InstrumentCriteriaSearchDto | null | undefined): InstrumentCriteriaSearchModel | null => {
  if (!dto) {
    return null;
  }

  return {
    ipaNumber: dto.ipaNumber,
    isin: dto.isin,
    icsd: dto.icsd,
    serialNumber: dto.serialNumber,
    finalTermReceived: dto.finalTermReceived,
    beginIssuedate: dto.beginIssueDateAsString,
    endIssuedate: dto.endIssueDateAsString,
    instrumentName: dto.instrumentName,
    withoutSettlement: dto.withoutSettlement || false
  };
};

const toFilterDateString = (date: string, h: number): string => {
  return new Date(Date.UTC(+date.split('-')[2].split('T')[0], +date.split('-')[1] - 1, +date.split('-')[0], h)).toISOString();
};

const toClearerEnum = (value: string): InstrumentCriteriaSearchDto.ClearerEnum => {
  const oneOrTwo = value === 'One' ? InstrumentCriteriaSearchDto.ClearerEnum.ONE : InstrumentCriteriaSearchDto.ClearerEnum.TWO;
  return value === 'None' ? InstrumentCriteriaSearchDto.ClearerEnum.NONE : oneOrTwo;
};

const toPermanentMonitoringDto = (value: string): string => {
  return value === 'Yes' ? 'true' : value === 'No' ? 'false' : '';
};
