import { Component, Input, OnDestroy } from '@angular/core';
import { SettlementModel } from '@instruments/models/settlement.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-settlement-history',
  templateUrl: './settlement-history.component.html',
  styleUrls: ['./settlement-history.component.scss']
})
export class SettlementHistoryComponent implements OnDestroy {
  public ClosedReason: DialogResult = 'closed';
  public CancelReason: DialogResult = 'cancel';
  public ConfirmReason: DialogResult = 'confirm';
  private readonly subscriptions: Subscription[] = [];

  confirmButton = 'modals.buttons.confirm';
  closeButton = 'modals.buttons.close';

  @Input() settlement: SettlementModel | null | undefined;

  constructor(private readonly activeModal: NgbActiveModal) {}

  public onClose(): void {
    this.activeModal.dismiss(this.ClosedReason);
    this.activeModal.close(this.ClosedReason);
  }

  public onCancel(): void {
    this.activeModal.close(this.CancelReason);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
