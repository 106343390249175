import { EventCriteriaSearchModel } from './event-criteria-search.model';
import { SecheduleSearchInput, SearchSchedulePaginationDto } from '@apis/backend/events';
import { TypedDataModel } from '@shared/models';

export interface ScheduleSearchInputModel {
  itemsByPage?: number;
  isSTA?: boolean;
  isinLists?: string[];
  ipaCodeLists?: number[];
  eventId?: number[];
  notificationFromDate?: string;
  notificationToDate?: string;
  valueFromDate?: string;
  valueToDate?: string;
  reqFundFromDate?: string;
  reqFundToDate?: string;
  preAdviceFromDate?: string;
  preAdviceToDate?: string;
  eventStatuses?: number[];
  eventTypes?: number[];
  pageNumber?: number;
  pageSize?: number;
  paymentCurrencies?: string[];
}

export const toScheduleSearchInputDto = (model: ScheduleSearchInputModel | null | undefined): SecheduleSearchInput | undefined => {
  if (!model) {
    return undefined;
  }
  return {
    isSTA: model.isSTA,
    isinLists: model.isinLists,
    ipaCodeLists: model.ipaCodeLists,
    eventReferences: model.eventId,
    notificationFromDate: model.notificationFromDate ? toDateFormat(model.notificationFromDate) : undefined,
    notificationToDate: model.notificationToDate ? toDateFormat(model.notificationToDate) : undefined,
    valueFromDate: model.valueFromDate ? toDateFormat(model.valueFromDate) : undefined,
    valueToDate: model.valueToDate ? toDateFormat(model.valueToDate) : undefined,
    reqFundFromDate: model.reqFundFromDate ? toDateFormat(model.reqFundFromDate) : undefined,
    reqFundToDate: model.reqFundToDate ? toDateFormat(model.reqFundToDate) : undefined,
    preAdviceFromDate: model.preAdviceFromDate ? toDateFormat(model.preAdviceFromDate) : undefined,
    preAdviceToDate: model.preAdviceToDate ? toDateFormat(model.preAdviceToDate) : undefined,
    eventStatuses: model.eventStatuses,
    eventTypes: model.eventTypes,
    paymentCurrencies: model?.paymentCurrencies
  };
};

export const toScheduleSearchPageInputDto = (model: ScheduleSearchInputModel | null | undefined, itemsByPage: number): SearchSchedulePaginationDto | undefined => {
  if (!model) {
    return undefined;
  }
  return {
    pageNumber: model.pageNumber,
    itemsByPage: itemsByPage,
    criteria: {
      isSTA: model.isSTA,
      isinLists: model.isinLists,
      ipaCodeLists: model.ipaCodeLists,
      eventReferences: model.eventId,
      notificationFromDate: model.notificationFromDate ? toDateFormat(model.notificationFromDate) : undefined,
      notificationToDate: model.notificationToDate ? toDateFormat(model.notificationToDate) : undefined,
      valueFromDate: model.valueFromDate ? toDateFormat(model.valueFromDate) : undefined,
      valueToDate: model.valueToDate ? toDateFormat(model.valueToDate) : undefined,
      reqFundFromDate: model.reqFundFromDate ? toDateFormat(model.reqFundFromDate) : undefined,
      reqFundToDate: model.reqFundToDate ? toDateFormat(model.reqFundToDate) : undefined,
      preAdviceFromDate: model.preAdviceFromDate ? toDateFormat(model.preAdviceFromDate) : undefined,
      preAdviceToDate: model.preAdviceToDate ? toDateFormat(model.preAdviceToDate) : undefined,
      eventStatuses: model.eventStatuses,
      eventTypes: model.eventTypes,
      pageSize: model.pageSize,
      paymentCurrencies: model.paymentCurrencies
    }
  };
};

export const toScheduleSearchInputModel = (scheduleSearchInput: EventCriteriaSearchModel, ipaCodes?: number[], pageNumber?: number): ScheduleSearchInputModel | null => {
  const searchInputIpaCodes = scheduleSearchInput?.ipaCode ? [Number(scheduleSearchInput.ipaCode)] : [];
  return {
    pageNumber: pageNumber,
    isSTA: scheduleSearchInput?.issuerPerimter ? (scheduleSearchInput?.issuerPerimter === 'STA' ? true : false) : undefined,
    isinLists: scheduleSearchInput?.isin ? Array.from(new Array(scheduleSearchInput.isin)) : [],
    ipaCodeLists: ipaCodes ? ipaCodes : searchInputIpaCodes,
    eventId: scheduleSearchInput?.eventId ? [Number(scheduleSearchInput.eventId)] : [],
    notificationFromDate: scheduleSearchInput?.notificationFromDate?.replace(/-/gi, '/'),
    notificationToDate: scheduleSearchInput?.notificationToDate?.replace(/-/gi, '/'),
    valueFromDate: scheduleSearchInput?.valueFromDate?.replace(/-/gi, '/'),
    valueToDate: scheduleSearchInput?.valueToDate?.replace(/-/gi, '/'),
    reqFundFromDate: scheduleSearchInput?.reqFundFromDate?.replace(/-/gi, '/'),
    reqFundToDate: scheduleSearchInput?.reqFundToDate?.replace(/-/gi, '/'),
    preAdviceFromDate: scheduleSearchInput?.preAdviceFromDate?.replace(/-/gi, '/'),
    preAdviceToDate: scheduleSearchInput?.preAdviceToDate?.replace(/-/gi, '/'),
    eventStatuses: convertToNumberArray(scheduleSearchInput?.eventStatuses as TypedDataModel[]),
    eventTypes: convertToNumberArray(scheduleSearchInput?.eventTypes),
    paymentCurrencies: scheduleSearchInput?.paymentCurrencies
  };
};

const convertToNumberArray = (event: TypedDataModel[] | null | undefined): number[] => {
  const arr: number[] = [];
  event?.forEach(element => {
    arr.push(Number(element.valueId));
  });
  return arr;
};

const toDateFormat = (dateString: string): string => {
  const [year, month, day] = dateString.split('/');
  return `${day}-${month}-${year}`;
};
