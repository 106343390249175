<div class="section-forms container g-0" [formGroup]="formGroup">
  <div class="row gy-4">
    <div class="col-md-6">
      <app-form-item [hidden]="hideIsin" [field]="fields.isinReference" [focus]="isinReferenceInput">
        <input class="form-control" type="text" maxlength="12" [formControlName]="fields.isinReference"
          [placeholder]="placeholder(fields.isinReference) | translate" component #isinReferenceInput />
      </app-form-item>
    </div>
  </div>
  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item [field]="fields.issueDate" [focus]="issueDateInput">
        <app-date-picker [formControlName]="fields.issueDate" #issueDateInput component></app-date-picker>
      </app-form-item>
    </div>
    <div class="col-md-6">
      <app-form-item [field]="fields.interestCommencementDate" [focus]="interestCommencementDateInput">
        <app-date-picker [formControlName]="fields.interestCommencementDate" #interestCommencementDateInput component>
        </app-date-picker>
      </app-form-item>
    </div>
  </div>
  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item [field]="fields.nominal" [focus]="nominalInput">
        <app-number [formControlName]="fields.nominal" [placeholder]="placeholder(fields.nominal) | translate" component
          #nominalInput></app-number>
      </app-form-item>
      <app-form-item [field]="fields.numberOfShares" [focus]="numberOfSharesInput">
        <app-number [formControlName]="fields.numberOfShares"
          [placeholder]="placeholder(fields.numberOfShares) | translate" component #numberOfSharesInput></app-number>
      </app-form-item>
    </div>
    <div class="col-md-6">
      <app-form-item [field]="fields.issuePrice" [focus]="issuePriceInput" labelAddendum="label">
        <app-number type="text" [formControlName]="fields.issuePrice" [appendLabel]="issuePriceAppend"
          [addOnParams]="issuePriceTranslateParams" [placeholder]="placeholder(fields.issuePrice) | translate" component
          #issuePriceInput></app-number>
      </app-form-item>
    </div>
  </div>
  <div class="row gy-4 mt-1" *ngIf="upto">
    <div class="col-md-6">
      <app-form-item [field]="fields.finalTermReceived" [focus]="finalTermReceivedInput">
        <app-yes-no-buttons [formControlName]="fields.finalTermReceived" #finalTermReceivedInput component>
        </app-yes-no-buttons>
      </app-form-item>
    </div>
  </div>
  <div class="row gy-4 mt-1" *ngIf="upto">
    <div class="col-md-6">
      <app-form-item [field]="fields.minimumTrading" [focus]="minimumTradingInput">
        <app-number [formControlName]="fields.minimumTrading"
          [placeholder]="placeholder(fields.minimumTrading) | translate" component #minimumTradingInput></app-number>
      </app-form-item>
    </div>
  </div>
