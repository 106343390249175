import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormService } from '@shared/services/forms/form.service';
import { PositionFormModel } from '@events/models/positionFormModel';

@Injectable({
  providedIn: 'root'
})
export class PositionFormService extends FormService<PositionFormModel> {
  constructor(fb: UntypedFormBuilder) {
    super('positions', fb, {
      tradingMethod: fb.control(null),
      interventionType: fb.control(null)
    });
  }
}
