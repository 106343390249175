import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { PermissionService } from '@shared/services/permission.service';
import { Observable } from 'rxjs';
import { isAuthorized$ } from './permission-utils';
import { first, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
  constructor(private readonly permissionService: PermissionService, private readonly router: Router) {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return isAuthorized$(route?.url[0].path, route?.params?.pageType, () => this.permissionService.getCurrentConnectedUserPermissions()).pipe(
      first(),
      tap(res => {
        if (!res) {
          this.router.navigate(['unauthorized']);
        }
      })
    );
  }
}
