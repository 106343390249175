import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ECashPaymentsContainerComponent, ECashPaymentsDetailComponent } from './components';
import { EcashPaymentsResolve } from './eCash-payments.resolve';

const ECASH_PAYMENT_ROUTES: Routes = [
  { path: 'eCashPayments', component: ECashPaymentsContainerComponent },
  { path: 'eCashPayments/:pageType', component: ECashPaymentsDetailComponent },
  {
    path: 'eCashPayments/:pageType/:id',
    component: ECashPaymentsDetailComponent,
    resolve: { eCashPayments: EcashPaymentsResolve }
  }
];

export const eCashPaymentsRoutes = RouterModule.forRoot(ECASH_PAYMENT_ROUTES, { preloadingStrategy: PreloadAllModules });
