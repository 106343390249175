<div class="section-forms container-fluid g-0" [formGroup]="formGroup">
  <div class="row gy-4">
    <div class="col-md-4">
      <app-form-item [field]="fields.operationType" [focus]="operationTypeField">
        <app-type-multiple-select [formControlName]="fields.operationType" [multiple]="false" [closeOnSelect]="true"
          [type]="OPERATION_TYPE" [hasError]="hasError(fields.operationType)"
          [placeholder]="placeholder(fields.operationType) | translate" #operationTypeField component>
        </app-type-multiple-select>
      </app-form-item>
    </div>
    <div class="col-md-4">
      <app-form-item [field]="fields.valueDate" [focus]="valueDateInput">
        <app-date-picker [formControlName]="fields.valueDate" #valueDateInput component></app-date-picker>
      </app-form-item>
    </div>
    <div class="col-md-4">
      <app-form-item [field]="fields.instructionDate" [focus]="instructionDateInput">
        <app-date-picker [formControlName]="fields.instructionDate" #instructionDateInput component></app-date-picker>
      </app-form-item>
    </div>
  </div>
</div>
