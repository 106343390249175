import { InstrumentRoleDataCreateDto } from '@apis/backend/instruments';
import { PartyModel, toPartyDto, toPartyModel } from '@shared/models';

export interface RoleDataModel {
  issuingAgent: PartyModel | null;
  principalPayingAgent: PartyModel | null;
  commonDepositary: PartyModel | null;
  registrar: PartyModel | null;
  commonServiceProvider: PartyModel | null;
  commonSafeKeeper: PartyModel | null;
  fiscalAgent: PartyModel | null;
  localPayingAgent: PartyModel | null;
  listingAgent: PartyModel | null;
  calculationAgent: PartyModel | null;
  deskCalculationAgent: PartyModel | null;
  transferAgent: PartyModel | null;
  cashManager: PartyModel | null;
  escrowManager: PartyModel | null;
  potentialEurosystemEligibility: boolean;
}

export const toRoleDataDto = (model: RoleDataModel | null | undefined): InstrumentRoleDataCreateDto | undefined => {
  if (!model) {
    return undefined;
  }

  return {
    issuingAgent: toPartyDto(model.issuingAgent),
    principalPayingAgent: toPartyDto(model.principalPayingAgent),
    commonDepositary: toPartyDto(model.commonDepositary),
    registrar: toPartyDto(model.registrar),
    commonServiceProvider: toPartyDto(model.commonServiceProvider),
    commonSafeKeeper: toPartyDto(model.commonSafeKeeper),
    fiscalAgent: toPartyDto(model.fiscalAgent),
    localPayingAgent: toPartyDto(model.localPayingAgent),
    listingAgent: toPartyDto(model.listingAgent),
    calculationAgent: toPartyDto(model.calculationAgent),
    deskCalculationAgent: toPartyDto(model.deskCalculationAgent),
    transferAgent: toPartyDto(model.transferAgent),
    cashManager: toPartyDto(model.cashManager),
    escrowManager: toPartyDto(model.escrowManager),
    potentialEurosystemEligibility: model.potentialEurosystemEligibility
  };
};

export const toRoleDataModel = (dto?: InstrumentRoleDataCreateDto | null | undefined): RoleDataModel => {
  return {
    issuingAgent: toPartyModel(dto?.issuingAgent),
    principalPayingAgent: toPartyModel(dto?.principalPayingAgent),
    commonDepositary: toPartyModel(dto?.commonDepositary),
    registrar: toPartyModel(dto?.registrar),
    commonServiceProvider: toPartyModel(dto?.commonServiceProvider),
    commonSafeKeeper: toPartyModel(dto?.commonSafeKeeper),
    fiscalAgent: toPartyModel(dto?.fiscalAgent),
    localPayingAgent: toPartyModel(dto?.localPayingAgent),
    listingAgent: toPartyModel(dto?.listingAgent),
    calculationAgent: toPartyModel(dto?.calculationAgent),
    deskCalculationAgent: toPartyModel(dto?.deskCalculationAgent),
    transferAgent: toPartyModel(dto?.transferAgent),
    cashManager: toPartyModel(dto?.cashManager),
    escrowManager: toPartyModel(dto?.escrowManager),
    potentialEurosystemEligibility: dto?.potentialEurosystemEligibility || false
  };
};
