import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { eventsRoutes } from './events.routing';
import {
  EventsContainerComponent,
  EventListFilterComponent,
  EventListActiveFiltersComponent,
  EventFilterSelectorComponent,
  EventsListComponent,
  EventActionComponent,
  EventCreationComponent,
  EventFormContainerComponent,
  EventSaveCancelComponent,
  EventTypeMultiselectComponent,
  EventRegenerateFormComponent,
  RegenerateSchedulesComponent,
  PositionsListComponent,
  PositionContainerComponent,
  EventsPositionActionComponent,
  EventsClearStreamActionComponent,
  SwiftsContainerComponent,
  EventDatesModificationComponent,
  EventDateFormComponent,
  EventDetailComponent,
  EventFiltersSaveComponent,
  PositionFormComponent,
  EcashPaymentsContainerComponent,
  EventAuditFormComponent,
  EventAuditDetailsComponent
} from './components';
import { HeaderModule } from '@shared/modules/header/header.module';
import { ButtonsModule } from '@shared/modules/buttons/buttons.module';
import { TypeSingleSelectModule } from '@shared/modules/type-single-select/type-single-select.module';
import { TypeMultipleSelectModule } from '@shared/modules/type-multiple-select/type-multiple-select.module';
import { LocalCustomFormModule } from '@shared/modules/forms/local-custom-form.module';
import { PaginationModule } from '@shared/modules/pagination/pagination.module';
import { FilterSelectorServiceInjectionToken } from '@shared/modules/save-filter/tokens/filter-selector.token';
import { DatePickerModule } from '@shared/modules/date-picker/date-picker.module';
import { EventFilterSelectorService } from './services/event-filter-selector.service';
import { CustomMultipleSelectModule } from '@shared/modules/custom-multiple-select/custom-multiple-select.module';
import { PartyMultipleSelectModule } from '@shared/modules/party-multiple-select/party-multiple-select.module';
import { NumberInputModule } from '@shared/modules/number-input/number-input.module';
import { YesNoButtonsModule } from '@shared/modules/yes-no-buttons/yes-no-buttons.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CurrencyMultipleSelectModule } from '@shared/modules/currency-multiple-select/currency-multiple-select.module';

@NgModule({
  declarations: [
    EventActionComponent,
    EventsContainerComponent,
    EventCreationComponent,
    EventFilterSelectorComponent,
    EventsListComponent,
    EventListActiveFiltersComponent,
    EventListFilterComponent,
    EventFormContainerComponent,
    EventDetailComponent,
    EventSaveCancelComponent,
    EventTypeMultiselectComponent,
    EventRegenerateFormComponent,
    EventFiltersSaveComponent,
    EventsPositionActionComponent,
    EventsClearStreamActionComponent,
    PositionsListComponent,
    PositionContainerComponent,
    RegenerateSchedulesComponent,
    SwiftsContainerComponent,
    EventDateFormComponent,
    EventDatesModificationComponent,
    PositionFormComponent,
    EcashPaymentsContainerComponent,
    EventAuditFormComponent,
    EventAuditDetailsComponent
  ],
  exports: [],
  providers: [{ provide: FilterSelectorServiceInjectionToken, useExisting: EventFilterSelectorService }],
  imports: [
    eventsRoutes,
    AgGridModule.withComponents([]),
    ButtonsModule,
    BrowserAnimationsModule,
    CommonModule,
    DatePickerModule,
    FormsModule,
    HeaderModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule.forRoot(),
    TypeSingleSelectModule,
    TypeMultipleSelectModule,
    TranslateModule,
    LocalCustomFormModule,
    PaginationModule,
    CustomMultipleSelectModule,
    PartyMultipleSelectModule,
    NumberInputModule,
    BsDropdownModule.forRoot(),
    YesNoButtonsModule,
    CurrencyMultipleSelectModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EventsModule {
  static forRoot(): ModuleWithProviders<EventsModule> {
    return {
      ngModule: EventsModule
    };
  }
}
