import { Component, OnInit, Input } from '@angular/core';
import { ColDef, GridOptions, GridApi } from 'ag-grid-community';
import { EcashPaymentDetailsResponse } from '@apis/backend/events';
import { EcashPaymentsColumnDefService } from '../_aggrid/ecash-payments-column-def-service';
import { EcashPaymentsGridModel, toEcashPaymentsModal } from '@events/models';

@Component({
  selector: 'app-ecash-payments-container',
  templateUrl: './ecash-payments-container.component.html',
  providers: [EcashPaymentsColumnDefService]
})
export class EcashPaymentsContainerComponent implements OnInit {
  @Input() public eCashPaymentsData?: EcashPaymentDetailsResponse;
  public columnDefs: ColDef[] = [];
  public gridOptions!: GridOptions;
  public gridApi!: GridApi;
  public eCashPaymentsRowData: EcashPaymentsGridModel[] | undefined;

  constructor(private readonly ecashPaymentsColumnDefService: EcashPaymentsColumnDefService) {}

  ngOnInit(): void {
    this.fetchEcashPayments();
    this.columnDefs = this.ecashPaymentsColumnDefService.get();
    this.gridOptions = {
      onGridSizeChanged: () => {
        this.gridOptions.api?.sizeColumnsToFit();
      },
      pagination: false,
      suppressPaginationPanel: false,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      suppressContextMenu: true,
      stopEditingWhenCellsLoseFocus: true
    };
  }

  onGridReady(params: { api: GridApi }): void {
    this.columnDefs = this.ecashPaymentsColumnDefService.get();

    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.refreshHeader();
  }

  public fetchEcashPayments() {
    const rowData: EcashPaymentsGridModel[] = [];
    this.eCashPaymentsData?.ecashPaymentDetails?.forEach((eCashPayment: EcashPaymentsGridModel) => {
      const ecashPayment = toEcashPaymentsModal(eCashPayment);
      rowData.push(ecashPayment);
      this.eCashPaymentsRowData = rowData;
    });
  }
}
