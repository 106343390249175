import { PositionDataFetchDto } from '@apis/backend/instruments/model/positionDataFetchDto';
import { PartyModel, toPartyModel } from '@shared/models';
import { PositionCriteriaSearchDto } from '@apis/backend/instruments';

export interface PositionModel {
  isin?: string;
  ipaCode?: number;
  seriesNumber?: string;
  valueDate?: string;
  currency?: string;
  icsd?: PartyModel | null;
  account?: string;
  totalAmount?: string;
  blockedAmount?: string;
  unblockedAmount?: string;
  depositary?: PartyModel | null;
}

export const toPositionFetchModel = (dto: PositionDataFetchDto): PositionModel => {
  return {
    ipaCode: dto?.ipaCode,
    icsd: toPartyModel(dto?.csd),
    currency: dto?.currency,
    isin: dto?.isinCode,
    seriesNumber: dto?.seriesNumber,
    valueDate: dto?.valueDate,
    account: dto?.accountNumber,
    unblockedAmount: dto?.unBlockValue ? Number(dto?.unBlockValue).toString() : '',
    blockedAmount: dto?.blockValue ? Number(dto?.blockValue).toString() : '',
    totalAmount: dto?.totalValue ? Number(dto?.totalValue).toString() : '',
    depositary: toPartyModel(dto?.depositary)
  };
};

export const toInstrumentPositionSearchDto = (isin: string): PositionCriteriaSearchDto => {
  return {
    isin: isin
  };
};
