import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { PaymentActionComponent } from './payment-action/payment-action.component';
import { PaymentModel } from 'src/app/payment/models';
import * as moment from 'moment';
import { MOMENT_DATE_FORMAT } from '@shared/pipes/date-format/common';

export interface PaymentColumnDef extends ColDef {
  field: keyof (PaymentModel & PaymentColumnsAction);
}
interface PaymentColumnsAction {
  actions: string;
}

export const PAYMENT_COLUMNS: PaymentColumnDef[] = [
  {
    field: 'payment'
  },
  {
    field: 'isin'
  },
  {
    field: 'eventType'
  },
  {
    field: 'valueDate',
    valueFormatter: (q: ValueFormatterParams) => {
      if (!q.data.valueDate) {
        return '';
      }
      const momentDate = moment(q.data.valueDate);
      return momentDate.format(MOMENT_DATE_FORMAT);
    }
  },
  {
    field: 'paymentReceiptDate',
    width: 215,
    valueFormatter: (q: ValueFormatterParams) => {
      if (!q.data.paymentReceiptDate) {
        return '';
      }
      const momentDate = moment(q.data.paymentReceiptDate);
      return momentDate.format(MOMENT_DATE_FORMAT);
    }
  },
  {
    field: 'amount'
  },
  {
    field: 'paymentCcy'
  },
  {
    field: 'cancelled'
  },
  {
    field: 'adviceNumber'
  },
  {
    field: 'details'
  },
  {
    field: 'paymentStatus'
  },
  {
    field: 'paymentRejectReason'
  },
  {
    field: 'comments',
    cellStyle: {
      backgroundColor: '#EAB0A3'
    }
  },
  {
    field: 'actions',
    cellRendererFramework: PaymentActionComponent,
    minWidth: 80,
    pinned: 'right'
  }
];
