/**
 * issuer-ipa-events-api API
 * Manage events data
 *
 * The version of the OpenAPI document: 1.3.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IpaTypeDto } from './ipaTypeDto';


/**
 * Represent a Schedule event
 */
export interface ScheduleEvent { 
    isSTA?: boolean;
    scheduleRegenerated?: boolean;
    eventReference?: number;
    instrumentId?: number;
    eventDate?: string;
    beginDate?: string;
    endDate?: string;
    valueDate?: string;
    notificationDate?: string;
    requestOfFundsDate?: string;
    preAdviceDate?: string;
    validationOfPaymentDate?: string;
    nominalCurrency?: string;
    status?: IpaTypeDto;
    eventType?: IpaTypeDto;
    couponFrequency?: IpaTypeDto;
}

