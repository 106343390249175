import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NullableYesNoButtonsComponent } from './nullable-yes-no-buttons.component';

@NgModule({
  declarations: [NullableYesNoButtonsComponent],
  imports: [CommonModule],
  exports: [NullableYesNoButtonsComponent]
})
export class NullableYesNoButtonsModule {}
