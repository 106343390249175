<h5>{{'instruments.instrument.forms.tranche-history.seriesNumber'|translate}}<span
  class="ms-2">{{instrument?.seriesNumber}}</span></h5>
<div class="row mt-2">
<div class="col-md-12">

  <ag-grid-angular #agGrid class="ag-theme-sg-bootstrap-condensed" [gridOptions]="gridOptions!"
    [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [rowData]="tranches$|async"
    (gridReady)="onGridReady($event)">
  </ag-grid-angular>
</div>
