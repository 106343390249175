import { Injectable } from '@angular/core';
import { TrancheFormContainerComponent } from '@instruments/components/tranche-form-container/tranche-form-container.component';
import { InstrumentModel } from '@instruments/models';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { INSTRUMENT_ACTIONS } from '@shared/models';
import { DialogModalComponent } from '@shared/modules/modals/components';

@Injectable({
  providedIn: 'root'
})
export class TrancheModalsService {
  constructor(private readonly modalService: NgbModal) {}

  public openCreateTrancheModal(instrument: InstrumentModel | null): NgbModalRef {
    const modal = this.modalService.open(TrancheFormContainerComponent, { size: 'lg', scrollable: true });
    const dialog: TrancheFormContainerComponent = modal.componentInstance;
    dialog.instrument = instrument;
    return modal;
  }

  public openConfirmChangeStatusModal(status: INSTRUMENT_ACTIONS, dataType: 'instrument' | 'tranche'): NgbModalRef {
    const title = `instruments.modals.titles.${status.toLocaleLowerCase()}.${dataType}`;
    const body = `instruments.modals.contents.${status.toLocaleLowerCase()}.${dataType}`;
    const modal = this.modalService.open(DialogModalComponent);
    const dialog: DialogModalComponent = modal.componentInstance;
    dialog.dialogTitle = title;
    dialog.dialogBody = body;
    return modal;
  }
}
