/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PartyDto } from './partyDto';
import { IpaTypeCreateDto } from './ipaTypeCreateDto';


/**
 * Instrument data needed for matrix recovery
 */
export interface MatrixInstrumentRoleRequestDto { 
    icsdProgrammeNumber?: string;
    isinReference?: string;
    instrumentType?: IpaTypeCreateDto;
    listingCountry?: IpaTypeCreateDto;
    clearingSystems?: Array<PartyDto>;
}

