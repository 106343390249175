<div class="modal-header">
    <h3 class="modal-title">{{ 'modals.titles.auditDetails'| translate}}</h3>
    <button type="button" class="close icon p-1" (click)="onClose()">close</button>
</div>
<div class="modal-body">
    <app-event-audit-details></app-event-audit-details>
</div>
<div class="modal-footer">
    <button type="button" (click)="onClose()" (keyup.enter)="onClose()" class="btn btn-lg btn-link">{{ closeButton |
        translate }}</button>
</div>
