import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CustomMultipleSelectModule } from '../custom-multiple-select/custom-multiple-select.module';
import { PartyMultipleSelectV2Component } from './v2/party-multiple-select-v2.component';
import { ItemsSelectorModule } from '../../../shared/components/items-selector';

@NgModule({
  declarations: [PartyMultipleSelectV2Component],
  exports: [PartyMultipleSelectV2Component],
  imports: [FormsModule, ReactiveFormsModule, CommonModule, CustomMultipleSelectModule, ItemsSelectorModule]
})
export class PartyMultipleSelectModule {}
