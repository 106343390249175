/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { Problem } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class TypedDataResourceService {

    protected basePath = 'https://issuer-ipa-develop.dev.socgen';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get types by typed data
     * Return list of Type corresponding of a Typed Data  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.referential.type; Authorities: SGM_ISS_PAY_api.issuer-ipa.referential.type
     * @param typedData Value of Typed Data: BUSINESS_DAY_CONVENTION, CALCULATION_TIME, COUPON_FREQUENCY, COUPON_TYPE, SECOND_COUPON_TYPE, DAY_COUNT_FRACTION, SECOND_DAY_COUNT_FRACTION, GLOBAL_NOTE_FORM, INSTRUMENT_REGULATION, INSTRUMENT_TYPE, LABEL_CATEGORY, LEGAL_DESK_ISSUER_COUNTRY, LEGAL_FORM, LISTING_COUNTRY, LISTING_PLACE, OPTION_EXERCISE_TYPE, PARTIAL_REDEMPTION_TYPE, PAYMENT_DATE_MGT_MODE, PHYSICAL_FORM, RECORD_DATE_CALC_MODE, RECORD_DATE_OFFSET_REF, TRADING_METHOD, INSTRUMENT_WORKFLOW_STATUS, TRANCHE_WORKFLOW_STATUS, INCREASE_WORKFLOW_STATUS, FINANCIAL_CENTER_TRADING_PLACES,SETTLEMENT_TASKS, OPERATION_TYPE, OPERATION_WORKFLOW_STATUS, OPERATION_TYPE , SWIFT_MESSAGE_STATUS , REQUEST_OF_FUNDS_STATUS, SWIFT_MESSAGE_FUNCTION , SWIFT_MESSAGE_TYPE , PAYMENTS_CONFIRMATION_STATUS , INTERVENTION_TYPE
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTypeByTypedData(typedData: 'BUSINESS_DAY_CONVENTION' | 'CALCULATION_TIME' | 'COUPON_FREQUENCY' | 'COUPON_TYPE' | 'SECOND_COUPON_TYPE' | 'DAY_COUNT_FRACTION' | 'SECOND_DAY_COUNT_FRACTION' | 'GLOBAL_NOTE_FORM' | 'INSTRUMENT_REGULATION' | 'INSTRUMENT_TYPE' | 'LABEL_CATEGORY' | 'LEGAL_DESK_ISSUER_COUNTRY' | 'LEGAL_FORM' | 'LISTING_COUNTRY' | 'LISTING_PLACE' | 'OPTION_EXERCISE_TYPE' | 'PARTIAL_REDEMPTION_TYPE' | 'PAYMENT_DATE_MGT_MODE' | 'PHYSICAL_FORM' | 'RECORD_DATE_CALC_MODE' | 'RECORD_DATE_OFFSET_REF' | 'TRADING_METHOD' | 'INSTRUMENT_WORKFLOW_STATUS' | 'TRANCHE_WORKFLOW_STATUS' | 'SETTLEMENT_WORKFLOW_STATUS' | 'INCREASE_WORKFLOW_STATUS' | 'FINANCIAL_CENTER_TRADING_PLACES' | 'PAYMENT_TYPE' | 'CONFIRMATION_STATUS' | 'SETTLEMENT_CLEARING_ISSUING_AGENT' | 'SETTLEMENT_CLEARING_SUBSCRIBER' | 'SETTLEMENT_TASKS' | 'CLEARING_ACCOUNT_NUMBER' | 'EVENT_WORKFLOW_STATUS' | 'TRANSFER_MODE' | 'EVENT_TYPE' | 'EVENT_REDEMPTION_TYPE' | 'VALUATION_STATUS' | 'OPERATION_WORKFLOW_STATUS' | 'OPERATION_TYPE' | 'SWIFT_MESSAGE_STATUS' | 'REQUEST_OF_FUNDS_STATUS' | 'SWIFT_MESSAGE_FUNCTION' | 'SWIFT_MESSAGE_TYPE' | 'PAYMENTS_CONFIRMATION_STATUS' | 'INTERVENTION_TYPE' | 'ECASH_PAYMENT_STATUS' | 'MX_MESSAGE_TYPE', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any>;
    public getTypeByTypedData(typedData: 'BUSINESS_DAY_CONVENTION' | 'CALCULATION_TIME' | 'COUPON_FREQUENCY' | 'COUPON_TYPE' | 'SECOND_COUPON_TYPE' | 'DAY_COUNT_FRACTION' | 'SECOND_DAY_COUNT_FRACTION' | 'GLOBAL_NOTE_FORM' | 'INSTRUMENT_REGULATION' | 'INSTRUMENT_TYPE' | 'LABEL_CATEGORY' | 'LEGAL_DESK_ISSUER_COUNTRY' | 'LEGAL_FORM' | 'LISTING_COUNTRY' | 'LISTING_PLACE' | 'OPTION_EXERCISE_TYPE' | 'PARTIAL_REDEMPTION_TYPE' | 'PAYMENT_DATE_MGT_MODE' | 'PHYSICAL_FORM' | 'RECORD_DATE_CALC_MODE' | 'RECORD_DATE_OFFSET_REF' | 'TRADING_METHOD' | 'INSTRUMENT_WORKFLOW_STATUS' | 'TRANCHE_WORKFLOW_STATUS' | 'SETTLEMENT_WORKFLOW_STATUS' | 'INCREASE_WORKFLOW_STATUS' | 'FINANCIAL_CENTER_TRADING_PLACES' | 'PAYMENT_TYPE' | 'CONFIRMATION_STATUS' | 'SETTLEMENT_CLEARING_ISSUING_AGENT' | 'SETTLEMENT_CLEARING_SUBSCRIBER' | 'SETTLEMENT_TASKS' | 'CLEARING_ACCOUNT_NUMBER' | 'EVENT_WORKFLOW_STATUS' | 'TRANSFER_MODE' | 'EVENT_TYPE' | 'EVENT_REDEMPTION_TYPE' | 'VALUATION_STATUS' | 'OPERATION_WORKFLOW_STATUS' | 'OPERATION_TYPE' | 'SWIFT_MESSAGE_STATUS' | 'REQUEST_OF_FUNDS_STATUS' | 'SWIFT_MESSAGE_FUNCTION' | 'SWIFT_MESSAGE_TYPE' | 'PAYMENTS_CONFIRMATION_STATUS' | 'INTERVENTION_TYPE' | 'ECASH_PAYMENT_STATUS' | 'MX_MESSAGE_TYPE', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<any>>;
    public getTypeByTypedData(typedData: 'BUSINESS_DAY_CONVENTION' | 'CALCULATION_TIME' | 'COUPON_FREQUENCY' | 'COUPON_TYPE' | 'SECOND_COUPON_TYPE' | 'DAY_COUNT_FRACTION' | 'SECOND_DAY_COUNT_FRACTION' | 'GLOBAL_NOTE_FORM' | 'INSTRUMENT_REGULATION' | 'INSTRUMENT_TYPE' | 'LABEL_CATEGORY' | 'LEGAL_DESK_ISSUER_COUNTRY' | 'LEGAL_FORM' | 'LISTING_COUNTRY' | 'LISTING_PLACE' | 'OPTION_EXERCISE_TYPE' | 'PARTIAL_REDEMPTION_TYPE' | 'PAYMENT_DATE_MGT_MODE' | 'PHYSICAL_FORM' | 'RECORD_DATE_CALC_MODE' | 'RECORD_DATE_OFFSET_REF' | 'TRADING_METHOD' | 'INSTRUMENT_WORKFLOW_STATUS' | 'TRANCHE_WORKFLOW_STATUS' | 'SETTLEMENT_WORKFLOW_STATUS' | 'INCREASE_WORKFLOW_STATUS' | 'FINANCIAL_CENTER_TRADING_PLACES' | 'PAYMENT_TYPE' | 'CONFIRMATION_STATUS' | 'SETTLEMENT_CLEARING_ISSUING_AGENT' | 'SETTLEMENT_CLEARING_SUBSCRIBER' | 'SETTLEMENT_TASKS' | 'CLEARING_ACCOUNT_NUMBER' | 'EVENT_WORKFLOW_STATUS' | 'TRANSFER_MODE' | 'EVENT_TYPE' | 'EVENT_REDEMPTION_TYPE' | 'VALUATION_STATUS' | 'OPERATION_WORKFLOW_STATUS' | 'OPERATION_TYPE' | 'SWIFT_MESSAGE_STATUS' | 'REQUEST_OF_FUNDS_STATUS' | 'SWIFT_MESSAGE_FUNCTION' | 'SWIFT_MESSAGE_TYPE' | 'PAYMENTS_CONFIRMATION_STATUS' | 'INTERVENTION_TYPE' | 'ECASH_PAYMENT_STATUS' | 'MX_MESSAGE_TYPE', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<any>>;
    public getTypeByTypedData(typedData: 'BUSINESS_DAY_CONVENTION' | 'CALCULATION_TIME' | 'COUPON_FREQUENCY' | 'COUPON_TYPE' | 'SECOND_COUPON_TYPE' | 'DAY_COUNT_FRACTION' | 'SECOND_DAY_COUNT_FRACTION' | 'GLOBAL_NOTE_FORM' | 'INSTRUMENT_REGULATION' | 'INSTRUMENT_TYPE' | 'LABEL_CATEGORY' | 'LEGAL_DESK_ISSUER_COUNTRY' | 'LEGAL_FORM' | 'LISTING_COUNTRY' | 'LISTING_PLACE' | 'OPTION_EXERCISE_TYPE' | 'PARTIAL_REDEMPTION_TYPE' | 'PAYMENT_DATE_MGT_MODE' | 'PHYSICAL_FORM' | 'RECORD_DATE_CALC_MODE' | 'RECORD_DATE_OFFSET_REF' | 'TRADING_METHOD' | 'INSTRUMENT_WORKFLOW_STATUS' | 'TRANCHE_WORKFLOW_STATUS' | 'SETTLEMENT_WORKFLOW_STATUS' | 'INCREASE_WORKFLOW_STATUS' | 'FINANCIAL_CENTER_TRADING_PLACES' | 'PAYMENT_TYPE' | 'CONFIRMATION_STATUS' | 'SETTLEMENT_CLEARING_ISSUING_AGENT' | 'SETTLEMENT_CLEARING_SUBSCRIBER' | 'SETTLEMENT_TASKS' | 'CLEARING_ACCOUNT_NUMBER' | 'EVENT_WORKFLOW_STATUS' | 'TRANSFER_MODE' | 'EVENT_TYPE' | 'EVENT_REDEMPTION_TYPE' | 'VALUATION_STATUS' | 'OPERATION_WORKFLOW_STATUS' | 'OPERATION_TYPE' | 'SWIFT_MESSAGE_STATUS' | 'REQUEST_OF_FUNDS_STATUS' | 'SWIFT_MESSAGE_FUNCTION' | 'SWIFT_MESSAGE_TYPE' | 'PAYMENTS_CONFIRMATION_STATUS' | 'INTERVENTION_TYPE' | 'ECASH_PAYMENT_STATUS' | 'MX_MESSAGE_TYPE', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (typedData === null || typedData === undefined) {
            throw new Error('Required parameter typedData was null or undefined when calling getTypeByTypedData.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (typedData !== undefined && typedData !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>typedData, 'typedData');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/v1/types/_typeByTypedData`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
