import { InstrumentGlobalNoteCreateDto, InstrumentGlobalNoteDto } from '@apis/backend/instruments';
import { IpaTypeModel, toIpaTypeDto, toIpaTypeModel } from '@shared/models';

export interface GlobalNoteModel {
  readonly globalNoteForm: IpaTypeModel | null;
  readonly legalForm: IpaTypeModel | null;
  readonly physicalForm: IpaTypeModel | null;
  readonly globalNoteClassic: boolean;
  readonly regs144LinkedInstrument?: string;
}

export const toGlobalNoteDto = (model: GlobalNoteModel | null | undefined): InstrumentGlobalNoteCreateDto | undefined => {
  if (!model) {
    return undefined;
  }

  return {
    globalNoteForm: toIpaTypeDto(model.globalNoteForm),
    legalForm: toIpaTypeDto(model.legalForm),
    physicalForm: toIpaTypeDto(model.physicalForm),
    globalNoteClassic: model.globalNoteClassic,
    regs144LinkedInstrument: model.regs144LinkedInstrument
  };
};

export const toGlobalNoteModel = (dto: InstrumentGlobalNoteDto | null | undefined): GlobalNoteModel => {
  return {
    globalNoteForm: toIpaTypeModel(dto?.globalNoteForm),
    legalForm: toIpaTypeModel(dto?.legalForm),
    physicalForm: toIpaTypeModel(dto?.physicalForm),
    globalNoteClassic: dto?.globalNoteClassic || false,
    regs144LinkedInstrument: dto?.regs144LinkedInstrument
  };
};
