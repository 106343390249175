import { PaymentsConfirmation } from '@apis/backend/payments';

export interface PaymentModel {
  payment?: number;
  isin?: string;
  eventType?: string;
  valueDate?: string;
  paymentReceiptDate?: string;
  amount?: number;
  paymentCcy?: string;
  cancelled?: string;
  adviceNumber?: string;
  details?: string;
  paymentStatus?: string;
  paymentRejectReason?: string;
  comments?: string;
}

export const toPaymentModel = (payment: PaymentsConfirmation): PaymentModel => {
  return {
    payment: payment.id,
    isin: payment.isin,
    eventType: payment.eventTypeTypedDataType?.ipaDataType?.valueDescription,
    valueDate: payment.valueDate,
    paymentReceiptDate: payment.receiptDate,
    amount: payment.amount,
    paymentCcy: payment.currency,
    cancelled: payment.isCancelled ? 'Y' : 'N',
    adviceNumber: payment.adviceNumber,
    details: payment.comments,
    paymentStatus: payment.paymentStatusTypedDataType?.ipaDataType?.valueDescription,
    paymentRejectReason: payment.paymentRejectReason,
    comments: payment.paymentRemarks
  };
};
