/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Tranche to update
 */
export interface InstrumentTrancheUpdateDto { 
    interestCommencementDateAsString?: string;
    finalTermReceived?: boolean;
    globalNoteReceived?: boolean;
    issueDateAsString?: string;
    issuePrice?: string;
    minimumTrading?: string;
    nominal?: string;
    outstandingNominal?: string;
    numberOfShares?: string;
    isinCodeTranche?: string;
    lastUpdateDateTimeAsString?: string;
}

