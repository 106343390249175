<button class="btn btn-sm btn-flat" (click)="goToEventDetail()" >
  <em class="icon">visibility</em>
</button>

<button *ngIf="canDeleteEvents" class="btn btn-sm btn-flat" [disabled]="isDisabled"  (click)="onDeleteEventClick()">
  <em class="icon">delete</em>
</button>

<button  class="btn btn-sm btn-flat" (click)="updateEventDates()">
  <em class="icon">edit</em>
</button>
