/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridApi, GridOptions } from 'ag-grid-community';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-ag-grid',
  templateUrl: './custom-ag-grid.component.html',
  styleUrls: ['./custom-ag-grid.component.scss']
})
export class CustomAgGridComponent {
  public gridApi: GridApi | undefined;
  public currentPage = 0;
  public maxPage = 0;

  public currentPageTest: number | undefined;
  public maxPageTest: number | undefined;
  public pageSizeTest: number | undefined;

  @Input() gridOptions: GridOptions | undefined;
  @Input() defaultColDef: any | undefined;
  @Input() columnDefs: any | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _data: any[] | undefined;

  @Input() set data(value: any[]) {
    this._data = value;
    if (this.gridOptions) {
      this.gridOptions.api?.paginationGoToFirstPage();
    }
  }

  @Output() goToDetailOutput: EventEmitter<void> = new EventEmitter();
  @Output() cellValueChangeOutput: EventEmitter<void> = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridApi?.sizeColumnsToFit();
    this.gridApi?.setDomLayout('autoHeight');
    this.maxPage = this.gridApi?.paginationGetTotalPages() ?? 0;
    this.currentPage = this.gridApi?.paginationGetCurrentPage() ?? 0;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onRowSelected() {}

  canDelete() {
    // To enable delete support only if current user has 'DeleteIssuance' SG|IAM permission
    // let allowDelete = this.gridApi && this.gridApi.getSelectedRows().length > 0;
    return this.gridApi && this.gridApi.getSelectedRows().length > 0;
  }

  goToDetail(event: any) {
    this.goToDetailOutput?.emit(event);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onCellValueChanged(event: any) {
    this.cellValueChangeOutput?.emit();
  }

  onPaginationChanged() {
    if (this.gridApi) {
      this.maxPage = this.gridApi.paginationGetTotalPages();
      this.currentPage = this.gridApi.paginationGetCurrentPage();
    }
  }
}
