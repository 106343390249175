/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FiltersSaveBaseComponent } from '@shared/modules/save-filter/filters-save/filters-save-base.component';
import { ValuationCriteriaSearchModel } from '@valuation/models';
import { ValuationFilterSelectorService } from '@valuation/services';

@Component({
  selector: 'app-valuation-filters-save',
  templateUrl: './valuation-filters-save.component.html'
})
export class ValuationFiltersSaveComponent extends FiltersSaveBaseComponent<ValuationCriteriaSearchModel, ValuationFilterSelectorService> {
  constructor(public activeModal: NgbActiveModal, filterSelectorService: ValuationFilterSelectorService) {
    super(activeModal, filterSelectorService);
  }
}
