import { Injectable } from '@angular/core';
import { AgGridColumnDefBaseService } from '@shared/services/aggrid-column-def-base-service';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';

import { PROGRAMMES_COLUMNS } from './programmes-column-def';

@Injectable()
export class ProgrammeColumnDefService extends AgGridColumnDefBaseService {
  constructor(translateService: I18nUtilsService) {
    super(translateService, 'programmes.list.headers', PROGRAMMES_COLUMNS);
  }
}
