import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { PartyMultipleCheckComponent } from './party-multiple-check.component';

@NgModule({
  declarations: [PartyMultipleCheckComponent],
  exports: [PartyMultipleCheckComponent],
  imports: [FormsModule, ReactiveFormsModule, NgSelectModule, CommonModule]
})
export class PartyMultipleCheckModule {}
