export interface DayDate {
  day: number;
  month: number;
}

export const dayDateToString = (date: DayDate): string => {
  return `${date.day.toString().padStart(2, '0')}${date.month.toString().padStart(2, '0')}`;
};
export const stringToDayDate = (date: string | null | undefined): DayDate | null => {
  if (!date) {
    return null;
  }

  return {
    day: +date.substring(0, 2) || 0,
    month: +date.substring(3, 4) || 0
  };
};
