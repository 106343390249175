import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CustomMultipleSelectComponent } from './custom-multiple-select.component';
import { ItemsSelectorModule } from '../../../shared/components/items-selector';

@NgModule({
  declarations: [CustomMultipleSelectComponent],
  exports: [CustomMultipleSelectComponent],
  imports: [FormsModule, ReactiveFormsModule, CommonModule, ItemsSelectorModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CustomMultipleSelectModule {}
