import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HideAndShowComponent } from './hide-and-show/hide-and-show.component';

@NgModule({
  declarations: [HideAndShowComponent],
  exports: [HideAndShowComponent],
  imports: [CommonModule]
})
export class HideAndShowModule {}
