<div class="d-flex flex-row align-items-center">
  <button class="btn btn-lg btn-flat-secondary m-1" type="button" (click)="changeHide()">
    <em *ngIf="hide" class="icon"> keyboard_arrow_up </em>
    <em *ngIf="!hide" class="icon"> keyboard_arrow_down</em>
  </button>
  <div class="mt-1">
    <h5>{{ title }}</h5>
  </div>
</div>
<div class="mt-4" *ngIf="!hide">
  <ng-content></ng-content>
</div>
