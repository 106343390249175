<app-header (goBack)="onBackClick()" [moveElementDown]="bodyContainer">
  <div headerButton>
    <div *ngIf="!isEditMode && canModifyValuation">
      <button (click)="onEditClick()" class="btn btn-flat-info btn-icon-start">
        <em class="icon">mode_edit</em>
        <span class="title-case" translate>valuation.buttons.modify</span>
      </button>
    </div>
    <app-valuation-save-cancel *ngIf="isEditMode"></app-valuation-save-cancel>
  </div>
</app-header>
<div class="container" #bodyContainer>
  <div class="border">
    <div class="card">
      <div class="card-header border-top" id="valuationDetails">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapseValuationDetails"
            aria-expanded="true" aria-controls="collapseValuationDetails">
            <i class="icon icon-sm me-2 text-secondary">
              keyboard_arrow_right
            </i>
            <span translate>valuation.form.productDetails</span>
          </button>
        </h5>
      </div>
      <div id="collapseValuationDetails" class="collapse show" aria-labelledby="headingOne">
        <div class="card-body pt-0">
          <div class="section-forms" [formGroup]="formGroup">
            <div class="row gy-2">
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.isinCode">
                  <input class="form-control" type="text" [formControlName]="fields.isinCode"
                     component #isinCode  />
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.eventType"  [focus]="eventType">
                  <input class="form-control" type="text" [formControlName]="fields.eventType"
                     component #eventType  />
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.eventId">
                  <input class="form-control" type="text" [formControlName]="fields.eventId"
                     component #eventId  />
                </app-form-item>
              </div>
            </div>
            <div class="row gy-2 mt-1">
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.outstandingNominal" [focus]="outstandingNominal">
                  <input class="form-control" type="text" [formControlName]="fields.outstandingNominal"
                     component #outstandingNominal
                     />
                </app-form-item>
              </div> 
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.denominationCcy">
                  <input class="form-control" type="text" [formControlName]="fields.denominationCcy"
                     component #denominationCcy [style.color]="denominationCcyMismatched"
                     />
                </app-form-item>
              </div>
              <div class="form-group col-md-3" >
                <app-form-item [field]="fields.denomination" >
                  <input class="form-control" type="text" [formControlName]="fields.denomination"
                     component #denomination [style.color]="denominationValueMismatched" />
                </app-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header border-top" id="headingTwo">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapseValuationDates"
            aria-expanded="false" aria-controls="collapseValuationDates">
            <i class="icon icon-sm me-2 text-secondary">
              keyboard_arrow_right
            </i>
            <span translate>valuation.form.valuationDetails</span>
          </button>
        </h5>
      </div>
      <div id="collapseValuationDates" class="collapse show" aria-labelledby="headingTwo">
        <div class="card-body pt-0">
          <div class="section-forms" [formGroup]="formGroup">
            <div class="row gy-2">
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.valuationRef">
                  <input class="form-control fw-medium" type="text" [formControlName]="fields.valuationRef"
                    component #valuationRef  />
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.valuationVersion" [focus]="valuationVersion">
                  <input class="form-control" type="text" [formControlName]="fields.valuationVersion"
                     component #valuationVersion  />
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
              <app-form-item [field]="fields.valuationStatus">
                <select  class="form-control box-style" type="text" [formControlName]="fields.valuationStatus" component
                    #valuationStatus>
                    <option *ngFor="let valuationStatus of allValuationStatus" [value]="valuationStatus" hidden>
                      {{valuationStatus}}
                    </option>
                    <option value="AWAIT NEW VALUATION" translate>valuation.form.awaitNewValuation</option>
                    <option *ngIf="isStatusAwaitNewValuation" value="REGULARIZED" translate>valuation.form.regularized</option>
                  </select>
              </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.rejectionStatus" [focus]="rejectionStatus">
                  <textarea class="form-control box-style" type="text" [formControlName]="fields.rejectionStatus" component
                    #rejectionStatus></textarea>
                </app-form-item>
              </div>
            </div>
            <div class="row gy-2 mt-1">
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.valueDate">
                     <app-date-picker [formControlName]="fields.valueDate" #valueDate component>
                    </app-date-picker>
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.notificationDate">
                     <app-date-picker [formControlName]="fields.notificationDate" #notificationDate component>
                    </app-date-picker>
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.receiptDate">
                     <app-date-picker [formControlName]="fields.receiptDate" #receiptDate component>
                    </app-date-picker>
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.remarks" [focus]="remarks">
                  <textarea class="form-control box-style" type="text" [formControlName]="fields.remarks" component #remarks></textarea>
                </app-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" [hidden]="displayInterestField">
      <div class="card-header border-top" id="interestPayment">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseInterestPayment" aria-expanded="false" aria-controls="collapseInterestPayment">
            <i class="icon icon-sm me-2 text-secondary">
              keyboard_arrow_right
            </i>
            <span translate>valuation.form.interestDetails</span>
          </button>
        </h5>
      </div>
      <div id="collapseInterestPayment" class="collapse show" aria-labelledby="interestPayment">
        <div class="card-body pt-0">
          <div class="section-forms" [formGroup]="formGroup">
            <div class="row gy-2">
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.couponType" [focus]="couponType">
                  <input class="form-control" type="text" [formControlName]="fields.couponType"
                     component #couponType  />
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.interestRate" [focus]="interestRate">
                  <input class="form-control" type="text" [formControlName]="fields.interestRate"
                     component #interestRate />
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.amountPerDenomination" [focus]="amountPerDenomination">
                    <app-number #amountPerDenomination component [formControlName]="fields.amountPerDenomination" [formatThousandSeparator]='true'
                    [style.color]="amountPerDenominationValueMismatched" placeholder="{{ placeholder(fields.amountPerDenomination) | translate }}">
                  </app-number>
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.paymentCcy" [focus]="paymentCcy">
                  <input class="form-control" type="text" [formControlName]="fields.paymentCcy"
                     component #paymentCcy  />
                </app-form-item>
              </div>
            </div>
            <div class="row gy-2 mt-1">              
              <div class="form-group col-md-3" [hidden]="displayInterestField">
                <app-form-item [field]="fields.startDate">
                     <app-date-picker [style.color]="startDateValueMismatched"
                     [formControlName]="fields.startDate" #startDate component>
                    </app-date-picker>
                </app-form-item>
              </div>
              <div class="form-group col-md-3" [hidden]="displayInterestField">
                <app-form-item [field]="fields.endDate">
                     <app-date-picker [style.color]="endDateValueMismatched" 
                     [formControlName]="fields.endDate" #endDate component>
                    </app-date-picker>
                </app-form-item>
              </div>              
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.dayCountFraction" [focus]="dayCountFraction">
                  <input class="form-control" type="text" [formControlName]="fields.dayCountFraction"
                     component #dayCountFraction [style.color]="dcfColorMismatched"
                     />
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.numOfDayOfThePeriod" [focus]="numOfDayOfThePeriod">
                  <input class="form-control" type="text" [formControlName]="fields.numOfDayOfThePeriod"
                     component #numOfDayOfThePeriod
                     />
                </app-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" [hidden]="displayRedemptionField">
      <div class="card-header border-top" id="redemption">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseRedemption" aria-expanded="false" aria-controls="collapseRedemption">
            <i class="icon icon-sm me-2 text-secondary">
              keyboard_arrow_right
            </i>
            <span translate>valuation.form.redemptionDetails</span>
          </button>
        </h5>
      </div>
      <div id="collapseRedemption" class="collapse show" aria-labelledby="redemption">
        <div class="card-body pt-0">
          <div class="section-forms" [formGroup]="formGroup">
            <div class="row gy-2">
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.redemptionType" [focus]="redemptionType">
                  <input class="form-control" type="text" [formControlName]="fields.redemptionTypeDescription"
                     component #redemptionType  [style.color]="redmTypeMismatched"
                      />
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.redemptionRate" [focus]="redemptionRate">
                  <input class="form-control" type="text" [formControlName]="fields.redemptionRate"
                     component #redemptionRate   [style.color]="redmRateMismatched" />
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.amountPerDenomination" [focus]="amountPerDenomination">
                    <app-number #amountPerDenomination component [formControlName]="fields.amountPerDenomination" [formatThousandSeparator]='true'
                    placeholder="{{ placeholder(fields.amountPerDenomination) | translate }}">
                  </app-number>
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.paymentCcy" [focus]="paymentCcy">
                  <input class="form-control" type="text" [formControlName]="fields.paymentCcy"
                     component #paymentCcy  />
                </app-form-item>
              </div>
            </div>
            <div class="row gy-2 mt-1">              
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.strikePrice" [focus]="strikePrice">
                     <app-number #strikePrice component [formControlName]="fields.strikePrice" [formatThousandSeparator]='true'
                    placeholder="{{ placeholder(fields.strikePrice) | translate }}">
                  </app-number>
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.closingPrice" [focus]="closingPrice">
                  <input class="form-control" type="text" [formControlName]="fields.closingPrice"
                     component #closingPrice  />
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.underlyingIsin" [focus]="underlyingIsin">
                  <input class="form-control" type="text" [formControlName]="fields.underlyingIsin"
                     component #underlyingIsin  />
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.underlyingCcy" [focus]="underlyingCcy">
                  <input class="form-control" type="text" [formControlName]="fields.underlyingCcy"
                     component #underlyingCcy  />
                </app-form-item>
              </div>
            </div>
            <div class="row gy-2 mt-1">
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.underlyingDenomQty" [focus]="underlyingDenomQty">
                    <app-number #underlyingDenomQty component [formControlName]="fields.underlyingDenomQty" [formatThousandSeparator]='true'
                      placeholder="{{ placeholder(fields.underlyingDenomQty) | translate }}">
                    </app-number>
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.totalNumOfUnderlying" [focus]="totalNumOfUnderlying">
                     <app-number #totalNumOfUnderlying component [formControlName]="fields.totalNumOfUnderlying" [formatThousandSeparator]='true'
                      placeholder="{{ placeholder(fields.totalNumOfUnderlying) | translate }}">
                    </app-number>
                </app-form-item>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header border-top" id="additionalDetails">
        <h5 class="mb-0">
          <button class="btn btn-link" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseAdditionalDetails" aria-expanded="false" aria-controls="collapseAdditionalDetails">
            <i class="icon icon-sm me-2 text-secondary">
              keyboard_arrow_right
              </i>
              <span translate>valuation.form.additionalDetails</span>
          </button>
        </h5>
      </div>
      <div id="collapseAdditionalDetails" class="collapse show" aria-labelledby="additionalDetails">
        <div class="card-body pt-0">
          <div class="section-forms" [formGroup]="formGroup">
            <div class="row gy-2">
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.previousPoolFactor" [focus]="previousPoolFactor">
                  <input class="form-control" type="text" [formControlName]="fields.previousPoolFactor"
                     component #previousPoolFactor
                     />
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.newPoolFactor" [focus]="newPoolFactor">
                  <input class="form-control" type="text" [formControlName]="fields.newPoolFactor"
                     component #newPoolFactor  />
                </app-form-item>
              </div>
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.positionIn" [focus]="positionIn">
                  <input class="form-control" type="text" [formControlName]="fields.positionIn"
                     component #positionIn  />
                </app-form-item>
              </div>
               <div class="form-group col-md-3">
                <app-form-item [field]="fields.positionOut" [focus]="positionOut">
                  <input class="form-control" type="text" [formControlName]="fields.positionOut"
                     component #positionOut  />
                </app-form-item>
              </div>
            </div>
            <div class="row gy-2 mt-1">
              <div class="form-group col-md-3">
                <app-form-item [field]="fields.forexRate" [focus]="forexRate">
                  <input class="form-control" type="text" [formControlName]="fields.forexRate"
                     component #forexRate  />
                </app-form-item>
              </div> 
              <div class="form-group col-md-3" [hidden]=isPredPcalEvent >
                <app-form-item [field]="fields.amountPerDenomination" [focus]="amountPerDenomination">
                    <app-number #amountPerDenomination component [formControlName]="fields.amountPerDenomination" [formatThousandSeparator]='true'
                    placeholder="{{ placeholder(fields.amountPerDenomination) | translate }}">
                  </app-number>
                </app-form-item>
              </div>
              <div class="form-group col-md-3" [hidden]=isPredPcalEvent>
                <app-form-item [field]="fields.paymentCcy" [focus]="paymentCcy">
                  <input class="form-control" type="text" [formControlName]="fields.paymentCcy"
                     component #paymentCcy  />
                </app-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>    
  </div>
</div>