export function notEmpty<T>(value: T | undefined | null): value is T {
  return value !== null && value !== undefined;
}

export function deepEqual<T extends Record<string, unknown>>(valA: T | null | undefined, valB: T | null | undefined): boolean {
  if (valA && valB) {
    return [...Object.keys(valA), ...Object.keys(valB)].map(k => k as keyof T).every(k => valA[k] === valB[k]);
  }
  return valA === valB;
}

export function stringToDate(dateAsString: string | undefined | null): Date | null {
  const result = new Date(dateAsString || '');
  if (isNaN(result.getTime())) {
    return null;
  }

  return result;
}
export function dateToString(date: Date | undefined | null): string | null {
  if (!date) {
    return null;
  }
  if (isNaN(date.valueOf())) {
    return null;
  }

  return date.toISOString();
}

export function getArray<T>(data: T | T[] | null | undefined): T[] {
  if (data === null || data === undefined) {
    return [];
  }
  return Array.isArray(data) ? data : [data];
}

export function arrayDistinct<T>(data: T[]): T[] {
  const distinct = (value: T, index: number, self: T[]) => {
    return self.indexOf(value) === index;
  };
  return data.filter(distinct);
}

export function removeUndefined<T extends Record<string, unknown>>(data: T): Partial<T> {
  return Object.keys(data).reduce<Partial<T>>((p, c) => {
    if (c in data && data[c] !== undefined) {
      return { ...p, [c]: data[c] };
    }
    return p;
  }, {});
}
export function toTitleCase(value: string | undefined): string {
  if (!value) {
    return '';
  }
  const first = value.substring(0, 1).toUpperCase();
  return first + value.substring(1).toLowerCase();
}
export function downloadFile(blob: Blob, filename: string): void {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = url;
  a.download = filename;
  a.click();
}

export function removeLeadingAndTrailingZeros(val: string | null): string | null {
  const removeZeros = /^0*(\d+(?:\.(?:(?!0+$)\d)+)?)/;
  if (!val) {
    return null;
  }

  const matches = Array.from(removeZeros.exec(val.trim()) || []);
  return matches[1] || null;
}

export function isXSIsin(isin: string | null | undefined): boolean {
  return !!isin && isin[0]?.toUpperCase() === 'X' && isin[1].toUpperCase() === 'S';
}
export function getExtensionFile(filename: string): string | null {
  const match = filename.match(/\.[0-9a-zA-Z]+$/i);
  return match ? match[0].substring(1) : null;
}
export function isEmptyOrSpaces(str: string): boolean {
  return str === null || str.match(/^ *$/) !== null;
}
export function replaceAll(str: string, find: RegExp, replace: string): string {
  return str.replace(find, replace);
}
