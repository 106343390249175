import { Component } from '@angular/core';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';
import { AuditDetailFormService } from '@events/services';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { AuditDetailModel } from '@events/models';

@Component({
  selector: 'app-event-audit-details',
  templateUrl: './event-audit-details.component.html',
  styleUrls: ['./event-audit-details.component.scss'],
  providers: [
    { provide: FormServiceInjectionToken, useExisting: AuditDetailFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'events.form' }
  ]
})
export class EventAuditDetailsComponent extends BaseFormComponent<AuditDetailModel, AuditDetailFormService> {
  constructor(auditDetailFormService: AuditDetailFormService) {
    super(auditDetailFormService, 'events.form');
  }
}
