import { Component, ComponentFactoryResolver, Input, Type, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentContainerDirective } from '../../directives';

type ConfirmFunction = () => boolean;
@Component({
  selector: 'app-component-modal',
  templateUrl: './component-modal.component.html'
})
export class ComponentModalComponent {
  @ViewChild(ComponentContainerDirective, { static: true }) componentContainer!: ComponentContainerDirective;
  @Input() dialogTitle = 'modals.titles.default';
  @Input() confirmButton = 'modals.buttons.confirm';
  @Input() cancelButton = 'modals.buttons.cancel';

  @Input() showConfirmButton = true;
  @Input() beforeConfirm: ConfirmFunction | undefined;

  public ClosedReason: DialogResult = 'closed';
  public CancelReason: DialogResult = 'cancel';
  public ConfirmReason: DialogResult = 'confirm';
  constructor(private readonly activeModal: NgbActiveModal, private readonly cfr: ComponentFactoryResolver) {}

  public onClose(): void {
    this.activeModal.dismiss(this.ClosedReason);
    this.activeModal.close(this.ClosedReason);
  }

  public onCancel(): void {
    this.activeModal.close(this.CancelReason);
  }
  public onConfirm(): void {
    if (!this.beforeConfirm || this.beforeConfirm()) {
      this.activeModal.close(this.ConfirmReason);
    }
  }

  public setComponent<T>(componentType: Type<T>, componentPrep?: (component: T) => void): void {
    const vcr = this.componentContainer.viewContainerRef;

    vcr.clear();
    const factory = this.cfr.resolveComponentFactory(componentType);
    const component = vcr.createComponent(factory);

    if (componentPrep) {
      componentPrep(component.instance);
    }
  }
}
