/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Information will be using for update status of Settlement
 */
export interface UpdateSettlementStatusRequestDto { 
    /**
     * Settlement number
     */
    settlementNumbers?: Array<string>;
    /**
     * IPA code of instrument
     */
    instrumentId?: string;
    /**
     * ISIN of instrument
     */
    isin?: string;
    /**
     * Tranche number or Increase number
     */
    trancheNumber?: string;
}

