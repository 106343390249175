import { Component, OnInit } from '@angular/core';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';
import { FongeTrancheFormService } from '@instruments/services/forms/fonge-tranche.form.service';
import { UpdateStatusRequestModel } from '@instruments/models';
import { IssuanceFormService } from '@instruments/services';
import { IpaTypeModelRules as Rule } from '@instruments/rules';

@Component({
  selector: 'app-tranche-fonge',
  templateUrl: './tranche-fonge.component.html',
  providers: [
    { provide: FormServiceInjectionToken, useExisting: FongeTrancheFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'instruments.instrument.forms.trancheFonge' }
  ]
})
export class TrancheFongeComponent extends BaseFormComponent<UpdateStatusRequestModel, FongeTrancheFormService> implements OnInit {
  constructor(formService: FongeTrancheFormService, public readonly issuanceFormService: IssuanceFormService) {
    super(formService, 'instruments.instrument.placeholder.trancheFonge');
  }

  ngOnInit(): void {
    this.formGroup.controls['certifiedNominal'].disable();
  }

  public get certifiedNominalType(): string {
    if (Rule.isTradingMethodNominal(this.issuanceFormService.rawValue('tradingMethodIdentifier'))) {
      return 'nominal';
    }
    return 'unit';
  }

  disableCertifiedNominal(): void {
    const partiallyCertified = this.formService.rawValue('partiallyCertified');
    if (partiallyCertified) {
      this.formGroup.controls['certifiedNominal'].enable();
    } else {
      this.formGroup.controls['certifiedNominal'].disable();
    }
  }
}
