import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentFileModel, DocumentTypeInjectionToken } from '@documents/models';
import { DocumentFormService, DocumentService } from '@documents/services';
import { routeToProgramme } from '@instruments/routes';
import { ProgrammeFormService, ProgrammeModalsService } from '@programmes/services';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { PartyModel, ProgrammeInformation, ProgrammeModel } from '@shared/models';
import { PermissionService } from '@shared/services/permission.service';
import { ProgrammeService } from '@shared/services/programme.service';
import { Forms } from '@shared/utils';
import { poll } from '@utils/rxjs';
import { combineLatest, of, Subject, BehaviorSubject } from 'rxjs';
import { mergeMap, startWith, takeUntil, filter, tap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { PartyService } from '@shared/services/party.service';
import { removeUndefined, notEmpty } from '@utils/utility-functions';
import { LocationService } from '@shared/services/location.service';

@Component({
  selector: 'app-programme',
  templateUrl: './programme.component.html',
  styleUrls: ['./programme.component.scss'],
  providers: [{ provide: DocumentTypeInjectionToken, useValue: 'programme' }, DocumentService, DocumentFormService]
})
export class ProgrammeComponent extends BaseFormComponent<ProgrammeModel, ProgrammeFormService> implements OnInit, OnDestroy {
  private readonly shutdown$ = new Subject<void>();
  private readonly programmeId$ = new BehaviorSubject<number | null>(null);
  private readonly issuerLeiCode$ = new BehaviorSubject<string | null>(null);
  public activeTab = 'documents';
  public canEditProgramme = false;
  public canCreateInstrument = false;
  public documents: DocumentFileModel[] = [];
  public isLoadingDocument = false;
  public programmeInformation: ProgrammeInformation | null = null;
  programmeHasCreated = false;
  public currentProgramme: ProgrammeModel | null = null;

  public isLoadingLeiInformation = false;
  constructor(
    private readonly router: Router,
    private readonly activedRoute: ActivatedRoute,
    private readonly programmeFormService: ProgrammeFormService,
    private readonly programmeModalsService: ProgrammeModalsService,
    private readonly programmeService: ProgrammeService,
    private readonly documentService: DocumentService,
    public readonly documentFormService: DocumentFormService,
    private readonly permissionService: PermissionService,
    private readonly partyService: PartyService,
    private readonly locationService: LocationService
  ) {
    super(programmeFormService, '');

    const state = this.router.getCurrentNavigation()?.extras.state;
    if (state) {
      this.activeTab = state.activeTab;
    }
  }

  public get programme(): Nullable<ProgrammeModel> {
    return this.programmeFormService.rawValue();
  }

  public get programmeId(): number | null {
    return this.programmeFormService.rawValue('id') || null;
  }

  private loadDocuments(idTech: number) {
    this.isLoadingDocument = true;
    this.documentService
      .searchDocuments(idTech, '')
      .pipe(
        takeUntil(this.shutdown$),
        poll(10000, 5, res => res.length > 0)
      )
      .subscribe(res => {
        this.documents = res;
        this.isLoadingDocument = false;
      });
  }

  ngOnInit(): void {
    this.programmeFormService.retrievePartyByLeiCode(this.shutdown$);
    this.programmeFormService.checkErrors(this.shutdown$);
    this.formService.programmeInformation$.pipe(takeUntil(this.shutdown$)).subscribe(res => (this.programmeInformation = res));
    this.permissionService.canEditProgramme$.pipe(takeUntil(this.shutdown$)).subscribe(res => (this.canEditProgramme = res));
    this.permissionService.canCreateInstrument$.pipe(takeUntil(this.shutdown$)).subscribe(res => (this.canCreateInstrument = res));
    this.programmeId$
      .pipe(
        takeUntil(this.shutdown$),
        filter(notEmpty),
        mergeMap(programmeId => this.programmeService.getProgrammeById(programmeId))
      )
      .subscribe(programme => {
        this.programmeFormService.setInitialValue(programme);

        const leiCode = programme?.issuer?.leiCode || null;
        this.programmeFormService.patch('issuerLeiCode', leiCode);
        this.issuerLeiCode$.next(leiCode);

        const cssfIdentifier = programme?.authorityProgrammeIdentifier;
        if (cssfIdentifier && cssfIdentifier.toLocaleLowerCase().startsWith('c-')) {
          this.programmeFormService.patch('authorityProgrammeIdentifier', cssfIdentifier.substring(2));
        }
        if (programme) {
          this.loadDocuments(programme.id);
        }
      });

    this.issuerLeiCode$
      .pipe(
        takeUntil(this.shutdown$),
        tap(() => (this.isLoadingLeiInformation = true)),
        mergeMap(issuerLeiCode => {
          if (!issuerLeiCode) {
            return of(null);
          }
          return this.partyService.getIssuerBdrDataFromLeiCode(issuerLeiCode);
        })
      )
      .subscribe(programmeInformation => {
        this.formService.setProgrammeInformation(programmeInformation);
        this.isLoadingLeiInformation = false;
      });
    combineLatest([this.activedRoute.data.pipe(startWith(...[])), this.activedRoute.params.pipe(startWith(...[]))])
      .pipe(takeUntil(this.shutdown$))
      .subscribe({
        next: ([, routeParams]) => {
          const pageType = Forms.isFormMode(routeParams.pageType) ? routeParams.pageType : 'consult';
          this.programmeFormService.setFormMode(pageType);

          this.documentFormService.setFormMode(pageType);
          this.documentFormService.reset();

          if (pageType === 'add') {
            this.programmeFormService.clearInitialValue();
            this.programmeFormService.reset();
          } else {
            const programmeId: number | null = routeParams.id || null;
            this.programmeId$.next(programmeId);
          }
        },
        error: () => (window.location.href = 'https://shared.sgmarkets.com/404')
      });

    this.permissionService.canEditProgramme$.pipe(takeUntil(this.shutdown$)).subscribe({
      next: (canEditProgramme: boolean) => {
        this.canEditProgramme = canEditProgramme;
      }
    });

    this.permissionService.canCreateInstrument$.pipe(takeUntil(this.shutdown$)).subscribe({
      next: (canCreateInstrument: boolean) => {
        this.canCreateInstrument = canCreateInstrument;
      }
    });
  }

  public onProgrammeInformationSelected(programmeInformation: ProgrammeInformation | null) {
    this.programmeFormService.setProgrammeInformation(programmeInformation);
  }

  ngOnDestroy() {
    this.shutdown$.next();
  }

  showCancelModalOrGoBack(): void {
    if (this.programmeFormService.dirty) {
      const modal = this.programmeModalsService.openCancelModal();
      modal.result.then((result: DialogResult) => {
        if (result !== 'confirm') {
          return;
        }
        this.goBack();
      });
    } else {
      this.goBack();
    }
  }

  redirectToInstrumentCreation(): void {
    const params = removeUndefined({
      icsdProgrammeNumber: this.programmeFormService.rawValue('icsdProgrammeNumber') || undefined,
      internalProgrammeNumber: this.programmeFormService.rawValue('id') || undefined,
      leiCode: this.programmeFormService.rawValue('issuerLeiCode') || undefined
    });
    this.router.navigate(['/instrument/add', params]);
  }

  goBack(): void {
    if (this.locationService.navgationHistories.length >= 1) {
      this.router.navigateByUrl(this.locationService.getBackUrl());
    } else {
      const progId = this.isAddOrEdit ? this.programmeId : null;
      this.router.navigate(routeToProgramme(progId, 'consult'));
    }
  }
  public get getErrorMessages() {
    return this.formService.refreshErrors.bind(this.formService);
  }

  public get leiFormControl() {
    return this.formService.control<UntypedFormControl>('issuerLeiCode');
  }
  public get leiCode(): string | null {
    const issuer = this.programmeFormService.rawValue('issuer');
    return issuer?.leiCode || null;
  }
  public set leiCode(value: string | null) {
    const issuer: PartyModel = { ...this.programmeFormService.rawValue('issuer'), leiCode: value || undefined };
    this.programmeFormService.patch('issuer', issuer);
  }

  public get icsdProgrammeNumberControl() {
    return this.formService.control<UntypedFormControl>('icsdProgrammeNumber');
  }

  public get formGroup() {
    return this.formService.formGroup;
  }
  switchToEditMode() {
    this.router.navigate(routeToProgramme(this.programmeId, 'edit'));
  }
  onSubmit(): void {
    const modal = this.programmeModalsService.openSaveModal(this.formService.formMode);
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        return;
      }
      this.getErrorMessages();
      const programme = this.formService.value();
      if (programme && this.documentFormService.valid) {
        const newProgramme = {
          ...programme,
          issuer: {
            ...programme.issuer,
            bdrId: this.programmeInformation?.bdrId,
            name: this.programmeInformation?.fullLegalName
          }
        };
        this.addOrUpdateProgrammeSubmit(newProgramme);
      }
    });
  }

  getAuthorityProgrammeIdentifier(newProgramme: ProgrammeModel): string | undefined {
    let authorityId: string | undefined = undefined;
    if (this.formService.requiredAuthorityProgrammeIdentifier()) {
      authorityId = `C-${newProgramme.authorityProgrammeIdentifier?.includes('C-') ? newProgramme.authorityProgrammeIdentifier?.split('C-')[1] : newProgramme.authorityProgrammeIdentifier}`;
    }
    return authorityId;
  }

  doUploadFiles(programme: ProgrammeModel): void {
    this.documentFormService.uploadFiles(programme.id, programme).finally(() => {
      this.router.navigate(routeToProgramme(programme.id));
    });
  }

  haveModificationForm(): boolean {
    const programmeFromForm: ProgrammeModel = this.formGroup.value;
    return JSON.stringify(this.currentProgramme) !== JSON.stringify(programmeFromForm);
  }
  addOrUpdateProgrammeSubmit(programme: ProgrammeModel): void {
    const newProgramme: ProgrammeModel = {
      ...programme,
      status: programme.status ?? 'INACTIVE',
      authorityProgrammeIdentifier: this.getAuthorityProgrammeIdentifier(programme) || null
    };

    if (this.isAddMode && !this.programmeHasCreated) {
      this.programmeService
        .createProgramme(newProgramme, this.documentFormService.length)
        .pipe(takeUntil(this.shutdown$))
        .subscribe({
          next: (data: ProgrammeModel | null) => {
            this.programmeHasCreated = true;
            this.currentProgramme = this.formGroup.value;
            if (data) {
              this.doUploadFiles(data);
            }
          }
        });
    } else if (this.formMode === 'edit' && this.haveModificationForm()) {
      this.programmeService
        .updateProgramme(newProgramme, this.documentFormService.length)
        .pipe(takeUntil(this.shutdown$))
        .subscribe({
          next: (data: ProgrammeModel | null) => {
            this.currentProgramme = data;
            if (this.currentProgramme) {
              this.doUploadFiles(this.currentProgramme);
              this.formService.initFormWithStoredData(this.currentProgramme);
            }
          }
        });
    } else {
      this.doUploadFiles(newProgramme);
    }
  }

  public get nameControl() {
    return this.formService.control<UntypedFormControl>('programmeName');
  }

  public get formErrors(): Record<string, any> {
    return this.formService.formErrors;
  }

  public get idTech() {
    return this.formService.rawValue('id');
  }

  public get isValid() {
    return this.formService.valid && this.documentFormService.valid;
  }
}
