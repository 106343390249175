/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InlineObject1 { 
    /**
     * Custom name of file
     */
    newDocumentName?: string;
    /**
     * Model ID to use for saving document
     */
    modelId: string;
    /**
     * Metadata from programme, instrument,...
     */
    metadata: string;
    /**
     * File to be saved
     */
    file: Blob;
}

