/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FiltersSaveBaseComponent } from '@shared/modules/save-filter/filters-save/filters-save-base.component';
import { PaymentCriteriaSearchModel } from '@payment/models';
import { PaymentFilterSelectorService } from '@payment/services';

@Component({
  selector: 'app-payment-filters-save',
  templateUrl: './payment-filters-save.component.html'
})
export class PaymentFiltersSaveComponent extends FiltersSaveBaseComponent<PaymentCriteriaSearchModel, PaymentFilterSelectorService> {
  constructor(public activeModal: NgbActiveModal, filterSelectorService: PaymentFilterSelectorService) {
    super(activeModal, filterSelectorService);
  }
}
