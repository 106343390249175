import { Component, EventEmitter, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OperationSearchCriteriaModel } from '@positions/models';
import { OperationCriteriaSearchFormService } from '@positions/services';
import { ActiveFilterItem } from '@shared/models';
import { ActiveFiltersBaseComponent } from '@shared/search-base';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-operation-list-active-filters',
  templateUrl: './operation-list-active-filters.component.html'
})
export class OperationListActiveFiltersComponent extends ActiveFiltersBaseComponent<OperationSearchCriteriaModel> {
  @Output() filtersChanged = new EventEmitter<ActiveFilterItem<OperationSearchCriteriaModel>[]>();
  public filterItems: ActiveFilterItem<OperationSearchCriteriaModel>[] = [];
  public shutdown$ = new Subject<void>();

  constructor(formService: OperationCriteriaSearchFormService, modalService: NgbModal, i18nUtils: I18nUtilsService) {
    super(formService, modalService, i18nUtils, 'operations-filters', 'operations.list.filters');
  }
  public onFilterChanged(items: ActiveFilterItem<OperationSearchCriteriaModel>[]): void {
    this.filtersChanged.emit(items);
  }
  public saveFilters(): NgbModalRef | null {
    return null;
  }
}
