import { Component, Input } from '@angular/core';
import { DocumentModel } from '@documents/models';
import { ReportingAccessModel } from '@instruments/models';
import { ReportingAccessFormService } from '@instruments/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';

@Component({
  selector: 'app-reporting-access-form',
  templateUrl: './reporting-access-form.component.html',
  providers: [{ provide: LabelRootTranslateInjectionToken, useValue: 'instruments.instruments-reporting-access' }]
})
export class ReportingAccessFormComponent extends BaseFormComponent<ReportingAccessModel, ReportingAccessFormService> {
  constructor(formService: ReportingAccessFormService, private readonly activeModal: NgbActiveModal) {
    super(formService, 'reportingAccess.placeholder');
  }

  @Input() documents: DocumentModel[] = [];

  onDatesChanged(event: any): void {
    this.formService.patch('startDate', event?.detail?.from?.formatted);
    this.formService.control('startDate').markAsDirty();
    this.formService.patch('endDate', event?.detail?.to?.formatted);
    this.formService.control('endDate').markAsDirty();
  }

  public onClose(): void {
    this.activeModal.dismiss('closed');
    this.activeModal.close('closed');
  }
}
