/* eslint-disable @typescript-eslint/no-namespace */
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IpaTypeModel } from '@shared/models';
import * as moment from 'moment';
import { removeLeadingAndTrailingZeros } from '@utils/utility-functions';

const internalValidateDate = (control: AbstractControl, validatorIfNull: ValidatorFn): ValidationErrors | null => {
  const value: string | Date | null = control.value || null;
  const error = { dateFormat: { formatDate: 'dd-mm-yyyy' } };
  if (!value) {
    return validatorIfNull(control);
  }

  const testFormat = /^(\d{2}-){2}\d{4}$/;
  if (typeof value === 'string' && !testFormat.test(value)) {
    return error;
  }

  const mDate = moment(value, 'DD-MM-yyyy');
  if (!mDate.isValid()) {
    return error;
  }

  return null;
};
const internalValidateIsDate = (control: AbstractControl, validatorIfNull: ValidatorFn): ValidationErrors | null => {
  const value = control.value || null;
  const error = { date: 'invalid_date' };
  if (!value) {
    return validatorIfNull(control);
  }
  if (value instanceof Date) {
    return isNaN(value.valueOf()) ? error : null;
  }
  return null;
};
export namespace CustomValidators {
  export function ValidateAmount(control: AbstractControl): ValidationErrors | null {
    const regexAmount = /^\\d{1,22}(\\.\\d{1,7})?$/;
    let isValid = null;
    if (control.value !== undefined && !regexAmount.test(control.value)) {
      isValid = { isAmount: false };
    }
    return isValid;
  }

  export function ValidateRequiredDate(control: AbstractControl): ValidationErrors | null {
    return internalValidateDate(control, Validators.required);
  }
  export function ValidateDate(control: AbstractControl): ValidationErrors | null {
    return internalValidateDate(control, () => null);
  }
  export function ValidateIsDate(control: AbstractControl): ValidationErrors | null {
    return internalValidateIsDate(control, () => null);
  }
  export function ValidateNumber(nbDigit: number, decimalPart: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string | null = control.value;

      const clearedValue = removeLeadingAndTrailingZeros(value);
      if (!clearedValue) {
        return null;
      }

      const testGlobalFormat = /^[\d]+(?:[,.]{1}[\d]+)?$/;
      const validationError: ValidationErrors = {
        invalidNumberFormat: {
          nbDigit,
          decimalPart
        }
      };

      if (isNaN(+clearedValue)) {
        return validationError;
      }

      if (!testGlobalFormat.test(clearedValue)) {
        return validationError;
      }

      const parts = clearedValue.split('.');
      const intPart = parts[0] || '';

      const decPart = parts[1] || '';
      const integerPart = nbDigit - Math.min(decPart.length, decimalPart);
      if (intPart.length > integerPart || decPart.length > decimalPart) {
        return validationError;
      }

      return null;
    };
  }

  export function RequiredIfDefined<T, K extends keyof T = keyof T>(ifDefinedField: K): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const otherControlValue = control.parent?.get(ifDefinedField.toString())?.value;
      if (otherControlValue !== null && otherControlValue !== undefined) {
        return Validators.required(control);
      }
      return null;
    };
  }

  export function RequiredIfNotDefined<T, K extends keyof T = keyof T>(ifDefinedField: K): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const otherControlValue = control.parent?.get(ifDefinedField.toString())?.value;
      if (otherControlValue === null || otherControlValue === undefined || (Array.isArray(otherControlValue) && otherControlValue.length === 0)) {
        return Validators.required(control);
      }
      return null;
    };
  }

  export function ValidateIf<T, K extends keyof T = keyof T>(field: K, condition: (value: T[K] | undefined | null) => boolean, validator: ValidatorFn | null): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const otherControlValue = control.parent?.get(field.toString())?.value;
      if (otherControlValue !== null && otherControlValue !== undefined && condition(otherControlValue) && validator) {
        return validator(control);
      }
      return null;
    };
  }

  export function MultipleValidateIf<T, U extends any[] = any[], K extends keyof T = keyof T>(fields: K[], condition: (...value: U) => boolean, validator: ValidatorFn | null): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const otherValues = fields.map(f => control.parent?.get(f.toString())?.value) as U;
      if (condition(...otherValues) && validator) {
        return validator(control);
      }
      return null;
    };
  }

  export function RequiredIf<T, K extends keyof T = keyof T>(field: K, condition: (value: T[K] | undefined | null) => boolean): ValidatorFn {
    return ValidateIf(field, condition, Validators.required);
  }

  export function Isin(control: AbstractControl): ValidationErrors | null {
    const validationError = Validators.pattern(/^[A-z]{2}[A-z0-9]{9}[0-9]{1}$/)(control);
    if (validationError !== null) {
      return { isin: true };
    }
    return null;
  }

  export function SEMEReference(control: AbstractControl): ValidationErrors | null {
    const validationError = Validators.pattern(/^[a-zA-Z0-9]{0,16}$/)(control);
    if (validationError !== null) {
      return { SEMEReference: true };
    }
    return null;
  }

  export function icsdAccountNumber(control: AbstractControl): ValidationErrors | null {
    const validationError = Validators.pattern(/^[0-9]{5}$/)(control);
    if (validationError !== null) {
      return { icsdAccountNumber: true };
    }
    return null;
  }

  export function CheckCoupon(couponValidation: (coupon: IpaTypeModel | null | undefined) => boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!couponValidation(control.value)) {
        return {
          invalidcoupon: true
        };
      }

      return null;
    };
  }

  export function PoolFactor(control: AbstractControl): ValidationErrors | null {
    const validationError = Validators.pattern(/^([0-9]|10\d*)(\.[0-9]{0,7})?$/)(control);
    if (validationError !== null) {
      return { poolFactor: true };
    }
    return null;
  }

  export function ValidateScaleToFifteenDecimals(control: AbstractControl): ValidationErrors | null {
    const validationError = Validators.pattern(/^(0|[1-9]\d*)(\.[0-9]{0,15})?$/)(control);
    if (validationError !== null) {
      return { fifteenDecimals: true };
    }
    return null;
  }

  export function someFieldRequiredValidatorValidator<T>(keys: (keyof T)[]): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      const controls = keys.map(control => group.get(control.toString()) ?? group.parent?.get(control.toString()));
      const valid = controls.some(c => c?.value);
      if (valid) {
        for (const control of controls) {
          control?.setErrors(null);
        }
        return null;
      }
      const validationError: ValidationErrors = {
        atLeastOneRquired: true
      };
      for (const control of controls) {
        control?.setErrors(validationError);
      }

      return validationError;
    };
  }
}
