import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { paymentRoutes } from './payment.routing';
import { RouterModule } from '@angular/router';
import { HeaderModule } from '@shared/modules/header/header.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocalCustomFormModule } from '@shared/modules/forms/local-custom-form.module';
import { TypeMultipleSelectModule } from '@shared/modules/type-multiple-select/type-multiple-select.module';
import { DatePickerModule } from '@shared/modules/date-picker/date-picker.module';
import { ButtonsModule } from '@shared/modules/buttons/buttons.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';
import {
  PaymentListFilterComponent,
  PaymentContainerComponent,
  PaymentListComponent,
  PaymentActionComponent,
  PaymentDetailComponent,
  PaymentSaveCancelComponent,
  PaymentFilterSelectorComponent,
  PaymentFiltersSaveComponent,
  PaymentListActiveFiltersComponent
} from './components';
import { AgGridModule } from 'ag-grid-angular';
import { CurrencyMultipleSelectModule } from '@shared/modules/currency-multiple-select/currency-multiple-select.module';
import { NumberInputModule } from '@shared/modules/number-input/number-input.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaymentFilterSelectorService } from './services';
import { FilterSelectorServiceInjectionToken } from '@shared/modules/save-filter/tokens/filter-selector.token';
import { ValuationModule } from '@valuation/valuation.module';
import { PaginationModule } from '@shared/modules/pagination/pagination.module';

@NgModule({
  declarations: [
    PaymentContainerComponent,
    PaymentListFilterComponent,
    PaymentListComponent,
    PaymentActionComponent,
    PaymentDetailComponent,
    PaymentSaveCancelComponent,
    PaymentFilterSelectorComponent,
    PaymentFiltersSaveComponent,
    PaymentListActiveFiltersComponent
  ],
  providers: [{ provide: FilterSelectorServiceInjectionToken, useExisting: PaymentFilterSelectorService }],
  imports: [
    CommonModule,
    paymentRoutes,
    AgGridModule.withComponents([]),
    RouterModule,
    HeaderModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    LocalCustomFormModule,
    TypeMultipleSelectModule,
    ValuationModule,
    DatePickerModule,
    CurrencyMultipleSelectModule,
    ButtonsModule,
    BrowserAnimationsModule,
    NgbModule,
    NumberInputModule,
    SharedModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PaymentModule {}
