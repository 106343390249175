import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ValuationResourceService, ValuationUpdateResponseDTO, ValuationSearchRequestDTO, ValuationPagingResultDto } from '@apis/backend/events';
import { toValuationStatusUpdateDto, toSearchByValuationId, setValuationDetails, ValuationDetailModel, toSearchByEventId } from '@valuation/models';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ValuationService {
  constructor(private readonly valuationService: ValuationResourceService, private readonly toastManagerService: ToastManagerService) {}

  getValuations(valuationSearchRequestDTO: ValuationSearchRequestDTO): Observable<ValuationPagingResultDto | null> {
    return this.valuationService.searchValuationWithPagination(valuationSearchRequestDTO);
  }

  getValuationDetail(id: number, isValuationId: boolean): Observable<ValuationDetailModel | null> {
    const dto = isValuationId ? toSearchByValuationId(id) : toSearchByEventId(id);
    if (!dto) {
      return of(null);
    }
    return this.valuationService.searchValuation(dto).pipe(map(response => setValuationDetails(response.valuationResponses ? response.valuationResponses[0] : null)));
  }

  updateValuationStatus(valuationRef: number, valuationStatus?: string, remarks?: string) {
    const valuationStatusUpdateDto = toValuationStatusUpdateDto(valuationRef, valuationStatus, remarks);
    return this.valuationService.updateValuation(valuationStatusUpdateDto).pipe(
      tap((result: ValuationUpdateResponseDTO) => {
        if (result) {
          this.toastManagerService.toastSuccess('toasts.valuation.update.title', 'toasts.valuation.update.message', undefined, result);
        }
      })
    );
  }

  valuationReconciliation(valuationId: number) {
    return this.valuationService.reconciliationProcess([valuationId]);
  }

  exportValuationsData(pagingRequestWithCriteria: ValuationSearchRequestDTO): Observable<Blob | null> {
    if (!pagingRequestWithCriteria) {
      return of(null);
    }
    return this.valuationService.exportValuationData(pagingRequestWithCriteria);
  }
}
