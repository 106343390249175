import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { ButtonsModule } from '@shared/modules/buttons/buttons.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePickerModule } from '@shared/modules/date-picker/date-picker.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderModule } from '@shared/modules/header/header.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { TypeMultipleSelectModule } from '@shared/modules/type-multiple-select/type-multiple-select.module';
import { TranslateModule } from '@ngx-translate/core';
import { LocalCustomFormModule } from '@shared/modules/forms/local-custom-form.module';
import { CustomMultipleSelectModule } from '@shared/modules/custom-multiple-select/custom-multiple-select.module';
import { valuationRoutes } from './valuation.routing';
import {
  ValuationListComponent,
  ValuationContainerComponent,
  ValuationListFilterComponent,
  ValuationDetailComponent,
  ValuationSaveCancelComponent,
  ValuationTypeMultiselectComponent,
  ValuationActionComponent,
  ValuationListActiveFiltersComponent,
  ValuationFiltersSaveComponent,
  ValuationFilterSelectorComponent
} from './components';
import { NumberInputModule } from '@shared/modules/number-input/number-input.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ValuationFilterSelectorService } from './services';
import { FilterSelectorServiceInjectionToken } from '@shared/modules/save-filter/tokens/filter-selector.token';
import { PaginationModule } from '@shared/modules/pagination/pagination.module';

@NgModule({
  declarations: [
    ValuationListComponent,
    ValuationActionComponent,
    ValuationListFilterComponent,
    ValuationContainerComponent,
    ValuationContainerComponent,
    ValuationDetailComponent,
    ValuationSaveCancelComponent,
    ValuationTypeMultiselectComponent,
    ValuationListActiveFiltersComponent,
    ValuationFiltersSaveComponent,
    ValuationFilterSelectorComponent
  ],
  providers: [{ provide: FilterSelectorServiceInjectionToken, useExisting: ValuationFilterSelectorService }],
  imports: [
    valuationRoutes,
    AgGridModule.withComponents([]),
    ButtonsModule,
    BrowserAnimationsModule,
    CommonModule,
    DatePickerModule,
    FormsModule,
    HeaderModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule.forRoot(),
    TypeMultipleSelectModule,
    TranslateModule,
    LocalCustomFormModule,
    CustomMultipleSelectModule,
    NumberInputModule,
    BsDropdownModule.forRoot(),
    PaginationModule
  ],
  exports: [ValuationTypeMultiselectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ValuationModule {
  static forRoot(): ModuleWithProviders<ValuationModule> {
    return {
      ngModule: ValuationModule
    };
  }
}
