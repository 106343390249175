import { Component } from '@angular/core';
import { DisposalModel } from '@instruments/models/disposal.model';
import { CommonDepositoryFormService } from '@instruments/services/forms/common-depository.form.service';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';

@Component({
  selector: 'app-disposal',
  templateUrl: './disposal.component.html',
  providers: [
    { provide: FormServiceInjectionToken, useExisting: CommonDepositoryFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'instruments.instrument.forms.managmentCommonDepository' }
  ]
})
export class DisposalComponent extends BaseFormComponent<DisposalModel, CommonDepositoryFormService> {
  constructor(formService: CommonDepositoryFormService) {
    super(formService, 'instruments.instrument.placeholder.managmentCommonDepository');
  }
}
