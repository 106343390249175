import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { PaymentService } from '@shared/services/payment.service';
import { PaymentDetailModel } from './models';

@Injectable({
  providedIn: 'root'
})
export class PaymentResolve implements Resolve<PaymentDetailModel | null> {
  constructor(private readonly service: PaymentService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PaymentDetailModel | null> {
    const id = route.params['id'];
    return this.service.getPaymentDetail(id).pipe(
      tap(response => {
        return response;
      }),
      catchError(() => {
        return of(null);
      })
    );
  }
}
