import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextIconButtonComponent } from './text-icon-button/text-icon-button.component';
import { AdvancedFiltersButtonComponent } from './advanced-filters-button/advanced-filters-button.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TextIconButtonComponent, AdvancedFiltersButtonComponent],
  exports: [TextIconButtonComponent, AdvancedFiltersButtonComponent],
  imports: [CommonModule, TranslateModule]
})
export class ButtonsModule {}
