import { ExtCashAccountDto } from '@apis/backend/instruments';

export interface InstructionModel {
  id?: number;
  name: string | undefined;
  leiCode: string | undefined;
  currency: string | undefined;
  correspondantBankName: string | undefined;
  correspondantBankBIC: string | undefined;
  intermediaryName: string | undefined;
  intermediaryBIC: string | undefined;
  account: string | undefined;
  correspondantBankAccount: string | undefined;
  accountBIC: string | undefined;
}

export const toInstructionModel = (dto: ExtCashAccountDto): InstructionModel => {
  return {
    account: dto.account,
    name: dto.party?.name,
    leiCode: dto.party?.leiCode,
    currency: dto.currency,
    correspondantBankName: dto.correspondBankName,
    correspondantBankBIC: dto.correspondBankBIC,
    intermediaryName: dto.intermediaryName,
    intermediaryBIC: dto.intermediaryBIC,
    correspondantBankAccount: dto.correspondBankAccount,
    accountBIC: dto.accountBic
  };
};
