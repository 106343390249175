// eslint-disable-next-line max-classes-per-file
import { Component } from '@angular/core';
import { InstrumentCriteriaSearchModel } from '@instruments/models/instrument-criteria-search.model';
import { InstrumentDataFetchModel, InstrumentModel, toInstrumentDataFetchModel } from '@instruments/models/instrument.model';
import { InstrumentCriteriaSearchFormService, PagingRequestWithCriteriaFormService, SearchInstrumentDisplayService } from '@instruments/services/';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InstrumentService } from '@shared/services/instrument.service';
import { PermissionService } from '@shared/services/permission.service';
import { takeUntil } from 'rxjs/operators';
import { InstrumentSearchQueryModel, InstrumentSearchService } from '@instruments/services/instrument-search.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SettlementDataUploadComponent } from '@instruments/components/settlement-data-upload/settlement-data-upload.component';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { SettlementsConfirmationsReportsComponent } from '@instruments/components/settlements-confirmations-reports/settlements-confirmations-reports.component';
import { PagingWithCriteriaModel } from '@instruments/models';
import { SearchContainerWithSaveFiltersBaseComponent } from '@shared/search-base';
import { InstrumentFiltersSaveComponent } from '../filters-save/instrument-filters-save.component';

@Component({
  selector: 'app-instruments-container',
  templateUrl: './instruments-container.component.html',
  styleUrls: ['./instruments-container.component.scss'],
  providers: [PagingRequestWithCriteriaFormService, InstrumentCriteriaSearchFormService, InstrumentSearchService, SearchInstrumentDisplayService]
})
export class InstrumentsContainerComponent extends SearchContainerWithSaveFiltersBaseComponent<
  InstrumentDataFetchModel,
  InstrumentCriteriaSearchModel,
  InstrumentSearchQueryModel,
  PagingWithCriteriaModel,
  PagingRequestWithCriteriaFormService,
  InstrumentCriteriaSearchFormService,
  InstrumentSearchService
> {
  isUploading = false;
  uploadingFile: File | undefined | null;
  public canCreateInstrument: boolean | undefined;
  public disableSearch = false;

  constructor(
    private readonly instrumentsService: InstrumentService,
    instrumentCriteriaSearchFormService: InstrumentCriteriaSearchFormService,
    pagingRequestWithCriteriaFormService: PagingRequestWithCriteriaFormService,
    permissionService: PermissionService,
    private readonly modalService: NgbModal,
    activatedRoute: ActivatedRoute,
    private readonly instrumentSearchService: InstrumentSearchService,
    router: Router,
    private readonly toast: ToastManagerService
  ) {
    super(pagingRequestWithCriteriaFormService, instrumentCriteriaSearchFormService, permissionService, instrumentSearchService, activatedRoute, router, 50, '/instrument');
  }

  public routerUrl(instrument: InstrumentModel): string {
    return `/instrument/consult/${instrument.id}`;
  }
  protected load(): void {
    const searchParams = this.pagingFormService.value();
    if (!searchParams || !this.searchCriteria) {
      return;
    }
    this.disableSearch = true;

    this.instrumentsService
      .getAllInstrumentsPaged({
        criteria: this.searchCriteria,
        itemsByPage: searchParams?.itemsByPage,
        pageNumber: searchParams?.pageNumber ? searchParams?.pageNumber : 1
      })
      .pipe(takeUntil(this.shutdown$))
      .subscribe(
        result => {
          this.disableSearch = false;
          this.instrumentSearchService.patch('totalItems', result?.totalItems);
          this.models = Array.from(result?.items || []).map(i => toInstrumentDataFetchModel(i));
        },
        error => {
          if (error) {
            this.disableSearch = false;
          }
        }
      );
  }
  protected initPermissions(): void {
    this.permissionService.canCreateInstrument$.pipe(takeUntil(this.shutdown$)).subscribe({
      next: (canCreateInstrument: boolean) => {
        this.canCreateInstrument = canCreateInstrument;
      }
    });
  }

  saveFilters(): NgbModalRef {
    const modal = this.modalService.open(InstrumentFiltersSaveComponent);
    const dialog: InstrumentFiltersSaveComponent = modal.componentInstance;
    dialog.value = this.criteriaFormService.value();
    dialog.keyBase = 'instrument-filters';
    return modal;
  }

  openReportModal(): void {
    this.modalService.open(SettlementsConfirmationsReportsComponent);
  }

  openUploadModal(): void {
    const modal = this.modalService.open(SettlementDataUploadComponent);

    modal.result.then((res: { reason: string; file: File }) => {
      if (res.reason !== 'upload') {
        return;
      }
      this.isUploading = true;
      this.uploadingFile = res.file;
      //upload here the file
      this.instrumentsService
        .uploadConfirmations(res.file)
        .pipe(takeUntil(this.shutdown$))
        .subscribe(m => {
          if (!m?.error) {
            this.toast.toastSuccess('toasts.instruments.upload.title', 'toasts.instruments.upload.message', undefined, res.file);
          }
          this.isUploading = false;
          this.uploadingFile = null;
        });
    });
  }

  get totalItems(): number {
    return this.pagingFormService.value()?.totalItems || 0;
  }
  get pageNumber(): number {
    return this.pagingFormService.value()?.pageNumber || 0;
  }
  get itemsByPage(): number {
    return this.pagingFormService.value()?.itemsByPage || 0;
  }
}
