/**
 * issuer-ipa-events-api API
 * Manage events data
 *
 * The version of the OpenAPI document: 1.1.16
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Criteria for search Valuation
 */
export interface EcashPaymentRequestDto { 
    id?: number;
    eventId?: number;
    ecashIds?: Array<string>;
    currencies?: Array<string>;
    paymentEndToEndId?: string;
    creationFromDate?: string;
    creationToDate?: string;
    messageTypeId?: number;
    ecashStatusId?: number;
    pageNumber?: number;
    pageSize?: number;
}

