/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TrancheModel } from '@instruments/models';
import { left } from '@popperjs/core';
import { CellStatusComponent } from '@shared/components/cell-status/cell-status.component';
import { MOMENT_DATE_FORMAT } from '@shared/pipes/date-format/common';
import { ColDef, ValueFormatterParams } from 'ag-grid-community/dist/lib/main';
import * as moment from 'moment';

export interface TrancheHistoryColumnDef extends ColDef {
  field: keyof TrancheModel;
}

export const TRANCHE_HISTORY_COLUMNS: TrancheHistoryColumnDef[] = [
  {
    field: 'trancheNumber',
    sortable: true,
    width: 150,
    pinned: left,
    resizable: true
  },
  {
    field: 'isinReference',
    width: 160,
    sortable: true,
    resizable: true
  },
  {
    field: 'issueDate',
    width: 140,
    valueFormatter: (q: ValueFormatterParams) => {
      if (!q.data.issueDate) {
        return '';
      }
      const momentDate = moment(q.data.issueDate);
      return momentDate.format(MOMENT_DATE_FORMAT);
    },
    sortable: true,
    resizable: true
  },
  {
    field: 'nominal',
    width: 200,
    valueFormatter: (q: ValueFormatterParams) => {
      return (+q.data.nominal).toString();
    },
    sortable: true,
    resizable: true
  },
  {
    field: 'numberOfShares',
    width: 200,
    valueFormatter: (q: ValueFormatterParams) => {
      return (+q.data.numberOfShares).toString();
    },
    sortable: true,
    resizable: true
  },
  {
    field: 'outstandingNominal',
    valueFormatter: (q: ValueFormatterParams) => {
      return (+q.data.outstandingNominal).toString();
    },
    width: 280,
    sortable: true,
    resizable: true
  },
  {
    field: 'outstandingNumberOfShares',
    valueFormatter: (q: ValueFormatterParams) => {
      return (+q.data.outstandingNominal).toString();
    },
    sortable: true,
    width: 280,
    resizable: true
  },

  {
    field: 'status',
    cellRendererFramework: CellStatusComponent,
    sortable: true,
    resizable: true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    comparator: (valueA, valueB, _nodeA, _nodeB, _isInverted) => {
      if (valueA === valueB) {
        return 0;
      }
      return valueA.valueDescription?.localeCompare(valueB?.valueDescription);
    }
  }
];
