import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { InstrumentComponent, InstrumentsContainerComponent } from './components';
import { InstrumentResolve } from './resolves/instrument.resolve';
import { PermissionGuard } from '@routes/permission.guard';
import { InstrumentCouponFrequencyResolve } from './resolves/instrument-coupon-frequency.resolve';
import { TypedDatasResolve } from './resolves/typed-datas.resolve';
import { PartiesDatasResolve } from './resolves/parties-datas.resolve';

const INSTRUMENTS_ROUTES: Routes = [
  {
    path: 'instrument',
    component: InstrumentsContainerComponent,
    canActivate: [PermissionGuard]
  },
  {
    path: 'instrument/:pageType',
    component: InstrumentComponent,
    canActivate: [PermissionGuard],
    resolve: {
      instrument: InstrumentResolve,
      couponFrequencies: InstrumentCouponFrequencyResolve,
      typeReferenceData: TypedDatasResolve,
      parties: PartiesDatasResolve
    },
    data: {
      types: ['COUPON_FREQUENCY', 'GLOBAL_NOTE_FORM', 'SETTLEMENT_CLEARING_ISSUING_AGENT', 'SETTLEMENT_CLEARING_SUBSCRIBER', 'PAYMENT_TYPE', 'CONFIRMATION_STATUS', 'CLEARING_ACCOUNT_NUMBER'],
      roles: [
        { role: 'CLEARING_SYSTEM', filter: 'INSTRUMENT' },
        { role: 'REGISTRAR', filter: 'INSTRUMENT' },
        { role: 'COMMON_DEPOSITARY', filter: 'INSTRUMENT' }
      ]
    }
  },
  {
    path: 'instrument/:pageType/:id',
    component: InstrumentComponent,
    canActivate: [PermissionGuard],
    resolve: {
      instrument: InstrumentResolve,
      couponFrequencies: InstrumentCouponFrequencyResolve,
      typeReferenceData: TypedDatasResolve,
      parties: PartiesDatasResolve
    },
    data: {
      types: ['COUPON_FREQUENCY', 'GLOBAL_NOTE_FORM', 'SETTLEMENT_CLEARING_ISSUING_AGENT', 'SETTLEMENT_CLEARING_SUBSCRIBER', 'PAYMENT_TYPE', 'CONFIRMATION_STATUS', 'CLEARING_ACCOUNT_NUMBER'],
      roles: [
        { role: 'CLEARING_SYSTEM', filter: 'INSTRUMENT' },
        { role: 'REGISTRAR', filter: 'INSTRUMENT' },
        { role: 'COMMON_DEPOSITARY', filter: 'INSTRUMENT' }
      ]
    }
  },
  {
    path: 'instrument/:pageType/:id/tranche/:trancheNumber',
    component: InstrumentComponent,
    canActivate: [PermissionGuard],
    resolve: {
      instrument: InstrumentResolve,
      couponFrequencies: InstrumentCouponFrequencyResolve,
      typeReferenceData: TypedDatasResolve,
      parties: PartiesDatasResolve
    },
    data: {
      types: ['COUPON_FREQUENCY', 'GLOBAL_NOTE_FORM', 'SETTLEMENT_CLEARING_ISSUING_AGENT', 'SETTLEMENT_CLEARING_SUBSCRIBER', 'PAYMENT_TYPE', 'CONFIRMATION_STATUS', 'CLEARING_ACCOUNT_NUMBER'],
      roles: [
        { role: 'CLEARING_SYSTEM', filter: 'INSTRUMENT' },
        { role: 'REGISTRAR', filter: 'INSTRUMENT' },
        { role: 'COMMON_DEPOSITARY', filter: 'INSTRUMENT' }
      ]
    }
  },
  {
    path: 'instrument/:pageType/:id/:tab',
    component: InstrumentComponent,
    canActivate: [PermissionGuard],
    resolve: {
      instrument: InstrumentResolve,
      couponFrequencies: InstrumentCouponFrequencyResolve,
      typeReferenceData: TypedDatasResolve,
      parties: PartiesDatasResolve
    },
    data: {
      types: ['COUPON_FREQUENCY', 'GLOBAL_NOTE_FORM', 'SETTLEMENT_CLEARING_ISSUING_AGENT', 'SETTLEMENT_CLEARING_SUBSCRIBER', 'PAYMENT_TYPE', 'CONFIRMATION_STATUS', 'CLEARING_ACCOUNT_NUMBER'],
      roles: [
        { role: 'CLEARING_SYSTEM', filter: 'INSTRUMENT' },
        { role: 'REGISTRAR', filter: 'INSTRUMENT' },
        { role: 'COMMON_DEPOSITARY', filter: 'INSTRUMENT' }
      ]
    }
  },
  {
    path: 'instrument/:pageType/:id/:tab/:trancheNumber',
    component: InstrumentComponent,
    canActivate: [PermissionGuard],
    resolve: {
      instrument: InstrumentResolve,
      couponFrequencies: InstrumentCouponFrequencyResolve,
      typeReferenceData: TypedDatasResolve,
      parties: PartiesDatasResolve
    },
    data: {
      types: ['COUPON_FREQUENCY', 'GLOBAL_NOTE_FORM', 'SETTLEMENT_CLEARING_ISSUING_AGENT', 'SETTLEMENT_CLEARING_SUBSCRIBER', 'PAYMENT_TYPE', 'CONFIRMATION_STATUS', 'CLEARING_ACCOUNT_NUMBER'],
      roles: [
        { role: 'CLEARING_SYSTEM', filter: 'INSTRUMENT' },
        { role: 'REGISTRAR', filter: 'INSTRUMENT' },
        { role: 'COMMON_DEPOSITARY', filter: 'INSTRUMENT' }
      ]
    }
  }
];
export const instrumentsRoutes = RouterModule.forRoot(INSTRUMENTS_ROUTES, { preloadingStrategy: PreloadAllModules });
