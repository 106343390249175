import { Component, OnInit } from '@angular/core';
import { TrancheHistoryColumnDefService } from '@instruments/components/instrument-list/_aggrid/tranche-history-column-def-service';
import { InstrumentModel, TrancheModel } from '@instruments/models';
import { InstrumentFormService } from '@instruments/services';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { ColDef, GridOptions } from 'ag-grid-community';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-instrument-tranche-history',
  templateUrl: './instrument-tranche-history.component.html',
  providers: [TrancheHistoryColumnDefService]
})
export class InstrumentTrancheHistoryComponent implements OnInit {
  public columnDefs: ColDef[] = [];
  public defaultColDef: any;
  public gridOptions!: GridOptions;
  public upto = '';

  constructor(private readonly instrumentFormService: InstrumentFormService, private readonly trancheHistoryColumnDefService: TrancheHistoryColumnDefService, private readonly translateService: I18nUtilsService) {}

  ngOnInit(): void {
    this.upto = this.instrumentFormService.rawValue().upto ? 'instruments.instrument.forms.tranche-history.increase' : 'instruments.instrument.forms.tranche-history.tranche';

    this.columnDefs = this.trancheHistoryColumnDefService.get();
    const trancheHistoryColumnDef = this.trancheHistoryColumnDefService.get() as ColDef[];

    trancheHistoryColumnDef.forEach((col: ColDef) => {
      if (col.field === 'trancheNumber') {
        col.headerValueGetter = () => {
          return this.translateService.getI18nValue(this.upto) + this.translateService.getI18nValue('instruments.instrument.forms.tranche-history.trancheIncreaseNumber');
        };
        col.valueFormatter = (params: { value: string }) => {
          return this.translateService.getI18nValue(this.upto) + params.value;
        };
      }
    });

    this.gridOptions = {
      pagination: false,
      suppressPaginationPanel: false,
      paginationPageSize: 10,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      suppressContextMenu: true,
      stopEditingWhenCellsLoseFocus: true
    };
    this.defaultColDef = {
      sortable: false,
      filter: false,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true }
    };
  }

  onGridReady(params: any): void {
    const gridApi = params.api;
    gridApi.setDomLayout('autoHeight');
    gridApi.refreshHeader();

    if (this.instrument?.tradingMethodIdentifier?.valueDescription?.toLowerCase() === 'nominal') {
      this.gridOptions.columnApi?.setColumnsVisible(['numberOfShares'], false);
      this.gridOptions.columnApi?.setColumnsVisible(['outstandingNumberOfShares'], false);
    } else {
      this.gridOptions.columnApi?.setColumnsVisible(['nominal'], false);
      this.gridOptions.columnApi?.setColumnsVisible(['outstandingNominal'], false);
    }
    params.api.sizeColumnsToFit();
  }
  public get instrument(): InstrumentModel | null {
    return this.instrumentFormService.value();
  }
  public get tranches$(): Observable<TrancheModel[]> {
    return this.instrumentFormService?.tranches$ ?? [];
  }
}
