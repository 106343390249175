/**
 * issuer-ipa-events-api API
 * Manage events data
 *
 * The version of the OpenAPI document: 1.3.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * ValuationSearchRequestDTO Valuation
 */
export interface ValuationSearchRequestDTO { 
    eventTypeValueIds?: Array<number>;
    isinLists?: Array<string>;
    valuationIds?: Array<number>;
    eventReferences?: Array<number>;
    valuationFromDate?: string;
    valuationToDate?: string;
    valueFromDate?: string;
    valueToDate?: string;
    valuationStatusLists?: Array<number>;
    receptionFromDate?: string;
    receptionToDate?: string;
    pageNumber?: number;
    pageSize?: number;
    redemptionTypeIds?: Array<number>;
    valuationVersion?: string;
    valuationRejectReasons?: Array<string>;
}

