export const EVENT_CONSTANTS = {
  eventTypes: {
    INTR: 'INTR',
    REDM: 'REDM',
    PRED: 'PRED',
    PCAL: 'PCAL',
    MCAL: 'MCAL',
    DVCA: 'DVCA',
    EXCH: 'EXCH',
    CERT: 'CERT'
  },
  redemptionType: {
    C: 'C'
  },
  partialRedemptionType: {
    creditEvent: 'CREDIT EVENT'
  },
  couponType: {
    zeroCoupon: 'ZERO-COUPON'
  },
  tradingMethod: {
    UNIT: 'UNIT',
    NOMINAL: 'NOMINAL'
  },
  leiCodeSocieteGeneraleLuxembourg: 'TPS0Q8GFSZF45ZZFL873',
  leiClearstreamBankingSALuxembourg: '549300OL514RA0SXJJ44',
  leiEuroclearBankBelgium: '549300OZ46BRLZ8Y6F65',
  dayCountFraction: {
    EXA_EXA_ICMA_ISMA: 'EXA/EXA ICMA/ISMA'
  },
  status: {
    eventToBeInput: 'EVENT TO BE INPUT',
    cancelled: 'CANCELLED',
    eventToBeValidated: 'EVENT TO BE VALIDATED',
    eventValidated: 'EVENT VALIDATED',
    valuationInRepair: 'VALUATION IN REPAIR',
    fixingDateToBeReached: 'FIXING DATE TO BE REACHED',
    notificationBlocked: 'NOTIFICATION BLOCKED',
    valuationChased: 'VALUATION CHASED',
    notificationSent: 'NOTIFICATION SENT',
    generateRequestOfFunds: 'GENERATE REQUEST OF FUNDS',
    requestOfFundsSent: 'REQUEST OF FUNDS SENT',
    generatePreAdvise: 'GENERATE PREADVISE',
    preAdviseBlocked: 'PREADVISE BLOCKED',
    paymentDone: 'PAYMENT DONE',
    paymentBlocked: 'PAYMENT BLOCKED',
    paymentToBeInput: 'PAYMENT TO BE INPUT',
    preAdviseSent: 'PREADVISE SENT',
    vamNotificationToBeSent: 'VAM-NOTIFICATION TO BE SENT',
    requestOfFundsBlocked: 'REQUEST OF FUNDS BLOCKED',
    paymentInRepair: 'PAYMENT IN REPAIR',
    notificationInRepair: 'NOTIFICATION IN REPAIR',
    preadviseInRepair: 'PREADVISE IN REPAIR',
    paymentInitiated: 'PAYMENT_INITIATED',
    paymentFailed: 'PAYMENT FAILED',
    markDownDone: 'MARKDOWN DONE',
    markDownPending: 'MARKDOWN PENDING',
    eventClosed: 'EVENT CLOSED',
    exchangeToValidate: 'EXCHANGE TO VALIDATE',
    confirmationDone: 'CONFIRMATION DONE',
    exchangeToDo: 'EXCHANGE_TO_DO',
    certificationToValidate: 'CERTIFICATION TO VALIDATE',
    certificationToDo: 'CERTIFICATION TO DO',
    requestOfFundsInRepair: 'REQUEST OF FUNDS IN REPAIR',
    valueId: {
      paymentInRepair: 19,
      paymentDone: 20,
      paymentFailed: 21,
      markDownDone: 22,
      markDownPending: 23,
      eventClosed: 6
    }
  },
  clearingSystem: {
    clearStream: 'Clearstream',
    euroClear: 'Euroclear'
  }
};
