import { IpaTypeCreateDto, IpaTypeDto } from '@apis/backend/instruments';
import { TypedDataModel } from './type-data.model';

export interface IpaTypeModel {
  readonly valueId?: number;
  readonly valueDescription?: string;
}

export const toIpaTypeModel = (dto: IpaTypeDto | null | undefined): IpaTypeModel | null => {
  if (!dto) {
    return null;
  }

  return {
    valueDescription: dto.valueDescription,
    valueId: dto.valueId
  };
};

export const toIpaTypeDto = (model: IpaTypeModel | IpaTypeModel[] | null | undefined): IpaTypeCreateDto | undefined => {
  if (!model) {
    return undefined;
  }
  if (Array.isArray(model)) {
    return {
      valueId: model[0].valueId
    };
  }
  return {
    valueId: model.valueId
  };
};
export const toIpaTypeModelFromTypedDataModel = (model: TypedDataModel | null | undefined): IpaTypeModel | undefined => {
  if (!model) {
    return undefined;
  }

  return {
    valueId: +model.valueId,
    valueDescription: model.valueDescription
  };
};
