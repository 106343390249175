import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OPERATION_ACTIONS } from '@positions/models';
import { DialogModalComponent } from '@shared/modules/modals/components';
import { OperationDetailFormService } from './operation-detail.form.service';

@Injectable()
export class OperationModalsService {
  constructor(private readonly modalService: NgbModal, private readonly operationDetailFormService: OperationDetailFormService) {}

  public openSaveModal(): NgbModalRef {
    const mode = this.operationDetailFormService.formMode;
    const modal = this.modalService.open(DialogModalComponent);
    const dialog: DialogModalComponent = modal.componentInstance;
    dialog.dialogTitle = mode === 'edit' ? 'modals.titles.editOperation' : 'modals.titles.saveOperation';
    dialog.dialogBody = mode === 'edit' ? 'modals.contents.editOperation' : 'modals.contents.saveOperation';
    return modal;
  }
  public openConfirmChangeStatusModal(status: OPERATION_ACTIONS): NgbModalRef {
    const title = `operations.modals.titles.${status.toLocaleLowerCase()}`;
    const body = `operations.modals.contents.${status.toLocaleLowerCase()}`;
    const modal = this.modalService.open(DialogModalComponent);
    const dialog: DialogModalComponent = modal.componentInstance;
    dialog.dialogTitle = title;
    dialog.dialogBody = body;
    return modal;
  }
}
