import { formatNumber } from '@angular/common';
import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';

@Component({
  selector: 'app-numeric-cell',
  templateUrl: './numeric-cell.component.html'
})
export class NumericEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  public value!: number | string;
  private cancelBeforeStart = false;

  @ViewChild('input', { read: ViewContainerRef })
  public input!: ViewContainerRef;

  constructor(private readonly toastManagerService: ToastManagerService) {}

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.setInitialState(this.params);
    // only start edit if key pressed is a number, not a letter
    this.cancelBeforeStart = !!(params.charPress && '1234567890'.indexOf(params.charPress) < 0);
  }

  setInitialState(params: ICellEditorParams): void {
    let startValue;
    if (params.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = params.charPress;
    } else if (params.value !== undefined) {
      startValue = this.formattedNumber(params.value)?.toString().split(',').join('');
    } else {
      // otherwise we start with the current value
      startValue = params.value;
    }
    this.value = startValue;
  }

  getValue(): any {
    return this.value;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  isCancelAfterEnd(): boolean {
    if ((this.params.colDef.field === 'subscriberAccountNumber' || this.params.colDef.field === 'accountNumber') && this.value > 99999) {
      if (this.value > 99999) {
        this.toastManagerService.toastWarning('warrning', 'Account Number should have 5 digits');
      }
    }
    this.formattedNumber(this.value);
    return this.value > 99999;
  }

  formattedNumber(value?: number | null | string): string | null | number {
    if (this.params.colDef.field === 'subscriberAccountNumber' || this.params.colDef.field === 'accountNumber') {
      return this.value;
    } else if (value) {
      const parsedValue = +(value.toString() || '').split(',').join('');
      this.value = formatNumber(parsedValue, 'en', '1.4-4');
      return this.value;
    }
    return null;
  }

  onKeyDown(event: any): void {
    if (event.key === 'Escape') {
      return;
    }
    if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
      event.stopPropagation();
      return;
    }

    if (!this.finishedEditingPressed(event) && !this.isKeyPressedNumericOrDot(event)) {
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit(): void {
    window.setTimeout(() => {
      this.input.element.nativeElement.focus();
    });
  }

  private isCharNumeric(charStr: string): boolean {
    return !!/\d/.test(charStr);
  }

  private isKeyPressedNumericOrDot(event: any): boolean {
    const charStr = event.key;
    const valueChecked = this.value + charStr;
    if (valueChecked.split('.').length - 1 === 1 || this.isCharNumeric(charStr)) {
      return true;
    }
    return false;
  }

  private deleteOrBackspace(event: any) {
    return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1;
  }

  private isLeftOrRight(event: any) {
    return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
  }

  private finishedEditingPressed(event: any) {
    const key = event.key;
    return key === KEY_ENTER || key === KEY_TAB;
  }
}
