import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PagingRequestWithCriteriaModel } from '@instruments/models';
import { InstrumentService } from '@shared/services/instrument.service';
import { Observable, forkJoin } from 'rxjs';
import { GlobalNoteIndicatorCellClickPayload, GlobalNoteIndicatorRow } from '../global-note-indicators-grid/global-note-indicators-grid.model';
import {
  GlobalNoteDisposalMonitoringQuery,
  GlobalNoteDisposalMonitoringStatus,
  GlobalNoteDocumentationCdReceptionMonitoringQuery,
  GlobalNoteDocumentationCdReceptionMonitoringStatus,
  GlobalNoteIndicatorQueries,
  GlobalNotePermanentMonitoringQuery,
  GlobalNotePermanentMonitoringStatus,
  GnCdIndicators,
  QueryParameters
} from './global-note-indicators.model';
import { map } from 'rxjs/operators';
import { InstrumentResourceService } from '@apis/backend/instruments';

@Injectable()
export class GlobalNoteIndicatorsService {
  private readonly baseQuery: PagingRequestWithCriteriaModel = {
    itemsByPage: 50,
    pageNumber: 1
  };

  private queries: GlobalNoteIndicatorQueries | null = null;

  constructor(private readonly instrumentsService: InstrumentService, private readonly instrumentResourceService: InstrumentResourceService, private readonly router: Router) {}

  getGlobalNoteIndicatorRows$(): Observable<GlobalNoteIndicatorRow[][]> {
    this.queries = {
      documentationCdReceptionMonitoringQuery: this.buildDocumentationCdReceptionMonitoringQueries(),
      permanentMonitoringQuery: this.buildPermanentMonitoringQueries(),
      disposalMonitoringQuery: this.buildDisposalMonitoringQueries()
    };

    return forkJoin([this.instrumentResourceService.getAllIndicatorsByThemeAndCategory(), this.instrumentsService.getAllInstrumentsPaged(this.queries.permanentMonitoringQuery.EMPTY)]).pipe(
      map(([indicators]) => this.buildGlobalNoteIndicatorRows(indicators as GnCdIndicators))
    );
  }

  navigateToInstruments(data: GlobalNoteIndicatorCellClickPayload): void {
    const { type, rowData } = data;
    let targetQuery: PagingRequestWithCriteriaModel;
    if (this.queries) {
      switch (type) {
        case 'DocumentationCdReceptionMonitoring':
          targetQuery = this.queries.documentationCdReceptionMonitoringQuery[rowData.status as GlobalNoteDocumentationCdReceptionMonitoringStatus];
          break;
        case 'PermanentMonitoring':
          targetQuery = this.queries.permanentMonitoringQuery[rowData.status as GlobalNotePermanentMonitoringStatus];
          break;
        case 'DisposalMonitoring':
          targetQuery = this.queries.disposalMonitoringQuery[rowData.status as GlobalNoteDisposalMonitoringStatus];
          if (targetQuery.criteria) {
            targetQuery.criteria.disposalMonitoring = targetQuery.criteria?.disposalMonitoring?.valueId as any;
          }
          break;
      }

      this.router.navigate(['/instrument'], {
        queryParams: {
          query: JSON.stringify({
            ...targetQuery,
            ...targetQuery.criteria
          })
        }
      });
    }
  }

  private buildQuery({ cdFtReceived, cdGnReceived, permanentMonitoring, disposalMonitoring, endDate }: QueryParameters): PagingRequestWithCriteriaModel {
    return {
      ...this.baseQuery,
      criteria: {
        ...(cdFtReceived !== undefined ? { cdFtReceived } : {}),
        ...(cdGnReceived !== undefined ? { cdGnReceived } : {}),
        ...(permanentMonitoring !== undefined ? { permanentMonitoring: permanentMonitoring } : {}),
        ...(disposalMonitoring !== undefined ? { disposalMonitoring: disposalMonitoring } : {}),
        ...(endDate !== undefined ? { endIssuedate: formatDate(endDate, 'dd-MM-yyyy', 'en') } : {})
      }
    };
  }

  private buildDocumentationCdReceptionMonitoringQueries(): GlobalNoteDocumentationCdReceptionMonitoringQuery {
    return {
      GN_YES_FT_NO: this.buildQuery({ cdGnReceived: true, cdFtReceived: false }),
      GN_NO_FT_YES: this.buildQuery({ cdGnReceived: false, cdFtReceived: true }),
      GN_NO_FT_NO: this.buildQuery({ cdGnReceived: false, cdFtReceived: false }),
      GN_YES_FT_YES: this.buildQuery({ cdGnReceived: true, cdFtReceived: true })
    };
  }

  private buildPermanentMonitoringQueries(): GlobalNotePermanentMonitoringQuery {
    const endDate = new Date(new Date().getFullYear() - 1, 11, 31);
    return {
      YES: this.buildQuery({ permanentMonitoring: 'Yes', endDate }),
      NO: this.buildQuery({ permanentMonitoring: 'No', endDate }),
      EMPTY: this.buildQuery({ permanentMonitoring: 'Empty', endDate })
    };
  }

  private buildDisposalMonitoringQueries(): GlobalNoteDisposalMonitoringQuery {
    return {
      ONE: this.buildQuery({ disposalMonitoring: { valueId: 'One', valueDescription: 'One' } }),
      BOTH: this.buildQuery({ disposalMonitoring: { valueId: 'Both', valueDescription: 'Both' } })
    };
  }

  private buildGlobalNoteIndicatorRows(indicators: GnCdIndicators): GlobalNoteIndicatorRow[][] {
    const statusesToGnCdDocumentReceptionIndicator = indicators.gnCdDocumentReceptionDtos.reduce((acc, cur) => {
      acc[`${cur.isGnReceivedIndicator}_${cur.isFtReceivedIndicator}`] = cur.receptionDocCount;
      return acc;
    }, {} as { [key: string]: number });

    const statusesToGnCdPermanentMonitoringIndicator = indicators.gnCdPermanentMonitoringDtos.reduce((acc, cur) => {
      acc[`${cur.pmStatus}`] = cur.permanentMonitoringCount;
      return acc;
    }, {} as { [key: string]: number });

    const [gnCdDisposalMonitoringIndicator] = indicators.gnCdDisposalMonitoringDtos;

    return [
      [
        {
          status: 'GN_YES_FT_NO',
          displayStatus: 'With GN received to Yes, and FT received to No',
          value: statusesToGnCdDocumentReceptionIndicator['true_false']
        },
        {
          status: 'GN_NO_FT_YES',
          displayStatus: 'With GN received to No, and FT received to Yes',
          value: statusesToGnCdDocumentReceptionIndicator['false_true']
        },
        {
          status: 'GN_NO_FT_NO',
          displayStatus: 'With GN received to No and FT received to No',
          value: statusesToGnCdDocumentReceptionIndicator['false_false']
        },
        {
          status: 'GN_YES_FT_YES',
          displayStatus: 'With GN received to Yes and FT received to Yes',
          value: statusesToGnCdDocumentReceptionIndicator['true_true']
        }
      ],
      [
        {
          status: 'YES',
          displayStatus: 'YES - OK',
          value: statusesToGnCdPermanentMonitoringIndicator['true']
        },
        {
          status: 'NO',
          displayStatus: 'NO - KO',
          value: statusesToGnCdPermanentMonitoringIndicator['false']
        },
        {
          status: 'EMPTY',
          displayStatus: 'EMPTY',
          value: statusesToGnCdPermanentMonitoringIndicator['']
        }
      ],
      [
        {
          status: 'ONE',
          displayStatus: 'For only one clearer',
          value: gnCdDisposalMonitoringIndicator?.disposalOneClearerCount
        },
        {
          status: 'BOTH',
          displayStatus: 'For both clearer',
          value: gnCdDisposalMonitoringIndicator?.disposalBothClearerCount
        }
      ]
    ];
  }
}
