/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component } from '@angular/core';
import { InstrumentCriteriaSearchModel } from '@instruments/models';
import { InstrumentFilterSelectorService } from '@instruments/services/instrument-filter-selector.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FiltersSaveBaseComponent } from '@shared/modules/save-filter/filters-save/filters-save-base.component';

@Component({
  selector: 'app-instrument-filters-save',
  templateUrl: './instrument-filters-save.component.html'
})
export class InstrumentFiltersSaveComponent extends FiltersSaveBaseComponent<InstrumentCriteriaSearchModel, InstrumentFilterSelectorService> {
  constructor(public activeModal: NgbActiveModal, filterSelectorService: InstrumentFilterSelectorService) {
    super(activeModal, filterSelectorService);
  }
}
