<ng-container *ngIf="programmeInformation">
  <div class="container g-0">
    <div class="row gy-4">
      <div class="pe-3 col-md-4" *ngIf="programmeInformation!.bdrId">
        <label class="text-secondary fw-medium">{{ 'programmes.programme.forms.programmeCharacteristics.programmeInformation.fullLegalName' | translate }}</label>
        <div>{{ programmeInformation!.fullLegalName }}</div>
      </div>
      <div class="pe-3 col-md-4">
        <label class="text-secondary fw-medium">{{ 'programmes.programme.forms.programmeCharacteristics.programmeInformation.bdrId' | translate }}</label>
        <div>{{ programmeInformation!.bdrId }}</div>
      </div>
      <div class="pe-3 col-md-4">
        <label class="text-secondary fw-medium">{{ 'programmes.programme.forms.programmeCharacteristics.programmeInformation.rctId' | translate }}</label>
        <div>{{ programmeInformation!.rctIdentifier }}</div>
      </div>
    </div>
    <div class="row gy-4 mt-1" [ngClass]="{ 'mb-4': formMode === 'add' }">
      <div class="pe-3 col-md-4">
        <label class="text-secondary fw-medium">{{ 'programmes.programme.forms.programmeCharacteristics.programmeInformation.relationshipNature' | translate }}</label>
        <div>{{ programmeInformation!.relationshipNature }}</div>
      </div>
      <div class="pe-3 col-md-4">
        <label class="text-secondary fw-medium">{{ 'programmes.programme.forms.programmeCharacteristics.programmeInformation.status' | translate }}</label>
        <div>{{ programmeInformation!.status }}</div>
      </div>
    </div>
  </div>
</ng-container>
