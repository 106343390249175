import { Component, Input, OnDestroy } from '@angular/core';
import { InstrumentModel } from '@instruments/models';
import {
  CharacteristicFormService,
  EventsDataFormService,
  GlobalNoteFormService,
  InstrumentAddendumFormService,
  InstrumentFormService,
  IssuanceFormService,
  ListingFormService,
  RoleDataFormService,
  TrancheDisplayService,
  TrancheFormService
} from '@instruments/services';
import { FongeTrancheFormService } from '@instruments/services/forms/fonge-tranche.form.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tranche-form-container',
  templateUrl: './tranche-fonge-container.component.html',
  providers: [
    FongeTrancheFormService,
    InstrumentFormService,
    CharacteristicFormService,
    EventsDataFormService,
    GlobalNoteFormService,
    InstrumentAddendumFormService,
    IssuanceFormService,
    ListingFormService,
    TrancheFormService,
    TrancheDisplayService,
    RoleDataFormService
  ]
})
export class TrancheFongeContainerComponent implements OnDestroy {
  public ClosedReason: DialogResult = 'closed';
  public CancelReason: DialogResult = 'cancel';
  public ConfirmReason: DialogResult = 'confirm';
  private readonly subscriptions: Subscription[] = [];

  confirmButton = 'modals.buttons.confirm';
  cancelButton = 'modals.buttons.cancel';
  @Input() set instrument(value: InstrumentModel | null | undefined) {
    if (value) {
      this.subscriptions.push(...this.displayService.Compile().map(o => o.subscribe()));
      this.fongeTrancheFormService.patch('certifiedNominal', +(value.outstandingNominal ?? 0));
      this.instrumentFormService.setInitialValue(value);
    }
  }

  constructor(private readonly activeModal: NgbActiveModal, public readonly instrumentFormService: InstrumentFormService, private readonly fongeTrancheFormService: FongeTrancheFormService, private readonly displayService: TrancheDisplayService) {}

  public onClose(): void {
    this.activeModal.dismiss(this.ClosedReason);
    this.activeModal.close(this.ClosedReason);
  }

  public onCancel(): void {
    this.activeModal.close(this.CancelReason);
  }
  public onConfirm(): void {
    this.fongeTrancheFormService.updateValueAndValidity();
    if (this.fongeTrancheFormService.valid) {
      this.activeModal.close({ reason: this.ConfirmReason, fonge: this.fongeTrancheFormService.value() });
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
