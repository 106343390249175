import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../../shared.module';
import { FormValidationErrorsComponent } from './form-validation-errors/form-validation-errors.component';
import { FormItemComponent } from './form-item/form-item.component';

@NgModule({
  declarations: [FormItemComponent, FormValidationErrorsComponent],
  exports: [FormItemComponent, FormValidationErrorsComponent],
  imports: [CommonModule, TranslateModule, SharedModule]
})
export class LocalCustomFormModule {}
