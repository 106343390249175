<app-navbar class="sticky"></app-navbar>
<app-toasts></app-toasts>
<router-outlet></router-outlet>

<!-- This ensures the main page is top-aligned instead of vertically centered. -->
<div class="vertical-align-flex-spacer"></div>

<sgwt-help-center
  id="sgwt-help-center"
  sg-connect-support="sg-connect-v2"
  application-id="issuer-ipa-frontend"
  introduction-tour
>
 
</sgwt-help-center>

<sgwt-mini-footer
  design="light"
  mode="sg-markets"
  container="container-fluid"
  cookies-consent
  contact-us="{'mail':'list.fr-ft-issuer-beam@socgen.com'}"
  accessibility-compliance="none"
  accessibility-link="https://shared.sgmarkets.com/accessibility/statement/en.html?redirect_uri=https://IPA"
></sgwt-mini-footer>
