<div class="modal-header">
  <h3 class="modal-title" translate>operations.forms.operationCreation.title</h3>
  <button type="button" class="close icon p-1" (click)="onClose()">close</button>
</div>
<div class="modal-body">
  <div class="section-forms container g-0" [class.pb-5]="!isClearstreamSelected && !isEuroclearSelected" [formGroup]="formGroup">
    <div class="row gy-4 mt-1">
      <div class="col-md-6">
        <app-form-item [field]="fields.isin" [focus]="isinInput">
          <input class="form-control" type="text" maxlength="12" [formControlName]="fields.isin"
            [placeholder]="placeholder(fields.isin) | translate" component #isinInput />
        </app-form-item>
      </div>
      <div class="col-md-6">
        <app-form-item [field]="fields.ipaCode" [focus]="ipaCodeInput">
          <input class="form-control" type="text" maxlength="12" [formControlName]="fields.ipaCode"
            [placeholder]="placeholder(fields.ipaCode) | translate" component #ipaCodeInput />
        </app-form-item>
      </div>
    </div>
    <div class="row gy-4 mt-1">
      <div class="col-md-6">
        <app-form-item [field]="fields.valueDate" [focus]="valueDatenput">
          <app-date-picker [formControlName]="fields.valueDate" #valueDatenput component></app-date-picker>
        </app-form-item>
      </div>
      <div class="col-md-6">
        <app-form-item [field]="fields.instructionDate" [focus]="instructionDateInput">
          <app-date-picker [formControlName]="fields.instructionDate" #instructionDateInput component></app-date-picker>
        </app-form-item>
      </div>
    </div>

    <div class="row gy-4 mt-1">
      <div class="col-md-6">
        <app-form-item [field]="fields.type" [focus]="operationType">
          <app-type-multiple-select #operationType component [formControlName]="fields.type" size="lg" [multiple]="false"
            [closeOnSelect]="true" [type]="OPERATION_TYPE" [hasError]="hasError(fields.type)"
            placeholder="{{ placeholder(fields.type) | translate }}">
          </app-type-multiple-select>
        </app-form-item>
      </div>
      <div class="col-md-6">
        <app-form-item [field]="fields.icsd">
          <app-party-multiple-check #icsdInput component [formControlName]="fields.icsd" classes="form-control-lg"
            [type]="CLEARING_SYSTEM_ROLE" [filter]="OPERATION_FILTER"
            placeholder="{{ placeholder(fields.icsd) | translate }}" [hasError]="hasError(fields.icsd)">
          </app-party-multiple-check>
        </app-form-item>
      </div>
    </div>
    <ng-container *ngIf="isClearstreamSelected">
      <div class="row gy-4 mt-1">
        <div class="col-md-6">
          <h5 *ngIf="isClearstreamSelected" translate>operations.forms.operationCreation.clearStream</h5>
        </div>
      </div>
      <div class="row gy-4 mt-1">
        <div class="col-md-6">
          <app-form-item [field]="fields.ClearValue" [focus]="ClearValueInput">
            <app-number [formControlName]="fields.ClearValue" [placeholder]="placeholder(fields.ClearValue) | translate"
              component #ClearValueInput></app-number>
          </app-form-item>
        </div>
        <div class="col-md-6">
          <app-form-item [field]="fields.ClearOperation" [focus]="ClearOperationInput">
            <app-yes-no-buttons [yesLabel]="'Credit'" [noLabel]="'Debit'" [formControlName]="fields.ClearOperation"
              #ClearOperationInput component> </app-yes-no-buttons>
          </app-form-item>
        </div>
      </div>
      <div class="row gy-4 mt-1">
        <div class="col-md-6">
          <app-form-item [field]="fields.ClearSendSwift" [focus]="ClearSendSwiftInput">
            <app-yes-no-buttons [formControlName]="fields.ClearSendSwift" #ClearSendSwiftInput component>
            </app-yes-no-buttons>
          </app-form-item>
        </div>
        <div class="col-md-6">
          <app-form-item [field]="fields.ClearSEMEReference" [focus]="ClearSEMEReferenceInput">
            <input class="form-control" type="text" maxlength="16" [formControlName]="fields.ClearSEMEReference"
              [placeholder]="placeholder(fields.ClearSEMEReference) | translate" component #ClearSEMEReferenceInput />
          </app-form-item>
        </div>
      </div>
      <div class="row gy-4 mt-1">
        <div class="col-md-6">
          <app-form-item [field]="fields.ClearIcsdAccountNumber" [focus]="ClearIcsdAccountNumber">
            <input class="form-control" type="text" maxlength="5" [formControlName]="fields.ClearIcsdAccountNumber"
              [placeholder]="placeholder(fields.icsdAccountNumber) | translate" component #ClearIcsdAccountNumber />
          </app-form-item>
        </div>
      </div>
    </ng-container>
    <hr *ngIf="showSeparation" />
    <ng-container *ngIf="isEuroclearSelected">
      <div class="row gy-4 mt-1">
        <div class="col-md-6">
          <h5 *ngIf="isEuroclearSelected" translate>operations.forms.operationCreation.euroClear</h5>
        </div>
      </div>
      <div class="row gy-4 mt-1">
        <div class="col-md-6">
          <app-form-item [field]="fields.value" [focus]="valueInput">
            <app-number [formControlName]="fields.value" [placeholder]="placeholder(fields.value) | translate" component
              #valueInput></app-number>
          </app-form-item>
        </div>
        <div class="col-md-6">
          <app-form-item [field]="fields.operation" [focus]="operationInput">
            <app-yes-no-buttons [yesLabel]="'Credit'" [noLabel]="'Debit'" [formControlName]="fields.operation"
              #operationInput component> </app-yes-no-buttons>
          </app-form-item>
        </div>
      </div>
      <div class="row gy-4 mt-1">
        <div class="col-md-6">
          <app-form-item [field]="fields.sendSwift" [focus]="sendSwiftInput">
            <app-yes-no-buttons [formControlName]="fields.sendSwift" #sendSwiftInput component> </app-yes-no-buttons>
          </app-form-item>
        </div>
        <div class="col-md-6">
          <app-form-item [field]="fields.SEMEReference" [focus]="SEMEReferenceInput">
            <input class="form-control" type="text" maxlength="16" [formControlName]="fields.SEMEReference"
              [placeholder]="placeholder(fields.SEMEReference) | translate" component #SEMEReferenceInput />
          </app-form-item>
        </div>
      </div>
      <div class="row gy-4 mt-1">
        <div class="col-md-6">
          <app-form-item [field]="fields.icsdAccountNumber" [focus]="icsdAccountNumber">
            <input class="form-control" type="text" maxlength="5" [formControlName]="fields.icsdAccountNumber"
              [placeholder]="placeholder(fields.icsdAccountNumber) | translate" component #icsdAccountNumber />
          </app-form-item>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer" [class.mt-5]="!isClearstreamSelected && !isEuroclearSelected">
    <button type="button" (click)="onCancel()" (keyup.enter)="onCancel()" class="btn btn-lg btn-link"
      translate>operations.forms.operationCreation.actions.cancel </button>
    <button ngbAutofocus type="button" (click)="onConfirm()" (keyup.enter)="onConfirm()" class="btn btn-lg btn-primary"
      translate>operations.forms.operationCreation.actions.confirm </button>
  </div>
</div>
