/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-text-icon-button',
  templateUrl: './text-icon-button.component.html'
})
export class TextIconButtonComponent {
  @Input() color: DesignSystemColorClass = 'default';
  @Input() icon: string | undefined;
  @Input() customClasses = '';
  @Input() label: string | undefined;
  @Input() size: DesignSystemSize = 'md';
  @Input() routerLink: buttonrouterLink | null | undefined;
  @Input() buttonType: 'btn-text-icon' | 'btn-icon-text' | 'btn-outline-primary' | 'btn-outline-default' | 'btn-primary' | '' = '';
  @Input() allowKeyup = false;
  @Input() submit = false;
  @Input() disabled = false;
  @Output() clicked = new EventEmitter();
  constructor(private readonly router: Router) {}

  public get classes(): string[] {
    return [`btn-${this.color}`, `btn-${this.size}`, this.buttonType, this.customClasses];
  }

  public onClick(): void {
    if ((Array.isArray(this.routerLink) && this.routerLink.length) || this.routerLink) {
      this.router.navigate([this.routerLink]);
    }
    this.clicked.emit();
  }
}
export type buttonrouterLink = any[] | string;
