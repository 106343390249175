import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class I18nUtilsService {
  constructor(private readonly translate: TranslateService) {}

  getI18nValue(keyString: string, interpolateParams?: Record<string, unknown>): string {
    return this.translate.instant(keyString, interpolateParams);
  }
}
