import { Routes } from '@angular/router';
import { HomeComponent } from '@home/components';
import { PartiesDatasResolve } from '@instruments/resolves/parties-datas.resolve';
import { TypedDatasResolve } from '@instruments/resolves/typed-datas.resolve';

const homeResolvers = {
  resolve: {
    typeReferenceData: TypedDatasResolve,
    parties: PartiesDatasResolve
  },
  data: {
    types: ['SETTLEMENT_WORKFLOW_STATUS', 'TRANCHE_WORKFLOW_STATUS', 'INSTRUMENT_TYPE'],
    roles: [{ role: 'ISSUER', filter: 'INSTRUMENT' }]
  }
};

export const ROUTES: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    ...homeResolvers
  },
  {
    path: '',
    component: HomeComponent,
    ...homeResolvers
  },

  {
    path: 'unauthorized',
    loadChildren: () => import('./authorization/authorization.module').then(m => m.AuthorizationModule)
  },
  { path: '**', redirectTo: 'instrument' }
];
