import { Injectable } from '@angular/core';
import { SettlementModel } from '@instruments/models/settlement.model';
import { TypedDataModel } from '@shared/models';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { ColDef } from 'ag-grid-community';
import { AgGridColumnDefBaseService } from '@shared/services/aggrid-column-def-base-service';
import { INSTRUMENT_SETTLEMENT_COLUMNS_EDITABLE } from './instrument-settlement-column-def-edit';

@Injectable()
export class InstrumentSettlementColumnDefEditService extends AgGridColumnDefBaseService {
  constructor(translateService: I18nUtilsService) {
    super(translateService, 'instruments.instrument.forms.settlement', INSTRUMENT_SETTLEMENT_COLUMNS_EDITABLE);
  }
  public init(subscriberClearings: TypedDataModel[], clearingIssuingAgent: TypedDataModel[], paymentType: TypedDataModel[], confirmationStatus: TypedDataModel[], clearingAccountNumber: TypedDataModel[]): ColDef[] {
    const columns = this.columnDefs;

    for (const field of columns) {
      field.headerValueGetter = this.localizeHeader.bind(this);
      if ('children' in field) {
        const children = field?.children;
        children.forEach(child => {
          child.headerValueGetter = this.localizeHeader.bind(this);
          if ('field' in child) {
            child.cellEditorParams = this.getCellEditorParams(child.field as keyof SettlementModel, subscriberClearings, clearingIssuingAgent, paymentType, confirmationStatus, clearingAccountNumber);
          }
        });
      }
    }
    return columns;
  }

  private getCellEditorParams(field: string, subscriberClearings: TypedDataModel[], clearingIssuingAgent: TypedDataModel[], paymentType: TypedDataModel[], confirmationStatus: TypedDataModel[], clearingAccountNumber: TypedDataModel[]): any {
    switch (field) {
      case 'subscriberClearing':
        return { values: subscriberClearings.map(sc => sc.valueDescription) };
      case 'issuingAgentClearing':
        return { values: clearingIssuingAgent.map(sc => sc.valueDescription) };
      case 'paymentType':
        return { values: paymentType.map(sc => sc.valueDescription) };
      case 'issuingSettlementStatus':
        return { values: confirmationStatus.map(sc => sc.valueDescription) };
      case 'clearingAccountNumber':
        return { values: clearingAccountNumber.map(sc => sc.valueDescription) };
    }

    return undefined;
  }
}
