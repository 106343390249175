import { AgGridModule } from 'ag-grid-angular';

import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { CustomAgGridComponent } from './custom-ag-grid.component';

@NgModule({
  declarations: [CustomAgGridComponent],
  imports: [CommonModule, AgGridModule],
  exports: [CustomAgGridComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CustomAgGridModule {}
