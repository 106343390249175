import { Subject } from 'rxjs';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorListFromBackendService {
  listError: string[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listErrorSubject$ = new Subject<any[]>();

  public emitDocuments(): void {
    this.listErrorSubject$.next(this.listError);
  }

  public resetListError(): void {
    this.listError = [];
    this.emitDocuments();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public showError(error: any): void {
    this.listError = [];
    const violations = error?.error?.violations || {};
    Object.values(violations).forEach((val: any) => {
      const field: string = val['field'];
      const message: string = val['message'];
      this.listError.push(`${field} : ${message}`);
    });

    this.emitDocuments();
  }
}
