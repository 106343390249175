import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InstrumentSettlementColumnDefEditService } from '@instruments/components/instrument-list/_aggrid/instrument-settlement-column-def-edit-service';
import { SETTLEMENT_CONSTANTS } from '@instruments/configs/instrument-constants';
import { TrancheModel } from '@instruments/models';
import { SettlementModel } from '@instruments/models/settlement.model';
import { routeToSettlement } from '@instruments/routes';
import { InstrumentFormService, InstrumentModalsService } from '@instruments/services';
import { IpaTypeModel, TypedDataModel } from '@shared/models';
import { EditingDatePickerComponent } from '@shared/modules/custom-aggrid-editing/editing-date-picker/editing-date-picker.component';
import { InstrumentService } from '@shared/services/instrument.service';
import { CellValueChangedEvent, ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IpaTypeModelRules as R } from '@instruments/rules';
import { FORM_DATE_FORMAT } from '@shared/pipes/date-format/common';

@Component({
  selector: 'app-instrument-settlement-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  providers: [InstrumentSettlementColumnDefEditService]
})
export class InstrumentSettlementEditComponent implements OnInit, OnDestroy {
  public settlemnts?: SettlementModel[] = [];
  public updatedsettlemnts: SettlementModel[] = [];
  public updatedsettlemntsIds: number[] = [];
  @Input() canModify?: boolean;
  @Input() canSave?: boolean;
  @Output() canModifyChange = new EventEmitter<boolean>();
  @Output() canSaveChange = new EventEmitter<boolean>();
  public gridOptions!: GridOptions;
  public columnDefs: ColDef[] = [];
  public defaultColDef: any;
  gridApi!: GridApi;
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public shutdown$ = new Subject<void>();
  public instrumentId?: number;
  public tradingMethod!: IpaTypeModel | null;
  private selectedTranche: TrancheModel | undefined;
  private readonly subscriptions: Subscription[] = [];
  private referenceData: Record<string, TypedDataModel[]> = {};
  constructor(
    private readonly instrumentSettlementColumnDefEditService: InstrumentSettlementColumnDefEditService,
    private readonly router: Router,
    private readonly instrumentService: InstrumentService,
    private readonly instrumentFormService: InstrumentFormService,
    private readonly modalsService: InstrumentModalsService,
    private readonly activeRoute: ActivatedRoute,
    private readonly instrumentModalsService: InstrumentModalsService
  ) {}

  ngOnInit(): void {
    this.tradingMethod = this.instrumentFormService.rawValue('tradingMethodIdentifier') || null;
    this.referenceData = this.activeRoute.snapshot.data['typeReferenceData'] as Record<string, TypedDataModel[]>;
    const instrument = this.instrumentFormService.value();
    this.instrumentId = instrument?.id || 1;
    this.subscriptions.push(
      this.instrumentFormService.currentTranche$.subscribe(tranche => {
        this.selectedTranche = tranche || null;
        this.load();
      })
    );

    this.columnDefs = this.instrumentSettlementColumnDefEditService.init(
      this.referenceData['SETTLEMENT_CLEARING_SUBSCRIBER'],
      this.referenceData['SETTLEMENT_CLEARING_ISSUING_AGENT'],
      this.referenceData['PAYMENT_TYPE'],
      this.referenceData['CONFIRMATION_STATUS'],
      this.referenceData['CLEARING_ACCOUNT_NUMBER']
    );
    this.gridOptions = {
      pagination: false,
      suppressPaginationPanel: false,
      paginationPageSize: 10,
      enableCellTextSelection: true,
      frameworkComponents: {
        agDateInput: EditingDatePickerComponent
      },
      rowClassRules: {
        'bg-danger': params => {
          return (params.data as SettlementModel).toBeDeleted;
        },
        'disable-settlement': params => {
          return (
            (params.data as SettlementModel).issuingSettlementStatus?.valueDescription !== SETTLEMENT_CONSTANTS.statuses.INITIALIZED &&
            (params.data as SettlementModel).issuingSettlementStatus?.valueDescription !== SETTLEMENT_CONSTANTS.statuses.IN_REPAIR &&
            params.data.id
          );
        }
      },
      ensureDomOrder: true,
      suppressContextMenu: true,
      stopEditingWhenCellsLoseFocus: true
    };
    this.defaultColDef = {
      sortable: false,
      filter: false,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true }
    };
  }

  load(): void {
    this.instrumentService
      .getAllSettlements(this.instrumentId, this.selectedTranche?.trancheNumber)
      .pipe(takeUntil(this.shutdown$))
      .subscribe(result => {
        if (result) {
          this.settlemnts = result;
        }
      });
  }

  add(): void {
    this.gridApi.applyTransaction({
      add: [{}]
    });
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.refreshHeader();

    if (R.isTradingMethodNominal(this.instrumentFormService.rawValue('tradingMethodIdentifier'))) {
      this.gridOptions.columnApi?.setColumnsVisible(['numberOfshares'], false);
    } else {
      this.gridOptions.columnApi?.setColumnsVisible(['amount'], false);
    }

    const body = document.querySelector('body');
    if (body) {
      this.gridApi.setPopupParent(body);
    }
  }

  onCancelClick(): void {
    const modal = this.instrumentModalsService.openCancelModal();
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        return;
      } else {
        this.canModify = true;
        this.canSave = false;
        this.canModifyChange.emit(true);
        this.canSaveChange.emit(false);
        this.load();
      }
    });
  }

  onCellValueChanged(e: CellValueChangedEvent): void {
    if (!this.updatedsettlemntsIds.includes(e.data.id) && e.data.id) {
      this.updatedsettlemntsIds.push(e.data.id);
    }
  }

  onSaveClick(): void {
    this.gridApi.stopEditing(false);
    const currentTrancheNumber = this.selectedTranche ? this.selectedTranche.trancheNumber : 1;
    const rowData: SettlementModel[] = [];
    this.gridApi.forEachNode(node => {
      node.data.instrumentTrancheId = this.instrumentId;
      node.data.settlementCurrency = this.instrumentFormService.value()?.nominalCurrency;
      if (typeof node.data.subscriberClearing == 'string') {
        node.data.subscriberClearing = this.referenceData['SETTLEMENT_CLEARING_SUBSCRIBER'].find(settlementClearingSubscriber => {
          return settlementClearingSubscriber.valueDescription === node.data.subscriberClearing;
        });
      }

      if (typeof node.data.paymentType == 'string') {
        node.data.paymentType = this.referenceData['PAYMENT_TYPE'].find(paymentType => {
          return paymentType.valueDescription === node.data.paymentType;
        });
      }

      if (typeof node.data.issuingAgentClearing == 'string') {
        node.data.issuingAgentClearing = this.referenceData['SETTLEMENT_CLEARING_ISSUING_AGENT'].find(settlementClearingIssuingAgent => {
          return settlementClearingIssuingAgent.valueDescription === node.data.issuingAgentClearing;
        });
      }

      if (typeof node.data.clearingAccountNumber == 'string') {
        node.data.clearingAccountNumber = this.referenceData['CLEARING_ACCOUNT_NUMBER'].find(clearingAccountNumber => {
          return clearingAccountNumber.valueDescription === node.data.clearingAccountNumber;
        });
      }

      //format Date
      if (node.data.tradeDateAsString) {
        node.data.tradeDateAsString = this.formatDate(node.data.tradeDateAsString);
      }
      if (node.data.settlementDateAsString) {
        node.data.settlementDateAsString = this.formatDate(node.data.settlementDateAsString);
      }
      if (node.data.confirmationSgssGenerationDateAsString) {
        node.data.confirmationSgssGenerationDateAsString = this.formatDate(node.data.confirmationSgssGenerationDateAsString);
      }
      rowData.push(node.data);
    });

    this.updatedsettlemnts = rowData.filter(x => !x.id);

    if (this.updatedsettlemntsIds.length > 0 || this.updatedsettlemnts.length > 0) {
      const modifySettlements = rowData.filter(object => {
        if (object && object.id) {
          return this.updatedsettlemntsIds.includes(object.id);
        } else {
          return;
        }
      });
      this.updatedsettlemnts.push(...modifySettlements);
    }
    const toBeDeletedSettlements = this.settlemnts?.filter(x => x.toBeDeleted === true);
    if (toBeDeletedSettlements) {
      this.updatedsettlemnts.push(...toBeDeletedSettlements);
    }

    const modal = this.modalsService.openSaveSettlement();
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        return;
      }

      if (this.settlemnts && this.instrumentId) {
        this.instrumentService
          .createSettlement(this.tradingMethod, this.instrumentId, currentTrancheNumber, this.updatedsettlemnts)
          .pipe(takeUntil(this.shutdown$))
          .subscribe(result => {
            if (result?.createdIds === undefined) {
              return;
            } else {
              this.router.navigate(routeToSettlement(this.instrumentId, currentTrancheNumber, 'consult'));
              this.canModify = true;
              this.canSave = false;
              this.canModifyChange.emit(true);
              this.canSaveChange.emit(false);
            }
          });
      }
    });
  }

  formatDate(date: string): string {
    return moment(date).format(FORM_DATE_FORMAT);
  }

  ngOnDestroy(): void {
    this.gridApi.stopEditing(true);
    this.subscriptions.forEach(s => s.unsubscribe());
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}
