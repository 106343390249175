import { Component, OnDestroy, OnInit } from '@angular/core';
import { TrancheModel } from '@instruments/models/tranche.model';
import { TrancheDisplayService, TrancheFormService, InstrumentFormService, GlobalNoteFormService } from '@instruments/services';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { combineLatest, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { IpaTypeModelRules as R } from '@instruments/rules';
import { FormServiceInjectionToken, DisplayServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';

@Component({
  selector: 'app-tranche-creation',
  templateUrl: './tranche-creation.component.html',
  providers: [
    { provide: FormServiceInjectionToken, useExisting: TrancheFormService },
    { provide: DisplayServiceInjectionToken, useExisting: TrancheDisplayService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'instruments.instrument.forms.trancheCreation' }
  ]
})
export class TrancheCreationComponent extends BaseFormComponent<TrancheModel, TrancheFormService> implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public hideIsin = false;
  constructor(formService: TrancheFormService, private readonly instrumentFormService: InstrumentFormService, private readonly gnFormService: GlobalNoteFormService, private readonly displayService: TrancheDisplayService) {
    super(formService, 'instruments.instrument.placeholder.trancheCreation');
  }

  ngOnInit(): void {
    this.subscriptions.push(
      combineLatest([this.formService.valueChanges('issueDate'), this.displayService.shouldDisplay('interestCommencementDate')])
        .pipe(
          delay(5) //For some reason "subscribe arrives before the tap reset in handleValidators"
        )
        .subscribe(([issueDate, isVisible]) => {
          if (!isVisible) {
            this.formService.patch('interestCommencementDate', null);
            return;
          }
          const interestCommencementDate = this.formService.rawValue('interestCommencementDate');
          if (!interestCommencementDate && this.formService.isValid('issueDate')) {
            this.formService.patch('interestCommencementDate', issueDate || null);
          }
        })
    );
  }

  public get upto(): boolean {
    return !this.instrumentFormService.rawValue().upto ?? true;
  }
  public get issuePriceAppend(): string {
    if (R.isTradingMethodNominal(this.instrumentFormService.rawValue('tradingMethodIdentifier'))) {
      return `instruments.instrument.forms.trancheCreation.${this.fields.issuePrice}.nominal`;
    }
    return `instruments.instrument.forms.trancheCreation.${this.fields.issuePrice}.unit`;
  }

  public get issuePriceTranslateParams(): Record<string, unknown> {
    return {
      nominalCurrency: this.instrumentFormService.rawValue('nominalCurrency') || ''
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
