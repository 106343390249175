/**
 * issuer-ipa-events-api API
 * Manage events data
 *
 * The version of the OpenAPI document: 1.3.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IpaTypeDto } from './ipaTypeDto';


export interface RequestOfFundsDto { 
    rofRef?: number;
    quantity?: number;
    totalAmount?: number;
    paymentCurrency?: string;
    valueDateExpected?: string;
    isin?: string;
    instrumentName?: string;
    nominalAmount?: number;
    nominalCurrency?: string;
    rate?: number;
    periodStartDate?: string;
    periodEndDate?: string;
    poolFactor?: number;
    eventRef?: number;
    fundArrivalDate?: string;
    amountPerDenomination?: number;
    qty2?: number;
    unitPrice2?: number;
    qty3?: number;
    unitPrice3?: number;
    qty4?: number;
    unitPrice4?: number;
    correspondantBicCode?: string;
    correspondantName?: string;
    beneficialAccountName?: string;
    correspondantAccount?: string;
    cutOff?: string;
    sgdocId?: string;
    newPoolFactor?: number;
    previousPoolFactor?: number;
    nominal?: number;
    eventType?: string;
    eventTypeDescription?: string;
    dayCountFraction?: IpaTypeDto;
    dayCountFractionString?: string;
    transferMode?: IpaTypeDto;
    rofStatus?: IpaTypeDto;
    transportType?: string;
    noOfDays?: number;
    correspondantBank?: string;
    beneficiaryAccountNumber?: string;
    beneficiaryBank?: string;
    beneficiaryBankBic?: string;
    localCashCorrespondent?: string;
    localCashCorrespondentBic?: string;
    nonStpCutoffTime?: string;
    nonStpCutoffOffset?: number;
    stpCutOffTime?: string;
    stpCutOffOffSet?: number;
}

