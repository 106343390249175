import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { TypedDataModel } from '@shared/models';
import { TypedDataService } from '@shared/services/typed-data.service';

@Injectable({
  providedIn: 'root'
})
export class InstrumentCouponFrequencyResolve implements Resolve<TypedDataModel[]> {
  constructor(private readonly typeService: TypedDataService) {}

  resolve(): Observable<TypedDataModel[]> {
    return this.typeService.getTypeByTypedData('COUPON_FREQUENCY');
  }
}
