import { PositionCriteriaSearchDto } from '@apis/backend/instruments';

export interface PositionSearchCriteriaModel {
  ipaCode: string;
  isin: string;
  valueDate: string;
}
export interface PositionPagingWithCriteriaModel {
  pageNumber?: number;
  totalItems?: number;
  itemsByPage?: number;
}

export const toPositionCriteriaSearchDto = (model: PositionSearchCriteriaModel | null | undefined): PositionCriteriaSearchDto | undefined => {
  if (!model) {
    return undefined;
  }
  return {
    ipaNumber: model.ipaCode,
    isin: model.isin,
    valueDateAsString: model.valueDate
  };
};
