/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { notEmpty, removeLeadingAndTrailingZeros } from '@utils/utility-functions';

/*
USAGE EXAMPLE :
<app-multiple-date-picker formControlName="..." [placeholder]="'placeholder'">
</app-multiple-date-picker>
*/

@Component({
  selector: 'app-number',
  templateUrl: './number-input.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true
    }
  ]
})
export class NumberInputComponent implements ControlValueAccessor {
  @Input() innerClass: string | undefined;
  @Input() placeholder: string | undefined;
  @Input() prependLabel: string | undefined;
  @Input() appendLabel: string | undefined;
  @Input() addOnParams: Record<string, unknown> | undefined;
  @Input() formatThousandSeparator = true;

  private internalValue: string | null = null;
  public isReadOnly = false;
  public get value(): string | null {
    return this.internalValue;
  }

  public set value(val: string | null) {
    this.internalValue = val;
    if (!val) {
      this.onChange(null);
    } else {
      this.onChange(removeLeadingAndTrailingZeros(val));
    }
  }
  onChange!: OnChangeFn<string | null>;

  onTouched!: OnTouchFn;

  setDisabledState(isDisabled: boolean): void {
    this.isReadOnly = isDisabled;
  }
  registerOnChange(fn: OnChangeFn<string | null>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchFn): void {
    this.onTouched = fn;
  }
  writeValue(value: number | string | null | undefined): void {
    this.internalValue = this.formatNumber(value?.toString());
  }

  private addThousandSeparator(val: string, separator = ','): string {
    return (
      val
        .split('')
        .reverse()
        .join('')
        .match(/.{1,3}/g)
        ?.map(v => v.split('').reverse().join(''))
        .reverse()
        .join(separator) || ''
    );
  }

  private clearThousandSeparator(val: string | null | undefined, separator = ',') {
    if (!notEmpty(val)) {
      return null;
    }
    return val.split(separator).join('');
  }
  private formatNumber(value: string | null | undefined, thousandSeparator = ','): string | null {
    let clearedValue = this.clearThousandSeparator(value, thousandSeparator);
    clearedValue = removeLeadingAndTrailingZeros(clearedValue);
    if (!notEmpty(clearedValue)) {
      return null;
    }

    const realNumber = +clearedValue;
    if (isNaN(realNumber)) {
      return null;
    }

    const numberParts = clearedValue.split('.');
    let integerPart = numberParts[0];
    const decimalPart = numberParts[1];

    if (this.formatThousandSeparator) {
      integerPart = this.addThousandSeparator(integerPart, thousandSeparator);
    }

    return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
  }

  onBlur(event: FocusEvent): void {
    this.onTouched();
    const target: HTMLInputElement = event.target as HTMLInputElement;
    target.value = this.formatNumber(target.value) || '';
  }
  onFocus(event: FocusEvent): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    target.value = this.clearThousandSeparator(target.value) || '';
  }
}
