import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DialogModalComponent } from '@shared/modules/modals/components';

@Injectable()
export class ValuationModalsService {
  constructor(private readonly modalService: NgbModal) {}

  public openCancelModal(): NgbModalRef {
    const modal = this.modalService.open(DialogModalComponent);
    const dialog: DialogModalComponent = modal.componentInstance;
    dialog.dialogTitle = 'modals.titles.formCancel';
    dialog.dialogBody = 'modals.contents.formCancel';
    return modal;
  }

  public openSaveModal(): NgbModalRef {
    const modal = this.modalService.open(DialogModalComponent);
    const dialog: DialogModalComponent = modal.componentInstance;
    dialog.dialogTitle = 'modals.titles.saveValuationStatus';
    dialog.dialogBody = 'modals.contents.saveValuationStatus';
    return modal;
  }
}
