 
<div *ngIf="items$ | async as items">
  <app-ttd-items-selector
    [(ngModel)]="value"
    [data]="items"
    [singleSelect]="!multiple"
    [isReadOnly]="isReadOnly"
    [icon]="icon"
    (focusout)="onBlurEmit()"
    [labelProperties]="['name']"
    [withSummary]="withSummary"
    [placeHolder]="placeholder"
    key="leiCode"
    labelPath="name">
    <div readonlyTemplate>   
      <span *ngFor="let val of displayValue; let isLast = last">{{val.name}}<span class="mx-1"
        *ngIf="!isLast">;</span></span>
    </div>
  </app-ttd-items-selector>
</div>
 