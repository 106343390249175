import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private readonly toastService: ToastManagerService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((e: HttpErrorResponse) => this.handleError(e)));
  }

  public handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof Blob) {
      const promise = error.error.text();
      return from(promise).pipe(switchMap(res => throwError(new HttpErrorResponse({ error: JSON.parse(res), headers: error.headers, statusText: error.statusText, url: error.url || undefined }))));
    }

    this.toastService.handleError(error);
    return throwError(error);
  }
}

export const httpErrorInterceptorProvider = [{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }];
