/**
 * issuer-ipa-events-extended API
 * Manage eventss data
 *
 * The version of the OpenAPI document: 1.1.15
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IpaTypeDto } from './ipaTypeDto';


export interface InstrumentTrancheDto { 
    trancheNumber?: number;
    interestCommencementDateAsString?: string;
    fongibilityDateAsString?: string;
    finalTermReceived?: boolean;
    globalNoteReceived?: boolean;
    issueDateAsString?: string;
    issuePrice?: string;
    isinCodeTranche?: string;
    minimumTrading?: string;
    nominal?: string;
    outstandingNominal?: string;
    numberOfShares?: string;
    status?: IpaTypeDto;
}

