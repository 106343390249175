import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-programme-statut',
  template: '<span class="{{ color }}">{{ status| uppercase  }}</span>',
  styles: ['button { width: 100%; }']
})
export class ProgrammeStatusComponent {
  @Input() status: string | null | undefined = 'inactive';
  colors = { ACTIVE: 'success', INACTIVE: 'warning' };

  public get color(): string | null | undefined {
    return this.status ? `badge badge-discreet-${this.colors[this.status as 'ACTIVE' | 'INACTIVE']} badge-pill` : null;
  }
}
