import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';
import { LocationService } from '@shared/services/location.service';
import { OperationDetailColumnDefService, OperationService } from '@positions/services';
import 'ag-grid-enterprise';
import { OperationDetailFormService } from '@positions/services/operation-detail.form.service';
import { POSITION_CONSTANTS } from '@positions/configs/position-constants';
import { OperationDetailEditColumnDefService } from '@positions/services/operation-detail-edit-column-def-service';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { OperationModalsService } from '@positions/services/operation-modals.service';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { OperationDetailItemModel, OperationDetailsModel, OPERATION_ACTIONS } from '@positions/models';
import { PermissionService } from '@shared/services/permission.service';

@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  providers: [
    OperationModalsService,
    OperationDetailFormService,
    OperationDetailColumnDefService,
    OperationDetailEditColumnDefService,
    { provide: FormServiceInjectionToken, useExisting: OperationDetailFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'operations.forms.operationCreation' }
  ]
})
export class OperationComponent extends BaseFormComponent<OperationDetailsModel, OperationDetailFormService> implements OnInit, OnDestroy {
  initialized = POSITION_CONSTANTS.operations.statuses.INITIALIZED;
  validated = POSITION_CONSTANTS.operations.statuses.VAIDATED;
  inRepair = POSITION_CONSTANTS.operations.statuses.INREPAIR;
  protected readonly shutdown$ = new Subject<void>();
  operation?: OperationDetailsModel | null;
  clientOperationItems: OperationDetailItemModel[] | undefined;
  public canUpdateOperation: boolean | undefined;
  public canUpdateStatusOperation: boolean | undefined;
  constructor(
    private readonly toastService: ToastManagerService,
    private readonly modalsService: OperationModalsService,
    private readonly operationService: OperationService,
    private readonly route: ActivatedRoute,
    formService: OperationDetailFormService,
    private readonly router: Router,
    private readonly operationModalService: OperationModalsService,
    private readonly locationService: LocationService,
    public readonly permissionService: PermissionService
  ) {
    super(formService, 'operations.placeholder.operationCreation');
  }

  ngOnInit(): void {
    const id = +this.route.snapshot.params['id'];
    this.loadOperation(id);
    this.formService.setFormMode('consult');
    this.permissionService.canUpdateOperation$.pipe(takeUntil(this.shutdown$)).subscribe({
      next: (canUpdateOperation: boolean) => {
        this.canUpdateOperation = canUpdateOperation;
      }
    });
    this.permissionService.canUpdateStatusOperation$.pipe(takeUntil(this.shutdown$)).subscribe({
      next: (canUpdateStatusOperation: boolean) => {
        this.canUpdateStatusOperation = canUpdateStatusOperation;
      }
    });
  }
  private loadOperation(id: number): void {
    this.operationService
      .getOperationById(id)
      .pipe(takeUntil(this.shutdown$))
      .subscribe(operation => {
        this.operation = operation;
        this.clientOperationItems = operation?.clientOperations;
        if (this.operation) {
          this.formService.setInitialValue(this.operation);
        }
      });
  }
  public onUpdateStatusInstrumentClick(action: OPERATION_ACTIONS): void {
    const modal = this.operationModalService.openConfirmChangeStatusModal(action);
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        return;
      }
      if (this.operation?.id) {
        this.operationService
          .updateStatus(action, this.operation.id)
          .pipe(
            switchMap(() => {
              if (this.operation?.id) {
                return this.operationService.getOperationById(this.operation.id).pipe(first());
              }
              return of(null);
            }),
            takeUntil(this.shutdown$)
          )
          .subscribe(() => {
            if (this.operation?.id) {
              this.loadOperation(this.operation?.id);
            }
          });
      }
    });
  }
  goback(): void {
    if (this.locationService.navgationHistories.length >= 1) {
      this.router.navigateByUrl(this.locationService.getBackUrl());
    }
  }
  modify(): void {
    this.formService.setFormMode('edit');
  }
  cancel(): void {
    if (!this.operation) {
      return;
    }
    this.formService.setInitialValue(this.operation);
    this.formService.setFormMode('consult');
  }
  save(): void {
    if (this.isConsult) {
      return;
    }

    this.formService.updateValueAndValidity({ emitEvent: false });
    const operationDetail = this.formService.value();

    if (!operationDetail) {
      this.toastService.toastWarning('toasts.operations.invalid-form.title', 'toasts.operations.invalid-form.message');
      return;
    }

    const modal = this.modalsService.openSaveModal();
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        return;
      }
      this.operationService
        .save(operationDetail)
        .pipe(takeUntil(this.shutdown$))
        .subscribe(_ => {
          this.formService.setFormMode('consult');
          if (operationDetail?.id) {
            this.loadOperation(operationDetail.id);
          }
        });
    });
  }

  ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}
