const positionBaseRoute = '/position';
const operationBaseRoute = '/position/operation';

declare type NullableStringOrNumber = string | number | null | undefined;

export const routeToMouvements = (positionId?: NullableStringOrNumber) => {
  if (positionId) {
    return [`${positionBaseRoute}/${positionId}`];
  }

  return [positionBaseRoute];
};

export const routeToOperation = (mouvementId?: NullableStringOrNumber) => {
  if (mouvementId) {
    return [`${operationBaseRoute}/${mouvementId}`];
  }

  return [positionBaseRoute];
};
