<app-header (goBack)="showCancelModalOrGoBack()" [moveElementDown]="bodyContainer">
  <div headerBody>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <div class="mb-0 mt-2 d-flex align-items-center">
        <span *ngIf="isAddMode" class="me-4 display-4">New programme</span>
        <ng-container *ngIf="!isAddMode">
          <input *ngIf="!isConsult" class="me-4 display-4 programme-name" type="text" [formControl]="nameControl" />
          <span *ngIf="isConsult" class="me-4 display-4">{{ nameControl.value }}</span>
        </ng-container>

        <app-programme-statut [status]="programme.status"></app-programme-statut>
      </div>
      <div class="d-flex flex-row align-items-end">
        <button type="button" *ngIf="!isConsult" (click)="showCancelModalOrGoBack()" (keyup.enter)="showCancelModalOrGoBack()" class="btn btn-md btn-flat-primary" translate>common-buttons.cancel </button>
        <button *ngIf="!isConsult" type="button" class="btn btn-md btn-info ms-3" appFocusInvalidInput [formElementRef]="formElement" [form]="formGroup" (click)="onSubmit()" (keyup.enter)="onSubmit()" translate id="submitForm" translate>
          <em class="icon me-1">done</em>
          common-buttons.save
        </button>

        <button *ngIf="isConsult && canCreateInstrument" (click)="redirectToInstrumentCreation()" class="btn btn-flat-primary btn-icon-start"> <em class="icon">add</em> Instrument </button>
        <button *ngIf="isConsult && canEditProgramme" class="btn btn-flat-info ms-3 btn-icon-start" (click)="switchToEditMode()" (keyup.enter)="switchToEditMode()"> <em class="icon">mode_edit</em> Modify </button>
      </div>
    </div>
  </div>
</app-header>
<div class="bg-lvl2" #bodyContainer>
  <div class="container">
    <div class="row">
      <div class="pt-4 pb-4 mx-auto" [ngClass]="{ 'col-md-8': isAddMode, 'col-md-12': !isAddMode }">
        <div class="end-of-screen">
          <form #formElement (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()" role="form">
            <ng-container *ngIf="isAddMode">
              <app-programme-forms [isLoadingLeiInformation]="isLoadingLeiInformation" formMode="add"></app-programme-forms>
            </ng-container>
            <ng-container *ngIf="!isAddMode">
              <app-hide-and-show>
                <div left-side>
                  <div class="section-forms container g-0">
                    <div class="row">
                      <div class="col-md-6 mb-3">
                        <label class="text-secondary fw-medium" [focusTo]="icsdProgrammeNumberInput" translate>programmes.programme.forms.programmeCharacteristics.icsdProgrammeNumber</label>
                        <input #icsdProgrammeNumberInput maxlength="10" type="text" class="form-control-plaintext" id="icsdProgrammeNumber" [formControl]="icsdProgrammeNumberControl" />
                      </div>
                      <div class="col-md-6 mb-3">
                        <label class="text-secondary fw-medium" [focusTo]="internalProgrammeNumberInput" translate>programmes.programme.forms.programmeCharacteristics.internalProgrammeNumber</label>
                        <input #internalProgrammeNumberInput type="text" readonly class="form-control-plaintext" [value]="programmeId" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 mb-3">
                        <label class="text-secondary fw-medium" [focusTo]="leiSelect" translate>programmes.programme.forms.programmeCharacteristics.leiCode</label>
                        <app-lei-multiple-select
                          #leiSelect
                          (blurEmit)="getErrorMessages()"
                          [formControl]="leiFormControl"
                          [multiple]="false"
                          [closeOnSelect]="true"
                          [withDebounce]="true"
                          [hasError]="(leiFormControl.touched && leiFormControl.errors !== null) || false"
                          (programmeInformation)="onProgrammeInformationSelected($event)"
                          [placeholder]="'programmes.programme.forms.placeholder.programmeCharacteristics.leiCode' | translate"
                        >
                        </app-lei-multiple-select>
                        <div *ngIf="formErrors?.issuerLeiCode" class="invalid-fields">
                          <div
                            >{{
                              formErrors.leiCode
                                | translate
                                  : {
                                      field: 'programmes.programme.forms.programmeCharacteristics.leiCode' | translate
                                    }
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="mb-5 mt-4" />
                  <app-programme-forms [isLoadingLeiInformation]="isLoadingLeiInformation" [formMode]="isConsult ? 'consult' : 'edit'"> </app-programme-forms>
                </div>

                <div right-side>
                  <div class="tab-content" right-side>
                    <div id="documents" class="tab-pane" role="tabpanel" aria-labelledby="documents-tab" [ngClass]="{ active: activeTab === 'documents' }">
                      <app-documents-list *ngIf="idTech" [documents]="documents" [isLoading]="isLoadingDocument" [canDeleteFile]="isEditMode" [isEditMode]="isEditMode"></app-documents-list>
                    </div>
                  </div>
                </div>
              </app-hide-and-show>
            </ng-container>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
