import { ColDef } from 'ag-grid-community';
import { PositionsTotalGridModel } from '@events/models';
import { EventsPositionActionComponent } from './events-position-action/events-total-position-action/events-position-action.component';

export interface PositionsColDef extends ColDef {
  field: keyof (PositionsTotalGridModel & PositionColumnsAction);
}

interface PositionColumnsAction {
  actions: string;
}

export function formatNumber(params: { value: string }): string {
  if (params.value) {
    const numberPart = params.value.toString().split('.');
    const decimalPart = numberPart[1];
    const integerPart = numberPart[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
  }
  return params.value?.toString() === '0' ? '0' : '';
}

export function currencyFormatter(params: { value: string }, sign: string) {
  if (params.value) {
    const wholeNumber = formatNumber(params);
    return `${sign} ${wholeNumber}`;
  }
  return params.value?.toString() === '0' ? `${sign} 0` : '';
}

export const POSITIONS_TOTAL_COLUMN: PositionsColDef[] = [
  {
    field: 'blankColumn',
    width: 250
  },
  {
    field: 'totalPositionNominal',
    valueFormatter: formatNumber,
    width: 225
  },
  {
    field: 'totalQuantity',
    valueFormatter: formatNumber,
    width: 200
  },
  {
    field: 'totalAdjustedQuantity',
    editable: (params: any) => (params.data.isEditable ? true : false),
    valueFormatter: formatNumber,
    width: 200
  },
  {
    field: 'totalNonEligibleNominal',
    valueFormatter: formatNumber,
    width: 250
  },
  {
    field: 'totalEligibleNominal',
    valueFormatter: formatNumber,
    width: 225
  },
  {
    field: 'totalAmount',
    valueFormatter: params => currencyFormatter(params, params.data.currency),
    width: 225
  },
  {
    field: 'totalNbrUndelying',
    valueFormatter: formatNumber,
    width: 225
  },
  {
    field: 'actions',
    cellRendererFramework: EventsPositionActionComponent,
    cellRendererParams: {
      eventId: null
    },
    maxWidth: 130,
    pinned: 'right'
  }
];
