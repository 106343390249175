import { IpaTypeModel, PartyModel } from '@shared/models';
import { dateToString } from '@utils/utility-functions';
import { IpaTypeModelRules as R } from '@instruments/rules';
import { POSITION_CONSTANTS } from '@positions/configs/position-constants';
import { OperationCreateDto, OperationDataFetchDto } from '@apis/backend/instruments';

export interface OperationModel {
  id?: number;
  isin?: string;
  ipaCode?: number;
  seriesNumber?: string;
  currency?: string;
  valueDate?: string;
  icsd?: PartyModel;
  name?: string;
  direction?: string;
  value?: string;
  type?: string;
  operation?: string;
  account?: string;
  status?: string;
  instructionDate?: string;
  sendSwift?: boolean;
  SEMEReference?: string;
  operationType?: string;
}

export interface OperationCreationModel {
  id?: number;
  isin?: string;
  ipaCode?: number;
  seriesNumber?: string;
  currency?: string;
  valueDate?: Date;
  icsd?: PartyModel[];
  name?: string;
  direction?: string;
  value?: string;
  type?: IpaTypeModel;
  operation?: boolean;
  account?: string;
  status?: string;
  instructionDate?: Date;
  sendSwift?: boolean;
  SEMEReference?: string;
  operationType?: string;
  icsdAccountNumber?: string;
  ClearValue?: string;
  ClearOperation?: boolean;
  ClearSendSwift?: boolean;
  ClearSEMEReference?: string;
  ClearIcsdAccountNumber?: string;
}

export interface ClientOperationModel {
  id?: number;
  csdParty?: PartyModel;
  depositoryParty?: PartyModel;
  direction?: string;
  value?: string;
  accountNumber?: string;
  semeReference?: string;
  sendSwifts?: boolean;
}

export const toClientOperationModel = (dto: OperationCreationModel | null | undefined): [] | ClientOperationModel[] => {
  const clientOperation = [];
  if (dto) {
    const isEuroclear = dto.icsd?.find(icsd => {
      return R.isEUROCLEAR(icsd);
    });
    const isClearstream = dto.icsd?.find(icsd => {
      return R.isClEARSTREAM(icsd);
    });

    if (isClearstream) {
      const clearstream: ClientOperationModel = {
        csdParty: isClearstream,
        depositoryParty: POSITION_CONSTANTS.LEI_SG_LUXEMBOURG,
        direction: dto.ClearOperation ? 'C' : 'D',
        value: dto.ClearValue,
        accountNumber: dto.ClearIcsdAccountNumber,
        semeReference: dto.ClearSEMEReference,
        sendSwifts: dto.ClearSendSwift
      };
      clientOperation.push(clearstream);
    }

    if (isEuroclear) {
      const clearstream: ClientOperationModel = {
        csdParty: isEuroclear,
        depositoryParty: POSITION_CONSTANTS.LEI_SG_LUXEMBOURG,
        direction: dto.operation ? 'C' : 'D',
        value: dto.value,
        accountNumber: dto.icsdAccountNumber,
        semeReference: dto.SEMEReference,
        sendSwifts: dto.sendSwift
      };
      clientOperation.push(clearstream);
    }
  }
  return clientOperation;
};

export const toPositionCreationModel = (dto: OperationCreationModel | null | undefined): OperationCreateDto => {
  return {
    instrumentId: dto?.ipaCode,
    isin: dto?.isin,
    operationType: dto?.type,
    valueDateAsString: dateToString(dto?.valueDate) || undefined,
    instructionDateAsString: dateToString(dto?.instructionDate) || undefined,
    clientOperationDtos: toClientOperationModel(dto)
  };
};

export const toOperationModelFetchModel = (dto: OperationDataFetchDto | null | undefined): OperationModel => {
  return {
    id: dto?.idOperation,
    ipaCode: dto?.ipaNumber,
    isin: dto?.isinCode,
    seriesNumber: dto?.seriesNumber,
    currency: dto?.currency,
    operationType: dto?.operationType,
    status: dto?.operationStatus,
    valueDate: dto?.valueDate,
    icsd: dto?.csd,
    direction: dto?.direction,
    value: dto?.value
  };
};
