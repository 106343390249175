import { Component, Output, EventEmitter, Input } from '@angular/core';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { ECashPaymentsCriteriaSearchModel } from 'src/app/eCashPayments/models';
import { ECashPaymentsCriteriaSearchFormService } from 'src/app/eCashPayments/services';
import { TYPED_DATA_TYPE } from '@shared/models';

@Component({
  selector: 'app-e-cash-payments-list-filter',
  templateUrl: './e-cash-payments-list-filter.component.html'
})
export class ECashPaymentsListFilterComponent extends BaseFormComponent<ECashPaymentsCriteriaSearchModel, ECashPaymentsCriteriaSearchFormService> {
  @Output() exportCSV = new EventEmitter<boolean>();
  @Input() disableExportCsv = false;
  public isCollapsed = true;
  public ECASH_PAYMENT_STATUS: TYPED_DATA_TYPE = 'ECASH_PAYMENT_STATUS';
  public MX_MESSAGE_TYPE: TYPED_DATA_TYPE = 'MX_MESSAGE_TYPE';
  constructor(eCashPaymentsCriteriaSearchFormService: ECashPaymentsCriteriaSearchFormService) {
    super(eCashPaymentsCriteriaSearchFormService, 'eCashPayments.list.filters');
  }

  onDatesChanged(e: any, fromDate: any, toDate: any) {
    this.formService.patch(fromDate, e?.detail?.from?.formatted);
    this.formService.control(fromDate).markAsDirty();
    this.formService.patch(toDate, e?.detail?.to?.formatted);
    this.formService.control(toDate).markAsDirty();
  }

  get creationBeginDate(): number | null {
    const formDate = this.formService?.rawValue()?.creationFromDate;
    if (formDate) {
      return this.getDateTime(formDate);
    }
    return null;
  }
  get creationEndDate(): number | null {
    const formDate = this.formService?.rawValue()?.creationToDate;
    if (formDate) {
      return this.getDateTime(formDate);
    }
    return null;
  }

  private getDateTime(formDate: string) {
    const date = formDate.split('-');
    const newDate = new Date(+date[2], +date[1] - 1, +date[0]);
    return newDate.getTime();
  }

  public get canExport(): boolean {
    return Object.keys(this.formService.fields)
      .map((_, index, array) => {
        const item = array[index];
        return this.formService.control(item as keyof ECashPaymentsCriteriaSearchModel);
      })
      .some(q => q.value);
  }

  exportCsv() {
    this.exportCSV.emit(true);
  }
}
