import { EventDetails, UpdateScheduleDetails, EventDetailsSearchInput, UpdateStatusDTO, UpdateScheduleEvent, ScheduleEventDetailsResponse, RequestOfFundsSearchDto } from '@apis/backend/events';
import { InstrumentDataModel } from '.';
import { IpaTypeModel, PartyModel } from '@shared/models';
import { StatusUpdateUserInputDto, PartialPaymentInputDto } from '@apis/backend/payments';
import { EVENT_CONSTANTS } from '@events/configs/event-constants';
import * as moment from 'moment';

export interface EventDetailModel {
  ipa?: number;
  isin?: string;
  issuerName?: string;
  principalPayingAgent?: PartyModel;
  tradingMethodIdentifier?: string;
  commonDepositary?: PartyModel;
  commonServiceProvider?: PartyModel;
  listingAgent?: PartyModel;
  couponFrequency?: string;
  physicalForm?: string;
  clearingSystems?: PartyModel[];
  partialRedemption?: boolean;
  partialRedemptionType?: IpaTypeModel;
  instrumentName?: string;
  eventType?: string;
  eventId?: string;
  timingFundArrivalDate?: Date;
  transferMode?: string;
  notificationDate?: Date;
  reqFundDate?: Date;
  preAdvDate?: Date;
  valueDate?: Date;
  paymentDate?: Date;
  beginDate?: Date;
  endDate?: Date;
  recordDate?: Date;
  exDate?: Date;
  couponType?: string;
  dayCountFraction?: string;
  numOfDayOfThePeriod?: number;
  interestRate?: number;
  redemptionType?: IpaTypeModel;
  eventRedemptionPrice?: string;
  eventRedemptionUnit?: string;
  underlyingDenomQty?: number;
  underlyingIsin?: string;
  underlyingDescription?: string;
  strikePrice?: number;
  previousPoolFactor?: number;
  newPoolFactor?: number;
  partialRedemptionRate?: number;
  forexRate?: number;
  denominationAsString?: string;
  denominationCurrency?: string;
  amountPerDenomination?: number;
  paymentCurrency?: string;
  paymentCurrencys?: string[];
  multiplePmtCurrencies?: boolean;
  outStandingNominalAsString?: string;
  eventStatus?: IpaTypeModel;
  redeemableInSecurities?: boolean;
  interventionType?: IpaTypeModel;
  narrative?: string;
  instrumentStatus?: string;
  complianceRestriction?: boolean;
  paymentDefault?: boolean;
  eocAdjQty?: number;
  clsAdjQty?: number;
  totalAdjQty?: number;
  updateAdjustedQty?: boolean;
  eventValidatedBy?: string;
  eventValidatedAt?: string;
  eventSentforValidationBy?: string;
}

export type REDMType = 'underlyingIsin' | 'underlyingDescription' | 'underlyingDenomQty' | 'strikePrice';
export type INTRType = 'beginDate' | 'endDate' | 'interestRate' | 'numOfDayOfThePeriod' | 'underlyingIsin' | 'underlyingDescription' | 'underlyingDenomQty' | 'strikePrice';
export type DVCAType = 'previousPoolFactor' | 'newPoolFactor' | 'forexRate';
export type PREDType = 'previousPoolFactor';
export type AllEventType =
  | 'couponType'
  | 'dayCountFraction'
  | 'ipa'
  | 'isin'
  | 'listingAgent'
  | 'physicalForm'
  | 'commonDepositary'
  | 'commonServiceProvider'
  | 'principalPayingAgent'
  | 'issuerName'
  | 'instrumentName'
  | 'eventType'
  | 'eventId'
  | 'eventStatus'
  | 'numOfDayOfThePeriod'
  | 'denominationAsString'
  | 'denominationCurrency';

export const REDMEventFields: readonly REDMType[] = ['underlyingIsin', 'underlyingDescription', 'underlyingDenomQty', 'strikePrice'];
export const INTREventFields: readonly INTRType[] = ['beginDate', 'endDate', 'interestRate', 'numOfDayOfThePeriod', 'underlyingIsin', 'underlyingDescription', 'underlyingDenomQty', 'strikePrice'];
export const DVCAFields: readonly DVCAType[] = ['forexRate', 'newPoolFactor', 'previousPoolFactor'];
export const PREDField: readonly PREDType[] = ['previousPoolFactor'];
export const AllEventTypeFields: readonly AllEventType[] = [
  'couponType',
  'dayCountFraction',
  'ipa',
  'isin',
  'listingAgent',
  'physicalForm',
  'commonDepositary',
  'commonServiceProvider',
  'principalPayingAgent',
  'issuerName',
  'instrumentName',
  'eventType',
  'eventId',
  'numOfDayOfThePeriod',
  'denominationAsString',
  'denominationCurrency'
];

enum TransferMode {
  'e-Mail' = 2,
  'csv' = 3
}

export enum CouponFrequencies {
  'ANNUAL' = 1,
  'SEMIANNUAL' = 2,
  'FOURMONTHLY' = 3,
  'QUARTERLY' = 4,
  'BIMESTRIAL' = 6,
  'MONTHLY' = 12,
  'BIMONTHLY' = 24,
  'WEEKLY' = 52
}

export const toEventDetailsSearchDto = (id: number): EventDetailsSearchInput => {
  return {
    eventReferences: [id]
  };
};

export const toUpdateScheduleEventDto = (model: EventDetailModel | null | undefined): UpdateScheduleEvent | undefined => {
  if (!model) {
    return;
  }
  return {
    ipaCode: model.ipa,
    updateDetails: toUpdateScheduleDetailsEventDto(model)
  };
};

const toUpdateScheduleDetailsEventDto = (model: EventDetailModel): UpdateScheduleDetails | undefined => {
  return {
    actionType: UpdateScheduleDetails.ActionTypeEnum.UPDATE || undefined,
    eventReference: Number(model.eventId),
    valueDate: model.valueDate ? toFilterDateString(model.valueDate) : undefined,
    notificationDate: model.notificationDate ? toFilterDateString(model.notificationDate) : undefined,
    reqFundDate: model.reqFundDate ? toFilterDateString(model.reqFundDate) : undefined,
    preAdviceDate: model.preAdvDate ? toFilterDateString(model.preAdvDate) : undefined,
    beginDate: model.beginDate ? toFilterDateString(model.beginDate) : undefined,
    endDate: model.endDate ? toFilterDateString(model.endDate) : undefined,
    eventStatus: model.eventStatus ? toSetEventStatus(model.eventStatus) : undefined
  };
};

export const toUpdateEventDto = (model: EventDetailModel): EventDetails[] | undefined => {
  return [
    {
      transferMode: model.transferMode ? toSetTransferMode(model.transferMode) : undefined,
      previousPoolFactor: model.previousPoolFactor ? Number(model.previousPoolFactor) : undefined,
      newPoolFactor: model.newPoolFactor ? Number(model.newPoolFactor) : undefined,
      forexRate: model.forexRate ? Number(model.forexRate) : undefined,
      interestRate: model.interestRate ? Number(model.interestRate) : undefined,
      underlyingIsin: model.underlyingIsin,
      strikePrice: model.strikePrice ? Number(model.strikePrice) : undefined,
      amountPerDenomination: model.amountPerDenomination ? Number(model.amountPerDenomination) : undefined,
      redemptionType: model.redemptionType,
      eventRedemptionPrice: convertNumber(model.eventRedemptionPrice),
      eventRedemptionUnit: convertNumber(model.eventRedemptionUnit),
      underlyingDenomQty: model.underlyingDenomQty ? Number(model.underlyingDenomQty) : undefined,
      underlyingLabelIsin: model.underlyingDescription,
      eventReference: Number(model.eventId),
      paymentCurrency: model.paymentCurrency,
      timingFundArrivalDate: model.timingFundArrivalDate ? toFilterDateString(model.timingFundArrivalDate) : undefined,
      recordDate: model.recordDate ? toFilterDateString(model.recordDate) : undefined,
      exDate: model.exDate ? toFilterDateString(model.exDate) : undefined,
      paymentDate: model.paymentDate ? toFilterDateString(model.paymentDate) : undefined,
      multiplePmtCurrencies: model.multiplePmtCurrencies,
      partialRedemptionRate: model.partialRedemptionRate ? Number(model.partialRedemptionRate) : undefined,
      narrative: model.narrative,
      clsAdjQty: model.clsAdjQty,
      eocAdjQty: model.eocAdjQty,
      totalAdjQty: model.totalAdjQty,
      updateAdjustedQty: model.updateAdjustedQty
    }
  ];
};

export const convertNumber = (val: string | null | undefined): number | undefined => {
  if (val) {
    return Number(val.split(',').join(''));
  }
  return undefined;
};

export const toSetTransferMode = (description: string): IpaTypeModel => {
  const id = description === 'e-Mail' ? TransferMode['e-Mail'] : TransferMode['csv'];
  return {
    valueId: id,
    valueDescription: description
  };
};

export const toSetEventStatus = (status: IpaTypeModel): number | undefined => {
  const enableStatusUpdate = [
    EVENT_CONSTANTS.status.valuationInRepair,
    EVENT_CONSTANTS.status.notificationBlocked,
    EVENT_CONSTANTS.status.eventToBeInput,
    EVENT_CONSTANTS.status.fixingDateToBeReached,
    EVENT_CONSTANTS.status.preAdviseBlocked,
    EVENT_CONSTANTS.status.generatePreAdvise,
    EVENT_CONSTANTS.status.generateRequestOfFunds,
    EVENT_CONSTANTS.status.paymentBlocked,
    EVENT_CONSTANTS.status.paymentToBeInput,
    EVENT_CONSTANTS.status.paymentDone,
    EVENT_CONSTANTS.status.requestOfFundsBlocked,
    EVENT_CONSTANTS.status.requestOfFundsSent,
    EVENT_CONSTANTS.status.eventClosed,
    EVENT_CONSTANTS.status.eventValidated,
    EVENT_CONSTANTS.status.notificationSent,
    EVENT_CONSTANTS.status.preAdviseSent
  ];
  if (status.valueDescription && enableStatusUpdate.includes(status.valueDescription)) {
    return status.valueId;
  } else if (status.valueDescription === EVENT_CONSTANTS.status.eventToBeValidated) {
    return 7;
  }
  return undefined;
};

export const toEventCancelUpdateDto = (eventRef: number, eventStatus: number): UpdateStatusDTO[] => {
  return [
    {
      eventRef: eventRef,
      eventStatus: eventStatus
    }
  ];
};

export const toReleasePaymentUpdateDto = (eventRef: number, systemType: string): PartialPaymentInputDto => {
  return {
    eventRef: eventRef,
    cls: systemType === EVENT_CONSTANTS.clearingSystem.clearStream ? true : undefined,
    eoc: systemType === EVENT_CONSTANTS.clearingSystem.euroClear ? true : undefined
  };
};

export const toStausUpdateUserInput = (eventId: number): StatusUpdateUserInputDto => {
  return {
    id: eventId,
    typedData: 'EVENT_WORKFLOW_STATUS'
  };
};

export const toRofSearchDto = (eventId: number): RequestOfFundsSearchDto => {
  return {
    eventRefIds: [eventId]
  };
};

export const setEventDetails = (instrument: InstrumentDataModel | null, eventDetailResponse: ScheduleEventDetailsResponse | null, eventReference: number): EventDetailModel | null => {
  if (instrument === null || eventDetailResponse === null) {
    return null;
  }

  const scheduleData = eventDetailResponse.scheduleMap;
  const targetEventReference = scheduleData ? scheduleData[eventReference] : null;
  const eventDetail: EventDetailModel = {
    isin: instrument.isinReference ? instrument.isinReference : undefined,
    issuerName: instrument.issuer ? instrument.issuer.name : undefined,
    instrumentStatus: instrument.instrumentStatus ? instrument.instrumentStatus.valueDescription : undefined,
    paymentCurrencys: setPaymentCCY(targetEventReference?.eventType?.valueDescription, instrument),
    instrumentName: instrument?.name ? instrument.name : undefined,
    principalPayingAgent: instrument?.principalPayingAgent ? instrument.principalPayingAgent : undefined,
    commonDepositary: instrument?.commonDepositary ? instrument.commonDepositary : undefined,
    commonServiceProvider: instrument?.commonServiceProvider ? instrument.commonServiceProvider : undefined,
    listingAgent: instrument?.listingAgent ? instrument.listingAgent : undefined,
    physicalForm: instrument?.physicalForm ? instrument.physicalForm.valueDescription : undefined,
    redeemableInSecurities: instrument?.redeemableInSecurities,
    partialRedemption: instrument?.partialRedemption,
    partialRedemptionType: instrument?.partialRedemptionType ? instrument.partialRedemptionType : undefined,
    tradingMethodIdentifier: instrument?.tradingMethodIdentifier ? instrument.tradingMethodIdentifier.valueDescription : undefined,
    clearingSystems: instrument.clearingSystems || undefined,
    ipa: targetEventReference?.instrumentId || undefined,
    eventId: targetEventReference?.eventReference?.toString() || undefined,
    eventType: targetEventReference?.eventType?.valueDescription || undefined,
    eventStatus: targetEventReference?.status || undefined,
    couponFrequency: targetEventReference?.couponFrequency?.valueDescription || undefined,
    beginDate: stringToDateFormat(targetEventReference?.beginDate) || undefined,
    endDate: stringToDateFormat(targetEventReference?.endDate) || undefined,
    valueDate: stringToDateFormat(targetEventReference?.valueDate) || undefined,
    notificationDate: stringToDateFormat(targetEventReference?.notificationDate) || undefined,
    reqFundDate: stringToDateFormat(targetEventReference?.requestOfFundsDate) || undefined,
    preAdvDate: stringToDateFormat(targetEventReference?.preAdviceDate) || undefined,
    denominationCurrency: targetEventReference?.nominalCurrency || undefined,
    complianceRestriction: instrument.complianceRestriction,
    paymentDefault: instrument.paymentDefault
  };
  if (eventDetailResponse.eventMap) {
    setEventData(eventDetailResponse.eventMap, eventDetail, eventReference);
  }
  return eventDetail;
};

const setEventData = (eventData: any, eventDetail: EventDetailModel, eventReference: number): void => {
  eventDetail.transferMode = eventData[eventReference].transferMode?.valueDescription || undefined;
  eventDetail.interestRate = eventData[eventReference].interestRate;
  eventDetail.eventValidatedBy = eventData[eventReference].auditValidatedBy;
  eventDetail.eventSentforValidationBy = eventData[eventReference].auditSentForValidation;
  eventDetail.eventValidatedAt = stringToDateTimeFormat(eventData[eventReference].auditValidatedTimestamp);
  eventDetail.timingFundArrivalDate = stringToDateFormat(eventData[eventReference].timingFundArrivalDate) || undefined;
  eventDetail.recordDate = stringToDateFormat(eventData[eventReference].recordDate) || undefined;
  eventDetail.exDate = stringToDateFormat(eventData[eventReference].exDate) || undefined;
  eventDetail.paymentDate = stringToDateFormat(eventData[eventReference].paymentDate) || undefined;
  eventDetail.previousPoolFactor = eventData[eventReference].previousPoolFactor || undefined;
  eventDetail.newPoolFactor = eventData[eventReference].newPoolFactor || undefined;
  eventDetail.forexRate = eventData[eventReference].forexRate;
  eventDetail.couponType = eventData[eventReference].couponType?.valueDescription || undefined;
  eventDetail.dayCountFraction = eventData[eventReference].dayCountFraction?.valueDescription || undefined;
  eventDetail.redemptionType = eventData[eventReference].redemptionType || undefined;
  eventDetail.numOfDayOfThePeriod = eventData[eventReference].numOfDayOfThePeriod;
  eventDetail.denominationAsString = formatedNumber(eventData[eventReference].denominationAsString) || undefined;
  eventDetail.paymentCurrency = eventData[eventReference].paymentCurrency || undefined;
  eventDetail.outStandingNominalAsString = eventData[eventReference].outStandingNominalAsString || undefined;
  eventDetail.strikePrice = eventData[eventReference].strikePrice;
  eventDetail.eventRedemptionPrice = thousandSeperator(eventData[eventReference].eventRedemptionPrice);
  eventDetail.eventRedemptionUnit = thousandSeperator(eventData[eventReference].eventRedemptionUnit);
  eventDetail.underlyingDenomQty = eventData[eventReference].underlyingDenomQty;
  eventDetail.underlyingIsin = eventData[eventReference].underlyingIsin || undefined;
  eventDetail.underlyingDescription = eventData[eventReference].underlyingLabelIsin || undefined;
  eventDetail.multiplePmtCurrencies = eventData[eventReference].multiplePmtCurrencies || undefined;
  eventDetail.narrative = eventData[eventReference].narrative;
  eventDetail.partialRedemptionRate = eventData[eventReference].partialRedemptionRate;
  eventDetail.amountPerDenomination = eventData[eventReference].amountPerDenomination;
  eventDetail.eocAdjQty = eventData[eventReference].eocAdjQty;
  eventDetail.clsAdjQty = eventData[eventReference].clsAdjQty;
  eventDetail.totalAdjQty = eventData[eventReference].totalAdjQty;
};

const setPaymentCCY = (eventType: string | undefined, instrument: InstrumentDataModel | undefined): string[] | undefined => {
  let paymentCCY: string[] | undefined;
  switch (eventType) {
    case 'INTR':
      paymentCCY = instrument?.couponPaymentCurrencies ? instrument.couponPaymentCurrencies : undefined;
      break;
    case 'DVCA':
    case 'REDM':
    case 'MCAL':
      paymentCCY = instrument?.redemptionCurrencies ? instrument.redemptionCurrencies : undefined;
      break;
    case 'PRED':
    case 'PCAL':
      paymentCCY = instrument?.partialRedemptCurrencies ? instrument.partialRedemptCurrencies : undefined;
      break;
  }
  return paymentCCY;
};

export const toFilterDateString = (date: Date): string => {
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = date.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
};

export const thousandSeperator = (val: number | null): string | undefined => {
  if (val) {
    const numberParts = val.toString().split('.');
    const integerPart = numberParts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const decimalPart = numberParts[1];
    return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
  }
  return val?.toString();
};

export const formatedNumber = (val: string | null): string | undefined => {
  if (val) {
    const parsedValue = parseFloat(val);
    const numberParts = parsedValue.toString().split('.');
    const integerPart = numberParts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const decimalPart = numberParts[1];
    return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
  }
  return undefined;
};

const stringToDateFormat = (date: string | undefined): Date | undefined => {
  if (date === undefined || null) {
    return undefined;
  }
  const [year, month, day] = date.split('-');
  return new Date(+year, +month - 1, +day);
};

const stringToDateTimeFormat = (date: string | undefined): string | undefined => {
  if (date === undefined || null) {
    return undefined;
  }
  return moment(date).format('DD MMM YYYY hh:mm:ss a');
};
