import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../../shared.module';
import { ProgrammeInformationComponent } from './programme-information.component';

@NgModule({
  declarations: [ProgrammeInformationComponent],
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, TranslateModule],
  exports: [ProgrammeInformationComponent]
})
export class ProgrammeInformationModule {}
