<div [formGroup]="formGroup" class="container g-0">
  <div [formArrayName]="formArrayName" class="row gy-4 mb-3"
    *ngFor="let documentControl of uploadDocuments.controls; let i = index">
    <ng-container [formGroupName]="i">
      <div class="col-sm-1 d-flex justify-content-end align-items-end">
        <button class="btn btn-flat-light" [ngClass]="{ 'mx-4': !isEditMode }" (click)="removeDocument(i)">
          <em class="icon icon-md icon-red">delete</em>
        </button>
      </div>
      <div [class.col-sm-11]="compact" [class.col-sm-4]="!compact">
        <app-document-form-item [focus]="documentName" [label]="label(fields.documentName)" [control]="control(i, fields.documentName)" [required]="isRequired(fields.documentName)">
          <input class="form-control {{formControlSize}}" [formControlName]="fields.documentName" type="text"
            #documentName component />
        </app-document-form-item>
      </div>
      <div [class.col-sm-11]="compact" [class.offset-sm-1]="compact" [class.col-sm-4]="!compact">
        <app-document-form-item [focus]="model" [label]="label(fields.modelId)" [control]="control(i, fields.modelId)" [required]="isRequired(fields.modelId)">
          <select class="form-control  {{formControlSize}}" #model
            [formControlName]="fields.modelId" component>
            <option value="" disabled selected>Select your type of document</option>
            <option [value]="documentModel.id" *ngFor="let documentModel of documentModels">{{ documentModel.name }}
            </option>
          </select>
        </app-document-form-item>
      </div>
      <div [class.col-sm-11]="compact" [class.offset-sm-1]="compact" [class.col-sm-3]="!compact"
        *ngIf="haveModelSelected(i)">
        <app-document-form-item [focus]="confidentialityLevel" [label]="label(fields.confidentialityLevel)" [control]="control(i, fields.confidentialityLevel)" [required]="isRequired(fields.confidentialityLevel)">
          <select class="form-control  {{formControlSize}}" [formControlName]="fields.confidentialityLevel"
            #confidentialityLevel component>
            <option [value]="confidentialityLevel" *ngFor="let confidentialityLevel of confidentialityList(i)">
              {{ confidentialityLevel }}</option>
          </select>
        </app-document-form-item>
      </div>
    </ng-container>
  </div>
</div>
<div *ngIf="isEditMode && (multiple || nbFiles < 1)">
  <app-uploading-files [isMultiple]="multiple" (fileDropped)="onFilesDropped($event)"></app-uploading-files>
</div>
