/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, ElementRef, Input, ViewChild, Output, EventEmitter } from '@angular/core';

type Format = 'default' | 'large' | 'small' | 'compact';

@Component({
  selector: 'app-uploading-files',
  templateUrl: './uploading-files.component.html',
  styleUrls: ['./uploading-files.component.scss']
})
export class UploadingFilesComponent {
  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef | undefined;

  @Input() isMultiple = true;
  get accept(): string {
    if (this.allowedExtensions.length > 0) {
      return this.allowedExtensions.reduce((p, c, i) => {
        if (i === 0) {
          return p;
        }
        return `${p}, .${c}`;
      }, `.${this.allowedExtensions[0]}`);
    }
    return '';
  }
  @Input() allowedExtensions: string[] = [];
  @Input() format: Format = 'large';

  @Output() fileDropped = new EventEmitter<File[]>();

  /**
   * on file drop handler
   */
  onFileDropped(files: File[]): void {
    this.fileDropped.next(files);
  }

  /**
   * handle file from browsing
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fileBrowseHandler(event: Event): void {
    const files = (event.target as HTMLInputElement).files;
    if (files !== null) {
      const filesDropped = Array.from(files || []);
      this.fileDropped.next(filesDropped);
    }
  }
}
