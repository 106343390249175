/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive, OnInit } from '@angular/core';
import { PagingBaseModel, PagingCriteriaFormService } from '@shared/search-base';
import { AgGridColumnDefBaseService } from '@shared/services/aggrid-column-def-base-service';
import { ColDef, FirstDataRenderedEvent, GridOptions } from 'ag-grid-community';

@Directive()
// eslint-disable-next-line max-len, prettier/prettier
export abstract class ListBaseComponent<U extends PagingBaseModel, PagingFormService extends PagingCriteriaFormService<U>, ColumnConfService extends AgGridColumnDefBaseService> implements OnInit {
  public columnDefs: ColDef[] = [];
  public defaultColDef: ColDef | undefined;
  public gridOptions!: GridOptions;

  constructor(protected readonly columnDefService: ColumnConfService, protected readonly pagingFormService: PagingFormService, private readonly _itemsByPage: number) {}
  ngOnInit(): void {
    this.columnDefs = this.columnDefService.get();

    this.gridOptions = {
      onFirstDataRendered: this.onFirstDataRendered,
      onGridSizeChanged: () => {
        this.gridOptions.api?.sizeColumnsToFit();
      },
      onColumnVisible: () => {
        this.columnResize();
      },
      onToolPanelVisibleChanged: () => {
        this.columnResize();
      },
      pagination: false,
      suppressPaginationPanel: false,
      paginationPageSize: this._itemsByPage,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      suppressContextMenu: true,
      stopEditingWhenCellsLoseFocus: true
    };
    this.defaultColDef = {
      sortable: false,
      filter: false,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true }
    };
  }
  public sideBar: any = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        minWidth: 225,
        width: 225,
        maxWidth: 225,
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true
        }
      }
    ],
    defaultToolPanel: ''
  };
  onGridReady(params: any): void {
    const gridApi = params.api;
    gridApi.sizeColumnsToFit();
    gridApi.addGlobalListener(this.resizeColumn);
    gridApi.setDomLayout('autoHeight');
    gridApi.refreshHeader();
  }
  private readonly resizeColumn = (event: any) => {
    if (['gridSizeChanged', 'toolPanelVisibleChanged', 'columnVisible', 'rowDataChanged'].includes(event)) {
      this.columnResize();
    }
  };
  onFirstDataRendered(params: FirstDataRenderedEvent): void {
    params.api.sizeColumnsToFit();
  }
  private columnResize() {
    setTimeout(() => {
      this.gridOptions.api?.sizeColumnsToFit();
    });
  }
  onPageChanged(page: number): void {
    this.pagingFormService.patch('pageNumber', page);
  }

  get totalItems(): number {
    return this.pagingFormService.value()?.totalItems || 0;
  }
  get pageNumber(): number {
    return this.pagingFormService.value()?.pageNumber || 0;
  }
  get itemsByPage(): number {
    return this.pagingFormService.value()?.itemsByPage || 0;
  }
  public get currentPage(): number {
    return this.pagingFormService.rawValue().pageNumber ?? 1;
  }
}
