import { TypedDataModel, IpaTypeModel } from '@shared/models';

export interface ValuationCriteriaSearchModel {
  eventRef?: number;
  isin?: string;
  valuationRef?: number;
  valuationStatus?: TypedDataModel[] | IpaTypeModel[];
  eventTypes?: TypedDataModel[];
  valuationFromDate?: string;
  valuationToDate?: string;
  valueFromDate?: string;
  valueToDate?: string;
  receiptFromDate?: string;
  receiptToDate?: string;
  valuationVersion?: string;
  redemptionTypes?: TypedDataModel[];
  valuationRejectReasons?: TypedDataModel[];
  pageNumber?: number;
  itemsByPage?: number;
}
