import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Router } from '@angular/router';
import { routeToEcashPayments } from 'src/app/eCashPayments/routes';

@Component({
  selector: 'app-e-cash-payments-action',
  templateUrl: './e-cash-payments-action.component.html'
})
export class ECashPaymentsActionComponent {
  params: ICellRendererParams | undefined;
  constructor(private readonly router: Router) {}
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  goToEcashPaymentsDetail(): void {
    const selectedData = [this.params?.node.data];
    this.router.navigate(routeToEcashPayments(selectedData[0].id, 'consult'));
  }
}
