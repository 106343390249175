/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { EventsDataModel, InstrumentForms } from '@instruments/models';
import { FormService } from '@shared/services/forms/form.service';
import { CustomValidators } from '@shared/validators/custom-validator';
import { IpaTypeModelRules } from '@instruments/rules';

@Injectable()
export class EventsDataFormService extends FormService<EventsDataModel, InstrumentForms> {
  constructor(fb: UntypedFormBuilder) {
    super('eventData', fb, {
      issueDate: fb.control(null, [Validators.required, CustomValidators.ValidateDate]),
      interestCommencementDate: fb.control(null, [Validators.required, CustomValidators.ValidateDate]),
      maturityDate: fb.control(null, Validators.required),
      perpetual: fb.control(false, Validators.required),
      paymentDateCorrelated: fb.control(true, Validators.required),
      couponType: fb.control(null, Validators.required),
      firstCouponPayDate: fb.control(null, Validators.required),
      interestRate: fb.control(null, Validators.required),
      dayCountFraction: fb.control(null, Validators.required),
      secondCouponType: fb.control(
        null,
        CustomValidators.CheckCoupon(coupon => IpaTypeModelRules.isNot(coupon, 'ZERO-COUPON'))
      ),
      secondCouponPayDate: fb.control(null, Validators.required),
      secondInterestRate: fb.control(null, Validators.required),
      secondDayCountFraction: fb.control(null, Validators.required),
      couponFrequency: fb.control(null, Validators.required),
      couponPaymentCurrencies: fb.control([]),
      firstEndPeriodDate: fb.control(null, Validators.required),
      paymentDateOffset: fb.control(null, [Validators.required, CustomValidators.ValidateNumber(6, 0)]),
      paymentDateMgtMode: fb.control(null, Validators.required),
      fundsArrivalDelayInDay: fb.control(null),
      endDateAdjusted: fb.control(false, Validators.required),
      businessDayConvention: fb.control(null, Validators.required),
      recordDateCalculationMode: fb.control(null, Validators.required),
      recordDatesCalculationDates: fb.control([]),
      recordDateOffset: fb.control(1, Validators.required),
      recordDateReference: fb.control(null, Validators.required),
      calculationTime: fb.control(null, Validators.required),
      calculationOffset: fb.control(null, Validators.required),
      redemptionValuationDate: fb.control(null),
      redemptionCurrencies: fb.control([]),
      redemptionPrice: fb.control(null),
      redeemableInSecurities: fb.control(false, Validators.required),
      optionExerciseStyle: fb.control(null),
      partialRedemption: fb.control(false, Validators.required),
      partialRedemptionCorrelatedToCoupon: fb.control(false, Validators.required),
      partialRedemptionType: fb.control(null),
      partialRedemptCurrencies: fb.control([]),
      instrFinancialCurrencies: fb.control([]),
      instrFinancialPlaces: fb.control([]),
      convertible: fb.control(false, Validators.required),
      partlyPaid: fb.control(false, Validators.required),
      putOption: fb.control(false, Validators.required)
    });
  }
}
