/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

import { Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/*

USAGE EXAMPLE :

<app-custom-multiple-select [multiple]="true" [validators]="validators" [items]="items" [placeholder]="'placeholder'">
</app-custom-multiple-select>

*/

type Anything = any[] | any | undefined;

@Component({
  selector: 'app-custom-multiple-select',
  templateUrl: './custom-multiple-select.component.html',
  styleUrls: ['./custom-multiple-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomMultipleSelectComponent),
      multi: true
    }
  ]
})
export class CustomMultipleSelectComponent implements ControlValueAccessor, OnDestroy {
  @ViewChild('select', { static: false, read: ElementRef }) select!: ElementRef;

  @Input() multiple = false;
  @Input() closeOnSelect = false;
  @Input() withDebounce = false;
  @Input() isReadOnly = false;

  // the list of data in the dropdown can be any type possible
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() items: any[] | null = null;

  @Input() bindLabelProperty = '';
  @Input() keyProperty = 'bdrId';
  @Input() placeholder: string | null = null;
  @Input() hasError = false;
  @Input() withSummary = false;
  @Output() blurEmit: EventEmitter<void> = new EventEmitter();
  @Output() inputEmit: EventEmitter<string> = new EventEmitter();

  private _value: Anything = undefined;
  get value(): Anything {
    return this._value;
  }

  set value(value: Anything) {
    this._value = value || undefined;
    this.onChange(value);
  }

  @Input() focusId: string | null = null;

  private readonly unsubscribe$ = new Subject<void>();

  isErrorDisplayed = false;

  get displayValue(): string[] {
    if (!this.value) {
      return [];
    }
    if (!Array.isArray(this.value)) {
      return [this.value[this.bindLabelProperty]];
    }
    return this.value.map(q => q[this.bindLabelProperty]);
  }

  onReady(): void {
    if (this.withDebounce && this.select) {
      const underlyingInput = this.select.nativeElement.querySelector('input');
      fromEvent<InputEvent>(underlyingInput, 'input')
        .pipe(
          filter(e => e !== null && e !== undefined),
          debounceTime(1000),
          distinctUntilChanged(),
          tap((event: InputEvent) => {
            this.inputEmit.emit((event.target as any).value);
          })
        )
        .subscribe();
    }
  }

  onBlur(): void {
    this.onTouched();
    this.blurEmit.emit();
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any[]): void {
    this._value = value;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
