/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

export class ComponentUtils {
  static scrollToFirstInvalidControl(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    containerEl: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    querySelector: any,
    formElementRef?: HTMLFormElement
  ): void {
    const findFirstInvalidField: HTMLElement = (formElementRef as HTMLElement)?.querySelector(querySelector) || document.querySelector(querySelector);

    containerEl.scroll({
      top: this.getTopOffset(findFirstInvalidField),
      left: 0,
      behavior: 'smooth'
    });

    fromEvent(containerEl, 'scroll')
      .pipe(debounceTime(100), take(1))
      .subscribe(() => {
        if (findFirstInvalidField) {
          // focus & focusOut for trigger message in formular
          findFirstInvalidField.focus();
          findFirstInvalidField.blur();
          findFirstInvalidField.focus();
        }
      });
  }

  private static getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 250; // the value was 100, it was increased to match the fixed header
    const controlElTop = controlEl?.getBoundingClientRect()?.top;

    const absoluteControlElTop = controlElTop + window.scrollY;

    return absoluteControlElTop - labelOffset;
  }
}
