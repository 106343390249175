<app-text-icon-button
    (click)="onCreateOperationClick()"
    icon="add"
    [disabled]="disableNewOperation"
    color="primary"
    label="{{ 'positions.list.create' | translate }}"
    size="md"
    buttonType="btn-primary"
    [customClasses]="'btn-icon-start'">
</app-text-icon-button>
