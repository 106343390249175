import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, ValidatorFn } from '@angular/forms';
import { GlobalNoteModel, InstrumentForms } from '@instruments/models';
import { FormService } from '@shared/services/forms/form.service';
import { CustomValidators } from '@shared/validators/custom-validator';

@Injectable()
export class GlobalNoteFormService extends FormService<GlobalNoteModel, InstrumentForms> {
  constructor(fb: UntypedFormBuilder) {
    super('globalNote', fb, {
      globalNoteForm: fb.control(null),
      legalForm: fb.control(null, legalFormValidator('globalNoteClassic', 'legalForm')),
      physicalForm: fb.control(null),
      globalNoteClassic: fb.control(false, legalFormValidator('globalNoteClassic', 'legalForm')),
      regs144LinkedInstrument: fb.control(null, CustomValidators.Isin)
    });
  }
}
function legalFormValidator(globalNoteClassicField: keyof GlobalNoteModel, legalFormField: keyof GlobalNoteModel): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const globalNoteClassicControl = control?.parent?.get(globalNoteClassicField.toString());
    const globalNoteClassic = control?.parent?.get(globalNoteClassicField.toString())?.value || false;
    const legalFormControl = control?.parent?.get(legalFormField.toString());
    const legalForm = control?.parent?.get(legalFormField.toString())?.value?.valueDescription || null;

    if (!legalForm) {
      return null;
    }
    // eslint-disable-next-line max-len
    const invalid = (globalNoteClassic && (legalForm === 'NEW SAFEKEEPING STRUCTURE' || legalForm === 'NEW GLOBAL NOTE')) || (!globalNoteClassic && (legalForm === 'BEARER' || legalForm === 'REGISTERED'));

    if (globalNoteClassicControl && legalFormControl) {
      if (invalid) {
        const result = { incompatible: control.value };
        globalNoteClassicControl.setErrors({ incompatible: true });
        legalFormControl.setErrors({ incompatible: true });
        return result;
      }
      globalNoteClassicControl.setErrors(null);
      legalFormControl.setErrors(null);
    }
    return null;
  };
}
