<div class="section-forms" [formGroup]="formGroup">
    <div class="container-fluid g-0 mb-4">
        <div class="row">
            <div class="col">
                <div class="row mt-1 mb-3 gy-2">
                    <div class="col-md-3">
                        <div class="input-group input-group-md">
                            <div class="input-icon-start">
                                <em class="icon text-secondary">search</em>
                            </div>
                            <input #isin component type="search" placeholder="{{ placeholder(fields.isin)  | translate }}"
                                class="form-control form-control-md" [formControlName]="fields.isin">
                        </div>
                    </div>
            
                    <div class="col-md-3">
                        <div class="input-group input-group-md">
                            <div class="input-icon-start">
                                <em class="icon text-secondary">search</em>
                            </div>
                            <input #fundingRequestRef component type="search"
                                placeholder="{{ placeholder(fields.fundingRequestRef)  | translate }}"
                                class="form-control form-control-md" [formControlName]="fields.fundingRequestRef">
                        </div>
                    </div>
            
                    <div class="col-md-3">
                        <sg-daterangepicker #valueDate component
                            (dateChanged)="onDatesChanged($event,'valueFromDate','valueToDate')" [startDate]="valueBeginDate"
                            [endDate]="valueEndDate" startPlaceholder="{{'payment.list.filters.valueDate'|translate}}"
                            endPlaceholder="{{'payment.list.filters.valueDate'|translate}}">
                        </sg-daterangepicker>
                    </div>
            
                    <div class="col-md-2">
                        <app-valuation-type-multiselect #eventType component [multiple]="true" [formControlName]="fields.eventTypes"
                            placeholder="{{ placeholder(fields.eventTypes)  | translate }}" [closeOnSelect]="true" styleClass="form-control-md"
                            [type]="EVENT_TYPE" [defaultItems]=eventTypesDropDown [hasError]="hasError(fields.eventTypes)">
                        </app-valuation-type-multiselect>
                    </div>
                </div>
                <div class="row gy-2">
                    <div class="col-md-3">
                        <app-valuation-type-multiselect #paymentStatus component [multiple]="true"
                            [formControlName]="fields.paymentStatus"
                            placeholder="{{ placeholder(fields.paymentStatus)  | translate }}" [closeOnSelect]="true"
                            styleClass="form-control-md" [type]="PAYMENTS_CONFIRMATION_STATUS" [defaultItems]=paymentstatusDropDown
                            [hasError]="hasError(fields.paymentStatus)">
                        </app-valuation-type-multiselect>
                    </div>
            
                    <div class="col-md-3">
                        <div class="input-group input-group-md">
                            <div class="input-icon-start">
                                <em class="icon text-secondary">search</em>
                            </div>
                            <input #amount component type="search" pattern="[0-9]+"
                                placeholder="{{ placeholder(fields.minAmount)  | translate }}" class="form-control form-control-md"
                                (ngModelChange)="onMinAmountEntered($event)" [formControlName]="fields.minAmount">
                        </div>
                    </div>
            
                    <div class="col-md-3">
                        <div class="input-group input-group-md">
                            <div class="input-icon-start">
                                <em class="icon text-secondary">search</em>
                            </div>
                            <input #amount component type="search" pattern="[0-9]+"
                                placeholder="{{ placeholder(fields.maxAmount)  | translate }}" class="form-control form-control-md"
                                [formControlName]="fields.maxAmount">
                        </div>
                    </div>
            
                    <div class="col-md-2">
                        <app-currency-multiple-select [formControlName]="fields.currency"
                            [placeholder]="placeholder(fields.currency) | translate" #currency [multiple]="false" component>
                        </app-currency-multiple-select>
                    </div>
                </div>
            </div>
            <div class="col-md-1">
                <ng-content select="[searchTemplate]"></ng-content>
            </div>
        </div>
    </div>
    <div class="container-fluid g-0 mt-2 mb-4">
        <div class="row">
            <div class="col-md-10">
                <app-payment-list-active-filters></app-payment-list-active-filters>
            </div>
            <div class="col-md-2 d-flex justify-content-end align-items-end">
                <button class="btn btn-lg btn-flat-secondary btn-icon-start" [disabled]="disableExportCsv" (click)="exportCsv()" *ngIf="canExport" translate>
                    <em class="icon">save_alt</em> common-buttons.CSV-export
                </button>
            </div>
        </div>
    </div>
</div>
