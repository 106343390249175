/**
 * issuer-ipa-events-extended API
 * Manage eventss data
 *
 * The version of the OpenAPI document: 1.1.15
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represent a Create Payments Confirmation
 */
export interface CreatePaymentsConfirmation { 
    valueDate?: string;
    currency?: string;
    amount?: number;
    settlementMode?: string;
    orderingInstitution?: string;
    beneficiary?: string;
    backOffice?: string;
    isCancelled?: boolean;
    adviceNumber?: string;
    dealIdentifier?: string;
    markReference?: string;
    isin?: string;
    eventTypeValueId?: number;
    comments?: string;
    receiptDate?: string;
}

