import { ErrorHandler, Injectable } from '@angular/core';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
class GlobalErrorHandler implements ErrorHandler {
  constructor(private readonly toastService: ToastManagerService) {}

  handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      // In local environment errors are handled in GlobalErrorHandler (here)
      // In dev environement errors are not handled in GlobalErrorHandler
      // Technical debt: understand why
      // Small fix for now : ignore HttpErrorResponse here, send error to the toaster from HttpErrorInterceptor
      return;
    }
    this.toastService.handleError(error);
    console.error(error);
  }
}

export const globalErrorHandlerProvider = [{ provide: ErrorHandler, useClass: GlobalErrorHandler }];
