<div dropdown container="body" placement="bottom right">
  <i id="button-dropdown" dropdownToggle class="icon icon-md text-secondary" aria-controls="dropdown-actions">
    more_vert
  </i>
  <ul id="dropdown-actions" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-dropdown">
    <li role="menuitem" *ngFor="let action of actions">
      <a class="dropdown-item" [routerLink]="[action.url, action.optionalParams]" [queryParams]="action.queryParams" target="_blank" translate> {{action.title}} </a>
    </li>
  </ul>
</div>
