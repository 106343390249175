/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Information will be using for update status of Object (Tranche, Increase, Instrument
 */
export interface UpdateStatusRequestDto { 
    /**
     * IPA code of instrument
     */
    ipaCode?: string;
    /**
     * ISIN of instrument
     */
    isin?: string;
    /**
     * Tranche number
     */
    trancheNumber?: string;
    /**
     * Increase number
     */
    increaseNumber?: string;
    /**
     * Issue date of tranche
     */
    issueDateAsString?: string;
    /**
     * Certified value for tefraD
     */
    certifiedNominal?: string | null;
    /**
     * Partially certified for tefraD
     */
    partiallyCertified?: boolean | null;
}

