import { InstrumentListingDataCreateDto } from '@apis/backend/instruments';
import { IpaTypeModel, toIpaTypeDto, toIpaTypeModel } from '@shared/models';

export interface ListingModel {
  readonly listingCountry: IpaTypeModel | null;
  readonly listingPlace: IpaTypeModel | null;
  readonly publiclySold: boolean;
}

export const toListingDto = (model: ListingModel | null | undefined): InstrumentListingDataCreateDto | undefined => {
  if (!model) {
    return undefined;
  }

  return {
    listingCountry: toIpaTypeDto(model.listingCountry),
    listingPlace: toIpaTypeDto(model.listingPlace),
    publiclySold: model.publiclySold
  };
};

export const toListingModel = (dto?: InstrumentListingDataCreateDto | null | undefined): ListingModel => {
  return {
    listingCountry: toIpaTypeModel(dto?.listingCountry),
    listingPlace: toIpaTypeModel(dto?.listingPlace),
    publiclySold: dto?.publiclySold || false
  };
};
