import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OperationDisplayService } from '@positions/services/display/operation.display.service';
import { PartyModel, ROLES_TYPE, TYPE_FILTER_TYPE } from '@shared/models';
import { PermissionService } from '@shared/services/permission.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PositionService, OperationService } from '@positions/services';

@Component({
  selector: 'app-position-shell',
  templateUrl: './position-shell.component.html',
  styleUrls: ['./position-shell.component.scss'],
  providers: [OperationDisplayService]
})
export class PositionShellComponent implements OnInit, OnDestroy {
  public canCreateOperation: boolean | undefined;
  public disableNewOperation = false;
  private readonly shutdown$ = new Subject<void>();
  private parties: [TYPE_FILTER_TYPE, ROLES_TYPE, PartyModel[]][] = [];
  clearings: PartyModel[] = [];

  constructor(public readonly permissionService: PermissionService, public readonly operationService: OperationService, public readonly positionService: PositionService, private readonly activatedRoute: ActivatedRoute) {
    this.positionService.disableNewOperation.subscribe(data => {
      this.disableNewOperation = data;
    });
    this.operationService.disableNewOperation.subscribe(data => {
      this.disableNewOperation = data;
    });
  }

  ngOnInit(): void {
    this.parties = this.activatedRoute.snapshot.data['parties'] as [TYPE_FILTER_TYPE, ROLES_TYPE, PartyModel[]][];
    this.clearings = this.getParties('OPERATION', 'CLEARING_SYSTEM');
    this.permissionService.canCreateOperation$.pipe(takeUntil(this.shutdown$)).subscribe({
      next: (canCreateOperation: boolean) => {
        this.canCreateOperation = canCreateOperation;
      }
    });
  }
  private getParties(filter: TYPE_FILTER_TYPE, role: ROLES_TYPE): PartyModel[] {
    return (this.parties?.find(q => q[0] === filter && q[1] === role) ?? [filter, role, []])[2] ?? [];
  }

  ngOnDestroy(): void {
    this.shutdown$.next();
  }
}
