import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private previousUrl = '';
  private currentUrl = '';
  public navgationHistories: string[] = [];

  constructor(private readonly router: Router) {
    router.events
      .pipe(
        filter(evt => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe(([previous, current]: any) => {
        this.previousUrl = previous.urlAfterRedirects;
        this.currentUrl = current.urlAfterRedirects;
        if (this.navgationHistories.length < 0) {
          this.navgationHistories.push(this.router.url);
        }
        if (this.previousUrl.includes('add') && this.currentUrl.includes('add')) {
          return;
        } else if (this.previousUrl.includes('edit') && this.currentUrl.includes('consult')) {
          this.navgationHistories = this.navgationHistories.slice(0, -1);
        } else {
          this.navgationHistories.push(this.previousUrl);
        }
      });
  }

  public getPreviousUrl(): string {
    return this.previousUrl;
  }

  public getCurrentUrl(): string {
    return this.currentUrl;
  }

  getBackUrl(): string {
    return this.navgationHistories[this.navgationHistories.length - 1];
  }
}
