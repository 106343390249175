import { InstrumentModel } from './instrument.model';
import { InstrumentTrancheCreateDto, InstrumentTrancheDto, InstrumentTrancheUpdateDto } from '@apis/backend/instruments';
import { dateToString, stringToDate } from '@utils/utility-functions';
import { toIpaTypeModel, IpaTypeModel } from '@shared/models';

type TrancheType = 'interestCommencementDate' | 'finalTermReceived' | 'issueDate' | 'issuePrice' | 'minimumTrading' | 'nominal' | 'numberOfShares' | 'isinReference' | 'denomination' | 'outstandingNominal';

export const TrancheFields: readonly TrancheType[] = ['interestCommencementDate', 'finalTermReceived', 'issueDate', 'issuePrice', 'minimumTrading', 'nominal', 'numberOfShares', 'isinReference', 'denomination', 'outstandingNominal'];

export type TrancheModel = Pick<InstrumentModel, TrancheType> & {
  readonly trancheNumber: number;
  readonly status: IpaTypeModel | null;
  outstandingNumberOfShares: string | null;
  trancheLastUpdateDateTimeAsString?: string | null;
};

export const toTrancheModel = (dto: InstrumentTrancheDto | null | undefined): TrancheModel | null => {
  if (!dto) {
    return null;
  }

  return {
    trancheNumber: dto.trancheNumber || 0,
    isinReference: dto.isinCodeTranche || null,
    interestCommencementDate: stringToDate(dto.interestCommencementDateAsString),
    finalTermReceived: dto.finalTermReceived || false,
    issueDate: stringToDate(dto.issueDateAsString),
    issuePrice: dto.issuePrice || null,
    minimumTrading: dto.minimumTrading || null,
    nominal: dto.nominal || null,
    numberOfShares: dto.numberOfShares || null,
    denomination: null,
    outstandingNominal: dto.outstandingNominal || null,
    outstandingNumberOfShares: dto.outstandingNominal || null,
    status: toIpaTypeModel(dto.status),
    trancheLastUpdateDateTimeAsString: dto.lastUpdateDateTimeAsString || null
  };
};

export const toCreateTrancheDto = (model: TrancheModel | null | undefined): InstrumentTrancheCreateDto | undefined => {
  if (!model) {
    return undefined;
  }

  return {
    interestCommencementDateAsString: dateToString(model.interestCommencementDate) || undefined,
    finalTermReceived: model.finalTermReceived || false,
    issueDateAsString: dateToString(model.issueDate) || undefined,
    issuePrice: model.issuePrice || undefined,
    minimumTrading: model.minimumTrading || undefined,
    nominal: model.nominal || undefined,
    numberOfShares: model.numberOfShares || undefined,
    isinCodeTranche: model.isinReference || undefined
  };
};

export const toUpdateTrancheDto = (instrument: Nullable<InstrumentModel>, trancheNumber: number): InstrumentTrancheUpdateDto | undefined => {
  if (!instrument || !instrument.id) {
    return undefined;
  }
  return {
    interestCommencementDateAsString: dateToString(instrument.interestCommencementDate) || undefined,
    finalTermReceived: instrument.finalTermReceived || false,
    issueDateAsString: dateToString(instrument.issueDate) || undefined,
    issuePrice: instrument.issuePrice || undefined,
    minimumTrading: instrument.minimumTrading || undefined,
    nominal: instrument.nominal || undefined,
    numberOfShares: instrument.numberOfShares || undefined,
    isinCodeTranche: instrument.isinReference || undefined,
    outstandingNominal: instrument.outstandingNominal || undefined,
    lastUpdateDateTimeAsString: instrument.tranches?.find(q => q.trancheNumber === trancheNumber)?.trancheLastUpdateDateTimeAsString ?? undefined
  };
};
