import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { PoolFactorGridModel } from '@events/models';
import { MOMENT_DATE_FORMAT } from '@shared/pipes/date-format/common';
import * as moment from 'moment';

export interface PoolFactorColDef extends ColDef {
  field: keyof (PoolFactorGridModel & PoolFactorColumnsAction);
}

interface PoolFactorColumnsAction {
  actions: string;
}

export const POOL_FACTOR_COLUMN: PoolFactorColDef[] = [
  {
    field: 'creationDate',
    valueFormatter: (q: ValueFormatterParams) => {
      if (!q.data.creationDate) {
        return '';
      }
      const momentDate = moment(q.data.creationDate);
      return momentDate.format(MOMENT_DATE_FORMAT);
    },
    width: 90,
    resizable: true
  },
  {
    field: 'currentPf',
    width: 90,
    resizable: true
  },
  {
    field: 'newPf',
    width: 90,
    resizable: true
  },
  {
    field: 'eventId',
    width: 95,
    resizable: true
  },
  {
    field: 'partialRedmType',
    width: 130,
    resizable: true
  },
  {
    field: 'comments',
    width: 160,
    resizable: true
  },
  {
    field: 'actions',
    maxWidth: 95,
    pinned: 'right'
  }
];
