<app-sgs-modal #authErrorModal content-class="shadow-max border border-danger" (sgsModalHidden)="authErrorSeen()">
  <div class="modal-header">
    <h3 class="modal-title text-danger" translate>global-errors.session-lost-title</h3>
    <button (click)="authErrorSeen()" type="button" class="close icon">close</button>
  </div>
  <div class="modal-body">
    <div translate>global-errors.session-lost-text</div>
  </div>
  <div class="modal-footer">
    <button (click)="authErrorSeen()" type="button" class="btn btn-lg btn-danger" translate>common-buttons.close</button>
  </div>
</app-sgs-modal>

<!-- U+2B6E: CLOCKWISE TRIANGLE-HEADED OPEN CIRCLE ARROW -->
<div [class.loader-hidden]="!showSpinner" class=" ms-4">
  <div class="loader-bg bg-white"></div>
  <div class="loader-overlay">
    <div class="blinker blinker-xl" role="status" translate>
    </div>
  </div>
</div>
