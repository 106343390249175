import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { YesNoButtonsComponent } from './yes-no-buttons.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [YesNoButtonsComponent],
  exports: [YesNoButtonsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class YesNoButtonsModule {}
