import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-events-position-action',
  templateUrl: './events-position-action.component.html'
})
export class EventsPositionActionComponent {
  params: any | undefined;
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
}
