import { Component, OnInit } from '@angular/core';
import { ValuationsFilter } from '@valuation/models';
import { ValuationCriteriaSearchFormService, ValuationFilterSelectorService } from '@valuation/services';

@Component({
  selector: 'app-valuation-filter-selector',
  templateUrl: './valuation-filter-selector.component.html'
})
export class ValuationFilterSelectorComponent implements OnInit {
  public filters: ValuationsFilter[] = [];
  public selected: ValuationsFilter | undefined;
  constructor(private readonly valuationCriteriaSearchFormService: ValuationCriteriaSearchFormService, public valuationFilterSelectorService: ValuationFilterSelectorService) {}

  ngOnInit(): void {
    this.valuationFilterSelectorService.loadFilters('valuations-filters');
  }

  onChange(event: ValuationsFilter): void {
    this.valuationCriteriaSearchFormService.patch(event.value);
  }

  select(filter: ValuationsFilter): void {
    this.selected = filter;
    this.valuationCriteriaSearchFormService.patch(filter.value);
  }
}
