/* eslint-disable @typescript-eslint/no-empty-function */
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { PartyService } from '@shared/services/party.service';
import { PartyModel, ROLES_TYPE, TYPE_FILTER_TYPE } from '@shared/models';
import { SelectableParty } from '@shared/models/selectable-party.model';

@Component({
  selector: 'app-party-multiple-check',
  templateUrl: './party-multiple-check.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PartyMultipleCheckComponent),
      multi: true
    }
  ]
})
export class PartyMultipleCheckComponent implements ControlValueAccessor, OnInit {
  @Input() multiple = false;

  @Input() classes = '';
  @Input() withDebounce = false;
  @Input() type: ROLES_TYPE | undefined = undefined;
  @Input() filter: TYPE_FILTER_TYPE | undefined = undefined;
  @Input() placeholder: string | null = null;

  @Input() hasError = false;

  public items$!: Observable<PartyModel[]>;
  public focusId: string | null = null;
  selectables: SelectableParty[] = [];
  constructor(private readonly typeService: PartyService) {}
  private sortPartyModel(a: PartyModel, b: PartyModel) {
    const nameA = a.name || '';
    const nameB = b.name || '';
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  ngOnInit(): void {
    if (this.type && this.filter) {
      this.typeService
        .getPartiesByRoles(this.type, this.filter)
        .pipe(
          map((r: PartyModel[]) =>
            [...r].sort(this.sortPartyModel).map((party: PartyModel) => {
              return { ...party, checked: false };
            })
          )
        )
        .subscribe(r => {
          this.selectables = r;
        });
    }
  }
  get value(): PartyModel | PartyModel[] | undefined {
    return this.selectables.filter(q => q.checked);
  }
  set value(value: PartyModel | PartyModel[] | undefined) {
    this.onChange(value);
  }
  get displayValue(): PartyModel[] {
    if (!this.value) {
      return [];
    }

    return Array.isArray(this.value) ? this.value : [this.value];
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  writeValue(value: PartyModel[]): void {
    if (!value) {
      return;
    }
    this.selectables.forEach(selectable => {
      const item = value.find(q => q.name === selectable.name);
      selectable.checked = !!item;
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onCheckBoxChange(item: SelectableParty): void {
    const selectable = this.selectables.find(q => q.name === item.name);
    if (selectable) {
      selectable.checked = item.checked;
      this.onChange(this.selectables.filter(item => item.checked));
    }
  }
}
