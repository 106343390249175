/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.58
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SettlementStatusHistoryDto { 
    settlementId?: number;
    creationDateAsString?: string;
    status?: string;
    settlementDateHistory?: string;
}

