import { InjectionToken } from '@angular/core';

const AllConfidentialityLevels = ['C1', 'C2'] as const;
export type ConfidentialityLevel = typeof AllConfidentialityLevels[number];
export const isConfidentialityLevel = (data: string | null | undefined): data is ConfidentialityLevel => {
  if (!data) {
    return false;
  }
  return AllConfidentialityLevels.includes(data as any);
};
export const allConfidentialityLevels: ConfidentialityLevel[] = Array.from(AllConfidentialityLevels);

export type DocumentType = 'instrument' | 'programme' | 'confirmationReports' | '';
export type IdTech = string | number | null | undefined;
export const DocumentTypeInjectionToken = new InjectionToken<DocumentType>('DOCUMENT_TYPE');
