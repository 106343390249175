/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InstrumentCriteriaSearchDto } from './instrumentCriteriaSearchDto';


/**
 * Parameters for paged instruments
 */
export interface PagingRequestDtoWithCriteriaDto { 
    /**
     * Requested page number.
     */
    pageNumber?: number;
    /**
     * Number of items per page.
     */
    itemsByPage?: number;
    criteria?: InstrumentCriteriaSearchDto;
}

