import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SgsEnvPropertiesService } from 'sgs-angular-components';
import { environment } from 'src/environments/environment';

import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { HttpFeedbackService } from '../../services/httpFeedback.service';
import { PermissionService } from '@shared/services/permission.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements AfterViewInit, OnDestroy {
  @ViewChild('sgwtAccountCenter', { static: false })
  sgwtAccountCenter: ElementRef | undefined;

  langAvailable: string = environment.languages;
  showAuthError = false;
  showSpinner = false;

  private allowModal = false;
  authType = this.env.auth_type;
  environment = this.env.environment;

  // https://stackoverflow.com/a/41177163
  private readonly ngUnsubscribe = new Subject<void>();
  private isListeningToReadyEvent = false;

  constructor(public readonly permissionService: PermissionService, public readonly translate: TranslateService, public readonly env: SgsEnvPropertiesService, private readonly httpFeedback: HttpFeedbackService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  private initialLanguage(): string {
    const langRegex = new RegExp(`^(?:${environment.languages.toLowerCase().replace(/,/g, '|')})`);
    const langMatch = langRegex.exec(this.translate.getBrowserLang());
    return langMatch == null ? 'EN' : langMatch[0].toUpperCase();
  }

  ngAfterViewInit(): void {
    this.sgwtAccountCenter?.nativeElement.addEventListener('sgwt-account-center--language-changed', this.onLanguageChanged);
    setTimeout(() => this.updateWidgetWhenReady(), 0);
  }

  private updateWidgetWhenReady(): void {
    this.isListeningToReadyEvent = this.sgwtAccountCenter?.nativeElement.changeLanguage === undefined;
    if (this.isListeningToReadyEvent) {
      // Case #1: the widget is not yet initialized so we wait for the ready event…
      this.sgwtAccountCenter?.nativeElement.addEventListener('sgwt-account-center--ready', this.onReady);
    } else {
      // Case #2: the widget is already initialized so apply the change immediately.
      this.onReady();
    }
  }

  ngOnDestroy(): void {
    this.sgwtAccountCenter?.nativeElement.removeEventListener('sgwt-account-center--language-changed', this.onLanguageChanged);
    if (this.isListeningToReadyEvent) {
      this.sgwtAccountCenter?.nativeElement.removeEventListener('sgwt-account-center--ready', this.onReady);
    }
    this.sgwtAccountCenter?.nativeElement.removeEventListener('sgwt-account-center--sign-in-request', this.onLogin);
    this.sgwtAccountCenter?.nativeElement.removeEventListener('sgwt-account-center--sign-out', this.onLogout);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onReady = (): void => {
    this.sgwtAccountCenter?.nativeElement.changeLanguage(this.initialLanguage());
    this.httpFeedback.activeRequestsCount.pipe(takeUntil(this.ngUnsubscribe)).subscribe(count => {
      this.showSpinner = count > 0;
    });
    this.httpFeedback.authenticatiorError.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      if (this.allowModal) {
        this.showAuthError = true;
        this.allowModal = false;
      }
    });
    this.sgwtAccountCenter?.nativeElement.addEventListener('sgwt-account-center--sign-in-request', this.onLogin);
    this.sgwtAccountCenter?.nativeElement.addEventListener('sgwt-account-center--sign-out', this.onLogout);
  };

  onLogin = (): void => window.location.assign(this.env.auth_url);

  onLogout = (): void => {
    this.allowModal = false;
    if (this.env.logout_action === 'auth') {
      window.location.assign(this.env.auth_url);
    } else {
      window.close();
    }
  };

  onLanguageChanged = (evt: Event): void => {
    this.translate.use((evt as CustomEvent).detail.language.toLowerCase());
  };

  public authErrorSeen(): void {
    this.showAuthError = false;
  }
}
