/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IpaTypeCreateDto } from './ipaTypeCreateDto';


export interface SettlementPerformDto { 
    id?: number;
    subscriberName?: string;
    subscriberClearing?: IpaTypeCreateDto;
    subscriberAccountNumber?: string;
    paymentType?: IpaTypeCreateDto;
    amount?: string;
    issuePrice?: string;
    settlementCurrency?: string;
    netAmount?: string;
    tradeDateAsString?: string;
    settlementDateAsString?: string;
    issuingAgentClearing?: IpaTypeCreateDto;
    clearingAccountNumber?: IpaTypeCreateDto;
    confirmationStatus?: IpaTypeCreateDto;
    confirmationSgssGenerationDateAsString?: string;
    comments?: string;
    userValidation?: string;
    timestampValidation?: string;
    toBeDeleted?: boolean;
}

