import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererParams } from 'ag-grid-community';
import { Forms } from '@shared/utils';

@Component({
  selector: 'app-programme-action',
  templateUrl: './programme-action.component.html'
})
export class ProgammeActionComponent {
  params: ICellRendererParams | undefined;

  constructor(private readonly router: Router) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  goToDetail(): void {
    this.router.navigate([`/programme/${Forms.PageConsult}/${this.params?.data.id}`]);
  }
}
