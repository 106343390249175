<div class="section-forms" [formGroup]="formGroup">
  <div class="row">
    <div class="col-md-7">
      <span translate>events.positions.form.tradingMethod</span>
      <span> - {{tradingMethod}}</span>
    </div>
    <div class="col-md-3" [hidden]='isExchCertEvent'>
      <app-event-type-multiselect #interventionType component [formControlName]="fields.interventionType"
        [multiple]="false" [closeOnSelect]="true" [type]="INTERVENTION_TYPE" (onchange)="interventionTypeChanged()"
        [defaultItems]=availableInterventionTypes [hasError]="hasError(fields.interventionType)"
        styleClass="form-control-lg" placeholder="{{ placeholder(fields.interventionType)  | translate }}">
      </app-event-type-multiselect>
    </div>
    <div class="col-md-1 me-5" style="position:absolute;right:2rem;">
      <div class="btn-group" dropdown>
        <button id="button-basic" dropdownToggle type="button" class="dropdown-toggle dropdown-button"
          aria-controls="dropdown-basic" translate>
          events.list.actions.quick-actions
        </button>

        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li role="menuitem">
            <a class="dropdown-item" *ngIf="eventPositionInput?.enableGeneratePreadvise"
              (click)="generatePreadvise()" translate> events.list.actions.generatePreadvise</a>
          </li>
          <li role="menuitem">
            <a class="dropdown-item" *ngIf="eventPositionInput?.isInternationalISIN" (click)="getNotificationList()" translate>events.list.actions.displayNotificationList</a>
          </li>
          <li role="menuitem">
            <a class="dropdown-item" *ngIf="enableEcashPaymentsList" (click)="getEcashPayments()" translate>events.list.actions.eCashPayments</a>
          </li>
          <li role="menuitem">
            <a class="dropdown-item" *ngIf="enablePoolFactorHistoryList" (click)="getPoolFactor()" translate>events.list.actions.poolFactor</a>
          </li>
          <li role="menuitem">
            <a class="dropdown-item"  *ngIf="enableRofDownload" (click)="downloadRequestOfFunds()" translate> events.list.actions.downloadRof</a>
            <a class="dropdown-item" *ngIf="enableGenerateRequestOfFunds"  (click)="updateStatusToGenerateReqOfFunds()" translate> events.list.actions.generateReqOfFunds</a>            
          </li>          
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="mt-3">
<app-positions-list [positions]="positions" [positionsTotal]="positionsTotal" [eventId]="eventId"
  [eventPositionInput]="eventPositionInput" (positionUpdated)="setUpdatePositionData()"></app-positions-list>
</div>
