
 <app-custom-multiple-select
[(ngModel)]="value"
[items]="items"
[multiple]="multiple"
[closeOnSelect]="closeOnSelect"
[withDebounce]="withDebounce"
[placeholder]="placeholder"
[isReadOnly]="isReadOnly"
[hasError]="hasError"
(blurEmit)="onBlurEmit()"
(inputEmit)="onInputEmit($event)"
[focusId]="focusId"
bindLabelProperty="valueDescription"
keyProperty="valueId">
</app-custom-multiple-select>
