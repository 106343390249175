import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CharacteristicModel, InstrumentForms } from '@instruments/models';
import { FormService } from '@shared/services/forms/form.service';
import { CustomValidators } from '@shared/validators/custom-validator';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProgrammeInformation } from '@shared/models';
import { PartyService } from '@shared/services/party.service';
import { first } from 'rxjs/operators';

@Injectable()
export class CharacteristicFormService extends FormService<CharacteristicModel, InstrumentForms> {
  private readonly programmeInformationSubject = new BehaviorSubject<ProgrammeInformation | null>(null);
  public loadingLeiInformationSubject = new BehaviorSubject<boolean>(false);
  constructor(fb: UntypedFormBuilder, private readonly partyService: PartyService) {
    super('characteristic', fb, {
      issuer: fb.control(null),
      issuerLeiCode: fb.control(null, Validators.required),
      clearingSystems: fb.control(null),
      icsdProgrammeNumber: fb.control(null),
      internalProgrammeNumber: fb.control(null),
      commonCode: fb.control(null, Validators.pattern(/^[0-9]{0,9}$/)),
      mainTrancheIsin: fb.control(null),
      isinReference: fb.control(null, CustomValidators.Isin),
      instrumentType: fb.control(null, Validators.required),
      seriesNumber: fb.control(null, CustomValidators.ValidateNumber(255, 0)),
      classNumber: fb.control(null),
      compartmentNumber: fb.control(null, CustomValidators.ValidateNumber(30, 0)),
      name: fb.control(null),
      finalTermReceived: fb.control(null),
      legalDeskIssuer: fb.control(null),
      complianceRestriction: fb.control(null),
      paymentDefault: fb.control(null),
      eligibility871m: fb.control(null),
      eligibilitySrd2: fb.control(null),
      labelCategory: fb.control(null),
      upto: fb.control(null)
    });

    this.lockFieldsPermanently('icsdProgrammeNumber');
  }

  public setProgrammeInformation(programmeInformation: ProgrammeInformation | null, standalone = true, tracheNumber = 1): void {
    this.programmeInformationSubject.next(programmeInformation);
    if (!programmeInformation) {
      this.patch('issuer', null);
      this.patch('issuerLeiCode', null);
    } else {
      this.patch('issuerLeiCode', programmeInformation.leiCode || null);
      this.patch('issuer', {
        leiCode: programmeInformation.leiCode,
        bdrId: programmeInformation.bdrId,
        name: programmeInformation.fullLegalName
      });
    }
    this.unlockFields('issuerLeiCode');

    if (programmeInformation && (!standalone || this.formMode === 'consult' || tracheNumber > 1)) {
      this.lockFields('issuerLeiCode');
    }
  }

  public loadLeiInformation(leiCode: string, standalone = true): void {
    this.loadingLeiInformationSubject.next(true);
    this.partyService
      .getIssuerBdrDataFromLeiCode(leiCode)
      .pipe(first())
      .subscribe(progInfo => {
        this.setProgrammeInformation(progInfo, standalone);
        this.loadingLeiInformationSubject.next(false);
      });
  }

  public get loadingLeiInformation$(): Observable<boolean> {
    return this.loadingLeiInformationSubject.asObservable();
  }

  public get programmeInformation$(): Observable<ProgrammeInformation | null> {
    return this.programmeInformationSubject.asObservable();
  }
}
