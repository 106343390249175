/**
 * issuer-ipa-events-api API
 * Manage events data
 *
 * The version of the OpenAPI document: 1.3.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreateEventPositionEntitlementTo } from './createEventPositionEntitlementTo';
import { ValuationDTO } from './valuationDTO';


export interface ValuationUpdateResponseDTO { 
    valuations?: Array<ValuationDTO>;
    statusTrack?: { [key: string]: string; };
    positionDtoLists?: Array<CreateEventPositionEntitlementTo>;
}

