import { Component } from '@angular/core';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { PaymentDetailModel } from '../../models';
import { PaymentDetailFormService } from '../../services';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';
import { Router, ActivatedRoute } from '@angular/router';
import { routeToPayment } from '../../routes';
import { combineLatest, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { Forms } from '@shared/utils';
import { PAYMENT_CONSTANTS } from '../../configs/payment-constants';
import { LocationService } from '@shared/services/location.service';

@Component({
  selector: 'app-payment-detail',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.scss'],
  providers: [
    { provide: FormServiceInjectionToken, useExisting: PaymentDetailFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'payment.form' }
  ]
})
export class PaymentDetailComponent extends BaseFormComponent<PaymentDetailModel, PaymentDetailFormService> {
  public shutdown$ = new Subject<void>();
  public paymentId: number | undefined | null;
  public allPaymentStatus = PAYMENT_CONSTANTS.payment.paymentStatus;
  private currentPaymentStatus: string | undefined | null;
  constructor(private readonly paymentDetailFormService: PaymentDetailFormService, private readonly router: Router, private readonly activatedRoute: ActivatedRoute, private readonly locationService: LocationService) {
    super(paymentDetailFormService, 'payment.detail');
  }
  ngOnInit(): void {
    combineLatest([this.activatedRoute.data.pipe(startWith(...[])), this.activatedRoute.params.pipe(startWith(...[]))])
      .pipe(takeUntil(this.shutdown$))
      .subscribe(([routeData, routeParams]) => {
        const paymentData = routeData['payment'] || null;
        const pageType = Forms.isFormMode(routeParams.pageType) ? routeParams.pageType : 'consult';
        this.paymentDetailFormService.setFormMode(pageType);
        if (pageType === 'consult') {
          this.paymentDetailFormService.setInitialValue(paymentData);
        }
        this.paymentId = this.paymentDetailFormService.value()?.paymentReference;
        this.paymentDetailFormService.paymentLockFields();
        this.currentPaymentStatus = this.paymentDetailFormService.value('paymentStatus');
        if (this.currentPaymentStatus && !PAYMENT_CONSTANTS.payment.paymentStatusDropDown.includes(this.currentPaymentStatus)) {
          this.paymentDetailFormService.lockPaymentStatusField();
        } else {
          this.paymentDetailFormService.unlockPaymentStatusField();
        }
      });
  }

  public get isEditMode(): boolean {
    return this.paymentDetailFormService.formMode === 'edit';
  }

  public get paymentStatusValue() {
    return this.currentPaymentStatus ? PAYMENT_CONSTANTS.payment.regularized.includes(this.currentPaymentStatus) : false;
  }

  public get paymentInRepairpaymentStatusValue() {
    return this.currentPaymentStatus === PAYMENT_CONSTANTS.payment.paymentInRepair;
  }

  public get paymentRejectReasonValue() {
    const paymentRejectReason = this.formService.rawValue('paymentRejectReason');
    const rejectionReasonValues = paymentRejectReason ? rejectReasonValues(paymentRejectReason) : undefined;
    if (rejectionReasonValues) {
      return rejectionReasonValues.find(value => PAYMENT_CONSTANTS.payment.awaitNewPayments.includes(value)) ? true : false;
    }
    return undefined;
  }

  onEditClick() {
    this.router.navigate(routeToPayment(this.paymentId, 'edit'));
  }

  onBackClick() {
    if (this.locationService.navgationHistories.length >= 1) {
      this.router.navigateByUrl(this.locationService.getBackUrl());
      return;
    }
    this.router.navigate(routeToPayment());
  }
}

const rejectReasonValues = (rejectReasons: string): string[] => {
  const paymentRejectReasons = rejectReasons.split('- ')[1];
  const rejectReasonsArray: string[] = [];
  paymentRejectReasons.split(', ').forEach(x => {
    rejectReasonsArray.push(x);
  });
  return rejectReasonsArray;
};
