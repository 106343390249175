import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog-modal',
  templateUrl: './dialog-modal.component.html'
})
export class DialogModalComponent {
  @Input() dialogTitle = 'modals.titles.default';
  @Input() dialogBody = 'modals.contents.default';

  @Input() confirmButton = 'modals.buttons.confirm';
  @Input() cancelButton = 'modals.buttons.cancel';

  @Input() showConfirmButton = true;

  public ClosedReason: DialogResult = 'closed';
  public CancelReason: DialogResult = 'cancel';
  public ConfirmReason: DialogResult = 'confirm';
  constructor(private readonly activeModal: NgbActiveModal) {}

  public onClose(): void {
    this.activeModal.dismiss('closed');
    this.activeModal.close('closed');
  }
  public onBtnClick(reason: DialogResult): void {
    this.activeModal.close(reason);
  }
}
