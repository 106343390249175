<div class="row">
  <div class="col-md-4">
    <div>
      <ag-grid-angular style="height: 20rem;" class="ag-theme-sg-bootstrap-condensed"
        [rowData]="swiftMessageValues" [columnDefs]="columnDefs" rowSelection="single"
        (selectionChanged)="onSelectionChanged($event)">
      </ag-grid-angular>
    </div>
  </div>
  <div *ngIf='swiftMessage' class="col-md-4">
    <div class="row swifts-header">
      <div class="col-md-11 swifts-header">
        <h6 translate>events.swifts.headers.swiftMessages</h6>
      </div>
      <div class="col-md-1 swifts-header">
        <button (click)="copySwiftMessage()" class="btn btn-md btn-link"><i class="icon icon-md">content_copy</i></button>
      </div>
    </div>
    <div class="scroll" [innerHTML]="swiftMessage"></div>
  </div>
  <div *ngIf="errorSwiftMessage" class="col-md-4">
    <div class="row swifts-header">
      <div class="col-md-11 swifts-header" >
        <h6 translate>events.swifts.headers.swiftErrorMessage</h6>
      </div>
      <div class="col-md-1 swifts-header">
        <button (click)="copySwiftErrorMessage()" class="btn btn-md btn-link"><i class="icon icon-md">content_copy</i></button>
      </div>
    </div>
    <div class="scroll" [innerHTML]="errorSwiftMessage">
    </div>
  </div>
</div>