import { PagingProgrammeDtoWithCriteriaDto, ProgrammeCriteriaSearchDto } from '@apis/backend/instruments';
import { PartyModel } from '@shared/models';
import { PagingBaseModel } from '@shared/search-base';
import { notEmpty } from '@utils/utility-functions';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProgrammePagingCriteriaModel extends PagingBaseModel {}
export type ProgrammeSearchModel = ProgrammeCriteriaSearchModel & ProgrammePagingCriteriaModel;
export type ProgrammeSearchQueryModel = ProgrammeCriteriaSearchModel | Pick<ProgrammePagingCriteriaModel, 'pageNumber'>;
export type ProgrammeSearchArrayType = [ProgrammeCriteriaSearchModel, ProgrammePagingCriteriaModel];
export interface ProgrammeCriteriaSearchModel {
  issuerNames?: PartyModel[];
  programmeName?: string;
  icsd?: string;
  status?: string;
}

export const toProgrammePagingRequestWithCriteriaDto = (model: ProgrammeSearchModel | null | undefined): PagingProgrammeDtoWithCriteriaDto | undefined => {
  if (!model) {
    return undefined;
  }

  return {
    pageNumber: model.pageNumber,
    itemsByPage: model.itemsByPage,
    criteria: toProgrammeCriteriaSearchDto(model)
  };
};
export const toProgrammeCriteriaSearchDto = (model: ProgrammeCriteriaSearchModel | null | undefined): ProgrammeCriteriaSearchDto | undefined => {
  if (!model) {
    return undefined;
  }

  return {
    icsd: model.icsd,
    issuerNames: model.issuerNames ? model.issuerNames.map(q => q.name).filter(notEmpty) : [],
    programmeName: model.programmeName,
    status: model.status
  };
};
