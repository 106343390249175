import { AfterViewInit, Component } from '@angular/core';
import { PiwikTrackingService } from './core/analytics/piwik-tracking.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  constructor(private readonly piwikTrackingService: PiwikTrackingService) {}
  ngAfterViewInit(): void {
    this.piwikTrackingService.configurePiwikTracker();
  }
}
