import { Pipe, PipeTransform } from '@angular/core';
import { MOMENT_DATE_FORMAT } from './common';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      const momentDate = moment(value);
      return momentDate.format(MOMENT_DATE_FORMAT);
    }
    return undefined;
  }
}
