<div class="form-group">
    <div class="btn-group w-100" ngbDropdown #drop="ngbDropdown" *ngIf="paymentFilterSelectorService.$currentFilters | async; let filters">
        <button class="btn btn-default btn-md dropdown-toggle" type="button"  [disabled]="!filters.length" ngbDropdownAnchor (focus)="drop.open()"
            aria-haspopup="true" aria-expanded="false" ngbDropdownAnchor>
            {{'payment.list.selector.select-filter'|translate}}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
            <a *ngFor="let filter of filters" ngbDropdownItem
                [ngClass]="{'active':  filter.key=== selected?.key}" (click)="select(filter)">{{filter.key}}</a>
        </div>
    </div>
</div>
