import { ColDef } from 'ag-grid-community';
import { PositionsGridModel } from '@events/models';
import { EventsClearStreamActionComponent } from './events-position-action/events-clearsystem-action/events-clear-stream-action.component';
import { EVENT_CONSTANTS } from '@events/configs/event-constants';

export interface PositionsColDef extends ColDef {
  field: keyof (PositionsGridModel & PositionColumnsAction);
}

interface PositionColumnsAction {
  actions: string;
}

export function formatNumber(params: { value: string }): string {
  if (params.value) {
    const numberPart = params.value.toString().split('.');
    const decimalPart = numberPart[1];
    const integerPart = numberPart[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
  }
  return params.value?.toString() === '0' ? '0' : '';
}

export function currencyFormatter(params: { value: string }, sign: string) {
  if (params.value) {
    const wholeNumber = formatNumber(params);
    return `${sign} ${wholeNumber}`;
  }
  return params.value?.toString() === '0' ? `${sign} 0` : '';
}

export const POSITIONS_COLUMN: PositionsColDef[] = [
  {
    field: 'systemType',
    width: 250
  },
  {
    field: 'positionNominal',
    valueFormatter: formatNumber,
    width: 225
  },
  {
    field: 'quantity',
    valueFormatter: formatNumber,
    width: 200
  },
  {
    field: 'adjustedQuantity',
    editable: (params: any) => (params.data.isEditable && !(params.data.eventType === EVENT_CONSTANTS.eventTypes.EXCH || params.data.eventType === EVENT_CONSTANTS.eventTypes.CERT) ? true : false),
    valueFormatter: formatNumber,
    width: 200
  },
  {
    field: 'nonEligibleNominal',
    editable: (params: any) => params.data.isEditable && ((params.data.eventType === EVENT_CONSTANTS.eventTypes.EXCH && params.data.eventStatus === 25) || (params.data.eventType === EVENT_CONSTANTS.eventTypes.CERT && params.data.eventStatus === 31)),
    valueFormatter: formatNumber,
    width: 250
  },
  {
    field: 'eligibleNominal',
    editable: (params: any) => params.data.isEditable && ((params.data.eventType === EVENT_CONSTANTS.eventTypes.EXCH && params.data.eventStatus === 25) || (params.data.eventType === EVENT_CONSTANTS.eventTypes.CERT && params.data.eventStatus === 31)),
    valueFormatter: (params: any) => (params.data.eventType === EVENT_CONSTANTS.eventTypes.EXCH || params.data.eventType === EVENT_CONSTANTS.eventTypes.CERT ? params.value : formatNumber(params)),
    width: 225
  },
  {
    field: 'amount',
    valueFormatter: params => currencyFormatter(params, params.data.currency),
    width: 225
  },
  {
    field: 'nbrUnderlying',
    valueFormatter: formatNumber,
    width: 225
  },
  {
    field: 'actions',
    cellRendererFramework: EventsClearStreamActionComponent,
    cellRendererParams: {
      eventId: null
    },
    maxWidth: 130,
    pinned: 'right'
  }
];
