/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-namespace */

import { IpaTypeModel } from '@shared/models/ipa-type.model';
import { BooleanRules as B } from '@instruments/rules/boolean.rules';
import { POSITION_CONSTANTS } from '@positions/configs/position-constants';

export namespace PositionModelRules {
  export const isMarkDown = (operationType: IpaTypeModel | null | undefined) => B.isDefined(operationType) && operationType.valueDescription === POSITION_CONSTANTS.operations.types.MARKDOWN;
  export const isNetting = (operationType: IpaTypeModel | null | undefined) => B.isDefined(operationType) && operationType.valueDescription === POSITION_CONSTANTS.operations.types.NETTING;
}
