import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FormService } from '@shared/services/forms/form.service';
import { CustomValidators } from '@shared/validators/custom-validator';
import { EventDetailModel, REDMEventFields, INTREventFields, AllEventTypeFields, DVCAFields, PREDField } from '@events/models';
import { EVENT_CONSTANTS } from '@events/configs/event-constants';

@Injectable({
  providedIn: 'root'
})
export class EventDetailFormService extends FormService<EventDetailModel> {
  constructor(fb: UntypedFormBuilder) {
    super('eventDetail', fb, {
      ipa: fb.control(null),
      isin: fb.control(null),
      issuerName: fb.control(null),
      principalPayingAgent: fb.control(null),
      tradingMethodIdentifier: fb.control(null),
      commonDepositary: fb.control(null),
      physicalForm: fb.control(null),
      commonServiceProvider: fb.control(null),
      listingAgent: fb.control(null),
      couponFrequency: fb.control(null),
      clearingSystems: fb.control(null),
      partialRedemption: fb.control(null),
      partialRedemptionType: fb.control(null),
      instrumentName: fb.control(null),
      eventType: fb.control(null),
      eventId: fb.control(null),
      timingFundArrivalDate: fb.control(null, [Validators.required, CustomValidators.ValidateDate]),
      transferMode: fb.control(null, [Validators.required]),
      notificationDate: fb.control(null, [CustomValidators.ValidateDate]),
      reqFundDate: fb.control(null, [CustomValidators.ValidateDate]),
      preAdvDate: fb.control(null, [CustomValidators.ValidateDate]),
      valueDate: fb.control(null, [Validators.required, CustomValidators.ValidateDate]),
      paymentDate: fb.control(null, [CustomValidators.ValidateDate]),
      beginDate: fb.control(null, [CustomValidators.ValidateDate]),
      endDate: fb.control(null, [CustomValidators.ValidateDate]),
      recordDate: fb.control(null, [CustomValidators.ValidateDate]),
      exDate: fb.control(null, [CustomValidators.ValidateDate]),
      couponType: fb.control(null),
      dayCountFraction: fb.control(null),
      numOfDayOfThePeriod: fb.control(null),
      interestRate: fb.control(null),
      redemptionType: fb.control(null),
      eventRedemptionPrice: fb.control(null),
      eventRedemptionUnit: fb.control(null),
      underlyingDenomQty: fb.control(null),
      underlyingIsin: fb.control(null),
      underlyingDescription: fb.control(null),
      strikePrice: fb.control(null),
      previousPoolFactor: fb.control(null, [CustomValidators.PoolFactor]),
      newPoolFactor: fb.control(null, [CustomValidators.PoolFactor]),
      forexRate: fb.control(null),
      partialRedemptionRate: fb.control(null),
      denominationAsString: fb.control(null),
      denominationCurrency: fb.control(null),
      amountPerDenomination: fb.control(null, [Validators.required, CustomValidators.ValidateScaleToFifteenDecimals]),
      paymentCurrency: fb.control(null),
      multiplePmtCurrencies: fb.control(null),
      outStandingNominalAsString: fb.control(null),
      eventStatus: fb.control(null),
      redeemableInSecurities: fb.control(null),
      interventionType: fb.control(null),
      paymentCurrencys: fb.control(null),
      narrative: fb.control(null),
      instrumentStatus: fb.control(null),
      complianceRestriction: fb.control(null),
      paymentDefault: fb.control(null),
      eocAdjQty: fb.control(null),
      clsAdjQty: fb.control(null),
      totalAdjQty: fb.control(null),
      updateAdjustedQty: fb.control(null),
      eventValidatedBy: fb.control(null),
      eventValidatedAt: fb.control(null),
      eventSentforValidationBy: fb.control(null)
    });
  }

  setMandatoryIntrFields() {
    const interestRate = this.formGroup.get('interestRate');
    const beginDate = this.formGroup.get('beginDate');
    const endDate = this.formGroup.get('endDate');
    const previousPoolFactor = this.formGroup.get('previousPoolFactor');
    const newPoolFactor = this.formGroup.get('newPoolFactor');
    interestRate?.setValidators(Validators.required);
    beginDate?.setValidators([Validators.required, CustomValidators.ValidateDate]);
    endDate?.setValidators([Validators.required, CustomValidators.ValidateDate]);
    previousPoolFactor?.setValidators([CustomValidators.PoolFactor]);
    newPoolFactor?.setValidators([CustomValidators.PoolFactor]);
    this.setREDMFields(true);
    this.setINTRFields(false);
  }

  setMandatoryPredFields() {
    const previousPoolFactor = this.formGroup.get('previousPoolFactor');
    const newPoolFactor = this.formGroup.get('newPoolFactor');
    const amountPerDenomination = this.formGroup.get('amountPerDenomination');
    previousPoolFactor?.setValidators([Validators.required, CustomValidators.PoolFactor]);
    newPoolFactor?.setValidators([Validators.required, CustomValidators.PoolFactor]);
    amountPerDenomination?.setValidators([CustomValidators.ValidateScaleToFifteenDecimals]);
    this.setREDMFields(true);
    this.setINTRFields(true);
    this.setPREDFileds();
  }

  setMandatoryDvcaFields() {
    const exDate = this.formGroup.get('exDate');
    exDate?.setValidators([Validators.required, CustomValidators.ValidateDate]);
    this.setDVCAFileds();
  }

  setMandatoryRedmAndMcalFields() {
    const previousPoolFactor = this.formGroup.get('previousPoolFactor');
    const newPoolFactor = this.formGroup.get('newPoolFactor');
    const redemptionType = this.formGroup.get('redemptionType');
    redemptionType?.setValidators([Validators.required]);
    previousPoolFactor?.setValidators([CustomValidators.PoolFactor]);
    newPoolFactor?.setValidators([CustomValidators.PoolFactor]);
    this.setREDMFields(false);
    this.setINTRFields(true);
  }

  setREDMFields(lock: boolean) {
    const redemptionFields = Object.values<keyof EventDetailModel>(this.fields).filter(f => REDMEventFields.includes(f as any));
    if (lock) {
      this.lockFields(...redemptionFields);
    } else {
      this.unlockFields(...redemptionFields);
    }
  }

  setPoolFactor() {
    const predField = Object.values<keyof EventDetailModel>(this.fields).filter(f => PREDField.includes(f as any));
    const partialRedmType = this.rawValue('partialRedemptionType')?.valueDescription;
    const partialRedm = this.rawValue('partialRedemption');
    if (partialRedmType === ('PRED' || 'CREDIT EVENT') && partialRedm === true) {
      this.unlockFields(...predField);
    } else {
      this.lockFields(...predField);
    }
  }

  setEventStatusFields(enableEventStatus: boolean) {
    if (enableEventStatus) {
      this.unlockFields('eventStatus');
    } else {
      this.lockFields('eventStatus');
    }
  }

  setPaymentCurrencyFields(multiPaymentCurrency: boolean) {
    const paymentCurrency = this.formGroup.get('paymentCurrency');
    if (multiPaymentCurrency) {
      paymentCurrency?.setValidators([Validators.required]);
      this.unlockFields('paymentCurrency');
    } else {
      this.lockFields('paymentCurrency');
      paymentCurrency?.clearValidators();
    }
  }

  setINTRFields(lock: boolean) {
    const intrFields = Object.values<keyof EventDetailModel>(this.fields).filter(f => INTREventFields.includes(f as any));
    if (lock) {
      this.lockFields(...intrFields);
    } else {
      this.unlockFields(...intrFields);
    }
  }

  setDVCAFileds() {
    const dvcaFields = Object.values<keyof EventDetailModel>(this.fields).filter(f => DVCAFields.includes(f as any));
    this.lockFields(...dvcaFields);
  }

  eventLockFields() {
    const eventFields = Object.values<keyof EventDetailModel>(this.fields).filter(f => AllEventTypeFields.includes(f as any));
    this.lockFieldsPermanently(...eventFields);
    this.setPoolFactor();
  }
  setPREDFileds() {
    const predField = Object.values<keyof EventDetailModel>(this.fields).filter(f => PREDField.includes(f as any));
    this.unlockFields(...predField);
  }

  addcommonDepTypeValidators() {
    const recordDate = this.formGroup.get('recordDate');
    const preAdvDate = this.formGroup.get('preAdvDate');
    const notificationDate = this.formGroup.get('notificationDate');
    notificationDate?.setValidators([Validators.required]);
    recordDate?.setValidators([Validators.required]);
    preAdvDate?.setValidators([Validators.required]);
  }

  addListingTypeValidators() {
    const notificationDate = this.formGroup.get('notificationDate');
    notificationDate?.setValidators([Validators.required]);
  }

  addPayingTypeValidators() {
    const reqFundDate = this.formGroup.get('reqFundDate');
    const notificationDate = this.formGroup.get('notificationDate');
    const paymentDate = this.formGroup.get('paymentDate');
    notificationDate?.setValidators([Validators.required]);
    reqFundDate?.setValidators([Validators.required]);
    paymentDate?.setValidators([Validators.required]);
  }

  addRedemtionPriceUnitValidators(tradingType: string | null | undefined) {
    if (tradingType === EVENT_CONSTANTS.tradingMethod.UNIT) {
      const eventRedemptionUnit = this.formGroup.get('eventRedemptionUnit');
      eventRedemptionUnit?.setValidators(Validators.required);
    } else {
      const eventRedemptionPrice = this.formGroup.get('eventRedemptionPrice');
      eventRedemptionPrice?.setValidators(Validators.required);
    }
  }

  clearValidators() {
    const redemptionType = this.formGroup.get('redemptionType');
    const previousPoolFactor = this.formGroup.get('previousPoolFactor');
    const newPoolFactor = this.formGroup.get('newPoolFactor');
    const beginDate = this.formGroup.get('beginDate');
    const endDate = this.formGroup.get('endDate');
    const interestRate = this.formGroup.get('interestRate');
    const exDate = this.formGroup.get('exDate');
    const recordDate = this.formGroup.get('recordDate');
    const preAdvDate = this.formGroup.get('preAdvDate');
    const reqFundDate = this.formGroup.get('reqFundDate');
    const notificationDate = this.formGroup.get('notificationDate');
    const paymentDate = this.formGroup.get('paymentDate');
    const strikePrice = this.formGroup.get('strikePrice');
    const eventRedemptionPrice = this.formGroup.get('eventRedemptionPrice');
    const eventRedemptionUnit = this.formGroup.get('eventRedemptionUnit');
    const underlyingDescription = this.formGroup.get('underlyingDescription');
    const underlyingIsin = this.formGroup.get('underlyingIsin');
    const underlyingDenomQty = this.formGroup.get('underlyingDenomQty');
    beginDate?.clearValidators();
    endDate?.clearValidators();
    redemptionType?.clearValidators();
    previousPoolFactor?.clearValidators();
    newPoolFactor?.clearValidators();
    interestRate?.clearValidators();
    exDate?.clearValidators();
    recordDate?.clearValidators();
    preAdvDate?.clearValidators();
    reqFundDate?.clearValidators();
    paymentDate?.clearValidators();
    notificationDate?.clearValidators();
    strikePrice?.clearValidators();
    eventRedemptionPrice?.clearValidators();
    eventRedemptionUnit?.clearValidators();
    underlyingDescription?.clearValidators();
    underlyingIsin?.clearValidators();
    underlyingDenomQty?.clearValidators();
  }

  addAmountPerDenomValidators() {
    const amountPerDenomination = this.formGroup.get('amountPerDenomination');
    amountPerDenomination?.setValidators([Validators.required, CustomValidators.ValidateScaleToFifteenDecimals]);
  }

  enableRedemtionTypeFields(redemptionType: number | undefined) {
    const strikePrice = this.formGroup.get('strikePrice');
    const underlyingDescription = this.formGroup.get('underlyingDescription');
    const underlyingIsin = this.formGroup.get('underlyingIsin');
    const underlyingDenomQty = this.formGroup.get('underlyingDenomQty');
    if (redemptionType === 1) {
      strikePrice?.disable();
      underlyingDescription?.disable();
      underlyingIsin?.disable();
      underlyingDenomQty?.disable();
      strikePrice?.setValue(null);
      underlyingDescription?.setValue('');
      underlyingIsin?.setValue('');
      underlyingDenomQty?.setValue(null);
      strikePrice?.clearValidators();
      underlyingDescription?.clearValidators();
      underlyingIsin?.clearValidators();
      underlyingDenomQty?.clearValidators();
    } else {
      strikePrice?.enable();
      underlyingIsin?.enable();
      underlyingDescription?.enable();
      underlyingDenomQty?.enable();
      strikePrice?.setValidators([Validators.required]);
      underlyingDescription?.setValidators([Validators.required]);
      underlyingIsin?.setValidators([Validators.required]);
      underlyingDenomQty?.setValidators([Validators.required]);
    }
  }
}
