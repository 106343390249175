/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { MOMENT_DATE_FORMAT } from '@shared/pipes/date-format/common';

type NullableDateOrString = Date | null | string;

/*
USAGE EXAMPLE :
Delete the placeholder 
<app-date-picker formControlName="..." [placeholder]="'placeholder'">
</app-type-multiple-select>
*/ @Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
export class DatePickerComponent implements ControlValueAccessor {
  @ViewChild('picker', { static: false, read: ElementRef }) picker!: ElementRef;

  // Define component state as invalid.
  @Input() hasError = false;

  //size
  @Input() size: DesignSystemSize = 'md';

  @Input() public focusId: string | null = null;

  @Input() public fixedPosition = false;

  @Output() inputBlur = new EventEmitter<void>();

  @Output() onchange: EventEmitter<NullableDateOrString> = new EventEmitter<NullableDateOrString>();

  public selectedDate: NullableDateOrString = null;

  // find another usage
  public isReadOnly = false;

  public sgDateFormat = 'dd-MM-yyyy';

  public state: string | null = null;

  public get model(): string | number | undefined {
    return this.selectedDate?.valueOf();
  }

  public get classSize(): string {
    return `form-control-${this.size}`;
  }

  onDateSelected(selectedDateEvent: any) {
    const date: Date = selectedDateEvent.detail.date;
    if (!date || isNaN(date.valueOf())) {
      this.onChange(null);
    } else {
      const zoneOffsetDate = moment(date).utcOffset(0, true).toDate();
      this.onChange(zoneOffsetDate);
      this.picker.nativeElement.setState(null, null);
      this.selectedDate = zoneOffsetDate;
    }
  }

  onInputCleared() {
    this.onChange(null);
    this.picker.nativeElement.setState('invalid');
  }

  onInvalidDate() {
    this.onChange(null);
    this.picker.nativeElement.setState('invalid');
  }

  public onCalendarClose() {
    this.picker.nativeElement.getSelection().then((selected: any) => {
      if (selected.length === 0) {
        this.onInputCleared();
      }
    });
    this.onBlur();
  }

  get dateLabel(): string {
    if (!this.selectedDate) {
      return '';
    }
    return moment(this.selectedDate).format(MOMENT_DATE_FORMAT);
  }

  public onBlur(): void {
    this.onTouched();
    this.inputBlur.next();
  }

  onChange!: OnChangeFn<NullableDateOrString>;

  onTouched!: OnTouchFn;

  setDisabledState(isDisabled: boolean): void {
    this.isReadOnly = isDisabled;
  }
  registerOnChange(fn: OnChangeFn<NullableDateOrString>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchFn): void {
    this.onTouched = fn;
  }

  writeValue(value: NullableDateOrString | undefined): void {
    this.selectedDate = (!value || value instanceof Date ? value : new Date(value)) || null;
  }
}
