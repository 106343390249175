<div *ngIf="models.length>0; else noData">
  <ag-grid-angular #agGrid class="ag-theme-sg-bootstrap-condensed" [gridOptions]="gridOptions!"
    [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [rowData]="models" (gridReady)="onGridReady($event)">
  </ag-grid-angular>
</div>
<div class="row mt-5">
  <div class="col-md-12">
    <app-sg-pagination [pageSize]="itemsByPage" [totalItems]="totalItems" (pageChanged)="onPageChanged($event)"
      [currentPage]="currentPage" enableCellTextSelection=true [maxPagesToDisplay]="10"></app-sg-pagination>
  </div>
</div>

<ng-template #noData>
  <div class="col-12 justify-content-center" [class.d-none]="!models" [class.d-flex]="models">
    <div class="text-center text-secondary">
      <em class="icon icon-xl d-inline-block">search</em>
      <div class="h4" translate>ag-grid.title</div>
      <p translate>ag-grid.searchSubTitle</p>
    </div>
  </div>
</ng-template>
