<div
  (click)="onContainerClicked($event)"
  (hidden.bs.modal)="hide()"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  [ngClass]="{ show: visibleAnimate }"
  [ngStyle]="{ display: shown ? 'block' : 'none', opacity: visibleAnimate ? 1 : 0 }"
>
  <div class="modal-dialog">
    <div [class]="'modal-content ' + contentClasses">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<!-- https://stackoverflow.com/a/40144809 -->
