/**
 * issuer-ipa-events-api API
 * Manage events data
 *
 * The version of the OpenAPI document: 1.3.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Search Swift Notification
 */
export interface SearchSwiftMessage { 
    swiftIds?: Array<number>;
    eventIds?: Array<number>;
    semeReferences?: Array<string>;
    corpReferences?: Array<string>;
    traReferences?: Array<string>;
    statusValueIds?: Array<number>;
    msgTypeValueIds?: Array<number>;
}

