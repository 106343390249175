  <div class="container g-0" [formGroup]="formGroup">
    <div class="row">
      <div class="col-md-6">
        <app-form-item [field]="fields.documentType" [focus]="documentType">
          <select class="form-control box-style" type="text" [formControlName]="fields.documentType" component #documentType>
            <option [value]="documentModel.name" *ngFor="let documentModel of documents">{{ documentModel.name }} 
          </select>
        </app-form-item>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6">
        <app-form-item [field]="fields.startDate" [focus]="dates">
          <sg-daterangepicker #dates component (dateChanged)="onDatesChanged($event)" 
            startPlaceholder="{{'instruments.instruments-reporting-access.filters.startdate'|translate}}"
            endPlaceholder="{{'instruments.instruments-reporting-access.filters.enddate'|translate}}">
          </sg-daterangepicker>
        </app-form-item>
      </div>
    </div>
  </div>
  <app-text-icon-button icon="search" [submit]="true" [disabled]="formGroup.invalid" customClasses="d-flex ms-auto"
    label="{{'instruments.instruments-reporting-access.search'|translate}}" size="md" buttonType="btn-icon-text">
  </app-text-icon-button>
