export * from './auditResource.service';
import { AuditResourceService } from './auditResource.service';
export * from './documentModelResource.service';
import { DocumentModelResourceService } from './documentModelResource.service';
export * from './documentResource.service';
import { DocumentResourceService } from './documentResource.service';
export * from './documentVersionResource.service';
import { DocumentVersionResourceService } from './documentVersionResource.service';
export * from './instrumentResource.service';
import { InstrumentResourceService } from './instrumentResource.service';
export * from './partyResource.service';
import { PartyResourceService } from './partyResource.service';
export * from './permissionsResource.service';
import { PermissionsResourceService } from './permissionsResource.service';
export * from './positionResource.service';
import { PositionResourceService } from './positionResource.service';
export * from './programmeResource.service';
import { ProgrammeResourceService } from './programmeResource.service';
export * from './typedDataResource.service';
import { TypedDataResourceService } from './typedDataResource.service';
export const APIS = [AuditResourceService, DocumentModelResourceService, DocumentResourceService, DocumentVersionResourceService, InstrumentResourceService, PartyResourceService, PermissionsResourceService, PositionResourceService, ProgrammeResourceService, TypedDataResourceService];
