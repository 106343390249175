/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { InstrumentCreateDto, InstrumentDto, InstrumentUpdateDto } from '@apis/backend/instruments';

import { CharacteristicModel, toCharacteristicDto, toCharacteristicModel, toCharacteristicUpdateDto } from './characteristic.model';
import { GlobalNoteModel, toGlobalNoteDto, toGlobalNoteModel } from './global-note.model';
import { IssuanceModel, toIssuanceDto, toIssuanceModel } from './issuance.model';
import { ListingModel, toListingDto, toListingModel } from './listing.model';
import { RoleDataModel, toRoleDataDto, toRoleDataModel } from './role-data.model';

import { EventsDataModel, toEventsDataDto, toEventsDataModel } from './events-data.model';
import { InstrumentAddendumModel, ToInstrumentAddendumModel } from './instrument-addendum.model';
import { toTrancheModel } from './tranche.model';
import { notEmpty } from '@utils/utility-functions';
import { IpaTypeModel } from '@shared/models';
import { InstrumentDataFetchDto } from '@apis/backend/instruments/model/instrumentDataFetchDto';
export type InstrumentArrayType = [CharacteristicModel, ListingModel, IssuanceModel, GlobalNoteModel, EventsDataModel, RoleDataModel, InstrumentAddendumModel];
export type InstrumentModel = UnionToIntersection<InstrumentArrayType[number]>;

export type InstrumentForms = 'rootForm_Instrument' | 'characteristic' | 'globalNote' | 'listing' | 'issuance' | 'eventData' | 'roleData' | 'addendum';
export interface InstrumentDataFetchModel {
  readonly type?: string;
  readonly idInstrument?: number;
  readonly instrumentType?: string;
  readonly issuerName?: string;
  readonly name?: string;
  readonly programmeICSDNumber?: string;
  readonly seriesNumber?: string;
  readonly settlementCurrency?: string;
  readonly trancheNumber?: number;
  readonly issueDate?: string;
  readonly isinCodeTranche?: string;
  readonly trancheStatus?: string;
  outStandingNominal?: string;
  readonly maturityDate?: string;
  readonly commonDepositaryName?: string;
  readonly commonDepositaryBdrId?: string;
  readonly commonSafeKeeperName?: string;
  readonly commonSafeKeeperBdrId?: string;
}

export const toInstrumentDataFetchDto = (model: InstrumentDataFetchModel | null | undefined): InstrumentDataFetchDto => ({
  type: model?.type,
  idInstrument: model?.idInstrument,
  instrumentType: model?.instrumentType,
  issuerName: model?.issuerName,
  name: model?.name,
  programmeICSDNumber: model?.programmeICSDNumber,
  seriesNumber: model?.seriesNumber,
  settlementCurrency: model?.settlementCurrency,
  trancheNumber: model?.trancheNumber,
  issueDate: model?.issueDate,
  isinCodeTranche: model?.isinCodeTranche,
  trancheStatus: model?.trancheStatus,
  outStandingNominal: model?.outStandingNominal,
  commonDepositaryName: model?.commonDepositaryName,
  commonDepositaryBdrId: model?.commonDepositaryBdrId,
  commonSafeKeeperName: model?.commonSafeKeeperName,
  commonSafeKeeperBdrId: model?.commonSafeKeeperBdrId
});
export const toInstrumentDataFetchModel = (dto: InstrumentDataFetchDto | null | undefined): InstrumentDataFetchModel => ({
  type: dto?.type,
  idInstrument: dto?.idInstrument,
  instrumentType: dto?.instrumentType,
  issuerName: dto?.issuerName,
  name: dto?.name,
  programmeICSDNumber: dto?.programmeICSDNumber,
  seriesNumber: dto?.seriesNumber,
  settlementCurrency: dto?.settlementCurrency,
  trancheNumber: dto?.trancheNumber,
  issueDate: dto?.issueDate,
  isinCodeTranche: dto?.isinCodeTranche,
  trancheStatus: dto?.trancheStatus,
  outStandingNominal: dto?.outStandingNominal,
  commonDepositaryName: dto?.commonDepositaryName,
  commonDepositaryBdrId: dto?.commonDepositaryBdrId,
  commonSafeKeeperName: dto?.commonSafeKeeperName,
  commonSafeKeeperBdrId: dto?.commonSafeKeeperBdrId
});
export const toInstrumentDto = (model: InstrumentModel | null | undefined, noFrequency: IpaTypeModel): InstrumentCreateDto | InstrumentUpdateDto => ({
  instrumentCharacteristicsData: toCharacteristicDto(model),
  instrumentListingData: toListingDto(model),
  instrumentIssuanceData: toIssuanceDto(model),
  instrumentGlobalNote: toGlobalNoteDto(model),
  instrumentRoleData: toRoleDataDto(model),
  instrumentEventsData: toEventsDataDto(model, noFrequency)
});
export const toInstrumentUpdateDto = (model: InstrumentModel | null | undefined, noFrequency: IpaTypeModel): InstrumentUpdateDto => ({
  id: model?.id,
  instrumentCharacteristicsData: toCharacteristicUpdateDto(model),
  instrumentListingData: toListingDto(model),
  instrumentIssuanceData: toIssuanceDto(model),
  instrumentGlobalNote: toGlobalNoteDto(model),
  instrumentRoleData: toRoleDataDto(model),
  instrumentEventsData: toEventsDataDto(model, noFrequency),
  lastUpdateDateTimeAsString: model?.lastUpdateDateTimeAsString || undefined
});

export const toInstrumentModel = (dto: InstrumentDto, trancheNumber?: number): InstrumentModel => {
  if (!trancheNumber) {
    trancheNumber = 1;
  } else {
    trancheNumber = +trancheNumber;
  }
  const tranches = Array.from(dto.instrumentTranches || [])
    .map(toTrancheModel)
    .filter(notEmpty)
    .sort((a, b) => a?.trancheNumber - b?.trancheNumber);
  const selectedTranche = tranches.find(t => t.trancheNumber === trancheNumber);

  return {
    ...toCharacteristicModel(dto.instrumentCharacteristicsData, selectedTranche),
    ...toListingModel(dto.instrumentListingData),
    ...toIssuanceModel(dto.instrumentIssuanceData, selectedTranche),
    ...toGlobalNoteModel(dto.instrumentGlobalNote),
    ...toRoleDataModel(dto.instrumentRoleData),
    ...toEventsDataModel(dto.instrumentEventsData, selectedTranche),
    ...ToInstrumentAddendumModel(dto, selectedTranche, tranches)
  };
};
