<app-ttd-items-selector
  [(ngModel)]="value"
  [data]="values"
  [singleSelect]="!multiple"
  [isReadOnly]="isReadOnly"
  (focusout)="onBlurEmit()"
  [labelProperties]="['name']"
  [withSummary]="false"
  [placeHolder]="placeholder"
  key="leiCode"
  labelPath="name">
  <div readonlyTemplate>
    <span *ngFor="let val of displayValue; let isLast = last">{{val.name}}<span class="mx-1"
        *ngIf="!isLast">;</span></span>
  </div>
</app-ttd-items-selector>