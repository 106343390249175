import { UpdateSettlementStatusRequestDto } from '@apis/backend/instruments';

export interface UpdateStatusSettlementRequestModel {
  settlementNumbers?: string[] | null;
  instrumentId?: string | null;
  isin?: string | null;
  trancheNumber?: string | null;
}
export const toUpdateStatusSettlementRequestDto = (model: UpdateStatusSettlementRequestModel): UpdateSettlementStatusRequestDto => {
  return {
    settlementNumbers: model.settlementNumbers || [],
    instrumentId: model.instrumentId?.toString() || '',
    isin: model.isin?.toString() || '',
    trancheNumber: model.trancheNumber?.toString() || ''
  };
};
