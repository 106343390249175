import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Router } from '@angular/router';
import { routeToPayment } from 'src/app/payment/routes';
import { PaymentDetailFormService } from 'src/app/payment/services';

@Component({
  selector: 'app-payment-action',
  templateUrl: './payment-action.component.html'
})
export class PaymentActionComponent {
  params: ICellRendererParams | undefined;
  constructor(private readonly router: Router, public paymentDetailFormService: PaymentDetailFormService) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  goToPaymentDetails(): void {
    const selectedData = [this.params?.node.data];
    this.router.navigate(routeToPayment(selectedData[0].payment, 'consult'));
  }
}
