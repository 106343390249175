import { InstrumentDataModel } from './instrumentDataModel';
import { ScheduleEventModel } from './scheduleEvent.model';
import { EventInstrumentDataDto } from '@apis/backend/events';
import { PartyModel } from '@shared/models';

export type ScheduleEventArrayType = [ScheduleEventModel, InstrumentDataModel];
export type ScheduleEventGridViewModel = UnionToIntersection<ScheduleEventArrayType[number]>;

export const toScheduleEventGridViewModel = (instrumentData: EventInstrumentDataDto | undefined, scheduleData: ScheduleEventModel, eventData: ScheduleEventModel | null | undefined): ScheduleEventGridViewModel | null => {
  if (!instrumentData && !scheduleData) {
    return null;
  }
  return {
    eventReference: scheduleData?.eventReference,
    instrumentId: scheduleData?.instrumentId,
    valueDate: scheduleData?.valueDate ? scheduleData?.valueDate : undefined,
    notificationDate: scheduleData?.notificationDate ? scheduleData?.notificationDate : undefined,
    requestOfFundsDate: scheduleData?.requestOfFundsDate ? scheduleData?.requestOfFundsDate : undefined,
    preAdviceDate: scheduleData?.preAdviceDate ? scheduleData?.preAdviceDate : undefined,
    validationOfPaymentDate: scheduleData?.validationOfPaymentDate,
    beginDate: scheduleData?.beginDate,
    endDate: scheduleData?.endDate,
    paymentCurrency: eventData?.paymentCurrency,
    status: scheduleData?.status,
    eventType: scheduleData?.eventType,
    id: scheduleData?.instrumentId ? scheduleData?.instrumentId : undefined,
    issuer: (instrumentData?.issuerName as PartyModel) || null,
    seriesNumber: instrumentData?.seriesNumber || null,
    isinReference: instrumentData?.isin || null,
    name: instrumentData?.instrumentName || null
  };
};
