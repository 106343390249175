import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ErrorListFromBackendComponent } from './error-list-from-backend.component';
import { ErrorListFromBackendService } from './service/error-list-from-backend.service';

@NgModule({
  declarations: [ErrorListFromBackendComponent],
  imports: [CommonModule, TranslateModule],
  providers: [ErrorListFromBackendService],
  exports: [ErrorListFromBackendComponent]
})
export class ErrorListFromBackendModule {
  static forRoot(): ModuleWithProviders<ErrorListFromBackendModule> {
    return {
      ngModule: ErrorListFromBackendModule,
      providers: [ErrorListFromBackendService]
    };
  }
}
