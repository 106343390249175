<app-header (goBack)="onBackClick()" [moveElementDown]="bodyContainer">
    <div headerButton>
        <div *ngIf="!isEditMode">
            <button (click)="onEditClick()" class="btn btn-flat-info btn-icon-start">
                <em class="icon">mode_edit</em>
                <span class="title-case" translate>payment.buttons.modify</span>
            </button>
        </div>
        <app-payment-save-cancel *ngIf="isEditMode"></app-payment-save-cancel>
    </div>
</app-header>
<div class="container" #bodyContainer>
    <div class="border">
        <div class="card">
            <div class="card-header border-top" id="genericDetails">
                <h5 class="mb-0">
                    <button class="btn btn-link" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseGenericDetails" aria-expanded="true"
                        aria-controls="collapseGenericDetails">
                        <i class="icon icon-sm me-2 text-secondary">
                            keyboard_arrow_right
                        </i>
                        <span translate>payment.form.genericDetails</span>
                    </button>
                </h5>
            </div>
            <div id="collapseGenericDetails" class="collapse show" aria-labelledby="genericDetails">
                <div class="card-body pt-0">
                    <div class="section-forms container g-0" [formGroup]="formGroup">
                        <div class="row gy-2">
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.isin">
                                    <input class="form-control" type="text" [formControlName]="fields.isin"
                                       component #isin  />
                                  </app-form-item>
                            </div>
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.eventType"  [focus]="eventType">
                                  <input class="form-control" type="text" [formControlName]="fields.eventType"
                                     component #eventType  />
                                </app-form-item>
                            </div>
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.requestOfFundsId"  [focus]="requestOfFundsId">
                                  <input class="form-control" type="text" [formControlName]="fields.requestOfFundsId"
                                     component #requestOfFundsId  />
                                </app-form-item>
                            </div>
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.eventId"  [focus]="eventId">
                                  <input class="form-control" type="text" [formControlName]="fields.eventId"
                                     component #eventId  />
                                </app-form-item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header border-top" id="paymentDetails">
                <h5 class="mb-0">
                    <button class="btn btn-link" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapsepaymentDetails" aria-expanded="true"
                        aria-controls="collapsepaymentDetails">
                        <i class="icon icon-sm me-2 text-secondary">
                            keyboard_arrow_right
                        </i>
                        <span translate>payment.form.paymentDetails</span>
                    </button>
                </h5>
            </div>
            <div id="collapsepaymentDetails" class="collapse show" aria-labelledby="paymentDetails">
                <div class="card-body pt-0">
                    <div class="section-forms" [formGroup]="formGroup">
                        <div class="row gy-2">
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.paymentConfReceiptDate" [focus]="paymentConfReceiptDate">
                                       <app-date-picker [formControlName]="fields.paymentConfReceiptDate" #paymentConfReceiptDate component>
                                    </app-date-picker>
                                  </app-form-item>
                            </div>
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.valueDate"  [focus]="valueDate">
                                     <app-date-picker [formControlName]="fields.valueDate" #valueDate component>
                                    </app-date-picker>
                                </app-form-item>
                            </div>
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.amount"  [focus]="amount">
                                    <app-number #amount component [formControlName]="fields.amount"
                                        [formatThousandSeparator]='true' placeholder="{{ placeholder(fields.amount) | translate }}">
                                    </app-number>
                                </app-form-item>
                            </div>
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.paymentCcy"  [focus]="paymentCcy">
                                  <input class="form-control" type="text" [formControlName]="fields.paymentCcy"
                                     component #paymentCcy  />
                                </app-form-item>
                            </div>
                        </div>
                        <div class="row gy-2 mt-1">
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.cancelled" [focus]="cancelled">
                                    <input class="form-control" type="text" [formControlName]="fields.cancelled"
                                       component #cancelled  />
                                  </app-form-item>
                            </div>
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.paymentStatus"  [focus]="paymentStatus">
                                     <select  class="form-control box-style" type="text" [formControlName]="fields.paymentStatus" component
                                        #paymentStatus>
                                        <option *ngFor="let paymentStatus of allPaymentStatus" [value]="paymentStatus" hidden>
                                          {{paymentStatus}}
                                        </option>
                                        <option *ngIf="paymentStatusValue" value="REGULARIZED" translate>payment.form.regularized</option>
                                        <option *ngIf="paymentStatusValue" value="AWAIT NEW PAYMENT" translate>payment.form.awaitNewPayment</option>
                                        <option *ngIf="paymentInRepairpaymentStatusValue" value="PAYMENT_PENDING" translate>payment.form.paymentPending</option>
                                        <option *ngIf="paymentRejectReasonValue" value="AWAIT NEW PAYMENT" translate>payment.form.awaitNewPayment</option>
                                      </select>
                                </app-form-item>
                            </div>
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.paymentRejectReason" [focus]="paymentRejectReason">
                                    <textarea class="form-control box-style" type="text" [formControlName]="fields.paymentRejectReason"
                                        component #paymentRejectReason></textarea>
                                </app-form-item>
                            </div>
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.comments" [focus]="comments">
                                    <textarea class="form-control box-style" type="text" [formControlName]="fields.comments" component
                                        #comments></textarea>
                                </app-form-item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header border-top" id="otherDetails">
                <h5 class="mb-0">
                    <button class="btn btn-link" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOtherDetails" aria-expanded="true"
                        aria-controls="collapseOtherDetails">
                        <i class="icon icon-sm me-2 text-secondary">
                            keyboard_arrow_right
                        </i>
                        <span translate>payment.form.otherDetails</span>
                    </button>
                </h5>
            </div>
            <div id="collapseOtherDetails" class="collapse show" aria-labelledby="otherDetails">
                <div class="card-body pt-0">
                    <div class="section-forms" [formGroup]="formGroup">
                        <div class="row gy-2">
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.paymentReference" [focus]="paymentReference">
                                    <input class="form-control" type="text" [formControlName]="fields.paymentReference"
                                       component #paymentReference  />
                                  </app-form-item>
                            </div>
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.settlementMode"  [focus]="settlementMode">
                                  <input class="form-control" type="text" [formControlName]="fields.settlementMode"
                                     component #settlementMode  />
                                </app-form-item>
                            </div>
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.adviceNumber"  [focus]="adviceNumber">
                                  <input class="form-control" type="text" [formControlName]="fields.adviceNumber"
                                     component #adviceNumber  />
                                </app-form-item>
                            </div>
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.orderingInstitutation"  [focus]="orderingInstitutation">
                                  <input class="form-control" type="text" [formControlName]="fields.orderingInstitutation"
                                     component #orderingInstitutation  />
                                </app-form-item>
                            </div>
                        </div>
                        <div class="row gy-2 mt-1">
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.dealIdentifier" [focus]="dealIdentifier">
                                    <input class="form-control" type="text" [formControlName]="fields.dealIdentifier"
                                       component #dealIdentifier  />
                                  </app-form-item>
                            </div>
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.markReference"  [focus]="markReference">
                                  <input class="form-control" type="text" [formControlName]="fields.markReference"
                                     component #markReference  />
                                </app-form-item>
                            </div>
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.details"  [focus]="details">
                                  <input class="form-control" type="text" [formControlName]="fields.details"
                                     component #details  />
                                </app-form-item>
                            </div>
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.beneficiary"  [focus]="beneficiary">
                                  <input class="form-control" type="text" [formControlName]="fields.beneficiary"
                                     component #beneficiary  />
                                </app-form-item>
                            </div>
                        </div>
                        <div class="row gy-2 mt-1">
                            <div class="form-group col-md-3">
                                <app-form-item [field]="fields.backOffice" [focus]="backOffice">
                                    <input class="form-control" type="text" [formControlName]="fields.backOffice"
                                       component #backOffice  />
                                  </app-form-item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
