import { ColDef } from 'ag-grid-community/dist/lib/main';
import { dateFormatter } from './instrument-settlement-column-def-edit';
export interface DisposalEditColumnDef extends ColDef {
  headerName: string;
  children: any[];
}

export const DISPOSAL_EDIT_COLUMNS: DisposalEditColumnDef[] = [
  {
    headerName: 'header',
    children: [
      {
        field: 'icsd',
        width: 340,
        singleClickEdit: true,
        cellEditor: 'agSelectCellEditor',
        cellRenderer: function (data: any) {
          return data?.value?.valueDescription ? data.value.valueDescription : data?.value;
        },
        resizable: true,
        editable: true
      },
      {
        field: 'receptionDate',
        cellEditor: 'agDateInput',
        valueFormatter: dateFormatter,
        width: 360,
        resizable: true,
        editable: true,
        singleClickEdit: true
      }
    ]
  }
];
