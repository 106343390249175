import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ToastManagerService } from './service/toastManager.service';
import { ToastsComponent } from './toasts.component';

@NgModule({
  declarations: [ToastsComponent],
  imports: [CommonModule, TranslateModule],
  providers: [ToastManagerService],
  exports: [ToastsComponent]
})
export class ToastModule {
  static forRoot(): ModuleWithProviders<ToastModule> {
    return {
      ngModule: ToastModule,
      providers: [ToastManagerService]
    };
  }
}
