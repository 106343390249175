<div class="section-forms" [formGroup]="formGroup">
    <div class="row">
        <div  class="form-group col-md-6">
          <app-form-item [field]="fields.eventId" [focus]="eventId">
            <input class="form-control box-style" type="text" [formControlName]="fields.eventId" component #eventId readonly />
          </app-form-item>
          </div>
          <div  class="form-group col-md-6">
            <app-form-item [field]="fields.eventType" [focus]="eventType">
              <input class="form-control box-style" type="text" [formControlName]="fields.eventType" component #eventType readonly />
            </app-form-item>
          </div> 
    </div>
    <div class="row" [hidden]="displayBeginEndDate">
      <div  class="form-group col-md-6">
        <app-form-item [field]="fields.beginDate" [focus]="beginDate">
          <app-date-picker  [formControlName]="fields.beginDate" #beginDate component (onchange)="beginEndDateModificationAlert()">
          </app-date-picker >
        </app-form-item>
        <div class="error-message" *ngIf=displayBeginEndDateErrorMessage>
          <span translate>events.creation.beginEndDateModificationAlert</span>
        </div>
        </div>
        <div  class="form-group col-md-6">
          <app-form-item [field]="fields.endDate" [focus]="endDate">
            <app-date-picker  [formControlName]="fields.endDate" #endDate component (onchange)="beginEndDateModificationAlert(); valueDateModificationAlert()">
            </app-date-picker >
          </app-form-item>
          <div class="error-message" *ngIf=displayBeginEndDateErrorMessage>
            <span translate>events.creation.beginEndDateModificationAlert</span>
          </div>
        </div> 
  </div>
    <div class="row">
      <div  class="form-group col-md-6">
          <app-form-item [field]="fields.valueDate" [focus]="valueDate" >
            <app-date-picker  [formControlName]="fields.valueDate" #valueDate component (onchange)="valueDateModificationAlert()">
            </app-date-picker >
          </app-form-item>
          <div class="error-message" *ngIf=displayValueEndDateErrorMessage>
            <span translate>events.creation.valueEndDateModificationAlert</span>
          </div>
          <div class="error-message" *ngIf=displayValueDateErrorMessage>
            <span translate>events.creation.valueDateMaturityError</span>
          </div>
          <div class="error-message" *ngIf=displayValueMaturityDateErrorMessage>
            <span translate>events.creation.redmAndMcalCreation</span>
          </div>
        </div>
        <div  class="form-group col-md-6" [hidden]="!displayNotificationDate">
          <app-form-item [field]="fields.notificationDate" [focus]="notificationDate" >
            <app-date-picker  [formControlName]="fields.notificationDate" #notificationDate component >
            </app-date-picker >
          </app-form-item>
        </div>
  </div>
</div>
