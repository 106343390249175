/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IpaTypeCreateDto } from './ipaTypeCreateDto';


export interface InstrumentEventsDataCreateDto { 
    issueDateAsString?: string;
    interestCommencementDateAsString?: string;
    maturityDateAsString?: string;
    perpetual?: boolean;
    paymentDateCorrelated?: boolean;
    couponType?: IpaTypeCreateDto;
    firstCouponPayDateAsString?: string;
    interestRate?: string;
    dayCountFraction?: IpaTypeCreateDto;
    secondCouponType?: IpaTypeCreateDto;
    secondCouponPayDateAsString?: string;
    secondInterestRate?: string;
    secondDayCountFraction?: IpaTypeCreateDto;
    couponFrequency?: IpaTypeCreateDto;
    couponPaymentCurrencies?: Array<string>;
    firstEndPeriodDateAsString?: string;
    paymentDateOffset?: string;
    paymentDateMgtMode?: IpaTypeCreateDto;
    fundsArrivalDelayInDay?: string;
    endDateAdjusted?: boolean;
    businessDayConvention?: IpaTypeCreateDto;
    recordDateCalculationMode?: IpaTypeCreateDto;
    recordDatesCalculationDates?: Array<string>;
    recordDateOffset?: string;
    recordDateReference?: IpaTypeCreateDto;
    calculationTime?: IpaTypeCreateDto;
    calculationOffset?: string;
    redemptionValuationDateAsString?: string;
    redemptionCurrencies?: Array<string>;
    redemptionPrice?: string;
    redeemableInSecurities?: boolean;
    optionExerciseStyle?: IpaTypeCreateDto;
    partialRedemption?: boolean;
    partialRedemptionCorrelatedToCoupon?: boolean;
    partialRedemptionType?: IpaTypeCreateDto;
    partialRedemptCurrencies?: Array<string>;
    instrFinancialCurrencies?: Array<string>;
    instrFinancialPlaces?: Array<IpaTypeCreateDto>;
    convertible?: boolean;
    partlyPaid?: boolean;
    putOption?: boolean;
}

