import { FilterItem } from '@shared/models';
import { BehaviorSubject, Observable } from 'rxjs';

export abstract class FilterSelectorBaseService<U> {
  private filters: FilterItem<U>[] = [];
  filtersSubject = new BehaviorSubject<FilterItem<U>[]>(this.filters);

  public get $currentFilters(): Observable<FilterItem<U>[]> {
    return this.filtersSubject.asObservable();
  }

  public push(value: U, label: string, keyBase: string): void {
    const json = localStorage.getItem(keyBase);
    let filters: FilterItem<U>[] = [];
    if (json) {
      filters = JSON.parse(json) as FilterItem<U>[];
    }
    filters.push({ key: label, value: value });
    localStorage.setItem(`${keyBase}`, JSON.stringify(filters));
    this.filters = filters;
    this.filtersSubject.next(this.filters);
  }

  public loadFilters(keyBase: string): void {
    const json = localStorage.getItem(keyBase);
    if (json) {
      this.filters = JSON.parse(json) as FilterItem<U>[];
      this.filtersSubject.next(this.filters);
    } else {
      this.filters = [];
      this.filtersSubject.next(this.filters);
    }
  }
}
