export const SOCIETE_GENERALE_LUXEMBOURG = 'SOCIETE GENERALE LUXEMBOURG';

export const INSTRUMENT_CONSTANTS = {
  Types: {
    instrumentTypes: {
      EMTN: 'EMTN',
      EMTC: 'EMTC',
      ECP: 'ECP',
      ETF: 'ETF',
      WARRANT: 'WARRANT',
      CERTIFICATE: 'CERTIFICATE'
    },
    statuses: {
      INITIALIZED: 'INITIALIZED',
      LIVE: 'LIVE',
      CLOSED: 'CLOSED',
      CANCELLED: 'CANCELLED'
    },
    couponFrequencies: {
      ANNUAL: 'ANNUAL',
      SEMIANNUAL: 'SEMI-ANNUAL',
      FOURMONTHLY: 'FOUR-MONTHLY',
      QUARTERLY: 'QUARTERLY',
      BIMESTRIAL: 'BIMESTRIAL',
      MONTHLY: 'MONTHLY',
      BIMONTHLY: 'BIMONTHLY',
      WEEKLY: 'WEEKLY',
      UNIQUE: 'UNIQUE',
      PONCTUAL: 'PONCTUAL',
      MATURITY: 'MATURITY',
      NOFREQUENCY: 'NO-FREQUENCY'
    },
    couponsTypes: {
      FIXED: 'FIXED',
      VARIABLE: 'VARIABLE',
      INDEXED: 'INDEXED',
      ZEROCOUPON: 'ZERO-COUPON'
    },
    PhysicalForms: {
      PERMANENTGLOBALNOTE: 'PERMANENT GLOBAL NOTE',
      TEMPORARYPERMANENTGLOBALNOTETEFRAD: 'TEMPORARY + PERMANENT GLOBAL NOTE (TEFRA D)',
      DEFINITIVEGLOBALNOTE: 'DEFINITIVE GLOBAL NOTE',
      NONUSREGISTEREDGLOBALNOTE: 'NON US REGISTERED GLOBAL NOTE',
      REGS: 'REGS',
      A144: '144A',
      COMBINEDGLOBALNOTE: 'COMBINED GLOBAL NOTE',
      GLOBALWARRANT: 'GLOBAL WARRANT',
      REGISTEREDNOTE: 'REGISTERED NOTE'
    },
    tradingMethods: {
      UNIT: 'UNIT',
      NOMINAL: 'NOMINAL'
    },
    globalNotes: {
      MATERIALIZED: 'MATERIALIZED',
      DEMATERIALIZED: 'DEMATERIALIZED'
    },
    legalForms: {
      BEARER: 'BEARER',
      REGISTERED: 'REGISTERED',
      NEW_GLOBAL_NOTE: 'NEW GLOBAL NOTE',
      NEW_SAFEKEEPING_STRUCTURE: 'NEW SAFEKEEPING STRUCTURE'
    }
  },
  Parties: {
    CLEARSTREAMBANKINGSALuxembourg: 'CLEARSTREAM BANKING SA Luxembourg',
    EUROCLEARBANKBELGIUM: 'EUROCLEAR BANK BELGIUM',
    SOCIETEGENERALELUXEMBOURG: SOCIETE_GENERALE_LUXEMBOURG,
    ClearingSystems: {
      CLEARSTREAMBANKINGSALuxembourg: 'CLEARSTREAM BANKING SA Luxembourg',
      CLEARSTREAMFRANKFURT: 'CLEARSTREAM FRANKFURT',
      DEPOSITORYTRUSTCIETHENEWYORK: 'DEPOSITORY TRUST CIE THE NEW YORK',
      EUROCLEARBANKBELGIUM: 'EUROCLEAR BANK BELGIUM',
      EUROCLEARFrance: 'EUROCLEAR France',
      EUROCLEARUKANDIRELANDLIMITEDCREST: 'EUROCLEAR UK AND IRELAND LIMITED (CREST)',
      EuroclearFinland: 'Euroclear Finland',
      EuroclearSweden: 'Euroclear Sweden',
      MonteTitoli: 'Monte Titoli',
      NORWEGIANCENTRALSECURITYDEPOSITARYVPSASA: 'NORWEGIAN CENTRAL SECURITY DEPOSITARY, VPS ASA',
      SIXSISLtd: 'SIX SIS Ltd'
    },
    Registrars: {
      SOCIETE_GENERALE_LUXEMBOURG: SOCIETE_GENERALE_LUXEMBOURG,
      NA: 'N/A'
    },
    commonDepositary: {
      DEUTSCHEBANKAG: 'DEUTSCHE BANK AG',
      HSBCBANKPLC: 'HSBC BANK PLC',
      SOCIETEGENERALELUXEMBOURG: SOCIETE_GENERALE_LUXEMBOURG,
      NA: 'N/A'
    }
  },
  Issuers: {
    SG_PARIS: 'SG PARIS',
    SGE: 'SGE',
    SGIS: 'SGIS',
    SGOE: 'SGOE'
  }
};

export const SETTLEMENT_CONSTANTS = {
  statuses: {
    INITIALIZED: 'INITIALIZED',
    IN_REPAIR: 'IN REPAIR',
    DELIVERY_TO_BE_VALIDATED: 'DELIVERY TO BE VALIDATED',
    DELIVERY_VALIDATED: 'DELIVERY VALIDATED',
    DELIVERY_SECURITIES_PAYMENT_TO_BE_VALIDATED: 'DELIVERY SECURITIES PAYMENT TO BE VALIDATED',
    PAYMENT_TO_BE_VALIDATED: 'PAYMENT TO BE VALIDATED',
    SECURITIES_TO_BE_VALIDATED: 'SECURITIES TO BE VALIDATED',
    PAYMENT_VALIDATED: 'PAYMENT VALIDATED',
    SECURITIES_VALIDATED: 'SECURITIES VALIDATED'
  }
};
