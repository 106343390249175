import { POSITION_CONSTANTS } from '@positions/configs/position-constants';
import { AmountFormatCellComponent } from '@shared/components/amount-format-cell/amount-format-cell.component';
import { MOMENT_DATE_FORMAT } from '@shared/pipes/date-format/common';
import { ColDef, ValueFormatterParams } from 'ag-grid-community/dist/lib/main';
import * as moment from 'moment';
import { MouvementModel } from '../models/mouvement.model';

export interface MouvementColumnDef extends ColDef {
  field: keyof (MouvementModel & MouvementColumnsAction);
}
interface MouvementColumnsAction {
  actions: string;
}
export const MOUVEMENTS_COLUMNS: MouvementColumnDef[] = [
  {
    field: 'operation',
    valueFormatter: (q: ValueFormatterParams) => q.data.operation?.valueDescription,
    sortable: true,
    resizable: true
  },
  {
    field: 'valueDate',
    sortable: true,
    resizable: true,
    valueFormatter: (q: ValueFormatterParams): string => {
      if (!q.data.valueDate) {
        return '';
      }
      const momentDate = moment(q.data.valueDate);
      return momentDate.format(MOMENT_DATE_FORMAT);
    }
  },
  {
    field: 'direction',
    sortable: true,
    resizable: true
  },
  {
    field: 'value',
    cellRendererFramework: AmountFormatCellComponent,
    sortable: true,
    resizable: true,
    cellStyle: { display: 'flex', 'justify-content': 'flex-end' }
  },
  {
    field: 'type',
    valueFormatter: (q: ValueFormatterParams) => {
      if (q.data.type === POSITION_CONSTANTS.mouvements.types.avai) {
        return POSITION_CONSTANTS.mouvements.types.unblocked;
      }
      return POSITION_CONSTANTS.mouvements.types.blocked;
    },
    sortable: true,
    resizable: true
  }
];
