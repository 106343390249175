import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormService } from '@shared/services/forms/form.service';
import { EventCriteriaSearchModel } from '../models/event-criteria-search.model';

@Injectable()
export class EventCriteriaSearchFormService extends FormService<EventCriteriaSearchModel> {
  constructor(fb: UntypedFormBuilder) {
    super('search_event', fb, {
      isin: fb.control(null),
      ipaCode: fb.control(null),
      eventId: fb.control(null),
      issuerPerimter: fb.control(null),
      eventTypes: fb.control(null),
      eventStatuses: fb.control(null),
      notificationFromDate: fb.control(null),
      notificationToDate: fb.control(null),
      reqFundFromDate: fb.control(null),
      reqFundToDate: fb.control(null),
      preAdviceFromDate: fb.control(null),
      preAdviceToDate: fb.control(null),
      valueFromDate: fb.control(null),
      valueToDate: fb.control(null),
      issuerName: fb.control(null),
      pageNumber: fb.control(null),
      paymentCurrencies: fb.control(null)
    });
  }
}
