<div>
  <ag-grid-angular
    #agGrid
    class="ag-theme-sg-bootstrap ag-theme-sg-bootstrap-condensed bg-lvl1"
    [gridOptions]="gridOptions!"
    [defaultColDef]="defaultColDef"
    [columnDefs]="columnDefs"
    [rowData]="_data"
    animateRows
 
    (paginationChanged)="onPaginationChanged()"
    (gridReady)="onGridReady($event)"
    
    (cellValueChanged)="onCellValueChanged($event)"
  >
  </ag-grid-angular>
</div>
<div class="pagine" *ngIf="maxPage > 1">
  <nav aria-label="pages">
    <ul class="pagination">
      <!-- Previous -->
      <li class="page-item me-2" [ngClass]="{ disabled: currentPage === 0 }">
        <button class="page-link" (click)="gridApi?.paginationGoToPreviousPage()" [disabled]="currentPage === 0"> &lt; </button>
      </li>
      <!-- First Page -->
      <li *ngIf="currentPage - 2 > 0" class="page-item">
        <a class="page-link" (click)="gridApi?.paginationGoToFirstPage()">1</a>
      </li>
      <li *ngIf="currentPage - 3 > 0" class="page-item">
        <a class="page-link not-clickable">...</a>
      </li>
      <li *ngIf="currentPage - 1 > 0" class="page-item">
        <a class="page-link" (click)="gridApi?.paginationGoToPage(currentPage - 2)">{{ currentPage - 1 }}</a>
      </li>
      <li *ngIf="currentPage > 0" class="page-item">
        <a class="page-link" (click)="gridApi?.paginationGoToPage(currentPage - 1)">{{ currentPage }}</a>
      </li>
      <li class="page-item active" aria-current="page">
        <a class="page-link">{{ currentPage + 1 }}</a>
      </li>
      <li *ngIf="currentPage + 2 <= maxPage" class="page-item">
        <a class="page-link" (click)="gridApi?.paginationGoToPage(currentPage + 1)">{{ currentPage + 2 }}</a>
      </li>
      <li *ngIf="currentPage + 3 <= maxPage" class="page-item">
        <a class="page-link" (click)="gridApi?.paginationGoToPage(currentPage + 2)">{{ currentPage + 3 }}</a>
      </li>
      <!-- ... -->
      <li *ngIf="currentPage + 4 < maxPage" class="page-item">
        <a class="page-link not-clickable"> ... </a>
      </li>
      <!-- max-page -->
      <li *ngIf="currentPage + 3 < maxPage" class="page-item">
        <a class="page-link" (click)="gridApi?.paginationGoToLastPage()">{{ maxPage }}</a>
      </li>
      <!-- Next -->
      <li class="page-item ms-2" [ngClass]="{ disabled: currentPage === maxPage - 1 }">
        <button class="page-link" (click)="gridApi?.paginationGoToNextPage()" [disabled]="currentPage === maxPage - 1"> &gt; </button>
      </li>
    </ul>
  </nav>
</div>
