import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PositionsGridModel, PositionsTotalGridModel, EventPositionInputModel } from '@events/models';

@Component({
  selector: 'app-position-form',
  templateUrl: './position-form.component.html'
})
export class PositionFormComponent {
  public ClosedReason: DialogResult = 'closed';
  public CancelReason: DialogResult = 'cancel';
  public ConfirmReason: DialogResult = 'confirm';
  confirmButton = 'modals.buttons.confirm';
  cancelButton = 'modals.buttons.cancel';
  @Input() public positions!: PositionsGridModel[];
  @Input() public positionsTotal!: PositionsTotalGridModel[];
  @Input() public eventPositionInput: EventPositionInputModel | undefined;
  @Input() public hideTotalsGrid: boolean | undefined;
  hideActionsColumn = true;

  constructor(private readonly activeModal: NgbActiveModal) {}

  public onClose(): void {
    this.activeModal.dismiss(this.ClosedReason);
    this.activeModal.close(this.ClosedReason);
  }

  public onCancel(): void {
    this.activeModal.close(this.CancelReason);
  }

  public onConfirm(): void {
    this.activeModal.close(this.ConfirmReason);
  }
}
