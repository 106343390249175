import { Subscription } from 'rxjs';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { DocumentService } from '../../services/document.service';
import { DocumentFormService } from '@documents/services/document-form.service';
import { notEmpty } from '@utils/utility-functions';
import { DocumentModel } from '@documents/models/document-model.model';
import { allConfidentialityLevels, ConfidentialityLevel } from '@documents/models/types';
import { Forms } from '@shared/utils';
import { FileUploadModel } from '@documents/models';

type ConfidentialityLevels = Record<string, ConfidentialityLevel[]>;

@Component({
  selector: 'app-document-forms-list',
  templateUrl: './document-forms-list.component.html',
  styleUrls: ['./document-forms-list.component.scss']
})
export class DocumentFormsListComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public documentModels: DocumentModel[] = [];
  public confidentialityLevels: ConfidentialityLevels = {};
  @Input() multiple = true;
  @Input() compact = false;

  constructor(public readonly formService: DocumentFormService, private readonly documentService: DocumentService) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.documentService.getAllModels().subscribe(res => {
        this.documentModels = [...res];
        this.confidentialityLevels = res.reduce<ConfidentialityLevels>((result, model) => {
          const confidentialities: ConfidentialityLevel[] = [];
          for (let i = +model.minimumConfidentialityLevel.substring(1); i <= 2; ++i) {
            confidentialities.push(allConfidentialityLevels[i - 1]);
          }

          return {
            ...result,
            [model.id]: confidentialities
          };
        }, {});
      })
    );
  }

  public get isEditMode() {
    return this.formService.formMode === Forms.PageAdd;
  }

  public get formControlSize(): string {
    if (this.compact) {
      return 'form-control-sm';
    }
    return '';
  }

  public get formInputClasses(): string[] {
    if (this.compact) {
      return ['col-sm-12'];
    }
    return ['col-sm-4'];
  }
  public get formGroup() {
    return this.formService.formGroup;
  }

  public get fields() {
    return this.formService.fields;
  }

  public get formArrayName() {
    return this.formService.formArrayName;
  }

  public get uploadDocuments() {
    return this.formService.formArray;
  }

  public get nbFiles() {
    return this.formService.length;
  }

  public get isRequired() {
    return this.formService.isRequired.bind(this.formService);
  }
  public label(field: keyof FileUploadModel): string {
    return `documents.document.forms.${field}`;
  }

  public get control() {
    return this.formService.control.bind(this.formService);
  }

  public onFilesDropped(files: File[]) {
    files.forEach(file => {
      const row = this.formService.addRow({ file, documentName: file.name.replace(/\.[^/.]+$/, '') });
      this.subscriptions.push(
        row.controls[this.formService.fields.modelId].valueChanges.subscribe(modelId => {
          const confidentialityLevel = this.confidentialityLevels[modelId][0] || 'C1';
          row.patchValue({ [this.formService.fields.confidentialityLevel]: confidentialityLevel });
        })
      );
    });
  }
  confidentialityList(idx: number) {
    const selectedModelId = this.formService.rawValue(idx, 'modelId');
    return this.confidentialityLevels[selectedModelId || 'null'] || [...allConfidentialityLevels];
  }

  haveModelSelected(index: number): boolean {
    const modelId = this.formService.rawValue(index, 'modelId');
    return notEmpty(modelId);
  }
  removeDocument(index: number): void {
    this.formService.removeRow(index);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    }
  }
}
