import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AuditResourceService } from './api/auditResource.service';
import { DocumentModelResourceService } from './api/documentModelResource.service';
import { DocumentResourceService } from './api/documentResource.service';
import { DocumentVersionResourceService } from './api/documentVersionResource.service';
import { InstrumentResourceService } from './api/instrumentResource.service';
import { PartyResourceService } from './api/partyResource.service';
import { PermissionsResourceService } from './api/permissionsResource.service';
import { PositionResourceService } from './api/positionResource.service';
import { ProgrammeResourceService } from './api/programmeResource.service';
import { TypedDataResourceService } from './api/typedDataResource.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
