import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Output, Input } from '@angular/core';

import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { ActiveFilterItem } from '@shared/models';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';

import { PositionSearchCriteriaModel } from 'src/app/positions-keeping/models/position-search-criteria.model';
import { PositionCriteriaSearchFormService } from 'src/app/positions-keeping/services/position-criteria-search.form.service';

@Component({
  selector: 'app-position-list-filter',
  templateUrl: './position-list-filter.component.html',
  providers: [
    { provide: FormServiceInjectionToken, useExisting: PositionCriteriaSearchFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'positions.list.filters' }
  ]
})
export class PositionListFilterComponent extends BaseFormComponent<PositionSearchCriteriaModel, PositionCriteriaSearchFormService> implements AfterContentChecked {
  public isCollapsed = true;
  public nullValue = null;
  @Output() exportCsvAction = new EventEmitter();
  @Input() disableExportCsv = false;

  public filters: ActiveFilterItem<PositionSearchCriteriaModel>[] = [];
  public get hasFilters(): boolean {
    return this.filters.some(_ => true);
  }

  constructor(readonly positionCriteriaSearchFormService: PositionCriteriaSearchFormService, public readonly i18nUtils: I18nUtilsService, private readonly changeDetector: ChangeDetectorRef) {
    super(positionCriteriaSearchFormService, 'positions.list.filters');
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  exportCsv(): void {
    this.exportCsvAction.emit();
  }

  public get canExport(): boolean {
    return Object.keys(this.formService.fields)
      .map((_, index, array) => {
        const item = array[index];
        return this.formService.control(item as keyof PositionSearchCriteriaModel);
      })
      .some(q => q.value);
  }

  public onFiltersChanged(filters: ActiveFilterItem<PositionSearchCriteriaModel>[]): void {
    const dateFilter = filters.find(f => f.key === 'valueDate');

    if (!dateFilter) {
      const todayDate = new Date(Date.now()).toISOString();
      this.filters = [
        ...filters,
        {
          isMultipleValuesFilter: false,
          key: 'valueDate',
          value: todayDate
        }
      ];
      this.positionCriteriaSearchFormService.patchField('valueDate', todayDate);
    }

    this.filters = [...filters];
  }
}
