import { Component, EventEmitter, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActiveFilterItem } from '@shared/models';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { Subject } from 'rxjs';
import { EventCriteriaSearchFormService } from '@events/services';
import { EventCriteriaSearchModel } from '@events/models';
import { ActiveFiltersBaseComponent } from '@shared/search-base';
import { EventFiltersSaveComponent } from '../filters-save/event-filters-save.component';

@Component({
  selector: 'app-event-list-active-filters',
  templateUrl: './event-list-active-filters.component.html'
})
export class EventListActiveFiltersComponent extends ActiveFiltersBaseComponent<EventCriteriaSearchModel> {
  @Output() filtersChanged = new EventEmitter<ActiveFilterItem<EventCriteriaSearchModel>[]>();
  public filterItems: ActiveFilterItem<EventCriteriaSearchModel>[] = [];
  public shutdown$ = new Subject<void>();

  constructor(formService: EventCriteriaSearchFormService, modalService: NgbModal, i18nUtils: I18nUtilsService) {
    super(formService, modalService, i18nUtils, 'events-filters', 'events.list.filters');
  }
  public onFilterChanged(items: ActiveFilterItem<EventCriteriaSearchModel>[]): void {
    this.filtersChanged.emit(items);
  }
  public saveFilters(): NgbModalRef {
    const modal = this.modalService.open(EventFiltersSaveComponent);
    const dialog: EventFiltersSaveComponent = modal.componentInstance;
    dialog.value = this.criteriaFormService.value();
    dialog.keyBase = this.keyBase;
    return modal;
  }
}
