import { Injectable } from '@angular/core';
import { UnionFormService } from '@shared/services/forms/union.form.service';
import { PaymentCriteriaSearchModel } from '../models';
import { PaymentCriteriaSearchFormService } from '.';

export type PaymentSearchArrayType = [PaymentCriteriaSearchModel];
export type PaymentSearchQueryModel = PaymentCriteriaSearchModel;

@Injectable()
export class PaymentSearchService extends UnionFormService<PaymentSearchArrayType> {
  constructor(private readonly paymentCriteriaSearchFormService: PaymentCriteriaSearchFormService) {
    super('payment-search', paymentCriteriaSearchFormService);
  }
  public getfields(): (keyof PaymentCriteriaSearchModel)[] {
    const array = Object.keys(this.paymentCriteriaSearchFormService.fields);
    return array as (keyof PaymentCriteriaSearchModel)[];
  }
}
