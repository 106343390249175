import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionService } from '@shared/services/permission.service';
import { ICellRendererParams } from 'ag-grid-community';
import { routeToOperation } from 'src/app/positions-keeping/routes';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-operation-action',
  templateUrl: './operation-action.component.html'
})
export class OperationActionComponent implements OnDestroy {
  params: ICellRendererParams | undefined;
  public canReadOperation: boolean | undefined;
  private readonly shutdown$ = new Subject<void>();

  constructor(private readonly router: Router, public readonly permissionService: PermissionService) {}

  agInit(params: ICellRendererParams): void {
    this.permissionService.canReadOperation$.pipe(takeUntil(this.shutdown$)).subscribe({
      next: (canCreateOperation: boolean) => {
        this.canReadOperation = canCreateOperation;
      }
    });
    this.params = params;
  }

  goToDetail(): void {
    this.router.navigate(routeToOperation(this.params?.data.id));
  }
  ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}
