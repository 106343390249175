<ng-container *ngIf="!isReadOnly; else readonlyTemplate">
  <sg-datepicker
    #picker
    (dateSelected)="onDateSelected($event)" 
    (inputCleared)="onInputCleared()"
    (calendarClose)="onCalendarClose()"
    (invalidDate)="onInvalidDate()"
    [size]="size"
    [state]="state"
    [disabled]="isReadOnly" 
    [format]="sgDateFormat" 
    [displayFormat]="sgDateFormat" 
    [initialValue]="model"
    [attr.id]="focusId"
    [ngClass]="{ 'fixed-position': fixedPosition }">
  </sg-datepicker>
</ng-container>
<ng-template #readonlyTemplate>
  <input readonly class="form-control-plaintext" value="{{dateLabel|dateFormat}}" type="text">
</ng-template>