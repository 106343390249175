import { Injectable } from '@angular/core';
import { CommonDepositoriesArrayType, CommonDepositoriesForms } from '@instruments/models/management-common-depository.model';
import { UnionFormService } from '@shared/services/forms/union.form.service';
import { CommonDepositoryFormService } from './common-depository.form.service';
import { DocumentationReceptionFormService } from './documentation-reception.form.service';
import { PermanentMonitoringFormService } from './permanent-monitoring.form.service';

@Injectable()
export class ManagementCommonDepositoryFormService extends UnionFormService<CommonDepositoriesArrayType, CommonDepositoriesForms> {
  constructor(documentationReceptionFormService: DocumentationReceptionFormService, commonDepositoryFormService: CommonDepositoryFormService, permanentMonitoringFormService: PermanentMonitoringFormService) {
    super('rootForm_CommonDepository', commonDepositoryFormService, documentationReceptionFormService, permanentMonitoringFormService);
  }
}
