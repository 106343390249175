import { PagingPositionDtoWithCriteriaDto } from '@apis/backend/instruments/model/pagingPositionDtoWithCriteriaDto';
import { PositionSearchCriteriaModel, toPositionCriteriaSearchDto } from './position-search-criteria.model';
import { PositionModel } from './position.model';

export interface PositionPagingRequestWithCriteriaModel {
  /**
   * Requested page number.
   */
  pageNumber?: number;
  /**
   * Number of items per page.
   */
  itemsByPage?: number;
  criteria?: PositionSearchCriteriaModel | null;
}

export const toPagingRequestWithCriteriaDto = (model: PositionPagingRequestWithCriteriaModel | null | undefined): PagingPositionDtoWithCriteriaDto | undefined => {
  if (!model) {
    return undefined;
  }

  return {
    pageNumber: model.pageNumber,
    itemsByPage: model.itemsByPage,
    criteria: toPositionCriteriaSearchDto(model.criteria)
  };
};

export interface PagingResultDtoPositionDataFetchModel {
  totalItems?: number;
  totalPages?: number;
  currentPage?: number;
  items?: PositionModel[];
}
