<div class="modal-header">
  <h3 class="modal-title" translate> {{ 'filter-save.save-filters' }}</h3>
  <button type="button" class="close icon p-1" (click)="cancel()">close</button>
</div>
<div class="modal-body" translate>
  <div class="row">
    <div class="form-group col-md-12">
      <label class="text-secondary fw-medium required" translate>{{ 'filter-save.label' }}</label>
      <input class="form-control" [(ngModel)]="label" />
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" (click)="cancel()" (keyup.enter)="cancel()" class="btn btn-lg btn-link" translate> {{ 'filter-save.cancel' }}</button>
  <button ngbAutofocus type="button" (click)="save()" [disabled]="!label" (keyup.enter)="save()" class="btn btn-lg btn-primary" translate>
    {{ 'filter-save.save' }}
  </button>
</div>
