import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAction } from './action.model';

@Component({
  selector: 'app-dropdown-menu-renderer',
  templateUrl: './dropdown-menu.renderer.component.html'
})
export class DropdownMenuRendererComponent implements ICellRendererAngularComp {
  actions: IAction[] = [];

  agInit(params: any): void {
    this.actions = params.actions;
  }

  refresh(): boolean {
    return false;
  }
}
