<div class="d-flex flex-column">
  <div class="d-flex flex-row">
    <div class="col-md-6">
      <label class="text-secondary fw-medium" translate> instruments.instrument.forms.settlement.nominal </label>
    </div>
    <div>{{ nominal }} {{ nominalCurrency }}</div>
  </div>
  <div class="d-flex flex-row">
    <div class="col-md-6">
      <label class="text-secondary fw-medium"translate> instruments.instrument.forms.settlement.currency </label>
    </div>
    <div>{{ settlementCurrency }}</div>
  </div>
</div>
