/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProgrammeCriteriaSearchDto } from './programmeCriteriaSearchDto';


export interface PagingProgrammeDtoWithCriteriaDto { 
    /**
     * Requested page number.
     */
    pageNumber?: number;
    /**
     * Number of items per page.
     */
    itemsByPage?: number;
    criteria?: ProgrammeCriteriaSearchDto;
}

