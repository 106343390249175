import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ProgrammeCriteriaSearchModel } from '@programmes/models/progamme-paging-criteria.model';
import { FormService } from '@shared/services/forms/form.service';

@Injectable()
export class ProgrammeCriteriaSearchFormService extends FormService<ProgrammeCriteriaSearchModel> {
  constructor(fb: UntypedFormBuilder) {
    super('criteria-search_programme', fb, {
      issuerNames: fb.control([]),
      programmeName: fb.control(null),
      icsd: fb.control(null),
      status: fb.control(null)
    });
  }
}
