/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IpaTypeCreateDto } from './ipaTypeCreateDto';


export interface InstrumentListingDataCreateDto { 
    listingCountry?: IpaTypeCreateDto;
    listingPlace?: IpaTypeCreateDto;
    publiclySold?: boolean;
}

