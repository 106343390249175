<ul class="nav nav-underline text-large  " role="tablist">
  <li class="nav-item">
    <a class="nav-link ps-3 ms-0 pe-3 me-0 pb-2" data-toggle="pill" role="tab" translate
      (click)="selectStatus(null)" [class.text-primary]="!internalValue" [class.text-secondary]="!internalValue"
      [class.active]="!internalValue">
      <span class="text-small">
        <span class="badge badge-pill me-1 badge-all">
          {{ totalItems}}
        </span>
      </span>
      <span>All | titleCase</span>
    </a>
  </li>

  <li *ngFor="let status of items$ | async" class="nav-item">
    <a class="nav-link ps-3 ms-0 pe-3 me-0 pb-2" data-toggle="pill" role="tab" translate (click)="selectStatus(status)"
      [class.text-primary]="status.valueId===internalValue?.valueId"
      [class.text-secondary]="status.valueId===internalValue?.valueId"
      [class.active]="status.valueId===internalValue?.valueId">
      <span class="text-small">
        <span class="badge badge-pill me-1" [class]="'badge-' + status?.valueDescription?.toLocaleLowerCase()">
          {{ getCount(status.valueDescription)}}
        </span>
      </span>
      <span>
        {{ status.valueDescription | titlecase }}
      </span>
    </a>
  </li>
</ul>
