import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ErrorListFromBackendService } from './service/error-list-from-backend.service';
import { ComponentUtils } from '@shared/utils';

@Component({
  selector: 'app-error-list-from-backend',
  templateUrl: './error-list-from-backend.component.html',
  styleUrls: ['./error-list-from-backend.component.scss']
})
export class ErrorListFromBackendComponent implements OnInit, OnDestroy {
  private readonly containerEl = window;
  private readonly unsubscribe$ = new Subject<void>();

  public listError: any[] = [];

  constructor(public translate: TranslateService, private readonly errorListFromBackendService: ErrorListFromBackendService) {}

  ngOnInit(): void {
    this.errorListFromBackendService.listErrorSubject$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: data => {
        this.listError = data;
        if (this.listError.length > 0) {
          setTimeout(() => {
            ComponentUtils.scrollToFirstInvalidControl(this.containerEl, '.grid-list');
          }, 200);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
