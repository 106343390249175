import { ProgrammeDataFetchDto } from '@apis/backend/instruments';

export interface ProgrammeFetchModel {
  id?: number;
  icsdProgrammeNumber?: string;
  programmeName?: string;
  issuerName?: string;
  issuerReference?: string;
  cssfProgrammeIdentifierDate?: string;
  status?: string;
}
export const toProgrammeFetchModel = (dto: ProgrammeDataFetchDto | null | undefined): ProgrammeFetchModel => {
  return {
    id: dto?.id,
    icsdProgrammeNumber: dto?.icsdProgrammeNumber,
    programmeName: dto?.programmeName,
    issuerName: dto?.issuerName,
    issuerReference: dto?.issuerReference,
    cssfProgrammeIdentifierDate: dto?.cssfProgrammeIdentifierDate,
    status: dto?.status
  };
};
