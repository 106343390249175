<div class="section-forms container g-0" [formGroup]="formGroup">
  <div class="row gy-4">
    <div class="form-group col-md-6">
      <app-form-item [focus]="globalNoteForm" [field]="fields.globalNoteForm">
        <app-type-multiple-select [type]="GLOBAL_NOTE_FORM" [formControlName]="fields.globalNoteForm" #globalNoteForm
          component [multiple]="false" [closeOnSelect]="true" [withDebounce]="true"
          [hasError]="hasError(fields.globalNoteForm)" [placeholder]="placeholder(fields.globalNoteForm) | translate">
        </app-type-multiple-select>
      </app-form-item>
    </div>
    <div class="form-group col-md-6">
      <app-form-item [focus]="globalNoteClassic" [field]="fields.globalNoteClassic"
        [params]="errorParamBetweenGlobalNoteClassicAndLegalForm">
        <app-yes-no-buttons [formControlName]="fields.globalNoteClassic" component #globalNoteClassic
          (onchange)="setcommonDepositary()">
        </app-yes-no-buttons>
      </app-form-item>
    </div>
  </div>
  <div class="row gy-4 mt-1">
    <div class="form-group col-md-6">
      <app-form-item [focus]="legalForm" [field]="fields.legalForm"
        [params]="errorParamBetweenGlobalNoteClassicAndLegalForm">
        <app-type-multiple-select [type]="LEGAL_FORM" [formControlName]="fields.legalForm" #legalForm [multiple]="false"
          component [closeOnSelect]="true" [withDebounce]="true" [hasError]="hasError(fields.legalForm)"
          (onchange)="setcommonRegistrar()" [placeholder]="placeholder(fields.legalForm) | translate">
        </app-type-multiple-select>
      </app-form-item>
    </div>
    <div class="form-group col-md-6">
      <app-form-item [focus]="physicalForm" [field]="fields.physicalForm"
        [params]="errorParamBetweenRegsAndPhysicalForm">
        <app-type-multiple-select [type]="PHYSICAL_FORM" [formControlName]="fields.physicalForm" #physicalForm component
          [multiple]="false" [closeOnSelect]="true" [withDebounce]="true" [hasError]="hasError(fields.physicalForm)"
          [placeholder]="placeholder(fields.physicalForm) | translate">
        </app-type-multiple-select>
      </app-form-item>
    </div>
  </div>
  <div class="row gy-4 mt-1">
    <div class="form-group col-md-6">
      <app-form-item [focus]="regs144LinkedInstrument" [field]="fields.regs144LinkedInstrument"
        [params]="errorParamBetweenRegsAndPhysicalForm">
        <input type="text" maxlength="12" class="form-control" [formControlName]="fields.regs144LinkedInstrument"
          [placeholder]="placeholder(fields.regs144LinkedInstrument) | translate" component #regs144LinkedInstrument>
      </app-form-item>
    </div>
  </div>
</div>
