import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TypedDataModel, TYPED_DATA_TYPE } from '@shared/models';
import { TypedDataService } from '@shared/services/typed-data.service';
import { EMPTY, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

type NullableTypedDataModelOrArray = TypedDataModel[] | TypedDataModel | undefined;

@Component({
  selector: 'app-type-single-select',
  templateUrl: './type-single-select.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TypeSingleSelectComponent),
      multi: true
    }
  ]
})
export class TypeSingleSelectComponent implements ControlValueAccessor, OnInit {
  @Input() nullable = false;
  @Input() closeOnSelect = false;
  @Input() classes = '';
  @Input() withDebounce = false;
  @Input() type: TYPED_DATA_TYPE | undefined = undefined;
  @Input() placeholder: string | null = null;

  @Input() hasError = false;
  @Output() blurEmit: EventEmitter<void> = new EventEmitter();
  @Input() datas!: string[];

  @Output() inputEmit: EventEmitter<string> = new EventEmitter();
  @Output() onchange: EventEmitter<NullableTypedDataModelOrArray> = new EventEmitter<NullableTypedDataModelOrArray>();

  public selection: NullableTypedDataModelOrArray = undefined;
  public items$!: Observable<TypedDataModel[]>;
  public isReadOnly = false;
  public focusId: string | null = null;

  constructor(private readonly typeService: TypedDataService) {}

  private sortTypedDataModel(a: TypedDataModel, b: TypedDataModel) {
    if (a.valueDescription < b.valueDescription) {
      return -1;
    }
    if (a.valueDescription > b.valueDescription) {
      return 1;
    }
    return 0;
  }

  ngOnInit(): void {
    if (this.type) {
      this.items$ = this.typeService.getTypeByTypedData(this.type).pipe(
        map(res => [...res].sort(this.sortTypedDataModel)),
        first()
      );
    } else {
      this.items$ = EMPTY;
    }
  }
  compareByID(itemOne: TypedDataModel, itemTwo: TypedDataModel): boolean {
    return (itemOne && itemTwo && itemOne.valueId === itemTwo.valueId) || (!itemOne && !itemTwo);
  }
  get value(): NullableTypedDataModelOrArray {
    return this.selection;
  }
  set value(value: NullableTypedDataModelOrArray) {
    this.selection = value || undefined;
    this.onChange(value);
    this.onchange.emit(value);
  }
  get displayValue(): TypedDataModel[] {
    if (!this.value) {
      return [];
    }
    return Array.isArray(this.value) ? this.value : [this.value];
  }
  public onBlurEmit(): void {
    this.onTouched();
    this.blurEmit.next();
  }
  public onInputEmit(value: string): void {
    this.inputEmit.next(value);
  }
  onChange!: OnChangeFn<NullableTypedDataModelOrArray>;

  onTouched!: OnTouchFn;

  setDisabledState(isDisabled: boolean): void {
    this.isReadOnly = isDisabled;
  }
  registerOnChange(fn: OnChangeFn<NullableTypedDataModelOrArray>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchFn): void {
    this.onTouched = fn;
  }

  writeValue(value: NullableTypedDataModelOrArray): void {
    this.selection = value;
  }
}
