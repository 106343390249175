<app-header containerType="container-fluid" (goBack)="goback()" [moveElementDown]="bodyContainer">
  <div headerBody>
    <div class="row">
      <div class="col-md-8 col-xs-12 d-flex flex-row flex-wrap mt-2">
        <div class="mt-2">
          <span class="me-4 display-4" translate>operations.operation.title</span>
        </div>
        <div class="mt-2">
          <app-status size="sm" [status]="operation?.operationStatus"> </app-status>
        </div>
      </div>
      <div class="d-flex flex-row mb-0 mt-2 col-md-4 col-xs-12 justify-content-end" *ngIf="isEditMode">
        <button (click)="cancel()" class="btn btn-md btn-flat-primary" translate>common-buttons.cancel
        </button>
        <button (click)="save()" class="btn btn-md btn-info ms-3" translate>
          <em class="icon me-1">done</em>
          common-buttons.save
        </button>
      </div>
    </div>
    <div class="row mt-4 mb-4">
      <div class="col-md-8 col-xs-12 d-flex flex-row flex-wrap">
        <div class="col-md-2">
          <label class="text-secondary fw-medium" translate>operations.operation.header.isin</label>
          <div>
            <span class="fw-medium"> {{ operation?.isin }}</span>
          </div>
        </div>
        <div class="col-md-2 form-group">
          <label class="text-secondary fw-medium" translate>operations.operation.header.seriesNumber</label>
          <div>
            <span class="fw-medium">{{ operation?.seriesNumber }}</span>
          </div>
        </div>
        <div class="col-md-2 form-group">
          <label class="text-secondary fw-medium" translate>operations.operation.header.currency</label>
          <div>
            <span class="fw-medium">{{ operation?.currency }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xs-12 d-flex justify-content-end align-items-center w-100"
        *ngIf="(operation?.operationStatus?.valueDescription === initialized || operation?.operationStatus?.valueDescription === inRepair) && isConsult">
        <button class="btn btn-lg btn-flat-success" *ngIf="canUpdateStatusOperation"(click)="onUpdateStatusInstrumentClick('VALIDATE')">
          <span class="title-case">Validate</span>
        </button>
        <button class="btn btn-lg btn-flat-danger" *ngIf="canUpdateStatusOperation" (click)="onUpdateStatusInstrumentClick('CANCEL')">
          <span class="title-case">Cancel</span>
        </button>
        <button class="btn btn-lg btn-flat-info ms-3 btn-icon-start" *ngIf="canUpdateOperation" (click)="modify()">
          <em class="icon">mode_edit</em>
          <span class="title-case">Modify</span>
        </button>
      </div>
    </div>

  </div>
</app-header>
<div class="container-fluid content-margin-top bg-lvl2 pb-5" #bodyContainer>
  <div class="pt-4 pb-4 mx-auto col-md-12">
    <app-operation-form></app-operation-form>
  </div>
  <div class="row mt-2">
    <div class="col-md-12">
      <div *ngIf="isConsult;else edit_mode">
        <app-operation-items-consult [operationItems]="clientOperationItems"></app-operation-items-consult>
      </div>
      <ng-template #edit_mode>
        <app-operation-items-edit></app-operation-items-edit>
      </ng-template>
    </div>
  </div>
</div>
