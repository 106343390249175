/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormEventOptions, FieldInitialiser, FieldsNames } from './form.models';
import { UntypedFormBuilder, AbstractControlOptions, UntypedFormArray, UntypedFormGroup, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Forms } from '@shared/utils';
import { notEmpty } from '@utils/utility-functions';

type Controls<T> = { [k in keyof T]-?: AbstractControl };
export abstract class ArrayFormService<T> {
  private readonly internalFormMode: Forms.FormMode = 'add';
  private readonly internalFormArray: UntypedFormArray;
  private readonly internalFormGroup: UntypedFormGroup;
  private readonly fieldNames: FieldsNames<T>;
  private readonly defaultControls: Controls<T>;

  constructor(private readonly fb: UntypedFormBuilder, private readonly groupDefinition: () => FieldInitialiser<T>, private readonly groupOptions?: AbstractControlOptions | null | undefined) {
    this.internalFormArray = fb.array([]);
    this.internalFormGroup = fb.group({ data: this.internalFormArray });
    const defaultDef = groupDefinition();
    this.fieldNames = Object.keys(defaultDef).reduce((p, c) => ({ ...p, [c]: c }), {} as FieldsNames<T>);
    const defaultGroup = fb.group(defaultDef);
    this.defaultControls = Object.values<keyof T>(this.fieldNames).reduce((p, c) => ({ ...p, [c]: defaultGroup.get(c.toString()) }), {} as Controls<T>);
  }

  public reset() {
    this.internalFormArray.reset();
    while (this.internalFormArray.length !== 0) {
      this.internalFormArray.removeAt(0);
    }
  }

  public get formMode() {
    return this.internalFormMode;
  }

  public setFormMode(mode: Forms.FormMode) {
    if (mode === 'consult') {
      this.formArray.disable();
      this.formArray.controls.forEach(c => c.disable());
    } else {
      this.formArray.enable();
      this.formArray.controls.forEach(c => c.enable());
    }
  }

  public isRequired<K extends keyof T>(field: K) {
    if (this.internalFormMode === 'consult') {
      return false;
    }

    const control = this.defaultControls[field];

    const validator = control.validator;
    if (!validator) {
      return false;
    }
    const res = validator({} as AbstractControl);
    return 'required' in (res || {});
  }
  public get formGroup(): UntypedFormGroup {
    return this.internalFormGroup;
  }
  public get fields(): FieldsNames<T> {
    return this.fieldNames;
  }

  public get formArrayName(): 'data' {
    return 'data';
  }

  public get formArray() {
    return this.internalFormArray;
  }

  public get length(): number {
    return this.internalFormArray.length;
  }

  public addRow(data?: Partial<T>) {
    const idx = this.internalFormArray.length;

    const row = this.fb.group(this.groupDefinition(), this.groupOptions);
    if (data) {
      row.patchValue(data);
    }
    this.internalFormArray.insert(idx, row);
    return row;
  }

  public removeRow(idx: number, options?: FormEventOptions) {
    this.internalFormArray.removeAt(idx, options);
  }

  public get valid() {
    return this.internalFormGroup.valid;
  }
  public value(): T[] | null {
    if (!this.valid) {
      return null;
    }
    return this.internalFormArray.getRawValue();
  }

  public rawValue(): Nullable<T>[];
  public rawValue<K extends keyof T>(idx: number, field: K): T[K] | null;
  public rawValue<K extends keyof T>(idx?: number, field?: K): T[K] | null | Nullable<T>[] {
    if (notEmpty(idx) && field) {
      return this.internalFormArray.get([idx, field.toString()])?.value || null;
    }

    return this.internalFormArray.getRawValue();
  }

  public control<C extends AbstractControl = AbstractControl, K extends keyof T = keyof T>(idx: number, field: K): C {
    return this.internalFormArray.get([idx, field.toString()]) as C;
  }

  public valueChanges(): Observable<Nullable<T>[]>;
  public valueChanges(idx: number): Observable<Nullable<T>> | null;
  public valueChanges(idx?: number): Observable<Nullable<T>[]> | Observable<Nullable<T>> | null {
    if (notEmpty(idx)) {
      return this.internalFormArray.get([idx])?.valueChanges || null;
    }
    return this.internalFormArray.valueChanges;
  }
}
