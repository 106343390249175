import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import { HttpFeedbackComponent } from './components/http-feedback/http-feedback.component';
import { ModalComponent } from './components/modal/modal.component';
import { ClickOutsideDirective, FocusInvalidInputDirective, FocusToDirective, FractionalNumberDirective, MoveElementDownDirective } from './directives';
import { csrfInterceptorProvider } from './interceptor/csrf.interceptor';
import { globalErrorHandlerProvider } from './interceptor/global-error-handler';
import { httpErrorInterceptorProvider } from './interceptor/http-error.interceptor';
import { httpFeedbackInterceptorProvider } from './interceptor/httpFeedback.interceptor';
import { CustomAgGridModule } from './modules/custom-ag-grid/custom-ag-grid.module';
import { HttpFeedbackService } from './services/httpFeedback.service';
import { I18nUtilsService } from './services/i18n-utils.service';
import { PartyService } from './services/party.service';
import { ProgrammeService } from './services/programme.service';
import { TypedDataService } from './services/typed-data.service';
import { DebugDisplayComponent } from './components/debug-display/debug-display.component';
import { ToBoolPipe, TitleCasePipe, AmountFormatPipe } from './pipes';
import { ActiveFilterItemPipe } from './pipes/active-filter-item.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { LocationService } from './services/location.service';
import { CellStatusComponent } from './components/cell-status/cell-status.component';
import { StatusComponent } from './components/status/status.component';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { ModuleRegistry } from '@ag-grid-community/core';
import { AmountFormatCellComponent } from './components/amount-format-cell/amount-format-cell.component';
import { TimeFormatPipe } from './pipes/date-format/time-format.pipe';
import { DropdownMenuRendererComponent } from './components/dropdown-menu-renderer/dropdown-menu.renderer.component';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DateFormatPipe } from './pipes/date-format/date-format.pipe';
import { NumericEditorComponent } from '@instruments/components/instrument-list/_aggrid/custom-cell-editor/numeric-cell.component';
import { IcsdFormatComponent } from './components/icsd-format-cell/icsd-format-cell.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { DatePipe } from '@angular/common';
import { LinkCellRendererComponent } from './components/link-cell-renderer/link-cell-renderer.component';
import { PoolFactorContainerComponent } from './components/pool-factor-list/pool-factor-container/pool-factor-container.component';
import { AgGridModule } from 'ag-grid-angular';
import { YesNoLabelDirective } from './directives/yes-no-label.directive';

ModuleRegistry.registerModules([MasterDetailModule]);
@NgModule({
  declarations: [
    HttpFeedbackComponent,
    ModalComponent,
    DebugDisplayComponent,
    FocusInvalidInputDirective,
    FocusToDirective,
    FractionalNumberDirective,
    ClickOutsideDirective,
    ToBoolPipe,
    TitleCasePipe,
    MoveElementDownDirective,
    ActiveFilterItemPipe,
    AmountFormatPipe,
    FileSizePipe,
    TimeFormatPipe,
    CellStatusComponent,
    StatusComponent,
    AmountFormatCellComponent,
    DropdownMenuRendererComponent,
    DateFormatPipe,
    NumericEditorComponent,
    IcsdFormatComponent,
    NoDataComponent,
    LinkCellRendererComponent,
    PoolFactorContainerComponent,
    YesNoLabelDirective
  ],
  imports: [BrowserModule, TranslateModule, FormsModule, RouterModule, AgGridModule.withComponents([]), BsDropdownModule.forRoot()],
  exports: [
    HttpFeedbackComponent,
    ModalComponent,
    DebugDisplayComponent,
    CustomAgGridModule,
    FocusInvalidInputDirective,
    FocusToDirective,
    ClickOutsideDirective,
    FractionalNumberDirective,
    ToBoolPipe,
    TitleCasePipe,
    ActiveFilterItemPipe,
    FileSizePipe,
    MoveElementDownDirective,
    AmountFormatPipe,
    CellStatusComponent,
    StatusComponent,
    AmountFormatCellComponent,
    TimeFormatPipe,
    DateFormatPipe,
    NumericEditorComponent,
    IcsdFormatComponent,
    NoDataComponent,
    LinkCellRendererComponent,
    PoolFactorContainerComponent,
    YesNoLabelDirective
  ],
  providers: [LocationService, DatePipe]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [csrfInterceptorProvider, httpFeedbackInterceptorProvider, httpErrorInterceptorProvider, globalErrorHandlerProvider, HttpFeedbackService, ProgrammeService, PartyService, TypedDataService, I18nUtilsService]
    };
  }
}

// https://alligator.io/angular/providers-shared-modules/
