import { DocumentVersionIdentifierModel, toDocumentVersionIdentifierModel } from './document-version-identifier.model';
import { DocumentContentModel, toDocumentContentModel } from './document-content.model';
import { ResultDoc, Doc } from '@apis/backend/instruments';
import { stringToDate, notEmpty } from '@utils/utility-functions';
import { ConfidentialityLevel, isConfidentialityLevel } from './types';
export interface DocumentFileModel {
  readonly documentVersionIdentifier: DocumentVersionIdentifierModel;
  readonly confidentialityLevel: ConfidentialityLevel;
  readonly date: Date | null;
  readonly metadata: Record<string, unknown>;
  readonly content: DocumentContentModel | null;
  readonly file: Blob | null;
  readonly contentType: string | null;
}

export const toDocumentFile = (dto: Doc | null | undefined): DocumentFileModel | null => {
  if (!dto) {
    return null;
  }

  return {
    documentVersionIdentifier: toDocumentVersionIdentifierModel(dto.documentVersionIdentifier), //Document is supposed to have version
    confidentialityLevel: isConfidentialityLevel(dto.confidentialityLevel) ? dto.confidentialityLevel : 'C2',
    date: stringToDate(dto.date),
    metadata: { ...dto.metadata },
    content: toDocumentContentModel(dto.content),
    file: dto.file || null,
    contentType: dto.contentType || null
  };
};

export const toDocumentFiles = (dto: ResultDoc | null | undefined): DocumentFileModel[] => {
  if (!dto) {
    return [];
  }
  return (dto.resources || []).map(toDocumentFile).filter(notEmpty);
};
