import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DisposalEditColumnDefService } from '@instruments/components/instrument-list/_aggrid/disposal-edit-column-def-service';
import { DisposalItemModel } from '@instruments/models/disposal.model';
import { ManagementCommonDepositoryFormService } from '@instruments/services/forms/managmement-common-depository.form.service';
import { TypedDataModel } from '@shared/models';
import { EditingDatePickerComponent } from '@shared/modules/custom-aggrid-editing/editing-date-picker/editing-date-picker.component';
import { CellValueChangedEvent, ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';

@Component({
  selector: 'app-disposal-items-edit',
  templateUrl: './disposal-items-edit.component.html',
  providers: [DisposalEditColumnDefService]
})
export class DisposalItemsEditComponent implements OnInit {
  public gridOptions!: GridOptions;
  public columnDefs: ColDef[] = [];
  public defaultColDef: unknown;
  public gridApi!: GridApi;
  @Input() disposalsItems?: DisposalItemModel[] | null = [];

  private referenceData: Record<string, TypedDataModel[]> = {};

  constructor(private readonly disposalEditColumnDefService: DisposalEditColumnDefService, public readonly formService: ManagementCommonDepositoryFormService, private readonly activeRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.referenceData = this.activeRoute.snapshot.data['typeReferenceData'] as Record<string, TypedDataModel[]>;
    this.formService.patch('disposalItems', this.disposalsItems);
    this.columnDefs = this.disposalEditColumnDefService.init(this.referenceData['SETTLEMENT_CLEARING_SUBSCRIBER']);

    this.gridOptions = {
      pagination: false,
      suppressPaginationPanel: false,
      paginationPageSize: 10,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      suppressContextMenu: true,
      stopEditingWhenCellsLoseFocus: true,
      frameworkComponents: {
        agDateInput: EditingDatePickerComponent
      }
    };
    this.defaultColDef = {
      sortable: false,
      filter: false,
      suppressMenu: true
    };
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.refreshHeader();

    const body = document.querySelector('body');
    if (body) {
      this.gridApi.setPopupParent(body);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onCellValueChanged(e: CellValueChangedEvent): void {
    const rowData: DisposalItemModel[] = [];
    this.gridApi.forEachNode(node => {
      rowData.push(node.data);
    });
    this.formService.patch('disposalItems', rowData);
  }

  add(): void {
    this.gridApi.applyTransaction({
      add: [{}]
    });
  }
}
