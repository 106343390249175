import { Component, Input } from '@angular/core';
import { ProgrammeInformation } from '@shared/models';
import { Forms } from '@shared/utils';

@Component({
  selector: 'app-programme-information',
  templateUrl: './programme-information.component.html'
})
export class ProgrammeInformationComponent {
  @Input() programmeInformation: ProgrammeInformation | null = null;
  @Input() formMode: Forms.FormMode = 'consult';
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}
