import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ProgrammePagingCriteriaModel } from '@programmes/models/progamme-paging-criteria.model';
import { FormService } from '@shared/services/forms/form.service';

@Injectable()
export class ProgrammePagingCriteriaSearchFormService extends FormService<ProgrammePagingCriteriaModel> {
  constructor(fb: UntypedFormBuilder) {
    super('paging_search_programme', fb, {
      pageNumber: fb.control(1),
      totalItems: fb.control(0),
      itemsByPage: fb.control(10)
    });
  }
}
