import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TypedDataModel, TYPED_DATA_TYPE } from '@shared/models';
import { TypedDataService } from '@shared/services/typed-data.service';
import { EMPTY, Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

type NullableTypedDataModelOrArray = TypedDataModel[] | TypedDataModel | undefined;

/*

USAGE EXAMPLE :

<app-type-multiple-select [multiple]="true" [validators]="validators" type="LISTING_COUNTRY" [placeholder]="'placeholder'">
</app-type-multiple-select>

*/

@Component({
  selector: 'app-type-multiple-select',
  templateUrl: './type-multiple-select-v2.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TypeMultipleSelectV2Component),
      multi: true
    }
  ]
})
export class TypeMultipleSelectV2Component implements ControlValueAccessor, OnInit {
  @Input() multiple = false;

  @Input() closeOnSelect = false;

  @Input() withDebounce = false;
  @Input() type: TYPED_DATA_TYPE | undefined = undefined;
  @Input() placeholder = '';
  @Input() icon = '';
  @Input() defaultValue: string | null = null;
  @Input() withSummary = false;
  @Input() hasError = false;
  @Input() maxDisplayedItems!: number;
  @Output() blurEmit: EventEmitter<void> = new EventEmitter();
  @Output() onchange: EventEmitter<NullableTypedDataModelOrArray> = new EventEmitter<NullableTypedDataModelOrArray>();
  @Output() inputEmit: EventEmitter<string> = new EventEmitter();
  public items$!: Observable<TypedDataModel[]>;
  public isReadOnly = false;
  public focusId: string | null = null;
  @Input() filters: string[] | null = null;

  constructor(private readonly typeService: TypedDataService) {}

  private sortTypedDataModel(a: TypedDataModel, b: TypedDataModel) {
    if (a.valueDescription < b.valueDescription) {
      return -1;
    }
    if (a.valueDescription > b.valueDescription) {
      return 1;
    }
    return 0;
  }

  ngOnInit(): void {
    if (this.type) {
      this.items$ = this.typeService.getTypeByTypedData(this.type).pipe(
        map((res: TypedDataModel[]) => (this.filters ? res.filter((td: TypedDataModel) => this.filters?.includes(td.valueDescription)) : res).sort(this.sortTypedDataModel)),
        first(),
        tap((res: TypedDataModel[]) => {
          if (this.defaultValue && !this.isReadOnly) {
            this.value = res.find(q => q.valueDescription === this.defaultValue);
          }
        })
      );
    } else {
      this.items$ = EMPTY;
    }
  }

  private _value: NullableTypedDataModelOrArray = undefined;
  get value(): NullableTypedDataModelOrArray {
    return this._value;
  }

  set value(value: NullableTypedDataModelOrArray) {
    if (value && Array.isArray(value) && !this.multiple) {
      this._value = value[0];
    } else {
      this._value = value || undefined;
    }
    this.onChange(value);
    this.onchange.emit(value);
  }

  get displayValue(): TypedDataModel[] {
    if (!this.value) {
      return [];
    }
    return Array.isArray(this.value) ? this.value : [this.value];
  }

  public onBlurEmit(): void {
    this.onTouched();
    this.blurEmit.next();
  }

  public onInputEmit(value: string): void {
    this.inputEmit.next(value);
  }

  onChange!: OnChangeFn<NullableTypedDataModelOrArray>;

  onTouched!: OnTouchFn;

  setDisabledState(isDisabled: boolean): void {
    this.isReadOnly = isDisabled;
  }

  registerOnChange(fn: OnChangeFn<NullableTypedDataModelOrArray>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchFn): void {
    this.onTouched = fn;
  }

  writeValue(value: NullableTypedDataModelOrArray): void {
    this._value = value;
  }
}
