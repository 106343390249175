import { Component } from '@angular/core';
import { LabelRootTranslateInjectionToken, FormServiceInjectionToken } from '@shared/modules/forms/tokens';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { LocationService } from '@shared/services/location.service';
import { Router, ActivatedRoute } from '@angular/router';
import { routeToEcashPayments } from '../../routes';
import { combineLatest, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { Forms } from '@shared/utils';
import { EcashPaymentsDetailFormService } from '../../services';
import { ECashPaymentsDetailModel } from '../../models';

@Component({
  selector: 'app-e-cash-payments-detail',
  templateUrl: './e-cash-payments-detail.component.html',
  providers: [
    { provide: FormServiceInjectionToken, useExisting: EcashPaymentsDetailFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'eCashPayments.form' }
  ]
})
export class ECashPaymentsDetailComponent extends BaseFormComponent<ECashPaymentsDetailModel, EcashPaymentsDetailFormService> {
  public shutdown$ = new Subject<void>();

  constructor(private readonly eCashPaymentsDetailFormService: EcashPaymentsDetailFormService, private readonly router: Router, private readonly activatedRoute: ActivatedRoute, private readonly locationService: LocationService) {
    super(eCashPaymentsDetailFormService, 'eCashPayments.detail');
  }

  ngOnInit(): void {
    combineLatest([this.activatedRoute.data.pipe(startWith(...[])), this.activatedRoute.params.pipe(startWith(...[]))])
      .pipe(takeUntil(this.shutdown$))
      .subscribe(([routeData, routeParams]) => {
        const eCashPaymentsData = routeData['eCashPayments'] || null;
        const pageType = Forms.isFormMode(routeParams.pageType) ? routeParams.pageType : 'consult';
        this.eCashPaymentsDetailFormService.setFormMode(pageType);
        if (pageType === 'consult') {
          this.eCashPaymentsDetailFormService.setInitialValue(eCashPaymentsData);
        }
      });
  }

  onBackClick() {
    if (this.locationService.navgationHistories.length >= 1) {
      this.router.navigateByUrl(this.locationService.getBackUrl());
      return;
    }
    this.router.navigate(routeToEcashPayments());
  }
}
