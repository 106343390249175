import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { InstrumentForms, RoleDataModel } from '@instruments/models';
import { FormService } from '@shared/services/forms/form.service';

@Injectable()
export class RoleDataFormService extends FormService<RoleDataModel, InstrumentForms> {
  constructor(fb: UntypedFormBuilder) {
    super('roleData', fb, {
      issuingAgent: fb.control(null, Validators.required),
      principalPayingAgent: fb.control(null, Validators.required),
      commonDepositary: fb.control(null),
      registrar: fb.control(null),
      commonServiceProvider: fb.control(null),
      commonSafeKeeper: fb.control(null),
      fiscalAgent: fb.control(null),
      localPayingAgent: fb.control(null),
      listingAgent: fb.control(null),
      calculationAgent: fb.control(null),
      deskCalculationAgent: fb.control(null),
      transferAgent: fb.control(null),
      cashManager: fb.control(null),
      escrowManager: fb.control(null),
      potentialEurosystemEligibility: fb.control(false, Validators.required)
    });
  }
}
