import { Component, Input } from '@angular/core';
import { IpaTypeModel } from '@shared/models';
import { notEmpty, replaceAll } from '@utils/utility-functions';

type NullableIpaModelOrString = IpaTypeModel | string | undefined | null;

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent {
  @Input() status: NullableIpaModelOrString;
  @Input() size: DesignSystemSize | null = null;

  public get statusDescription(): string | undefined | null {
    // eslint-disable-next-line no-useless-escape
    const re = /\_/gi;
    if (typeof this.status === 'string') {
      return replaceAll(this.status, re, ' ');
    } else {
      return replaceAll(this.status?.valueDescription ?? '', re, ' ');
    }
  }

  public get class(): string[] {
    if (typeof this.status === 'string') {
      this.status = this.status.trim();
      const formattedStatus = this.status.replace(/\s/g, '-');
      return ['badge', 'rounded-pill', `badge-discreet-${formattedStatus.toLocaleLowerCase()}`, this.size ?? `badge-${this.size}`].filter(notEmpty);
    } else {
      return ['badge', 'rounded-pill', `badge-discreet-${this.status?.valueDescription?.replace(/\s/g, '-').toLocaleLowerCase()}`, this.size ?? `badge-${this.size}`].filter(notEmpty);
    }
  }
}
