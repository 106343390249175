import { UntypedFormGroup } from '@angular/forms';
import { FieldsNames } from '@shared/services/forms/form.models';
import { FormService } from '@shared/services/forms/form.service';
import { Forms } from '@shared/utils';

export abstract class BaseFormComponent<U, T extends FormService<U>> {
  constructor(public readonly formService: T, private readonly placeholderTranslateBase: string = '') {}

  public get formGroup(): UntypedFormGroup {
    return this.formService.formGroup;
  }

  public get fields(): FieldsNames<U> {
    return this.formService.fields;
  }
  public placeholder(key: keyof U): string {
    return `${this.placeholderTranslateBase}.${key.toString()}`;
  }

  public get hasError() {
    return this.formService.hasError.bind(this.formService);
  }
  public get formMode(): Forms.FormMode {
    return this.formService.formMode;
  }

  public get isAddOrEdit(): boolean {
    return this.formMode !== 'consult';
  }
  public get isConsult(): boolean {
    return this.formMode === 'consult';
  }

  public get isAddMode(): boolean {
    return this.formMode === 'add';
  }
  public get isEditMode(): boolean {
    return this.formMode === 'edit';
  }
}
