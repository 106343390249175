import { IpaTypeModel } from '@shared/models';
import { ValuationDTO, ValuationRequestDTO } from '@apis/backend/events';

export interface ValuationModel {
  eventRef?: number;
  valuationId?: number;
  isinCode?: string;
  eventType?: string;
  redemptionType?: IpaTypeModel;
  redemptionTypeDescription?: string;
  denomination?: number;
  denominationCCY?: string;
  paymentCCY?: string;
  valueDate?: string;
  valuationDate?: string;
  receiptDate?: string;
  interestRate?: number;
  dayCountFraction?: string;
  redemptionRate?: number;
  underlyingISIN?: string;
  nominal?: number;
  valuationStatusDescription?: IpaTypeModel | string;
  valuationVersion?: string;
  rejectionReason?: string;
  comments?: string;
}

export const toValuationModel = (valuationDetails: ValuationDTO): ValuationModel | null => {
  if (!valuationDetails) {
    return null;
  }

  const valuationData = valuationDetails?.valuation;
  if (valuationData) {
    return {
      valuationId: valuationData.valuationId,
      eventRef: valuationDetails.eventReference,
      isinCode: valuationData.isinCode,
      eventType: valuationData.eventType?.valueDescription,
      redemptionType: valuationData?.redemptionType,
      redemptionTypeDescription: valuationData?.redemptionType?.valueDescription,
      denomination: valuationData.denomination,
      denominationCCY: valuationData.nominalCurrency,
      paymentCCY: setPaymentCCY(valuationData.eventType?.valueDescription, valuationData),
      valueDate: setValueDate(valuationData.eventType?.valueDescription, valuationData),
      valuationDate: valuationData.valuationDate,
      receiptDate: valuationDetails.timestampCreate,
      interestRate: valuationData.couponRate,
      dayCountFraction: valuationData.dayCountFraction?.valueDescription,
      redemptionRate: valuationData.redemptionRate,
      underlyingISIN: valuationData.underlyingISIN,
      nominal: valuationData.nominal,
      rejectionReason: valuationDetails.rejectReason,
      comments: valuationData.remarks,
      valuationVersion: valuationData.valuationVersion,
      valuationStatusDescription: valuationDetails.status?.valueDescription
    };
  }
  return null;
};

const setPaymentCCY = (eventType: string | undefined, valuation: ValuationRequestDTO): string | undefined => {
  let paymentCCY: string | undefined;
  switch (eventType) {
    case 'INTR':
      paymentCCY = valuation.interestCurrency || undefined;
      break;
    case 'PRED':
    case 'REDM':
    case 'MCAL':
      paymentCCY = valuation.redemptionCurrency;
      break;
  }
  return paymentCCY;
};

const setValueDate = (eventType: string | undefined, valuation: ValuationRequestDTO): string | undefined => {
  let valueDate: string | undefined = '';
  switch (eventType) {
    case 'INTR':
      valueDate = valuation.interestDate;
      break;
    case 'PRED':
    case 'REDM':
    case 'MCAL':
      valueDate = valuation.rofPaymentDate;
      break;
  }
  return valueDate;
};
