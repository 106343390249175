/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PartyDto } from './partyDto';


export interface PositionDataFetchDto { 
    ipaCode?: number;
    csd?: PartyDto;
    depositary?: PartyDto;
    currency?: string;
    isinCode?: string;
    seriesNumber?: string;
    valueDate?: string;
    accountNumber?: string;
    unBlockValue?: string;
    blockValue?: string;
    totalValue?: string;
}

