import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartyModel } from '@shared/models';
import { OperationFormContainerComponent } from './operation-form-container/operation-form-container.component';

@Component({
  selector: 'app-add-operation',
  templateUrl: './add-operation.component.html'
})
export class AddOperationComponent {
  @Input() clearings: PartyModel[] = [];
  @Input() disableNewOperation = false;

  constructor(private readonly modalService: NgbModal) {}
  onCreateOperationClick(): void {
    const modal = this.modalService.open(OperationFormContainerComponent, { size: 'lg', scrollable: true });
    const dialog: OperationFormContainerComponent = modal.componentInstance;
    dialog.clearings = this.clearings;
    modal.result.then((res: any) => {
      if (res.reason !== 'confirm') {
        return;
      }
    });
  }
}
