import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SgPaginationComponent } from './sg-pagination/sg-pagination.component';

@NgModule({
  declarations: [SgPaginationComponent],
  exports: [SgPaginationComponent],
  imports: [CommonModule]
})
export class PaginationModule {}
