import { MovementDto } from '@apis/backend/instruments';
import { IpaTypeModel } from '@shared/models';

export interface MouvementModel {
  operation: IpaTypeModel | undefined;
  valueDate: string | undefined;
  value: number | undefined;
  type: string | undefined;
  status: IpaTypeModel | undefined;
  direction: string | undefined;
}
export const toMouvementModel = (dto: MovementDto): MouvementModel => {
  return {
    type: dto.type,
    valueDate: dto.valueDateAsString,
    direction: dto.direction,
    value: dto.value,
    operation: dto.clientOperationDetailDto?.type,
    status: dto.clientOperationDetailDto?.status
  };
};
