<div class="section-forms" [formGroup]="formGroup">
  <div class="d-flex mb-4">

    <div class="me-2">
      <div class="input-group input-group-md">
        <div class="input-icon-start">
          <em class="icon text-secondary">search</em>
        </div>
        <input placeholder="{{ placeholder(fields.icsd)  | translate }}" #icsd component [formControlName]="fields.icsd"
          class="form-control form-control-md">
      </div>
    </div>

    <div class="me-2">
      <div class="input-group input-group-md">
        <div class="input-icon-start">
          <em class="icon text-secondary">search</em>
        </div>
        <input placeholder="{{ placeholder(fields.programmeName)  | translate }}" #isin component
          [formControlName]="fields.programmeName" class="form-control form-control-md">
      </div>
    </div>

    <div class="me-1">
      <app-type-single-select #status component [formControlName]="fields.status" [multiple]="false"
        classes="form-control-md" [closeOnSelect]="true" [datas]="statuses" [hasError]="hasError(fields.status)"
        placeholder="{{ placeholder(fields.status)  | translate }}">
      </app-type-single-select>
    </div>

    <div class="me-2 col-md-5">
      <app-party-multiple-select #issuerNamesInput component [formControlName]="fields.issuerNames" [multiple]="true"
        [type]="'ISSUER'" [filter]="'PROGRAMME'" [hasError]="hasError('issuerNames')" [withSummary]="true"
        placeholder="{{ placeholder(fields.issuerNames) | translate }}">
      </app-party-multiple-select>
    </div>
    <div class="ms-auto">
      <button class="btn btn-md btn-flat-primary btn-icon-start me-1" [disabled]="disableExportCsv" (click)="exportCsv()" type="button" *ngIf="canExport"
        translate>
        <em class="icon">save_alt</em> common-buttons.CSV-export
      </button>
      <ng-content select="[searchTemplate]"></ng-content>
    </div>
  </div>

  <div class="row mt-2 mb-4">
    <div class="col-md-10">
      <app-programme-list-active-filters (filtersChanged)="onFiltersChanged($event)">
      </app-programme-list-active-filters>
    </div>
  </div>
</div>
