<div class="section-forms container g-0" [formGroup]="formGroup">
  <h5 class="mb-4" translate>instruments.instrument.forms.programmeCharacteristics.sectionName</h5>

  <div class="row gy-4 mt-1" *ngIf="isAddOrEdit">
    <div class="col-md-6">
      <app-form-item [focus]="leiCodeSelect" [field]="fields.issuerLeiCode">
        <app-lei-multiple-select
          #leiCodeSelect
          [formControlName]="fields.issuerLeiCode"
          [multiple]="false"
          [closeOnSelect]="true"
          [hasError]="hasError('issuerLeiCode')"
          [placeholder]="placeholder(fields.issuerLeiCode) | translate"
          (issuer)="onIssuerSelected($event)"
          (programmeInformation)="onProgrammeInformationSelected($event)"
          component
        >
        </app-lei-multiple-select>
      </app-form-item>
    </div>
  </div>

  <div *ngIf="loadingLeiInformation$ | async; else programmeInformation" class="loading-spinner small text-center">
    <span>⭮</span>
  </div>
  <ng-template #programmeInformation>
    <app-programme-information [formMode]="formMode" [programmeInformation]="programmeInformation$ | async"> </app-programme-information>
  </ng-template>
  <hr class="mb-5 mt-5" />
  <div class="row gy-4">
    <div class="col-md-6" *ngIf="hasIcsdProgrammeNumber">
      <app-form-item [focus]="icsdProgrammeNumber" [field]="fields.icsdProgrammeNumber">
        <input component #icsdProgrammeNumber maxlength="10" [formControlName]="fields.icsdProgrammeNumber" type="text" class="form-control" placeholder="{{ placeholder(fields.icsdProgrammeNumber) | translate }}" />
      </app-form-item>
    </div>

    <div class="col-md-6" *ngIf="hasInternalProgrammeNumber">
      <app-form-item [focus]="internalProgrammeNumber" [field]="fields.internalProgrammeNumber">
        <input component #internalProgrammeNumber maxlength="10" [formControlName]="fields.internalProgrammeNumber" type="text" class="form-control" placeholder="{{ placeholder(fields.internalProgrammeNumber) | translate }}" />
      </app-form-item>
    </div>
  </div>

  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item [focus]="clearingSystemsInput" [field]="fields.clearingSystems">
        <app-party-multiple-select
          #clearingSystemsInput
          component
          [formControlName]="fields.clearingSystems"
          [multiple]="true"
          [type]="CLEARING_SYSTEM"
          [filter]="INSTRUMENT"
          [hasError]="hasError('clearingSystems')"
          placeholder="{{ placeholder(fields.clearingSystems) | translate }}"
        >
        </app-party-multiple-select>
      </app-form-item>
    </div>
  </div>

  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item [focus]="isinReference" [field]="fields.isinReference">
        <input #isinReference maxlength="12" [formControlName]="fields.isinReference" component type="text" class="form-control" placeholder="{{ placeholder(fields.isinReference) | translate }}" />
      </app-form-item>
    </div>

    <div class="col-md-6">
      <app-form-item [focus]="commonCode" [field]="fields.commonCode" [params]="{ maxlength: 9, type: 'numbers' }">
        <input #commonCode appFractional numberType="integer" maxlength="9" [formControlName]="fields.commonCode" type="text" component class="form-control" placeholder="{{ placeholder(fields.commonCode) | translate }}" />
      </app-form-item>
    </div>
  </div>

  <hr class="mb-5 mt-5" />

  <div class="row gy-4">
    <div class="col-md-6">
      <app-form-item [focus]="instrumentTypeInput" [field]="fields.instrumentType">
        <app-type-multiple-select
          #instrumentTypeInput
          component
          [formControlName]="fields.instrumentType"
          [multiple]="false"
          [closeOnSelect]="true"
          [type]="INSTRUMENT_TYPE"
          [hasError]="hasError(fields.instrumentType)"
          placeholder="{{ placeholder(fields.instrumentType) | translate }}"
        >
        </app-type-multiple-select>
      </app-form-item>
    </div>

    <div class="col-md-6">
      <app-form-item [focus]="upto" [field]="fields.upto">
        <app-yes-no-buttons [formControlName]="fields.upto" #upto component></app-yes-no-buttons>
      </app-form-item>
    </div>
  </div>

  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item [focus]="compartmentNumber" [field]="fields.compartmentNumber">
        <app-number #compartmentNumber component [formControlName]="fields.compartmentNumber" [formatThousandSeparator]="false" placeholder="{{ placeholder(fields.compartmentNumber) | translate }}"> </app-number>
      </app-form-item>
    </div>
    <div class="col-md-6">
      <app-form-item [focus]="seriesNumber" [field]="fields.seriesNumber">
        <app-number #seriesNumber [formControlName]="fields.seriesNumber" component [formatThousandSeparator]="false" placeholder="{{ placeholder(fields.seriesNumber) | translate }}"></app-number>
      </app-form-item>
    </div>
  </div>

  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item [focus]="name" [field]="fields.name">
        <input #name maxlength="255" [formControlName]="fields.name" type="text" component class="form-control" placeholder="{{ placeholder(fields.name) | translate }}" />
      </app-form-item>
    </div>

    <div class="col-md-6">
      <app-form-item [focus]="classNumber" [field]="fields.classNumber">
        <input #classNumber maxlength="30" [formControlName]="fields.classNumber" component type="text" class="form-control" placeholder="{{ placeholder(fields.classNumber) | translate }}" />
      </app-form-item>
    </div>
  </div>

  <hr class="mb-5 mt-5" />

  <div class="row gy-4">
    <div class="col-md-6">
      <app-form-item [focus]="finalTermReceived" [field]="fields.finalTermReceived">
        <app-yes-no-buttons componet #finalTermReceived [formControlName]="fields.finalTermReceived" component> </app-yes-no-buttons>
      </app-form-item>
    </div>

    <div class="col-md-6">
      <app-form-item [focus]="legalDeskIssuer" [field]="fields.legalDeskIssuer">
        <app-type-multiple-select
          #legalDeskIssuer
          component
          [formControlName]="fields.legalDeskIssuer"
          [multiple]="false"
          [closeOnSelect]="true"
          [type]="LEGAL_DESK_ISSUER_COUNTRY"
          [bindLabelProperty]="'valueDescription'"
          [hasError]="hasError('legalDeskIssuer')"
          placeholder="{{ placeholder(fields.legalDeskIssuer) | translate }}"
        >
        </app-type-multiple-select>
      </app-form-item>
    </div>
  </div>

  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item [focus]="complianceRestriction" [field]="fields.complianceRestriction">
        <app-yes-no-buttons componet #complianceRestriction [formControlName]="fields.complianceRestriction" component> </app-yes-no-buttons>
      </app-form-item>
    </div>

    <div class="col-md-6">
      <app-form-item [focus]="paymentDefault" [field]="fields.paymentDefault">
        <app-yes-no-buttons component #paymentDefault [formControlName]="fields.paymentDefault"></app-yes-no-buttons>
      </app-form-item>
    </div>
  </div>

  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item [focus]="eligibility871m" [field]="fields.eligibility871m">
        <app-yes-no-buttons component #eligibility871m [formControlName]="fields.eligibility871m"></app-yes-no-buttons>
      </app-form-item>
    </div>

    <div class="col-md-6">
      <app-form-item [focus]="eligibilitySrd2" [field]="fields.eligibilitySrd2">
        <app-yes-no-buttons component #eligibilitySrd2 [formControlName]="fields.eligibilitySrd2"></app-yes-no-buttons>
      </app-form-item>
    </div>
  </div>

  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item [focus]="labelCategory" [field]="fields.labelCategory">
        <app-type-multiple-select
          #labelCategory
          component
          [formControlName]="fields.labelCategory"
          [multiple]="false"
          [closeOnSelect]="true"
          [type]="LABEL_CATEGORY"
          [bindLabelProperty]="'valueDescription'"
          defaultValue="NO LABEL"
          [hasError]="hasError('labelCategory')"
          placeholder="{{ placeholder(fields.labelCategory) | translate }}"
        >
        </app-type-multiple-select>
      </app-form-item>
    </div>
  </div>
</div>
