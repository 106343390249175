export interface EventPositionInputModel {
  eventId?: number;
  isInternationalISIN: boolean;
  isRedemptionInShares: boolean;
  outstandingNominal: string | undefined;
  isSGLuxNotCommonDepOrServiceProvider: boolean;
  tradingType: string;
  enableReleasePayment?: boolean;
  enableGeneratePreadvise?: boolean;
  enableGenerateRequestOfFunds?: boolean;
  eventTypeValue: string;
  downloadRof?: string | null;
  isQtyUpdated: boolean | undefined;
  paymentDate?: Date;
  fundArrivalDate?: Date;
  paymentCcy?: string[];
  eventStatus?: number;
  eocAdjustedQuantity?: number;
  clsAdjustedQuantity?: number;
  totalAdjustedQuantity?: number;
}
