import { IpaTypeModel } from '@shared/models';

export interface PositionsGridModel {
  readonly systemType?: string;
  readonly positionNominal?: number;
  readonly positionUnits?: number;
  readonly quantity?: number | null;
  readonly adjustedQuantity?: number | null;
  readonly nonEligibleNominal?: number;
  readonly nonEligibleUnits?: number;
  readonly eligibleNominal?: number;
  readonly eligibleUnits?: number;
  readonly amount?: number;
  readonly nbrUnderlying?: number;
  readonly interventionType?: IpaTypeModel;
  readonly isEditable?: boolean;
  readonly totalNominal?: number;
  readonly totalUnits?: number;
  readonly positionToCertify?: number;
  readonly blockedQuantity?: number;
  readonly quantityToUnblock?: number;
  readonly semeReference?: string;
  readonly isClsPaid?: boolean;
  readonly isEocPaid?: boolean;
  readonly currency?: string[];
  readonly paymentDate?: Date;
  readonly fundArrivalDate?: Date;
  readonly eventType?: string;
  readonly eventStatus?: number;
  readonly eocAdjustedQuantity?: number;
  readonly clsAdjustedQuantity?: number;
}
