import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { InstrumentCriteriaSearchModel } from '@instruments/models/instrument-criteria-search.model';
import { FormService } from '@shared/services/forms/form.service';

@Injectable()
export class InstrumentCriteriaSearchFormService extends FormService<InstrumentCriteriaSearchModel> {
  constructor(fb: UntypedFormBuilder) {
    super('search_instrument', fb, {
      ipaNumber: fb.control(null),
      issuers: fb.control(null),
      isin: fb.control(null),
      icsd: fb.control(null),
      serialNumber: fb.control(null),
      instrumentType: fb.control(null),
      status: fb.control(null),
      finalTermReceived: fb.control(null),
      issuerPerimter: fb.control(null),
      beginIssuedate: fb.control(null),
      endIssuedate: fb.control(null),
      instrumentName: fb.control(null),
      paymentType: fb.control(null),
      settlementCurrency: fb.control(null),
      settlementStatus: fb.control(null),
      withoutSettlement: fb.control(null),
      cdFtReceived: fb.control(null),
      cdGnReceived: fb.control(null),
      disposalMonitoring: fb.control(null),
      permanentMonitoring: fb.control(null)
    });
  }
}
