import { forkJoin, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { TypedDataModel, TYPED_DATA_TYPE } from '@shared/models';
import { TypedDataService } from '@shared/services/typed-data.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TypedDatasResolve implements Resolve<Record<string, TypedDataModel[]>> {
  constructor(private readonly typeService: TypedDataService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Record<string, TypedDataModel[]>> {
    const types: TYPED_DATA_TYPE[] = route.data.types;
    const obs = types.map(type => {
      return this.typeService.getTypeByTypedData(type).pipe(
        map(r => {
          return { key: type, result: r };
        })
      );
    });

    return forkJoin(obs).pipe(
      map(r => {
        const result: Record<string, TypedDataModel[]> = {};
        r.forEach(e => {
          result[e.key] = e.result;
        });
        return result;
      })
    );
  }
}
