import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { RegenerateSchedulesFormService } from '@events/services/regenerateSchedules.form.service';

@Component({
  selector: 'app-event-regenerate-form',
  templateUrl: './event-regenerate-form.component.html'
})
export class EventRegenerateFormComponent {
  public ClosedReason: DialogResult = 'closed';
  public CancelReason: DialogResult = 'cancel';
  public ConfirmReason: DialogResult = 'confirm';
  private readonly subscriptions: Subscription[] = [];

  confirmButton = 'modals.buttons.confirm';
  cancelButton = 'modals.buttons.cancel';
  @Input() set isin(value: string | null | undefined) {
    this.regenerateSchedulesFormService.reset();
    if (value) {
      this.regenerateSchedulesFormService.patchField('isin', value);
    }
  }

  constructor(private readonly activeModal: NgbActiveModal, private readonly regenerateSchedulesFormService: RegenerateSchedulesFormService) {}

  public onClose(): void {
    this.activeModal.dismiss(this.ClosedReason);
    this.activeModal.close(this.ClosedReason);
  }

  public onCancel(): void {
    this.activeModal.close(this.CancelReason);
  }

  public onConfirm(): void {
    this.regenerateSchedulesFormService.updateValueAndValidity();
    if (this.regenerateSchedulesFormService.valid) {
      this.activeModal.close(this.ConfirmReason);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
