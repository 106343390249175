/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, ElementRef, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { PartyService } from '@shared/services/party.service';
import { ProgrammeInformation, ROLES_TYPE, TYPE_FILTER_TYPE, PartyModel } from '@shared/models';
import { ProgrammeFormService } from '@programmes/services';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { ProgrammeModel } from '@shared/models/programme.model';
import { routeToProgramme } from '@instruments/routes';

@Component({
  selector: 'app-programme-forms',
  templateUrl: './programme-forms.component.html',
  styleUrls: ['./programme-forms.component.scss']
})
export class ProgrammeFormsComponent extends BaseFormComponent<ProgrammeModel, ProgrammeFormService> implements OnInit, OnDestroy {
  @ViewChild('eurosystemEligibilities', { static: false }) eurosystemEligibilities: ElementRef | undefined;

  @Input() isLoadingLeiInformation = true;
  private readonly issuerLeiCode = 'issuer.leiCode';
  public issuingAgents: PartyModel[] = [];
  public principalPayingAgents: PartyModel[] = [];
  public commonDepositories: PartyModel[] = [];
  public registrars: PartyModel[] = [];
  public commonSafekeepers: PartyModel[] = [];
  public listingFiscalAgents: PartyModel[] = [];
  public localPayingAgents: PartyModel[] = [];
  public listingAgents: PartyModel[] = [];
  public competentAuthorities: PartyModel[] = [];
  public calculationAgents: PartyModel[] = [];
  public transferAgents: PartyModel[] = [];
  public showEurosystemEligibilities = true;
  private readonly shutdown$ = new Subject<void>();
  public programmeInformation: ProgrammeInformation | null = null;

  public currentProgramme: ProgrammeModel | null = null;

  constructor(formService: ProgrammeFormService, public readonly i18nUtils: I18nUtilsService, private readonly router: Router, private readonly partyService: PartyService) {
    super(formService);
  }

  public get programmeId(): number | null {
    return this.formService.rawValue('id') || null;
  }

  public get formErrors(): Record<string, any> {
    return this.formService.formErrors;
  }

  ngOnInit() {
    this.toggleConditionnalRulesForm();
    this.retrievingPartyRole();
    this.formService.programmeInformation$.pipe(takeUntil(this.shutdown$)).subscribe(res => (this.programmeInformation = res));
  }

  ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.complete();
  }

  getErrorMessages() {
    this.formService.refreshErrors();
  }
  onProgrammeInformationSelected(programmeInformation: ProgrammeInformation | null) {
    this.formService.setProgrammeInformation(programmeInformation);
  }
  retrievingPartyRole(): void {
    const getPartyByRoles = (roleType: ROLES_TYPE, typeFilter: TYPE_FILTER_TYPE): Observable<PartyModel[]> => {
      return this.partyService.getPartiesByRoles(roleType, typeFilter).pipe(takeUntil(this.shutdown$));
    };

    getPartyByRoles('ISSUING_AGENT', 'PROGRAMME').subscribe(res => (this.issuingAgents = res));
    getPartyByRoles('PRINCIPAL_PAYING_AGENT', 'PROGRAMME').subscribe(res => (this.principalPayingAgents = res));
    getPartyByRoles('COMMON_DEPOSITARY', 'PROGRAMME').subscribe(res => (this.commonDepositories = res));
    getPartyByRoles('REGISTRAR', 'PROGRAMME').subscribe(res => (this.registrars = res));
    getPartyByRoles('COMMON_SAFEKEEPER', 'PROGRAMME').subscribe(res => (this.commonSafekeepers = res));
    getPartyByRoles('FISCAL_AGENT', 'PROGRAMME').subscribe(res => (this.listingFiscalAgents = res));
    getPartyByRoles('LOCAL_PAYING_AGENT', 'PROGRAMME').subscribe(res => (this.localPayingAgents = res));
    getPartyByRoles('LISTING_AGENT', 'PROGRAMME').subscribe(res => (this.listingAgents = res));
    getPartyByRoles('COMPETENT_AUTHORITY', 'PROGRAMME').subscribe(res => (this.competentAuthorities = res));
    getPartyByRoles('CALCULATION_AGENT', 'PROGRAMME').subscribe(res => (this.calculationAgents = res));
    getPartyByRoles('TRANSFER_AGENT', 'PROGRAMME').subscribe(res => (this.transferAgents = res));
  }

  getErrorMessagesMaestroPicker() {
    this.formGroup.get(this.issuerLeiCode)?.markAsTouched();
    this.formGroup.get('issuer.bdrId')?.markAsTouched();
    this.formService.refreshErrors();
  }

  toggleConditionnalRulesForm() {
    this.formGroup
      .get('commonDepositaries')
      ?.valueChanges.pipe(takeUntil(this.shutdown$))
      .pipe(takeUntil(this.shutdown$))
      .subscribe({
        next: (values: PartyModel[]) => {
          if (values?.some(value => value?.leiCode === this.formService.sgLuxParty?.leiCode)) {
            this.showEurosystemEligibilities = true;
          } else {
            this.showEurosystemEligibilities = false;
            this.formGroup.controls['eurosystemEligibility'].setValue(false);
          }
          this.formGroup.get('eurosystemEligibility')?.updateValueAndValidity();
          this.formGroup.get('localPayingAgents')?.updateValueAndValidity();
        }
      });
    this.formGroup
      .get('eurosystemEligibility')
      ?.valueChanges.pipe(takeUntil(this.shutdown$))
      .pipe(takeUntil(this.shutdown$))
      .subscribe({
        next: (value: string) => {
          if (!value) {
            this.formGroup.controls['commonSafekeepers'].setValue([]);
          }
          this.formGroup.get('commonSafekeepers')?.updateValueAndValidity();
        }
      });
    this.formGroup
      .get('principalPayingAgents')
      ?.valueChanges.pipe(takeUntil(this.shutdown$))
      .pipe(takeUntil(this.shutdown$))
      .subscribe({
        next: () => this.formGroup.get('localPayingAgents')?.updateValueAndValidity()
      });

    this.formGroup
      .get('competentAuthorities')
      ?.valueChanges.pipe(takeUntil(this.shutdown$))
      .pipe(takeUntil(this.shutdown$))
      .subscribe({
        next: () => {
          this.formGroup.get('authorityProgrammeIdentifier')?.updateValueAndValidity();
          this.formGroup.get('authorityProgrammeIdentifierDate')?.updateValueAndValidity();
        }
      });
  }

  get forms() {
    return this.formGroup.controls;
  }

  get formsIssuerGroup() {
    return this.formGroup.get('issuer');
  }

  toggleEurosystemEligibilities(event: any): void {
    Array.prototype.forEach.call(this.eurosystemEligibilities?.nativeElement.children, child => {
      child.classList.remove('active');
    });
    event.currentTarget.classList.add('active');
    this.formGroup.controls['eurosystemEligibility'].patchValue(JSON.parse(event.currentTarget.value));
    this.formService.refreshErrors();
  }

  switchToEditMode(): void {
    this.router.navigate(routeToProgramme(this.programmeId, 'edit'));
  }

  public controlValue(key: string) {
    return this.formGroup.get(key)?.value;
  }

  public get requiredAuthorityProgrammeIdentifier() {
    return this.formService.requiredAuthorityProgrammeIdentifier.bind(this.formService);
  }
  public get requiredLocalPayingAgents() {
    return this.formService.requiredLocalPayingAgents.bind(this.formService);
  }

  public get requiredCompetentAuthorities() {
    return this.formService.requiredCompetentAuthorities.bind(this.formService);
  }

  public get requiredCommonSafekeeper() {
    return this.formService.requiredCommonSafekeeper.bind(this.formService);
  }
}
