/* eslint-disable @typescript-eslint/no-empty-function */
import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-instrument-type',
  templateUrl: './instrument-type.component.html'
})
export class InstrumentTypeComponent {
  params: ICellRendererParams | undefined;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
}
