/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ROLES_TYPE, TYPE_FILTER_TYPE, PartyModel, ProgrammeModel } from '@shared/models';
import { Observable, EMPTY, Subject } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InstrumentRoleMatrixService } from '@instruments/services/instrument-role-matrix.service';
import { takeUntil, tap } from 'rxjs/operators';

type PartyModelOrModels = PartyModel | PartyModel[] | undefined;

@Component({
  selector: 'app-instrument-role-multiselect',
  templateUrl: './instrument-role-multiselect-v2.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InstrumentRoleMultiselectV2Component),
      multi: true
    }
  ]
})
export class InstrumentRoleMultiselectV2Component implements ControlValueAccessor, OnInit {
  @Input() multiple = false;

  @Input() closeOnSelect = false;

  @Input() withDebounce = false;
  @Input() programme: ProgrammeModel | null | undefined;
  @Input() type: ROLES_TYPE | undefined = undefined;
  @Input() filter: TYPE_FILTER_TYPE | undefined = undefined;
  @Input() placeholder = '';
  @Input() defaultValue: string | null = null;
  @Input() hasError = false;
  @Output() blurEmit: EventEmitter<void> = new EventEmitter();

  @Output() inputEmit: EventEmitter<string> = new EventEmitter();
  private readonly $shutdown = new Subject<void>();
  public items$!: Observable<PartyModel[]>;
  public isReadOnly = false;
  public focusId: string | null = null;
  public values: PartyModel[] = [];
  public DEFAULT_VALUES: PartyModel[] = [];

  constructor(private readonly instrumentRoleMultiselectService: InstrumentRoleMatrixService) {}

  ngOnInit(): void {
    if (this.type && this.filter) {
      this.instrumentRoleMultiselectService
        .getDefaultParties(this.type, this.filter, this.programme)
        .pipe(
          tap(res => {
            if (this.defaultValue && !this.isReadOnly) {
              this.value = res.find(q => q.name === this.defaultValue);
            }
          }),

          takeUntil(this.$shutdown)
        )
        .subscribe(defaultvalues => {
          this.DEFAULT_VALUES = defaultvalues;
          this.values = defaultvalues;
          if (this.values.length === 1 && !this.isReadOnly) {
            this.value = this.values[0];
          }
        });

      this.instrumentRoleMultiselectService
        .getRoleFromType(this.type)
        .pipe(takeUntil(this.$shutdown))
        .subscribe(res => {
          if (res.length) {
            this.values = res;
          } else {
            this.values = this.DEFAULT_VALUES;
          }
        });
    } else {
      this.items$ = EMPTY;
    }
  }

  private _selection: PartyModelOrModels = undefined;
  get value(): PartyModelOrModels {
    return this._selection;
  }

  set value(value: PartyModelOrModels) {
    this._selection = value || undefined;
    this.onChange(value);
  }

  get displayValue(): PartyModel[] {
    if (!this.value) {
      return [];
    }

    return Array.isArray(this.value) ? this.value : [this.value];
  }

  public onBlurEmit(): void {
    this.onTouched();
    this.blurEmit.next();
  }

  public onInputEmit(value: string): void {
    this.inputEmit.next(value);
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  setDisabledState(isDisabled: boolean): void {
    this.isReadOnly = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: PartyModel[]): void {
    this._selection = value;
  }
}
