<app-ttd-items-selector
  [(ngModel)]="value"
  [data]="possibleCurrencies"
  [singleSelect]="!multiple"
  [isReadOnly]="isReadOnly"
  [icon]="icon"
  (focusout)="onBlurEmit()"
  [labelProperties]="['code']"
  [withSummary]="withSummary"
  [placeHolder]="placeholder"
  key="code"
  labelPath="code"
>
  <div readonlyTemplate><span class="me-1" *ngFor="let val of displayValue">{{ val }}</span></div>
</app-ttd-items-selector>