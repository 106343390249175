import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ReportingAccessModel } from '@instruments/models';
import { FormService } from '@shared/services/forms/form.service';
import { CustomValidators } from '@shared/validators/custom-validator';

@Injectable()
export class ReportingAccessFormService extends FormService<ReportingAccessModel> {
  constructor(fb: FormBuilder) {
    super('reporting-access', fb, {
      startDate: fb.control(null, [Validators.required, CustomValidators.ValidateDate]),
      endDate: fb.control(null, [Validators.required, CustomValidators.ValidateDate]),
      documentType: fb.control(null, [Validators.required])
    });
  }
}
