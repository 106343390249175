import { Injectable } from '@angular/core';
import { UnionFormService } from '@shared/services/forms/union.form.service';
import { InstrumentArrayType, InstrumentForms, InstrumentModel } from '@instruments/models/instrument.model';
import { CharacteristicFormService } from './characteristic.form.service';
import { EventsDataFormService } from './events-data.form.service';
import { GlobalNoteFormService } from './global-note.form.service';
import { InstrumentAddendumFormService } from './instrument-addendum.form.service';
import { IssuanceFormService } from './issuance.form.service';
import { ListingFormService } from './listing.form.service';
import { RoleDataFormService } from './role-data.form.service';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, takeUntil, filter } from 'rxjs/operators';
import { TrancheFields, TrancheModel } from '@instruments/models/tranche.model';
import { IpaTypeModelRules as R } from '@instruments/rules';
import { notEmpty } from '@utils/utility-functions';
import { INSTRUMENT_CONSTANTS } from '@instruments/configs/instrument-constants';

type PossibleStatus = 'live' | 'initialized';
type PossibleField = keyof Pick<InstrumentModel, 'tradingMethodIdentifier' | 'nominal' | 'denomination' | 'numberOfShares' | 'outstandingNominal' | 'isinReference' | 'finalTermReceived' | 'minimumTrading'>;
type PossibleTranche = 'firstTranche' | 'otherTranche';
type instrumentGlobalNoteType = 'tefraC' | 'tefraD';
type trancheType = 'Tranche' | 'Increase';
type LockMatrix = Record<trancheType, Record<instrumentGlobalNoteType, Record<PossibleStatus, Record<PossibleTranche, Record<PossibleField, boolean>>>>>;

@Injectable()
export class InstrumentFormService extends UnionFormService<InstrumentArrayType, InstrumentForms> {
  private readonly tranchesFields: (keyof InstrumentModel)[];
  private readonly tranchesSubject = new BehaviorSubject<TrancheModel[]>([]);
  private readonly currentTrancheSubject = new BehaviorSubject<TrancheModel | null>(null);
  private readonly selectedTrancheSubject = new BehaviorSubject<number | null>(1);
  private readonly selectedTabTypeSubject = new BehaviorSubject<InstrumentSelectorTabType>('Tranche');
  private readonly roleDataFormServiceValue: RoleDataFormService;
  public get currentTranche$(): Observable<TrancheModel> {
    return this.currentTrancheSubject.pipe(filter(notEmpty));
  }
  public get currentTabType$(): Observable<InstrumentSelectorTabType> {
    return this.selectedTabTypeSubject.asObservable();
  }
  public get tranches$(): Observable<TrancheModel[]> {
    return this.tranchesSubject.asObservable();
  }

  private readonly lockMatrix: LockMatrix;
  constructor(
    characteristicFormService: CharacteristicFormService,
    listingFormService: ListingFormService,
    issuanceFormService: IssuanceFormService,
    globalNoteFormService: GlobalNoteFormService,
    roleDataFormService: RoleDataFormService,
    eventsDataFormService: EventsDataFormService,
    instrumentAddendumModelService: InstrumentAddendumFormService
  ) {
    super('rootForm_Instrument', characteristicFormService, listingFormService, issuanceFormService, globalNoteFormService, eventsDataFormService, roleDataFormService, instrumentAddendumModelService);

    this.roleDataFormServiceValue = roleDataFormService;

    this.lockFieldsPermanently('id');
    this.tranchesFields = Object.values<keyof InstrumentModel>(this.fields).filter(f => TrancheFields.includes(f as any));

    // true => editable, false => locked
    this.lockMatrix = {
      Increase: {
        tefraD: {
          initialized: {
            firstTranche: {
              tradingMethodIdentifier: true,
              denomination: true,
              nominal: true,
              numberOfShares: true,
              outstandingNominal: false,
              isinReference: true,
              finalTermReceived: true,
              minimumTrading: true
            },
            otherTranche: {
              tradingMethodIdentifier: false,
              denomination: false,
              nominal: true,
              numberOfShares: true,
              outstandingNominal: false,
              isinReference: true,
              finalTermReceived: false,
              minimumTrading: false
            }
          },
          live: {
            firstTranche: {
              tradingMethodIdentifier: false,
              denomination: true,
              nominal: false,
              numberOfShares: false,
              outstandingNominal: true,
              isinReference: true,
              finalTermReceived: true,
              minimumTrading: true
            },
            otherTranche: {
              tradingMethodIdentifier: false,
              denomination: false,
              nominal: false,
              numberOfShares: false,
              outstandingNominal: true,
              isinReference: true,
              finalTermReceived: false,
              minimumTrading: false
            }
          }
        },
        tefraC: {
          initialized: {
            firstTranche: {
              tradingMethodIdentifier: true,
              denomination: true,
              nominal: true,
              numberOfShares: true,
              outstandingNominal: false,
              isinReference: false,
              finalTermReceived: true,
              minimumTrading: true
            },
            otherTranche: {
              tradingMethodIdentifier: false,
              denomination: false,
              nominal: true,
              numberOfShares: true,
              outstandingNominal: false,
              isinReference: false,
              finalTermReceived: false,
              minimumTrading: false
            }
          },
          live: {
            firstTranche: {
              tradingMethodIdentifier: false,
              denomination: true,
              nominal: false,
              numberOfShares: false,
              outstandingNominal: true,
              isinReference: false,
              finalTermReceived: true,
              minimumTrading: true
            },
            otherTranche: {
              tradingMethodIdentifier: false,
              denomination: false,
              nominal: false,
              numberOfShares: false,
              outstandingNominal: true,
              isinReference: false,
              finalTermReceived: false,
              minimumTrading: false
            }
          }
        }
      },
      Tranche: {
        tefraD: {
          initialized: {
            firstTranche: {
              tradingMethodIdentifier: true,
              denomination: true,
              nominal: true,
              numberOfShares: true,
              outstandingNominal: false,
              isinReference: true,
              finalTermReceived: true,
              minimumTrading: true
            },
            otherTranche: {
              tradingMethodIdentifier: false,
              denomination: false,
              nominal: true,
              numberOfShares: true,
              outstandingNominal: false,
              isinReference: true,
              finalTermReceived: true,
              minimumTrading: true
            }
          },
          live: {
            firstTranche: {
              tradingMethodIdentifier: false,
              denomination: true,
              nominal: false,
              numberOfShares: false,
              outstandingNominal: true,
              isinReference: true,
              finalTermReceived: true,
              minimumTrading: true
            },
            otherTranche: {
              tradingMethodIdentifier: false,
              denomination: false,
              nominal: false,
              numberOfShares: false,
              outstandingNominal: true,
              isinReference: true,
              finalTermReceived: true,
              minimumTrading: true
            }
          }
        },
        tefraC: {
          initialized: {
            firstTranche: {
              tradingMethodIdentifier: true,
              denomination: true,
              nominal: true,
              numberOfShares: true,
              outstandingNominal: false,
              isinReference: false,
              finalTermReceived: true,
              minimumTrading: true
            },
            otherTranche: {
              tradingMethodIdentifier: false,
              denomination: false,
              nominal: true,
              numberOfShares: true,
              outstandingNominal: false,
              isinReference: false,
              finalTermReceived: true,
              minimumTrading: true
            }
          },
          live: {
            firstTranche: {
              tradingMethodIdentifier: false,
              denomination: true,
              nominal: false,
              numberOfShares: false,
              outstandingNominal: true,
              isinReference: false,
              finalTermReceived: true,
              minimumTrading: true
            },
            otherTranche: {
              tradingMethodIdentifier: false,
              denomination: false,
              nominal: false,
              numberOfShares: false,
              outstandingNominal: true,
              isinReference: false,
              finalTermReceived: true,
              minimumTrading: true
            }
          }
        }
      }
    };
  }

  private applyLockMatrix(upto: boolean, type: instrumentGlobalNoteType, status: PossibleStatus, tranche: PossibleTranche) {
    const data = this.lockMatrix[upto ? 'Increase' : 'Tranche'][type][status][tranche];
    const keys = Object.keys(data).map(k => k as PossibleField);
    keys.forEach(k => {
      if (data[k]) {
        this.unlockFields(k);
      } else {
        this.lockFields(k);
      }
    });
  }

  public setSelectedTranche(trancheNumber: number | null): void {
    this.selectedTrancheSubject.next(trancheNumber);
  }
  public selectTabType(tab: InstrumentSelectorTabType): void {
    if (tab !== 'Tranche') {
      this.selectedTrancheSubject.next(null);
    }
    this.selectedTabTypeSubject.next(tab);
  }
  public setTranches(tranches: TrancheModel[]): void {
    this.tranchesSubject.next(tranches);
    this.patch('tranches', tranches);
  }

  public clearTrancheInformations(): void {
    this.tranchesSubject.next([]);
    this.selectedTrancheSubject.next(1);
    this.currentTrancheSubject.next(null);
    this.reset(this.tranchesFields);

    this.patch({
      selectedTrancheNumber: 1,
      trancheStatus: null
    });
  }

  public setTranchesInformations(tranches: TrancheModel[], trancheNumber: number): void {
    this.tranchesSubject.next(tranches);
    this.selectedTrancheSubject.next(trancheNumber);

    const trancheModel = tranches.find(t => t.trancheNumber === trancheNumber) || null;

    this.currentTrancheSubject.next(trancheModel);
    const status = this.rawValue().status;
    this.patch({
      ...trancheModel,
      denomination: this.rawValue('denomination'),
      selectedTrancheNumber: trancheModel?.trancheNumber || 1,
      trancheStatus: trancheModel?.status || null
    });
    if ((trancheModel?.trancheNumber || 1) > 1) {
      this.patch({ status: status });
    }
  }

  public subscribeTrancheModification(shutdown$: Observable<unknown>): void {
    combineLatest([this.valueChanges('id'), this.selectedTrancheSubject, this.tranchesSubject])
      .pipe(
        takeUntil(shutdown$),
        filter(([, , tranches]) => !!tranches.length),
        distinctUntilChanged((a, b) => {
          const [idA, trancheNumberA, tranchesA] = a;
          const [idB, trancheNumberB, tranchesB] = b;

          return idA === idB && trancheNumberA === trancheNumberB && JSON.stringify(tranchesA) === JSON.stringify(tranchesB);
        })
      )
      .subscribe(([_, trancheNumber, tranches]) => {
        const tranche = tranches.find(t => t.trancheNumber === trancheNumber) || null;
        this.currentTrancheSubject.next(tranche);
        this.patch({
          selectedTrancheNumber: trancheNumber,
          trancheStatus: tranche?.status || null
        });
      });

    combineLatest([this.valueChanges('denomination'), this.currentTrancheSubject])
      .pipe(
        takeUntil(shutdown$),
        filter(([, tranche]) => !!tranche),
        distinctUntilChanged((a, b) => {
          const [, trancheA] = a;
          const [, trancheB] = b;
          return trancheA?.trancheNumber === trancheB?.trancheNumber;
        })
      )
      .subscribe(([denomination, tranche]) => {
        const status = this.rawValue().status;
        this.patch({ ...tranche, denomination }, { emitEvent: false });
        this.patch({ ...{ status: status } });
        const trancheNumber = tranche?.trancheNumber || null;
        if (!trancheNumber) {
          console.warn('Selected tranche has no trancheNumber');
          return;
        }

        if (trancheNumber !== 1) {
          this.lockAllFieldsExcept(...this.tranchesFields);
        } else {
          this.unlockAllFieldsExcept();
        }
        const instrument = this.rawValue();
        const trancheStatus = tranche?.status;
        const trancheType: PossibleTranche = trancheNumber === 1 ? 'firstTranche' : 'otherTranche';
        const isLive = R.isLive(trancheStatus) ? 'live' : null;
        const statusType: PossibleStatus | null = R.isInitialized(trancheStatus) ? 'initialized' : isLive;
        const type: instrumentGlobalNoteType = this.rawValue('physicalForm')?.valueDescription === INSTRUMENT_CONSTANTS.Types.PhysicalForms.TEMPORARYPERMANENTGLOBALNOTETEFRAD ? 'tefraD' : 'tefraC';
        if (statusType) {
          this.applyLockMatrix(instrument.upto ?? false, type, statusType, trancheType);
        }
      });
  }

  public setcommonDepositary(commonDepositary: any, lock: boolean, wasLocked: boolean): void {
    const currentCommonDepositary = this.roleDataFormServiceValue.rawValue('commonDepositary');
    if (lock) {
      this.lockFields('commonDepositary');
      this.roleDataFormServiceValue.patch('commonDepositary', commonDepositary);
    } else {
      this.unlockFields('commonDepositary');
      wasLocked ? this.roleDataFormServiceValue.clearField('commonDepositary') : this.roleDataFormServiceValue.patch('commonDepositary', currentCommonDepositary ?? null);
    }
  }

  public setcommonRegistrar(registrar: any, lock: boolean, wasLocked: boolean): void {
    const currentRegistrar = this.roleDataFormServiceValue.rawValue('registrar');
    if (lock) {
      this.lockFields('registrar');
      this.roleDataFormServiceValue.patch('registrar', registrar);
    } else {
      this.unlockFields('registrar');
      wasLocked ? this.roleDataFormServiceValue.clearField('registrar') : this.roleDataFormServiceValue.patch('registrar', currentRegistrar ?? null);
    }
  }
}
