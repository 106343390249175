import { Component, Input, OnInit } from '@angular/core';
import { OperationDetailItemModel } from '@positions/models';
import { OperationDetailColumnDefService } from '@positions/services';
import { OperationDetailFormService } from '@positions/services/operation-detail.form.service';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-operation-items-consult',
  templateUrl: './operation-items-consult.component.html'
})
export class OperationItemsConsultComponent implements OnInit {
  @Input() operationItems: OperationDetailItemModel[] | null | undefined = [];
  public gridOptions!: GridOptions;
  public columnDefs: ColDef[] = [];
  public defaultColDef: ColDef | undefined;
  public gridApi!: GridApi;
  public rowSelection: 'single' | 'multiple' = 'single';

  constructor(private readonly operationDetailColumnDefService: OperationDetailColumnDefService, private readonly formService: OperationDetailFormService) {}

  ngOnInit(): void {
    this.gridOptions = {
      detailRowAutoHeight: true,
      onGridSizeChanged: () => {
        this.gridOptions.api?.sizeColumnsToFit();
      },
      pagination: false,
      suppressPaginationPanel: false,
      paginationPageSize: 10,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      suppressContextMenu: true,
      stopEditingWhenCellsLoseFocus: true
    };
    this.defaultColDef = {
      sortable: false,
      filter: false,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true }
    };
  }
  onGridReady(params: { api: GridApi }): void {
    this.columnDefs = this.operationDetailColumnDefService.get();
    const gridApi = params.api;
    gridApi.sizeColumnsToFit();
    gridApi.setDomLayout('autoHeight');
    gridApi.refreshHeader();
  }
}
