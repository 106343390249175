<div class="section-forms container g-0" [formGroup]="formGroup">
  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item [field]="fields.issuingAgent" [focus]="issuingAgent"> 
        <app-instrument-role-multiselect component filter="INSTRUMENT" [type]="ISSUING_AGENT" [programme]="programme"
          [formControlName]="fields.issuingAgent" #issuingAgent [multiple]="false" [closeOnSelect]="true"
          [withDebounce]="true" [hasError]="hasError(fields.issuingAgent)"
          [placeholder]="placeholder(fields.issuingAgent) | translate">
        </app-instrument-role-multiselect>
      </app-form-item>
    </div>
    <div class="col-md-6">
      <app-form-item [field]="fields.principalPayingAgent" [focus]="principalPayingAgent">
        <app-instrument-role-multiselect filter="INSTRUMENT" [type]="PRINCIPAL_PAYING_AGENT" component
          [formControlName]="fields.principalPayingAgent" #principalPayingAgent [multiple]="false" [programme]="programme"
          [closeOnSelect]="true" [withDebounce]="true" [hasError]="hasError(fields.principalPayingAgent)"
          [placeholder]="placeholder(fields.principalPayingAgent) | translate">
        </app-instrument-role-multiselect>
      </app-form-item>
    </div>
  </div>
  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item [field]="fields.commonDepositary" [focus]="commonDepositary">
        <app-instrument-role-multiselect filter="INSTRUMENT" [type]="COMMON_DEPOSITARY" component [programme]="programme"
          [formControlName]="fields.commonDepositary" #commonDepositary [multiple]="false" [closeOnSelect]="true"
          [withDebounce]="true" [hasError]="hasError(fields.commonDepositary)" bindLabelProperty="valueDescription"
          [placeholder]="placeholder(fields.commonDepositary) | translate">
        </app-instrument-role-multiselect>
      </app-form-item>
    </div>
    <div class="col-md-6">
      <app-form-item [field]="fields.registrar" [focus]="registrar">
        <app-instrument-role-multiselect filter="INSTRUMENT" [type]="REGISTRAR" [formControlName]="fields.registrar"
          component #registrar [multiple]="false" [closeOnSelect]="true" [withDebounce]="true" [programme]="programme"
          [hasError]="hasError(fields.registrar)" [placeholder]="placeholder(fields.registrar) | translate">
        </app-instrument-role-multiselect>
      </app-form-item>
    </div>
  </div>
  <hr class="mb-5 mt-5" />
  <div class="row gy-4">
    <div class="col-md-6">
      <app-form-item [field]="fields.potentialEurosystemEligibility" [focus]="potentialEurosystemEligibility" >
        <app-yes-no-buttons [formControlName]="fields.potentialEurosystemEligibility" component [programme]="programme"
          #potentialEurosystemEligibility>
        </app-yes-no-buttons>
      </app-form-item>
    </div>
  </div>
  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item  [field]="fields.commonServiceProvider" [focus]="commonServiceProvider">
        <app-instrument-role-multiselect filter="INSTRUMENT" [type]="COMMON_SERVICE_PROVIDER" component
          [formControlName]="fields.commonServiceProvider" #commonServiceProvider [multiple]="false" [programme]="programme"
          [closeOnSelect]="true" [withDebounce]="true" [hasError]="hasError(fields.commonServiceProvider)"
          [placeholder]="placeholder(fields.commonServiceProvider) | translate">
        </app-instrument-role-multiselect>
      </app-form-item>
    </div>
    <div class="col-md-6">
      <app-form-item [field]="fields.commonSafeKeeper" [focus]="commonSafeKeeper">
        <app-instrument-role-multiselect filter="INSTRUMENT" [type]="COMMON_SAFEKEEPER" component
          [formControlName]="fields.commonSafeKeeper" #commonSafeKeeper [multiple]="false" [closeOnSelect]="true"
          [withDebounce]="true" [hasError]="hasError(fields.commonSafeKeeper)" [programme]="programme"
          [placeholder]="placeholder(fields.commonSafeKeeper) | translate">
        </app-instrument-role-multiselect>
      </app-form-item>
    </div>
  </div>
  <hr class="mb-5 mt-5" />
  <div class="row gy-4">
    <div class="col-md-6">
      <app-form-item [field]="fields.fiscalAgent" [focus]="fiscalAgent" >
        <app-instrument-role-multiselect filter="INSTRUMENT" [type]="FISCAL_AGENT"
          [formControlName]="fields.fiscalAgent" component #fiscalAgent [multiple]="false" [closeOnSelect]="true"
          [withDebounce]="true" [hasError]="hasError(fields.fiscalAgent)" [programme]="programme"
          [placeholder]="placeholder(fields.fiscalAgent) | translate">
        </app-instrument-role-multiselect>
      </app-form-item>
    </div>
    <div class="col-md-6">
      <app-form-item [field]="fields.localPayingAgent" [focus]="localPayingAgent">
        <app-instrument-role-multiselect filter="INSTRUMENT" [type]="LOCAL_PAYING_AGENT" component
          [formControlName]="fields.localPayingAgent" #localPayingAgent [multiple]="false" [closeOnSelect]="true"
          [withDebounce]="true" [hasError]="hasError(fields.localPayingAgent)" [programme]="programme"
          [placeholder]="placeholder(fields.localPayingAgent) | translate">
        </app-instrument-role-multiselect>
      </app-form-item>
    </div>
  </div>
  <div class="row gy-4 mt-1">
    <div class="  col-md-6">
      <app-form-item [field]="fields.listingAgent" [focus]="listingAgent">
        <app-instrument-role-multiselect filter="INSTRUMENT" [type]="LISTING_AGENT"
          [formControlName]="fields.listingAgent" component #listingAgent [multiple]="false" [closeOnSelect]="true"
          [withDebounce]="true" [hasError]="hasError(fields.listingAgent)" [programme]="programme"
          [placeholder]="placeholder(fields.listingAgent) | translate">
        </app-instrument-role-multiselect>
      </app-form-item>
    </div>
    <div class="col-md-6">
      <app-form-item  [field]="fields.transferAgent" [focus]="transferAgent">
        <app-instrument-role-multiselect filter="INSTRUMENT" [type]="TRANSFER_AGENT"
          [formControlName]="fields.transferAgent" component #transferAgent [multiple]="false" [closeOnSelect]="true"
          [withDebounce]="true" [hasError]="hasError(fields.transferAgent)" [programme]="programme"
          [placeholder]="placeholder(fields.transferAgent) | translate">
        </app-instrument-role-multiselect>
      </app-form-item>
    </div>
  </div>
  <div class="row gy-4 mt-1">
    <div class="col-md-6">
      <app-form-item  [field]="fields.calculationAgent" [focus]="calculationAgent">
        <app-instrument-role-multiselect filter="INSTRUMENT" [type]="CALCULATION_AGENT" component
          [formControlName]="fields.calculationAgent" #calculationAgent [multiple]="false" [closeOnSelect]="true"
          [withDebounce]="true" [hasError]="hasError(fields.calculationAgent)" [programme]="programme"
          [placeholder]="placeholder(fields.calculationAgent) | translate">
        </app-instrument-role-multiselect>
      </app-form-item>
    </div>
    <div class="col-md-6">
      <app-form-item [field]="fields.deskCalculationAgent" [focus]="deskCalculationAgent">
        <app-instrument-role-multiselect filter="INSTRUMENT" [type]="DESK_CALCULATION_AGENT" component
          [formControlName]="fields.deskCalculationAgent" filter="INSTRUMENT" #deskCalculationAgent [multiple]="false"
          [closeOnSelect]="true" [withDebounce]="true" [hasError]="hasError(fields.deskCalculationAgent)" [programme]="programme"
          [placeholder]="placeholder(fields.deskCalculationAgent) | translate">
        </app-instrument-role-multiselect>
      </app-form-item>
    </div>
  </div>
  <hr class="mb-5 mt-5" />
  <div class="row gy-4">
    <div class=" col-md-6">
      <app-form-item [field]="fields.cashManager" [focus]="cashManager">
        <app-instrument-role-multiselect filter="INSTRUMENT" [type]="CASH_MANAGER" defaultValue="N/A"
          [formControlName]="fields.cashManager" #cashManager [multiple]="false" [closeOnSelect]="true"
          [withDebounce]="true" component [hasError]="hasError(fields.cashManager)" [programme]="programme"
          [placeholder]="placeholder(fields.cashManager) | translate">
        </app-instrument-role-multiselect>
      </app-form-item>
    </div>
    <div class="col-md-6">
      <app-form-item [field]="fields.escrowManager" [focus]="escrowManager">
        <app-instrument-role-multiselect filter="INSTRUMENT" [type]="ESCROW_MANAGER" defaultValue="N/A"
          [formControlName]="fields.escrowManager" component #escrowManager [multiple]="false" [closeOnSelect]="true"
          [withDebounce]="true" [hasError]="hasError(fields.escrowManager)" [programme]="programme"
          [placeholder]="placeholder(fields.escrowManager) | translate">
        </app-instrument-role-multiselect>
      </app-form-item>
    </div>
  </div>
</div>
