<div dropdown container="body" placement="bottom right">
    <em id="button-dropdown" dropdownToggle class="icon icon-md text-secondary" aria-controls="dropdown-actions">
      more_vert
    </em>
    <ul id="dropdown-actions" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
      aria-labelledby="button-dropdown">
      <li role="menuitem">
        <!-- to be implemented for domestic isin -->
      </li>
    </ul>
  </div>