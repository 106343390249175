/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, OnInit } from '@angular/core';
import { INSTRUMENT_CONSTANTS } from '@instruments/configs/instrument-constants';
import { PositionModel } from '@positions/models';
import { PostionColumnDefService } from '@positions/services';
import { notEmpty } from '@utils/utility-functions';
import { ColDef, GridOptions, GridApi, ValueFormatterParams } from 'ag-grid-community';

@Component({
  selector: 'app-position-list',
  templateUrl: './position-list.component.html',
  styleUrls: ['./position-list.component.scss'],
  providers: [PostionColumnDefService]
})
export class PositionListComponent implements OnInit {
  _positions: PositionModel[] = [];
  get positions(): PositionModel[] {
    return this._positions;
  }
  @Input() set positions(value: PositionModel[]) {
    this._positions = value;

    if (value.length > 0) {
      if (value.every(q => q.depositary?.name === INSTRUMENT_CONSTANTS.Parties.commonDepositary.DEUTSCHEBANKAG)) {
        this.gridOptions.columnApi?.setColumnsVisible(['actions'], false);
        this.gridOptions.api?.sizeColumnsToFit();
      } else {
        this.gridOptions.columnApi?.setColumnsVisible(['actions'], true);
      }
    }
  }
  public columnDefs: ColDef[] = [];
  public defaultColDef: ColDef | undefined;
  public gridOptions!: GridOptions;
  public gridApi!: GridApi;

  constructor(private readonly positionColumnDefService: PostionColumnDefService) {}
  ngOnInit(): void {
    this.gridOptions = {
      rowClassRules: {
        'bg-info': params => {
          return (params.data as PositionModel).depositary?.name === INSTRUMENT_CONSTANTS.Parties.commonDepositary.DEUTSCHEBANKAG;
        }
      },
      onGridSizeChanged: () => {
        this.gridOptions.api?.sizeColumnsToFit();
      },
      pagination: false,
      suppressPaginationPanel: false,
      paginationPageSize: 10,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      suppressContextMenu: true,
      stopEditingWhenCellsLoseFocus: true
    };
    this.defaultColDef = {
      sortable: false,
      filter: false,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true }
    };
  }

  onGridReady(params: { api: GridApi }): void {
    this.columnDefs = this.positionColumnDefService.get();

    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.refreshHeader();
  }

  exportAsCsv(): void {
    const items = this.columnDefs
      .filter(q => q.field !== 'actions')
      .map(q => q.field)
      .filter(notEmpty);

    this.gridApi.exportDataAsExcel({
      columnKeys: items,
      processCellCallback: params => {
        const colDef = params.column.getColDef();
        // try to reuse valueFormatter from the colDef
        if (colDef.valueFormatter && typeof colDef.valueFormatter !== 'string' && params.node) {
          const valueFormatterParams: ValueFormatterParams = {
            ...params,
            data: params?.node?.data,
            node: params.node,
            colDef: params.column.getColDef()
          };
          const formatter = colDef.valueFormatter as (params: ValueFormatterParams) => string;
          return formatter(valueFormatterParams);
        }
        return params.value;
      }
    });
  }
}
