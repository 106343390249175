/**
 * issuer-ipa-events-extended API
 * Manage eventss data
 *
 * The version of the OpenAPI document: 1.1.15
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IpaDataType } from './ipaDataType';


export interface IpaTypedDataType { 
    typedDataDescription?: string;
    ipaDataType?: IpaDataType;
}

