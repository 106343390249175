import { Injectable } from '@angular/core';
import { FormService } from '@shared/services/forms/form.service';
import { UntypedFormBuilder } from '@angular/forms';
import { ValuationFields, ValuationDetailModel } from '@valuation/models';

@Injectable({
  providedIn: 'root'
})
export class ValuationDetailFormService extends FormService<ValuationDetailModel> {
  constructor(fb: UntypedFormBuilder) {
    super('valuationDetail', fb, {
      valuationRef: fb.control(null),
      eventId: fb.control(null),
      valuationStatus: fb.control(null),
      valuationRepairReason: fb.control(null),
      isinCode: fb.control(null),
      eventType: fb.control(null),
      denomination: fb.control(null),
      denominationCcy: fb.control(null),
      valueDate: fb.control(null),
      maturityDate: fb.control(null),
      notificationDate: fb.control(null),
      receiptDate: fb.control(null),
      startDate: fb.control(null),
      endDate: fb.control(null),
      couponType: fb.control(null),
      interestRate: fb.control(null),
      dayCountFraction: fb.control(null),
      numOfDayOfThePeriod: fb.control(null),
      redemptionType: fb.control(null),
      redemptionTypeDescription: fb.control(null),
      strikePrice: fb.control(null),
      redemptionRate: fb.control(null),
      underlyingIsin: fb.control(null),
      underlyingDenomQty: fb.control(null),
      previousPoolFactor: fb.control(null),
      forexRate: fb.control(null),
      amountPerDenomination: fb.control(null),
      newPoolFactor: fb.control(null),
      paymentCcy: fb.control(null),
      underlyingCcy: fb.control(null),
      outstandingNominal: fb.control(null),
      totalNumOfUnderlying: fb.control(null),
      positionIn: fb.control(null),
      positionOut: fb.control(null),
      valuationVersion: fb.control(null),
      rejectionStatus: fb.control(null),
      closingPrice: fb.control(null),
      remarks: fb.control(null)
    });
  }
  valuationLockFields() {
    const valuationField = Object.values<keyof ValuationDetailModel>(this.fields).filter(f => ValuationFields.includes(f as any));
    this.lockFieldsPermanently(...valuationField);
  }

  valuationStatusLockFields() {
    const valuationStatusField = this.fields.valuationStatus;
    this.lockFields(valuationStatusField);
  }

  valuationStatusUnLockFields() {
    const valuationStatusField = this.fields.valuationStatus;
    this.unlockFields(valuationStatusField);
  }
}
