<div class="modal-header">
  <h3 class="modal-title">{{ dialogTitle | translate}}</h3>
  <button type="button" class="close icon p-1" (click)="onClose()">close</button>
</div>
<div class="modal-body" translate>{{ dialogBody }}</div>
<div class="modal-footer">
  <button type="button" (click)="onBtnClick(CancelReason)" (keyup.enter)="onBtnClick(CancelReason)"
    class="btn btn-lg btn-link">{{ cancelButton | translate }}</button>
  <button ngbAutofocus *ngIf="showConfirmButton" type="button" (click)="onBtnClick(ConfirmReason)" (keyup.enter)="onBtnClick(ConfirmReason)"
    class="btn btn-lg btn-primary" translate>{{ confirmButton | translate }}</button>
</div>
