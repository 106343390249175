import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { IssuanceModel } from '@instruments/models';
import { IpaTypeModelRules as R } from '@instruments/rules';
import { EventsDataFormService, InstrumentFormService, IssuanceFormService } from '@instruments/services';
import { IssuanceDisplayService } from '@instruments/services/display/issuance.display.service';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { TYPED_DATA_TYPE } from '@shared/models';
import { DisplayServiceInjectionToken, FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';
import { Subscription } from 'rxjs';
import { EventsService } from '@shared/services/events.service';
import { PoolFactorHistoryResponseListDto } from '@apis/backend/events';

@Component({
  selector: 'app-instrument-issuance',
  templateUrl: './instrument-issuance.component.html',
  providers: [
    { provide: FormServiceInjectionToken, useExisting: IssuanceFormService },
    { provide: DisplayServiceInjectionToken, useExisting: IssuanceDisplayService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'instruments.instrument.forms.instrumentIssuance' }
  ]
})
export class InstrumentIssuanceComponent extends BaseFormComponent<IssuanceModel, IssuanceFormService> implements OnInit, OnDestroy {
  public TRADING_METHOD: TYPED_DATA_TYPE = 'TRADING_METHOD';
  private readonly subscriptions: Subscription[] = [];
  public poolFactorData?: PoolFactorHistoryResponseListDto;
  isConsultMode = false;
  displayPoolFactors = false;

  constructor(
    public readonly formService: IssuanceFormService,
    private readonly displayService: IssuanceDisplayService,
    readonly eventsDataformService: EventsDataFormService,
    private readonly eventsService: EventsService,
    private readonly instrumentFormService: InstrumentFormService,
    @Inject(LabelRootTranslateInjectionToken) private readonly labelRoot: string
  ) {
    super(formService, 'instruments.instrument.placeholder.instrumentIssuance');
  }

  public get outstandingNominalType(): string {
    if (R.isTradingMethodNominal(this.formService.rawValue('tradingMethodIdentifier'))) {
      return 'nominal';
    }
    return 'unit';
  }

  public get issuePriceAppend(): string {
    if (R.isTradingMethodNominal(this.formService.rawValue('tradingMethodIdentifier'))) {
      return `${this.labelRoot}.${this.fields.issuePrice}.nominal`;
    }
    return `${this.labelRoot}.${this.fields.issuePrice}.unit`;
  }

  public get issuePriceTranslateParams(): Record<string, unknown> {
    return {
      nominalCurrency: this.formService.rawValue('nominalCurrency') || ''
    };
  }

  ngOnInit(): void {
    const instrument = this.instrumentFormService.rawValue();
    const instrumentId = instrument?.id;
    this.isConsultMode = this.formMode === 'consult';

    const displayObservables = this.displayService.Compile();
    if (this.isConsultMode && instrumentId) {
      this.subscriptions.push(
        this.eventsService.getPoolFactor(instrumentId ?? -1, true).subscribe(result => {
          if (result?.factorHistorySearchResponseDtos) {
            this.displayPoolFactors = true;
            this.poolFactorData = result;
          }
        })
      );
    }
    this.subscriptions.push(...displayObservables.map(o => o.subscribe()));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
