<ng-container *ngIf="!isReadOnly; else readonlyTemplate">
  <select type="text" class="form-control" [(ngModel)]="value" (ngModelChange)="onChange($event)" [ngClass]="classes"
    [compareWith]="compareByID">
    <option [disabled]="!nullable" [ngValue]="null">{{ placeholder }}</option>
    <ng-container *ngIf="datas; else itemsTypedData">
      <option *ngFor="let item of datas" [ngValue]="item">{{ item | titlecase }}</option>
    </ng-container>
    <ng-template #itemsTypedData>
      <option *ngFor="let item of items$ | async" [ngValue]="item">{{ item.valueDescription | titlecase }}</option>
    </ng-template>
  </select>
</ng-container>
<ng-template #readonlyTemplate>
  <div readonlyTemplate><span class="me-1" *ngFor="let val of displayValue">{{val.valueDescription}}</span></div>
</ng-template>
