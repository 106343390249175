<div class="modal-header">
    <h3 class="modal-title" translate> {{'instruments.settlements-confirmations-reports.title'}}</h3>
    <button type="button" class="close icon p-1" (click)="cancel()">close</button>
</div>
<div class="modal-body" style="height: 600px;">
    <app-documents-list [documents]="documents" [isEditMode]="false" [isLoading]="isLoadingDocument" [canDeleteFile]="false" [showTitle]="false"></app-documents-list>
</div>
<div class="modal-footer">
    <button type="button" (click)="cancel()" (keyup.enter)="cancel()" class="btn btn-lg btn-link" translate>
      {{'instruments.settlements-confirmations-reports.close'}}</button>
  </div>