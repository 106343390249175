import { Component, Input, Inject, Optional, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { IFormService } from '@shared/services/forms/form.models';
import { DisplayService } from '@shared/services/display/display.service';
import { Subscription } from 'rxjs';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken, DisplayServiceInjectionToken } from '../tokens';

@Component({
  selector: 'app-form-item',
  templateUrl: './form-item.component.html'
})
export class FormItemComponent implements OnInit, OnDestroy {
  @Input() withLabel = true;
  @Input() field!: any;
  @Input() params: Record<string, unknown> | undefined;
  @Input() focus: unknown;
  @Input() labelAddendum: string | undefined;
  @Output() displayChanged = new EventEmitter<boolean>();
  public control!: AbstractControl;
  @Input() display = true;
  private readonly rootLabel: string = '';
  public label = '';
  private displaySubscription: Subscription | null = null;

  constructor(
    @Inject(FormServiceInjectionToken) private readonly formService: IFormService<any>,
    @Inject(LabelRootTranslateInjectionToken) translationRoot: string,
    @Optional() @Inject(DisplayServiceInjectionToken) private readonly displayService: DisplayService<any> | undefined
  ) {
    this.rootLabel = translationRoot;
  }

  ngOnInit(): void {
    this.control = this.formService.control(this.field);
    this.label = `${this.rootLabel}.${this.field}`;
    if (this.labelAddendum) {
      this.label = `${this.label}.${this.labelAddendum}`;
    }
    if (this.displayService) {
      this.displaySubscription = this.displayService.shouldDisplay(this.field).subscribe(d => {
        this.display = d;
        this.displayChanged.emit(d);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.displaySubscription) {
      this.displaySubscription.unsubscribe();
    }
  }
  public get required(): boolean {
    return this.formService.isRequired(this.field);
  }
}
