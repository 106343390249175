import { ColDef } from 'ag-grid-community/dist/lib/main';
import { dateFormatter } from './instrument-settlement-column-def-edit';

export interface PermanentMonitoringColumnDef extends ColDef {
  field: string;
}

export const PERMANENT_MONITORING_COLUMNS: PermanentMonitoringColumnDef[] = [
  {
    field: 'dateOfPm',
    valueFormatter: dateFormatter,
    sortable: true,
    resizable: true,
    width: 250,
    editable: false
  },
  {
    field: 'pmOk',
    sortable: true,
    resizable: true,
    width: 250,
    editable: false
  },
  {
    field: 'typeOfDiscordance',
    sortable: true,
    resizable: true,
    width: 400,
    editable: false
  },
  {
    field: 'comment',
    sortable: true,
    resizable: true,
    width: 550,
    editable: false
  }
];
