import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuditDetailFormService } from '@events/services';

@Component({
  selector: 'app-event-audit-form',
  templateUrl: './event-audit-form.component.html'
})
export class EventAuditFormComponent {
  public ClosedReason: DialogResult = 'closed';
  closeButton = 'modals.buttons.close';

  @Input() set eventValidatedBy(value: string | undefined) {
    if (value) {
      this.auditDetailFormService.patchField('eventValidatedBy', value);
    }
  }

  @Input() set eventValidatedAt(value: string | undefined) {
    if (value) {
      this.auditDetailFormService.patchField('eventValidatedAt', value);
    }
  }

  @Input() set eventSentforValidationBy(value: string | undefined) {
    if (value) {
      this.auditDetailFormService.patchField('eventSentforValidationBy', value);
    }
  }

  constructor(private readonly activeModal: NgbActiveModal, private readonly auditDetailFormService: AuditDetailFormService) {}

  public onClose(): void {
    this.activeModal.dismiss(this.ClosedReason);
    this.activeModal.close(this.ClosedReason);
  }
}
