import { Component, Output, EventEmitter } from '@angular/core';
import { EventFormService } from '@events/services';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { EventModel } from '@events/models';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';
import { EVENT_CONSTANTS } from '@events/configs/event-constants';
import { Validators } from '@angular/forms';
import { CustomValidators } from '@shared/validators/custom-validator';
import { formatDate } from '@angular/common';
import { InstrumentModel } from '@instruments/models';
import { InstrumentService } from '@shared/services/instrument.service';

@Component({
  selector: 'app-event-dates-modification',
  templateUrl: './event-dates-modification.component.html',
  styleUrls: ['./event-dates-modification.component.scss'],
  providers: [
    { provide: FormServiceInjectionToken, useExisting: EventFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'events.form' }
  ]
})
export class EventDatesModificationComponent extends BaseFormComponent<EventModel, EventFormService> {
  public displayBeginEndDateErrorMessage = false;
  public displayValueEndDateErrorMessage = false;
  public displayValueDateErrorMessage = false;
  public displayValueMaturityDateErrorMessage = false;
  public instrument: InstrumentModel | undefined;
  @Output() disableConfirmButtonStatus = new EventEmitter<boolean>();

  constructor(private readonly eventFormService: EventFormService, private readonly instrumentService: InstrumentService) {
    super(eventFormService);
  }

  private readonly format = 'yyyy-MM-dd';
  private readonly culture = 'en_US';

  ngOnInit() {
    this.clearValidators();
    this.beginEndDateValidation();
    this.getInstrumentDetails();
  }

  private clearValidators() {
    const eventType = this.formGroup.get('eventType');
    const isin = this.formGroup.get('isin');
    eventType?.clearValidators();
    isin?.clearValidators();
  }

  public get displayBeginEndDate(): boolean {
    const eventType = this.eventFormService.formGroup.getRawValue().eventType;
    return eventType !== EVENT_CONSTANTS.eventTypes.INTR;
  }

  public get displayNotificationDate(): boolean {
    const eventType = this.eventFormService.formGroup.getRawValue().eventType;
    return eventType === EVENT_CONSTANTS.eventTypes.EXCH || eventType === EVENT_CONSTANTS.eventTypes.CERT;
  }

  private beginEndDateValidation() {
    const eventType = this.eventFormService.formGroup.getRawValue().eventType;
    const beginDate = this.formGroup.get('beginDate');
    const endDate = this.formGroup.get('endDate');
    if (eventType === EVENT_CONSTANTS.eventTypes.INTR) {
      beginDate?.setValidators([Validators.required, CustomValidators.ValidateDate]);
      endDate?.setValidators([Validators.required, CustomValidators.ValidateDate]);
    } else {
      beginDate?.clearValidators();
      endDate?.clearValidators();
    }
  }

  beginEndDateModificationAlert() {
    const beginDate = this.formService.rawValue('beginDate');
    const endDate = this.formService.rawValue('endDate');
    if (beginDate && endDate && formatDate(beginDate, this.format, this.culture) > formatDate(endDate, this.format, this.culture)) {
      this.displayBeginEndDateErrorMessage = true;
    } else {
      this.displayBeginEndDateErrorMessage = false;
    }
    this.disablingSaveButton();
  }

  valueDateModificationAlert() {
    const eventType = this.eventFormService.formGroup.getRawValue().eventType;
    const valueDate = this.formService.rawValue('valueDate');
    const endDate = this.formService.rawValue('endDate');
    if (eventType === EVENT_CONSTANTS.eventTypes.INTR) {
      if (valueDate && endDate && formatDate(endDate, this.format, this.culture) > formatDate(valueDate, this.format, this.culture)) {
        this.displayValueEndDateErrorMessage = true;
      } else {
        this.displayValueEndDateErrorMessage = false;
      }
    }
    this.valueDateMaturityDateAlert(eventType);
    this.disablingSaveButton();
  }

  valueDateMaturityDateAlert(eventType: string) {
    const valueDate = this.formService.rawValue('valueDate');
    const maturityDate = this.instrument?.maturityDate;
    switch (eventType) {
      case EVENT_CONSTANTS.eventTypes.REDM:
      case EVENT_CONSTANTS.eventTypes.MCAL:
        if (valueDate && maturityDate && toFilterDateString(maturityDate) === toFilterDateString(valueDate)) {
          this.displayValueMaturityDateErrorMessage = false;
        } else {
          this.displayValueMaturityDateErrorMessage = true;
        }
        break;
      default:
        if (maturityDate && valueDate && toFilterDateString(maturityDate) >= toFilterDateString(valueDate)) {
          this.displayValueDateErrorMessage = false;
        } else {
          this.displayValueDateErrorMessage = true;
        }
        break;
    }
  }

  getInstrumentDetails() {
    const isin = this.formService.rawValue('isin');
    this.instrumentService.getInstrumentByIsin(isin ?? undefined).subscribe(result => {
      if (result) {
        this.instrument = result;
      }
    });
  }

  private disablingSaveButton() {
    const disableSaveButton = this.displayBeginEndDateErrorMessage || this.displayValueEndDateErrorMessage || this.displayValueDateErrorMessage || this.displayValueMaturityDateErrorMessage;
    if (disableSaveButton) {
      this.disableConfirmButtonStatus.emit(true);
    } else {
      this.disableConfirmButtonStatus.emit(false);
    }
  }
}

const toFilterDateString = (date: Date): string => {
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = date.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
};
