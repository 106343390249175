import { Injectable } from '@angular/core';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { EVENTS_COLUMNS } from './events-column-def';
import { AgGridColumnDefBaseService } from '@shared/services/aggrid-column-def-base-service';

@Injectable()
export class EventColumnDefService extends AgGridColumnDefBaseService {
  constructor(translateService: I18nUtilsService) {
    super(translateService, 'events.list.headers', EVENTS_COLUMNS);
  }
}
