/* eslint-disable @typescript-eslint/no-empty-function */
import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-cell-status',
  templateUrl: './cell-status.component.html'
})
export class CellStatusComponent {
  params: ICellRendererParams | undefined;
  status = '';

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (params?.data?.trancheStatus) {
      this.status = params?.data?.trancheStatus;
    } else if (params?.data?.status.valueDescription) {
      this.status = params?.data?.status.valueDescription;
    } else if (params?.data?.status) {
      this.status = params?.data?.status;
    } else {
      this.status = this.params?.value.valueDescription;
    }
  }
}
