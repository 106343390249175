import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UpdateStatusRequestModel } from '@instruments/models';
import { FormService } from '@shared/services/forms/form.service';

@Injectable()
export class FongeTrancheFormService extends FormService<UpdateStatusRequestModel> {
  constructor(fb: UntypedFormBuilder) {
    super('tranche', fb, {
      ipaCode: fb.control(null),
      certifiedNominal: fb.control(null, Validators.required),
      increaseNumber: fb.control(null),
      issueDate: fb.control(null),
      trancheNumber: fb.control(null),
      partiallyCertified: fb.control(false)
    });
  }
}
