import { Component, Input } from '@angular/core';

import { InstrumentModel } from '@instruments/models';
import { InstrumentFormService } from '@instruments/services';
import { ProgrammeModel } from '@shared/models';

@Component({
  selector: 'app-instrument-header-info',
  templateUrl: './instrument-header-info.component.html'
})
export class InstrumentHeaderInfoComponent {
  @Input() programme: ProgrammeModel | null = null;

  constructor(public instrumentFormService: InstrumentFormService) {}

  get value(): Nullable<InstrumentModel> {
    return this.instrumentFormService.rawValue();
  }
}
