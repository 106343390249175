import { Injectable } from '@angular/core';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { AgGridColumnDefBaseService } from '@shared/services/aggrid-column-def-base-service';
import { DISPOSAL_COLUMNS } from './disposal-column-def';

@Injectable()
export class DisposalColumnDefService extends AgGridColumnDefBaseService {
  constructor(translateService: I18nUtilsService) {
    super(translateService, 'instruments.instrument.forms.disposal', DISPOSAL_COLUMNS);
  }
}
