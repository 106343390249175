/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SettlementStatusHistoryDto } from './settlementStatusHistoryDto';
import { IpaTypeDto } from './ipaTypeDto';


export interface SettlementDto { 
    id?: number;
    subscriberName?: string;
    subscriberClearing?: IpaTypeDto;
    subscriberAccountNumber?: string;
    paymentType?: IpaTypeDto;
    amount?: string;
    issuePrice?: string;
    settlementCurrency?: string;
    netAmount?: string;
    tradeDateAsString?: string;
    settlementDateAsString?: string;
    issuingAgentClearing?: IpaTypeDto;
    clearingAccountNumber?: IpaTypeDto;
    confirmationStatus?: IpaTypeDto;
    confirmationSgssGenerationDateAsString?: string;
    comments?: string;
    userValidation?: string;
    timestampValidation?: string;
    instrumentId?: number;
    trancheNumber?: number;
    status?: IpaTypeDto;
    settlementStatusHistoryDtos?: Array<SettlementStatusHistoryDto>;
}

