<div class="toaster-list m-2" aria-live="polite" aria-atomic="true">
    <div *ngFor="let toast of toasts; let i = index" class="toast toast-primary fade show {{getColor(toast)}}" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">
        <strong>{{ toast.title }}</strong>
        <button type="button" class="ms-2 mb-1 close" data-dismiss="toast" aria-label="Close" (click)="deleteToast(toast)">
          <em class="icon">close</em>
        </button>
      </div>
      <div class="toast-body">
        <p>{{ toast.detail }}</p>
      </div>
    </div>
</div>
