/**
 * issuer-ipa-events-extended API
 * Manage eventss data
 *
 * The version of the OpenAPI document: 1.1.15
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IpaTypeDto } from './ipaTypeDto';


export interface EventPositionEntitlementDto { 
    positionId?: number;
    tradingMethod?: IpaTypeDto;
    interventionType?: IpaTypeDto;
    isinCode?: string;
    eventId?: number;
    clsPosition?: number;
    clsQty?: number;
    clsEligible?: number;
    clsNonEligible?: number;
    clsAmount?: number;
    clsUnderlyingQty?: number;
    clsAccountNumber?: string;
    eocQty?: number;
    eocEligible?: number;
    eocNonEligible?: number;
    eocAmount?: number;
    eocUnderlyingQty?: number;
    eocAccountNumber?: string;
    version?: number;
    outstandingPosition?: number;
    totalQty?: number;
    totalEligible?: number;
    totalNonEligible?: number;
    totalAmount?: number;
    totalUnderlyingQty?: number;
    createdBy?: string;
    modifiedBy?: string;
    lastModifiedOn?: string;
    createdOn?: string;
    positionDiffered?: boolean;
    isQtyUpdated?: boolean;
    clsBlockedPosition?: number;
    clsSemeReference?: string;
    eocBlockedPosition?: number;
    eocSemeReference?: string;
    isClsPaid?: boolean;
    isEocPaid?: boolean;
    eocPosition?: number;
}

