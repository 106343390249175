<app-header [backButton]="false" containerType="container-fluid">
  <div headerBody class="d-flex flex-row justify-content-between mb-1 mt-1">
    <h1 class="display-3 line-height-1" translate>positions.list.title</h1>
    <app-add-operation [disableNewOperation]="disableNewOperation" *ngIf="canCreateOperation" [clearings]="clearings"></app-add-operation>
  </div>
  <div subheaderBody class="row">
    <div class="col-md-12">
      <ul class="nav nav-underline">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['./search']" routerLinkActive="active" translate>
            positions.nav.position-search</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['./operations']" routerLinkActive="active"
            translate>positions.nav.operation-management</a>
        </li>
      </ul>
    </div>
  </div>
</app-header>

<div class="container-fluid content-margin-top">
    <router-outlet></router-outlet>
</div>
