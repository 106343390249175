/* eslint-disable @typescript-eslint/no-empty-function */
import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html'
})
export class TypeComponent {
  params: ICellRendererParams | undefined;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
}
