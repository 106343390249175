/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { IdDto } from '../model/models';
import { MovementOfPositionCriteriaSearchDto } from '../model/models';
import { MovementOfPositionResultDto } from '../model/models';
import { OperationCreateDto } from '../model/models';
import { OperationDto } from '../model/models';
import { OperationUpdateDto } from '../model/models';
import { PagingOperationDtoWithCriteriaDto } from '../model/models';
import { PagingResultDtoOperationDataFetchDto } from '../model/models';
import { PositionCriteriaSearchDto } from '../model/models';
import { Problem } from '../model/models';
import { ResultPositionDataFetchDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class PositionResourceService {

    protected basePath = 'https://issuer-ipa-develop.dev.socgen';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * createOperation
     * Create a new operation  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.operation.create; Authorities: SGM_ISS_PAY_api.issuer-ipa.operation.create
     * @param operationCreateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOperation(operationCreateDto: OperationCreateDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<IdDto>;
    public createOperation(operationCreateDto: OperationCreateDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<IdDto>>;
    public createOperation(operationCreateDto: OperationCreateDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<IdDto>>;
    public createOperation(operationCreateDto: OperationCreateDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {
        if (operationCreateDto === null || operationCreateDto === undefined) {
            throw new Error('Required parameter operationCreateDto was null or undefined when calling createOperation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<IdDto>(`${this.configuration.basePath}/api/v1/positions`,
            operationCreateDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getMovementsOfPosition
     * All movements of a position are returned  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.position.multiple.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.position.multiple.read
     * @param movementOfPositionCriteriaSearchDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMovementsOfPosition(movementOfPositionCriteriaSearchDto: MovementOfPositionCriteriaSearchDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<MovementOfPositionResultDto>;
    public getMovementsOfPosition(movementOfPositionCriteriaSearchDto: MovementOfPositionCriteriaSearchDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<HttpResponse<MovementOfPositionResultDto>>;
    public getMovementsOfPosition(movementOfPositionCriteriaSearchDto: MovementOfPositionCriteriaSearchDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<HttpEvent<MovementOfPositionResultDto>>;
    public getMovementsOfPosition(movementOfPositionCriteriaSearchDto: MovementOfPositionCriteriaSearchDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<any> {
        if (movementOfPositionCriteriaSearchDto === null || movementOfPositionCriteriaSearchDto === undefined) {
            throw new Error('Required parameter movementOfPositionCriteriaSearchDto was null or undefined when calling getMovementsOfPosition.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<MovementOfPositionResultDto>(`${this.configuration.basePath}/api/v1/positions/_getMovementsOfPosition`,
            movementOfPositionCriteriaSearchDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getOperationById
     * Get operation by id  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.operation.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.operation.read
     * @param id Id of operation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOperationById(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<OperationDto>;
    public getOperationById(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<OperationDto>>;
    public getOperationById(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<OperationDto>>;
    public getOperationById(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getOperationById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<OperationDto>(`${this.configuration.basePath}/api/v1/positions/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchPaginatedOperations
     * All operations are returned  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.operation.multiple.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.operation.multiple.read
     * @param pagingOperationDtoWithCriteriaDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPaginatedOperations(pagingOperationDtoWithCriteriaDto: PagingOperationDtoWithCriteriaDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<PagingResultDtoOperationDataFetchDto>;
    public searchPaginatedOperations(pagingOperationDtoWithCriteriaDto: PagingOperationDtoWithCriteriaDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<HttpResponse<PagingResultDtoOperationDataFetchDto>>;
    public searchPaginatedOperations(pagingOperationDtoWithCriteriaDto: PagingOperationDtoWithCriteriaDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<HttpEvent<PagingResultDtoOperationDataFetchDto>>;
    public searchPaginatedOperations(pagingOperationDtoWithCriteriaDto: PagingOperationDtoWithCriteriaDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<any> {
        if (pagingOperationDtoWithCriteriaDto === null || pagingOperationDtoWithCriteriaDto === undefined) {
            throw new Error('Required parameter pagingOperationDtoWithCriteriaDto was null or undefined when calling searchPaginatedOperations.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PagingResultDtoOperationDataFetchDto>(`${this.configuration.basePath}/api/v1/positions/do-search`,
            pagingOperationDtoWithCriteriaDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchPosition
     * All positions are returned  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.position.multiple.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.position.multiple.read
     * @param positionCriteriaSearchDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPosition(positionCriteriaSearchDto: PositionCriteriaSearchDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<ResultPositionDataFetchDto>;
    public searchPosition(positionCriteriaSearchDto: PositionCriteriaSearchDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<HttpResponse<ResultPositionDataFetchDto>>;
    public searchPosition(positionCriteriaSearchDto: PositionCriteriaSearchDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<HttpEvent<ResultPositionDataFetchDto>>;
    public searchPosition(positionCriteriaSearchDto: PositionCriteriaSearchDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<any> {
        if (positionCriteriaSearchDto === null || positionCriteriaSearchDto === undefined) {
            throw new Error('Required parameter positionCriteriaSearchDto was null or undefined when calling searchPosition.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ResultPositionDataFetchDto>(`${this.configuration.basePath}/api/v1/positions/_search`,
            positionCriteriaSearchDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateOperation
     * Update an operation  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.operation.update; Authorities: SGM_ISS_PAY_api.issuer-ipa.operation.update
     * @param operationUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOperation(operationUpdateDto: OperationUpdateDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any>;
    public updateOperation(operationUpdateDto: OperationUpdateDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<any>>;
    public updateOperation(operationUpdateDto: OperationUpdateDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<any>>;
    public updateOperation(operationUpdateDto: OperationUpdateDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {
        if (operationUpdateDto === null || operationUpdateDto === undefined) {
            throw new Error('Required parameter operationUpdateDto was null or undefined when calling updateOperation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/v1/positions`,
            operationUpdateDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateOperationStatus
     * Update Status for an Operation  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.operation.update.status; Authorities: SGM_ISS_PAY_api.issuer-ipa.operation.update.status
     * @param actionCodeEnum Information will be using for update status of Object (Operation)
     * @param id Id of operation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOperationStatus(actionCodeEnum: 'VALIDATE' | 'CANCEL', id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any>;
    public updateOperationStatus(actionCodeEnum: 'VALIDATE' | 'CANCEL', id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<any>>;
    public updateOperationStatus(actionCodeEnum: 'VALIDATE' | 'CANCEL', id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<any>>;
    public updateOperationStatus(actionCodeEnum: 'VALIDATE' | 'CANCEL', id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {
        if (actionCodeEnum === null || actionCodeEnum === undefined) {
            throw new Error('Required parameter actionCodeEnum was null or undefined when calling updateOperationStatus.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateOperationStatus.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (actionCodeEnum !== undefined && actionCodeEnum !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>actionCodeEnum, 'actionCodeEnum');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/v1/positions/updateOperationStatuses/${encodeURIComponent(String(id))}`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
