import { Injectable } from '@angular/core';

import { UnionFormService } from '@shared/services/forms/union.form.service';
import { OperationPagingWithCriteriaModel, OperationSearchArrayType, OperationSearchCriteriaModel } from '../models/operation-search-criteria.model';
import { OperationCriteriaSearchFormService } from './operation-criteria-search.form.service';
import { OperationPagingCriteriaSearchFormService } from './operation-paging-criteria-search.form.service';

@Injectable()
export class OperationSearchService extends UnionFormService<OperationSearchArrayType> {
  /**
   *
   */
  constructor(private readonly operationCriteriaSearchFormService: OperationCriteriaSearchFormService, readonly pagingRequestWithCriteriaFormService: OperationPagingCriteriaSearchFormService) {
    super('operation-search', operationCriteriaSearchFormService, pagingRequestWithCriteriaFormService);
  }
  public getfields(): (keyof (OperationSearchCriteriaModel & OperationPagingWithCriteriaModel))[] {
    const array = Object.keys(this.operationCriteriaSearchFormService.fields);
    array.push('pageNumber');
    return array as (keyof (OperationSearchCriteriaModel & OperationPagingWithCriteriaModel))[];
  }
}
