import { MouvementModel } from './mouvement.model';

export interface MouvementPagingRequestModel {
  pageNumber?: number;
  totalItems?: number;
  itemsByPage?: number;
}
export interface PagingResultDtoMouvementDataFetchModel {
  totalItems?: number;
  totalPages?: number;
  currentPage?: number;
  items?: MouvementModel[];
}
