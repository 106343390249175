import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { PartySingleSelectComponent } from './party-single-select.component';

@NgModule({
  declarations: [PartySingleSelectComponent],
  exports: [PartySingleSelectComponent],
  imports: [FormsModule, ReactiveFormsModule, NgSelectModule, CommonModule]
})
export class PartySingleSelectModule {}
