import { BdrIdDataDto } from '@apis/backend/instruments/model/models';

export interface ProgrammeInformation {
  leiCode?: string;
  bdrId?: string;
  fullLegalName?: string;
  rctIdentifier?: string;
  businessEntity?: string;
  // roles?: string[];
  relationshipNature?: string;
  status?: string;
}

export const toProgrammeInformation = (leiCode: string, bdrIdData: BdrIdDataDto | null | undefined): ProgrammeInformation | null => {
  if (!bdrIdData) {
    return null;
  }

  return {
    leiCode: leiCode,
    bdrId: bdrIdData.bdrId,
    fullLegalName: bdrIdData.fullLegalName,
    rctIdentifier: bdrIdData.rctIdentifier,
    businessEntity: bdrIdData.businessEntity,
    // roles: bdrIdData.roles,
    relationshipNature: bdrIdData.relationshipNature,
    status: bdrIdData.status
  };
};
