import { Component, OnDestroy, OnInit } from '@angular/core';
import { TrancheModel } from '@instruments/models';
import { toCommonDepositoryDataFetchModel } from '@instruments/models/management-common-depository.model';
import { InstrumentFormService, InstrumentModalsService } from '@instruments/services';
import { CommonDepositoryFormService } from '@instruments/services/forms/common-depository.form.service';
import { DocumentationReceptionFormService } from '@instruments/services/forms/documentation-reception.form.service';
import { ManagementCommonDepositoryFormService } from '@instruments/services/forms/managmement-common-depository.form.service';
import { InstrumentService } from '@shared/services/instrument.service';
import { PermissionService } from '@shared/services/permission.service';
import { Subject, Subscription, of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-common-depository-container',
  templateUrl: './common-depository-container.component.html',
  providers: [ManagementCommonDepositoryFormService, DocumentationReceptionFormService, CommonDepositoryFormService]
})
export class CommonDepositoryContainerComponent implements OnInit, OnDestroy {
  public canSave = false;
  public canEdit = false;
  public instrumentId: number | undefined;
  public selectedTranche: TrancheModel | undefined;
  private readonly subscriptions: Subscription[] = [];
  public shutdown$ = new Subject<void>();
  constructor(
    public readonly formService: ManagementCommonDepositoryFormService,
    private readonly instrumentModalsService: InstrumentModalsService,
    private readonly instrumentFormService: InstrumentFormService,
    private readonly instrumentService: InstrumentService,
    private readonly permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    const instrument = this.instrumentFormService.value();
    this.instrumentId = instrument?.id;
    this.subscriptions.push(
      this.instrumentService
        .getGlobalNoteByInstrumentId(this.instrumentId ?? -1)
        .pipe(takeUntil(this.shutdown$))
        .subscribe(result => {
          if (result) {
            this.formService.setInitialValue(toCommonDepositoryDataFetchModel(this.instrumentId ?? -1, result));
          }
        })
    );

    this.formService.setFormMode('consult');

    this.permissionService.canEditCommonDep$
      .pipe(
        tap(canEditCommonDep => (this.canEdit = canEditCommonDep)),
        takeUntil(this.shutdown$)
      )
      .subscribe();
  }

  onEditClick(): void {
    this.formService.setFormMode('edit');
    this.canEdit = false;
    this.canSave = true;
  }

  onCancelClick(): void {
    const modal = this.instrumentModalsService.openCancelModal();
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        this.formService.setFormMode('edit');
      } else {
        this.canEdit = true;
        this.canSave = false;
        this.formService.reset();
        this.formService.setFormMode('consult');
      }
    });
  }

  onSaveClick(): void {
    const modal = this.instrumentModalsService.openSaveCommonDepositary();
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        this.formService.setFormMode('edit');
        return;
      }

      const data = this.formService.value();
      if (this.instrumentId && data) {
        this.instrumentService
          .createOrUpdateGlobalNote(data)
          .pipe(
            tap(() => {
              this.canEdit = true;
              this.canSave = false;
              this.formService.setFormMode('consult');
              this.formService.setInitialValue(this.formService.rawValue() as any);
            }),
            catchError(error => of(error)),
            takeUntil(this.shutdown$)
          )
          .subscribe();
      }
    });
  }

  ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}
