export interface PositionsTotalGridModel {
  readonly tradingMethod?: string;
  readonly totalPositionNominal?: string;
  readonly totalPositionUnits?: string;
  readonly totalQuantity?: number | null;
  readonly totalAdjustedQuantity?: number | null;
  readonly totalNonEligibleUnits?: number;
  readonly totalEligibleNominal?: number;
  readonly totalNonEligibleNominal?: number;
  readonly totalEligibleUnits?: number;
  readonly totalAmount?: number;
  readonly totalNbrUndelying?: number;
  readonly isEditable?: boolean;
  readonly isin?: string;
  readonly blankColumn?: string;
  readonly currency?: string[];
}
