/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { PermissionService } from '@shared/services/permission.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from '@shared/services/forms/form.service';

import { UnionFormService } from '@shared/services/forms/union.form.service';
import { PagingBaseModel } from './paging-base.model';
import { PagingCriteriaFormService } from '@shared/search-base/paging-criteria-form.service';
import { isEmptyOrSpaces } from '@utils/utility-functions';
@Directive()
export abstract class SearchContainerWithPaginationBaseComponent<
  M,
  C,
  QM extends Partial<UnionToIntersection<PC | C>> | null,
  PC extends PagingBaseModel,
  PagingtWithCriteriaFormService extends PagingCriteriaFormService<PC>,
  CriteriaSearchFormService extends FormService<C>,
  UnionSearchFormService extends UnionFormService<[C, PC]>
> implements OnInit, OnDestroy{
  protected readonly shutdown$ = new Subject<void>();
  public models: M[] = [];
  protected searchCriteria: C | null | undefined;
  constructor(
    protected readonly pagingFormService: PagingtWithCriteriaFormService,
    protected readonly criteriaFormService: CriteriaSearchFormService,
    public readonly permissionService: PermissionService,
    public readonly searchUnionFormService: UnionSearchFormService,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly router: Router,
    private readonly _itemsByPage: number,
    protected readonly baseUrl: string | string[]
  ) {}
  ngOnInit(): void {
    this.init();
    this.subsribeToPageChanges();
    this.initSearch();
    this.subscribeToFormChanges();
    if (this.searchCriteria && Object.values(this.searchCriteria).some(q => q !== undefined && q !== null || (typeof q == 'string' && !isEmptyOrSpaces(q)))) {
      this.search();
    }
    this.initPermissions();
  }
  protected init(): void {
    this.pagingFormService.patch('itemsByPage', this._itemsByPage);
    const queryString = this.activatedRoute.snapshot.queryParams.query;
    if (queryString) {
      const query = JSON.parse(queryString) as QM;
      this.searchUnionFormService.setInitialValue(query);
    } else {
      this.pagingFormService.patch('pageNumber', 1, { emitEvent: false });
    }
  }
  protected subscribeToFormChanges(): void {
    const Allkeys = [...Object.keys(this.criteriaFormService.fields), 'pageNumber'] as (keyof UnionToIntersection<PC | C>)[];
    this.searchUnionFormService
      .valueChanges(Allkeys)
      .pipe(takeUntil(this.shutdown$))
      .subscribe(q => {
        const query = this.removeEmpty(q);
        const stringfiedPredicate = JSON.stringify(query);
        this.router.navigate([this.baseUrl], { queryParams: { query: stringfiedPredicate } });
      });
  }
  protected subsribeToPageChanges(): void {
    this.pagingFormService
      .valueChanges('pageNumber')
      .pipe(takeUntil(this.shutdown$))
      .subscribe(_ => {
        this.load();
      });
  }
  public searchClick(withValidation = false): void {
    this.initOnSearchClick();
    if (withValidation && !this.validateSearch()) {
      return;
    }
    this.search();
  }
  protected validateSearch(): boolean {
    this.criteriaFormService.updateValueAndValidity();
    return this.criteriaFormService.isFormValid;
  }
  public search(): void {
    this.initSearch();
    this.load();
  }
  protected initSearch(): void {
    this.searchCriteria = this.criteriaFormService.value();
  }

  protected initOnSearchClick(): void {
    this.pagingFormService.patch('pageNumber', 1, { emitEvent: false });
  }
  protected abstract load(): void;
  protected abstract initPermissions(): void;
  get pageNumber(): number {
    return this.pagingFormService.value()?.pageNumber || 0;
  }
  ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.complete();
  }
  removeEmpty(obj: Nullable<Pick<UnionToIntersection<C | PC>, keyof UnionToIntersection<C | PC>>>): Partial<Nullable<Pick<UnionToIntersection<C | PC>, keyof UnionToIntersection<C | PC>>>> {
    return Object.entries(obj)
      .filter(([_, v]) => v != null)
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }
}
