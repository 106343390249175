import { Content } from '@apis/backend/instruments/model/models';

export interface DocumentContentModel {
  readonly mimeType: string | null;
  readonly size: number | null;
  readonly fileName: string | null;
  readonly uploadDateAsString: string | null;
  readonly creator: unknown | null;
  readonly applicationCreator: string | null;
}

export const toDocumentContentModel = (model: Content | null | undefined): DocumentContentModel | null => {
  if (!model) {
    return null;
  }

  return {
    mimeType: model.mimeType || null,
    size: model.size || null,
    fileName: model.fileName || null,
    uploadDateAsString: model.uploadDateAsString || null,
    creator: model.creator || null,
    applicationCreator: model.applicationCreator || null
  };
};
