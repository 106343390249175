import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { PagingWithCriteriaModel } from '@instruments/models/paging-with-criteria.model';

import { PagingCriteriaFormService } from '../../shared/search-base/paging-criteria-form.service';

@Injectable()
export class PagingRequestWithCriteriaFormService extends PagingCriteriaFormService<PagingWithCriteriaModel> {
  constructor(fb: UntypedFormBuilder) {
    super('InstrumentPaging', fb, {
      pageNumber: fb.control(0),
      itemsByPage: fb.control(10),
      totalItems: fb.control(0),
      initialized: fb.control(0),
      live: fb.control(0),
      closed: fb.control(0),
      cancelled: fb.control(0)
    });
  }
}
