import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormService } from '@shared/services/forms/form.service';
import { CustomValidators } from '@shared/validators/custom-validator';
import { PositionSearchCriteriaModel } from '../models/position-search-criteria.model';

export type PositionSearchModel = PositionSearchCriteriaModel;
export type PositionSearchQueryModel = Partial<PositionSearchCriteriaModel>;

@Injectable()
export class PositionCriteriaSearchFormService extends FormService<PositionSearchCriteriaModel> {
  constructor(fb: UntypedFormBuilder) {
    super('search_position', fb, {
      ipaCode: fb.control(null, [CustomValidators.someFieldRequiredValidatorValidator(['ipaCode', 'isin'])]),
      isin: fb.control(null, [CustomValidators.someFieldRequiredValidatorValidator(['ipaCode', 'isin'])]),
      valueDate: fb.control(null)
    });
  }
}
