/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FormsRules } from './formsRules';


export interface DocModel { 
    /**
     * id of model in sgDoc
     */
    readonly id?: string;
    /**
     * name of model
     */
    readonly name?: string;
    /**
     * Description of model
     */
    readonly description?: string;
    /**
     * default confidentiality level of model
     */
    readonly defaultConfidentialityLevel?: string;
    /**
     * minimum confidentiality level of model
     */
    readonly minimumConfidentialityLevel?: string;
    /**
     * List of rules for dynamic forms in frontEnd
     */
    readonly formsRules?: Array<FormsRules>;
}

