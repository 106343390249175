import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ISgPickerItems } from './items-selector.model';

@Component({
  selector: 'app-ttd-items-selector',
  templateUrl: './items-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ItemsSelectorComponent),
      multi: true
    }
  ]
})
export class ItemsSelectorComponent<T> implements ControlValueAccessor, OnChanges, OnInit, OnDestroy {
  @ViewChild('itemsPicker')
  itemsPicker: ElementRef | null = null;

  /**
  Property to set logic using for
  - signle selection = true
  - multi selection = false
  */
  @Input() singleSelect = true;

  /**
  Property to set used key in component selection
  */
  @Input() key: string | null = null;

  /**
  String list, define name of property to display label
  */
  @Input() labelProperties: string[] | null = null;

  /**
  Separator used in path label
  */
  @Input() labelSeparator = ' - ';

  /**
  Data used to bind list on picker
  Data type is generic
  */
  @Input() data: T[] | null = null;

  /**
  Property to truncate selection and display only account seclection:
  - normal display = false
  - truncate display = true
  */
  @Input() withSummary = false;

  @Input() icon = '';
  @Input() isReadOnly = false;
  @Input() maxDisplayedItems = 0;
  @Output() ready = new EventEmitter<boolean>(false);

  private _placeHolder: string | null = null;
  @Input() set placeHolder(value: string | null) {
    this._placeHolder = value;
    this.displayedPlaceholder = this.getDisplayedPlaceholder();
  }
  get placeHolder(): string | null {
    return this._placeHolder;
  }

  displayedPlaceholder: string | null = null;

  private propagateChange: any;
  private destroy$ = new Subject<void>();
  private componentIsReadySubject$ = new BehaviorSubject<boolean>(false);
  private dataSubject$ = new BehaviorSubject<T[] | null>(null);
  private labelPropertiesSubject$ = new BehaviorSubject<string[] | null>(null);
  private keySubject$ = new BehaviorSubject<string | null>(null);
  private selectedItemsSubject$ = new BehaviorSubject<any | null>(null);
  private selectedItems: any | null = null;

  ngOnInit(): void {
    combineLatest([
      this.componentIsReadySubject$,
      this.dataSubject$,
      this.labelPropertiesSubject$,
      this.keySubject$,
      this.selectedItemsSubject$.pipe(
        tap(items => {
          this.selectedItems = items;
          this.displayedPlaceholder = this.getDisplayedPlaceholder();
        })
      )
    ])
      .pipe(
        takeUntil(this.destroy$),
        filter(([ready, items, labelProperties, key]) => ready && !!items && Array.isArray(labelProperties) && !!key),
        tap(([_, items, labelProperties, key, selectedItems]) => {
          this.populateAndSetIncomingValuesSgPicker(items, labelProperties!, key!, selectedItems);
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { data, labelProperties, key } = changes;

    if (data && data.currentValue) {
      this.dataSubject$.next(data.currentValue);
    }

    if (labelProperties && labelProperties.currentValue) {
      this.labelPropertiesSubject$.next(labelProperties.currentValue);
    }

    if (key && key.currentValue) {
      this.keySubject$.next(key.currentValue);
    }
  }

  private populateAndSetIncomingValuesSgPicker(items: T[] | null, labelProperties: string[], key: string, selectedValue: any = null) {
    const pickerItems = items?.map((value: T) => this.toItemSgPickerItem(value, labelProperties, key)).filter(pickerItem => pickerItem.label) || [];
    this.itemsPicker?.nativeElement.setItems(pickerItems);
    if (selectedValue) {
      this.performSelection(selectedValue, key);
    }
  }

  private performSelection(value: any, key: string) {
    if (value && Array.isArray(value)) {
      for (const item of value) {
        this.itemsPicker?.nativeElement.selectItemByKey(item[key]);
      }
      return;
    }

    this.itemsPicker?.nativeElement.selectItemByKey(value[key]);
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /* eslint-disable @typescript-eslint/no-empty-function */
  public registerOnTouched(): void {}

  public writeValue(value: T | T[] | null): void {
    this.selectedItemsSubject$.next(value);
  }

  public onSelectItem(): void {
    this.itemsPicker?.nativeElement.getSelection().then((selectedItems: ISgPickerItems<T>[]) => {
      const tempSelectedItems = [...selectedItems];
      let item: T | T[] | null = null;
      if (tempSelectedItems && tempSelectedItems.length > 0) {
        item = this.singleSelect ? this.toOutputModel(tempSelectedItems[0]) : tempSelectedItems.map(selectedItem => this.toOutputModel(selectedItem));
      }
      this.selectedItems = item;
      this.displayedPlaceholder = this.getDisplayedPlaceholder();
      this.propagateChange(item);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  /**
   * check if component is ready
   */
  public onReady() {
    this.componentIsReadySubject$.next(true);
    this.ready.next(true);
  }

  /**
    @param item : T
    @returns item : ISgPickerItems<T>
   */
  private toItemSgPickerItem(item: T, labelProperties: string[], key: string): ISgPickerItems<T> {
    return {
      selected: false,
      key: (item as any)[key],
      label: labelProperties
        .map((value: string) => (item as any)[value])
        .filter(value => value)
        .join(this.labelSeparator),
      ...item
    };
  }
  /**
    @param item : ISgPickerItems<T>
    @returns item : T
   */
  private toOutputModel(item: ISgPickerItems<T>): T {
    const localItem = { ...item };

    if (this.data && this.key) {
      const selectedItem = (this.data as any[])?.find(x => x[this.key!] === localItem.key);
      return selectedItem;
    }

    delete localItem.label;
    delete localItem.key;
    delete localItem.selected;
    delete localItem.desc;
    return localItem;
  }

  private getDisplayedPlaceholder() {
    return this.selectedItems && (!Array.isArray(this.selectedItems) || this.selectedItems.length > 0) ? null : this._placeHolder;
  }
}
