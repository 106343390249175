<div class="row mt-5">
  <div class="col-md-12">
    <form (ngSubmit)="searchClick()">
      <app-position-list-filter [disableExportCsv]="disableSearch" (ngSubmit)="searchClick()" (exportCsvAction)="onExportCsvAction()">
        <app-text-icon-button *ngIf="canReadMultiplePosition" icon="search" [disabled]="disableSearch" searchTemplate [submit]="true" [customClasses]="'btn-icon-start'"
          label="{{ 'positions.list.search' | translate }}" size="md" buttonType="btn-outline-default">
        </app-text-icon-button>
      </app-position-list-filter>
    </form>
  </div>
</div>
<div class="softLoading" *ngIf="disableSearch">Loading</div>
<app-position-list [positions]="models"></app-position-list>
