/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, ViewEncapsulation } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';

type NullableDate = Date | undefined | null;

@Component({
  selector: 'app-editing-date-picker',
  templateUrl: './editing-date-picker.component.html',
  encapsulation: ViewEncapsulation.None
})
export class EditingDatePickerComponent implements ICellEditorAngularComp {
  private dateHasBeenSetOnce = false;
  private _date: NullableDate;
  params: any;
  get date(): NullableDate {
    return this._date;
  }
  set date(value: NullableDate) {
    if (this.dateHasBeenSetOnce && value !== this._date) {
      this._date = value;
      this.params.api.stopEditing(false);
    } else {
      this._date = value;
      this.dateHasBeenSetOnce = true;
    }
  }

  agInit(params: any): void {
    this.params = params;
    this.date = params.value ? new Date(params.value) : null;
  }

  getValue() {
    return this.date;
  }

  isPopup(): boolean {
    return true;
  }

  getPopupPosition?(): string | undefined {
    return 'under';
  }

  isCancelBeforeStart?(): boolean {
    return false;
  }

  isCancelAfterEnd?(): boolean {
    return false;
  }

  focusIn?(): void {}

  focusOut?(): void {}

  getFrameworkComponentInstance?() {}
  afterGuiAttached?(params?: IAfterGuiAttachedParams | undefined): void {}
}
