/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { InstrumentDataFetchModel } from '@instruments/models/instrument-data-fetch.model';
import { AmountFormatCellComponent } from '@shared/components/amount-format-cell/amount-format-cell.component';
import { CellStatusComponent } from '@shared/components/cell-status/cell-status.component';
import { MOMENT_DATE_FORMAT } from '@shared/pipes/date-format/common';
import { ColDef, ValueFormatterParams } from 'ag-grid-community/dist/lib/main';
import * as moment from 'moment';
import { InstrumentActionComponent } from './instrument-action/instrument-action.component';
import { InstrumentTypeComponent } from './instrument-type/instrument-type.component';
import { TypeComponent } from './type/type.component';

export interface InstrumentColumnDef extends ColDef {
  field: keyof (InstrumentDataFetchModel & InstrumentColumnsAction);
}
interface InstrumentColumnsAction {
  action: string;
}
export const INSTRUMENTS_COLUMNS: InstrumentColumnDef[] = [
  {
    field: 'type',
    cellRendererFramework: TypeComponent,
    width: 300,
    sortable: true,
    resizable: true
  },
  {
    field: 'instrumentType',
    cellRendererFramework: InstrumentTypeComponent,
    width: 250,
    sortable: true,
    resizable: true
  },
  {
    field: 'idInstrument',
    sortable: true,
    resizable: true,
    width: 180
  },
  {
    field: 'issuerName',
    valueFormatter: (q: ValueFormatterParams) => q.data.issuer?.name,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    comparator: (valueA, valueB, _nodeA, _nodeB, _isInverted) => {
      if (valueA === valueB) {
        return 0;
      }
      return valueA.name?.localeCompare(valueB?.name);
    },
    sortable: true,
    width: 400,
    resizable: true
  },
  {
    field: 'name',
    sortable: true,
    resizable: true
  },
  {
    field: 'isinCodeTranche',
    sortable: true,
    resizable: true
  },
  {
    field: 'programmeICSDNumber',
    sortable: true,
    resizable: true
  },
  {
    field: 'seriesNumber',
    sortable: true,
    resizable: true
  },
  {
    field: 'issueDate',
    valueFormatter: (q: ValueFormatterParams) => {
      if (!q.data.issueDate) {
        return '';
      }
      const momentDate = moment(q.data.issueDate);
      return momentDate.format(MOMENT_DATE_FORMAT);
    },
    sortable: true,
    resizable: true
  },
  {
    field: 'outStandingNominal',
    cellRendererFramework: AmountFormatCellComponent,
    sortable: true
  },
  {
    field: 'trancheStatus',
    cellRendererFramework: CellStatusComponent,
    width: 350,
    sortable: true,
    resizable: false
  },
  {
    field: 'action',
    cellRendererFramework: InstrumentActionComponent,
    sortable: true,
    minWidth: 150
  }
];
