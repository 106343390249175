import { Component, Input, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { EventPositionEntitlementDto } from '@apis/backend/payments';
import { PositionsGridModel, PositionsTotalGridModel, EventPositionInputModel } from '@events/models';
import { TYPED_DATA_TYPE, IpaTypeModel } from '@shared/models';
import { PositionFormService } from '@events/services/position.form.service';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { PositionFormModel } from '@events/models/positionFormModel';
import { LabelRootTranslateInjectionToken, FormServiceInjectionToken } from '@shared/modules/forms/tokens';
import { PositionsListComponent } from '@events/components';
import { EVENT_CONSTANTS as E } from '@events/configs/event-constants';
import { EventsService } from '@shared/services/events.service';
import { routeToEvent } from '@events/routes';
import { Router } from '@angular/router';
import { EventModalsService } from '@events/services';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';

@Component({
  selector: 'app-position-container',
  templateUrl: './position-container.component.html',
  styleUrls: ['position-container.component.scss'],
  providers: [
    { provide: FormServiceInjectionToken, useExisting: PositionFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'events.positions.form' }
  ]
})
export class PositionContainerComponent extends BaseFormComponent<PositionFormModel, PositionFormService> implements OnChanges {
  @ViewChild(PositionsListComponent) positionsListComponent!: PositionsListComponent;

  @Input() public positionData: EventPositionEntitlementDto[] = [];
  @Input() public pageType: string | undefined;
  @Input() public eventPositionInput: EventPositionInputModel | undefined;
  @Output() displaySwiftMessage = new EventEmitter<boolean>();
  @Output() displayPoolFactor = new EventEmitter<boolean>();
  @Output() displayEcashPayments = new EventEmitter<boolean>();
  @Output() positionsGridData = new EventEmitter<any>();
  public positions: PositionsGridModel[] = [];
  public positionsTotal: PositionsTotalGridModel[] = [];
  public eventId: number | undefined;
  public tradingMethod: string | undefined = '';
  public availableInterventionTypes: (string | undefined)[] = [];
  public INTERVENTION_TYPE: TYPED_DATA_TYPE = 'INTERVENTION_TYPE';
  public isPaymentDone = [E.status.valueId.paymentInRepair, E.status.valueId.paymentDone, E.status.valueId.paymentFailed, E.status.valueId.markDownDone, E.status.valueId.markDownPending, E.status.valueId.eventClosed];
  public positionGridData:
    | {
        positions: PositionsGridModel[];
        positionsTotal: PositionsTotalGridModel[];
      }
    | undefined;
  isin?: string;
  private readonly eventConsultRoute = '/events/consult';

  constructor(
    private readonly positionService: PositionFormService,
    private readonly eventService: EventsService,
    private readonly router: Router,
    private readonly modalsService: EventModalsService,
    private readonly toastManagerService: ToastManagerService
  ) {
    super(positionService, 'events.positions.form');
  }

  ngOnInit(): void {
    let interventionType: IpaTypeModel;
    if (this.positionData.length === 1 && this.positionData[0].interventionType) {
      interventionType = this.positionData[0].interventionType;
      this.formService.patchField('interventionType', interventionType);
      this.setPositionData(interventionType);
    } else {
      this.setPositionData();
    }
    this.positionsGridData.emit(this.positionGridData);
  }

  ngOnChanges() {
    this.setPositionData();
  }

  saveModifiedRows(doPositionAmountRecalculation: boolean) {
    this.positionsListComponent.saveModifiedRows(doPositionAmountRecalculation);
  }

  getNotificationList() {
    this.displaySwiftMessage.emit(true);
  }

  getPoolFactor() {
    this.displayPoolFactor.emit(true);
  }

  getEcashPayments() {
    this.displayEcashPayments.emit(true);
  }
  private setPositionData(interventionType?: IpaTypeModel) {
    let position = null;
    if (this.positionData.length === 1 && interventionType != null) {
      position = this.positionData[0];
      this.formService.patchField('interventionType', interventionType);
    } else if (this.positionData.length > 1) {
      if (!interventionType) {
        position = this.positionData.find(x => x.version === undefined);
        if (position) {
          this.formService.patchField('interventionType', undefined);
        } else {
          const interventionVersions = this.positionData.map(x => x.version).filter(x => x !== undefined) as number[];
          const latestIntervention = Math.max.apply(null, interventionVersions);
          position = this.positionData.find(x => x.version === latestIntervention);
          this.formService.patchField('interventionType', position?.interventionType);
        }
      } else if (interventionType) {
        this.formService.patchField('interventionType', interventionType);
        position = this.positionData.find(x => x.interventionType?.valueDescription === interventionType.valueDescription);
      }
    } else {
      position = this.positionData.find(x => x.interventionType === undefined);
      this.formService.patchField('interventionType', undefined);
    }

    this.availableInterventionTypes = this.positionData.length > 0 ? this.positionData.map(item => item.interventionType?.valueDescription).filter((value, index, self) => self.indexOf(value) === index) : [undefined];
    this.eventId = this.positionData.length > 0 && this.positionData[0].eventId ? this.positionData[0].eventId : undefined;
    this.tradingMethod = this.eventPositionInput?.tradingType;
    if (position) {
      this.positions = [
        {
          systemType: 'Clearstream',
          positionNominal: position.clsPosition,
          positionUnits: position.clsPosition,
          quantity: position.clsQty,
          eligibleNominal: position.clsEligible,
          nonEligibleNominal: position.clsNonEligible,
          eligibleUnits: position.clsEligible,
          nonEligibleUnits: position.clsNonEligible,
          amount: position.clsAmount,
          nbrUnderlying: position.clsUnderlyingQty,
          totalNominal: position.clsPosition,
          totalUnits: position.clsPosition,
          blockedQuantity: position.clsBlockedPosition,
          quantityToUnblock: position.clsQty,
          positionToCertify: position.clsQty,
          semeReference: position.clsSemeReference,
          isEditable: (!interventionType || interventionType.valueId === 4) && this.pageType === 'edit' ? true : false,
          isClsPaid: position.isClsPaid,
          eventType: this.eventPositionInput?.eventTypeValue,
          currency: this.eventPositionInput?.paymentCcy,
          paymentDate: this.eventPositionInput?.paymentDate,
          fundArrivalDate: this.eventPositionInput?.fundArrivalDate,
          eventStatus: this.eventPositionInput?.eventStatus,
          adjustedQuantity: this.eventPositionInput?.clsAdjustedQuantity
        },
        {
          systemType: 'Euroclear',
          positionNominal: position.eocPosition,
          positionUnits: position.eocPosition,
          quantity: position.eocQty,
          eligibleNominal: position.eocEligible,
          nonEligibleNominal: position.eocNonEligible,
          eligibleUnits: position.eocEligible,
          nonEligibleUnits: position.eocNonEligible,
          amount: position.eocAmount,
          nbrUnderlying: position.eocUnderlyingQty,
          totalUnits: position.eocPosition,
          totalNominal: position.eocPosition,
          blockedQuantity: position.eocBlockedPosition,
          quantityToUnblock: position.eocQty,
          positionToCertify: position.eocQty,
          semeReference: position.eocSemeReference,
          isEditable: (!interventionType || interventionType.valueId === 4) && this.pageType === 'edit' ? true : false,
          isEocPaid: position.isEocPaid,
          eventType: this.eventPositionInput?.eventTypeValue,
          currency: this.eventPositionInput?.paymentCcy,
          paymentDate: this.eventPositionInput?.paymentDate,
          fundArrivalDate: this.eventPositionInput?.fundArrivalDate,
          eventStatus: this.eventPositionInput?.eventStatus,
          adjustedQuantity: this.eventPositionInput?.eocAdjustedQuantity
        }
      ];

      const positionaNominal = this.eventPositionInput?.outstandingNominal ? Number(this.eventPositionInput?.outstandingNominal).toPrecision() : undefined;
      this.positionsTotal = [
        {
          tradingMethod: this.eventPositionInput?.tradingType,
          totalPositionNominal: positionaNominal,
          totalPositionUnits: positionaNominal,
          totalQuantity: position.totalQty,
          totalNonEligibleNominal: position.totalNonEligible,
          totalEligibleNominal: position.totalEligible,
          totalNonEligibleUnits: position.totalNonEligible,
          totalEligibleUnits: position.totalEligible,
          totalAmount: position.totalAmount,
          totalNbrUndelying: position.totalUnderlyingQty,
          isEditable: this.pageType === 'edit' && (position.eocQty === null || position.eocQty === 0) && (position.clsQty === null || position.clsQty === 0) ? true : false,
          isin: position.isinCode,
          currency: this.eventPositionInput?.paymentCcy,
          totalAdjustedQuantity: this.eventPositionInput?.totalAdjustedQuantity
        }
      ];
      this.isin = position.isinCode;
    } else if (this.eventPositionInput?.isSGLuxNotCommonDepOrServiceProvider) {
      const positionNominal = this.eventPositionInput?.outstandingNominal ? Number(this.eventPositionInput?.outstandingNominal).toPrecision() : undefined;
      this.positionsTotal = [
        {
          tradingMethod: this.eventPositionInput?.tradingType,
          totalPositionNominal: positionNominal,
          totalPositionUnits: positionNominal,
          totalAdjustedQuantity: this.eventPositionInput?.totalAdjustedQuantity,
          isEditable: this.pageType === 'edit'
        }
      ];
      this.positions = [
        {
          systemType: 'Clearstream',
          quantity: null,
          isEditable: !interventionType && this.pageType === 'edit' ? true : false
        },
        {
          systemType: 'Euroclear',
          quantity: null,
          isEditable: !interventionType && this.pageType === 'edit' ? true : false
        }
      ];
    } else {
      this.positions = [];
      this.positionsTotal = [];
    }
    this.positionGridData = { positions: this.positions, positionsTotal: this.positionsTotal };
  }

  interventionTypeChanged() {
    this.setPositionData(this.formService.rawValue('interventionType') ?? undefined);
  }

  setUpdatePositionData() {
    this.router.navigateByUrl(this.eventConsultRoute, { skipLocationChange: true }).then(() => {
      this.router.navigate(routeToEvent(this.eventPositionInput?.eventId, 'consult'));
    });
  }

  releasePayment() {
    const modal = this.modalsService.openReleasePaymentModal(this.positions, this.positionsTotal, this.eventPositionInput);
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        return;
      }
      this.confirmReleasePayment();
    });
  }

  private confirmReleasePayment() {
    if (this.eventId !== null) {
      const eventId = this.eventId;
      this.eventService.updateEventStatus(Number(eventId), 20).subscribe(result => {
        if (result) {
          this.router.navigateByUrl(this.eventConsultRoute, { skipLocationChange: true }).then(() => {
            this.router.navigate(routeToEvent(eventId, 'consult'));
            this.toastManagerService.toastSuccess('toasts.events.releasePayment.title', 'toasts.events.releasePayment.message', undefined, result);
          });
        }
      });
    }
  }

  downloadRequestOfFunds() {
    if (this.eventPositionInput?.downloadRof) {
      this.eventService.getDocumentContent(this.eventPositionInput?.downloadRof, this.eventId ?? -1, this.eventPositionInput?.eventTypeValue, this.isin ?? '');
    }
  }

  updateStatusToGenerateReqOfFunds() {
    if (this.eventPositionInput?.enableGenerateRequestOfFunds) {
      this.eventService.updateEventStatus(Number(this.eventId), 12).subscribe(result => {
        if (result) {
          this.router.navigateByUrl(this.eventConsultRoute, { skipLocationChange: true }).then(() => {
            this.router.navigate(routeToEvent(this.eventId, 'consult'));
          });
        }
      });
    }
  }

  generatePreadvise() {
    if (this.eventId !== null) {
      const eventId = this.eventId;
      this.eventService.updateEventStatus(Number(eventId), 14).subscribe(result => {
        if (result) {
          this.router.navigateByUrl(this.eventConsultRoute, { skipLocationChange: true }).then(() => {
            this.router.navigate(routeToEvent(eventId, 'consult'));
          });
        }
      });
    }
  }

  public get isExchCertEvent(): boolean {
    return this.eventPositionInput?.eventTypeValue === E.eventTypes.EXCH || this.eventPositionInput?.eventTypeValue === E.eventTypes.CERT;
  }

  public get enableRofDownload(): boolean {
    return this.eventPositionInput?.downloadRof ? true : false;
  }

  public get enableGenerateRequestOfFunds(): boolean {
    return this.eventPositionInput?.enableGenerateRequestOfFunds ? true : false;
  }

  public get enablePoolFactorHistoryList(): boolean {
    return this.eventPositionInput?.eventTypeValue === E.eventTypes.PRED ? true : false;
  }

  public get enableEcashPaymentsList(): boolean {
    const eventStatus = this.eventPositionInput?.eventStatus;
    if (eventStatus === E.status.valueId.eventClosed && (this.eventPositionInput?.eventTypeValue === E.eventTypes.EXCH || this.eventPositionInput?.eventTypeValue === E.eventTypes.CERT)) {
      return false;
    }
    return eventStatus ? this.isPaymentDone.includes(eventStatus) : false;
  }

  ngOnDestroy() {
    this.formService.reset();
  }
}
