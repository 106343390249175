/* eslint-disable @typescript-eslint/no-empty-function */
import { EMPTY, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { PartyService } from '@shared/services/party.service';
import { PartyModel, ROLES_TYPE, TYPE_FILTER_TYPE } from '@shared/models';

type NullablePartyModelOrArray = PartyModel | PartyModel[] | undefined;

@Component({
  selector: 'app-party-single-select',
  templateUrl: './party-single-select.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PartySingleSelectComponent),
      multi: true
    }
  ]
})
export class PartySingleSelectComponent implements ControlValueAccessor, OnInit {
  @Input() multiple = false;

  @Input() closeOnSelect = false;
  @Input() classes = '';
  @Input() withDebounce = false;
  @Input() type: ROLES_TYPE | undefined = undefined;
  @Input() filter: TYPE_FILTER_TYPE | undefined = undefined;
  @Input() placeholder: string | null = null;

  @Input() hasError = false;
  @Output() blurEmit: EventEmitter<void> = new EventEmitter();

  @Output() inputEmit: EventEmitter<string> = new EventEmitter();
  private selection: NullablePartyModelOrArray = undefined;
  public items$!: Observable<PartyModel[]>;
  public isReadOnly = false;
  public focusId: string | null = null;

  constructor(private readonly typeService: PartyService) {}

  private sortPartyModel(a: PartyModel, b: PartyModel) {
    const nameA = a.name || '';
    const nameB = b.name || '';
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  ngOnInit(): void {
    if (this.type && this.filter) {
      this.items$ = this.typeService.getPartiesByRoles(this.type, this.filter).pipe(
        first(),
        map(r => [...r].sort(this.sortPartyModel))
      );
    } else {
      this.items$ = EMPTY;
    }
  }
  get value(): NullablePartyModelOrArray {
    return this.selection;
  }
  set value(value: NullablePartyModelOrArray) {
    this.selection = value || undefined;
    this.onChange(value);
  }
  get displayValue(): PartyModel[] {
    if (!this.value) {
      return [];
    }

    return Array.isArray(this.value) ? this.value : [this.value];
  }

  public onBlurEmit() {
    this.onTouched();
    this.blurEmit.next();
  }
  public onInputEmit(value: string) {
    this.inputEmit.next(value);
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  setDisabledState(isDisabled: boolean): void {
    this.isReadOnly = isDisabled;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: PartyModel[]): void {
    this.selection = value;
  }
}
