import { Injectable } from '@angular/core';

import { DisplayService } from '@shared/services/display/display.service';
import { OperationCreationModel } from '@positions/models';
import { IpaTypeModelRules as R, BooleanRules as B } from '@instruments/rules';
import { OperationCreationFormService } from '../operation-creation.form.service';

@Injectable()
export class OperationDisplayService extends DisplayService<OperationCreationModel> {
  constructor(os: OperationCreationFormService) {
    super(os);

    this.with(os, 'icsd').rules({
      when: ({ icsd }) => {
        return B.isDefined(icsd) && icsd?.some(icsd => R.isEUROCLEAR(icsd));
      },
      displayFields: ['value', 'operation', 'sendSwift', 'SEMEReference', 'icsdAccountNumber']
    });

    this.with(os, 'icsd').rules({
      when: ({ icsd }) => {
        return B.isDefined(icsd) && icsd?.some(icsd => R.isClEARSTREAM(icsd));
      },
      displayFields: ['ClearValue', 'ClearOperation', 'ClearSendSwift', 'ClearSEMEReference', 'ClearIcsdAccountNumber']
    });
  }
}
