import { Directive, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActiveFilterItem, ReferenceDataModel } from '@shared/models';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { isEmptyOrSpaces, notEmpty } from '@utils/utility-functions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormService } from '@shared/services/forms/form.service';

type ReferenceDataModelMulti = string | boolean | Date | ReferenceDataModel;

@Directive()
export abstract class ActiveFiltersBaseComponent<T> implements OnInit, OnDestroy {
  public filterItems: ActiveFilterItem<T>[] = [];
  public shutdown$ = new Subject<void>();

  constructor(public readonly criteriaFormService: FormService<T>, protected readonly modalService: NgbModal, public readonly i18nUtils: I18nUtilsService, protected readonly keyBase: string, protected readonly i18nKeyBase: string) {}

  ngOnInit(): void {
    this.criteriaFormService
      .valueChanges()
      .pipe(takeUntil(this.shutdown$))
      .subscribe(filters => {
        this.filterItems = [];
        Object.values<keyof T>(this.criteriaFormService.fields).forEach(k => {
          const v = filters[k];
          if (typeof v == 'string' && isEmptyOrSpaces(v)) {
            return;
          }
          if (v !== null && v !== undefined) {
            if (Array.isArray(v)) {
              if (!v.some(_ => true)) {
                return;
              }
              this.filterItems.push({ key: k, value: v as ReferenceDataModelMulti[], isMultipleValuesFilter: true });
            } else {
              this.filterItems.push({ key: k, value: v as ReferenceDataModelMulti, isMultipleValuesFilter: false });
            }
          }
        });
        this.onFilterChanged(this.filterItems);
      });
  }
  public remove(item: ActiveFilterItem<T>): void {
    this.filterItems = this.filterItems.filter(q => q !== item).filter(notEmpty);

    const control = this.criteriaFormService.control(item.key);
    control.reset();
  }
  public clear(): void {
    this.filterItems.forEach(item => {
      const control = this.criteriaFormService.control(item.key);
      control.reset();
    });

    this.filterItems = [];
  }
  public get isShown(): boolean {
    return this.filterItems.some(_ => true);
  }
  public abstract saveFilters(): NgbModalRef | null;

  public abstract onFilterChanged(items: ActiveFilterItem<T>[]): void;
  public label(key: keyof T): string {
    return this.i18nUtils.getI18nValue(`${this.i18nKeyBase}.${key.toString()}`);
  }
  ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}
