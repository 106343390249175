import { Component, OnDestroy, OnInit } from '@angular/core';
import { DocumentFileModel, DocumentTypeInjectionToken } from '@documents/models';
import { DocumentFormService, DocumentService } from '@documents/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-settlements-confirmations-reports',
  templateUrl: './settlements-confirmations-reports.component.html',
  providers: [DocumentFormService, DocumentService, { provide: DocumentTypeInjectionToken, useValue: 'confirmationReports' }]
})
export class SettlementsConfirmationsReportsComponent implements OnInit, OnDestroy {
  public readonly shutdown$ = new Subject<void>();
  isLoadingDocument = false;

  documents: DocumentFileModel[] = [];
  constructor(public readonly activeModal: NgbActiveModal, private readonly documentService: DocumentService) {}

  ngOnInit(): void {
    this.loadDocuments('confirmationReports');
  }
  private loadDocuments(instrumentId: string) {
    this.isLoadingDocument = true;
    this.documentService
      .searchDocuments(instrumentId)
      .pipe(first())
      .pipe(takeUntil(this.shutdown$))
      .subscribe(res => {
        this.documents = res;
        this.isLoadingDocument = false;
      });
  }
  public cancel(): void {
    this.activeModal.dismiss('closed');
    this.activeModal.close('closed');
  }
  ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}
