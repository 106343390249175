import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { GlobalNoteModel, InstrumentModel } from '@instruments/models';
import { GlobalNoteFormService, InstrumentFormService } from '@instruments/services';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { IpaTypeModel, PartyModel, ProgrammeModel, ROLES_TYPE, toIpaTypeModelFromTypedDataModel, TypedDataModel, TYPED_DATA_TYPE, TYPE_FILTER_TYPE } from '@shared/models';
import { combineLatest, Subject } from 'rxjs';
import { startWith, takeUntil, takeWhile } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { INSTRUMENT_CONSTANTS } from '@instruments/configs/instrument-constants';

interface InterfieldErrorParams extends Record<string, string> {
  field1: string;
  field2: string;
}

@Component({
  selector: 'app-instrument-global-note',
  templateUrl: './instrument-global-note.component.html',
  providers: [
    { provide: FormServiceInjectionToken, useExisting: GlobalNoteFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'instruments.instrument.forms.instrumentGlobalNote' }
  ]
})
export class InstrumentGlobalNoteComponent extends BaseFormComponent<GlobalNoteModel, GlobalNoteFormService> implements OnInit, OnDestroy {
  public readonly GLOBAL_NOTE_FORM: TYPED_DATA_TYPE = 'GLOBAL_NOTE_FORM';
  public readonly LEGAL_FORM: TYPED_DATA_TYPE = 'LEGAL_FORM';
  public readonly PHYSICAL_FORM: TYPED_DATA_TYPE = 'PHYSICAL_FORM';
  private readonly shutdown$ = new Subject<void>();
  private programmeId: number | null = null;
  private registrarWasLocked = false;
  private depositaryWasLocked = false;
  private typeReferenceData: Record<string, TypedDataModel[]> = {};
  private parties: [TYPE_FILTER_TYPE, ROLES_TYPE, PartyModel[]][] | undefined;
  constructor(
    public readonly formService: GlobalNoteFormService,
    public readonly i18nUtils: I18nUtilsService,
    @Inject(LabelRootTranslateInjectionToken) private readonly labelRootTranslation: string,
    public instrumentFormService: InstrumentFormService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    super(formService, 'instruments.instrument.placeholder.instrumentGlobalNote');
    this.instrumentFormService = instrumentFormService;
    combineLatest([this.activatedRoute.data.pipe(startWith(...[])), this.activatedRoute.params.pipe(startWith(...[]))])
      .pipe(takeUntil(this.shutdown$))
      .subscribe(([routeData, routeParams]) => {
        const values = routeData['instrument'] as [InstrumentModel | null, ProgrammeModel | null];
        const instrument = values ? values[0] : null;
        this.programmeId = instrument?.internalProgrammeNumber || routeParams.internalProgrammeNumber || null;
      });
  }
  ngOnInit(): void {
    this.typeReferenceData = this.activatedRoute.snapshot.data['typeReferenceData'] as Record<string, TypedDataModel[]>;
    this.parties = this.activatedRoute.snapshot.data['parties'] as [TYPE_FILTER_TYPE, ROLES_TYPE, PartyModel[]][];
    this.instrumentFormService
      .valueChanges('clearingSystems')
      .pipe(
        takeWhile(_ => this.formMode === 'add'),
        takeUntil(this.shutdown$)
      )
      .subscribe(value => {
        if (value?.find(q => q.name === INSTRUMENT_CONSTANTS.Parties.ClearingSystems.CLEARSTREAMBANKINGSALuxembourg || q.name === INSTRUMENT_CONSTANTS.Parties.ClearingSystems.EUROCLEARBANKBELGIUM)) {
          const globalNotes = this.typeReferenceData['GLOBAL_NOTE_FORM'];
          const materialized = toIpaTypeModelFromTypedDataModel(globalNotes.find(q => q.valueDescription === INSTRUMENT_CONSTANTS.Types.globalNotes.MATERIALIZED));
          if (materialized) {
            this.formService.patch('globalNoteForm', materialized);
          }
          this.formService.patch('globalNoteClassic', true);
        }
      });
  }

  private get globalNoteClassic(): string {
    return this.i18nUtils.getI18nValue(`${this.labelRootTranslation}.${this.fields.globalNoteClassic}`);
  }
  private get legalForm(): string {
    return this.i18nUtils.getI18nValue(`${this.labelRootTranslation}.${this.fields.legalForm}`);
  }

  public get errorParamBetweenGlobalNoteClassicAndLegalForm(): InterfieldErrorParams {
    return {
      field1: this.globalNoteClassic,
      field2: this.legalForm
    };
  }

  private get regs144LinkedInstrument(): string {
    return this.i18nUtils.getI18nValue(`${this.labelRootTranslation}.${this.fields.regs144LinkedInstrument}`);
  }

  private get physicalForm(): string {
    return this.i18nUtils.getI18nValue(`${this.labelRootTranslation}.${this.fields.physicalForm}`);
  }

  public get errorParamBetweenRegsAndPhysicalForm(): InterfieldErrorParams {
    return {
      field1: this.regs144LinkedInstrument,
      field2: this.physicalForm
    };
  }

  private getParties(filter: TYPE_FILTER_TYPE, role: ROLES_TYPE): PartyModel[] {
    return (this.parties?.find(q => q[0] === filter && q[1] === role) ?? [filter, role, []])[2] ?? [];
  }
  setcommonDepositary(): void {
    const commonDepositories = this.getParties('INSTRUMENT', 'COMMON_DEPOSITARY');
    const naCommonDepository = commonDepositories.find(q => q.name === INSTRUMENT_CONSTANTS.Parties.Registrars.NA);
    if (!naCommonDepository) {
      return;
    }
    if (this.programmeId) {
      const classicGlobalNote: boolean | null | undefined = this.formService.rawValue('globalNoteClassic');
      if (!classicGlobalNote || classicGlobalNote === undefined) {
        this.instrumentFormService.setcommonDepositary(naCommonDepository, true, this.depositaryWasLocked);
        this.depositaryWasLocked = true;
      } else {
        this.instrumentFormService.setcommonDepositary(null, false, this.depositaryWasLocked);
        this.depositaryWasLocked = false;
      }
    }
  }

  setcommonRegistrar(): void {
    if (this.programmeId) {
      const registrars = this.getParties('INSTRUMENT', 'REGISTRAR');
      const naRegistrar = registrars.find(q => q.name === INSTRUMENT_CONSTANTS.Parties.Registrars.NA);
      const legalForm: IpaTypeModel | null | undefined = this.formService.rawValue('legalForm');
      if (legalForm?.valueDescription === INSTRUMENT_CONSTANTS.Types.legalForms.BEARER) {
        this.instrumentFormService.setcommonRegistrar(naRegistrar, true, this.registrarWasLocked);
        this.registrarWasLocked = true;
      } else {
        this.instrumentFormService.setcommonRegistrar(null, false, this.registrarWasLocked);
        this.registrarWasLocked = false;
      }
    }
  }
  ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}
