import { AfterContentChecked, ChangeDetectorRef, Component } from '@angular/core';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { ActiveFilterItem, TYPED_DATA_TYPE } from '@shared/models';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { OperationSearchCriteriaModel } from 'src/app/positions-keeping/models/operation-search-criteria.model';
import { OperationCriteriaSearchFormService } from 'src/app/positions-keeping/services/operation-criteria-search.form.service';

@Component({
  selector: 'app-operation-list-filter',
  templateUrl: './operation-list-filter.component.html'
})
export class OperationListFilterComponent extends BaseFormComponent<OperationSearchCriteriaModel, OperationCriteriaSearchFormService> implements AfterContentChecked {
  public filters: ActiveFilterItem<OperationSearchCriteriaModel>[] = [];
  public OPERATION_TYPE: TYPED_DATA_TYPE = 'OPERATION_TYPE';
  public OPERATION_WORKFLOW_STATUS: TYPED_DATA_TYPE = 'OPERATION_WORKFLOW_STATUS';

  constructor(readonly operationCriteriaSearchFormService: OperationCriteriaSearchFormService, public readonly i18nUtils: I18nUtilsService, private readonly changeDetector: ChangeDetectorRef) {
    super(operationCriteriaSearchFormService, 'operations.list.filters');
  }

  public onFiltersChanged(filters: ActiveFilterItem<OperationSearchCriteriaModel>[]): void {
    this.filters = [...filters];
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
