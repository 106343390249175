<div class="modal-header">
  <h3 class="modal-title"> {{ createLabel | translate }}</h3>
  <button type="button" class="close icon p-1" (click)="onClose()">close</button>
</div>
<div class="modal-body">
  <app-tranche-creation></app-tranche-creation>
</div>
<div class="modal-footer">
  <button type="button" (click)="onCancel()" (keyup.enter)="onCancel()" class="btn btn-lg btn-link">{{ cancelButton |
    translate }}</button>
  <button ngbAutofocus type="button" (click)="onConfirm()" (keyup.enter)="onConfirm()" class="btn btn-lg btn-primary"
    translate>{{ confirmButton | translate }}</button>
</div>
