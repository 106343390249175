import { Component } from '@angular/core';
import { ListingModel } from '@instruments/models/listing.model';
import { ListingFormService } from '@instruments/services';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { TYPED_DATA_TYPE } from '@shared/models';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';

@Component({
  selector: 'app-instrument-listing',
  templateUrl: './instrument-listing.component.html',
  providers: [
    { provide: FormServiceInjectionToken, useExisting: ListingFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'instruments.instrument.forms.instrumentListing' }
  ]
})
export class InstrumentListingComponent extends BaseFormComponent<ListingModel, ListingFormService> {
  public listingCountry: TYPED_DATA_TYPE = 'LISTING_COUNTRY';
  public listingPlace: TYPED_DATA_TYPE = 'LISTING_PLACE';
  constructor(formService: ListingFormService) {
    super(formService, 'instruments.instrument.placeholder.instrumentListing');
  }
}
