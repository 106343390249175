import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-nullable-yes-no-buttons',
  templateUrl: './nullable-yes-no-buttons.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NullableYesNoButtonsComponent),
      multi: true
    }
  ]
})
export class NullableYesNoButtonsComponent implements ControlValueAccessor {
  @Input() yesLabel = 'Yes';
  @Input() noLabel = 'No';
  @Input() nullLabel = 'All';

  public focusId: string | null = null;
  public internalValue: boolean | null = null;
  public isReadOnly = false;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateValue(newValue: boolean | null): void {
    this.value = newValue;
  }

  get value(): boolean | null {
    return this.internalValue;
  }

  set value(value: boolean | null) {
    this.internalValue = value;
    this.onChange(value);
    this.onTouched();
  }

  onChange!: OnChangeFn<boolean | null>;

  onTouched!: OnTouchFn;

  registerOnChange(fn: OnChangeFn<boolean | null>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchFn): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isReadOnly = isDisabled;
  }

  writeValue(value: boolean | null): void {
    this.internalValue = value;
  }
}
