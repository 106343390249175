import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { Toast, ToastManagerService } from './service/toastManager.service';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss']
})
export class ToastsComponent implements OnInit {
  toasts: Toast[] = [];

  constructor(private readonly toastManagerService: ToastManagerService, private readonly cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.toastManagerService.toasts$.subscribe({
      next: data => {
        this.toasts = data;
        this.cdRef.detectChanges();
      }
    });
  }

  public getColor(toast: Toast): string {
    switch (toast.level) {
      case 'error':
        return 'toast-danger';
      case 'info':
        return 'toast-info';
      case 'success':
        return 'toast-success';
      case 'warning':
        return 'toast-warning';
      default:
        return 'toast-primary';
    }
  }

  deleteToast(toast: Toast): void {
    this.toastManagerService.deleteToast(toast.id);
  }
}
