import { Injectable } from '@angular/core';
import { EventEcashPaymentResourceService, EcashPaymentResponseDto, EcashPaymentRequestDto, SearchEcashPaginationDto, EcachPagingResultDto } from '@apis/backend/events';
import { Observable, of } from 'rxjs';
import { toSearchCriteriaInputDto, toCsvCriteriaInputDto, toEcashPaymentsDetailsSearchDto, setEcashPaymentsDetails } from '../models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ECashPaymentsService {
  constructor(private readonly eCashPaymentsService: EventEcashPaymentResourceService) {}

  getEcashPayments(serachCriteria: SearchEcashPaginationDto, pageNumber: number, itemsByPage: number): Observable<EcachPagingResultDto> {
    const dto = toSearchCriteriaInputDto(serachCriteria, pageNumber, itemsByPage);
    return this.eCashPaymentsService.searchWithPagination(dto);
  }

  getEcashPaymentsDetail(id: number): Observable<EcashPaymentResponseDto | null> {
    const dto = toEcashPaymentsDetailsSearchDto(id);
    if (!dto) {
      return of(null);
    }

    return this.eCashPaymentsService.searchEcashPaymentDetails(dto).pipe(map(response => setEcashPaymentsDetails(response.ecashPaymentDetails ? response.ecashPaymentDetails[0] : null)));
  }

  exportValuationsData(pagingRequestWithCriteria: EcashPaymentRequestDto): Observable<Blob | null> {
    const dto = toCsvCriteriaInputDto(pagingRequestWithCriteria);
    if (!dto) {
      return of(null);
    }
    return this.eCashPaymentsService.exportEcashData(dto);
  }
}
