import { IpaTypeModel } from '@shared/models';
import { dateToString, stringToDate } from '@utils/utility-functions';
import { OperationDetailItemModel } from '.';
import { OperationUpdateDto, OperationDto } from '@apis/backend/instruments';

export interface OperationDetailsModel {
  id?: number;
  instrumentId?: number;
  isin?: string;
  seriesNumber?: string;
  currency?: string;
  operationType?: IpaTypeModel;
  operationStatus?: IpaTypeModel;
  valueDate?: Date;
  instructionDate?: Date;
  clientOperations?: OperationDetailItemModel[];
}

export const toOperationDetailsModelDto = (model: OperationDetailsModel | null | undefined): OperationUpdateDto | undefined => {
  if (!model) {
    return undefined;
  }

  return {
    idOperation: model.id,
    instrumentId: model.instrumentId,
    isin: model.isin,
    operationType: model.operationType,
    valueDateAsString: dateToString(model.valueDate) || undefined,
    instructionDateAsString: dateToString(model.instructionDate) || undefined,
    clientOperationDtos: model.clientOperations
  };
};

export const toOperationDetailsModel = (model: OperationDto | null | undefined): OperationDetailsModel | undefined => {
  if (!model) {
    return undefined;
  }

  return {
    id: model.idOperation,
    instrumentId: model.instrumentId,
    isin: model.isin,
    seriesNumber: model.seriesNumber,
    currency: model.currency,
    operationType: model.operationType,
    operationStatus: model.operationStatus,
    valueDate: stringToDate(model.valueDateAsString) || undefined,
    instructionDate: stringToDate(model.instructionDateAsString) || undefined,
    clientOperations: model.clientOperationDtos || undefined
  };
};
