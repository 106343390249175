<div class="row mt-2">
  <div class="col-md-6">
    <div *ngIf="isConsult;else edit_mode">
      <app-disposal-items-consult [disposalsItems]="formService.rawValue('disposalItems')"></app-disposal-items-consult>
    </div>
    <ng-template #edit_mode>
      <app-disposal-items-edit [disposalsItems]="formService.rawValue('disposalItems')"></app-disposal-items-edit>
    </ng-template>
  </div>
</div>

<div class="section-forms" [formGroup]="formGroup">
  <div class="container g-0">
      <div class="row gy-2">
        <div class="col-md-6">
          <input [formControlName]="fields.disposalId" type="hidden">
          <app-form-item [focus]="archiveBoxNumber" [field]="fields.archiveBoxNumber">
            <input #archiveBoxNumber [formControlName]="fields.archiveBoxNumber" component type="text" maxlength="5"
              class="form-control" placeholder="{{ placeholder(fields.archiveBoxNumber) | translate }}" />
          </app-form-item>
        </div>
        <div class="col-md-6">
          <app-form-item [field]="fields.archiveDate" [focus]="archiveDate">
            <app-date-picker [formControlName]="fields.archiveDate" [hasError]="hasError(fields.archiveDate)"
              #archiveDate component> </app-date-picker>
          </app-form-item>
        </div>
    </div>
  </div>
</div>
