/* eslint-disable @typescript-eslint/no-explicit-any */
import { IFormService, FormEventOptions, FieldsNames } from './form.models';
import { Forms } from '@shared/utils';
import { ValidationErrors, AbstractControl } from '@angular/forms';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';

type FormServices<T, N extends string = string> = { [K in keyof T]: IFormService<T[K], N> };
type StatusComputer<N extends string> = Record<N, () => boolean>;

type Model<T extends any[]> = UnionToIntersection<T[number]>;
export abstract class UnionFormService<ArrayType extends any[], N extends string = string, T = Model<ArrayType>> implements IFormService<T, N> {
  private readonly services: FormServices<ArrayType>;
  private readonly formsErrors: StatusComputer<N>;
  protected readonly subscriptions: Subscription[] = [];
  constructor(private readonly internalName: N, ...formServices: FormServices<ArrayType>) {
    this.services = formServices;
    this.formsErrors = formServices.reduce<StatusComputer<N>>((stats, fs) => {
      return {
        ...stats,
        [fs.name]: () => fs.touched && !fs.valid
      };
    }, {} as any);
  }
  public get isFormValid(): boolean {
    return this.services.every(s => s.isFormValid);
  }

  public get name(): N {
    return this.internalName;
  }

  public get formMode(): Forms.FormMode {
    return this.services[0].formMode;
  }

  public get formMode$(): Observable<Forms.FormMode> {
    return this.services[0].formMode$;
  }

  public get fields(): FieldsNames<T> {
    const allFields = this.services.map(s => s.fields);
    const allFieldsFlat = allFields.reduce<FieldsNames<any>>((p, c) => ({ ...p, ...c }), {});
    return allFieldsFlat as FieldsNames<T>;
  }

  private getFormServiceWithField(field: keyof T): IFormService<any> {
    const result = this.services.find(s => field in s.fields);
    if (!result) {
      throw new Error(`failed to find formservice for ${field.toString()}`);
    }
    return result;
  }

  public hasError(formName: N): boolean {
    if (this.formMode === 'consult') {
      return false;
    }

    const computer = this.formsErrors[formName];
    if (computer) {
      return computer();
    }
    return false;
  }

  public get errorStatus(): Record<N, boolean> {
    let k: keyof StatusComputer<N>;
    const result: Record<N, boolean> = {} as any;
    for (k in this.formsErrors) {
      result[k] = this.formsErrors[k]();
    }

    return result;
  }
  public control<U extends AbstractControl = AbstractControl>(field: keyof T): U {
    const service = this.getFormServiceWithField(field);
    return service.control<U>(field);
  }
  public setInitialValue(model: Partial<T> | null, options?: FormEventOptions): void {
    this.services.forEach(s => s.setInitialValue(model, options));
  }

  public clearInitialValue(): void {
    this.services.forEach(s => s.clearInitialValue());
  }

  public setFormMode(mode: Forms.FormMode): void {
    this.services.forEach(f => f.setFormMode(mode));
  }
  private patchGroup(value: Partial<T>, options?: FormEventOptions): void {
    this.services.forEach(f => f.patch(value, options));
  }

  private patchField<K extends keyof T>(field: K, value: T[K], options?: FormEventOptions): void {
    const group: Partial<T> = {};
    group[field] = value;
    this.patchGroup(group, options);
  }

  public clearField<K extends keyof T>(field: K, options?: FormEventOptions): void {
    const service = this.getFormServiceWithField(field);
    service.clearField(field, options);
  }

  public patch(value: Partial<T>, options?: FormEventOptions): void;
  public patch<K extends keyof T>(field: K, value: T[K] | null, options?: FormEventOptions): void;
  public patch<K extends keyof T>(param1: K | Partial<T>, param2?: T[K] | null | FormEventOptions, param3?: FormEventOptions): void {
    if (typeof param1 !== 'object') {
      this.patchField(param1, param2 as T[K], param3);
    } else {
      this.patchGroup(param1, param2 as FormEventOptions);
    }
  }

  public get valid(): boolean {
    return this.services.every(f => f.valid);
  }
  public get touched(): boolean {
    return this.services.some(f => f.touched);
  }

  public get dirty(): boolean {
    return this.services.some(f => f.dirty);
  }

  public rawValue(): Nullable<T>;
  public rawValue<K extends keyof T>(field: K): T[K] | null | undefined;
  public rawValue<L extends (keyof T)[]>(fields: L): Pick<T, L[number]>;
  public rawValue<K extends keyof T, L extends (keyof T)[]>(params?: K | L): T[K] | Nullable<T> | Nullable<Pick<T, L[number]>> | null | undefined {
    const allModels = this.services.map(f => f.rawValue());
    const rawValue = allModels.reduce<Nullable<T>>((p, c) => ({ ...p, ...c }), {});

    if (!params) {
      return rawValue;
    }

    if (Array.isArray(params)) {
      return params.reduce((p, c) => ({ ...p, [c]: rawValue[c] }), {} as Nullable<Pick<T, L[number]>>);
    }

    return rawValue[params];
  }
  public value(): T | null;
  public value<K extends keyof T>(field: K): T[K] | null;
  public value<L extends (keyof T)[]>(fields: L): Pick<T, L[number]> | null;
  public value<K extends keyof T, L extends (keyof T)[]>(params?: K | L): T[K] | T | Pick<T, L[number]> | null {
    if (!this.valid) {
      return null;
    }

    const rawValue = this.rawValue() as T;
    if (!params) {
      return rawValue;
    }

    if (Array.isArray(params)) {
      return params.reduce((p, c) => {
        p[c] = rawValue[c];
        return p;
      }, {} as Pick<T, L[number]>);
    }

    return rawValue[params];
  }

  public reset(options?: FormEventOptions): void;
  public reset<K extends keyof T>(field: K, options?: FormEventOptions): void;
  public reset<L extends (keyof T)[]>(fields: L, options?: FormEventOptions): void;
  public reset<K extends keyof T, L extends (keyof T)[]>(fields?: L | K, options?: FormEventOptions): void {
    this.services.forEach(s => s.reset(fields, options));
  }
  public updateValueAndValidity(options?: FormEventOptions): void {
    this.services.forEach(s => s.updateValueAndValidity(options));
  }

  public errors(): Record<string, ValidationErrors> {
    const result: Record<string, ValidationErrors> = {};

    this.services.forEach(s => {
      const errors = s.errors();

      Object.keys(errors).forEach(field => {
        const error = errors[field];
        const existingError = result[field];
        const finalError = { ...error, ...existingError };
        result[field] = finalError;
      });
    });

    return result;
  }

  public valueChanges(): Observable<Nullable<T>>;
  public valueChanges<K extends keyof T>(field: K): Observable<T[K] | null | undefined>;
  public valueChanges<K extends keyof T, L extends K[]>(fields?: L): Observable<Nullable<Pick<T, L[number]>>>;
  public valueChanges<K extends keyof T, L extends K[]>(fields?: K | L): Observable<T[K] | null | undefined> | Observable<Nullable<Pick<T, L[number]>>> | Observable<Nullable<T>> {
    if (!fields) {
      const initialValue = this.rawValue();
      return combineLatest(this.services.map(s => s.valueChanges())).pipe(
        map(values => {
          return values.reduce<Nullable<T>>((p, _, i) => ({ ...p, ...values[i] }), {});
        }),
        startWith(initialValue)
      );
    }

    if (Array.isArray(fields)) {
      const initialValue = this.rawValue(fields);
      const valueChanges = fields.map(f => this.control(f).valueChanges.pipe(startWith(initialValue[f]), shareReplay()));
      return combineLatest(valueChanges).pipe(
        map(values => {
          return fields.reduce<Pick<T, L[number]>>((p, c, i) => ({ ...p, [c]: values[i] }), {} as Pick<T, L[number]>);
        })
      );
    }

    const service = this.getFormServiceWithField(fields);
    return service.valueChanges(fields as any);
  }

  public lockFieldsPermanently(...fields: (keyof T)[]): void {
    this.services.forEach(s => s.lockFieldsPermanently(...fields));
  }
  public lockFields(...fields: (keyof T)[]): void {
    this.services.forEach(s => s.lockFields(...fields));
  }
  public unlockFields(...fields: (keyof T)[]): void {
    this.services.forEach(s => s.unlockFields(...fields));
  }

  public lockAllFieldsExcept(...fields: (keyof T)[]): void {
    this.services.forEach(s => s.lockAllFieldsExcept(...fields));
  }

  public unlockAllFieldsExcept(...fields: (keyof T)[]): void {
    this.services.forEach(s => s.unlockAllFieldsExcept(...fields));
  }

  public isRequired<K extends keyof T>(field: K): boolean {
    const service = this.getFormServiceWithField(field);
    return service.isRequired(field);
  }
}
