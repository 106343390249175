import { ColDef } from 'ag-grid-community';
import { SwiftsGridModel } from '@events/models/swiftsGrid.model';
import { CellStatusComponent } from '@shared/components/cell-status/cell-status.component';

export interface SwiftsColDef extends ColDef {
  field: keyof SwiftsGridModel;
}

export const SWIFTS_COLUMN: SwiftsColDef[] = [
  {
    field: 'swiftMessageType',
    width: 180
  },
  {
    field: 'swiftMessageFunction',
    width: 210
  },
  {
    field: 'status',
    cellRendererFramework: CellStatusComponent,
    width: 125
  }
];
