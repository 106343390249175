import { InstrumentDto } from '@apis/backend/instruments';
import { IpaTypeModel } from '@shared/models';
import { TrancheModel } from './tranche.model';

export interface InstrumentAddendumModel {
  readonly id?: number;
  readonly idWorkflow?: string;
  readonly status: IpaTypeModel | null;
  readonly trancheStatus: IpaTypeModel | null;
  readonly selectedTrancheNumber: number | null;
  readonly tranches: TrancheModel[];
  readonly lastUpdateDateTimeAsString: string | null;
  readonly isInternalIssuer: boolean;
}

export const ToInstrumentAddendumModel = (dto: InstrumentDto, currentTranche: TrancheModel | undefined, tranches: TrancheModel[]): InstrumentAddendumModel => {
  return {
    id: dto?.id,
    idWorkflow: dto?.idWorkflow,
    status: dto?.status || currentTranche?.status || null,
    trancheStatus: currentTranche?.status || null,
    selectedTrancheNumber: currentTranche?.trancheNumber || null,
    tranches: tranches,
    lastUpdateDateTimeAsString: dto?.lastUpdateDateTimeAsString || null,
    isInternalIssuer: dto?.isInternalIssuer || false
  };
};
