import { Component, Input } from '@angular/core';
import { DocumentFileModel } from '@documents/models';

@Component({
  selector: 'app-reporting-access-list',
  templateUrl: './reporting-access-list.component.html'
})
export class ReportingAccessListComponent {
  @Input()
  documents: DocumentFileModel[] = [];

  @Input()
  isLoadingDocument = false;
}
