import { Injectable } from '@angular/core';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { AgGridColumnDefBaseService } from '@shared/services/aggrid-column-def-base-service';
import { PAYMENT_COLUMNS } from './payment-column-def';

@Injectable()
export class PaymentColumnDefService extends AgGridColumnDefBaseService {
  constructor(translateService: I18nUtilsService) {
    super(translateService, 'payment.list.headers', PAYMENT_COLUMNS);
  }
}
