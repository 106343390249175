/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PartyDto } from './partyDto';
import { IpaTypeCreateDto } from './ipaTypeCreateDto';


export interface InstrumentCharacteristicsDataCreateDto { 
    issuer?: PartyDto;
    clearingSystems?: Array<PartyDto>;
    commonCode?: string;
    isinReference?: string;
    instrumentType?: IpaTypeCreateDto;
    seriesNumber?: string;
    classNumber?: string;
    compartmentNumber?: string;
    name?: string;
    finalTermReceived?: boolean;
    legalDeskIssuer?: IpaTypeCreateDto;
    complianceRestriction?: boolean;
    paymentDefault?: boolean;
    eligibility871m?: boolean;
    eligibilitySrd2?: boolean;
    labelCategory?: IpaTypeCreateDto;
    upto?: boolean;
    icsdProgrammeNumber?: string;
    internalProgrammeNumber?: number;
}

