<div class="row mt-5">
  <div class="col-md-12">
    <form (ngSubmit)="search()">
      <app-operation-list-filter>
        <app-text-icon-button *ngIf="canReadMultipleOperation" [disabled]="disableSearch" icon="search" searchTemplate [submit]="true" [customClasses]="'btn-icon-start'"
          label="{{ 'operations.list.search' | translate }}" size="md" buttonType="btn-outline-default">
        </app-text-icon-button>
      </app-operation-list-filter>
    </form>
  </div>
</div>
<app-operation-list [operations]="models"></app-operation-list>
<div class="softLoading" *ngIf="disableSearch">Loading</div>
