import { PartyModel } from './party.model';

export interface TypedDataResponse {
  resources: TypedDataModel[];
}

export interface TypedDataModel {
  valueId: string;
  valueDescription: string;
}
export type ReferenceDataModel = TypedDataModel | PartyModel;
