/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InstrumentDataFetchDto { 
    type?: string;
    idInstrument?: number;
    instrumentType?: string;
    issuerName?: string;
    name?: string;
    programmeICSDNumber?: string;
    seriesNumber?: string;
    settlementCurrency?: string;
    trancheNumber?: number;
    issueDate?: string;
    isinCodeTranche?: string;
    trancheStatus?: string;
    outStandingNominal?: string;
    maturityDate?: string;
    commonDepositaryName?: string;
    commonDepositaryBdrId?: string;
    commonSafeKeeperName?: string;
    commonSafeKeeperBdrId?: string;
}

