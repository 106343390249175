/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sg-pagination',
  templateUrl: './sg-pagination.component.html'
})
export class SgPaginationComponent {
  private size = 0;
  private total = 0;
  private maxPages = 6;

  private current = 1;
  next = true;
  previous = false;
  totalPages = 1;
  pages: number[] = [];
  visible = false;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter();
  @Input() get pageSize(): number {
    return this.size;
  }

  set pageSize(size: number) {
    this.size = size;
    this.initializePagination();
  }

  @Input() set currentPage(current: number) {
    this.current = current;
  }

  get currentPage(): number {
    return this.current;
  }
  @Input() get totalItems(): number {
    return this.total;
  }

  set totalItems(count: number) {
    this.total = count;
    this.initializePagination();
  }

  @Input() get maxPagesToDisplay(): number {
    return this.maxPages;
  }

  set maxPagesToDisplay(max: number) {
    this.maxPages = max;
    this.initializePagination();
  }

  initializePagination(): void {
    this.pages = [1];
    this.next = true;
    this.previous = this.current !== 1;

    if (this.total && this.size) {
      this.totalPages = Math.ceil(this.total / this.size);
      if (this.total >= this.size) {
        this.buildPager();
      }
      this.visible = this.pages.length > 0;
      return;
    }
    this.visible = false;
  }

  navigate(direction: number, event?: MouseEvent): void {
    let page: number = this.current;
    if (direction === -1) {
      if (page > 1) {
        page--;
      }
    } else {
      if (page < this.totalPages) {
        page++;
      }
    }
    this.changePage(page, event);
  }

  changePage(page: number, event?: MouseEvent): void {
    if (event) {
      event.preventDefault();
    }
    if (this.current === page) {
      return;
    }
    this.current = page;
    this.previous = this.current > 1;
    this.next = this.current < this.totalPages;
    this.buildPager();
    this.pageChanged.emit(page);
  }

  buildPager(): void {
    this.pages = [];

    let start: number;
    let end: number;

    if (this.totalPages <= this.maxPages) {
      start = 1;
      end = this.totalPages;
    } else {
      const maxBeforeCurrent = Math.floor(this.maxPages / 2);
      const maxAfterCurrent = Math.ceil(this.maxPages / 2) - 1;

      if (this.current <= maxBeforeCurrent) {
        start = 1;
        end = this.maxPages;
      } else if (this.current + maxAfterCurrent >= this.totalPages) {
        start = this.totalPages - this.maxPages + 1;
        end = this.totalPages;
      } else {
        start = this.current - maxBeforeCurrent;
        end = this.current + maxAfterCurrent;
      }
    }
    for (let i = start; i < end + 1; i++) {
      this.pages.push(i);
    }
    if (!this.pages.includes(this.currentPage)) {
      this.currentPage = 1;
      this.pageChanged.emit(1);
    }
  }
}
