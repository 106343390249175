import { PartyModel } from '@shared/models';
export interface OperationDetailItemModel {
  id?: number;
  csdParty?: PartyModel;
  depositoryParty?: PartyModel;
  direction?: string;
  value?: string;
  accountNumber?: string;
  sendSwifts?: boolean;
  semeReference?: string;
}
