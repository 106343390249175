import { SwiftMessageDto, SearchSwiftMessage } from '@apis/backend/events';

export interface SwiftsGridModel {
  readonly swiftMessageType?: string;
  readonly swiftMessageFunction?: string;
  readonly status?: string;
  readonly swiftMessage?: string;
  readonly swiftMessageError?: string;
}

export const toSwiftModal = (swifts: SwiftMessageDto): SwiftsGridModel => {
  return {
    swiftMessageType: swifts.swiftMessageType?.valueDescription,
    swiftMessageFunction: swifts.swiftMessageFunction?.valueDescription,
    status: swifts.status?.valueDescription,
    swiftMessage: swifts.swiftMessage,
    swiftMessageError: swifts.swiftMessageError
  };
};

export const toSwiftSearchDto = (id: number[]): SearchSwiftMessage => {
  return {
    eventIds: [Number(id)]
  };
};
