import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';
import { TIME_FORMAT } from './common';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService, private readonly datePipe: DatePipe) {}

  transform(value: any, timeZone: string | undefined = undefined): any {
    if (value) {
      return this.datePipe.transform(value, TIME_FORMAT, timeZone, this.translateService.currentLang);
    }

    return undefined;
  }
}
