/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InstrumentRoleDataCreateDto } from './instrumentRoleDataCreateDto';
import { InstrumentCharacteristicsDataUpdateDto } from './instrumentCharacteristicsDataUpdateDto';
import { InstrumentEventsDataCreateDto } from './instrumentEventsDataCreateDto';
import { InstrumentGlobalNoteCreateDto } from './instrumentGlobalNoteCreateDto';
import { InstrumentIssuanceDataUpdateDto } from './instrumentIssuanceDataUpdateDto';
import { InstrumentListingDataCreateDto } from './instrumentListingDataCreateDto';


/**
 * Instrument to update
 */
export interface InstrumentUpdateDto { 
    id?: number;
    instrumentCharacteristicsData?: InstrumentCharacteristicsDataUpdateDto;
    instrumentIssuanceData?: InstrumentIssuanceDataUpdateDto;
    instrumentGlobalNote?: InstrumentGlobalNoteCreateDto;
    instrumentListingData?: InstrumentListingDataCreateDto;
    instrumentEventsData?: InstrumentEventsDataCreateDto;
    instrumentRoleData?: InstrumentRoleDataCreateDto;
    lastUpdateDateTimeAsString?: string;
}

