/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';

import { DayModel } from '../day.model';

@Component({
  selector: 'app-month-display',
  templateUrl: './month-display.component.html',
  styleUrls: ['./month-display.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonthDisplayComponent implements OnInit {
  @Input() monthNumber!: number;
  @Input() nbDays!: number;
  @Input() set selectedDates(value: DayModel[]) {
    this.selectedDays = value.filter(v => v.month === this.monthNumber + 1).map(v => v.day);
  }
  @Output() dateSelected = new EventEmitter<DayModel>();

  public constructor(private readonly i18n: I18nUtilsService) {}

  public days: number[] = [];
  private selectedDays: number[] = [];
  public get monthLabel(): string {
    return this.i18n.getI18nValue(`date-picker.month-${this.monthNumber}`);
  }

  public isSelected(day: number): boolean {
    return this.selectedDays.includes(day);
  }

  public onDayClick(day: number): void {
    this.dateSelected.next({ day, month: this.monthNumber + 1 });
  }
  ngOnInit(): void {
    this.days = Array<number>(this.nbDays || 0)
      .fill(0)
      .map((x, i) => i + 1);
  }
}
