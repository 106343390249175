/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.37
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PermanentMonitoringDataDto } from './permanentMonitoringDataDto';
import { GnDisposalDataDto } from './gnDisposalDataDto';


/**
 * Global note to create or update
 */
export interface GnReceptionDataDto { 
    id?: number;
    idInstrument?: number;
    isGnReceivedIndicator?: boolean;
    isFtReceivedIndicator?: boolean;
    commonDepComment?: string;
    gnDisposalDataDto?: GnDisposalDataDto;
    permanentMonitoringDataDtos?: Array<PermanentMonitoringDataDto>;
}

