import { Injectable } from '@angular/core';
import { UnionFormService } from '@shared/services/forms/union.form.service';
import { EventCriteriaSearchModel } from '@events/models';
import { EventCriteriaSearchFormService } from '.';

export type EventSearchArrayType = [EventCriteriaSearchModel];
export type EventSearchQueryModel = EventCriteriaSearchModel;

@Injectable()
export class EventSearchService extends UnionFormService<EventSearchArrayType> {
  constructor(private readonly eventCriteriaSearchFormService: EventCriteriaSearchFormService) {
    super('event-search', eventCriteriaSearchFormService);
  }
  public getfields(): (keyof EventCriteriaSearchModel)[] {
    const array = Object.keys(this.eventCriteriaSearchFormService.fields);
    return array as (keyof EventCriteriaSearchModel)[];
  }
}
