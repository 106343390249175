import { Injectable } from '@angular/core';
import { OperationDetailItemModel } from '@positions/models';
import { PartyModel } from '@shared/models';
import { AgGridColumnDefBaseService } from '@shared/services/aggrid-column-def-base-service';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { ColDef } from 'ag-grid-community';
import { OPERATION_DETAIL_EDIT_COLUMNS } from './operation-detail-edit-column-def';

@Injectable()
export class OperationDetailEditColumnDefService extends AgGridColumnDefBaseService {
  constructor(translateService: I18nUtilsService) {
    super(translateService, 'operations.detail.headers', OPERATION_DETAIL_EDIT_COLUMNS);
  }

  public init(clearings: PartyModel[]): ColDef[] {
    const columns = this.columnDefs;

    for (const field of columns) {
      field.headerValueGetter = this.localizeHeader.bind(this);

      if ('field' in field) {
        field.cellEditorParams = this.getCellEditorParams(field.field as keyof OperationDetailItemModel, clearings);
      }
    }
    return columns;
  }
  private getCellEditorParams(field: keyof OperationDetailItemModel, clearings: PartyModel[]): any {
    switch (field) {
      case 'csdParty':
        return { values: clearings.map(sc => sc.name) };
      case 'direction':
        return { values: ['C', 'D'] };
      default:
        return null;
    }
  }
}
