import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-advanced-filters-button',
  templateUrl: './advanced-filters-button.component.html'
})
export class AdvancedFiltersButtonComponent {
  @Input() advancedFiltersShown = false;
  @Input() hasFilters = false;
  @Output() changed = new EventEmitter<boolean>();

  public toggle() {
    this.advancedFiltersShown = !this.advancedFiltersShown;
    this.changed.emit(this.advancedFiltersShown);
  }
  public get class(): string {
    if (this.advancedFiltersShown && !this.hasFilters) {
      return 'btn-flat-secondary - active';
    }
    if (!this.advancedFiltersShown && this.hasFilters) {
      return 'btn-flat-info';
    }
    if (this.advancedFiltersShown && this.hasFilters) {
      return 'btn-flat-info - active';
    }
    return 'btn-flat-secondary';
  }
}
