import { Component, OnInit } from '@angular/core';
import { PaymentsFilter } from '@payment/models';
import { PaymentCriteriaSearchFormService, PaymentFilterSelectorService } from '@payment/services';

@Component({
  selector: 'app-payment-filter-selector',
  templateUrl: './payment-filter-selector.component.html'
})
export class PaymentFilterSelectorComponent implements OnInit {
  public filters: PaymentsFilter[] = [];
  public selected: PaymentsFilter | undefined;
  constructor(private readonly paymentCriteriaSearchFormService: PaymentCriteriaSearchFormService, public paymentFilterSelectorService: PaymentFilterSelectorService) {}

  ngOnInit(): void {
    this.paymentFilterSelectorService.loadFilters('payments-filters');
  }

  onChange(event: PaymentsFilter): void {
    this.paymentCriteriaSearchFormService.patch(event.value);
  }

  select(filter: PaymentsFilter): void {
    this.selected = filter;
    this.paymentCriteriaSearchFormService.patch(filter.value);
  }
}
