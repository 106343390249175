/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { fromEvent, Subject, BehaviorSubject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil, tap, map, mergeMap } from 'rxjs/operators';

import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, Output, ViewChild, ViewEncapsulation, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PartyService } from '@shared/services/party.service';
import { PartyModel, ProgrammeInformation } from '@shared/models';

interface SearchValue {
  term: string;
  items: any[];
}

interface InternalItem {
  bdrId: string;
  issuer: PartyModel;
  programmeInformation: ProgrammeInformation;
}

@Component({
  selector: 'app-lei-multiple-select',
  templateUrl: './lei-multiple-select.component.html',
  styleUrls: ['./lei-multiple-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LeiMultipleSelectComponent),
      multi: true
    }
  ]
})
export class LeiMultipleSelectComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy {
  @ViewChild('select', { static: false, read: ElementRef }) select!: ElementRef;
  public isReadOnly = false;
  @Input() multiple = false;
  @Input() closeOnSelect = false;
  public items: InternalItem[] = [];
  public listItems: string[] = [];
  @Input() placeholder: string | null = null;

  @Input() hasError = false;
  @Input() focusId: string | null = null;

  @Output() blurEmit: EventEmitter<void> = new EventEmitter();
  @Output() inputEmit: EventEmitter<string> = new EventEmitter();
  @Output() bdrId = new EventEmitter<string | null>();
  @Output() programmeInformation = new EventEmitter<ProgrammeInformation | null>();
  @Output() issuer = new EventEmitter<PartyModel | null>();
  public value: string | null = null;
  private readonly searchInput$ = new BehaviorSubject<string | null>(null);
  private readonly shutdown$ = new Subject<void>();
  constructor(private readonly partyService: PartyService) {}

  onSearch(search: SearchValue) {
    this.searchInput$.next(search.term);
  }

  ngOnInit() {
    this.searchInput$.next(null);
    this.searchInput$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        mergeMap((searchInput: string | null) => {
          if (!searchInput) {
            return of([]);
          }

          return this.partyService.getIssuerBdrDataFromLeiCode(searchInput).pipe(
            map(progInfo => {
              if (!progInfo) {
                return [];
              }
              const bdrId = `${progInfo.leiCode} - ${progInfo.fullLegalName} - BDR ${progInfo.bdrId}`;
              const item: InternalItem = {
                bdrId,
                issuer: {
                  bdrId: progInfo.bdrId,
                  leiCode: progInfo.leiCode,
                  name: progInfo.fullLegalName
                },
                programmeInformation: progInfo
              };

              return [item];
            })
          );
        }),
        takeUntil(this.shutdown$)
      )
      .subscribe(items => {
        this.items = items;
        this.listItems = items.map(i => i.bdrId);
      });
  }

  ngAfterViewInit(): void {
    if (this.select) {
      fromEvent<InputEvent>(this.select.nativeElement.querySelector('input'), 'input')
        .pipe(
          filter(e => e !== null && e !== undefined),
          debounceTime(300),
          distinctUntilChanged(),
          tap((event: InputEvent) => {
            this.inputEmit.emit((event.target as any).value);
          })
        )
        .subscribe();
    }
  }

  onFocusout(): void {
    this.onTouched();
    this.blurEmit.emit();
  }

  onValueChange(value: string | null) {
    const item = this.items.find(i => i.bdrId === value);

    this.value = value || null;
    this.bdrId.emit(item?.bdrId || null);
    this.issuer.emit(item?.issuer || null);
    this.programmeInformation.emit(item?.programmeInformation || null);
    this.onChange(item?.programmeInformation?.leiCode || null);
  }
  onChange: OnChangeFn<string | null> = () => {};

  onTouched: OnTouchFn = () => {};

  setDisabledState(isDisabled: boolean): void {
    this.isReadOnly = isDisabled;
  }

  registerOnChange(fn: OnChangeFn<string | null>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchFn): void {
    this.onTouched = fn;
  }

  writeValue(value: string | null): void {
    this.value = value;
  }

  ngOnDestroy() {
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}
