<app-header containerType="container-fluid" [backButton]="false">
  <div headerBody class="d-flex flex-row justify-content-between mb-1 mt-1">
    <h1 class="display-3 line-height-1" translate [translateParams]="{ variable: 'Home' }">Home</h1>
    <div class="d-flex justify-content-end">
      <app-text-icon-button color="primary-alt" (clicked)="openReportingAccessModal()" class="me-2" [customClasses]="'btn-default'"
        label="{{'instruments.reporting-access'|translate}}" size="md" buttonType="btn-icon-text">
      </app-text-icon-button>
    </div>
  </div>
</app-header>
<div class="container-fluid content-margin-top">
  <div class="row">
    <div class="col col-md-4">
      <div class="d-flex flex-column">
        <div class="align-self-center h3">SPI Issuing Settlement Indicators (actions)</div>
        <app-issuing-settlement-indicators-grid
          [issuingSettlementIndicators]="issuingSettlementIndicatorsRows"
          scope="SPI"
          statusColumnText="Actions"
          (linkClick)="onIssuingSettlementIndictorsGridLinkClicked($event)">
        </app-issuing-settlement-indicators-grid>
      </div>
    </div>
    <div class="col col-md-4">
      <div class="d-flex flex-column">
        <div class="align-self-center h3">SPI Issuing Settlement Indicators</div>
          <app-issuing-settlement-indicators-grid
            [issuingSettlementIndicators]="issuingSettlementIndicatorsRows"
            scope="SPI NOT LIVE"
            statusColumnText="Infos"
            (linkClick)="onIssuingSettlementIndictorsGridLinkClicked($event)">
          </app-issuing-settlement-indicators-grid>
        </div>
    </div>
    <div class="col col-md-4">
      <div class="d-flex flex-column">
        <div class="align-self-center h3">STA Issuing Settlement Indicators</div>
          <app-issuing-settlement-indicators-grid
            [issuingSettlementIndicators]="issuingSettlementIndicatorsRows"
            scope="STA"
            (linkClick)="onIssuingSettlementIndictorsGridLinkClicked($event)">
          </app-issuing-settlement-indicators-grid>
        </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col col-md-12">
        <app-spi-event-grid></app-spi-event-grid>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col col-md-4">
      <div class="d-flex flex-column">
        <div class="align-self-center h3">CD Documentation reception</div>
        <app-global-note-indicators-grid-component
          statusHeaderName="Instrument Documentation Reception"
          [globalNoteIndicators]="documentationCdReceptionMonitoringRows"
          (linkClick)="onGlobalNoteIndicatorsGridLinkClicked($event, 'DocumentationCdReceptionMonitoring')">
        </app-global-note-indicators-grid-component>
      </div>
    </div>
    <div class="col col-md-4">
      <div class="d-flex flex-column">
        <div class="align-self-center h3">CD Permanent monitoring</div>
        <app-global-note-indicators-grid-component
          statusHeaderName="Instrument With Permanent Monitoring Done"
          [globalNoteIndicators]="permanentMonitoringRows"
          (linkClick)="onGlobalNoteIndicatorsGridLinkClicked($event, 'PermanentMonitoring')">
        </app-global-note-indicators-grid-component>
      </div>
    </div>
    <div class="col col-md-4">
      <div class="d-flex flex-column">
        <div class="align-self-center h3">CD disposal monitoring</div>
        <app-global-note-indicators-grid-component
          statusHeaderName="Instrument With Disposal Received"
          [globalNoteIndicators]="disposalMonitoringRows"
          (linkClick)="onGlobalNoteIndicatorsGridLinkClicked($event, 'DisposalMonitoring')">
        </app-global-note-indicators-grid-component>
      </div>
    </div>
  </div>
</div>
  
