import { Component, EventEmitter, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActiveFilterItem } from '@shared/models';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { Subject } from 'rxjs';
import { ValuationCriteriaSearchFormService } from '@valuation/services';
import { ValuationCriteriaSearchModel } from '@valuation/models';
import { ActiveFiltersBaseComponent } from '@shared/search-base';
import { ValuationFiltersSaveComponent } from '../filters-save/valuation-filters-save.component';

@Component({
  selector: 'app-valuation-list-active-filters',
  templateUrl: './valuation-list-active-filters.component.html'
})
export class ValuationListActiveFiltersComponent extends ActiveFiltersBaseComponent<ValuationCriteriaSearchModel> {
  @Output() filtersChanged = new EventEmitter<ActiveFilterItem<ValuationCriteriaSearchModel>[]>();
  public filterItems: ActiveFilterItem<ValuationCriteriaSearchModel>[] = [];
  public shutdown$ = new Subject<void>();

  constructor(formService: ValuationCriteriaSearchFormService, modalService: NgbModal, i18nUtils: I18nUtilsService) {
    super(formService, modalService, i18nUtils, 'valuations-filters', 'valuation.list.filters');
  }
  public onFilterChanged(items: ActiveFilterItem<ValuationCriteriaSearchModel>[]): void {
    this.filtersChanged.emit(items);
  }
  public saveFilters(): NgbModalRef {
    const modal = this.modalService.open(ValuationFiltersSaveComponent);
    const dialog: ValuationFiltersSaveComponent = modal.componentInstance;
    dialog.value = this.criteriaFormService.value();
    dialog.keyBase = this.keyBase;
    return modal;
  }
}
