<app-header [backButton]="false" containerType="container-fluid">
    <div headerBody class="d-flex flex-row justify-content-between mb-1 mt-1">
      <h1 class="display-3 line-height-1" translate [translateParams]="{ variable: 'EcashPayments' }">ECash payments</h1>
    </div>
  </app-header>
  <div class="softLoading" *ngIf="disableSearch">Loading</div>
  <div class="container-fluid content-margin-top">
    <div class="row">
      <div class="col-md-12">
        <form>          
          <app-e-cash-payments-list-filter [disableExportCsv]="disableSearch" (exportCSV)="exportCsv()">
            <app-text-icon-button icon="search" (clicked)="search()" [disabled]="disableSearch" searchTemplate label="{{'payment.list.search'|translate}}" [submit]="true"
            size="md" customClasses="d-flex ms-auto" buttonType="btn-icon-text"></app-text-icon-button>
          </app-e-cash-payments-list-filter>
        </form>
      </div>
    </div>
        <app-e-cash-payments-list [eCashPayments]="eCashPayments" *ngIf="loaded| async; else noData" ></app-e-cash-payments-list>
        <div class="row mt-2">
          <div class="col-md-12" *ngIf=displayPagination>
            <app-sg-pagination [pageSize]="itemsByPage" [totalItems]="totalItems" (pageChanged)="onPageChanged($event)"
              [currentPage]="pageNumber" enableCellTextSelection=true [maxPagesToDisplay]="10"></app-sg-pagination>
          </div>
        </div>
        <ng-template #noData>
          <div class="col-12 justify-content-center" [class.d-none]="!eCashPayments" [class.d-flex]="eCashPayments">
            <div class="text-center text-secondary">
              <em class="icon icon-xl d-inline-block">search</em>
              <div class="h4" translate>ag-grid.title</div>
              <p translate>ag-grid.searchSubTitle</p>
            </div>
          </div>
        </ng-template>
      </div>
  