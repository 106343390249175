import { Injectable } from '@angular/core';

import { DisplayService } from '@shared/services/display/display.service';
import { IssuanceModel } from '@instruments/models/issuance.model';
import { InstrumentFormService, IssuanceFormService } from '..';
import { IpaTypeModelRules as R, BooleanRules as B } from '@instruments/rules';

@Injectable()
export class IssuanceDisplayService extends DisplayService<IssuanceModel> {
  constructor(is: IssuanceFormService, ts: InstrumentFormService) {
    super(is);

    this.with(ts, 'trancheStatus').rules({
      activeOn: ['edit', 'add'],
      when: ({ trancheStatus }) => {
        return B.isUndefined(trancheStatus) || R.isInitialized(trancheStatus) || R.isLive(trancheStatus);
      },
      displayFields: ['nominal', 'numberOfShares']
    });

    this.describe('tradingMethodIdentifier').rules({
      activeOn: ['edit', 'add'],
      when: ({ tradingMethodIdentifier }) => R.isTradingMethodNominal(tradingMethodIdentifier),
      displayFields: 'nominal'
    });

    this.describe('tradingMethodIdentifier').rules({
      activeOn: ['edit', 'add'],
      when: ({ tradingMethodIdentifier }) => R.istradingMethodUnit(tradingMethodIdentifier),
      displayFields: 'numberOfShares'
    });

    this.with(ts, 'trancheStatus').rules({
      activeOn: ['edit', 'add'],
      when: ({ trancheStatus }) => R.isLive(trancheStatus),
      displayFields: ['outstandingNominal']
    });

    this.with(ts, 'upto').rules({
      when: ({ upto }) => B.isTrue(upto),
      displayFields: 'maximumNominal'
    });
  }
}
