/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { DayModel } from '../day.model';

@Component({
  selector: 'app-date-pill',
  templateUrl: './date-pill.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatePillComponent {
  @Input() date!: DayModel;
  @Input() isReadOnly = false;
  @Output() deleted = new EventEmitter<void>();

  public get dateLabel(): string | null {
    if (!this.date) {
      return null;
    }
    return `${this.date?.day.toString().padStart(2, '0')}/${this.date?.month.toString().padStart(2, '0')}`;
  }

  public onDelete(event: MouseEvent): boolean {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.deleted.next();
    return false;
  }
}
