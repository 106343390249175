import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { ComponentUtils } from '@shared/utils';

@Directive({
  selector: '[appFocusInvalidInput]'
})
export class FocusInvalidInputDirective {
  private readonly containerEl = window;
  @Input()
  formElementRef: HTMLFormElement | undefined;
  @Input()
  form: UntypedFormGroup | undefined;

  constructor(private readonly el: ElementRef) {}

  @HostListener('click') validateBeforeSubmit(): void {
    if (this.form) {
      this.validateAllFormFields(this.form);
    }
    if (this.form?.invalid) {
      ComponentUtils.scrollToFirstInvalidControl(this.containerEl, '.ng-invalid', this.formElementRef?.nativeElement);
    }
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
