/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[focusTo]'
})
export class FocusToDirective implements OnInit {
  @Input('focusTo')
  targetRef: any = null;

  private createGuid(): string {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return `${S4()}${S4()}-${S4()}-4${S4().substring(0, 4)}-${S4()}-${S4()}${S4()}${S4()}`.toLowerCase();
  }

  constructor(private readonly elRef: ElementRef) {}
  ngOnInit(): void {
    if (!this.elRef || !this.elRef.nativeElement) {
      return;
    }

    const focusId = this.createGuid();
    this.elRef.nativeElement.setAttribute('for', focusId);
    if (!this.targetRef) {
      console.warn(`Label' ${focusId}' doesn't have a target`);
    } else {
      if (this.targetRef instanceof HTMLElement) {
        this.targetRef.setAttribute('id', focusId);
      } else if ('focusId' in this.targetRef) {
        this.targetRef.focusId = focusId;
      }
    }
  }
}
