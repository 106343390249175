/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { PermissionService } from '@shared/services/permission.service';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from '@shared/services/forms/form.service';

@Directive()
export abstract class SearchContainerWithoutPaginationBaseComponent<M, C, QM extends Partial<C> | null, CriteriaSearchFormService extends FormService<C>> implements OnInit, OnDestroy {
  protected readonly shutdown$ = new Subject<void>();
  public models: M[] = [];
  protected searchCriteria: C | null | undefined;
  constructor(
    protected readonly criteriaFormService: CriteriaSearchFormService,
    public readonly permissionService: PermissionService,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly router: Router,
    protected readonly baseUrl: string | string[]
  ) {}
  ngOnInit(): void {
    this.init();
    this.subscribeToFormChanges();
    this.search();
    this.initPermissions();
  }
  protected init(): void {
    const queryString = this.activatedRoute.snapshot.queryParams.query;
    if (queryString) {
      const query = JSON.parse(queryString) as QM;
      this.criteriaFormService.setInitialValue(query);
    }
  }
  protected subscribeToFormChanges(): void {
    const Allkeys = Object.keys(this.criteriaFormService.fields) as (keyof C)[];
    this.criteriaFormService.valueChanges(Allkeys).subscribe(q => {
      const query = this.removeEmpty(q);
      const stringfiedPredicate = JSON.stringify(query);
      this.router.navigate([this.baseUrl], { queryParams: { query: stringfiedPredicate } });
    });
  }

  public searchClick(): void {
    if (!this.validateSearch()) {
      return;
    }
    this.search();
  }
  public search(): void {
    this.prepareSearch();
    this.load();
  }
  protected validateSearch(): boolean {
    this.criteriaFormService.updateValueAndValidity();
    return this.criteriaFormService.isFormValid;
  }
  protected prepareSearch(): void {
    this.searchCriteria = this.criteriaFormService.value();
  }
  protected abstract load(): void;
  protected abstract initPermissions(): void;

  ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.complete();
  }
  removeEmpty(obj:  Nullable<Pick<C, keyof C>>): Partial< Nullable<Pick<C, keyof C>>> {
    return Object.entries(obj)
      .filter(([_, v]) => v != null)
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }
}
