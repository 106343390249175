import { Component, OnInit, Input } from '@angular/core';
import { DisposalColumnDefService } from '@instruments/components/instrument-list/_aggrid/disposal-column-def-service';
import { DisposalItemModel } from '@instruments/models/disposal.model';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';

@Component({
  selector: 'app-disposal-items-consult',
  templateUrl: './disposal-items-consult.component.html',
  providers: [DisposalColumnDefService]
})
export class DisposalItemsConsultComponent implements OnInit {
  public gridOptions!: GridOptions;
  public columnDefs: ColDef[] = [];
  public defaultColDef: unknown;
  public gridApi!: GridApi;

  @Input() disposalsItems?: DisposalItemModel[] | null = [];
  constructor(private readonly disposalColumnDefService: DisposalColumnDefService) {}

  ngOnInit(): void {
    this.columnDefs = this.disposalColumnDefService.get();
    this.gridOptions = {
      pagination: false,
      suppressPaginationPanel: false,
      paginationPageSize: 10,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      suppressContextMenu: true,
      stopEditingWhenCellsLoseFocus: true
    };
    this.defaultColDef = {
      sortable: false,
      filter: false,
      suppressMenu: true
    };
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.refreshHeader();
  }
}
