<div class="section-forms" [formGroup]="formGroup">

  <div class="d-flex mb-4">

    <div class="me-2">
      <app-form-item [field]="fields.isin" [focus]="isin" [withLabel]="false">
        <div class="input-group input-group-md" component>
          <div class="input-icon-start">
            <em class="icon text-secondary">search</em>
          </div>
          <input placeholder="{{ placeholder(fields.isin)  | translate }}" #isin component type="search"
            [formControlName]="fields.isin" class="form-control form-control-md">
        </div>
      </app-form-item>
    </div>
    <div class="me-2">
      <app-form-item [field]="fields.ipaCode" [focus]="ipaCode" [withLabel]="false">
        <div class="input-group input-group-md" component>
          <div class="input-icon-start">
            <em class="icon text-secondary">search</em>
          </div>
          <input [formControlName]="fields.ipaCode" placeholder="{{ placeholder(fields.ipaCode)  | translate }}"
            class="form-control form-control-md" #ipaCode  >
        </div>
      </app-form-item>
    </div>

    <div class="me-2">
      <app-form-item [field]="fields.valueDate" [focus]="valueDate" [withLabel]="false">

        <app-date-picker [formControlName]="fields.valueDate" [hasError]="hasError(fields.valueDate)" #valueDate component size="md" >
        </app-date-picker>
      </app-form-item>
    </div>
    <div class="ms-auto ">

      <button type="button" class="btn btn-md btn-flat-primary btn-icon-start me-1" [disabled]="disableExportCsv" (click)="exportCsv()" *ngIf="canExport" translate>
         <em class="icon">save_alt</em> common-buttons.CSV-export
      </button>
      <ng-content select="[searchTemplate]"></ng-content>
    </div>
  </div>

  <div class="row mt-2 mb-4">
    <div class="col-md-10">
      <app-position-list-active-filters (filtersChanged)="onFiltersChanged($event)"></app-position-list-active-filters>
    </div>
  </div>
</div>
